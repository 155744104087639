import { useUserTenantSession } from '../components/UserTenantSessionProvider/UserTenantSessionContext'
import { Role } from '../generated/graphql'

export const ADMIN_ROLE = [Role.BillyAdmin, Role.Admin]
export const FINANCE_OR_ADMIN_ROLE = [...ADMIN_ROLE, Role.BillyEngineer, Role.Finance]

export const INTERNAL_ROLES = [Role.BillyAdmin, Role.BillyEngineer]
export const NON_ASSIGNABLE_ROLES = [Role.BillyAdmin, Role.BillyEngineer, Role.BillyJob, Role.Crm, Role.Import]

export function isFinanceOrAdminRole(role: Role | undefined): boolean {
  return !!role && FINANCE_OR_ADMIN_ROLE.includes(role)
}

export function isAdminRole(role: Role | undefined): boolean {
  return !!role && ADMIN_ROLE.includes(role)
}

export function canMutateProductCatalog(role: Role | undefined): boolean {
  const allowedRoles = [Role.Admin, Role.Finance]
  return !!role && allowedRoles.concat(INTERNAL_ROLES).includes(role)
}

export function canMutateOrder(role: Role | undefined): boolean {
  const allowedRoles = [Role.Admin, Role.Finance, Role.Sales, Role.SalesManager]
  return !!role && allowedRoles.concat(INTERNAL_ROLES).includes(role)
}

export function canMutateOpportunity(role: Role | undefined): boolean {
  const allowedRoles = [Role.Admin, Role.Finance, Role.Sales, Role.SalesManager]
  return !!role && allowedRoles.concat(INTERNAL_ROLES).includes(role)
}

export function canViewBulkInvoice(role: Role | undefined): boolean {
  const allowedRoles = VIEW_BULK_INVOICE_RUN_ROLES
  return !!role && allowedRoles.includes(role)
}

export function canMutateSubscription(role: Role | undefined): boolean {
  const unAllowedRoles = [Role.Sales]
  return !!role && !unAllowedRoles.includes(role)
}

export function canExecuteOrder(role: Role | undefined): boolean {
  const allowedRoles = [Role.Admin, Role.Finance, Role.SalesManager]
  return !!role && allowedRoles.concat(INTERNAL_ROLES).includes(role)
}

export function canCreateChangeOrder(role: Role | undefined): boolean {
  const allowedRoles = [Role.Admin, Role.Finance, Role.Sales, Role.SalesManager]
  return !!role && allowedRoles.concat(INTERNAL_ROLES).includes(role)
}

export function canMutateInvoice(role: Role | undefined): boolean {
  const allowedRoles = [Role.Admin, Role.Finance, Role.BillingClerk]
  return !!role && allowedRoles.concat(INTERNAL_ROLES).includes(role)
}

export function canMutateCreditMemo(role: Role | undefined): boolean {
  const allowedRoles = [Role.Admin, Role.Finance, Role.BillingClerk]
  return !!role && allowedRoles.concat(INTERNAL_ROLES).includes(role)
}

export function canApplyPayment(role: Role | undefined): boolean {
  const allowedRoles = [Role.Admin, Role.Finance, Role.BillingClerk]
  return !!role && allowedRoles.concat(INTERNAL_ROLES).includes(role)
}

export function canVoidInvoice(role: Role | undefined): boolean {
  const allowedRoles = [Role.Admin, Role.Finance, Role.BillingClerk]
  return !!role && allowedRoles.concat(INTERNAL_ROLES).includes(role)
}

export function canVoidPayment(role: Role | undefined) {
  return canApplyPayment(role)
}

export function canEnableAccounting(role: Role | undefined): boolean {
  const allowedRoles = [Role.Admin]
  return !!role && allowedRoles.concat(INTERNAL_ROLES).includes(role)
}

export function canViewAccountingPeriods(role: Role | undefined): boolean {
  const allowedRoles = [
    Role.Admin,
    Role.Finance,
    Role.Accountant,
    Role.BillingClerk,
    Role.RevenueClerk,
    Role.ReadOnly,
    Role.Executive,
  ]
  return !!role && allowedRoles.concat(INTERNAL_ROLES).includes(role)
}

export function canMutateAccounting(role: Role | undefined): boolean {
  const allowedRoles = [Role.Admin, Role.Finance, Role.Accountant]
  return !!role && allowedRoles.concat(INTERNAL_ROLES).includes(role)
}

export function useCanViewRevenueDetails() {
  const { currentUser } = useUserTenantSession()
  return canViewRevenueDetails(currentUser.role)
}

export function canMutateRevenue(role: Role | undefined): boolean {
  return isRoleAllowed(role, EDIT_RECOGNIZE_REVENUE_ROLES)
}

export function useCanMutateRevenue() {
  const { currentUser } = useUserTenantSession()
  return canMutateRevenue(currentUser.role)
}

export function canViewRevenueDetails(role: Role | undefined): boolean {
  const allowedRoles = [Role.Admin, Role.Finance, Role.Accountant, Role.RevenueClerk, Role.ReadOnly, Role.Executive]
  return isRoleAllowed(role, allowedRoles)
}

export function canViewReports(role: Role | undefined): boolean {
  const allowedRoles = [
    Role.Accountant,
    Role.Admin,
    Role.Finance,
    Role.BillingClerk,
    Role.RevenueClerk,
    Role.ReadOnly,
    Role.Executive,
    Role.SalesManager,
  ]
  return !!role && allowedRoles.concat(INTERNAL_ROLES).includes(role)
}

export function useCanUploadUsage(): boolean {
  const { currentUser } = useUserTenantSession()
  const allowedRoles = [Role.Admin, Role.Finance, Role.BillingClerk]
  return isRoleAllowed(currentUser.role, allowedRoles)
}

export function canMutateAccount(role: Role | undefined): boolean {
  return isRoleAllowed(role, MUTATE_ACCOUNT_ROLES)
}

export function useCanMutateAccount(): boolean {
  const { currentUser } = useUserTenantSession()
  return canMutateAccount(currentUser.role)
}

export function useCanMutateAccountContact(): boolean {
  const { currentUser } = useUserTenantSession()
  const allowedRoles = [Role.Admin, Role.Finance, Role.Sales, Role.SalesManager]
  return isRoleAllowed(currentUser.role, allowedRoles)
}

export function useCanMutateSubscriptionChargeAlias(): boolean {
  const { currentUser } = useUserTenantSession()
  const allowedRoles = [Role.Admin, Role.Finance, Role.Accountant, Role.BillingClerk, Role.RevenueClerk]
  return isRoleAllowed(currentUser.role, allowedRoles)
}

export function useCanMutateCreditMemo() {
  const { currentUser } = useUserTenantSession()
  return isRoleAllowed(currentUser.role, MUTATE_CREDIT_MEMO_ROLES)
}

export function useCanMutateRateCard() {
  const { currentUser } = useUserTenantSession()
  return isRoleAllowed(currentUser.role, ADMIN_ROLE)
}

export function canGenerateAccountPaymentLink(role: Role | undefined): boolean {
  const allowedRoles = [Role.Admin, Role.Finance, Role.BillingClerk]
  return !!role && allowedRoles.concat(INTERNAL_ROLES).includes(role)
}

export const CREATE_ACCOUNT_ROLES = [Role.Admin, Role.Finance, Role.Sales, Role.SalesManager, Role.BillingClerk]

const MUTATE_ACCOUNT_ROLES = [Role.Admin, Role.Finance, Role.Sales, Role.SalesManager, Role.Accountant]

const EDIT_RECOGNIZE_REVENUE_ROLES = [Role.Admin, Role.Finance, Role.RevenueClerk]

export const VIEW_BULK_INVOICE_RUN_ROLES = [Role.Admin, Role.Finance, Role.BillingClerk, Role.ReadOnly, Role.Executive]

export const MUTATE_BULK_INVOICE_RUN_ROLES = [Role.Admin, Role.Finance, Role.BillingClerk]

export const MUTATE_CREDIT_MEMO_ROLES = [Role.Admin, Role.Finance, Role.BillingClerk]

export function isRoleAllowed(role: Role | undefined, allowedRoles: Array<Role>): boolean {
  return !!role && allowedRoles.concat(INTERNAL_ROLES).includes(role)
}

export function getAllowedRoles(predicate: (role: Role) => boolean): Array<Role> {
  return Object.values(Role).filter((role) => predicate(role))
}
