import { Feature, OpportunityFragment, OrderDetailFragment, OrderStatus, OrderType } from '@/generated/graphql'
import React from 'react'
import { Grid, Typography } from '@mui/material'
import BillyDetailsCard from '@/components/card/billyDetailsCard'
import PrimaryOrderChip from '@/components/table/cells/primaryOrderChip'
import BillyLink from '@/components/link/billyLink'
import DocumentLink from '@/pageComponents/orders/documentLink'
import { GenerateSalesRoomLink } from '@/components/SalesRoom/GenerateSalesRoomLink'
import DateRangeStart from '@/components/DateRangeStart/DateRangeStart'
import ContactCell, { MissingContactCell } from '@/components/table/cells/contactCell'
import { toTitleCase } from '@/util/string'
import DateRangeEnd from '@/components/DateRangeEnd/DateRangeEnd'
import { getBillingCycleDisplayText } from '@/util/billingCycleDisplayUtil'
import { getPaymentTermDisplayText } from '@/util/paymentTermUtil'
import { SingleEntityCell } from '@/components/table/cells/EntityCell'
import { notEmpty } from '@/util/array'
import { billableCurrencyFormat } from '@/util/currencyUtil'
import BooleanDisplay from '@/components/display/booleanDisplay'
import { PurchaseOrderCellContent } from '@/components/table/cells/PurchaseOrderCell'
import { NOT_APPLICABLE } from '@/pages/orders/[orderId]'
import { makeStyles } from 'tss-react/mui'
import { useDoesMultipleEntitiesExist } from '@/components/Entity/useDoesMultipleEntitiesExist'
import useDynamicFeatureFlag from '@/components/state/useDynamicFeatureFlag'
import { useIsUpdateOrderStartDateEnabled } from '@/pageComponents/orders/useIsUpdateOrderStartDateEnabled'
import { useCurrencyContext } from '@/components/state/context/CurrencyContext'

export const useOrderDetailStyles = makeStyles()((theme, _params, _classes) => ({
  linkText: {
    fontWeight: 550,
    fontSize: '0.875rem',
  },
}))

export function OrderDetailsViewCard({
  orderId,
  orderData,
  opportunityData,
  hasHubSpotIntegration,
  hasSalesforceIntegration,
}: {
  orderData: OrderDetailFragment
  opportunityData?: OpportunityFragment
  orderId: string
  hasHubSpotIntegration?: boolean
  hasSalesforceIntegration?: boolean
}) {
  const { classes } = useOrderDetailStyles()
  const isRenewal = orderData?.orderType === OrderType.Renewal
  const isMultiEntityEnabled = useDoesMultipleEntitiesExist()
  const isDocumentLinkFeatureEnabled = useDynamicFeatureFlag(Feature.DocumentLink)
  const isSalesRoomFeatureEnabled = useDynamicFeatureFlag(Feature.SalesRoom)
  const isUpdateOrderStartDateEnabled = useIsUpdateOrderStartDateEnabled()
  const orderIsNotComposite = !orderData?.compositeOrderId

  const [currency] = useCurrencyContext()

  return (
    <Grid item>
      <BillyDetailsCard
        titleLabel={`${orderData.orderType} SUBSCRIPTION ORDER`}
        title={orderData.name ?? `Order Details`}
        subtitle={orderData.id}
        status={orderData.status === OrderStatus.Expired ? OrderStatus.Expired : ''}
        action={<PrimaryOrderChip value={orderData.isPrimaryOrderForSfdcOpportunity} />}
        rows={[
          {
            cells: [
              {
                label: 'Account',
                content: (
                  <BillyLink nextProps={{ href: `/accounts/${orderData.account?.id}` }}>
                    <Typography className={classes.linkText}>{orderData.account?.name}</Typography>
                  </BillyLink>
                ),
              },
              {
                label: 'Composite Order',
                content: orderData.compositeOrderId ? (
                  <BillyLink nextProps={{ href: `/compositeOrders/${orderData.compositeOrderId}` }}>
                    <Typography className={classes.linkText}>{orderData.compositeOrderId}</Typography>
                  </BillyLink>
                ) : undefined,
              },
              {
                label: 'CRM Opportunity Name',
                content:
                  orderIsNotComposite &&
                  orderData.sfdcOpportunityName &&
                  (hasSalesforceIntegration || hasHubSpotIntegration) ? (
                    <BillyLink nextProps={{ href: `/opportunities/${opportunityData?.opportunityId}` }}>
                      <Typography className={classes.linkText}>{orderData.sfdcOpportunityName}</Typography>
                    </BillyLink>
                  ) : undefined,
              },
              {
                label: 'Subscription',
                content: orderData.subscriptionId ? (
                  <BillyLink nextProps={{ href: `/subscriptions/${orderData.subscriptionId}` }}>
                    <Typography className={classes.linkText}>{orderData.subscriptionId}</Typography>
                  </BillyLink>
                ) : undefined,
              },
              {
                label: 'External ID',
                content: orderData.externalId,
              },
              {
                label: 'Renewed From',
                content:
                  isRenewal && orderData.renewalForSubscription?.id ? (
                    <BillyLink nextProps={{ href: `/subscriptions/${orderData.renewalForSubscription.id}` }}>
                      <Typography className={classes.linkText}>{orderData.renewalForSubscription.id}</Typography>
                    </BillyLink>
                  ) : undefined,
              },
              {
                label: 'Share Order Form',
                content: isDocumentLinkFeatureEnabled ? <DocumentLink orderId={orderId} /> : undefined,
              },
              {
                label: 'Sales Room',
                content:
                  isSalesRoomFeatureEnabled && orderData.status === OrderStatus.Approved ? (
                    <GenerateSalesRoomLink orderId={orderId} orderData={orderData} />
                  ) : undefined,
              },
              {
                label: 'Approval Segment',
                content: orderData.approvalSegment?.name,
              },
              {
                label: 'Expires on',
                content:
                  orderData.expiresOn && orderData.status === OrderStatus.Draft ? (
                    <DateRangeStart datetime={orderData.expiresOn} />
                  ) : null,
              },
            ].filter((el) => !!el.content),
          },
          {
            cells: [
              {
                label: 'Bill to',
                content: orderData?.billingContact ? (
                  <ContactCell className={classes.linkText} contact={orderData.billingContact} />
                ) : (
                  <MissingContactCell />
                ),
              },
              {
                label: 'Ship to',
                content: orderData?.shippingContact ? (
                  <ContactCell className={classes.linkText} contact={orderData.shippingContact} />
                ) : (
                  <MissingContactCell />
                ),
              },
              {
                label: 'Currency',
                content: currency || 'USD',
              },
              {
                label: 'Channel',
                content: orderData.resoldBy?.id ? (
                  <div>
                    Resold by
                    <div>
                      <BillyLink nextProps={{ href: `/accounts/${orderData.resoldBy.id}` }}>
                        <Typography className={classes.linkText}>{orderData.resoldBy.name}</Typography>
                      </BillyLink>
                    </div>
                  </div>
                ) : (
                  ''
                ),
              },
            ],
          },
          {
            cells: [
              {
                label: 'Order Start Date Type',
                content:
                  isUpdateOrderStartDateEnabled && orderData.orderType === OrderType.New
                    ? toTitleCase(orderData.startDateType ?? 'Fixed')
                    : undefined,
              },
              {
                label: 'Start Date',
                content: orderData.startDate ? <DateRangeStart datetime={orderData.startDate} /> : null,
              },
              {
                label: 'End Date',
                content: orderData.endDate ? <DateRangeEnd datetime={orderData.endDate} /> : null,
              },
              {
                label: 'Order Execution Date',
                content: orderData.executedOn ? <DateRangeStart datetime={orderData.executedOn} /> : null,
              },
            ].filter((el) => !!el.content),
          },
          {
            cells: [
              {
                label: 'Billing Cycle Start Date',
                content: orderData.billingAnchorDate ? <DateRangeStart datetime={orderData.billingAnchorDate} /> : null,
              },
              {
                label: 'Billing Cycle',
                content: getBillingCycleDisplayText({ cycle: orderData.billingCycle }),
              },
              {
                label: 'Payment Term',
                content: getPaymentTermDisplayText(orderData.paymentTerm),
                width: 2,
              },
              {
                label: 'Entity',
                content: isMultiEntityEnabled
                  ? orderData?.entityId && <SingleEntityCell value={orderData?.entityId} />
                  : '',
              },
            ].filter(notEmpty),
          },
          {
            cells: [
              {
                label: 'Entry ARR (MRR)',
                content:
                  orderData.orderMetrics?.entryArr == null
                    ? NOT_APPLICABLE
                    : `${billableCurrencyFormat({
                        currency,
                        value: orderData.orderMetrics.entryArr,
                      })} (${billableCurrencyFormat({
                        currency,
                        value: orderData.orderMetrics.entryArr / 12,
                      })})`,
              },
              {
                label: 'ARR (MRR) Now',
                content:
                  orderData.orderMetrics?.arr == null
                    ? NOT_APPLICABLE
                    : `${billableCurrencyFormat({
                        currency,
                        value: orderData.orderMetrics.arr,
                      })} (${billableCurrencyFormat({
                        currency,
                        value: orderData.orderMetrics.arr / 12,
                      })})`,
              },
              {
                label: 'Exit ARR (MRR)',
                content:
                  orderData.orderMetrics?.exitArr == null
                    ? NOT_APPLICABLE
                    : `${billableCurrencyFormat({
                        currency,
                        value: orderData.orderMetrics.exitArr,
                      })} (${billableCurrencyFormat({
                        currency,
                        value: orderData.orderMetrics.exitArr / 12,
                      })})`,
              },
              {
                label: 'Average ARR (MRR)',
                content:
                  orderData.orderMetrics?.averageArr == null
                    ? NOT_APPLICABLE
                    : `${billableCurrencyFormat({
                        currency,
                        value: orderData.orderMetrics.averageArr,
                      })} (${billableCurrencyFormat({
                        currency,
                        value: orderData.orderMetrics.averageArr / 12,
                      })})`,
              },
            ],
          },
          {
            cells: [
              {
                label: 'Delta ARR (MRR)',
                content:
                  orderData.orderMetrics?.deltaArr == null
                    ? NOT_APPLICABLE
                    : `${billableCurrencyFormat({
                        currency,
                        value: orderData.orderMetrics.deltaArr,
                      })} (${billableCurrencyFormat({
                        currency,
                        value: orderData.orderMetrics.deltaArr / 12,
                      })})`,
              },
            ],
          },
          {
            cells: [
              {
                label: 'Created By',
                content:
                  orderData.createdBy?.displayName + (orderData.createdBy?.state != 'ACTIVE' ? ' (Disabled)' : ''),
              },
              {
                label: 'Owner',
                content: orderData.owner?.displayName + (orderData.owner?.state != 'ACTIVE' ? ' (Disabled)' : ''),
              },
              {
                label: 'Auto-Renew',
                content: <BooleanDisplay value={orderData.autoRenew || false} />,
              },
              {
                label: 'P.O Number' + (orderData?.purchaseOrderRequiredForInvoicing ? ' (Required)' : ''),
                content:
                  orderData?.purchaseOrderRequiredForInvoicing || orderData?.purchaseOrderNumber ? (
                    <PurchaseOrderCellContent
                      purchaseOrderRequired={orderData?.purchaseOrderRequiredForInvoicing}
                      purchaseOrderNumber={orderData?.purchaseOrderNumber}
                    />
                  ) : null,
              },
            ].filter(notEmpty),
          },
        ]}
      />
    </Grid>
  )
}
