import React, { useCallback, useContext } from 'react'
import { CardHeader } from '@mui/material'
import BillyCard from '../../components/card/billyCard'
import { JotaiFormContext } from '../../components/state/jotaiFormProvider'
import { JotaiForm } from '../../components/state/useJotaiForm'
import BaseTable, { BillyColumn } from '../../components/table/baseTable'
import { EmailContacts, EmailLogsResponse, EmailType, InvoiceDunningDetailFragment } from '../../generated/graphql'
import { InvoicePreviewPageForm } from '../../pages/invoices/[invoiceNumber]'
import { notEmpty } from '../../util/array'
import { unixToUtcString } from '../../util/datetime/luxon/dateUtil'
import { dunningTypeToLabel } from '../../util/dunning'
import buildLogger from '../../util/logger'
import { deepMutable } from '../../components/SchemaForm/DeepMutable'
import { EmailContactsCell } from '@/components/table/cells/EmailContactsCell'
import StatusChip from '@/components/table/cells/statusChip'
import { toTitleCase } from '@/util/string'

const logger = buildLogger('InvoiceEmailActivityTable')

export enum EmailStatus {
  Attempted = 'ATTEMPTED',
  Delivered = 'DELIVERED',
  Bounced = 'BOUNCED',
}

type EmailActivity = {
  emailSentOn: string
  type: string
  recipients: EmailContacts
  status: string
}

function mapInvoiceDunningDetailsAndEmailLogs(
  invoiceDunningDetails?: InvoiceDunningDetailFragment[],
  emailLogs?: EmailLogsResponse
): EmailActivity[] {
  if (!emailLogs) {
    return []
  }

  return emailLogs.logs
    .filter(notEmpty)
    .sort((d1, d2) => d1.createdOn - d2.createdOn)
    .map((log) => {
      let type = toTitleCase(log.emailType)
      if (log.emailType === EmailType.Dunning) {
        const dunningReminderType = invoiceDunningDetails?.find(
          (dunning) => dunning?.emailId === log.emailId
        )?.reminderType
        if (dunningReminderType) {
          type = dunningTypeToLabel(dunningReminderType)
        }
      }

      return {
        emailSentOn: unixToUtcString(log.createdOn),
        type: type,
        recipients: log.emailContacts as EmailContacts,
        status: log.status,
      }
    })
}

const InvoiceEmailActivityTable: React.FC<React.PropsWithChildren<Record<string, never>>> = () => {
  const columns: ReadonlyArray<BillyColumn<EmailActivity>> = [
    {
      Header: 'Date',
      accessor: 'emailSentOn',
      dataType: 'date',
    },
    {
      Header: 'Type',
      accessor: 'type',
    },
    {
      Header: 'Recipient',
      accessor: 'recipients',
      Cell: EmailContactsCell,
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: StatusChip,
    },
  ]

  const jotaiForm = useContext<JotaiForm<InvoicePreviewPageForm>>(JotaiFormContext)
  const invoiceDunningDetails = jotaiForm.useSelect(
    useCallback((form: InvoicePreviewPageForm) => form.invoiceDunningDetails, [])
  )
  const emailLogs = jotaiForm.useSelect(useCallback((form: InvoicePreviewPageForm) => form.emailLogs, []))

  return (
    <>
      <BillyCard hidden={!invoiceDunningDetails && !emailLogs}>
        <CardHeader title="Email Activity" />
        <BaseTable
          columns={columns}
          data={mapInvoiceDunningDetailsAndEmailLogs(
            deepMutable(invoiceDunningDetails),
            deepMutable(emailLogs) as EmailLogsResponse
          )}
          onRowClick={(id) => {
            //TODO: v2 adding email template preview
            logger.info({ msg: 'clicked', id })
          }}
          rowId={'id'}
        />
      </BillyCard>
    </>
  )
}

export default InvoiceEmailActivityTable
