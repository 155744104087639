import { TabCard } from '@/components/tab/TabCard'
import { TabPanel } from '@mui/lab'
import OrderInvoicePreview from '@/pageComponents/orders/OrderInvoicePreview'
import React from 'react'
import { OrderDetailFragment, PlatformFeature, Role } from '@/generated/graphql'
import { useEnabledPlatformFeature } from '@/components/state/useEnabledPlatformFeature'
import BillyCard from '@/components/card/billyCard'
import { CardHeader } from '@mui/material'
import LineItemsViewTable from '@/pageComponents/orders/LineItemsViewTable/LineItemsViewTable'
import { deepMutable } from '@/components/SchemaForm/DeepMutable'
import { canMutateOrder } from '@/util/roleUtils'
import OrderTotalListPriceCardContent from '@/pageComponents/orders/orderTotalListPriceCardContent'
import { isOrderExecutedOrCancelled } from '@/pages/orders/[orderId]'

type OrderItemPreviewProps = {
  orderData: OrderDetailFragment
  orderId: string
  updateOrder: () => void
  role: Role | undefined
}

function OrderLineItemsTable(props: OrderItemPreviewProps) {
  const { orderData, updateOrder, role } = props
  return (
    <BillyCard hidden={!orderData}>
      <CardHeader title="Order Items" />
      <LineItemsViewTable
        currentSubscription={orderData.currentSubscription}
        lineItems={deepMutable(orderData.lineItems)}
        currency={orderData?.currency ?? undefined}
        orderType={orderData.orderType}
        metrics={orderData.orderMetrics ?? undefined}
        canEditCustomFields={!isOrderExecutedOrCancelled(orderData) && canMutateOrder(role)}
        onRefresh={updateOrder}
      />
      <OrderTotalListPriceCardContent orderDetail={orderData} />
    </BillyCard>
  )
}

export function OrderItemPreview(props: OrderItemPreviewProps) {
  const { orderData, orderId } = props

  const orderInvoicePreviewEnabled = useEnabledPlatformFeature(PlatformFeature.Billing)

  return (
    <>
      {orderInvoicePreviewEnabled ? (
        <TabCard
          cardId={'OrderItemPreviewCard'}
          tabs={[
            { label: 'Order Items', value: 'order-items-tab' },
            { label: 'Invoice Preview', value: 'invoice-preview-tab', hide: !orderInvoicePreviewEnabled },
          ]}
        >
          <TabPanel value={'order-items-tab'}>
            <OrderLineItemsTable {...props} />
          </TabPanel>
          <TabPanel value={'invoice-preview-tab'}>
            <OrderInvoicePreview orderId={orderId} currency={orderData.currency ?? 'USD'} />
          </TabPanel>
        </TabCard>
      ) : (
        <OrderLineItemsTable {...props} />
      )}
    </>
  )
}
