import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
import * as Operations from 'urql'
import * as Urql from '../components/data/billyUrql'
export type Maybe<T> = T | null
export type InputMaybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  BigDecimal: number
  Long: number
  TimeZone: any
}

export type Account = {
  readonly __typename?: 'Account'
  readonly address?: Maybe<AccountAddress>
  readonly crmId?: Maybe<Scalars['String']>
  readonly crmType?: Maybe<Scalars['String']>
  readonly currency?: Maybe<Scalars['String']>
  readonly description?: Maybe<Scalars['String']>
  readonly entityIds?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>
  readonly erpId?: Maybe<Scalars['String']>
  readonly excludeFromBatchOperations?: Maybe<Scalars['Boolean']>
  readonly excludeFromDunning?: Maybe<Scalars['Boolean']>
  readonly externalId?: Maybe<Scalars['String']>
  readonly hasAutomaticPayment?: Maybe<Scalars['Boolean']>
  readonly id?: Maybe<Scalars['String']>
  readonly isReseller?: Maybe<Scalars['Boolean']>
  readonly name: Scalars['String']
  readonly phoneNumber?: Maybe<Scalars['String']>
  readonly supportedPaymentTypes?: Maybe<ReadonlyArray<Maybe<PaymentType>>>
  readonly taxExemptionUseCode?: Maybe<TaxExemptionUseCode>
  readonly updatedOn?: Maybe<Scalars['Long']>
}

export type AccountAddress = {
  readonly __typename?: 'AccountAddress'
  readonly city: Scalars['String']
  readonly country: Scalars['String']
  readonly state?: Maybe<Scalars['String']>
  readonly streetAddressLine1: Scalars['String']
  readonly streetAddressLine2?: Maybe<Scalars['String']>
  readonly zipcode: Scalars['String']
}

export type AccountContact = {
  readonly __typename?: 'AccountContact'
  readonly accountId?: Maybe<Scalars['String']>
  readonly address?: Maybe<AccountAddress>
  readonly email: Scalars['String']
  readonly erpId?: Maybe<Scalars['String']>
  readonly externalId?: Maybe<Scalars['String']>
  readonly firstName: Scalars['String']
  readonly id?: Maybe<Scalars['String']>
  readonly lastName?: Maybe<Scalars['String']>
  readonly phoneNumber?: Maybe<Scalars['String']>
  readonly title?: Maybe<Scalars['String']>
}

export type AccountContactDetail = {
  readonly __typename?: 'AccountContactDetail'
  readonly accountId?: Maybe<Scalars['String']>
  readonly address?: Maybe<AccountAddress>
  readonly email: Scalars['String']
  readonly erpId?: Maybe<Scalars['String']>
  readonly externalId?: Maybe<Scalars['String']>
  readonly firstName: Scalars['String']
  readonly id?: Maybe<Scalars['String']>
  readonly inUse: Scalars['Boolean']
  readonly lastName?: Maybe<Scalars['String']>
  readonly phoneNumber?: Maybe<Scalars['String']>
  readonly title?: Maybe<Scalars['String']>
  readonly updatedOn?: Maybe<Scalars['Long']>
}

export type AccountDetail = {
  readonly __typename?: 'AccountDetail'
  readonly accountMetrics: AccountMetrics
  readonly address?: Maybe<AccountAddress>
  /** @deprecated Deprecated */
  readonly automaticPaymentMethod?: Maybe<PaymentProviderPaymentMethod>
  readonly contactDetails: ReadonlyArray<AccountContactDetail>
  readonly crmId?: Maybe<Scalars['String']>
  readonly crmType?: Maybe<Scalars['String']>
  readonly currency?: Maybe<Scalars['String']>
  readonly customFields: ReadonlyArray<CustomFieldEntry>
  readonly description?: Maybe<Scalars['String']>
  readonly enrolledPaymentMethod?: Maybe<PaymentMethodDetail>
  readonly entityIds?: Maybe<ReadonlyArray<Scalars['String']>>
  readonly erpId?: Maybe<Scalars['String']>
  readonly excludeFromBatchOperations?: Maybe<Scalars['Boolean']>
  readonly excludeFromDunning?: Maybe<Scalars['Boolean']>
  readonly externalId?: Maybe<Scalars['String']>
  readonly hasAutomaticPayment?: Maybe<Scalars['Boolean']>
  readonly id: Scalars['String']
  readonly inUse: Scalars['Boolean']
  readonly isReseller?: Maybe<Scalars['Boolean']>
  readonly name: Scalars['String']
  readonly paymentMethodDetails?: Maybe<ReadonlyArray<PaymentMethodDetail>>
  readonly phoneNumber?: Maybe<Scalars['String']>
  readonly supportedPaymentTypes?: Maybe<ReadonlyArray<Maybe<PaymentType>>>
  readonly taxExemptionUseCode?: Maybe<TaxExemptionUseCode>
}

export type AccountMetrics = {
  readonly __typename?: 'AccountMetrics'
  readonly arr: Scalars['BigDecimal']
  readonly arrTrend: ReadonlyArray<Maybe<TimeSeriesAmount>>
  readonly averageArr?: Maybe<Scalars['BigDecimal']>
  readonly deltaArr: Scalars['BigDecimal']
  readonly deltaTcv: Scalars['BigDecimal']
  readonly entryArr?: Maybe<Scalars['BigDecimal']>
  readonly exitArr?: Maybe<Scalars['BigDecimal']>
  readonly nonRecurringTotal?: Maybe<Scalars['BigDecimal']>
  readonly recurringTotal?: Maybe<Scalars['BigDecimal']>
  readonly tcv: Scalars['BigDecimal']
}

export type AccountPaymentLinkResponse = {
  readonly __typename?: 'AccountPaymentLinkResponse'
  readonly accountId: Scalars['String']
  readonly connectAccountId: Scalars['String']
  /** @deprecated Deprecated */
  readonly enrolledPaymentMethod?: Maybe<PaymentProviderPaymentMethod>
  readonly enrolledPaymentMethodDetail?: Maybe<PaymentMethodDetail>
  readonly isCustomerEnrolledInAutoPay: Scalars['Boolean']
  readonly supportedPaymentTypes: ReadonlyArray<Maybe<PaymentType>>
}

export type AccountPaymentManagementLinkResponse = {
  readonly __typename?: 'AccountPaymentManagementLinkResponse'
  readonly accountId: Scalars['String']
  readonly linkId: Scalars['String']
}

export enum AccountPaymentMethodStatus {
  /** ACTIVE */
  Active = 'ACTIVE',
  /** DISABLED */
  Disabled = 'DISABLED',
  /** PENDING */
  Pending = 'PENDING',
  /** SUSPENDED */
  Suspended = 'SUSPENDED',
}

export type AccountReceivableContactJson = {
  readonly __typename?: 'AccountReceivableContactJson'
  readonly address?: Maybe<AccountAddress>
  readonly email: Scalars['String']
  readonly firstName: Scalars['String']
  readonly lastName?: Maybe<Scalars['String']>
  readonly phoneNumber?: Maybe<Scalars['String']>
}

export enum AccountType {
  /** ALL */
  All = 'ALL',
  /** NON_RESELLER */
  NonReseller = 'NON_RESELLER',
  /** RESELLER */
  Reseller = 'RESELLER',
}

export type AccountingPeriod = {
  readonly __typename?: 'AccountingPeriod'
  readonly calculation?: Maybe<AccountingPeriodCalculation>
  readonly closedBy?: Maybe<Scalars['String']>
  readonly closedByUser?: Maybe<User>
  readonly closedOn?: Maybe<Scalars['String']>
  readonly deferredRevenueBalance?: Maybe<Scalars['BigDecimal']>
  readonly endDate: Scalars['String']
  readonly entityId?: Maybe<Scalars['String']>
  readonly id?: Maybe<Scalars['String']>
  readonly openedBy?: Maybe<Scalars['String']>
  readonly openedByUser?: Maybe<User>
  readonly openedOn?: Maybe<Scalars['String']>
  readonly startDate: Scalars['String']
  readonly status: AccountingPeriodStatus
  readonly syncStatus: ExternalSyncStatus
}

export type AccountingPeriodCalculation = {
  readonly __typename?: 'AccountingPeriodCalculation'
  readonly additionalRevenue?: Maybe<Scalars['BigDecimal']>
  readonly deferredRevenueEndingBalance?: Maybe<Scalars['BigDecimal']>
  readonly deferredRevenueStartingBalance?: Maybe<Scalars['BigDecimal']>
  readonly recognizedRevenue?: Maybe<Scalars['BigDecimal']>
  readonly unrecognizedRevenue?: Maybe<Scalars['BigDecimal']>
  readonly unrecognizedTransactionCount?: Maybe<Scalars['Int']>
}

export enum AccountingPeriodStatus {
  /** CLOSED */
  Closed = 'CLOSED',
  /** CLOSE_IN_PROGRESS */
  CloseInProgress = 'CLOSE_IN_PROGRESS',
  /** OPEN */
  Open = 'OPEN',
  /** UPCOMING */
  Upcoming = 'UPCOMING',
}

export type Action = {
  readonly __typename?: 'Action'
  readonly actionMetadata: Scalars['String']
  readonly actionRuleName: Scalars['String']
  readonly customizationActionType: CustomizationActionType
}

export enum ActionType {
  /** ADD */
  Add = 'ADD',
  /** MISSING_RENEWAL */
  MissingRenewal = 'MISSING_RENEWAL',
  /** NONE */
  None = 'NONE',
  /** REMOVE */
  Remove = 'REMOVE',
  /** RENEWAL */
  Renewal = 'RENEWAL',
  /** RESTRUCTURE */
  Restructure = 'RESTRUCTURE',
  /** UPDATE */
  Update = 'UPDATE',
}

export enum AddPaymentMethodActionType {
  /** CREATE */
  Create = 'CREATE',
  /** UPDATE */
  Update = 'UPDATE',
}

export type AggregatedUsage = {
  readonly __typename?: 'AggregatedUsage'
  readonly chargeId?: Maybe<Scalars['String']>
  readonly endAt?: Maybe<Scalars['Long']>
  readonly startAt?: Maybe<Scalars['Long']>
  readonly subscriptionId?: Maybe<Scalars['String']>
  readonly sum?: Maybe<Scalars['BigDecimal']>
}

export type ApiKeyDetail = {
  readonly __typename?: 'ApiKeyDetail'
  readonly active: Scalars['Boolean']
  readonly createdOn: Scalars['Long']
  readonly deactivatedBy?: Maybe<Scalars['String']>
  readonly entityId?: Maybe<Scalars['String']>
  readonly expiresOn?: Maybe<Scalars['Long']>
  readonly generatedBy?: Maybe<Scalars['String']>
  readonly id: Scalars['String']
  readonly note?: Maybe<Scalars['String']>
  readonly role?: Maybe<Scalars['String']>
  readonly userId?: Maybe<Scalars['String']>
}

export type ApprovalFlowDetail = {
  readonly __typename?: 'ApprovalFlowDetail'
  readonly description?: Maybe<Scalars['String']>
  readonly entityIds?: Maybe<ReadonlyArray<Scalars['String']>>
  readonly id?: Maybe<Scalars['String']>
  readonly name: Scalars['String']
  readonly states: ReadonlyArray<Maybe<ApprovalStateDetail>>
  readonly status?: Maybe<Scalars['String']>
  readonly transitionRules: ReadonlyArray<Maybe<ApprovalTransitionRuleDetail>>
}

export type ApprovalFlowInstanceDataDetail = {
  readonly __typename?: 'ApprovalFlowInstanceDataDetail'
  readonly approvalFlowId: Scalars['String']
  readonly description?: Maybe<Scalars['String']>
  readonly id: Scalars['String']
  readonly name: Scalars['String']
  readonly states: ReadonlyArray<ApprovalFlowInstanceStateDetail>
  readonly status: ApprovalFlowInstanceStatus
  readonly transitionRules: ReadonlyArray<ApprovalTransitionRuleDetail>
}

export type ApprovalFlowInstanceDetail = {
  readonly __typename?: 'ApprovalFlowInstanceDetail'
  readonly activeStateId?: Maybe<Scalars['String']>
  readonly approvalFlowId: Scalars['String']
  readonly data: ApprovalFlowInstanceDataDetail
  readonly id: Scalars['String']
  readonly status: ApprovalFlowInstanceStatus
}

export type ApprovalFlowInstanceGroupDetail = {
  readonly __typename?: 'ApprovalFlowInstanceGroupDetail'
  readonly approvalFlowInstances: ReadonlyArray<ApprovalFlowInstanceDetail>
  readonly approvalStatus: ApprovalFlowInstanceStatus
  readonly createdOn: Scalars['Long']
  readonly id: Scalars['String']
  readonly orderId: Scalars['String']
  readonly submitterNote?: Maybe<ApprovalFlowSubmitterNote>
  readonly updatedOn: Scalars['Long']
  readonly version: Scalars['Int']
  readonly workflowStatus: ApprovalFlowInstanceWorkflowStatus
}

export type ApprovalFlowInstanceStateDetail = {
  readonly __typename?: 'ApprovalFlowInstanceStateDetail'
  readonly action: ApprovalStateAction
  /** @deprecated Deprecated */
  readonly approvalGroup: UserGroupDetail
  readonly approvalGroupDetail: ApprovalGroupDetail
  readonly approvedBy: ReadonlyArray<Maybe<UserActionTimeJson>>
  readonly escalationPolicy?: Maybe<EscalationPolicy>
  readonly id: Scalars['String']
  readonly name: Scalars['String']
  readonly rejectedBy: ReadonlyArray<Maybe<UserActionTimeJson>>
  readonly status: ApprovalFlowInstanceStatus
}

export enum ApprovalFlowInstanceStatus {
  /** APPROVED */
  Approved = 'APPROVED',
  /** AWAITING_APPROVAL */
  AwaitingApproval = 'AWAITING_APPROVAL',
  /** CANCELLED */
  Cancelled = 'CANCELLED',
  /** ERROR_ORDER_OWNER_IS_MISSING_TO_FIND_APPROVAL_SEGMENT */
  ErrorOrderOwnerIsMissingToFindApprovalSegment = 'ERROR_ORDER_OWNER_IS_MISSING_TO_FIND_APPROVAL_SEGMENT',
  /** ERROR_ORDER_OWNER_NOT_PART_OF_ANY_APPROVAL_SEGMENT */
  ErrorOrderOwnerNotPartOfAnyApprovalSegment = 'ERROR_ORDER_OWNER_NOT_PART_OF_ANY_APPROVAL_SEGMENT',
  /** ERROR_ORDER_OWNER_NOT_PART_OF_APPROVAL_SEGMENT_SELECTED_ON_ORDER */
  ErrorOrderOwnerNotPartOfApprovalSegmentSelectedOnOrder = 'ERROR_ORDER_OWNER_NOT_PART_OF_APPROVAL_SEGMENT_SELECTED_ON_ORDER',
  /** ERROR_ORDER_OWNER_PART_OF_MULTIPLE_APPROVAL_SEGMENTS */
  ErrorOrderOwnerPartOfMultipleApprovalSegments = 'ERROR_ORDER_OWNER_PART_OF_MULTIPLE_APPROVAL_SEGMENTS',
  /** INACTIVE */
  Inactive = 'INACTIVE',
  /** NEEDS_APPROVAL */
  NeedsApproval = 'NEEDS_APPROVAL',
  /** NOT_APPLICABLE */
  NotApplicable = 'NOT_APPLICABLE',
  /** REJECTED */
  Rejected = 'REJECTED',
}

export enum ApprovalFlowInstanceWorkflowStatus {
  /** COMPLETED */
  Completed = 'COMPLETED',
  /** IN_PROGRESS */
  InProgress = 'IN_PROGRESS',
  /** NOT_STARTED */
  NotStarted = 'NOT_STARTED',
  /** PREVIEW */
  Preview = 'PREVIEW',
}

export enum ApprovalFlowStatus {
  /** ACTIVE */
  Active = 'ACTIVE',
  /** INACTIVE */
  Inactive = 'INACTIVE',
}

export type ApprovalFlowSubmitterNote = {
  readonly __typename?: 'ApprovalFlowSubmitterNote'
  readonly id?: Maybe<Scalars['String']>
  readonly note: Scalars['String']
  readonly orderId: Scalars['String']
}

export type ApprovalGroupDetail = {
  readonly __typename?: 'ApprovalGroupDetail'
  readonly approvalRoleResolvedFrom?: Maybe<ApprovalRole>
  readonly description?: Maybe<Scalars['String']>
  readonly id: Scalars['String']
  readonly isDeleted: Scalars['Boolean']
  readonly isRole: Scalars['Boolean']
  readonly isUser: Scalars['Boolean']
  readonly isUserGroup: Scalars['Boolean']
  readonly name: Scalars['String']
  readonly updatedOn: Scalars['Long']
  readonly users: ReadonlyArray<Maybe<User>>
}

export type ApprovalRole = {
  readonly __typename?: 'ApprovalRole'
  readonly createdOn?: Maybe<Scalars['Long']>
  readonly description?: Maybe<Scalars['String']>
  readonly id?: Maybe<Scalars['String']>
  readonly name: Scalars['String']
  readonly updatedOn?: Maybe<Scalars['Long']>
}

export type ApprovalRuleConditions = {
  readonly __typename?: 'ApprovalRuleConditions'
  readonly orderCondition?: Maybe<Scalars['String']>
  readonly orderLineCondition?: Maybe<Scalars['String']>
}

export type ApprovalSegment = {
  readonly __typename?: 'ApprovalSegment'
  readonly createdOn?: Maybe<Scalars['Long']>
  readonly description?: Maybe<Scalars['String']>
  readonly id?: Maybe<Scalars['String']>
  readonly name: Scalars['String']
  readonly updatedOn?: Maybe<Scalars['Long']>
}

export type ApprovalStateAction = {
  readonly __typename?: 'ApprovalStateAction'
  readonly emailGroupId: Scalars['String']
}

export type ApprovalStateDetail = {
  readonly __typename?: 'ApprovalStateDetail'
  readonly action: ApprovalStateAction
  /** @deprecated Deprecated */
  readonly approvalGroup: UserGroupDetail
  readonly approvalGroupDetail: ApprovalGroupDetail
  readonly escalationPolicy?: Maybe<EscalationPolicy>
  readonly id: Scalars['String']
  readonly name: Scalars['String']
}

export type ApprovalTransitionRuleDetail = {
  readonly __typename?: 'ApprovalTransitionRuleDetail'
  readonly condition?: Maybe<Scalars['String']>
  readonly fromState?: Maybe<ApprovalStateDetail>
  readonly id: Scalars['String']
  readonly name: Scalars['String']
  readonly ruleConditions: ApprovalRuleConditions
  readonly toState: ApprovalStateDetail
}

export type ApprovalWithCodeResponse = {
  readonly __typename?: 'ApprovalWithCodeResponse'
  readonly orderId: Scalars['String']
  readonly status: ApprovalWithCodeStatus
}

export enum ApprovalWithCodeStatus {
  /** APPROVED */
  Approved = 'APPROVED',
  /** PREVIOUSLY_APPROVED */
  PreviouslyApproved = 'PREVIOUSLY_APPROVED',
  /** PREVIOUSLY_REJECTED */
  PreviouslyRejected = 'PREVIOUSLY_REJECTED',
  /** REJECTED */
  Rejected = 'REJECTED',
}

export enum ApproverType {
  /** ROLE */
  Role = 'ROLE',
  /** USER */
  User = 'USER',
  /** USER_GROUP */
  UserGroup = 'USER_GROUP',
}

export type Attachment = {
  readonly __typename?: 'Attachment'
  readonly accountId?: Maybe<Scalars['String']>
  readonly createdOn: Scalars['String']
  readonly description?: Maybe<Scalars['String']>
  readonly id: Scalars['String']
  readonly inUse?: Maybe<Scalars['Boolean']>
  readonly isDeleted?: Maybe<Scalars['Boolean']>
  readonly name: Scalars['String']
  readonly status?: Maybe<AttachmentStatus>
  readonly tag?: Maybe<AttachmentTag>
  readonly updatedOn?: Maybe<Scalars['String']>
}

export enum AttachmentStatus {
  /** UPLOADED */
  Uploaded = 'UPLOADED',
  /** UPLOADING */
  Uploading = 'UPLOADING',
}

export enum AttachmentTag {
  /** MASTER_SUBSCRIPTION_AGREEMENT */
  MasterSubscriptionAgreement = 'MASTER_SUBSCRIPTION_AGREEMENT',
  /** ORDER_FORM */
  OrderForm = 'ORDER_FORM',
  /** OTHER */
  Other = 'OTHER',
  /** STATEMENT_OF_WORK */
  StatementOfWork = 'STATEMENT_OF_WORK',
}

export type AttributeReference = {
  readonly __typename?: 'AttributeReference'
  readonly attributeDefinitionId: Scalars['String']
  readonly attributeValue: Scalars['String']
}

export enum AuthMethod {
  /** PASSWORD_AND_SSO */
  PasswordAndSso = 'PASSWORD_AND_SSO',
  /** PASSWORD_ONLY */
  PasswordOnly = 'PASSWORD_ONLY',
  /** SSO_ONLY */
  SsoOnly = 'SSO_ONLY',
}

export type AvalaraIntegration = {
  readonly __typename?: 'AvalaraIntegration'
  readonly accountId: Scalars['String']
  readonly companyCode?: Maybe<Scalars['String']>
  readonly createdOn: Scalars['Long']
  readonly integrationId: Scalars['String']
  readonly sandboxEnvironment: Scalars['Boolean']
  readonly shouldCommitDocuments?: Maybe<Scalars['Boolean']>
  readonly updatedOn: Scalars['Long']
}

export enum BillingCycle {
  /** DEFAULT */
  Default = 'DEFAULT',
  /** MONTH */
  Month = 'MONTH',
  /** PAID_IN_FULL */
  PaidInFull = 'PAID_IN_FULL',
  /** QUARTER */
  Quarter = 'QUARTER',
  /** SEMI_ANNUAL */
  SemiAnnual = 'SEMI_ANNUAL',
  /** YEAR */
  Year = 'YEAR',
}

export type BillingEventEntry = {
  readonly __typename?: 'BillingEventEntry'
  readonly amount: Scalars['BigDecimal']
  readonly createdOn: Scalars['Long']
  readonly id: Scalars['String']
  readonly triggerOn: Scalars['Long']
}

export type BillingEvents = {
  readonly __typename?: 'BillingEvents'
  readonly entries: ReadonlyArray<BillingEventEntry>
  readonly remainingAmount: Scalars['BigDecimal']
}

export enum BillingTerm {
  /** IN_ARREARS */
  InArrears = 'IN_ARREARS',
  /** UP_FRONT */
  UpFront = 'UP_FRONT',
}

export type BillyChartDataValue = {
  readonly __typename?: 'BillyChartDataValue'
  readonly crossNumber?: Maybe<Scalars['BigDecimal']>
  readonly crossText?: Maybe<Scalars['String']>
  readonly mainNumber?: Maybe<Scalars['BigDecimal']>
  readonly mainText?: Maybe<Scalars['String']>
}

export type BillyChartDataset = {
  readonly __typename?: 'BillyChartDataset'
  readonly data: ReadonlyArray<BillyChartDataValue>
  readonly label: Scalars['String']
  readonly order: Scalars['Int']
}

export type BulkInvoiceRun = {
  readonly __typename?: 'BulkInvoiceRun'
  readonly automatedInvoiceRuleId?: Maybe<Scalars['String']>
  readonly bulkInvoiceRunId: Scalars['String']
  readonly chargeInclusionOption?: Maybe<InvoiceChargeInclusionOption>
  readonly createdOn?: Maybe<Scalars['Long']>
  readonly description?: Maybe<Scalars['String']>
  readonly entityId?: Maybe<Scalars['String']>
  readonly failureReason?: Maybe<Scalars['String']>
  /** @deprecated Use BulkInvoiceRunId instead */
  readonly id: Scalars['String']
  readonly invoiceDate?: Maybe<Scalars['Long']>
  readonly invoiceSelector?: Maybe<BulkInvoiceRunSelector>
  readonly name?: Maybe<Scalars['String']>
  readonly phase?: Maybe<BulkInvoiceRunPhase>
  readonly status?: Maybe<BulkInvoiceRunStatus>
  readonly targetDate?: Maybe<Scalars['Long']>
  readonly updatedOn?: Maybe<Scalars['Long']>
}

export type BulkInvoiceRunExclusions = {
  readonly __typename?: 'BulkInvoiceRunExclusions'
  readonly accounts: ReadonlyArray<Account>
}

export type BulkInvoiceRunItem = {
  readonly __typename?: 'BulkInvoiceRunItem'
  readonly accountId: Scalars['String']
  readonly accountName?: Maybe<Scalars['String']>
  readonly createdOn?: Maybe<Scalars['Long']>
  readonly draftInvoiceNumber?: Maybe<Scalars['String']>
  readonly emailSent?: Maybe<Scalars['Boolean']>
  readonly excludedForEmailing?: Maybe<Scalars['Boolean']>
  readonly excludedForPosting?: Maybe<Scalars['Boolean']>
  readonly failureReason?: Maybe<Scalars['String']>
  readonly invoiceAmount?: Maybe<Scalars['BigDecimal']>
  readonly invoiceCreatedOn?: Maybe<Scalars['Long']>
  readonly invoiceCurrencyCode?: Maybe<Scalars['String']>
  readonly postedInvoiceNumber?: Maybe<Scalars['String']>
  readonly runId: Scalars['String']
  readonly subscriptionId: Scalars['String']
  readonly updatedOn?: Maybe<Scalars['Long']>
}

export enum BulkInvoiceRunPhase {
  /** INVOICES_EMAILED */
  InvoicesEmailed = 'INVOICES_EMAILED',
  /** INVOICES_EMAILING */
  InvoicesEmailing = 'INVOICES_EMAILING',
  /** INVOICES_GENERATED */
  InvoicesGenerated = 'INVOICES_GENERATED',
  /** INVOICES_GENERATING */
  InvoicesGenerating = 'INVOICES_GENERATING',
  /** INVOICES_POSTED */
  InvoicesPosted = 'INVOICES_POSTED',
  /** INVOICES_POSTING */
  InvoicesPosting = 'INVOICES_POSTING',
  /** INVOICE_GENERATION_NOT_STARTED */
  InvoiceGenerationNotStarted = 'INVOICE_GENERATION_NOT_STARTED',
}

export type BulkInvoiceRunSelector = {
  readonly __typename?: 'BulkInvoiceRunSelector'
  readonly exclusions: BulkInvoiceRunExclusions
}

export enum BulkInvoiceRunStatus {
  /** COMPLETED */
  Completed = 'COMPLETED',
  /** CREATED */
  Created = 'CREATED',
  /** FAILED */
  Failed = 'FAILED',
  /** PROCESSING */
  Processing = 'PROCESSING',
  /** QUEUED */
  Queued = 'QUEUED',
  /** RUNNING */
  Running = 'RUNNING',
}

export type ClmChatMessageDetail = {
  readonly __typename?: 'CLMChatMessageDetail'
  readonly createdOn?: Maybe<Scalars['Long']>
  readonly message: Scalars['String']
  readonly threadId?: Maybe<Scalars['String']>
  readonly userIdentifier: Scalars['String']
}

export type ClmThreadCreationDetail = {
  readonly __typename?: 'CLMThreadCreationDetail'
  readonly documentLinkId: Scalars['String']
  readonly firstMessage: ClmChatMessageDetail
  readonly pinObject: Scalars['String']
  readonly threadId: Scalars['String']
}

export type ClmThreadDetail = {
  readonly __typename?: 'CLMThreadDetail'
  readonly documentLinkId: Scalars['String']
  readonly messages: ReadonlyArray<Maybe<ClmChatMessageDetail>>
  readonly pinObject: Scalars['String']
  readonly threadId: Scalars['String']
}

export type CancelAndRestructureOrder = {
  readonly __typename?: 'CancelAndRestructureOrder'
  readonly account: Account
  readonly arr?: Maybe<Scalars['BigDecimal']>
  readonly autoRenew?: Maybe<Scalars['Boolean']>
  readonly billingAnchorDate?: Maybe<Scalars['Long']>
  readonly billingContact?: Maybe<AccountContact>
  readonly billingCycle: Recurrence
  readonly billingTerm: BillingTerm
  readonly compositeOrderId?: Maybe<Scalars['String']>
  readonly crmOpportunityId?: Maybe<Scalars['String']>
  readonly crmOpportunityName?: Maybe<Scalars['String']>
  readonly crmOpportunityStage?: Maybe<Scalars['String']>
  readonly crmOpportunityType?: Maybe<Scalars['String']>
  readonly currency?: Maybe<Scalars['String']>
  readonly currentSubscription?: Maybe<Subscription>
  readonly customFields?: Maybe<ReadonlyArray<CustomFieldEntry>>
  readonly deltaArr?: Maybe<Scalars['BigDecimal']>
  readonly documentCustomContent?: Maybe<DocumentCustomContent>
  readonly documentMasterTemplateId?: Maybe<Scalars['String']>
  readonly endDate?: Maybe<Scalars['Long']>
  readonly entityId?: Maybe<Scalars['String']>
  readonly id?: Maybe<Scalars['String']>
  readonly lineItems: ReadonlyArray<Maybe<OrderLineItemDetail>>
  readonly name?: Maybe<Scalars['String']>
  readonly nonRecurringTotal?: Maybe<Scalars['BigDecimal']>
  readonly orderFormTemplates?: Maybe<ReadonlyArray<Maybe<DocumentTemplate>>>
  readonly orders?: Maybe<ReadonlyArray<Maybe<OrderDetailMinimal>>>
  readonly owner?: Maybe<User>
  readonly paymentTerm: Scalars['String']
  readonly predefinedDiscounts?: Maybe<ReadonlyArray<Maybe<PredefinedDiscountDetail>>>
  readonly purchaseOrderNumber?: Maybe<Scalars['String']>
  readonly purchaseOrderRequiredForInvoicing?: Maybe<Scalars['Boolean']>
  readonly rampInterval?: Maybe<ReadonlyArray<Maybe<Scalars['Long']>>>
  readonly recurringTotal?: Maybe<Scalars['BigDecimal']>
  readonly removedLineItems?: Maybe<ReadonlyArray<Maybe<OrderLineItemDetail>>>
  readonly resoldBy?: Maybe<Account>
  readonly restructureForSubscriptionId?: Maybe<Scalars['String']>
  readonly shippingContact?: Maybe<AccountContact>
  readonly startDate: Scalars['Long']
  readonly status?: Maybe<OrderStatus>
  readonly subscriptionId?: Maybe<Scalars['String']>
  readonly tcv?: Maybe<Scalars['BigDecimal']>
  readonly termLength?: Maybe<Recurrence>
  readonly type?: Maybe<CompositeOrderType>
}

export type CatalogRelationship = {
  readonly __typename?: 'CatalogRelationship'
  readonly catalogRelationshipType?: Maybe<CatalogRelationshipType>
  readonly fromPlanId?: Maybe<Scalars['String']>
  readonly fromProductId?: Maybe<Scalars['String']>
  readonly relationshipId?: Maybe<Scalars['String']>
  readonly toPlanId?: Maybe<Scalars['String']>
  readonly toProductId?: Maybe<Scalars['String']>
}

export enum CatalogRelationshipType {
  /** EXCLUDED_PLANS */
  ExcludedPlans = 'EXCLUDED_PLANS',
  /** IS_PERCENT_OF */
  IsPercentOf = 'IS_PERCENT_OF',
  /** IS_REPLACED_BY */
  IsReplacedBy = 'IS_REPLACED_BY',
  /** REQUIRE_ALL_PLANS */
  RequireAllPlans = 'REQUIRE_ALL_PLANS',
  /** REQUIRE_ANY_PLANS */
  RequireAnyPlans = 'REQUIRE_ANY_PLANS',
}

export type Charge = {
  readonly __typename?: 'Charge'
  readonly amount?: Maybe<Scalars['BigDecimal']>
  readonly billingCycle?: Maybe<BillingCycle>
  readonly billingTerm?: Maybe<BillingTerm>
  readonly chargeModel: ChargeModel
  readonly defaultQuantity?: Maybe<Scalars['Long']>
  readonly description?: Maybe<Scalars['String']>
  readonly displayName?: Maybe<Scalars['String']>
  readonly durationInMonths?: Maybe<Scalars['Long']>
  readonly erpId?: Maybe<Scalars['String']>
  readonly externalId?: Maybe<Scalars['String']>
  readonly id?: Maybe<Scalars['String']>
  readonly isCustom?: Maybe<Scalars['Boolean']>
  readonly isDrawdown?: Maybe<Scalars['Boolean']>
  readonly isEventBased?: Maybe<Scalars['Boolean']>
  readonly isListPriceEditable?: Maybe<Scalars['Boolean']>
  readonly isRenewable: Scalars['Boolean']
  readonly itemCode?: Maybe<Scalars['String']>
  readonly ledgerAccountMapping?: Maybe<LedgerAccountMapping>
  readonly maxAmount?: Maybe<Scalars['BigDecimal']>
  readonly maxQuantity?: Maybe<Scalars['Long']>
  readonly minAmount?: Maybe<Scalars['BigDecimal']>
  readonly minQuantity?: Maybe<Scalars['Long']>
  readonly minimumCommitBaseChargeId?: Maybe<Scalars['String']>
  readonly name: Scalars['String']
  readonly overageBaseChargeId?: Maybe<Scalars['String']>
  readonly percent?: Maybe<Scalars['BigDecimal']>
  readonly percentDerivedFrom?: Maybe<PercentDerivedFrom>
  readonly planId?: Maybe<Scalars['String']>
  readonly priceTiers?: Maybe<ReadonlyArray<PriceTier>>
  readonly productCode?: Maybe<Scalars['String']>
  readonly rateCardId?: Maybe<Scalars['String']>
  readonly recognitionRuleId?: Maybe<Scalars['String']>
  readonly recurrence?: Maybe<Recurrence>
  readonly shouldTrackArr?: Maybe<Scalars['Boolean']>
  readonly targetPlanIds?: Maybe<ReadonlyArray<Scalars['String']>>
  readonly taxRateId?: Maybe<Scalars['String']>
  readonly type: ChargeType
  readonly unitOfMeasureId?: Maybe<Scalars['String']>
}

export type ChargeDefaultAttributeReferences = {
  readonly __typename?: 'ChargeDefaultAttributeReferences'
  readonly attributeReferences: ReadonlyArray<Maybe<AttributeReference>>
  readonly chargeId: Scalars['String']
  readonly id?: Maybe<Scalars['String']>
}

export type ChargeDetail = {
  readonly __typename?: 'ChargeDetail'
  readonly amount?: Maybe<Scalars['BigDecimal']>
  readonly billingCycle?: Maybe<BillingCycle>
  readonly billingTerm: BillingTerm
  readonly chargeModel: ChargeModel
  readonly defaultQuantity?: Maybe<Scalars['Long']>
  readonly description?: Maybe<Scalars['String']>
  readonly displayName?: Maybe<Scalars['String']>
  readonly durationInMonths?: Maybe<Scalars['Long']>
  readonly erpId?: Maybe<Scalars['String']>
  readonly externalId?: Maybe<Scalars['String']>
  readonly id: Scalars['String']
  readonly isCustom?: Maybe<Scalars['Boolean']>
  readonly isDrawdown?: Maybe<Scalars['Boolean']>
  readonly isEventBased?: Maybe<Scalars['Boolean']>
  readonly isListPriceEditable?: Maybe<Scalars['Boolean']>
  readonly isRenewable: Scalars['Boolean']
  readonly itemCode?: Maybe<Scalars['String']>
  readonly ledgerAccountMapping?: Maybe<LedgerAccountMapping>
  readonly maxAmount?: Maybe<Scalars['BigDecimal']>
  readonly maxQuantity?: Maybe<Scalars['Long']>
  readonly minAmount?: Maybe<Scalars['BigDecimal']>
  readonly minQuantity?: Maybe<Scalars['Long']>
  readonly minimumCommitBaseChargeId?: Maybe<Scalars['String']>
  readonly name: Scalars['String']
  readonly overageBaseChargeId?: Maybe<Scalars['String']>
  readonly percent?: Maybe<Scalars['BigDecimal']>
  readonly percentDerivedFrom?: Maybe<PercentDerivedFrom>
  readonly planId: Scalars['String']
  readonly priceTiers: ReadonlyArray<PriceTier>
  readonly productCode?: Maybe<Scalars['String']>
  readonly rateCardId?: Maybe<Scalars['String']>
  readonly recognitionRule?: Maybe<RecognitionRule>
  readonly recurrence?: Maybe<Recurrence>
  readonly shouldTrackArr?: Maybe<Scalars['Boolean']>
  readonly targetPlanIds?: Maybe<ReadonlyArray<Scalars['String']>>
  readonly taxRate?: Maybe<TaxRate>
  readonly type: ChargeType
  readonly unitOfMeasure?: Maybe<UnitOfMeasure>
}

export enum ChargeModel {
  /** BLOCK */
  Block = 'BLOCK',
  /** FLAT_FEE */
  FlatFee = 'FLAT_FEE',
  /** PER_UNIT */
  PerUnit = 'PER_UNIT',
  /** RATE_CARD_LOOKUP */
  RateCardLookup = 'RATE_CARD_LOOKUP',
  /** TIERED */
  Tiered = 'TIERED',
  /** VOLUME */
  Volume = 'VOLUME',
}

export type ChargeResolver = {
  readonly __typename?: 'ChargeResolver'
  readonly amount?: Maybe<Scalars['BigDecimal']>
  readonly billingCycle?: Maybe<BillingCycle>
  readonly billingTerm: BillingTerm
  readonly chargeModel: ChargeModel
  readonly defaultQuantity?: Maybe<Scalars['Long']>
  readonly description?: Maybe<Scalars['String']>
  readonly displayName?: Maybe<Scalars['String']>
  readonly durationInMonths?: Maybe<Scalars['Long']>
  readonly erpId?: Maybe<Scalars['String']>
  readonly externalId?: Maybe<Scalars['String']>
  readonly id: Scalars['String']
  readonly isCustom?: Maybe<Scalars['Boolean']>
  readonly isDrawdown?: Maybe<Scalars['Boolean']>
  readonly isEventBased?: Maybe<Scalars['Boolean']>
  readonly isListPriceEditable?: Maybe<Scalars['Boolean']>
  readonly isRenewable?: Maybe<Scalars['Boolean']>
  readonly ledgerAccountMapping?: Maybe<LedgerAccountMapping>
  readonly maxQuantity?: Maybe<Scalars['Long']>
  readonly minQuantity?: Maybe<Scalars['Long']>
  readonly minimumCommitBaseChargeId?: Maybe<Scalars['String']>
  readonly name: Scalars['String']
  readonly overageBaseChargeId?: Maybe<Scalars['String']>
  readonly percent?: Maybe<Scalars['BigDecimal']>
  readonly percentDerivedFrom?: Maybe<PercentDerivedFrom>
  readonly planId: Scalars['String']
  readonly priceTiers: ReadonlyArray<PriceTier>
  readonly rateCardId?: Maybe<Scalars['String']>
  readonly recognitionRule?: Maybe<RecognitionRule>
  readonly recurrence?: Maybe<Recurrence>
  readonly shouldTrackArr?: Maybe<Scalars['Boolean']>
  readonly targetPlanIds?: Maybe<ReadonlyArray<Scalars['String']>>
  readonly taxRate?: Maybe<TaxRate>
  readonly taxRateId?: Maybe<Scalars['String']>
  readonly type: ChargeType
  readonly unitOfMeasure?: Maybe<UnitOfMeasure>
  readonly unitOfMeasureId?: Maybe<Scalars['String']>
}

export enum ChargeType {
  /** ONE_TIME */
  OneTime = 'ONE_TIME',
  /** PERCENTAGE_OF */
  PercentageOf = 'PERCENTAGE_OF',
  /** PREPAID */
  Prepaid = 'PREPAID',
  /** RECURRING */
  Recurring = 'RECURRING',
  /** USAGE */
  Usage = 'USAGE',
}

export type CompanyContactJson = {
  readonly __typename?: 'CompanyContactJson'
  readonly address?: Maybe<AccountAddress>
  readonly email: Scalars['String']
  readonly firstName: Scalars['String']
  readonly lastName?: Maybe<Scalars['String']>
  readonly phoneNumber?: Maybe<Scalars['String']>
}

export type CompositeOrder = {
  readonly __typename?: 'CompositeOrder'
  readonly account?: Maybe<Account>
  readonly crmOpportunityId?: Maybe<Scalars['String']>
  readonly crmOpportunityName?: Maybe<Scalars['String']>
  readonly crmOpportunityStage?: Maybe<Scalars['String']>
  readonly crmOpportunityType?: Maybe<Scalars['String']>
  readonly documentMasterTemplateId?: Maybe<Scalars['String']>
  readonly entityId?: Maybe<Scalars['String']>
  readonly id: Scalars['String']
  readonly isPrimaryCompositeOrderForCrmOpportunity?: Maybe<Scalars['Boolean']>
  readonly orderIds: ReadonlyArray<Maybe<Scalars['String']>>
  readonly orders?: Maybe<ReadonlyArray<Maybe<OrderDetail>>>
  readonly status: OrderStatus
  readonly type: CompositeOrderType
}

export type CompositeOrderMinimal = {
  readonly __typename?: 'CompositeOrderMinimal'
  readonly compositeOrderId: Scalars['String']
  readonly createdOn?: Maybe<Scalars['String']>
  readonly crmOpportunityId?: Maybe<Scalars['String']>
  readonly crmOpportunityName?: Maybe<Scalars['String']>
  readonly crmOpportunityStage?: Maybe<Scalars['String']>
  readonly crmOpportunityType?: Maybe<Scalars['String']>
  readonly documentMasterTemplateId?: Maybe<Scalars['String']>
  readonly entityId?: Maybe<Scalars['String']>
  readonly isPrimaryCompositeOrderForCrmOpportunity?: Maybe<Scalars['Boolean']>
  readonly status: OrderStatus
  readonly type: CompositeOrderType
  readonly updatedOn?: Maybe<Scalars['String']>
}

export enum CompositeOrderType {
  /** CANCEL_SINGLE_SUBSCRIPTION_AND_RESTRUCTURE */
  CancelSingleSubscriptionAndRestructure = 'CANCEL_SINGLE_SUBSCRIPTION_AND_RESTRUCTURE',
  /** UPSELL_AND_EARLY_RENEWAL */
  UpsellAndEarlyRenewal = 'UPSELL_AND_EARLY_RENEWAL',
}

export enum ContactType {
  /** ACCOUNT_CONTACT */
  AccountContact = 'ACCOUNT_CONTACT',
  /** USER */
  User = 'USER',
  /** USER_GROUP */
  UserGroup = 'USER_GROUP',
}

export enum ContractTermsColumn {
  /** BILLING_CYCLE */
  BillingCycle = 'BILLING_CYCLE',
  /** CURRENCY */
  Currency = 'CURRENCY',
  /** END_DATE */
  EndDate = 'END_DATE',
  /** PAYMENT_TERM */
  PaymentTerm = 'PAYMENT_TERM',
  /** START_DATE */
  StartDate = 'START_DATE',
  /** SUBSCRIPTION_TERM */
  SubscriptionTerm = 'SUBSCRIPTION_TERM',
}

export enum ContractTermsTableStyle {
  /** LEFT_HEADER */
  LeftHeader = 'LEFT_HEADER',
  /** TOP_HEADER */
  TopHeader = 'TOP_HEADER',
}

export type CreditMemo = {
  readonly __typename?: 'CreditMemo'
  readonly account: Account
  readonly amount: Scalars['BigDecimal']
  readonly balance?: Maybe<Scalars['BigDecimal']>
  readonly billingContact?: Maybe<AccountContact>
  readonly createdFrom?: Maybe<Scalars['String']>
  readonly createdOn: Scalars['Long']
  readonly creditMemoDate?: Maybe<Scalars['Long']>
  readonly creditMemoNumber: Scalars['String']
  readonly creditReason?: Maybe<Scalars['String']>
  readonly currencyCode?: Maybe<Scalars['String']>
  readonly endDate?: Maybe<Scalars['Long']>
  readonly entityId?: Maybe<Scalars['String']>
  readonly erpId?: Maybe<Scalars['String']>
  readonly invoicesAppliedTo?: Maybe<ReadonlyArray<Maybe<Invoice>>>
  readonly isDeleted: Scalars['Boolean']
  readonly lineItems: ReadonlyArray<Maybe<CreditMemoLineItem>>
  readonly notes?: Maybe<Scalars['String']>
  readonly postedDate?: Maybe<Scalars['Long']>
  readonly refundsApplied?: Maybe<ReadonlyArray<Maybe<Refund>>>
  readonly settlementApplicationDetails?: Maybe<ReadonlyArray<Maybe<SettlementApplication>>>
  readonly startDate?: Maybe<Scalars['Long']>
  readonly status: CreditMemoStatus
  readonly updatedOn: Scalars['Long']
}

export type CreditMemoEmailResponse = {
  readonly __typename?: 'CreditMemoEmailResponse'
  readonly emailId: Scalars['String']
}

export type CreditMemoLineItem = {
  readonly __typename?: 'CreditMemoLineItem'
  readonly amount: Scalars['BigDecimal']
  readonly charge: Charge
  readonly chargeDetail: ChargeDetail
  readonly createdOn: Scalars['Long']
  readonly endDate: Scalars['Long']
  readonly isDeleted?: Maybe<Scalars['Boolean']>
  readonly plan: Plan
  readonly startDate: Scalars['Long']
  readonly taxAmount: Scalars['BigDecimal']
  readonly updatedOn: Scalars['Long']
}

export enum CreditMemoStatus {
  /** CLOSED */
  Closed = 'CLOSED',
  /** DRAFT */
  Draft = 'DRAFT',
  /** POSTED */
  Posted = 'POSTED',
}

export type CrmContact = {
  readonly __typename?: 'CrmContact'
  readonly action?: Maybe<CrmContactSyncAction>
  readonly crmType?: Maybe<CrmType>
  readonly email?: Maybe<Scalars['String']>
  readonly firstName?: Maybe<Scalars['String']>
  readonly id?: Maybe<Scalars['String']>
  readonly lastName?: Maybe<Scalars['String']>
  readonly mailingCity?: Maybe<Scalars['String']>
  readonly mailingCountry?: Maybe<Scalars['String']>
  readonly mailingPostalCode?: Maybe<Scalars['String']>
  readonly mailingState?: Maybe<Scalars['String']>
  readonly mailingStreet?: Maybe<Scalars['String']>
  readonly phone?: Maybe<Scalars['String']>
  readonly title?: Maybe<Scalars['String']>
}

export enum CrmContactSyncAction {
  /** INSERT */
  Insert = 'INSERT',
  /** UPDATE */
  Update = 'UPDATE',
}

export enum CrmType {
  /** HUBSPOT */
  Hubspot = 'HUBSPOT',
  /** SALESFORCE */
  Salesforce = 'SALESFORCE',
}

export type Currency = {
  readonly __typename?: 'Currency'
  readonly currencyCode: Scalars['String']
  readonly inUse: Scalars['Boolean']
  readonly supportedByTenant: Scalars['Boolean']
}

export type CurrencyConversionRate = {
  readonly __typename?: 'CurrencyConversionRate'
  readonly conversionRate: Scalars['BigDecimal']
  readonly effectiveDate: Scalars['Long']
  readonly fromCurrency: Scalars['String']
  readonly id?: Maybe<Scalars['String']>
  readonly isOverridden?: Maybe<Scalars['Boolean']>
  readonly roundingPrecision?: Maybe<Scalars['BigDecimal']>
  readonly roundingTreatment: RoundingMode
  readonly toCurrency: Scalars['String']
  readonly updatedBy?: Maybe<Scalars['String']>
}

export type CurrencyTypeSetting = {
  readonly __typename?: 'CurrencyTypeSetting'
  readonly catalogCurrency?: Maybe<Scalars['String']>
  readonly currencyTypeSettingId?: Maybe<Scalars['String']>
  readonly id?: Maybe<Scalars['String']>
  readonly planCurrencySettingType: PlanCurrencySettingType
}

export type CustomFieldDefault = {
  readonly __typename?: 'CustomFieldDefault'
  readonly selections?: Maybe<ReadonlyArray<Scalars['String']>>
  readonly value?: Maybe<Scalars['String']>
}

export type CustomFieldDefinition = {
  readonly __typename?: 'CustomFieldDefinition'
  readonly createdOn: Scalars['String']
  readonly defaultValue?: Maybe<CustomFieldDefault>
  readonly fieldLabel?: Maybe<Scalars['String']>
  readonly fieldName: Scalars['String']
  readonly fieldType: CustomFieldType
  readonly id: Scalars['String']
  readonly options?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>
  readonly parentObjectType: CustomFieldParentType
  readonly required?: Maybe<Scalars['Boolean']>
  readonly updatedOn: Scalars['String']
}

export type CustomFieldEntry = {
  readonly __typename?: 'CustomFieldEntry'
  readonly defaultValue?: Maybe<CustomFieldDefault>
  readonly id: Scalars['String']
  readonly label?: Maybe<Scalars['String']>
  readonly name: Scalars['String']
  readonly options?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>
  readonly required?: Maybe<Scalars['Boolean']>
  readonly selections?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>
  readonly source?: Maybe<CustomFieldSource>
  readonly type?: Maybe<CustomFieldType>
  readonly value?: Maybe<Scalars['String']>
}

export enum CustomFieldParentType {
  /** ACCOUNT */
  Account = 'ACCOUNT',
  /** CHARGE */
  Charge = 'CHARGE',
  /** INVOICE */
  Invoice = 'INVOICE',
  /** OPPORTUNITY */
  Opportunity = 'OPPORTUNITY',
  /** ORDER */
  Order = 'ORDER',
  /** ORDER_ITEM */
  OrderItem = 'ORDER_ITEM',
  /** PLAN */
  Plan = 'PLAN',
  /** SALES_ROOM */
  SalesRoom = 'SALES_ROOM',
  /** SUBSCRIPTION_ITEM */
  SubscriptionItem = 'SUBSCRIPTION_ITEM',
}

export type CustomFieldSettings = {
  readonly __typename?: 'CustomFieldSettings'
  readonly definitionsPerObject?: Maybe<Scalars['Int']>
}

export enum CustomFieldSource {
  /** SYSTEM */
  System = 'SYSTEM',
  /** USER */
  User = 'USER',
}

export enum CustomFieldType {
  /** MULTISELECT_PICKLIST */
  MultiselectPicklist = 'MULTISELECT_PICKLIST',
  /** PICKLIST */
  Picklist = 'PICKLIST',
  /** STRING */
  String = 'STRING',
}

export type CustomFieldWithParentReference = {
  readonly __typename?: 'CustomFieldWithParentReference'
  readonly customFieldEntries: ReadonlyArray<Maybe<CustomFieldEntry>>
  readonly parentObjectId: Scalars['String']
}

export type CustomPredefinedTemplateOnOrder = {
  readonly __typename?: 'CustomPredefinedTemplateOnOrder'
  readonly content: Scalars['String']
  readonly description?: Maybe<Scalars['String']>
  readonly id?: Maybe<Scalars['String']>
  readonly name: Scalars['String']
  readonly orderId: Scalars['String']
}

export enum CustomizationActionType {
  /** ADD_PLAN_APPLY_FILTER */
  AddPlanApplyFilter = 'ADD_PLAN_APPLY_FILTER',
}

export enum Cycle {
  /** MONTH */
  Month = 'MONTH',
  /** PAID_IN_FULL */
  PaidInFull = 'PAID_IN_FULL',
  /** QUARTER */
  Quarter = 'QUARTER',
  /** SEMI_ANNUAL */
  SemiAnnual = 'SEMI_ANNUAL',
  /** YEAR */
  Year = 'YEAR',
}

export type DataImport = {
  readonly __typename?: 'DataImport'
  readonly completedOn?: Maybe<Scalars['Long']>
  readonly domain: ImportDomain
  readonly entityIds?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>
  readonly fileName: Scalars['String']
  readonly fileSizeBytes: Scalars['Long']
  readonly fileType: ImportFileType
  readonly importId: Scalars['String']
  readonly importedBy: Scalars['String']
  readonly importedOn: Scalars['Long']
  readonly operation: ImportOperation
  readonly rowCount: Scalars['Long']
  readonly status: ImportStatus
}

export type DeliveryAttempt = {
  readonly __typename?: 'DeliveryAttempt'
  readonly errorMessage?: Maybe<Scalars['String']>
  readonly isSuccess: Scalars['Boolean']
  readonly statusCode?: Maybe<Scalars['Int']>
}

export type Discount = {
  readonly __typename?: 'Discount'
  readonly discountAmount?: Maybe<Scalars['BigDecimal']>
  readonly discountedPrice?: Maybe<Scalars['BigDecimal']>
  readonly name?: Maybe<Scalars['String']>
  readonly percent?: Maybe<Scalars['BigDecimal']>
  readonly status?: Maybe<DiscountStatus>
}

export type DiscountDetailJson = {
  readonly __typename?: 'DiscountDetailJson'
  readonly amount?: Maybe<Scalars['BigDecimal']>
  readonly discountAmount?: Maybe<Scalars['BigDecimal']>
  readonly discountedPrice?: Maybe<Scalars['BigDecimal']>
  readonly name?: Maybe<Scalars['String']>
  readonly percent?: Maybe<Scalars['BigDecimal']>
  readonly status?: Maybe<DiscountStatus>
}

export type DiscountJson = {
  readonly __typename?: 'DiscountJson'
  readonly description?: Maybe<Scalars['String']>
  readonly entityIds?: Maybe<ReadonlyArray<Scalars['String']>>
  readonly id?: Maybe<Scalars['String']>
  readonly inUse?: Maybe<Scalars['Boolean']>
  readonly name: Scalars['String']
  readonly percent: Scalars['BigDecimal']
  readonly status?: Maybe<DiscountStatus>
  readonly type: Scalars['String']
}

export enum DiscountStatus {
  /** ACTIVE */
  Active = 'ACTIVE',
  /** DEPRECATED */
  Deprecated = 'DEPRECATED',
}

export enum DocuSignEnvironmentType {
  /** DEMO */
  Demo = 'DEMO',
  /** PRODUCTION */
  Production = 'PRODUCTION',
}

export type DocuSignIntegrationResponse = {
  readonly __typename?: 'DocuSignIntegrationResponse'
  readonly clientId: Scalars['String']
  readonly completed: Scalars['Boolean']
  readonly environment: DocuSignEnvironmentType
  readonly integrationId: Scalars['String']
}

export type DocumentCustomContent = {
  readonly __typename?: 'DocumentCustomContent'
  readonly content: Scalars['String']
  readonly id?: Maybe<Scalars['String']>
  readonly orderId: Scalars['String']
  readonly title?: Maybe<Scalars['String']>
}

export type DocumentMasterTemplate = {
  readonly __typename?: 'DocumentMasterTemplate'
  readonly configuration?: Maybe<DocumentMasterTemplateConfiguration>
  readonly content: Scalars['String']
  readonly entityIds?: Maybe<ReadonlyArray<Scalars['String']>>
  readonly id?: Maybe<Scalars['String']>
  readonly inUse?: Maybe<Scalars['Boolean']>
  readonly isDefault?: Maybe<Scalars['Boolean']>
  readonly isFullHtml?: Maybe<Scalars['Boolean']>
  readonly name: Scalars['String']
  readonly status?: Maybe<DocumentMasterTemplateStatus>
  readonly type: DocumentTemplateType
}

export type DocumentMasterTemplateConfiguration = {
  readonly __typename?: 'DocumentMasterTemplateConfiguration'
  readonly contractTermsColumns: ReadonlyArray<Maybe<ContractTermsColumn>>
  readonly contractTermsTableStyle: ContractTermsTableStyle
  readonly dunningReminderType?: Maybe<DunningReminderType>
  readonly fontFamily: Scalars['String']
  readonly fontSize: Scalars['String']
  readonly headerStyle: HeaderStyle
  readonly lineItemColumns: ReadonlyArray<Maybe<LineItemColumn>>
  readonly lineItemTableStyle: LineItemTableStyle
  readonly primaryColor: Scalars['String']
  readonly secondaryColor: Scalars['String']
  readonly showArrByYears: Scalars['Boolean']
  readonly showBreakupByBillingCycle: Scalars['Boolean']
  readonly showLineBreakupByPredefinedDiscounts?: Maybe<Scalars['Boolean']>
  readonly showNextInvoiceAmount: Scalars['Boolean']
  readonly version: Scalars['Int']
}

export enum DocumentMasterTemplateStatus {
  /** ACTIVE */
  Active = 'ACTIVE',
  /** DRAFT */
  Draft = 'DRAFT',
}

export type DocumentSection = {
  readonly __typename?: 'DocumentSection'
  readonly entityIds?: Maybe<ReadonlyArray<Scalars['String']>>
  readonly id?: Maybe<Scalars['String']>
  readonly location: DocumentSectionLocation
  readonly name: Scalars['String']
  readonly title: Scalars['String']
}

export enum DocumentSectionLocation {
  /** AFTER_SIGNATURE */
  AfterSignature = 'AFTER_SIGNATURE',
  /** BEFORE_SIGNATURE */
  BeforeSignature = 'BEFORE_SIGNATURE',
}

export type DocumentTemplate = {
  readonly __typename?: 'DocumentTemplate'
  readonly content: Scalars['String']
  readonly description?: Maybe<Scalars['String']>
  readonly entityIds?: Maybe<ReadonlyArray<Scalars['String']>>
  readonly id: Scalars['String']
  readonly inUse?: Maybe<Scalars['Boolean']>
  readonly isUserSelectable?: Maybe<Scalars['Boolean']>
  readonly name: Scalars['String']
  readonly plans?: Maybe<ReadonlyArray<Maybe<Plan>>>
  readonly sectionUuid?: Maybe<Scalars['String']>
  readonly status: DocumentTemplateStatus
  readonly templateId?: Maybe<Scalars['String']>
  readonly type: DocumentTemplateType
  readonly updatedOn?: Maybe<Scalars['Long']>
  readonly version?: Maybe<Scalars['Int']>
}

export enum DocumentTemplateStatus {
  /** ACTIVE */
  Active = 'ACTIVE',
  /** DEPRECATED */
  Deprecated = 'DEPRECATED',
  /** DRAFT */
  Draft = 'DRAFT',
}

export enum DocumentTemplateType {
  /** CANCEL_AND_RESTRUCTURE */
  CancelAndRestructure = 'CANCEL_AND_RESTRUCTURE',
  /** CREDIT_MEMO */
  CreditMemo = 'CREDIT_MEMO',
  /** DUNNING */
  Dunning = 'DUNNING',
  /** EMAIL */
  Email = 'EMAIL',
  /** INVOICE */
  Invoice = 'INVOICE',
  /** ORDER */
  Order = 'ORDER',
  /** UPSELL_EARLY_RENEWAL */
  UpsellEarlyRenewal = 'UPSELL_EARLY_RENEWAL',
}

export enum DunningReminderType {
  /** AUTO_PAYMENT_FAILED */
  AutoPaymentFailed = 'AUTO_PAYMENT_FAILED',
  /** DUE_DATE */
  DueDate = 'DUE_DATE',
  /** MANUAL */
  Manual = 'MANUAL',
  /** MONTH_AFTER_DUE_DATE */
  MonthAfterDueDate = 'MONTH_AFTER_DUE_DATE',
  /** TWO_MONTHS_AFTER_DUE_DATE */
  TwoMonthsAfterDueDate = 'TWO_MONTHS_AFTER_DUE_DATE',
  /** TWO_WEEKS_AFTER_DUE_DATE */
  TwoWeeksAfterDueDate = 'TWO_WEEKS_AFTER_DUE_DATE',
  /** WEEK_AFTER_DUE_DATE */
  WeekAfterDueDate = 'WEEK_AFTER_DUE_DATE',
  /** WEEK_BEFORE_DUE_DATE */
  WeekBeforeDueDate = 'WEEK_BEFORE_DUE_DATE',
}

export type DunningSettingDetails = {
  readonly __typename?: 'DunningSettingDetails'
  readonly dunningTypeStatus?: Maybe<ReadonlyArray<Maybe<DunningTypeStatus>>>
  readonly isEnabled?: Maybe<Scalars['Boolean']>
}

export type DunningTypeStatus = {
  readonly __typename?: 'DunningTypeStatus'
  readonly dunningType: DunningReminderType
  readonly isEnabled: Scalars['Boolean']
}

export type ElectronicSignature = {
  readonly __typename?: 'ElectronicSignature'
  readonly accountSignatory?: Maybe<EmailContact>
  readonly additionalRecipients?: Maybe<ReadonlyArray<EmailContact>>
  readonly documentProvider: ElectronicSignatureProvider
  readonly externalDocumentId: Scalars['String']
  readonly id: Scalars['String']
  readonly initiatedBy: Scalars['String']
  readonly link?: Maybe<Scalars['String']>
  readonly orderId: Scalars['String']
  readonly status: ElectronicSignatureStatus
  readonly tenantSignatory?: Maybe<EmailContact>
}

export enum ElectronicSignatureAuditLogStatus {
  /** SENT */
  Sent = 'SENT',
  /** SIGNED */
  Signed = 'SIGNED',
  /** VIEWED */
  Viewed = 'VIEWED',
}

export enum ElectronicSignatureProvider {
  /** DOCUSIGN */
  Docusign = 'DOCUSIGN',
  /** PANDADOC */
  Pandadoc = 'PANDADOC',
}

export enum ElectronicSignatureStatus {
  /** COMPLETED */
  Completed = 'COMPLETED',
  /** FAILED */
  Failed = 'FAILED',
  /** PARTIALLY_SIGNED */
  PartiallySigned = 'PARTIALLY_SIGNED',
  /** PENDING */
  Pending = 'PENDING',
  /** SENT */
  Sent = 'SENT',
  /** VIEWED */
  Viewed = 'VIEWED',
  /** VOIDED */
  Voided = 'VOIDED',
}

export type EmailContact = {
  readonly __typename?: 'EmailContact'
  readonly email: Scalars['String']
  readonly entityIds?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>
  readonly id: Scalars['String']
  readonly name: Scalars['String']
  readonly type: ContactType
}

export type EmailContacts = {
  readonly __typename?: 'EmailContacts'
  readonly bccContacts: ReadonlyArray<Maybe<EmailContact>>
  readonly ccContacts: ReadonlyArray<Maybe<EmailContact>>
  readonly toContacts: ReadonlyArray<Maybe<EmailContact>>
}

export type EmailNotifierContact = {
  readonly __typename?: 'EmailNotifierContact'
  readonly email: Scalars['String']
  readonly firstName: Scalars['String']
  readonly id: Scalars['String']
  readonly isAccountContact: Scalars['Boolean']
  readonly isUser: Scalars['Boolean']
  readonly lastName?: Maybe<Scalars['String']>
}

export type EmailNotifiersDetail = {
  readonly __typename?: 'EmailNotifiersDetail'
  readonly bccContacts: ReadonlyArray<Maybe<EmailNotifierContact>>
  readonly ccContacts: ReadonlyArray<Maybe<EmailNotifierContact>>
  readonly toContacts: ReadonlyArray<Maybe<EmailNotifierContact>>
}

export enum EmailType {
  /** APPROVAL_FLOW */
  ApprovalFlow = 'APPROVAL_FLOW',
  /** CREDIT_MEMO_AVAILABLE */
  CreditMemoAvailable = 'CREDIT_MEMO_AVAILABLE',
  /** DUNNING */
  Dunning = 'DUNNING',
  /** ESIGN */
  Esign = 'ESIGN',
  /** HUBSPOT_ERROR */
  HubspotError = 'HUBSPOT_ERROR',
  /** INVOICE_POSTED */
  InvoicePosted = 'INVOICE_POSTED',
  /** LOGIN_LINK */
  LoginLink = 'LOGIN_LINK',
}

export type EnabledPlatformFeature = {
  readonly __typename?: 'EnabledPlatformFeature'
  readonly enabledAt?: Maybe<Scalars['Long']>
  readonly enabledByUserId?: Maybe<Scalars['String']>
  readonly platformFeature?: Maybe<PlatformFeature>
}

export type Entity = {
  readonly __typename?: 'Entity'
  readonly displayId?: Maybe<Scalars['String']>
  readonly id: Scalars['String']
  readonly name: Scalars['String']
}

export type EntityJson = {
  readonly __typename?: 'EntityJson'
  readonly accountReceivableContact?: Maybe<AccountReceivableContactJson>
  readonly companyContact?: Maybe<CompanyContactJson>
  readonly creditMemoConfig?: Maybe<NumberConfig>
  readonly displayId?: Maybe<Scalars['String']>
  readonly erpId?: Maybe<Scalars['String']>
  readonly functionalCurrency: Scalars['String']
  readonly id?: Maybe<Scalars['String']>
  readonly invoiceConfig?: Maybe<NumberConfig>
  readonly invoiceConfigId?: Maybe<Scalars['String']>
  readonly name: Scalars['String']
  readonly prorationMode: ProrationMode
  readonly prorationScheme: ProrationScheme
  readonly timezone?: Maybe<Scalars['String']>
  readonly wireInstruction?: Maybe<Scalars['String']>
}

export type EntityResolver = {
  readonly __typename?: 'EntityResolver'
  readonly accountReceivableContact?: Maybe<AccountReceivableContactJson>
  readonly companyContact?: Maybe<CompanyContactJson>
  readonly displayId?: Maybe<Scalars['String']>
  readonly erpId?: Maybe<Scalars['String']>
  readonly functionalCurrency: Scalars['String']
  readonly id: Scalars['String']
  readonly invoiceConfig: NumberConfig
  readonly invoiceConfigId: Scalars['String']
  readonly name: Scalars['String']
  readonly prorationMode: ProrationMode
  readonly prorationScheme: ProrationScheme
  readonly wireInstruction?: Maybe<Scalars['String']>
}

export type ErpInvoice = {
  readonly __typename?: 'ErpInvoice'
  readonly amountPaid: Scalars['BigDecimal']
  readonly amountRemaining: Scalars['BigDecimal']
  readonly erpInvoiceNumber: Scalars['String']
  readonly id: Scalars['String']
  readonly status: Scalars['String']
  readonly subtotal: Scalars['BigDecimal']
  readonly total: Scalars['BigDecimal']
}

export enum EscalationCycle {
  /** DAYS */
  Days = 'DAYS',
  /** HOURS */
  Hours = 'HOURS',
  /** MINUTES */
  Minutes = 'MINUTES',
  /** SECONDS */
  Seconds = 'SECONDS',
}

export type EscalationPolicy = {
  readonly __typename?: 'EscalationPolicy'
  readonly cycle: EscalationCycle
  readonly description?: Maybe<Scalars['String']>
  readonly id: Scalars['String']
  readonly name: Scalars['String']
  readonly status: EscalationPolicyStatus
  readonly step: Scalars['Long']
  readonly type: EscalationType
}

export enum EscalationPolicyStatus {
  /** ACTIVE */
  Active = 'ACTIVE',
  /** DRAFT */
  Draft = 'DRAFT',
}

export enum EscalationType {
  /** DELEGATE */
  Delegate = 'DELEGATE',
  /** NEXT_APPROVAL_STEP */
  NextApprovalStep = 'NEXT_APPROVAL_STEP',
}

export type EsignRequest = {
  readonly __typename?: 'EsignRequest'
  readonly accountSignatory?: Maybe<EmailContact>
  readonly additionalRecipients?: Maybe<ReadonlyArray<Maybe<EmailContact>>>
  readonly isComposite?: Maybe<Scalars['Boolean']>
  readonly orderId: Scalars['String']
  readonly tenantSignatory?: Maybe<EmailContact>
}

export enum ExternalSyncStatus {
  /** FAILED */
  Failed = 'FAILED',
  /** IN_PROGRESS */
  InProgress = 'IN_PROGRESS',
  /** NONE */
  None = 'NONE',
  /** SUCCEEDED */
  Succeeded = 'SUCCEEDED',
  /** WAITING */
  Waiting = 'WAITING',
}

export type FailedPayment = {
  readonly __typename?: 'FailedPayment'
  readonly amount: Scalars['BigDecimal']
  readonly createdOn: Scalars['Long']
  readonly invoiceNumber: Scalars['String']
  readonly note: Scalars['String']
  readonly paymentState: Scalars['String']
  readonly transactionId?: Maybe<Scalars['String']>
}

export enum Feature {
  /** ACCOUNTING_V1_0 */
  AccountingV1_0 = 'ACCOUNTING_V1_0',
  /** ADD_MISSED_RENEWAL_ORDER_LINES */
  AddMissedRenewalOrderLines = 'ADD_MISSED_RENEWAL_ORDER_LINES',
  /** ADD_NEGATIVE_ARR_FOR_CANCEL_ON_LAST_PLUS_ONE_DAY */
  AddNegativeArrForCancelOnLastPlusOneDay = 'ADD_NEGATIVE_ARR_FOR_CANCEL_ON_LAST_PLUS_ONE_DAY',
  /** ALLOW_ZERO_DURATION_ORDER_ITEMS */
  AllowZeroDurationOrderItems = 'ALLOW_ZERO_DURATION_ORDER_ITEMS',
  /** ALWAYS_ALLOW_EDITING_DISCOUNT_PERCENT */
  AlwaysAllowEditingDiscountPercent = 'ALWAYS_ALLOW_EDITING_DISCOUNT_PERCENT',
  /** ANROK */
  Anrok = 'ANROK',
  /** APPROVAL_ESCALATION */
  ApprovalEscalation = 'APPROVAL_ESCALATION',
  /** APPROVAL_FLOW_SUBMITTER_NOTE */
  ApprovalFlowSubmitterNote = 'APPROVAL_FLOW_SUBMITTER_NOTE',
  /** BULK_PAYMENT_UPLOAD_CSV */
  BulkPaymentUploadCsv = 'BULK_PAYMENT_UPLOAD_CSV',
  /** CANCEL_SINGLE_SUBSCRIPTION_AND_RESTRUCTURE */
  CancelSingleSubscriptionAndRestructure = 'CANCEL_SINGLE_SUBSCRIPTION_AND_RESTRUCTURE',
  /** CHANGE_DELTA_ARR_FOR_RENEWALS */
  ChangeDeltaArrForRenewals = 'CHANGE_DELTA_ARR_FOR_RENEWALS',
  /** CLM */
  Clm = 'CLM',
  /** CREDIT_BUCKETS */
  CreditBuckets = 'CREDIT_BUCKETS',
  /** CUSTOM_FIELDS_ON_APPROVAL_FLOWS */
  CustomFieldsOnApprovalFlows = 'CUSTOM_FIELDS_ON_APPROVAL_FLOWS',
  /** CUSTOM_FIELDS_SYNC_TO_CRM */
  CustomFieldsSyncToCrm = 'CUSTOM_FIELDS_SYNC_TO_CRM',
  /** CUSTOM_FIELD_DEFAULT */
  CustomFieldDefault = 'CUSTOM_FIELD_DEFAULT',
  /** DELETE_REVERT_SUBSCRIPTION */
  DeleteRevertSubscription = 'DELETE_REVERT_SUBSCRIPTION',
  /** DISTRIBUTED_ACCOUNT_CACHE */
  DistributedAccountCache = 'DISTRIBUTED_ACCOUNT_CACHE',
  /** DISTRIBUTED_CUSTOM_FIELDS_CACHE */
  DistributedCustomFieldsCache = 'DISTRIBUTED_CUSTOM_FIELDS_CACHE',
  /** DOCUMENT_LINK */
  DocumentLink = 'DOCUMENT_LINK',
  /** DOCUSIGN_SINGLE_SIGNER */
  DocusignSingleSigner = 'DOCUSIGN_SINGLE_SIGNER',
  /** DOCUSIGN_V2 */
  DocusignV2 = 'DOCUSIGN_V2',
  /** EDUCATION_PERFECT_CUSTOM_FIELDS */
  EducationPerfectCustomFields = 'EDUCATION_PERFECT_CUSTOM_FIELDS',
  /** EMAIL_APPROVAL */
  EmailApproval = 'EMAIL_APPROVAL',
  /** EMAIL_LINK_LOGIN */
  EmailLinkLogin = 'EMAIL_LINK_LOGIN',
  /** ENABLE_PAYMENT_JOB */
  EnablePaymentJob = 'ENABLE_PAYMENT_JOB',
  /** EXPENSE_LEDGER_ACCOUNT */
  ExpenseLedgerAccount = 'EXPENSE_LEDGER_ACCOUNT',
  /** EXPIRE_CANCEL_DELTA_ARR */
  ExpireCancelDeltaArr = 'EXPIRE_CANCEL_DELTA_ARR',
  /** FETCH_CLOSED_WON_OPPORTUNITY_FROM_CRM */
  FetchClosedWonOpportunityFromCrm = 'FETCH_CLOSED_WON_OPPORTUNITY_FROM_CRM',
  /** FLATFILE_BULK_OPERATIONS */
  FlatfileBulkOperations = 'FLATFILE_BULK_OPERATIONS',
  /** FLATFILE_IMPORT */
  FlatfileImport = 'FLATFILE_IMPORT',
  /** FLEXIBLE_BILLING_ANCHOR_DATE */
  FlexibleBillingAnchorDate = 'FLEXIBLE_BILLING_ANCHOR_DATE',
  /** FOREIGN_EXCHANGE */
  ForeignExchange = 'FOREIGN_EXCHANGE',
  /** GUIDED_SELLING */
  GuidedSelling = 'GUIDED_SELLING',
  /** HANDLE_NEGATIVE_REV_REC_EVENT */
  HandleNegativeRevRecEvent = 'HANDLE_NEGATIVE_REV_REC_EVENT',
  /** IMPORT_SELF_SERVE */
  ImportSelfServe = 'IMPORT_SELF_SERVE',
  /** INVOICE_GENERATION_JOB_REVAMP */
  InvoiceGenerationJobRevamp = 'INVOICE_GENERATION_JOB_REVAMP',
  /** INVOICE_PREVIEW_REPORT_V2 */
  InvoicePreviewReportV2 = 'INVOICE_PREVIEW_REPORT_V2',
  /** MAP_FOR_ONE_TIME_CHARGE */
  MapForOneTimeCharge = 'MAP_FOR_ONE_TIME_CHARGE',
  /** MASTER_TEMPLATE_EDITOR_V2 */
  MasterTemplateEditorV2 = 'MASTER_TEMPLATE_EDITOR_V2',
  /** MERGE_POC */
  MergePoc = 'MERGE_POC',
  /** MIN_MAX_PERCENT_OF_AMOUNT */
  MinMaxPercentOfAmount = 'MIN_MAX_PERCENT_OF_AMOUNT',
  /** MULTIPLE_RAMPS_FOR_AMENDMENT */
  MultipleRampsForAmendment = 'MULTIPLE_RAMPS_FOR_AMENDMENT',
  /** MULTI_ENTITY_ACCOUNTING */
  MultiEntityAccounting = 'MULTI_ENTITY_ACCOUNTING',
  /** MULTI_ENTITY_AUTH */
  MultiEntityAuth = 'MULTI_ENTITY_AUTH',
  /** NETSUITE_INVOICE_SYNC */
  NetsuiteInvoiceSync = 'NETSUITE_INVOICE_SYNC',
  /** ORDER_ANNUALIZED_AMOUNT */
  OrderAnnualizedAmount = 'ORDER_ANNUALIZED_AMOUNT',
  /** ORDER_CREATION_CUSTOMIZATION_ZEPPA */
  OrderCreationCustomizationZeppa = 'ORDER_CREATION_CUSTOMIZATION_ZEPPA',
  /** ORDER_CURRENCY */
  OrderCurrency = 'ORDER_CURRENCY',
  /** ORDER_CUSTOM_FIELDS */
  OrderCustomFields = 'ORDER_CUSTOM_FIELDS',
  /** ORDER_FORM_RAW_JSON_INJECTION */
  OrderFormRawJsonInjection = 'ORDER_FORM_RAW_JSON_INJECTION',
  /** ORDER_FORM_TEMPLATE_IDE */
  OrderFormTemplateIde = 'ORDER_FORM_TEMPLATE_IDE',
  /** ORDER_LINE_ARR_OVERRIDE */
  OrderLineArrOverride = 'ORDER_LINE_ARR_OVERRIDE',
  /** ORDER_LINE_ITEM_CUSTOM_FIELDS_CUSTOMIZATION */
  OrderLineItemCustomFieldsCustomization = 'ORDER_LINE_ITEM_CUSTOM_FIELDS_CUSTOMIZATION',
  /** ORDER_LINE_PLAN_GROUPING */
  OrderLinePlanGrouping = 'ORDER_LINE_PLAN_GROUPING',
  /** ORDER_PAGE_TIMELINE_REFACTOR */
  OrderPageTimelineRefactor = 'ORDER_PAGE_TIMELINE_REFACTOR',
  /** PAYMENT_BANK_ACCOUNT */
  PaymentBankAccount = 'PAYMENT_BANK_ACCOUNT',
  /** PAYMENT_WITH_BANK_ACCOUNT */
  PaymentWithBankAccount = 'PAYMENT_WITH_BANK_ACCOUNT',
  /** PLAN_ADDITION_CUSTOMIZATION */
  PlanAdditionCustomization = 'PLAN_ADDITION_CUSTOMIZATION',
  /** PLAN_ADDITION_CUSTOMIZATION_ZEPPA */
  PlanAdditionCustomizationZeppa = 'PLAN_ADDITION_CUSTOMIZATION_ZEPPA',
  /** POPULATE_MISSING_ITEMS */
  PopulateMissingItems = 'POPULATE_MISSING_ITEMS',
  /** PRECOMMIT_OVERAGE */
  PrecommitOverage = 'PRECOMMIT_OVERAGE',
  /** PRODUCT_RULES_DEMO */
  ProductRulesDemo = 'PRODUCT_RULES_DEMO',
  /** QUICK_ACCESS */
  QuickAccess = 'QUICK_ACCESS',
  /** REBASE_AMENDMENT */
  RebaseAmendment = 'REBASE_AMENDMENT',
  /** RENEWAL_REPLACE_PLAN */
  RenewalReplacePlan = 'RENEWAL_REPLACE_PLAN',
  /** RESTRICT_PREDEFINED_TERM_UPDATE_ON_ORDER_TO_ADMIN */
  RestrictPredefinedTermUpdateOnOrderToAdmin = 'RESTRICT_PREDEFINED_TERM_UPDATE_ON_ORDER_TO_ADMIN',
  /** REVENUE_RECOGNITION_V2_5 */
  RevenueRecognitionV2_5 = 'REVENUE_RECOGNITION_V2_5',
  /** REVREC_AMOUNT_BASED_COMPLETION */
  RevrecAmountBasedCompletion = 'REVREC_AMOUNT_BASED_COMPLETION',
  /** REV_REC_INVOICE */
  RevRecInvoice = 'REV_REC_INVOICE',
  /** SALES_ROOM */
  SalesRoom = 'SALES_ROOM',
  /** SEPARATE_REMOVED_LINES_FROM_RESTRUCTURED_LINES */
  SeparateRemovedLinesFromRestructuredLines = 'SEPARATE_REMOVED_LINES_FROM_RESTRUCTURED_LINES',
  /** SKIP_IMPORTED_ORDER_CRM_SYNC */
  SkipImportedOrderCrmSync = 'SKIP_IMPORTED_ORDER_CRM_SYNC',
  /** SUBSCRIPTION_CHARGE_RANK */
  SubscriptionChargeRank = 'SUBSCRIPTION_CHARGE_RANK',
  /** SUBSKRIBE_AI_AGENT */
  SubskribeAiAgent = 'SUBSKRIBE_AI_AGENT',
  /** TAX_PERCENT */
  TaxPercent = 'TAX_PERCENT',
  /** TENANT_UI_CUSTOMIZATION */
  TenantUiCustomization = 'TENANT_UI_CUSTOMIZATION',
  /** TERMS_PREVIEW */
  TermsPreview = 'TERMS_PREVIEW',
  /** TRANSACTIONAL_FOREIGN_EXCHANGE */
  TransactionalForeignExchange = 'TRANSACTIONAL_FOREIGN_EXCHANGE',
  /** UI_CONFIGURATION */
  UiConfiguration = 'UI_CONFIGURATION',
  /** UI_ES_TABLE_ORDER */
  UiEsTableOrder = 'UI_ES_TABLE_ORDER',
  /** UI_LABORATORY */
  UiLaboratory = 'UI_LABORATORY',
  /** UI_LINE_ITEMS_EDIT_TABLE */
  UiLineItemsEditTable = 'UI_LINE_ITEMS_EDIT_TABLE',
  /** UI_LINE_ITEMS_EDIT_TABLE_DGP */
  UiLineItemsEditTableDgp = 'UI_LINE_ITEMS_EDIT_TABLE_DGP',
  /** UI_MULTI_ENTITY_WATERFALL */
  UiMultiEntityWaterfall = 'UI_MULTI_ENTITY_WATERFALL',
  /** UI_SAVE_DGP_TABLE_STATE */
  UiSaveDgpTableState = 'UI_SAVE_DGP_TABLE_STATE',
  /** UNAPPLY_CREDIT_MEMO */
  UnapplyCreditMemo = 'UNAPPLY_CREDIT_MEMO',
  /** UPDATE_ORDER_START_DATE */
  UpdateOrderStartDate = 'UPDATE_ORDER_START_DATE',
  /** UPSELL_EARLY_RENEWAL */
  UpsellEarlyRenewal = 'UPSELL_EARLY_RENEWAL',
  /** USAGE_ARR */
  UsageArr = 'USAGE_ARR',
  /** VOID_INVOICE */
  VoidInvoice = 'VOID_INVOICE',
}

export type FeatureSetting = {
  readonly __typename?: 'FeatureSetting'
  readonly enabled: Scalars['Boolean']
  readonly name: Scalars['String']
}

export type FlatfileSpace = {
  readonly __typename?: 'FlatfileSpace'
  readonly domain: ImportDomain
  readonly name: Scalars['String']
  readonly spaceId: Scalars['String']
}

export type FlatfileSpaceResponse = {
  readonly __typename?: 'FlatfileSpaceResponse'
  readonly data: FlatfileSpaceResponseData
}

export type FlatfileSpaceResponseData = {
  readonly __typename?: 'FlatfileSpaceResponseData'
  readonly accessToken: Scalars['String']
  readonly environmentId: Scalars['String']
  readonly guestLink: Scalars['String']
  readonly id: Scalars['String']
  readonly name: Scalars['String']
}

export type GqlMutation = {
  readonly __typename?: 'GqlMutation'
  readonly abandonAccountingPeriodClose: AccountingPeriod
  readonly activatePlan: PlanDetail
  readonly addAccount: Account
  readonly addBillingEvent: BillingEvents
  readonly addBulkInvoiceRun: BulkInvoiceRun
  readonly addCharge: ChargeDetail
  readonly addEntity: EntityJson
  readonly addEscalationPolicy: EscalationPolicy
  readonly addIntegration: IntegrationDetail
  readonly addMailingContact: AccountContact
  readonly addMessageToCLMThread: ClmChatMessageDetail
  readonly addPlan: PlanDetail
  readonly addProduct: ProductDetail
  readonly addProductCategory: ProductCategory
  readonly addRawUsages: UsageBatchInsertResult
  readonly addSamlIntegration: Scalars['Boolean']
  readonly addSubscriptionChargeAlias: SubscriptionChargeAlias
  readonly addTenant: TenantCreationResponseJson
  readonly addTenantSignatory: Scalars['Boolean']
  readonly addUnitOfMeasure: UnitOfMeasure
  readonly applyCreditMemo: ReadonlyArray<SettlementApplication>
  readonly applyManualPayment: ReadonlyArray<SettlementApplication>
  readonly approvalFlowStatusChangeWithCode: ApprovalWithCodeResponse
  readonly completeDocuSignIntegration: Scalars['Boolean']
  readonly completeHubSpotIntegration?: Maybe<HubSpotIntegration>
  readonly completeIntegration: Integration
  readonly completeSalesforceIntegration: Scalars['Boolean']
  readonly completeStripeConnectIntegration: Scalars['Boolean']
  readonly convertNegativeDraftInvoice: CreditMemo
  readonly createAccount?: Maybe<AccountDetail>
  readonly createCLMThread: ClmThreadCreationDetail
  readonly createCompositeOrderDocument: Scalars['Boolean']
  readonly createCustomFieldDefinition: CustomFieldDefinition
  readonly createFlatfileWorkbook: FlatfileSpaceResponse
  readonly createMergeLinkToken?: Maybe<Scalars['String']>
  readonly createOrderDocument: Scalars['Boolean']
  readonly createOrderDocumentForSalesRoom: Scalars['Boolean']
  readonly createPaymentIntent: StripeIntentResponse
  readonly createProposal: Proposal
  readonly createRefund: Refund
  readonly createSalesRoomLink: SalesRoomLink
  readonly createSetupIntent: StripeSetupIntentResponse
  readonly createStandaloneCreditMemo: CreditMemo
  readonly createUpsellWithEarlyRenewal: CompositeOrder
  readonly deleteAccount: Account
  readonly deleteAccountContact: AccountContact
  readonly deleteApprovalFlow: ApprovalFlowDetail
  readonly deleteApprovalRole: ApprovalRole
  readonly deleteApprovalSegment: ApprovalSegment
  readonly deleteBillingEvent: BillingEvents
  readonly deleteCancelAndRestructure: CancelAndRestructureOrder
  readonly deleteCharge: ChargeDetail
  readonly deleteCreditMemo: CreditMemo
  readonly deleteCustomFieldDefinition: CustomFieldDefinition
  readonly deleteCustomPredefinedTemplateOnOrder: CustomPredefinedTemplateOnOrder
  readonly deleteDiscount: DiscountJson
  readonly deleteDocuSignIntegration: DocuSignIntegrationResponse
  readonly deleteDocumentMasterTemplate: DocumentMasterTemplate
  readonly deleteDocumentTemplate: DocumentTemplate
  readonly deleteEntity: Scalars['Boolean']
  readonly deleteFlatfileSpace: Scalars['Boolean']
  readonly deleteHubSpotIntegration: Scalars['Boolean']
  readonly deleteIntegration: Integration
  readonly deleteInvoice: Invoice
  readonly deleteJournalEntriesInAccountingPeriodFromErp: Scalars['String']
  readonly deleteLedgerAccount: LedgerAccount
  readonly deleteMailingContact: AccountContact
  readonly deleteOrder: OrderDetail
  readonly deletePaymentBankAccount: PaymentBankAccountJson
  readonly deletePlan: PlanDetail
  readonly deletePlanGroup: PlanGroupInterface
  readonly deletePriceAttribute: PriceAttribute
  readonly deleteProduct: ProductDetail
  readonly deleteProductCategory: ProductCategory
  readonly deleteRecognitionRule: RecognitionRule
  readonly deleteSalesforceIntegration: Scalars['Boolean']
  readonly deleteStripeConnectIntegration: PaymentStripeConnectIntegration
  readonly deleteSubscription: Scalars['Boolean']
  readonly deleteSubscriptionChargeAlias: SubscriptionChargeAlias
  readonly deleteTaxRate: TaxRate
  readonly deleteTenantSignatory: Scalars['Boolean']
  readonly deleteUnitOfMeasure: UnitOfMeasure
  readonly deleteUpsellWithEarlyRenewal: CompositeOrder
  readonly deleteUser: User
  readonly deleteUserGroup: UserGroupDetail
  readonly deprecatePlan: PlanDetail
  readonly disableSalesRoomLink: Scalars['Boolean']
  readonly disableUser: User
  readonly duplicatePlan: PlanDetail
  readonly emailLinkForLogin: Scalars['Boolean']
  readonly enableAccountingPlatformFeature: EnabledPlatformFeature
  /** @deprecated Use enableAccountingPlatformFeature */
  readonly enablePlatformFeature: EnabledPlatformFeature
  readonly enableUser: User
  readonly generateAccountPaymentLink?: Maybe<AccountPaymentManagementLinkResponse>
  readonly generateApiKeyV3: Scalars['String']
  readonly generateCancelAndRestructure: CancelAndRestructureOrder
  readonly generateDocumentLink: Scalars['String']
  readonly generateDocumentLinkIdForCLM: Scalars['String']
  readonly generateInvoice?: Maybe<Invoice>
  readonly generateInvoicePaymentLink?: Maybe<InvoicePaymentManagementLinkGqlResponse>
  readonly generateJournalEntries: Scalars['String']
  readonly generatePaymentLink: Scalars['String']
  readonly generateReport: ReportJobResponse
  readonly getOrCreateAccountByCrmId?: Maybe<Account>
  readonly importAccountByCrmId?: Maybe<Account>
  readonly initiateAccountingPeriodClose: AccountingPeriod
  readonly initiateDocuSignIntegration: Scalars['String']
  readonly initiateHubSpotIntegration: Scalars['String']
  readonly initiateSalesforceIntegration: Scalars['String']
  readonly initiateStripeConnectIntegration: PaymentStripeConnectIntegrationSetupResponse
  readonly mutateSuccessfully: Scalars['String']
  readonly mutateWithError: Scalars['String']
  readonly pingAvalara: Scalars['String']
  readonly postCreditMemo: CreditMemo
  readonly postInvoice: Invoice
  readonly postInvoicesForBulkInvoiceRun: BulkInvoiceRun
  readonly processImport: DataImport
  readonly processMergePublicToken?: Maybe<Scalars['Boolean']>
  readonly reEnableAutomaticPayments: Scalars['Boolean']
  readonly reactivatePlan: PlanDetail
  readonly rebaseAmendment: OrderDetail
  readonly recognizeRevenue: Scalars['Boolean']
  readonly renewApiKeyForFlatfileSpace: Scalars['Boolean']
  readonly resendCognitoInvitationEmailForExistingUser: Scalars['Boolean']
  readonly resendEsignatureDocumentV2: Scalars['Boolean']
  readonly resendNotification?: Maybe<NotificationInstanceWithAttempts>
  readonly resetOpportunityClosedState: Opportunity
  readonly resolveThread: Scalars['Boolean']
  readonly retryTenantJobAsAdmin: TenantJob
  readonly revertSubscription: Scalars['Boolean']
  readonly revertToDraft: PlanDetail
  readonly revokeApiKeyV3: Scalars['Boolean']
  readonly sendCreditMemoEmail: CreditMemoEmailResponse
  readonly sendEmailForDocusign: Scalars['Boolean']
  readonly sendEmailForEsign: Scalars['Boolean']
  readonly sendEmailForEsignSalesRoom: Scalars['Boolean']
  readonly sendInvoiceEmail: InvoiceEmailResponse
  readonly setupHubSpotCustomObjects: ReadonlyArray<HubSpotSetupMessage>
  readonly setupHubSpotCustomProperties: ReadonlyArray<HubSpotSetupMessage>
  readonly specifyCurrentAccountingPeriod: AccountingPeriod
  readonly subscribeExistingNotificationTargetToEvent: Notification
  readonly syncCompositeOrderToCrm: CompositeOrder
  readonly syncCreditMemoToErp: Scalars['String']
  readonly syncDealInformationToHubSpot: Scalars['Boolean']
  readonly syncInvoiceToErp: Scalars['String']
  readonly syncJournalEntriesInAccountingPeriodToErp: Scalars['String']
  readonly syncOrderToSalesforce: OrderDetail
  readonly testAnrokIntegration: Scalars['Boolean']
  readonly testTaxJarIntegration: Scalars['Boolean']
  readonly unEnrollFromAutomaticPayments: Scalars['Boolean']
  readonly unapplyCreditMemo: ReadonlyArray<SettlementApplication>
  readonly unsubscribeNotificationEventForTarget: Notification
  readonly unsubscribeNotificationTarget: Notification
  /** @deprecated Deprecated */
  readonly updateAccount: Account
  readonly updateAccountReceivableContact: AccountReceivableContactJson
  readonly updateAccountSupportedPaymentTypes: Scalars['Boolean']
  readonly updateAccountV2?: Maybe<AccountDetail>
  readonly updateAccountingPeriodStatus: AccountingPeriod
  /** @deprecated Use updatePaymentConfiguration instead */
  readonly updateAutomaticPaymentConfiguration: PaymentConfiguration
  readonly updateCancelAndRestructureOrderStatus: CancelAndRestructureOrder
  readonly updateCharge: ChargeDetail
  readonly updateChargePartial: ChargeDetail
  readonly updateCompositeOrderFields: CompositeOrder
  readonly updateCompositeOrderStatus: CompositeOrder
  readonly updateCustomFieldDefinition: CustomFieldDefinition
  readonly updateCustomFields: ReadonlyArray<CustomFieldEntry>
  readonly updateCustomFieldsForSalesRoom: ReadonlyArray<CustomFieldEntry>
  readonly updateDiscountStatus: DiscountJson
  readonly updateDocumentMasterTemplateStatus: DocumentMasterTemplate
  readonly updateDocumentTemplateStatus: DocumentTemplate
  readonly updateDraftCreditMemo: CreditMemo
  readonly updateDunningSetting: DunningSettingDetails
  readonly updateEntity: EntityJson
  readonly updateEscalationPolicy: EscalationPolicy
  readonly updateInvoice: Invoice
  readonly updateOrderApprovalState?: Maybe<OrderDetail>
  readonly updateOrderContacts: Scalars['Boolean']
  readonly updateOrderExpiryDurationInDays?: Maybe<Scalars['Int']>
  readonly updateOrderStatus: OrderStatusUpdateResponse
  readonly updatePaymentConfiguration: PaymentConfiguration
  readonly updatePaymentIntent: Scalars['Boolean']
  readonly updatePaymentTermSettings: PaymentTermSettings
  readonly updatePlan: PlanDetail
  readonly updatePrimaryCompositeOrderIdForCrmOpportunity: CompositeOrder
  readonly updatePrimaryOrderIdForHubSpotOpportunity: OrderDetail
  readonly updatePrimaryOrderIdForSalesforceOpportunity: OrderDetail
  readonly updateProduct: ProductDetail
  readonly updateProductCategory: ProductCategory
  readonly updateSetting: Setting
  readonly updateSigningOrder?: Maybe<SigningOrder>
  readonly updateSubscriptionAttributes: Subscription
  readonly updateSupportedCurrencies: ReadonlyArray<Currency>
  readonly updateTenantDetails: TenantDetails
  readonly updateWireInstruction: Scalars['Boolean']
  readonly upsertAccount: AccountDetail
  readonly upsertAccountContact: AccountContact
  readonly upsertAccountContactForSalesRoom: AccountContact
  readonly upsertAmendment: OrderDetail
  readonly upsertAnrokIntegration: Integration
  readonly upsertApprovalFlow: ApprovalFlowDetail
  readonly upsertApprovalFlowSubmitterNote: ApprovalFlowSubmitterNote
  readonly upsertApprovalRole: ApprovalRole
  readonly upsertApprovalSegment: ApprovalSegment
  readonly upsertAvalaraIntegration: AvalaraIntegration
  readonly upsertCancelAndRestructure: CancelAndRestructureOrder
  readonly upsertChargeDefaultAttributeReferences: ChargeDefaultAttributeReferences
  readonly upsertCrmContacts: ReadonlyArray<UpsertCrmContactResponse>
  readonly upsertCurrencyConversionRate: CurrencyConversionRate
  readonly upsertCurrencyTypeSetting: CurrencyTypeSetting
  readonly upsertCustomPredefinedTemplateOnOrder: CustomPredefinedTemplateOnOrder
  readonly upsertDiscount: DiscountJson
  readonly upsertDocumentMasterTemplate: DocumentMasterTemplate
  readonly upsertDocumentMasterTemplateV2: DocumentMasterTemplate
  readonly upsertDocumentSection: DocumentSection
  readonly upsertDocumentTemplate: DocumentTemplate
  readonly upsertEsignDetails: EsignRequest
  readonly upsertLedgerAccount: LedgerAccount
  readonly upsertNotificationTargetAndSubscriptions: Notification
  readonly upsertOrder: OrderDetail
  readonly upsertPaymentBankAccount: PaymentBankAccountJson
  /** @deprecated Deprecated */
  readonly upsertPlan: PlanDetail
  readonly upsertPlanGroup: PlanGroupInterface
  readonly upsertPriceAttribute: PriceAttribute
  readonly upsertProductCategory: ProductCategory
  readonly upsertRateCard: RateCard
  readonly upsertRecognitionRule: RecognitionRule
  readonly upsertRenewalOrder: OrderDetail
  readonly upsertTaxJarIntegration: TaxJarIntegration
  readonly upsertTaxRate: TaxRate
  readonly upsertUnitOfMeasure: UnitOfMeasure
  /** @deprecated Deprecated */
  readonly upsertUser: User
  readonly upsertUserGroup: UserGroupDetail
  readonly upsertUserV2: User
  readonly voidEsignatureDocumentV2: Scalars['String']
  readonly voidInvoice: Invoice
  readonly voidInvoiceOnErp: Scalars['String']
  readonly voidPayment: ReadonlyArray<SettlementApplication>
}

export type GqlMutationAbandonAccountingPeriodCloseArgs = {
  accountingPeriodId: Scalars['String']
}

export type GqlMutationActivatePlanArgs = {
  id: Scalars['String']
}

export type GqlMutationAddAccountArgs = {
  account: InputAccount
}

export type GqlMutationAddBillingEventArgs = {
  billingEventInput: InputBillingEventInput
}

export type GqlMutationAddBulkInvoiceRunArgs = {
  bulkInvoiceRunInput: InputBulkInvoiceRunInput
}

export type GqlMutationAddChargeArgs = {
  charge: InputCharge
  planId: Scalars['String']
}

export type GqlMutationAddEntityArgs = {
  entity: InputEntityJson
}

export type GqlMutationAddEscalationPolicyArgs = {
  escalationPolicy: InputAddEscalationPolicyRequest
}

export type GqlMutationAddIntegrationArgs = {
  integrationRequest: InputIntegrationRequest
}

export type GqlMutationAddMailingContactArgs = {
  contactId: Scalars['String']
  subscriptionId: Scalars['String']
}

export type GqlMutationAddMessageToClmThreadArgs = {
  clmThreadMessageRequest: InputClmChatMessageDetail
}

export type GqlMutationAddPlanArgs = {
  addPlanRequest: InputAddPlanRequest
}

export type GqlMutationAddProductArgs = {
  description?: InputMaybe<Scalars['String']>
  displayName?: InputMaybe<Scalars['String']>
  entityIds?: InputMaybe<ReadonlyArray<Scalars['String']>>
  externalId?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  productCategoryId?: InputMaybe<Scalars['String']>
  sku?: InputMaybe<Scalars['String']>
}

export type GqlMutationAddProductCategoryArgs = {
  description: Scalars['String']
  entityIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>
  name: Scalars['String']
}

export type GqlMutationAddRawUsagesArgs = {
  rawUsagesData: InputRawUsagesData
}

export type GqlMutationAddSamlIntegrationArgs = {
  authSamlIntegration: InputAuthSamlIntegrationRequest
}

export type GqlMutationAddSubscriptionChargeAliasArgs = {
  aliasId: Scalars['String']
  chargeId: Scalars['String']
  subscriptionId: Scalars['String']
}

export type GqlMutationAddTenantArgs = {
  tenant: InputTenant
}

export type GqlMutationAddTenantSignatoryArgs = {
  tenantSignatory: InputEsignTenantSignatory
}

export type GqlMutationAddUnitOfMeasureArgs = {
  unitOfMeasure: InputUnitOfMeasureRequest
}

export type GqlMutationApplyCreditMemoArgs = {
  ApplyCreditMemoRequest: InputApplyCreditMemoRequest
}

export type GqlMutationApplyManualPaymentArgs = {
  ApplyPaymentRequest: InputApplyPaymentRequest
}

export type GqlMutationApprovalFlowStatusChangeWithCodeArgs = {
  code: Scalars['String']
  state: Scalars['Boolean']
}

export type GqlMutationCompleteDocuSignIntegrationArgs = {
  code: Scalars['String']
  state: Scalars['String']
}

export type GqlMutationCompleteHubSpotIntegrationArgs = {
  code: Scalars['String']
  redirect_uri: Scalars['String']
  state: Scalars['String']
}

export type GqlMutationCompleteIntegrationArgs = {
  authCode: Scalars['String']
  realmId?: InputMaybe<Scalars['String']>
  state: Scalars['String']
}

export type GqlMutationCompleteSalesforceIntegrationArgs = {
  code: Scalars['String']
  redirect_uri: Scalars['String']
  state: Scalars['String']
}

export type GqlMutationCompleteStripeConnectIntegrationArgs = {
  code: Scalars['String']
  scope: Scalars['String']
  state: Scalars['String']
}

export type GqlMutationConvertNegativeDraftInvoiceArgs = {
  invoiceNumber: Scalars['String']
}

export type GqlMutationCreateAccountArgs = {
  accountInput: InputCreateAccountInput
}

export type GqlMutationCreateClmThreadArgs = {
  clmThread: InputClmCreateThreadRequest
}

export type GqlMutationCreateCompositeOrderDocumentArgs = {
  id: Scalars['String']
}

export type GqlMutationCreateCustomFieldDefinitionArgs = {
  customFieldDefinition: InputCustomFieldDefinitionCreate
}

export type GqlMutationCreateFlatfileWorkbookArgs = {
  domain?: InputMaybe<ImportDomain>
}

export type GqlMutationCreateMergeLinkTokenArgs = {
  integrationType: IntegrationTargetService
}

export type GqlMutationCreateOrderDocumentArgs = {
  orderId: Scalars['String']
}

export type GqlMutationCreateOrderDocumentForSalesRoomArgs = {
  linkId: Scalars['String']
}

export type GqlMutationCreatePaymentIntentArgs = {
  paymentLinkId: Scalars['String']
}

export type GqlMutationCreateProposalArgs = {
  createProposalRequest: InputCreateProposalRequest
}

export type GqlMutationCreateRefundArgs = {
  refundRequest: InputRefundRequest
}

export type GqlMutationCreateSalesRoomLinkArgs = {
  orderId: Scalars['String']
}

export type GqlMutationCreateSetupIntentArgs = {
  paymentLinkId: Scalars['String']
}

export type GqlMutationCreateStandaloneCreditMemoArgs = {
  creditMemo: InputCreditMemoRequest
}

export type GqlMutationCreateUpsellWithEarlyRenewalArgs = {
  amendAndRenewRequest?: InputMaybe<InputAmendAndRenewCreateRequest>
  crmOpportunityId?: InputMaybe<Scalars['String']>
  subscriptionId: Scalars['String']
}

export type GqlMutationDeleteAccountArgs = {
  id: Scalars['String']
}

export type GqlMutationDeleteAccountContactArgs = {
  id: Scalars['String']
}

export type GqlMutationDeleteApprovalFlowArgs = {
  id: Scalars['String']
}

export type GqlMutationDeleteApprovalRoleArgs = {
  id: Scalars['String']
}

export type GqlMutationDeleteApprovalSegmentArgs = {
  id: Scalars['String']
}

export type GqlMutationDeleteBillingEventArgs = {
  id: Scalars['String']
}

export type GqlMutationDeleteCancelAndRestructureArgs = {
  id: Scalars['String']
}

export type GqlMutationDeleteChargeArgs = {
  chargeId: Scalars['String']
  planId: Scalars['String']
}

export type GqlMutationDeleteCreditMemoArgs = {
  creditMemoNumber: Scalars['String']
}

export type GqlMutationDeleteCustomFieldDefinitionArgs = {
  id: Scalars['String']
}

export type GqlMutationDeleteCustomPredefinedTemplateOnOrderArgs = {
  orderId: Scalars['String']
  templateId: Scalars['String']
}

export type GqlMutationDeleteDiscountArgs = {
  id: Scalars['String']
}

export type GqlMutationDeleteDocumentMasterTemplateArgs = {
  id: Scalars['String']
}

export type GqlMutationDeleteDocumentTemplateArgs = {
  id: Scalars['String']
}

export type GqlMutationDeleteEntityArgs = {
  entityId: Scalars['String']
}

export type GqlMutationDeleteFlatfileSpaceArgs = {
  spaceId?: InputMaybe<Scalars['String']>
}

export type GqlMutationDeleteIntegrationArgs = {
  integrationId: Scalars['String']
}

export type GqlMutationDeleteInvoiceArgs = {
  invoiceNumber: Scalars['String']
}

export type GqlMutationDeleteJournalEntriesInAccountingPeriodFromErpArgs = {
  accountingPeriodId: Scalars['String']
}

export type GqlMutationDeleteLedgerAccountArgs = {
  id: Scalars['String']
}

export type GqlMutationDeleteMailingContactArgs = {
  contactId: Scalars['String']
  subscriptionId: Scalars['String']
}

export type GqlMutationDeleteOrderArgs = {
  id: Scalars['String']
}

export type GqlMutationDeletePaymentBankAccountArgs = {
  id: Scalars['String']
}

export type GqlMutationDeletePlanArgs = {
  id: Scalars['String']
}

export type GqlMutationDeletePlanGroupArgs = {
  id: Scalars['String']
}

export type GqlMutationDeletePriceAttributeArgs = {
  id: Scalars['String']
}

export type GqlMutationDeleteProductArgs = {
  id: Scalars['String']
}

export type GqlMutationDeleteProductCategoryArgs = {
  id: Scalars['String']
}

export type GqlMutationDeleteRecognitionRuleArgs = {
  id: Scalars['String']
}

export type GqlMutationDeleteSubscriptionArgs = {
  subscriptionId: Scalars['String']
  version: Scalars['Int']
}

export type GqlMutationDeleteSubscriptionChargeAliasArgs = {
  aliasId: Scalars['String']
}

export type GqlMutationDeleteTaxRateArgs = {
  id: Scalars['String']
}

export type GqlMutationDeleteTenantSignatoryArgs = {
  userId: Scalars['String']
}

export type GqlMutationDeleteUnitOfMeasureArgs = {
  id: Scalars['String']
}

export type GqlMutationDeleteUpsellWithEarlyRenewalArgs = {
  id: Scalars['String']
}

export type GqlMutationDeleteUserArgs = {
  id: Scalars['String']
}

export type GqlMutationDeleteUserGroupArgs = {
  id: Scalars['String']
}

export type GqlMutationDeprecatePlanArgs = {
  id: Scalars['String']
}

export type GqlMutationDisableSalesRoomLinkArgs = {
  linkId: Scalars['String']
}

export type GqlMutationDisableUserArgs = {
  id: Scalars['String']
}

export type GqlMutationDuplicatePlanArgs = {
  id: Scalars['String']
}

export type GqlMutationEmailLinkForLoginArgs = {
  email: Scalars['String']
}

export type GqlMutationEnablePlatformFeatureArgs = {
  platformFeature: PlatformFeature
}

export type GqlMutationEnableUserArgs = {
  id: Scalars['String']
}

export type GqlMutationGenerateAccountPaymentLinkArgs = {
  accountId: Scalars['String']
}

export type GqlMutationGenerateApiKeyV3Args = {
  apiKey: InputApiKey
}

export type GqlMutationGenerateCancelAndRestructureArgs = {
  subscriptionId: Scalars['String']
}

export type GqlMutationGenerateDocumentLinkArgs = {
  orderId: Scalars['String']
}

export type GqlMutationGenerateDocumentLinkIdForClmArgs = {
  orderId: Scalars['String']
}

export type GqlMutationGenerateInvoiceArgs = {
  invoiceChargeInclusionOption: InvoiceChargeInclusionOption
  invoiceDate?: InputMaybe<Scalars['Long']>
  subscriptionId: Scalars['String']
  targetDate: Scalars['Long']
}

export type GqlMutationGenerateInvoicePaymentLinkArgs = {
  accountId: Scalars['String']
  invoiceNumber: Scalars['String']
}

export type GqlMutationGenerateJournalEntriesArgs = {
  accountingPeriodId: Scalars['String']
}

export type GqlMutationGeneratePaymentLinkArgs = {
  accountId: Scalars['String']
  actionType: AddPaymentMethodActionType
}

export type GqlMutationGenerateReportArgs = {
  reportRequest: InputPredefinedReportRequest
}

export type GqlMutationGetOrCreateAccountByCrmIdArgs = {
  accountCrmId: Scalars['String']
  opportunityCrmId?: InputMaybe<Scalars['String']>
}

export type GqlMutationImportAccountByCrmIdArgs = {
  accountCrmId: Scalars['String']
}

export type GqlMutationInitiateAccountingPeriodCloseArgs = {
  accountingPeriodId: Scalars['String']
}

export type GqlMutationInitiateDocuSignIntegrationArgs = {
  docuSignIntegrationRequest: InputDocuSignIntegrationRequest
}

export type GqlMutationInitiateHubSpotIntegrationArgs = {
  email?: InputMaybe<Scalars['String']>
}

export type GqlMutationInitiateSalesforceIntegrationArgs = {
  salesforceIntegration: InputSalesforceIntegration
}

export type GqlMutationMutateSuccessfullyArgs = {
  result: Scalars['String']
}

export type GqlMutationMutateWithErrorArgs = {
  message: Scalars['String']
}

export type GqlMutationPingAvalaraArgs = {
  accountId: Scalars['String']
  accountLicenseKey: Scalars['String']
  companyCode?: InputMaybe<Scalars['String']>
  sandboxEnvironment: Scalars['Boolean']
}

export type GqlMutationPostCreditMemoArgs = {
  creditMemoNumber: Scalars['String']
}

export type GqlMutationPostInvoiceArgs = {
  invoiceNumber: Scalars['String']
}

export type GqlMutationPostInvoicesForBulkInvoiceRunArgs = {
  bulkInvoiceRunId: Scalars['String']
  subscriptionIdExclusionList?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>
}

export type GqlMutationProcessImportArgs = {
  dataImportId?: InputMaybe<Scalars['String']>
}

export type GqlMutationProcessMergePublicTokenArgs = {
  integrationType: IntegrationTargetService
  publicToken: Scalars['String']
}

export type GqlMutationReEnableAutomaticPaymentsArgs = {
  paymentLinkId: Scalars['String']
  paymentLinkType: PaymentManagementLinkType
}

export type GqlMutationReactivatePlanArgs = {
  id: Scalars['String']
}

export type GqlMutationRebaseAmendmentArgs = {
  orderId: Scalars['String']
}

export type GqlMutationRenewApiKeyForFlatfileSpaceArgs = {
  spaceId?: InputMaybe<Scalars['String']>
}

export type GqlMutationResendCognitoInvitationEmailForExistingUserArgs = {
  email: Scalars['String']
}

export type GqlMutationResendEsignatureDocumentV2Args = {
  id: Scalars['String']
}

export type GqlMutationResendNotificationArgs = {
  notificationInstanceId?: InputMaybe<Scalars['String']>
}

export type GqlMutationResetOpportunityClosedStateArgs = {
  opportunityId: Scalars['String']
}

export type GqlMutationResolveThreadArgs = {
  threadId: Scalars['String']
}

export type GqlMutationRetryTenantJobAsAdminArgs = {
  id?: InputMaybe<Scalars['String']>
  tenantId?: InputMaybe<Scalars['String']>
}

export type GqlMutationRevertSubscriptionArgs = {
  subscriptionId: Scalars['String']
  version: Scalars['Int']
}

export type GqlMutationRevertToDraftArgs = {
  id: Scalars['String']
}

export type GqlMutationRevokeApiKeyV3Args = {
  id: Scalars['String']
}

export type GqlMutationSendCreditMemoEmailArgs = {
  creditMemoNumber: Scalars['String']
}

export type GqlMutationSendEmailForDocusignArgs = {
  esignRequest: InputEsignRequest
}

export type GqlMutationSendEmailForEsignArgs = {
  esignRequest: InputEsignRequest
}

export type GqlMutationSendEmailForEsignSalesRoomArgs = {
  accountSignatory?: InputMaybe<InputEmailContact>
  linkId: Scalars['String']
}

export type GqlMutationSendInvoiceEmailArgs = {
  invoiceNumber: Scalars['String']
}

export type GqlMutationSetupHubSpotCustomObjectsArgs = {
  privateKey: Scalars['String']
}

export type GqlMutationSpecifyCurrentAccountingPeriodArgs = {
  startDate: Scalars['Long']
}

export type GqlMutationSubscribeExistingNotificationTargetToEventArgs = {
  notificationEventType: NotificationEventType
  notificationId: Scalars['String']
}

export type GqlMutationSyncCompositeOrderToCrmArgs = {
  compositeOrderId: Scalars['String']
}

export type GqlMutationSyncCreditMemoToErpArgs = {
  creditMemoNumber: Scalars['String']
}

export type GqlMutationSyncDealInformationToHubSpotArgs = {
  orderId: Scalars['String']
}

export type GqlMutationSyncInvoiceToErpArgs = {
  invoiceNumber: Scalars['String']
}

export type GqlMutationSyncJournalEntriesInAccountingPeriodToErpArgs = {
  accountingPeriodId: Scalars['String']
}

export type GqlMutationSyncOrderToSalesforceArgs = {
  orderId: Scalars['String']
}

export type GqlMutationTestAnrokIntegrationArgs = {
  apiKey: Scalars['String']
}

export type GqlMutationTestTaxJarIntegrationArgs = {
  apiKey: Scalars['String']
  isSandbox?: InputMaybe<Scalars['Boolean']>
}

export type GqlMutationUnEnrollFromAutomaticPaymentsArgs = {
  paymentLinkId: Scalars['String']
  paymentLinkType: PaymentManagementLinkType
}

export type GqlMutationUnapplyCreditMemoArgs = {
  UnapplyCreditMemoRequest: InputUnapplyCreditMemoRequest
}

export type GqlMutationUnsubscribeNotificationEventForTargetArgs = {
  notificationEventType: NotificationEventType
  notificationId: Scalars['String']
}

export type GqlMutationUnsubscribeNotificationTargetArgs = {
  notificationId: Scalars['String']
}

export type GqlMutationUpdateAccountArgs = {
  account: InputAccount
}

export type GqlMutationUpdateAccountReceivableContactArgs = {
  accountReceivableContact: InputAccountReceivableContactJson
}

export type GqlMutationUpdateAccountSupportedPaymentTypesArgs = {
  accountId: Scalars['String']
  supportedPaymentTypes: ReadonlyArray<InputMaybe<PaymentType>>
}

export type GqlMutationUpdateAccountV2Args = {
  accountInput: InputUpdateAccountInput
}

export type GqlMutationUpdateAccountingPeriodStatusArgs = {
  id: Scalars['String']
  status: AccountingPeriodStatus
}

export type GqlMutationUpdateAutomaticPaymentConfigurationArgs = {
  paymentTypes: ReadonlyArray<PaymentType>
}

export type GqlMutationUpdateCancelAndRestructureOrderStatusArgs = {
  id: Scalars['String']
  status: OrderStatus
  statusUpdatedOn?: InputMaybe<Scalars['Long']>
}

export type GqlMutationUpdateChargeArgs = {
  charge: InputCharge
  planId: Scalars['String']
}

export type GqlMutationUpdateChargePartialArgs = {
  chargePartial: InputChargePartial
  planId: Scalars['String']
}

export type GqlMutationUpdateCompositeOrderFieldsArgs = {
  compositeOrderFieldsUpdateRequest: InputCompositeOrderFieldsUpdateRequest
  compositeOrderId: Scalars['String']
}

export type GqlMutationUpdateCompositeOrderStatusArgs = {
  id: Scalars['String']
  status: OrderStatus
  statusUpdatedOn?: InputMaybe<Scalars['Long']>
}

export type GqlMutationUpdateCustomFieldDefinitionArgs = {
  customFieldDefinitionUpdate: InputCustomFieldDefinitionUpdate
  id: Scalars['String']
}

export type GqlMutationUpdateCustomFieldsArgs = {
  customFields: ReadonlyArray<InputCustomFieldInput>
  parentObjectId: Scalars['String']
  parentObjectType: CustomFieldParentType
}

export type GqlMutationUpdateCustomFieldsForSalesRoomArgs = {
  customFields: ReadonlyArray<InputCustomFieldInput>
  linkId: Scalars['String']
}

export type GqlMutationUpdateDiscountStatusArgs = {
  discountId: Scalars['String']
  status: DiscountStatus
}

export type GqlMutationUpdateDocumentMasterTemplateStatusArgs = {
  id: Scalars['String']
  status: DocumentMasterTemplateStatus
}

export type GqlMutationUpdateDocumentTemplateStatusArgs = {
  status: DocumentTemplateStatus
  templateId: Scalars['String']
}

export type GqlMutationUpdateDraftCreditMemoArgs = {
  creditMemo: InputCreditMemoRequest
  creditMemoNumber: Scalars['String']
}

export type GqlMutationUpdateDunningSettingArgs = {
  dunningSetting: InputDunningSettingDetails
}

export type GqlMutationUpdateEntityArgs = {
  entity: InputEntityPatchRequest
}

export type GqlMutationUpdateEscalationPolicyArgs = {
  escalationPolicy: InputUpdateEscalationPolicyRequest
}

export type GqlMutationUpdateInvoiceArgs = {
  invoiceNumber: Scalars['String']
  updateInvoice: InputUpdateInvoiceMutation
}

export type GqlMutationUpdateOrderApprovalStateArgs = {
  orderApprovalStateUpdates: InputApprovalFlowInstanceStateUpdates
}

export type GqlMutationUpdateOrderContactsArgs = {
  billingContactId?: InputMaybe<Scalars['String']>
  linkId: Scalars['String']
  shippingContactId?: InputMaybe<Scalars['String']>
}

export type GqlMutationUpdateOrderExpiryDurationInDaysArgs = {
  orderExpiryDurationInDays?: InputMaybe<Scalars['String']>
}

export type GqlMutationUpdateOrderStatusArgs = {
  orderId: Scalars['String']
  status: Scalars['String']
  statusUpdatedOn?: InputMaybe<Scalars['Long']>
}

export type GqlMutationUpdatePaymentConfigurationArgs = {
  paymentTypes: ReadonlyArray<PaymentType>
}

export type GqlMutationUpdatePaymentIntentArgs = {
  optInForAutomaticPayments: Scalars['Boolean']
  paymentIntentId: Scalars['String']
  paymentLinkId: Scalars['String']
}

export type GqlMutationUpdatePaymentTermSettingsArgs = {
  paymentTermSettings: InputPaymentTermSettings
}

export type GqlMutationUpdatePlanArgs = {
  updatePlanRequest: InputUpdatePlanRequest
}

export type GqlMutationUpdatePrimaryCompositeOrderIdForCrmOpportunityArgs = {
  compositeOrderId: Scalars['String']
}

export type GqlMutationUpdatePrimaryOrderIdForHubSpotOpportunityArgs = {
  orderId: Scalars['String']
}

export type GqlMutationUpdatePrimaryOrderIdForSalesforceOpportunityArgs = {
  orderId: Scalars['String']
}

export type GqlMutationUpdateProductArgs = {
  description?: InputMaybe<Scalars['String']>
  displayName?: InputMaybe<Scalars['String']>
  entityIds?: InputMaybe<ReadonlyArray<Scalars['String']>>
  externalId?: InputMaybe<Scalars['String']>
  id: Scalars['String']
  name: Scalars['String']
  productCategoryId?: InputMaybe<Scalars['String']>
  sku?: InputMaybe<Scalars['String']>
}

export type GqlMutationUpdateProductCategoryArgs = {
  description: Scalars['String']
  entityIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>
  id: Scalars['String']
  name: Scalars['String']
}

export type GqlMutationUpdateSettingArgs = {
  setting: InputSetting
}

export type GqlMutationUpdateSigningOrderArgs = {
  signingOrder?: InputMaybe<SigningOrder>
}

export type GqlMutationUpdateSubscriptionAttributesArgs = {
  subscriptionId: Scalars['String']
  subscriptionUpdate: InputSubscriptionUpdate
}

export type GqlMutationUpdateSupportedCurrenciesArgs = {
  currencies: ReadonlyArray<Scalars['String']>
}

export type GqlMutationUpdateTenantDetailsArgs = {
  tenant: InputUpdateTenantRequest
}

export type GqlMutationUpdateWireInstructionArgs = {
  wireInstruction: Scalars['String']
}

export type GqlMutationUpsertAccountArgs = {
  account: InputAccount
}

export type GqlMutationUpsertAccountContactArgs = {
  accountContact: InputAccountContact
  accountId: Scalars['String']
  skipAddressValidation?: InputMaybe<Scalars['Boolean']>
  strictValidation?: InputMaybe<Scalars['Boolean']>
}

export type GqlMutationUpsertAccountContactForSalesRoomArgs = {
  accountContact: InputAccountContact
  linkId: Scalars['String']
  skipAddressValidation?: InputMaybe<Scalars['Boolean']>
  strictValidation?: InputMaybe<Scalars['Boolean']>
  updateReseller?: InputMaybe<Scalars['Boolean']>
}

export type GqlMutationUpsertAmendmentArgs = {
  isDryRun?: InputMaybe<Scalars['Boolean']>
  order: InputAmendmentOrderRequest
  populateMissingLineItems?: InputMaybe<Scalars['Boolean']>
}

export type GqlMutationUpsertAnrokIntegrationArgs = {
  apiKey: Scalars['String']
}

export type GqlMutationUpsertApprovalFlowArgs = {
  approvalFlow: InputApprovalFlow
}

export type GqlMutationUpsertApprovalFlowSubmitterNoteArgs = {
  approvalFlowSubmitterNote: InputApprovalFlowSubmitterNote
}

export type GqlMutationUpsertApprovalRoleArgs = {
  approvalRole: InputApprovalRole
}

export type GqlMutationUpsertApprovalSegmentArgs = {
  approvalSegment: InputApprovalSegment
}

export type GqlMutationUpsertAvalaraIntegrationArgs = {
  accountId: Scalars['String']
  accountLicenseKey: Scalars['String']
  companyCode?: InputMaybe<Scalars['String']>
  sandboxEnvironment: Scalars['Boolean']
  shouldCommitDocuments?: InputMaybe<Scalars['Boolean']>
}

export type GqlMutationUpsertCancelAndRestructureArgs = {
  isDryRun?: InputMaybe<Scalars['Boolean']>
  order: InputCancelAndRestructureRequest
}

export type GqlMutationUpsertChargeDefaultAttributeReferencesArgs = {
  chargeDefaultAttributeReferences: InputChargeDefaultAttributeReferences
}

export type GqlMutationUpsertCrmContactsArgs = {
  accountId: Scalars['String']
  crmIds: ReadonlyArray<Scalars['String']>
}

export type GqlMutationUpsertCurrencyConversionRateArgs = {
  currencyConversionRate: InputCurrencyConversionRate
}

export type GqlMutationUpsertCurrencyTypeSettingArgs = {
  currencyTypeSetting: InputCurrencyTypeSetting
}

export type GqlMutationUpsertCustomPredefinedTemplateOnOrderArgs = {
  documentTemplate: InputCustomPredefinedTemplateOnOrder
}

export type GqlMutationUpsertDiscountArgs = {
  discount: InputDiscountJson
}

export type GqlMutationUpsertDocumentMasterTemplateArgs = {
  documentMasterTemplate: InputDocumentMasterTemplate
}

export type GqlMutationUpsertDocumentMasterTemplateV2Args = {
  documentMasterTemplate: InputDocumentMasterTemplate
}

export type GqlMutationUpsertDocumentSectionArgs = {
  documentSection: InputDocumentSection
}

export type GqlMutationUpsertDocumentTemplateArgs = {
  documentTemplate: InputDocumentTemplateRequest
}

export type GqlMutationUpsertEsignDetailsArgs = {
  esignRequest: InputEsignRequest
}

export type GqlMutationUpsertLedgerAccountArgs = {
  ledgerAccount: InputLedgerAccount
}

export type GqlMutationUpsertNotificationTargetAndSubscriptionsArgs = {
  notification: InputNotification
}

export type GqlMutationUpsertOrderArgs = {
  isDryRun?: InputMaybe<Scalars['Boolean']>
  order: InputOrderRequest
  sanitizeLineItemDates?: InputMaybe<Scalars['Boolean']>
}

export type GqlMutationUpsertPaymentBankAccountArgs = {
  paymentBankAccount: InputPaymentBankAccountJson
}

export type GqlMutationUpsertPlanArgs = {
  catalogRelationships?: InputMaybe<ReadonlyArray<InputMaybe<InputCatalogRelationship>>>
  plan: InputPlan
}

export type GqlMutationUpsertPlanGroupArgs = {
  planGroup: InputPlanGroupInterface
}

export type GqlMutationUpsertPriceAttributeArgs = {
  priceAttribute: InputPriceAttribute
}

export type GqlMutationUpsertProductCategoryArgs = {
  description: Scalars['String']
  entityIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>
  id?: InputMaybe<Scalars['String']>
  name: Scalars['String']
}

export type GqlMutationUpsertRateCardArgs = {
  rateCard: InputRateCard
}

export type GqlMutationUpsertRecognitionRuleArgs = {
  recognitionRule: InputRecognitionRule
}

export type GqlMutationUpsertRenewalOrderArgs = {
  isDryRun?: InputMaybe<Scalars['Boolean']>
  order: InputOrderRequest
}

export type GqlMutationUpsertTaxJarIntegrationArgs = {
  apiKey: Scalars['String']
  isSandbox?: InputMaybe<Scalars['Boolean']>
}

export type GqlMutationUpsertTaxRateArgs = {
  taxRate: InputUpsertTaxRateRequest
}

export type GqlMutationUpsertUnitOfMeasureArgs = {
  unitOfMeasure: InputUnitOfMeasureRequest
}

export type GqlMutationUpsertUserArgs = {
  user: InputUser
}

export type GqlMutationUpsertUserGroupArgs = {
  userGroup: InputUserGroupRequest
}

export type GqlMutationUpsertUserV2Args = {
  user: InputUserInput
}

export type GqlMutationVoidEsignatureDocumentV2Args = {
  id: Scalars['String']
}

export type GqlMutationVoidInvoiceArgs = {
  voidInvoice: InputVoidInvoiceMutation
}

export type GqlMutationVoidInvoiceOnErpArgs = {
  invoiceNumber: Scalars['String']
}

export type GqlMutationVoidPaymentArgs = {
  VoidPaymentRequest: InputVoidPaymentRequest
}

export type GqlQuery = {
  readonly __typename?: 'GqlQuery'
  readonly accountByCrmId?: Maybe<Account>
  readonly accountByHubSpotCompanyId?: Maybe<Account>
  readonly accountContactDetail: AccountContactDetail
  readonly accountContacts: ReadonlyArray<AccountContact>
  readonly accountDetail: AccountDetail
  readonly accountDetails: ReadonlyArray<AccountDetail>
  readonly accountPaymentLinkDetails: AccountPaymentLinkResponse
  readonly accountReceivableContactSetting?: Maybe<AccountReceivableContactJson>
  readonly accountingPeriod: AccountingPeriod
  readonly accountingPeriodsBetween: ReadonlyArray<AccountingPeriod>
  readonly accounts: ReadonlyArray<Account>
  readonly activeErpSyncTasks: ReadonlyArray<TenantJob>
  readonly activeSubscriptionsByAccountCrmId: ReadonlyArray<Maybe<Scalars['String']>>
  readonly aliasesBySubscriptionIdAndChargeId?: Maybe<ReadonlyArray<SubscriptionChargeAlias>>
  readonly allLedgerAccountTemplates: ReadonlyArray<LedgerAccount>
  readonly anrokIntegration: Integration
  readonly apiKey: ApiKeyDetail
  readonly apiKeys: ReadonlyArray<ApiKeyDetail>
  readonly applicablePaymentBankAccountsForInvoicePayment: ReadonlyArray<PaymentBankAccountJson>
  readonly approvalFlows: ReadonlyArray<ApprovalFlowDetail>
  readonly approvalRoles: ReadonlyArray<ApprovalRole>
  readonly approvalSegments: ReadonlyArray<ApprovalSegment>
  readonly associatedUsers: ReadonlyArray<User>
  readonly attachments: ReadonlyArray<Attachment>
  readonly avalaraIntegration: AvalaraIntegration
  readonly billingEvents: BillingEvents
  readonly billingPeriodStartDates: ReadonlyArray<Scalars['Long']>
  readonly bulkInvoiceRun: BulkInvoiceRun
  readonly bulkInvoiceRunInProgress?: Maybe<Scalars['String']>
  readonly bulkInvoiceRunItems: ReadonlyArray<BulkInvoiceRunItem>
  readonly cancelAndRestructure: CancelAndRestructureOrder
  readonly chargeDefaultAttributeReferences: ChargeDefaultAttributeReferences
  readonly chargeDetail: ChargeDetail
  readonly compositeOrder: CompositeOrder
  readonly compositeOrdersByCrmOpportunityId: ReadonlyArray<CompositeOrderMinimal>
  readonly creditMemo: CreditMemo
  readonly crmContactsForAccount?: Maybe<ReadonlyArray<CrmContact>>
  readonly currencyConversionRateById: CurrencyConversionRate
  readonly currencyConversionRates: ReadonlyArray<CurrencyConversionRate>
  readonly currencyConversionRatesForGivenCurrencyPair: ReadonlyArray<CurrencyConversionRate>
  readonly currencyTypeSetting: CurrencyTypeSetting
  readonly currentAccountingPeriod?: Maybe<AccountingPeriod>
  readonly currentAccountingPeriodByEntityId?: Maybe<AccountingPeriod>
  readonly currentLookerUser?: Maybe<LookerUserMapping>
  readonly currentTenant: Tenant
  readonly currentUser: User
  readonly customFieldDefinitions: ReadonlyArray<CustomFieldDefinition>
  readonly customFields: ReadonlyArray<CustomFieldEntry>
  readonly customFieldsBatched: ReadonlyArray<CustomFieldWithParentReference>
  readonly customPredefinedTemplateOnOrder: CustomPredefinedTemplateOnOrder
  readonly dataImports: ReadonlyArray<DataImport>
  readonly defaultBulkInvoiceRunSelector: BulkInvoiceRunSelector
  readonly defaultLedgerAccountTemplates: ReadonlyArray<LedgerAccount>
  readonly defaultLedgerAccounts: ReadonlyArray<LedgerAccount>
  readonly discounts: ReadonlyArray<DiscountJson>
  readonly docuSignIntegration: DocuSignIntegrationResponse
  readonly documentMasterTemplate: DocumentMasterTemplate
  readonly documentMasterTemplates: ReadonlyArray<DocumentMasterTemplate>
  readonly documentSections: ReadonlyArray<DocumentSection>
  readonly documentTemplate: DocumentTemplate
  readonly documentTemplates: ReadonlyArray<DocumentTemplate>
  readonly draftInvoicesCountForCustomerAccount: Scalars['Int']
  readonly dunningSetting: DunningSettingDetails
  readonly duplicateOrder: OrderDetail
  readonly dynamicFeatureEnabled: Scalars['Boolean']
  readonly dynamicFeatureListV2: ReadonlyArray<FeatureSetting>
  /** @deprecated Deprecated */
  readonly dynamicFeatures: ReadonlyArray<FeatureSetting>
  readonly echo: Scalars['String']
  readonly electronicSignature: ElectronicSignature
  readonly electronicSignatureAuditLogs: ReadonlyArray<Maybe<ImmutableElectronicSignatureAuditLog>>
  readonly electronicSignatureProvider?: Maybe<ElectronicSignatureProvider>
  readonly emailContactsForAccount: ReadonlyArray<EmailContact>
  readonly emailLogs: EmailLogsResponse
  readonly emailSentForInvoice: Scalars['Boolean']
  readonly enabledPlatformFeature: EnabledPlatformFeature
  readonly enabledPlatformFeatures: ReadonlyArray<EnabledPlatformFeature>
  readonly entities: ReadonlyArray<EntityJson>
  readonly entity: EntityJson
  readonly entityResolver: EntityResolver
  readonly erpIntegration?: Maybe<Integration>
  readonly erpInvoice: ErpInvoice
  readonly erpInvoicesBySubscriptionId: ReadonlyArray<ErpInvoice>
  readonly escalationPolicy: EscalationPolicy
  readonly esignDetails?: Maybe<EsignRequest>
  readonly failedPaymentsForInvoice: ReadonlyArray<FailedPayment>
  readonly flatfileSpace: FlatfileSpaceResponse
  readonly flatfileSpaces: ReadonlyArray<FlatfileSpace>
  readonly generateAmendment: OrderDetail
  readonly generateCancelOrder: OrderDetail
  readonly generateRefund: RefundPreviewDetail
  readonly generateRenewalOrder: OrderDetail
  readonly getInvoiceSettlementApplications: ReadonlyArray<SettlementApplication>
  /** @deprecated Deprecated */
  readonly getPayment: ReadonlyArray<PaymentDetail>
  readonly getSalesforceAccountById?: Maybe<SalesforceAccount>
  readonly hasAnrokIntegration: Scalars['Boolean']
  readonly hasAvalaraIntegration: Scalars['Boolean']
  /** @deprecated Deprecated */
  readonly hasDocusignIntegration: Scalars['Boolean']
  readonly hasPaymentIntegration: Scalars['Boolean']
  readonly hasTaxJarIntegration: Scalars['Boolean']
  readonly hubSpotIntegration: HubSpotIntegration
  readonly integration?: Maybe<Integration>
  readonly invoice: Invoice
  readonly invoiceDunningDetails: ReadonlyArray<InvoiceDunningDetail>
  readonly invoicePaymentLinkDetails: PaymentLinkResponse
  readonly isAmendmentCurrent: Scalars['Boolean']
  readonly lastUsageArrivalCheckpoint?: Maybe<Scalars['Long']>
  readonly latestAggregatedUsage: ReadonlyArray<AggregatedUsage>
  readonly latestAggregationTime?: Maybe<Scalars['Long']>
  readonly ledgerAccounts: ReadonlyArray<LedgerAccount>
  readonly ledgerAccountsForPaymentBankAccount: ReadonlyArray<LedgerAccount>
  readonly notification: Notification
  readonly notificationInstance: NotificationInstanceWithAttempts
  readonly notificationProcessorTypeSupport: ReadonlyArray<Maybe<NotificationProcessorTypeSupport>>
  readonly notifications: ReadonlyArray<Notification>
  readonly openInvoicesReportOverview: ReadonlyArray<BillyChartDataset>
  readonly opportunitiesByAccountCrmId?: Maybe<ReadonlyArray<Opportunity>>
  readonly opportunitiesByHubSpotCompanyId?: Maybe<ReadonlyArray<Opportunity>>
  readonly opportunitiesBySalesforceAccountId?: Maybe<ReadonlyArray<Opportunity>>
  readonly opportunityByCrmOpportunityId: Opportunity
  readonly opportunityByOpportunityId: Opportunity
  readonly orderApprovals: ReadonlyArray<ApprovalFlowInstanceGroupDetail>
  readonly orderDetail: OrderDetail
  readonly orderExpiryDurationInDays?: Maybe<Scalars['Int']>
  readonly orderInvoicePreview: OrderInvoicePreview
  readonly orders: ReadonlyArray<Order>
  readonly ordersByCrmOpportunityId: ReadonlyArray<Order>
  readonly ordersOverviewByDate: ReadonlyArray<BillyChartDataset>
  readonly payment: PaymentDetail
  readonly paymentBankAccount: PaymentBankAccountJson
  readonly paymentConfiguration: PaymentConfiguration
  readonly paymentIntegration?: Maybe<PaymentIntegration>
  readonly paymentIntegrationEnabled: Scalars['Boolean']
  readonly paymentMethods: ReadonlyArray<PaymentMethodDetail>
  readonly paymentTermSettings: PaymentTermSettings
  readonly paymentsForInvoice: ReadonlyArray<PaymentDetail>
  readonly paymentsInAccountDetail: ReadonlyArray<PaymentInAccountDetail>
  readonly pendingApprovalFlowsForUser: ReadonlyArray<PendingOrderApprovalFlow>
  readonly planDetail: PlanDetail
  readonly planGroup: PlanGroupInterface
  readonly planMinimals: ReadonlyArray<PlanMinimal>
  readonly plans: ReadonlyArray<Plan>
  readonly predefinedReports: PredefinedReports
  readonly previewDocumentTemplate: Scalars['String']
  readonly previewMasterTemplate: Scalars['String']
  readonly previewUnbilledUsage: ReadonlyArray<InvoiceItem>
  readonly priceAttributes: ReadonlyArray<PriceAttribute>
  readonly product: ProductDetail
  readonly productCategories: ReadonlyArray<ProductCategory>
  readonly products: ReadonlyArray<ProductDetail>
  readonly proposal: Proposal
  readonly rateCard: RateCard
  readonly rateCards: ReadonlyArray<RateCard>
  readonly recentAccountingPeriods: ReadonlyArray<AccountingPeriod>
  readonly recognitionRuleById: RecognitionRule
  readonly recognitionRules: ReadonlyArray<RecognitionRule>
  readonly refundablePayments: ReadonlyArray<PaymentDetail>
  readonly refunds: ReadonlyArray<Refund>
  readonly resellerForSalesRoom?: Maybe<AccountDetail>
  readonly runPlanAdditionCustomization: ReadonlyArray<Action>
  readonly salesRoom?: Maybe<SalesRoom>
  readonly salesRoomLink?: Maybe<SalesRoomLink>
  readonly salesforceContactsForAccount?: Maybe<ReadonlyArray<SalesforceContact>>
  readonly salesforceIntegration: SalesforceIntegrationResponse
  readonly salesforceIntegrationDetails: SalesforceIntegrationResponse
  readonly search: Scalars['String']
  readonly searchInTenant: Scalars['String']
  readonly sendSampleDunningEmail: Scalars['Boolean']
  readonly setting: Setting
  readonly signingOrder?: Maybe<SigningOrder>
  readonly stripeConnectIntegration?: Maybe<PaymentStripeConnectIntegration>
  readonly subscriptionChargeAlias: SubscriptionChargeAlias
  readonly subscriptionChargeRecognition: SubscriptionChargeRecognition
  readonly subscriptionModifiable: SubscriptionModifiableResponse
  readonly subscriptionReversible: SubscriptionModifiableResponse
  readonly subscriptions: ReadonlyArray<Subscription>
  readonly supportedCurrencies: ReadonlyArray<Currency>
  readonly supportedPaymentTypes: ReadonlyArray<Maybe<PaymentType>>
  readonly systemSettings: SystemSettings
  readonly taxJarIntegration: TaxJarIntegration
  readonly taxRates: ReadonlyArray<TaxRate>
  readonly tenantDetails: TenantDetails
  readonly tenantJob: TenantJob
  readonly tenantJobAsAdmin: TenantJob
  readonly tenantSignatories: ReadonlyArray<Maybe<TenantSignatory>>
  readonly tenants: ReadonlyArray<Tenant>
  readonly testIntegration?: Maybe<Scalars['Boolean']>
  readonly throwException: Scalars['String']
  readonly timezones: ReadonlyArray<TimeZoneData>
  readonly uiConfiguration?: Maybe<UiConfiguration>
  readonly unitsOfMeasure: ReadonlyArray<UnitOfMeasure>
  readonly usageForSubscriptionCharge: ReadonlyArray<InvoiceItem>
  readonly user: User
  readonly userAuthInfo?: Maybe<UserAuthInfo>
  readonly userGroups: ReadonlyArray<UserGroupDetail>
  readonly userLoggedIn: Scalars['Boolean']
  readonly validMinimumDateForTransactions?: Maybe<Scalars['Long']>
  readonly verifyHubSpotSetup: ReadonlyArray<HubSpotSetupMessage>
  readonly webOrderFormDetail: WebOrderFormDetail
  readonly webhookSigningKey: Scalars['String']
  readonly wireInstruction?: Maybe<Scalars['String']>
}

export type GqlQueryAccountByCrmIdArgs = {
  accountCrmId: Scalars['String']
}

export type GqlQueryAccountByHubSpotCompanyIdArgs = {
  companyId: Scalars['String']
}

export type GqlQueryAccountContactDetailArgs = {
  accountId: Scalars['String']
  id: Scalars['String']
}

export type GqlQueryAccountContactsArgs = {
  accountId: Scalars['String']
  id?: InputMaybe<Scalars['String']>
}

export type GqlQueryAccountDetailArgs = {
  id: Scalars['String']
}

export type GqlQueryAccountDetailsArgs = {
  id?: InputMaybe<Scalars['String']>
  type?: InputMaybe<AccountType>
}

export type GqlQueryAccountPaymentLinkDetailsArgs = {
  linkId: Scalars['String']
}

export type GqlQueryAccountingPeriodArgs = {
  accountingPeriodId: Scalars['String']
}

export type GqlQueryAccountingPeriodsBetweenArgs = {
  endDate: Scalars['Long']
  startDate: Scalars['Long']
}

export type GqlQueryAccountsArgs = {
  id?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Scalars['String']>
}

export type GqlQueryActiveSubscriptionsByAccountCrmIdArgs = {
  accountCrmId: Scalars['String']
}

export type GqlQueryAliasesBySubscriptionIdAndChargeIdArgs = {
  chargeId: Scalars['String']
  subscriptionId: Scalars['String']
}

export type GqlQueryApiKeyArgs = {
  id: Scalars['String']
}

export type GqlQueryApiKeysArgs = {
  activeOnly: Scalars['Boolean']
}

export type GqlQueryApplicablePaymentBankAccountsForInvoicePaymentArgs = {
  invoiceNumber: Scalars['String']
}

export type GqlQueryApprovalFlowsArgs = {
  id?: InputMaybe<Scalars['String']>
}

export type GqlQueryApprovalRolesArgs = {
  id?: InputMaybe<Scalars['String']>
}

export type GqlQueryApprovalSegmentsArgs = {
  id?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
}

export type GqlQueryAttachmentsArgs = {
  accountId?: InputMaybe<Scalars['String']>
  attachmentId?: InputMaybe<Scalars['String']>
}

export type GqlQueryBillingEventsArgs = {
  subscriptionChargeId: Scalars['String']
  subscriptionId: Scalars['String']
}

export type GqlQueryBillingPeriodStartDatesArgs = {
  subscriptionId: Scalars['String']
}

export type GqlQueryBulkInvoiceRunArgs = {
  bulkInvoiceRunId: Scalars['String']
}

export type GqlQueryBulkInvoiceRunItemsArgs = {
  bulkInvoiceRunId: Scalars['String']
}

export type GqlQueryCancelAndRestructureArgs = {
  id: Scalars['String']
}

export type GqlQueryChargeDefaultAttributeReferencesArgs = {
  chargeId?: InputMaybe<Scalars['String']>
}

export type GqlQueryChargeDetailArgs = {
  chargeId: Scalars['String']
  planId: Scalars['String']
}

export type GqlQueryCompositeOrderArgs = {
  id: Scalars['String']
}

export type GqlQueryCompositeOrdersByCrmOpportunityIdArgs = {
  crmOpportunityId: Scalars['String']
}

export type GqlQueryCreditMemoArgs = {
  creditMemoNumber: Scalars['String']
}

export type GqlQueryCrmContactsForAccountArgs = {
  accountId: Scalars['String']
}

export type GqlQueryCurrencyConversionRateByIdArgs = {
  id: Scalars['String']
}

export type GqlQueryCurrencyConversionRatesForGivenCurrencyPairArgs = {
  fromCurrency: Scalars['String']
  toCurrency: Scalars['String']
}

export type GqlQueryCurrentAccountingPeriodByEntityIdArgs = {
  entityId: Scalars['String']
}

export type GqlQueryCustomFieldDefinitionsArgs = {
  parentObjectType: CustomFieldParentType
}

export type GqlQueryCustomFieldsArgs = {
  parentObjectId?: InputMaybe<Scalars['String']>
  parentObjectType: CustomFieldParentType
}

export type GqlQueryCustomFieldsBatchedArgs = {
  parentObjectIds: ReadonlyArray<InputMaybe<Scalars['String']>>
  parentObjectType: CustomFieldParentType
}

export type GqlQueryCustomPredefinedTemplateOnOrderArgs = {
  orderId: Scalars['String']
  templateId: Scalars['String']
}

export type GqlQueryDataImportsArgs = {
  dataImportId?: InputMaybe<Scalars['String']>
}

export type GqlQueryDiscountsArgs = {
  discountId?: InputMaybe<Scalars['String']>
}

export type GqlQueryDocumentMasterTemplateArgs = {
  id?: InputMaybe<Scalars['String']>
}

export type GqlQueryDocumentMasterTemplatesArgs = {
  templateType?: InputMaybe<DocumentTemplateType>
}

export type GqlQueryDocumentTemplateArgs = {
  id?: InputMaybe<Scalars['String']>
}

export type GqlQueryDocumentTemplatesArgs = {
  type?: InputMaybe<Scalars['String']>
}

export type GqlQueryDraftInvoicesCountForCustomerAccountArgs = {
  accountId: Scalars['String']
}

export type GqlQueryDuplicateOrderArgs = {
  accountId?: InputMaybe<Scalars['String']>
  orderId: Scalars['String']
}

export type GqlQueryDynamicFeatureEnabledArgs = {
  feature: Feature
}

export type GqlQueryEchoArgs = {
  message: Scalars['String']
}

export type GqlQueryElectronicSignatureArgs = {
  orderId: Scalars['String']
}

export type GqlQueryElectronicSignatureAuditLogsArgs = {
  electronicSignatureId: Scalars['String']
}

export type GqlQueryEmailContactsForAccountArgs = {
  accountId?: InputMaybe<Scalars['String']>
}

export type GqlQueryEmailLogsArgs = {
  entityId?: InputMaybe<Scalars['String']>
  parentEntityId?: InputMaybe<Scalars['String']>
}

export type GqlQueryEmailSentForInvoiceArgs = {
  invoiceNumber: Scalars['String']
}

export type GqlQueryEnabledPlatformFeatureArgs = {
  platformFeature: PlatformFeature
}

export type GqlQueryEntityArgs = {
  id?: InputMaybe<Scalars['String']>
}

export type GqlQueryEntityResolverArgs = {
  id?: InputMaybe<Scalars['String']>
}

export type GqlQueryErpInvoiceArgs = {
  invoiceErpId: Scalars['String']
}

export type GqlQueryErpInvoicesBySubscriptionIdArgs = {
  subscriptionId: Scalars['String']
}

export type GqlQueryEscalationPolicyArgs = {
  id: Scalars['String']
}

export type GqlQueryEsignDetailsArgs = {
  orderId: Scalars['String']
}

export type GqlQueryFailedPaymentsForInvoiceArgs = {
  invoiceNumber: Scalars['String']
}

export type GqlQueryFlatfileSpaceArgs = {
  spaceId?: InputMaybe<Scalars['String']>
}

export type GqlQueryGenerateAmendmentArgs = {
  subscriptionId: Scalars['String']
}

export type GqlQueryGenerateCancelOrderArgs = {
  effectiveDate?: InputMaybe<Scalars['Long']>
  subscriptionId: Scalars['String']
}

export type GqlQueryGenerateRefundArgs = {
  creditMemoNumber: Scalars['String']
  paymentId: Scalars['String']
}

export type GqlQueryGenerateRenewalOrderArgs = {
  subscriptionId: Scalars['String']
}

export type GqlQueryGetInvoiceSettlementApplicationsArgs = {
  invoiceNumber: Scalars['String']
}

export type GqlQueryGetPaymentArgs = {
  accountId?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['String']>
}

export type GqlQueryGetSalesforceAccountByIdArgs = {
  accountId: Scalars['String']
}

export type GqlQueryIntegrationArgs = {
  targetService: IntegrationTargetService
}

export type GqlQueryInvoiceArgs = {
  invoiceNumber: Scalars['String']
}

export type GqlQueryInvoiceDunningDetailsArgs = {
  invoiceNumber: Scalars['String']
}

export type GqlQueryInvoicePaymentLinkDetailsArgs = {
  linkId: Scalars['String']
}

export type GqlQueryIsAmendmentCurrentArgs = {
  orderId: Scalars['String']
}

export type GqlQueryLatestAggregatedUsageArgs = {
  subscriptionId: Scalars['String']
}

export type GqlQueryLedgerAccountsForPaymentBankAccountArgs = {
  id?: InputMaybe<Scalars['String']>
}

export type GqlQueryNotificationArgs = {
  notificationId: Scalars['String']
}

export type GqlQueryNotificationInstanceArgs = {
  notificationInstanceId?: InputMaybe<Scalars['String']>
}

export type GqlQueryOpportunitiesByAccountCrmIdArgs = {
  accountCrmId: Scalars['String']
}

export type GqlQueryOpportunitiesByHubSpotCompanyIdArgs = {
  companyId: Scalars['String']
}

export type GqlQueryOpportunitiesBySalesforceAccountIdArgs = {
  sfdcAccountId: Scalars['String']
}

export type GqlQueryOpportunityByCrmOpportunityIdArgs = {
  crmOpportunityId: Scalars['String']
}

export type GqlQueryOpportunityByOpportunityIdArgs = {
  opportunityId: Scalars['String']
}

export type GqlQueryOrderApprovalsArgs = {
  orderId: Scalars['String']
}

export type GqlQueryOrderDetailArgs = {
  id: Scalars['String']
}

export type GqlQueryOrderInvoicePreviewArgs = {
  orderId: Scalars['String']
}

export type GqlQueryOrdersArgs = {
  accountId?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['String']>
  limit?: InputMaybe<Scalars['Int']>
  subscriptionId?: InputMaybe<Scalars['String']>
}

export type GqlQueryOrdersByCrmOpportunityIdArgs = {
  crmOpportunityId: Scalars['String']
}

export type GqlQueryOrdersOverviewByDateArgs = {
  from: Scalars['Long']
  to: Scalars['Long']
}

export type GqlQueryPaymentArgs = {
  id: Scalars['String']
}

export type GqlQueryPaymentBankAccountArgs = {
  id: Scalars['String']
}

export type GqlQueryPaymentMethodsArgs = {
  accountId: Scalars['String']
}

export type GqlQueryPaymentsForInvoiceArgs = {
  invoiceNumber?: InputMaybe<Scalars['String']>
}

export type GqlQueryPaymentsInAccountDetailArgs = {
  accountId?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['String']>
}

export type GqlQueryPlanDetailArgs = {
  id: Scalars['String']
}

export type GqlQueryPlanGroupArgs = {
  id: Scalars['String']
}

export type GqlQueryPlanMinimalsArgs = {
  accountId?: InputMaybe<Scalars['String']>
  orderCurrencyCode?: InputMaybe<Scalars['String']>
  status?: InputMaybe<PlanStatus>
}

export type GqlQueryPlansArgs = {
  id?: InputMaybe<Scalars['String']>
  productId?: InputMaybe<Scalars['String']>
  status?: InputMaybe<PlanStatus>
}

export type GqlQueryPreviewDocumentTemplateArgs = {
  orderId: Scalars['String']
  templateId: Scalars['String']
}

export type GqlQueryPreviewMasterTemplateArgs = {
  masterTemplateId: Scalars['String']
  orderId: Scalars['String']
}

export type GqlQueryPreviewUnbilledUsageArgs = {
  subscriptionId: Scalars['String']
}

export type GqlQueryProductArgs = {
  id: Scalars['String']
}

export type GqlQueryProductCategoriesArgs = {
  id?: InputMaybe<Scalars['String']>
}

export type GqlQueryProposalArgs = {
  id: Scalars['String']
}

export type GqlQueryRateCardArgs = {
  effectiveDate?: InputMaybe<Scalars['Long']>
  id: Scalars['String']
  targetCurrencyCode?: InputMaybe<Scalars['String']>
}

export type GqlQueryRecentAccountingPeriodsArgs = {
  recentNumberOfMonths: Scalars['Long']
}

export type GqlQueryRecognitionRuleByIdArgs = {
  id: Scalars['String']
}

export type GqlQueryRefundablePaymentsArgs = {
  accountId: Scalars['String']
}

export type GqlQueryRefundsArgs = {
  accountId?: InputMaybe<Scalars['String']>
  paymentId?: InputMaybe<Scalars['String']>
}

export type GqlQueryResellerForSalesRoomArgs = {
  linkId: Scalars['String']
}

export type GqlQueryRunPlanAdditionCustomizationArgs = {
  planCustomizationInput: InputPlanAdditionCustomizationInput
}

export type GqlQuerySalesRoomArgs = {
  linkId: Scalars['String']
}

export type GqlQuerySalesRoomLinkArgs = {
  orderId: Scalars['String']
}

export type GqlQuerySalesforceContactsForAccountArgs = {
  accountId: Scalars['String']
}

export type GqlQuerySearchArgs = {
  jsonInBase64: Scalars['String']
}

export type GqlQuerySearchInTenantArgs = {
  jsonInBase64: Scalars['String']
  tenantId: Scalars['String']
}

export type GqlQuerySendSampleDunningEmailArgs = {
  dunningReminderType: DunningReminderType
}

export type GqlQuerySubscriptionChargeAliasArgs = {
  aliasId: Scalars['String']
}

export type GqlQuerySubscriptionChargeRecognitionArgs = {
  chargeId: Scalars['String']
  subscriptionId: Scalars['String']
}

export type GqlQuerySubscriptionModifiableArgs = {
  subscriptionId: Scalars['String']
}

export type GqlQuerySubscriptionReversibleArgs = {
  subscriptionId: Scalars['String']
}

export type GqlQuerySubscriptionsArgs = {
  id: Scalars['String']
}

export type GqlQueryTaxRatesArgs = {
  id?: InputMaybe<Scalars['String']>
}

export type GqlQueryTenantJobArgs = {
  id?: InputMaybe<Scalars['String']>
}

export type GqlQueryTenantJobAsAdminArgs = {
  id?: InputMaybe<Scalars['String']>
  tenantId?: InputMaybe<Scalars['String']>
}

export type GqlQueryTestIntegrationArgs = {
  targetService: IntegrationTargetService
}

export type GqlQueryThrowExceptionArgs = {
  message: Scalars['String']
}

export type GqlQueryUnitsOfMeasureArgs = {
  id?: InputMaybe<Scalars['String']>
}

export type GqlQueryUsageForSubscriptionChargeArgs = {
  chargeId: Scalars['String']
  subscriptionId: Scalars['String']
}

export type GqlQueryUserArgs = {
  id: Scalars['String']
}

export type GqlQueryUserAuthInfoArgs = {
  email: Scalars['String']
}

export type GqlQueryUserGroupsArgs = {
  id?: InputMaybe<Scalars['String']>
}

export type GqlQueryWebOrderFormDetailArgs = {
  documentId: Scalars['String']
}

export type GqlQueryWebhookSigningKeyArgs = {
  notificationId: Scalars['String']
}

export enum HeaderStyle {
  /** THREE_COLUMN_LAYOUT */
  ThreeColumnLayout = 'THREE_COLUMN_LAYOUT',
  /** TWO_COLUMN_LAYOUT */
  TwoColumnLayout = 'TWO_COLUMN_LAYOUT',
}

export type HubSpotIntegration = {
  readonly __typename?: 'HubSpotIntegration'
  readonly createdOn: Scalars['Long']
  readonly email?: Maybe<Scalars['String']>
  readonly hubspotId?: Maybe<Scalars['String']>
  readonly integrationId: Scalars['String']
  readonly isCompleted: Scalars['Boolean']
}

export type HubSpotSetupMessage = {
  readonly __typename?: 'HubSpotSetupMessage'
  readonly level?: Maybe<MessageLevel>
  readonly message?: Maybe<Scalars['String']>
}

export type ImmutableElectronicSignatureAuditLog = {
  readonly __typename?: 'ImmutableElectronicSignatureAuditLog'
  readonly createdOn: Scalars['Long']
  readonly electronicSignatureId: Scalars['String']
  readonly id: Scalars['String']
  readonly initiatedBy: Scalars['String']
  readonly status: ElectronicSignatureAuditLogStatus
}

export enum ImportDomain {
  /** ACCOUNT */
  Account = 'ACCOUNT',
  /** BULK_ACCOUNT_UPDATE */
  BulkAccountUpdate = 'BULK_ACCOUNT_UPDATE',
  /** BULK_ORDER_UPDATE */
  BulkOrderUpdate = 'BULK_ORDER_UPDATE',
  /** BULK_PLAN_UPDATE */
  BulkPlanUpdate = 'BULK_PLAN_UPDATE',
  /** BULK_PRODUCT_UPDATE */
  BulkProductUpdate = 'BULK_PRODUCT_UPDATE',
  /** BULK_SUBSCRIPTION_UPDATE */
  BulkSubscriptionUpdate = 'BULK_SUBSCRIPTION_UPDATE',
  /** CATALOG */
  Catalog = 'CATALOG',
  /** ORDER */
  Order = 'ORDER',
  /** USAGE */
  Usage = 'USAGE',
}

export enum ImportFileType {
  /** CSV */
  Csv = 'CSV',
}

export enum ImportOperation {
  /** CREATE */
  Create = 'CREATE',
  /** UPDATE */
  Update = 'UPDATE',
}

export enum ImportStatus {
  /** FAILED */
  Failed = 'FAILED',
  /** PARTIALLY_SUCCESSFUL */
  PartiallySuccessful = 'PARTIALLY_SUCCESSFUL',
  /** PROCESSING */
  Processing = 'PROCESSING',
  /** SUCCESSFUL */
  Successful = 'SUCCESSFUL',
  /** VALIDATED */
  Validated = 'VALIDATED',
}

export type InputAccount = {
  readonly address?: InputMaybe<InputAccountAddress>
  readonly crmId?: InputMaybe<Scalars['String']>
  readonly crmType?: InputMaybe<Scalars['String']>
  readonly currency?: InputMaybe<Scalars['String']>
  readonly description?: InputMaybe<Scalars['String']>
  readonly entityIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>
  readonly erpId?: InputMaybe<Scalars['String']>
  readonly excludeFromBatchOperations?: InputMaybe<Scalars['Boolean']>
  readonly excludeFromDunning?: InputMaybe<Scalars['Boolean']>
  readonly externalId?: InputMaybe<Scalars['String']>
  readonly hasAutomaticPayment?: InputMaybe<Scalars['Boolean']>
  readonly id?: InputMaybe<Scalars['String']>
  readonly isReseller?: InputMaybe<Scalars['Boolean']>
  readonly name: Scalars['String']
  readonly phoneNumber?: InputMaybe<Scalars['String']>
  readonly supportedPaymentTypes?: InputMaybe<ReadonlyArray<InputMaybe<PaymentType>>>
  readonly taxExemptionUseCode?: InputMaybe<TaxExemptionUseCode>
  readonly updatedOn?: InputMaybe<Scalars['Long']>
}

export type InputAccountAddress = {
  readonly city: Scalars['String']
  readonly country: Scalars['String']
  readonly state?: InputMaybe<Scalars['String']>
  readonly streetAddressLine1: Scalars['String']
  readonly streetAddressLine2?: InputMaybe<Scalars['String']>
  readonly zipcode: Scalars['String']
}

export type InputAccountContact = {
  readonly accountId?: InputMaybe<Scalars['String']>
  readonly address?: InputMaybe<InputAccountAddress>
  readonly email: Scalars['String']
  readonly erpId?: InputMaybe<Scalars['String']>
  readonly externalId?: InputMaybe<Scalars['String']>
  readonly firstName: Scalars['String']
  readonly id?: InputMaybe<Scalars['String']>
  readonly lastName?: InputMaybe<Scalars['String']>
  readonly phoneNumber?: InputMaybe<Scalars['String']>
  readonly title?: InputMaybe<Scalars['String']>
}

export type InputAccountReceivableContactJson = {
  readonly address?: InputMaybe<InputAccountAddress>
  readonly email: Scalars['String']
  readonly firstName: Scalars['String']
  readonly lastName?: InputMaybe<Scalars['String']>
  readonly phoneNumber?: InputMaybe<Scalars['String']>
}

export type InputAddEscalationPolicyRequest = {
  readonly cycle: EscalationCycle
  readonly description?: InputMaybe<Scalars['String']>
  readonly name: Scalars['String']
  readonly status: EscalationPolicyStatus
  readonly step: Scalars['Long']
  readonly type: EscalationType
}

export type InputAddPlanRequest = {
  readonly billingTerm?: InputMaybe<BillingTerm>
  readonly currency?: InputMaybe<Scalars['String']>
  readonly description?: InputMaybe<Scalars['String']>
  readonly displayName?: InputMaybe<Scalars['String']>
  readonly entityId?: InputMaybe<Scalars['String']>
  readonly entityIds?: InputMaybe<ReadonlyArray<Scalars['String']>>
  readonly externalId?: InputMaybe<Scalars['String']>
  readonly name: Scalars['String']
  readonly planRelationships?: InputMaybe<ReadonlyArray<InputMaybe<InputCatalogRelationship>>>
  readonly productId: Scalars['String']
  readonly replacementPlanIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>
  readonly status: PlanStatus
  readonly templateIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>
}

export type InputAmendAndRenewCreateRequest = {
  readonly customFields?: InputMaybe<ReadonlyArray<InputCustomFieldInput>>
}

export type InputAmendmentOrderRequest = {
  readonly approvalSegmentId?: InputMaybe<Scalars['String']>
  readonly autoRenew?: InputMaybe<Scalars['Boolean']>
  readonly billingContactId?: InputMaybe<Scalars['String']>
  readonly customFields?: InputMaybe<ReadonlyArray<InputMaybe<InputCustomFieldEntry>>>
  readonly documentCustomContent?: InputMaybe<InputDocumentCustomContent>
  readonly documentMasterTemplateId?: InputMaybe<Scalars['String']>
  readonly executedOn?: InputMaybe<Scalars['Long']>
  readonly expiresOn?: InputMaybe<Scalars['Long']>
  readonly id?: InputMaybe<Scalars['String']>
  readonly isPrimaryOrderForSfdcOpportunity?: InputMaybe<Scalars['Boolean']>
  readonly lineItems?: InputMaybe<ReadonlyArray<InputMaybe<InputOrderLineItemRequest>>>
  readonly name?: InputMaybe<Scalars['String']>
  readonly opportunityCrmType?: InputMaybe<OpportunityCrmType>
  readonly orderFormTemplateIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>
  readonly orderType: OrderType
  readonly ownerId?: InputMaybe<Scalars['String']>
  readonly predefinedDiscounts?: InputMaybe<ReadonlyArray<InputMaybe<InputTenantDiscountJson>>>
  readonly purchaseOrderNumber?: InputMaybe<Scalars['String']>
  readonly purchaseOrderRequiredForInvoicing?: InputMaybe<Scalars['Boolean']>
  readonly rampInterval?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Long']>>>
  readonly sfdcOpportunityId?: InputMaybe<Scalars['String']>
  readonly sfdcOpportunityName?: InputMaybe<Scalars['String']>
  readonly sfdcOpportunityStage?: InputMaybe<Scalars['String']>
  readonly sfdcOpportunityType?: InputMaybe<Scalars['String']>
  readonly shippingContactId?: InputMaybe<Scalars['String']>
  readonly startDate: Scalars['Long']
  readonly subscriptionId: Scalars['String']
}

export type InputApiKey = {
  readonly entityId?: InputMaybe<Scalars['String']>
  readonly expiry?: InputMaybe<Scalars['Long']>
  readonly note?: InputMaybe<Scalars['String']>
  readonly role?: InputMaybe<Role>
  readonly userId?: InputMaybe<Scalars['String']>
}

export type InputApplyCreditMemoRequest = {
  readonly amount: Scalars['BigDecimal']
  readonly creditMemoNumber: Scalars['String']
  readonly invoiceAmount: Scalars['BigDecimal']
  readonly invoiceNumber: Scalars['String']
  readonly note?: InputMaybe<Scalars['String']>
}

export type InputApplyPaymentRequest = {
  readonly amount: Scalars['BigDecimal']
  readonly bankFee?: InputMaybe<Scalars['BigDecimal']>
  readonly invoiceAmount: Scalars['String']
  readonly invoiceNumber: Scalars['String']
  readonly note?: InputMaybe<Scalars['String']>
  readonly paymentBankAccountId?: InputMaybe<Scalars['String']>
  readonly paymentDate?: InputMaybe<Scalars['Long']>
  readonly paymentMethodId?: InputMaybe<Scalars['String']>
  readonly paymentType?: InputMaybe<PaymentType>
}

export type InputApprovalFlow = {
  readonly description?: InputMaybe<Scalars['String']>
  readonly entityIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>
  readonly id?: InputMaybe<Scalars['String']>
  readonly name: Scalars['String']
  readonly states: ReadonlyArray<InputMaybe<InputApprovalState>>
  readonly status?: InputMaybe<ApprovalFlowStatus>
  readonly transitionRules: ReadonlyArray<InputMaybe<InputApprovalTransitionRule>>
}

export type InputApprovalFlowInstanceStateUpdate = {
  readonly approvalFlowId: Scalars['String']
  readonly note?: InputMaybe<Scalars['String']>
  readonly stateId: Scalars['String']
  readonly status: ApprovalFlowInstanceStatus
}

export type InputApprovalFlowInstanceStateUpdates = {
  readonly groupId: Scalars['String']
  readonly stateUpdates: ReadonlyArray<InputApprovalFlowInstanceStateUpdate>
}

export type InputApprovalFlowSubmitterNote = {
  readonly id?: InputMaybe<Scalars['String']>
  readonly note: Scalars['String']
  readonly orderId: Scalars['String']
}

export type InputApprovalRole = {
  readonly createdOn?: InputMaybe<Scalars['Long']>
  readonly description?: InputMaybe<Scalars['String']>
  readonly id?: InputMaybe<Scalars['String']>
  readonly name: Scalars['String']
  readonly updatedOn?: InputMaybe<Scalars['Long']>
}

export type InputApprovalRuleConditions = {
  readonly orderCondition?: InputMaybe<Scalars['String']>
  readonly orderLineCondition?: InputMaybe<Scalars['String']>
}

export type InputApprovalSegment = {
  readonly createdOn?: InputMaybe<Scalars['Long']>
  readonly description?: InputMaybe<Scalars['String']>
  readonly id?: InputMaybe<Scalars['String']>
  readonly name: Scalars['String']
  readonly updatedOn?: InputMaybe<Scalars['Long']>
}

export type InputApprovalState = {
  readonly action: InputApprovalStateAction
  readonly approvalGroupId?: InputMaybe<Scalars['String']>
  readonly approverId?: InputMaybe<Scalars['String']>
  readonly approverType?: InputMaybe<ApproverType>
  readonly escalationPolicyId?: InputMaybe<Scalars['String']>
  readonly id: Scalars['String']
  readonly name: Scalars['String']
}

export type InputApprovalStateAction = {
  readonly emailGroupId: Scalars['String']
}

export type InputApprovalTransitionRule = {
  readonly condition?: InputMaybe<Scalars['String']>
  readonly fromState?: InputMaybe<Scalars['String']>
  readonly id: Scalars['String']
  readonly name: Scalars['String']
  readonly ruleConditions?: InputMaybe<InputApprovalRuleConditions>
  readonly toState: Scalars['String']
}

export type InputAttributeMapping = {
  readonly key: Scalars['String']
  readonly value: Scalars['String']
}

export type InputAttributeReference = {
  readonly attributeDefinitionId: Scalars['String']
  readonly attributeValue: Scalars['String']
}

export type InputAuthSamlIntegrationRequest = {
  readonly attributeMapping: ReadonlyArray<InputMaybe<InputAttributeMapping>>
  readonly metadataUrl?: InputMaybe<Scalars['String']>
  readonly providerName?: InputMaybe<Scalars['String']>
}

export type InputBillingEventInput = {
  readonly amount: Scalars['BigDecimal']
  readonly subscriptionChargeId?: InputMaybe<Scalars['String']>
  readonly subscriptionId?: InputMaybe<Scalars['String']>
  readonly triggerOn: Scalars['Long']
}

export type InputBulkInvoiceRunInput = {
  readonly chargeInclusionOption?: InputMaybe<InvoiceChargeInclusionOption>
  readonly description?: InputMaybe<Scalars['String']>
  readonly entityId?: InputMaybe<Scalars['String']>
  readonly invoiceDate?: InputMaybe<Scalars['Long']>
  readonly name?: InputMaybe<Scalars['String']>
  readonly targetDate?: InputMaybe<Scalars['Long']>
}

export type InputClmChatMessageDetail = {
  readonly createdOn?: InputMaybe<Scalars['Long']>
  readonly message: Scalars['String']
  readonly threadId?: InputMaybe<Scalars['String']>
  readonly userIdentifier: Scalars['String']
}

export type InputClmCreateThreadRequest = {
  readonly documentLinkId: Scalars['String']
  readonly firstMessage: InputClmChatMessageDetail
  readonly pin: Scalars['String']
}

export type InputCancelAndRestructureRequest = {
  readonly accountId?: InputMaybe<Scalars['String']>
  readonly approvalSegmentId?: InputMaybe<Scalars['String']>
  readonly autoRenew?: InputMaybe<Scalars['Boolean']>
  readonly billingAnchorDate?: InputMaybe<Scalars['Long']>
  readonly billingContactId?: InputMaybe<Scalars['String']>
  readonly billingCycle?: InputMaybe<InputRecurrence>
  readonly billingTerm?: InputMaybe<BillingTerm>
  readonly compositeOrderId?: InputMaybe<Scalars['String']>
  readonly crmOpportunityId?: InputMaybe<Scalars['String']>
  readonly crmOpportunityName?: InputMaybe<Scalars['String']>
  readonly crmOpportunityStage?: InputMaybe<Scalars['String']>
  readonly crmOpportunityType?: InputMaybe<Scalars['String']>
  readonly currency?: InputMaybe<Scalars['String']>
  readonly customFields?: InputMaybe<ReadonlyArray<InputMaybe<InputCustomFieldEntry>>>
  readonly documentCustomContent?: InputMaybe<InputDocumentCustomContent>
  readonly documentMasterTemplateId?: InputMaybe<Scalars['String']>
  readonly endDate?: InputMaybe<Scalars['Long']>
  readonly executedOn?: InputMaybe<Scalars['Long']>
  readonly expiresOn?: InputMaybe<Scalars['Long']>
  readonly id?: InputMaybe<Scalars['String']>
  readonly isPrimaryOrderForSfdcOpportunity?: InputMaybe<Scalars['Boolean']>
  readonly lineItems?: InputMaybe<ReadonlyArray<InputMaybe<InputOrderLineItemRequest>>>
  readonly name?: InputMaybe<Scalars['String']>
  readonly opportunityCrmType?: InputMaybe<OpportunityCrmType>
  readonly orderFormTemplateIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>
  readonly orderType: OrderType
  readonly ownerId?: InputMaybe<Scalars['String']>
  readonly paymentTerm?: InputMaybe<Scalars['String']>
  readonly predefinedDiscounts?: InputMaybe<ReadonlyArray<InputMaybe<InputTenantDiscountJson>>>
  readonly purchaseOrderNumber?: InputMaybe<Scalars['String']>
  readonly purchaseOrderRequiredForInvoicing?: InputMaybe<Scalars['Boolean']>
  readonly rampInterval?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Long']>>>
  readonly restructureForSubscriptionId?: InputMaybe<Scalars['String']>
  readonly shippingContactId?: InputMaybe<Scalars['String']>
  readonly startDate: Scalars['Long']
  readonly subscriptionId: Scalars['String']
  readonly termLength?: InputMaybe<InputRecurrence>
}

export type InputCatalogRelationship = {
  readonly catalogRelationshipType?: InputMaybe<CatalogRelationshipType>
  readonly fromPlanId?: InputMaybe<Scalars['String']>
  readonly fromProductId?: InputMaybe<Scalars['String']>
  readonly relationshipId?: InputMaybe<Scalars['String']>
  readonly toPlanId?: InputMaybe<Scalars['String']>
  readonly toProductId?: InputMaybe<Scalars['String']>
}

export type InputCharge = {
  readonly amount?: InputMaybe<Scalars['BigDecimal']>
  readonly billingCycle?: InputMaybe<BillingCycle>
  readonly billingTerm?: InputMaybe<BillingTerm>
  readonly chargeModel: ChargeModel
  readonly defaultQuantity?: InputMaybe<Scalars['Long']>
  readonly description?: InputMaybe<Scalars['String']>
  readonly displayName?: InputMaybe<Scalars['String']>
  readonly durationInMonths?: InputMaybe<Scalars['Long']>
  readonly erpId?: InputMaybe<Scalars['String']>
  readonly externalId?: InputMaybe<Scalars['String']>
  readonly id?: InputMaybe<Scalars['String']>
  readonly isCustom?: InputMaybe<Scalars['Boolean']>
  readonly isDrawdown?: InputMaybe<Scalars['Boolean']>
  readonly isEventBased?: InputMaybe<Scalars['Boolean']>
  readonly isListPriceEditable?: InputMaybe<Scalars['Boolean']>
  readonly isRenewable: Scalars['Boolean']
  readonly itemCode?: InputMaybe<Scalars['String']>
  readonly ledgerAccountMapping?: InputMaybe<InputLedgerAccountMapping>
  readonly maxAmount?: InputMaybe<Scalars['BigDecimal']>
  readonly maxQuantity?: InputMaybe<Scalars['Long']>
  readonly minAmount?: InputMaybe<Scalars['BigDecimal']>
  readonly minQuantity?: InputMaybe<Scalars['Long']>
  readonly minimumCommitBaseChargeId?: InputMaybe<Scalars['String']>
  readonly name: Scalars['String']
  readonly overageBaseChargeId?: InputMaybe<Scalars['String']>
  readonly percent?: InputMaybe<Scalars['BigDecimal']>
  readonly percentDerivedFrom?: InputMaybe<PercentDerivedFrom>
  readonly planId?: InputMaybe<Scalars['String']>
  readonly priceTiers?: InputMaybe<ReadonlyArray<InputpriceTier>>
  readonly productCode?: InputMaybe<Scalars['String']>
  readonly rateCardId?: InputMaybe<Scalars['String']>
  readonly recognitionRuleId?: InputMaybe<Scalars['String']>
  readonly recurrence?: InputMaybe<InputRecurrence>
  readonly shouldTrackArr?: InputMaybe<Scalars['Boolean']>
  readonly targetPlanIds?: InputMaybe<ReadonlyArray<Scalars['String']>>
  readonly taxRateId?: InputMaybe<Scalars['String']>
  readonly type: ChargeType
  readonly unitOfMeasureId?: InputMaybe<Scalars['String']>
}

export type InputChargeDefaultAttributeReferences = {
  readonly attributeReferences: ReadonlyArray<InputMaybe<InputAttributeReference>>
  readonly chargeId: Scalars['String']
  readonly id?: InputMaybe<Scalars['String']>
}

export type InputChargePartial = {
  readonly defaultQuantity?: InputMaybe<Scalars['Long']>
  readonly description?: InputMaybe<Scalars['String']>
  readonly displayName?: InputMaybe<Scalars['String']>
  readonly erpId?: InputMaybe<Scalars['String']>
  readonly externalId?: InputMaybe<Scalars['String']>
  readonly id?: InputMaybe<Scalars['String']>
  readonly isRenewable: Scalars['Boolean']
  readonly itemCode?: InputMaybe<Scalars['String']>
  readonly maxAmount?: InputMaybe<Scalars['BigDecimal']>
  readonly maxQuantity?: InputMaybe<Scalars['Long']>
  readonly minAmount?: InputMaybe<Scalars['BigDecimal']>
  readonly minQuantity?: InputMaybe<Scalars['Long']>
  readonly name: Scalars['String']
  readonly productCode?: InputMaybe<Scalars['String']>
  readonly recognitionRuleId?: InputMaybe<Scalars['String']>
  readonly taxRateId?: InputMaybe<Scalars['String']>
  readonly unitOfMeasureId?: InputMaybe<Scalars['String']>
}

export type InputCompanyContactJson = {
  readonly address?: InputMaybe<InputAccountAddress>
  readonly email: Scalars['String']
  readonly firstName: Scalars['String']
  readonly lastName?: InputMaybe<Scalars['String']>
  readonly phoneNumber?: InputMaybe<Scalars['String']>
}

export type InputCompositeOrderFieldsUpdateRequest = {
  readonly crmOpportunityId?: InputMaybe<Scalars['String']>
  readonly documentMasterTemplateId?: InputMaybe<Scalars['String']>
}

export type InputCreateAccountInput = {
  readonly address?: InputMaybe<InputAccountAddress>
  readonly crmId?: InputMaybe<Scalars['String']>
  readonly crmType?: InputMaybe<Scalars['String']>
  readonly currency?: InputMaybe<Scalars['String']>
  readonly description?: InputMaybe<Scalars['String']>
  readonly entityIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>
  readonly excludeFromBatchOperations: Scalars['Boolean']
  readonly excludeFromDunning: Scalars['Boolean']
  readonly externalId?: InputMaybe<Scalars['String']>
  readonly hasAutomaticPayment: Scalars['Boolean']
  readonly isReseller: Scalars['Boolean']
  readonly name: Scalars['String']
  readonly phoneNumber?: InputMaybe<Scalars['String']>
  readonly supportedPaymentTypes?: InputMaybe<ReadonlyArray<InputMaybe<PaymentType>>>
  readonly taxExemptionUseCode?: InputMaybe<TaxExemptionUseCode>
}

export type InputCreateProposalRequest = {
  readonly currencyCode?: InputMaybe<Scalars['String']>
  readonly planGroupIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>
  readonly proposalFor?: InputMaybe<Scalars['String']>
}

export type InputCreditMemoLineItem = {
  readonly amount: Scalars['BigDecimal']
  readonly chargeId: Scalars['String']
  readonly endDate?: InputMaybe<Scalars['Long']>
  readonly startDate?: InputMaybe<Scalars['Long']>
}

export type InputCreditMemoRequest = {
  readonly accountId: Scalars['String']
  readonly amount: Scalars['BigDecimal']
  readonly billingContactId?: InputMaybe<Scalars['String']>
  readonly creditMemoDate?: InputMaybe<Scalars['Long']>
  readonly creditReason?: InputMaybe<Scalars['String']>
  readonly currencyCode?: InputMaybe<Scalars['String']>
  readonly endDate?: InputMaybe<Scalars['Long']>
  readonly entityId?: InputMaybe<Scalars['String']>
  readonly lineItems: ReadonlyArray<InputMaybe<InputCreditMemoLineItem>>
  readonly notes?: InputMaybe<Scalars['String']>
  readonly postedDate?: InputMaybe<Scalars['Long']>
  readonly startDate?: InputMaybe<Scalars['Long']>
  readonly status: CreditMemoStatus
}

export type InputCurrencyConversionRate = {
  readonly conversionRate: Scalars['BigDecimal']
  readonly effectiveDate: Scalars['Long']
  readonly fromCurrency: Scalars['String']
  readonly id?: InputMaybe<Scalars['String']>
  readonly isOverridden?: InputMaybe<Scalars['Boolean']>
  readonly roundingPrecision?: InputMaybe<Scalars['BigDecimal']>
  readonly roundingTreatment: RoundingMode
  readonly toCurrency: Scalars['String']
  readonly updatedBy?: InputMaybe<Scalars['String']>
}

export type InputCurrencyTypeSetting = {
  readonly catalogCurrency?: InputMaybe<Scalars['String']>
  readonly currencyTypeSettingId?: InputMaybe<Scalars['String']>
  readonly id?: InputMaybe<Scalars['String']>
  readonly planCurrencySettingType: PlanCurrencySettingType
}

export type InputCustomFieldDefault = {
  readonly selections?: InputMaybe<ReadonlyArray<Scalars['String']>>
  readonly value?: InputMaybe<Scalars['String']>
}

export type InputCustomFieldDefinitionCreate = {
  readonly defaultValue?: InputMaybe<InputCustomFieldDefault>
  readonly fieldLabel?: InputMaybe<Scalars['String']>
  readonly fieldName: Scalars['String']
  readonly fieldType: CustomFieldType
  readonly options?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>
  readonly parentObjectType: CustomFieldParentType
  readonly required?: InputMaybe<Scalars['Boolean']>
  readonly source?: InputMaybe<CustomFieldSource>
}

export type InputCustomFieldDefinitionUpdate = {
  readonly defaultValue?: InputMaybe<InputCustomFieldDefault>
  readonly fieldLabel?: InputMaybe<Scalars['String']>
  readonly fieldName: Scalars['String']
  readonly fieldType?: InputMaybe<CustomFieldType>
  readonly options?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>
}

export type InputCustomFieldEntry = {
  readonly defaultValue?: InputMaybe<InputCustomFieldDefault>
  readonly id: Scalars['String']
  readonly label?: InputMaybe<Scalars['String']>
  readonly name: Scalars['String']
  readonly options?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>
  readonly required?: InputMaybe<Scalars['Boolean']>
  readonly selections?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>
  readonly source?: InputMaybe<CustomFieldSource>
  readonly type?: InputMaybe<CustomFieldType>
  readonly value?: InputMaybe<Scalars['String']>
}

export type InputCustomFieldInput = {
  readonly id: Scalars['String']
  readonly selections?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>
  readonly value?: InputMaybe<Scalars['String']>
}

export type InputCustomPredefinedTemplateOnOrder = {
  readonly content: Scalars['String']
  readonly description?: InputMaybe<Scalars['String']>
  readonly id?: InputMaybe<Scalars['String']>
  readonly name: Scalars['String']
  readonly orderId: Scalars['String']
}

export type InputDiscount = {
  readonly discountAmount?: InputMaybe<Scalars['BigDecimal']>
  readonly discountedPrice?: InputMaybe<Scalars['BigDecimal']>
  readonly name?: InputMaybe<Scalars['String']>
  readonly percent?: InputMaybe<Scalars['BigDecimal']>
  readonly status?: InputMaybe<DiscountStatus>
}

export type InputDiscountJson = {
  readonly description?: InputMaybe<Scalars['String']>
  readonly entityIds?: InputMaybe<ReadonlyArray<Scalars['String']>>
  readonly id?: InputMaybe<Scalars['String']>
  readonly inUse?: InputMaybe<Scalars['Boolean']>
  readonly name: Scalars['String']
  readonly percent: Scalars['BigDecimal']
  readonly status?: InputMaybe<DiscountStatus>
  readonly type: Scalars['String']
}

export type InputDocuSignIntegrationRequest = {
  readonly clientId: Scalars['String']
  readonly clientSecret: Scalars['String']
  readonly environment: DocuSignEnvironmentType
}

export type InputDocumentCustomContent = {
  readonly content: Scalars['String']
  readonly id?: InputMaybe<Scalars['String']>
  readonly orderId: Scalars['String']
  readonly title?: InputMaybe<Scalars['String']>
}

export type InputDocumentMasterTemplate = {
  readonly configuration?: InputMaybe<InputDocumentMasterTemplateConfiguration>
  readonly content: Scalars['String']
  readonly entityIds?: InputMaybe<ReadonlyArray<Scalars['String']>>
  readonly id?: InputMaybe<Scalars['String']>
  readonly inUse?: InputMaybe<Scalars['Boolean']>
  readonly isDefault?: InputMaybe<Scalars['Boolean']>
  readonly isFullHtml?: InputMaybe<Scalars['Boolean']>
  readonly name: Scalars['String']
  readonly status?: InputMaybe<DocumentMasterTemplateStatus>
  readonly type: DocumentTemplateType
}

export type InputDocumentMasterTemplateConfiguration = {
  readonly contractTermsColumns: ReadonlyArray<InputMaybe<ContractTermsColumn>>
  readonly contractTermsTableStyle: ContractTermsTableStyle
  readonly dunningReminderType?: InputMaybe<DunningReminderType>
  readonly fontFamily: Scalars['String']
  readonly fontSize: Scalars['String']
  readonly headerStyle: HeaderStyle
  readonly lineItemColumns: ReadonlyArray<InputMaybe<LineItemColumn>>
  readonly lineItemTableStyle: LineItemTableStyle
  readonly primaryColor: Scalars['String']
  readonly secondaryColor: Scalars['String']
  readonly showArrByYears: Scalars['Boolean']
  readonly showBreakupByBillingCycle: Scalars['Boolean']
  readonly showLineBreakupByPredefinedDiscounts?: InputMaybe<Scalars['Boolean']>
  readonly showNextInvoiceAmount: Scalars['Boolean']
  readonly version: Scalars['Int']
}

export type InputDocumentSection = {
  readonly entityIds?: InputMaybe<ReadonlyArray<Scalars['String']>>
  readonly id?: InputMaybe<Scalars['String']>
  readonly location: DocumentSectionLocation
  readonly name: Scalars['String']
  readonly title: Scalars['String']
}

export type InputDocumentTemplateRequest = {
  readonly content: Scalars['String']
  readonly description?: InputMaybe<Scalars['String']>
  readonly entityIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>
  readonly id?: InputMaybe<Scalars['String']>
  readonly isUserSelectable?: InputMaybe<Scalars['Boolean']>
  readonly name: Scalars['String']
  readonly sectionUuid?: InputMaybe<Scalars['String']>
  readonly type: DocumentTemplateType
}

export type InputDunningSettingDetails = {
  readonly dunningTypeStatus?: InputMaybe<ReadonlyArray<InputMaybe<InputDunningTypeStatus>>>
  readonly isEnabled?: InputMaybe<Scalars['Boolean']>
}

export type InputDunningTypeStatus = {
  readonly dunningType: DunningReminderType
  readonly isEnabled: Scalars['Boolean']
}

export type InputEmailContact = {
  readonly email: Scalars['String']
  readonly entityIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>
  readonly id: Scalars['String']
  readonly name: Scalars['String']
  readonly type: ContactType
}

export type InputEmailNotifiersList = {
  readonly bccIds: ReadonlyArray<InputMaybe<Scalars['String']>>
  readonly ccIds: ReadonlyArray<InputMaybe<Scalars['String']>>
  readonly toIds: ReadonlyArray<InputMaybe<Scalars['String']>>
}

export type InputEntity = {
  readonly displayId?: InputMaybe<Scalars['String']>
  readonly id: Scalars['String']
  readonly name: Scalars['String']
}

export type InputEntityJson = {
  readonly accountReceivableContact?: InputMaybe<InputAccountReceivableContactJson>
  readonly companyContact?: InputMaybe<InputCompanyContactJson>
  readonly creditMemoConfig?: InputMaybe<InputNumberConfig>
  readonly displayId?: InputMaybe<Scalars['String']>
  readonly erpId?: InputMaybe<Scalars['String']>
  readonly functionalCurrency: Scalars['String']
  readonly id?: InputMaybe<Scalars['String']>
  readonly invoiceConfig?: InputMaybe<InputNumberConfig>
  readonly invoiceConfigId?: InputMaybe<Scalars['String']>
  readonly name: Scalars['String']
  readonly prorationMode: ProrationMode
  readonly prorationScheme: ProrationScheme
  readonly timezone?: InputMaybe<Scalars['String']>
  readonly wireInstruction?: InputMaybe<Scalars['String']>
}

export type InputEntityPatchRequest = {
  readonly accountReceivableContact?: InputMaybe<InputAccountReceivableContactJson>
  readonly companyContact?: InputMaybe<InputCompanyContactJson>
  readonly creditMemoConfig?: InputMaybe<InputNumberConfig>
  readonly displayId?: InputMaybe<Scalars['String']>
  readonly entityId?: InputMaybe<Scalars['String']>
  readonly erpId?: InputMaybe<Scalars['String']>
  readonly functionalCurrency?: InputMaybe<Scalars['String']>
  readonly invoiceConfig?: InputMaybe<InputNumberConfig>
  readonly invoiceConfigId?: InputMaybe<Scalars['String']>
  readonly name?: InputMaybe<Scalars['String']>
  readonly prorationMode?: InputMaybe<ProrationMode>
  readonly prorationScheme?: InputMaybe<ProrationScheme>
  readonly timezone?: InputMaybe<Scalars['TimeZone']>
  readonly wireInstruction?: InputMaybe<Scalars['String']>
}

export type InputEsignRequest = {
  readonly accountSignatory?: InputMaybe<InputEmailContact>
  readonly additionalRecipients?: InputMaybe<ReadonlyArray<InputMaybe<InputEmailContact>>>
  readonly isComposite?: InputMaybe<Scalars['Boolean']>
  readonly orderId: Scalars['String']
  readonly tenantSignatory?: InputMaybe<InputEmailContact>
}

export type InputEsignTenantSignatory = {
  readonly userId: Scalars['String']
}

export type InputIntegrationRequest = {
  readonly clientId: Scalars['String']
  readonly clientSecret: Scalars['String']
  readonly environment?: InputMaybe<Scalars['String']>
  readonly redirectUri?: InputMaybe<Scalars['String']>
  readonly targetService: IntegrationTargetService
}

export type InputLedgerAccount = {
  readonly accountCode: Scalars['String']
  readonly accountType: LedgerAccountType
  readonly description?: InputMaybe<Scalars['String']>
  readonly entityIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>
  readonly id?: InputMaybe<Scalars['String']>
  readonly inUse?: InputMaybe<Scalars['Boolean']>
  readonly isDefault?: InputMaybe<Scalars['Boolean']>
  readonly name: Scalars['String']
}

export type InputLedgerAccountMapping = {
  readonly contractAssetAccountId?: InputMaybe<Scalars['String']>
  readonly deferredRevenueAccountId?: InputMaybe<Scalars['String']>
  readonly recognizedRevenueAccountId?: InputMaybe<Scalars['String']>
  readonly taxLiabilityAccountId?: InputMaybe<Scalars['String']>
}

export type InputNotification = {
  readonly description?: InputMaybe<Scalars['String']>
  readonly name?: InputMaybe<Scalars['String']>
  readonly notificationId?: InputMaybe<Scalars['String']>
  readonly notificationTarget?: InputMaybe<Scalars['String']>
  readonly notificationTargetType?: InputMaybe<NotificationTargetType>
  readonly subscribedEvents?: InputMaybe<ReadonlyArray<InputMaybe<NotificationEventType>>>
}

export type InputNumberConfig = {
  readonly configId?: InputMaybe<Scalars['String']>
  readonly length: Scalars['Int']
  readonly nextSequenceNumber?: InputMaybe<Scalars['Long']>
  readonly prefix: Scalars['String']
  readonly scheme: NumberScheme
}

export type InputOrderLineItemRequest = {
  readonly action?: InputMaybe<ActionType>
  readonly arrOverride?: InputMaybe<Scalars['BigDecimal']>
  readonly attributeReferences?: InputMaybe<ReadonlyArray<InputMaybe<InputAttributeReference>>>
  readonly chargeId: Scalars['String']
  readonly customFields?: InputMaybe<ReadonlyArray<InputMaybe<InputCustomFieldEntry>>>
  readonly discounts?: InputMaybe<ReadonlyArray<InputMaybe<InputDiscount>>>
  readonly effectiveDate?: InputMaybe<Scalars['Long']>
  readonly endDate?: InputMaybe<Scalars['Long']>
  readonly id?: InputMaybe<Scalars['String']>
  readonly isDryRunItem?: InputMaybe<Scalars['Boolean']>
  readonly isRamp?: InputMaybe<Scalars['Boolean']>
  readonly itemGroupId?: InputMaybe<Scalars['String']>
  readonly listPriceOverrideRatio?: InputMaybe<Scalars['BigDecimal']>
  readonly listUnitPrice?: InputMaybe<Scalars['BigDecimal']>
  readonly planId: Scalars['String']
  readonly predefinedDiscounts?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>
  readonly quantity?: InputMaybe<Scalars['Long']>
  readonly subscriptionChargeId?: InputMaybe<Scalars['String']>
}

export type InputOrderRequest = {
  readonly accountId?: InputMaybe<Scalars['String']>
  readonly approvalSegmentId?: InputMaybe<Scalars['String']>
  readonly attachmentId?: InputMaybe<Scalars['String']>
  readonly autoRenew?: InputMaybe<Scalars['Boolean']>
  readonly billingAnchorDate?: InputMaybe<Scalars['Long']>
  readonly billingContactId?: InputMaybe<Scalars['String']>
  readonly billingCycle?: InputMaybe<InputRecurrence>
  readonly billingTerm?: InputMaybe<BillingTerm>
  readonly currency?: InputMaybe<Scalars['String']>
  readonly customFields?: InputMaybe<ReadonlyArray<InputMaybe<InputCustomFieldEntry>>>
  readonly documentCustomContent?: InputMaybe<InputDocumentCustomContent>
  readonly documentMasterTemplateId?: InputMaybe<Scalars['String']>
  readonly endDate?: InputMaybe<Scalars['Long']>
  readonly entityId?: InputMaybe<Scalars['String']>
  readonly executedOn?: InputMaybe<Scalars['Long']>
  readonly expiresOn?: InputMaybe<Scalars['Long']>
  readonly externalId?: InputMaybe<Scalars['String']>
  readonly id?: InputMaybe<Scalars['String']>
  readonly isPrimaryOrderForSfdcOpportunity?: InputMaybe<Scalars['Boolean']>
  readonly lineItems?: InputMaybe<ReadonlyArray<InputMaybe<InputOrderLineItemRequest>>>
  readonly name?: InputMaybe<Scalars['String']>
  readonly opportunityCrmType?: InputMaybe<OpportunityCrmType>
  readonly orderFormTemplateIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>
  readonly orderType: OrderType
  readonly ownerId?: InputMaybe<Scalars['String']>
  readonly paymentTerm?: InputMaybe<Scalars['String']>
  readonly predefinedDiscounts?: InputMaybe<ReadonlyArray<InputMaybe<InputTenantDiscountJson>>>
  readonly purchaseOrderNumber?: InputMaybe<Scalars['String']>
  readonly purchaseOrderRequiredForInvoicing?: InputMaybe<Scalars['Boolean']>
  readonly rampInterval?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Long']>>>
  readonly renewalForSubscriptionId?: InputMaybe<Scalars['String']>
  readonly sfdcOpportunityId?: InputMaybe<Scalars['String']>
  readonly sfdcOpportunityName?: InputMaybe<Scalars['String']>
  readonly sfdcOpportunityStage?: InputMaybe<Scalars['String']>
  readonly sfdcOpportunityType?: InputMaybe<Scalars['String']>
  readonly shippingContactId?: InputMaybe<Scalars['String']>
  readonly startDate: Scalars['Long']
  readonly startDateType?: InputMaybe<OrderStartDateType>
  readonly subscriptionId?: InputMaybe<Scalars['String']>
  readonly termLength?: InputMaybe<InputRecurrence>
}

export type InputPaymentBankAccountJson = {
  readonly cashLedgerAccountId: Scalars['String']
  readonly createdOn?: InputMaybe<Scalars['Long']>
  readonly currencyCode: Scalars['String']
  readonly description?: InputMaybe<Scalars['String']>
  readonly entityIds: ReadonlyArray<InputMaybe<Scalars['String']>>
  readonly expenseLedgerAccountId: Scalars['String']
  readonly externalId?: InputMaybe<Scalars['String']>
  readonly id?: InputMaybe<Scalars['String']>
  readonly name: Scalars['String']
  readonly status: PaymentBankAccountStatus
  readonly updatedOn?: InputMaybe<Scalars['Long']>
}

export type InputPaymentTermSettings = {
  readonly customPaymentTermsAllowed: Scalars['Boolean']
  readonly defaultPaymentTerm: Scalars['String']
  readonly defaultPaymentTerms: ReadonlyArray<Scalars['String']>
}

export type InputPlan = {
  readonly charges: ReadonlyArray<InputCharge>
  readonly currency?: InputMaybe<Scalars['String']>
  readonly description?: InputMaybe<Scalars['String']>
  readonly displayName?: InputMaybe<Scalars['String']>
  readonly entityIds?: InputMaybe<ReadonlyArray<Scalars['String']>>
  readonly externalId?: InputMaybe<Scalars['String']>
  readonly id?: InputMaybe<Scalars['String']>
  readonly name: Scalars['String']
  readonly productId: Scalars['String']
  readonly replacementPlanIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>
  readonly status: PlanStatus
  readonly templateIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>
  readonly updatedOn?: InputMaybe<Scalars['Long']>
}

export type InputPlanAdditionCustomizationInput = {
  readonly accountId: Scalars['String']
}

export type InputPlanGroupInterface = {
  readonly currency?: InputMaybe<Scalars['String']>
  readonly description?: InputMaybe<Scalars['String']>
  readonly id?: InputMaybe<Scalars['String']>
  readonly name: Scalars['String']
  readonly planGroupItems?: InputMaybe<ReadonlyArray<InputMaybe<InputPlanGroupItemInterface>>>
}

export type InputPlanGroupItemInterface = {
  readonly chargeId: Scalars['String']
  readonly customUnitPrice?: InputMaybe<Scalars['BigDecimal']>
  readonly defaultDiscountRatio?: InputMaybe<Scalars['BigDecimal']>
  readonly defaultQuantity?: InputMaybe<Scalars['Long']>
  readonly planId: Scalars['String']
}

export type InputPredefinedReportRequest = {
  readonly duration?: InputMaybe<InputReportDuration>
  readonly reportDate?: InputMaybe<Scalars['Long']>
  readonly reportId: Scalars['String']
}

export type InputPriceAttribute = {
  readonly id?: InputMaybe<Scalars['String']>
  readonly name: Scalars['String']
  readonly type?: InputMaybe<PriceAttributeType>
  readonly values: ReadonlyArray<InputMaybe<Scalars['String']>>
}

export type InputPriceTableElement = {
  readonly attributeReferences: ReadonlyArray<InputAttributeReference>
  readonly price: Scalars['BigDecimal']
}

export type InputRateCard = {
  readonly currencyCode: Scalars['String']
  readonly description?: InputMaybe<Scalars['String']>
  readonly entityIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>
  readonly id?: InputMaybe<Scalars['String']>
  readonly name: Scalars['String']
  readonly priceTable: ReadonlyArray<InputPriceTableElement>
}

export type InputRawUsage = {
  readonly aliasId?: InputMaybe<Scalars['String']>
  readonly attributeReferences?: InputMaybe<ReadonlyArray<InputMaybe<InputAttributeReference>>>
  readonly chargeId?: InputMaybe<Scalars['String']>
  readonly id: Scalars['String']
  readonly subscriptionId?: InputMaybe<Scalars['String']>
  readonly usageQuantity: Scalars['Long']
  readonly usageTime?: InputMaybe<Scalars['Long']>
}

export type InputRawUsagesData = {
  readonly data: ReadonlyArray<InputMaybe<InputRawUsage>>
}

export type InputRecognitionRule = {
  readonly deferredRevenueAccountId?: InputMaybe<Scalars['String']>
  readonly distributionMethod?: InputMaybe<RecognitionDistributionMethod>
  readonly entityIds?: InputMaybe<ReadonlyArray<Scalars['String']>>
  readonly id?: InputMaybe<Scalars['String']>
  readonly inUse?: InputMaybe<Scalars['Boolean']>
  readonly isCatchupRequired?: InputMaybe<Scalars['Boolean']>
  readonly name: Scalars['String']
  readonly recognitionDateAlignment?: InputMaybe<RecognitionDateAlignment>
  readonly recognitionEventType?: InputMaybe<RecognitionEventType>
  readonly recognitionType: RecognitionType
  readonly recognizedRevenueAccountId?: InputMaybe<Scalars['String']>
  readonly source: RecognitionSource
}

export type InputRecurrence = {
  readonly cycle: Cycle
  readonly step: Scalars['Int']
}

export type InputRefundRequest = {
  readonly amount: Scalars['BigDecimal']
  readonly createdBy: Scalars['String']
  readonly creditMemoNumber: Scalars['String']
  readonly notes?: InputMaybe<Scalars['String']>
  readonly paymentId: Scalars['String']
  readonly paymentMethodType?: InputMaybe<Scalars['String']>
  readonly referenceId?: InputMaybe<Scalars['String']>
  readonly refundDate: Scalars['Long']
}

export type InputReportDuration = {
  readonly end: Scalars['Long']
  readonly start: Scalars['Long']
}

export type InputSalesforceIntegration = {
  readonly baseLoginUrl?: InputMaybe<Scalars['String']>
  readonly clientId: Scalars['String']
  readonly clientSecret: Scalars['String']
  readonly redirectUri?: InputMaybe<Scalars['String']>
}

export type InputSetting = {
  readonly defaultTimeZone?: InputMaybe<Scalars['String']>
  readonly globalBccEmail?: InputMaybe<Scalars['String']>
  readonly isUpdateOrderStartDateEnabled?: InputMaybe<Scalars['Boolean']>
  readonly orderExpiryDurationInDays?: InputMaybe<Scalars['String']>
  readonly percentDerivedFrom?: InputMaybe<PercentDerivedFrom>
  readonly signingOrder?: InputMaybe<SigningOrder>
  readonly supportedCurrencies?: InputMaybe<ReadonlyArray<Scalars['String']>>
  readonly tenantSettingSeal?: InputMaybe<TenantSettingSeal>
}

export type InputSubscriptionUpdate = {
  readonly activationDate?: InputMaybe<Scalars['Long']>
  readonly autoRenew?: InputMaybe<Scalars['Boolean']>
  readonly billingContactId?: InputMaybe<Scalars['String']>
  readonly emailNotifiersList?: InputMaybe<InputEmailNotifiersList>
  readonly externalId?: InputMaybe<Scalars['String']>
  readonly name?: InputMaybe<Scalars['String']>
  readonly purchaseOrderNumber?: InputMaybe<Scalars['String']>
  readonly purchaseOrderRequiredForInvoicing?: InputMaybe<Scalars['Boolean']>
  readonly shippingContactId?: InputMaybe<Scalars['String']>
}

export type InputTenant = {
  readonly address?: InputMaybe<InputAccountAddress>
  readonly createdOn?: InputMaybe<Scalars['Long']>
  readonly email?: InputMaybe<Scalars['String']>
  readonly hasHubSpotIntegration: Scalars['Boolean']
  readonly hasSalesforceIntegration: Scalars['Boolean']
  readonly hasSso?: InputMaybe<Scalars['Boolean']>
  readonly id?: InputMaybe<Scalars['String']>
  readonly isDeleted?: InputMaybe<Scalars['Boolean']>
  readonly isTest?: InputMaybe<Scalars['Boolean']>
  readonly name: Scalars['String']
  readonly phoneNumber?: InputMaybe<Scalars['String']>
  readonly tenantSetting?: InputMaybe<InputSetting>
}

export type InputTenantDiscountJson = {
  readonly id?: InputMaybe<Scalars['String']>
  readonly percent?: InputMaybe<Scalars['BigDecimal']>
}

export type InputUnapplyCreditMemoRequest = {
  readonly creditMemoBalanceAmount: Scalars['BigDecimal']
  readonly creditMemoNumber: Scalars['String']
  readonly invoiceBalanceAmount: Scalars['BigDecimal']
  readonly invoiceNumber: Scalars['String']
  readonly note?: InputMaybe<Scalars['String']>
  readonly settlementToUnapplyId: Scalars['String']
}

export type InputUnitOfMeasureRequest = {
  readonly description?: InputMaybe<Scalars['String']>
  readonly id?: InputMaybe<Scalars['String']>
  readonly name: Scalars['String']
}

export type InputUpdateAccountInput = {
  readonly address?: InputMaybe<InputAccountAddress>
  readonly crmId?: InputMaybe<Scalars['String']>
  readonly crmType?: InputMaybe<Scalars['String']>
  readonly currency?: InputMaybe<Scalars['String']>
  readonly description?: InputMaybe<Scalars['String']>
  readonly entityIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>
  readonly excludeFromBatchOperations: Scalars['Boolean']
  readonly excludeFromDunning: Scalars['Boolean']
  readonly externalId?: InputMaybe<Scalars['String']>
  readonly hasAutomaticPayment: Scalars['Boolean']
  readonly id: Scalars['String']
  readonly isReseller: Scalars['Boolean']
  readonly name: Scalars['String']
  readonly phoneNumber?: InputMaybe<Scalars['String']>
  readonly supportedPaymentTypes?: InputMaybe<ReadonlyArray<InputMaybe<PaymentType>>>
  readonly taxExemptionUseCode?: InputMaybe<TaxExemptionUseCode>
}

export type InputUpdateEscalationPolicyRequest = {
  readonly cycle: EscalationCycle
  readonly description?: InputMaybe<Scalars['String']>
  readonly id: Scalars['String']
  readonly name: Scalars['String']
  readonly status: EscalationPolicyStatus
  readonly step: Scalars['Long']
  readonly type: EscalationType
}

export type InputUpdateInvoiceMutation = {
  readonly billingContactId?: InputMaybe<Scalars['String']>
  readonly dueDate?: InputMaybe<Scalars['Long']>
  readonly emailNotifiersList?: InputMaybe<InputEmailNotifiersList>
  readonly invoiceDate: Scalars['Long']
  readonly note?: InputMaybe<Scalars['String']>
  readonly purchaseOrderNumber?: InputMaybe<Scalars['String']>
}

export type InputUpdatePlanRequest = {
  readonly billingTerm?: InputMaybe<BillingTerm>
  readonly currency?: InputMaybe<Scalars['String']>
  readonly description?: InputMaybe<Scalars['String']>
  readonly displayName?: InputMaybe<Scalars['String']>
  readonly entityId?: InputMaybe<Scalars['String']>
  readonly entityIds?: InputMaybe<ReadonlyArray<Scalars['String']>>
  readonly externalId?: InputMaybe<Scalars['String']>
  readonly id: Scalars['String']
  readonly name: Scalars['String']
  readonly planRelationships?: InputMaybe<ReadonlyArray<InputMaybe<InputCatalogRelationship>>>
  readonly productId: Scalars['String']
  readonly replacementPlanIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>
  readonly status: PlanStatus
  readonly templateIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>
}

export type InputUpdateTenantRequest = {
  readonly address?: InputMaybe<InputAccountAddress>
  readonly email?: InputMaybe<Scalars['String']>
  readonly name: Scalars['String']
  readonly phoneNumber?: InputMaybe<Scalars['String']>
}

export type InputUpsertTaxRateRequest = {
  readonly description?: InputMaybe<Scalars['String']>
  readonly id?: InputMaybe<Scalars['String']>
  readonly name: Scalars['String']
  readonly taxCode: Scalars['String']
  readonly taxInclusive?: InputMaybe<Scalars['Boolean']>
  readonly taxPercentage?: InputMaybe<Scalars['BigDecimal']>
}

export type InputUser = {
  readonly approvalSegments?: InputMaybe<ReadonlyArray<InputMaybe<InputApprovalSegment>>>
  readonly availableEntities?: InputMaybe<ReadonlyArray<InputEntity>>
  readonly cognitoUserStatus?: InputMaybe<UserStatusType>
  readonly displayName: Scalars['String']
  readonly email: Scalars['String']
  readonly entityIds?: InputMaybe<ReadonlyArray<Scalars['String']>>
  readonly externalId?: InputMaybe<Scalars['String']>
  readonly hasAllEntitiesAccess?: InputMaybe<Scalars['Boolean']>
  readonly id: Scalars['String']
  readonly phoneNumber?: InputMaybe<Scalars['String']>
  readonly role: Role
  readonly ssoOnly?: InputMaybe<Scalars['Boolean']>
  readonly state: Status
  readonly tenantName?: InputMaybe<Scalars['String']>
  readonly title?: InputMaybe<Scalars['String']>
  readonly userGroups?: InputMaybe<ReadonlyArray<InputMaybe<InputUserGroupJson>>>
}

export type InputUserGroupJson = {
  readonly description?: InputMaybe<Scalars['String']>
  readonly entityIds?: InputMaybe<ReadonlyArray<Scalars['String']>>
  readonly externalId?: InputMaybe<Scalars['String']>
  readonly id: Scalars['String']
  readonly name: Scalars['String']
  readonly users: ReadonlyArray<InputMaybe<Scalars['String']>>
}

export type InputUserGroupRequest = {
  readonly description?: InputMaybe<Scalars['String']>
  readonly entityIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>
  readonly externalId?: InputMaybe<Scalars['String']>
  readonly id?: InputMaybe<Scalars['String']>
  readonly name: Scalars['String']
  readonly users: ReadonlyArray<InputMaybe<Scalars['String']>>
}

export type InputUserInput = {
  readonly displayName: Scalars['String']
  readonly email: Scalars['String']
  readonly entityIds?: InputMaybe<ReadonlyArray<Scalars['String']>>
  readonly externalId?: InputMaybe<Scalars['String']>
  readonly id?: InputMaybe<Scalars['String']>
  readonly phoneNumber?: InputMaybe<Scalars['String']>
  readonly role: Role
  readonly ssoOnly?: InputMaybe<Scalars['Boolean']>
  readonly state: Status
  readonly title?: InputMaybe<Scalars['String']>
}

export type InputVoidInvoiceMutation = {
  readonly invoiceBalance: Scalars['BigDecimal']
  readonly invoiceNumber: Scalars['String']
  readonly voidDate: Scalars['Long']
}

export type InputVoidPaymentRequest = {
  readonly invoiceBalance: Scalars['BigDecimal']
  readonly invoiceNumber: Scalars['String']
  readonly note?: InputMaybe<Scalars['String']>
  readonly paymentId: Scalars['String']
  readonly voidDate: Scalars['Long']
}

export type InputpriceTier = {
  readonly amount: Scalars['BigDecimal']
  readonly overage?: InputMaybe<Scalars['BigDecimal']>
  readonly untilQuantity: Scalars['String']
}

export type Integration = {
  readonly __typename?: 'Integration'
  readonly clientId: Scalars['String']
  readonly createdOn?: Maybe<Scalars['String']>
  readonly environment?: Maybe<Scalars['String']>
  readonly id?: Maybe<Scalars['String']>
  readonly maskedApiKey?: Maybe<Scalars['String']>
  readonly realmId?: Maybe<Scalars['String']>
  readonly status?: Maybe<IntegrationStatus>
  readonly targetService: IntegrationTargetService
  readonly updatedOn?: Maybe<Scalars['String']>
}

export type IntegrationDetail = {
  readonly __typename?: 'IntegrationDetail'
  readonly authUrl?: Maybe<Scalars['String']>
  readonly clientId: Scalars['String']
  readonly createdOn?: Maybe<Scalars['String']>
  readonly environment?: Maybe<Scalars['String']>
  readonly id?: Maybe<Scalars['String']>
  readonly maskedApiKey?: Maybe<Scalars['String']>
  readonly realmId?: Maybe<Scalars['String']>
  readonly status?: Maybe<IntegrationStatus>
  readonly targetService: IntegrationTargetService
  readonly updatedOn?: Maybe<Scalars['String']>
}

export enum IntegrationStatus {
  /** ACTIVE */
  Active = 'ACTIVE',
  /** DELETED */
  Deleted = 'DELETED',
  /** PENDING */
  Pending = 'PENDING',
}

export enum IntegrationTargetService {
  /** ANROK */
  Anrok = 'ANROK',
  /** MERGE */
  Merge = 'MERGE',
  /** NETSUITE */
  Netsuite = 'NETSUITE',
  /** QUICKBOOKS */
  Quickbooks = 'QUICKBOOKS',
  /** XERO */
  Xero = 'XERO',
}

export type Invoice = {
  readonly __typename?: 'Invoice'
  readonly account: Account
  readonly balance?: Maybe<Scalars['BigDecimal']>
  readonly billingContact?: Maybe<AccountContact>
  readonly convertedTo?: Maybe<Scalars['String']>
  readonly currency?: Maybe<Scalars['String']>
  readonly dueDate?: Maybe<Scalars['Long']>
  readonly emailNotifiersList: EmailNotifiersDetail
  readonly entityId?: Maybe<Scalars['String']>
  readonly erpId?: Maybe<Scalars['String']>
  readonly generatedBy?: Maybe<Scalars['String']>
  readonly generationMethod?: Maybe<InvoiceGenerationMethod>
  readonly invoiceDate?: Maybe<Scalars['Long']>
  readonly invoiceItems?: Maybe<ReadonlyArray<Maybe<InvoiceItem>>>
  readonly invoiceNumber: Scalars['String']
  /** @deprecated Deprecated */
  readonly mailingContacts?: Maybe<ReadonlyArray<AccountContact>>
  readonly note?: Maybe<Scalars['String']>
  readonly paymentTerm?: Maybe<Scalars['String']>
  readonly postedDate?: Maybe<Scalars['Long']>
  readonly purchaseOrderNumber?: Maybe<Scalars['String']>
  readonly purchaseOrderRequired?: Maybe<Scalars['Boolean']>
  readonly resellerAccount?: Maybe<Account>
  readonly shippingContact?: Maybe<AccountContact>
  readonly status: InvoiceStatus
  readonly subTotal: Scalars['BigDecimal']
  readonly subscriptionId: Scalars['String']
  readonly taxTotal: Scalars['BigDecimal']
  readonly taxTransactionCode?: Maybe<Scalars['String']>
  readonly total: Scalars['BigDecimal']
  readonly totalDiscount?: Maybe<Scalars['BigDecimal']>
  readonly voidRequestedOn?: Maybe<Scalars['Long']>
  readonly voidedDate?: Maybe<Scalars['Long']>
}

export enum InvoiceChargeInclusionOption {
  /** EXCLUDE_USAGE */
  ExcludeUsage = 'EXCLUDE_USAGE',
  /** INCLUDE_USAGE */
  IncludeUsage = 'INCLUDE_USAGE',
  /** ONLY_USAGE */
  OnlyUsage = 'ONLY_USAGE',
}

export type InvoiceDunningDetail = {
  readonly __typename?: 'InvoiceDunningDetail'
  readonly amount: Scalars['BigDecimal']
  readonly emailId: Scalars['String']
  readonly emailSentOn: Scalars['Long']
  readonly emailSentTo: Scalars['String']
  readonly id: Scalars['String']
  readonly invoiceNumber: Scalars['String']
  readonly reminderType: DunningReminderType
}

export type InvoiceEmailResponse = {
  readonly __typename?: 'InvoiceEmailResponse'
  readonly emailId: Scalars['String']
}

export enum InvoiceGenerationMethod {
  /** API_INITIATED */
  ApiInitiated = 'API_INITIATED',
  /** AUTOMATED_INVOICE_JOB */
  AutomatedInvoiceJob = 'AUTOMATED_INVOICE_JOB',
  /** BULK_INVOICE_RUN */
  BulkInvoiceRun = 'BULK_INVOICE_RUN',
  /** RULE_DRIVEN_INVOICE_JOB */
  RuleDrivenInvoiceJob = 'RULE_DRIVEN_INVOICE_JOB',
  /** USER_INITIATED */
  UserInitiated = 'USER_INITIATED',
}

export type InvoiceItem = {
  readonly __typename?: 'InvoiceItem'
  readonly amount: Scalars['BigDecimal']
  readonly charge: ChargeDetail
  readonly discountAmount?: Maybe<Scalars['BigDecimal']>
  readonly drawdownQuantityRemaining?: Maybe<Scalars['Long']>
  readonly drawdownQuantityUsed?: Maybe<Scalars['Long']>
  readonly invoiceLineNumber?: Maybe<Scalars['String']>
  readonly isBilled?: Maybe<Scalars['Boolean']>
  readonly listAmount?: Maybe<Scalars['BigDecimal']>
  readonly listUnitPrice?: Maybe<Scalars['BigDecimal']>
  readonly orderId?: Maybe<Scalars['String']>
  readonly orderLineItemId?: Maybe<Scalars['String']>
  readonly periodEndDate: Scalars['Long']
  readonly periodStartDate: Scalars['Long']
  readonly plan: Plan
  readonly quantity?: Maybe<Scalars['Long']>
  readonly sellUnitPrice?: Maybe<Scalars['BigDecimal']>
  readonly subscriptionChargeGroupId?: Maybe<Scalars['String']>
  readonly subscriptionChargeId?: Maybe<Scalars['String']>
  readonly taxAmount: Scalars['BigDecimal']
  readonly taxRate?: Maybe<TaxRate>
}

export type InvoiceItemPreview = {
  readonly __typename?: 'InvoiceItemPreview'
  readonly amount: Scalars['BigDecimal']
  readonly charge: ChargeResolver
  readonly chargeId: Scalars['String']
  readonly discountAmount?: Maybe<Scalars['BigDecimal']>
  readonly drawdownQuantityRemaining?: Maybe<Scalars['Long']>
  readonly drawdownQuantityUsed?: Maybe<Scalars['Long']>
  readonly id: Scalars['String']
  readonly listAmount?: Maybe<Scalars['BigDecimal']>
  readonly listUnitPrice?: Maybe<Scalars['BigDecimal']>
  readonly orderLineItemId: Scalars['String']
  readonly periodEndDate: Scalars['Long']
  readonly periodStartDate: Scalars['Long']
  readonly quantity: Scalars['Long']
  readonly sellUnitPrice?: Maybe<Scalars['BigDecimal']>
  readonly subscriptionChargeGroupId?: Maybe<Scalars['String']>
  readonly subscriptionChargeId?: Maybe<Scalars['String']>
  readonly taxEstimate?: Maybe<Scalars['BigDecimal']>
  readonly taxRate?: Maybe<TaxRate>
}

export type InvoicePaymentManagementLinkGqlResponse = {
  readonly __typename?: 'InvoicePaymentManagementLinkGqlResponse'
  readonly invoiceNumber: Scalars['String']
  readonly linkId: Scalars['String']
}

export type InvoicePreview = {
  readonly __typename?: 'InvoicePreview'
  readonly invoiceItems: ReadonlyArray<InvoiceItemPreview>
  readonly orderId: Scalars['String']
  readonly total: Scalars['BigDecimal']
  readonly totalDiscount: Scalars['BigDecimal']
}

export enum InvoiceStatus {
  /** CONVERTED */
  Converted = 'CONVERTED',
  /** DRAFT */
  Draft = 'DRAFT',
  /** PAID */
  Paid = 'PAID',
  /** POSTED */
  Posted = 'POSTED',
  /** VOIDED */
  Voided = 'VOIDED',
}

export type LedgerAccount = {
  readonly __typename?: 'LedgerAccount'
  readonly accountCode: Scalars['String']
  readonly accountType: LedgerAccountType
  readonly description?: Maybe<Scalars['String']>
  readonly entityIds?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>
  readonly id?: Maybe<Scalars['String']>
  readonly inUse?: Maybe<Scalars['Boolean']>
  readonly isDefault?: Maybe<Scalars['Boolean']>
  readonly name: Scalars['String']
}

export type LedgerAccountMapping = {
  readonly __typename?: 'LedgerAccountMapping'
  readonly contractAssetAccountId?: Maybe<Scalars['String']>
  readonly deferredRevenueAccountId?: Maybe<Scalars['String']>
  readonly recognizedRevenueAccountId?: Maybe<Scalars['String']>
  readonly taxLiabilityAccountId?: Maybe<Scalars['String']>
}

export enum LedgerAccountType {
  /** ACCOUNTS_RECEIVABLE */
  AccountsReceivable = 'ACCOUNTS_RECEIVABLE',
  /** CASH */
  Cash = 'CASH',
  /** CONTRACT_ASSET */
  ContractAsset = 'CONTRACT_ASSET',
  /** DEFERRED_REVENUE */
  DeferredRevenue = 'DEFERRED_REVENUE',
  /** EXPENSE */
  Expense = 'EXPENSE',
  /** RECOGNIZED_REVENUE */
  RecognizedRevenue = 'RECOGNIZED_REVENUE',
  /** TAX_LIABILITY */
  TaxLiability = 'TAX_LIABILITY',
}

export enum LineItemColumn {
  /** BLOCK_QUANTITY */
  BlockQuantity = 'BLOCK_QUANTITY',
  /** CHARGE_NAME */
  ChargeName = 'CHARGE_NAME',
  /** DISCOUNT_AMOUNT */
  DiscountAmount = 'DISCOUNT_AMOUNT',
  /** DISCOUNT_PERCENT */
  DiscountPercent = 'DISCOUNT_PERCENT',
  /** END_DATE */
  EndDate = 'END_DATE',
  /** LIST_AMOUNT */
  ListAmount = 'LIST_AMOUNT',
  /** LIST_PRICE */
  ListPrice = 'LIST_PRICE',
  /** PLAN_NAME */
  PlanName = 'PLAN_NAME',
  /** QUANTITY */
  Quantity = 'QUANTITY',
  /** QUANTITY_AND_UNIT_OF_MEASURE */
  QuantityAndUnitOfMeasure = 'QUANTITY_AND_UNIT_OF_MEASURE',
  /** START_DATE */
  StartDate = 'START_DATE',
  /** UNIT_OF_MEASURE */
  UnitOfMeasure = 'UNIT_OF_MEASURE',
  /** UNIT_PRICE */
  UnitPrice = 'UNIT_PRICE',
  /** YEARLY_AMOUNT */
  YearlyAmount = 'YEARLY_AMOUNT',
}

export type LineItemMetrics = {
  readonly __typename?: 'LineItemMetrics'
  readonly arr: Scalars['BigDecimal']
  readonly arrWithoutOverride: Scalars['BigDecimal']
  readonly deltaArr?: Maybe<Scalars['BigDecimal']>
  readonly deltaTcv?: Maybe<Scalars['BigDecimal']>
  readonly nonRecurringTotal: Scalars['BigDecimal']
  readonly recurringTotal: Scalars['BigDecimal']
  readonly tcv: Scalars['BigDecimal']
}

export enum LineItemTableStyle {
  /** GROUPED */
  Grouped = 'GROUPED',
  /** SINGLE_LINE */
  SingleLine = 'SINGLE_LINE',
}

export type LookerUserMapping = {
  readonly __typename?: 'LookerUserMapping'
  readonly isDisabled: Scalars['Boolean']
  readonly lookerUserId: Scalars['String']
  readonly tenantId: Scalars['String']
  readonly userId: Scalars['String']
}

export enum MessageLevel {
  /** ERROR */
  Error = 'ERROR',
  /** INFO */
  Info = 'INFO',
  /** WARNING */
  Warning = 'WARNING',
}

export type Notification = {
  readonly __typename?: 'Notification'
  readonly description?: Maybe<Scalars['String']>
  readonly name?: Maybe<Scalars['String']>
  readonly notificationId?: Maybe<Scalars['String']>
  readonly notificationTarget?: Maybe<Scalars['String']>
  readonly notificationTargetType?: Maybe<NotificationTargetType>
  readonly subscribedEvents?: Maybe<ReadonlyArray<Maybe<NotificationEventType>>>
}

export enum NotificationEventType {
  /** ESIGNATURE_COMPLETED */
  EsignatureCompleted = 'ESIGNATURE_COMPLETED',
  /** INVOICE_POSTED */
  InvoicePosted = 'INVOICE_POSTED',
  /** ORDER_APPROVED */
  OrderApproved = 'ORDER_APPROVED',
  /** ORDER_EXECUTED */
  OrderExecuted = 'ORDER_EXECUTED',
  /** ORDER_SUBMITTED */
  OrderSubmitted = 'ORDER_SUBMITTED',
  /** SUBSCRIPTION_ACTIVATING */
  SubscriptionActivating = 'SUBSCRIPTION_ACTIVATING',
  /** SUBSCRIPTION_CANCELLING */
  SubscriptionCancelling = 'SUBSCRIPTION_CANCELLING',
  /** SUBSCRIPTION_CHARGE_CHANGE */
  SubscriptionChargeChange = 'SUBSCRIPTION_CHARGE_CHANGE',
  /** SUBSCRIPTION_CREATED */
  SubscriptionCreated = 'SUBSCRIPTION_CREATED',
  /** SUBSCRIPTION_EXPIRING */
  SubscriptionExpiring = 'SUBSCRIPTION_EXPIRING',
}

export enum NotificationInstanceStatus {
  /** ATTEMPTED */
  Attempted = 'ATTEMPTED',
  /** CREATED */
  Created = 'CREATED',
  /** DELIVERED */
  Delivered = 'DELIVERED',
  /** FAILED */
  Failed = 'FAILED',
}

export type NotificationInstanceWithAttempts = {
  readonly __typename?: 'NotificationInstanceWithAttempts'
  readonly deliveryAttempts?: Maybe<ReadonlyArray<Maybe<DeliveryAttempt>>>
  readonly entityId: Scalars['String']
  readonly eventType: Scalars['String']
  readonly id: Scalars['String']
  readonly lastDeliveryAttempt?: Maybe<Scalars['String']>
  readonly numberOfDeliveryAttempts: Scalars['Int']
  readonly payload: Scalars['String']
  readonly status: NotificationInstanceStatus
}

export type NotificationProcessorTypeSupport = {
  readonly __typename?: 'NotificationProcessorTypeSupport'
  readonly supportedTypes: ReadonlyArray<Maybe<Scalars['String']>>
  readonly targetType: Scalars['String']
}

export enum NotificationTargetType {
  /** SLACK */
  Slack = 'SLACK',
  /** WEBHOOK */
  Webhook = 'WEBHOOK',
}

export type NumberConfig = {
  readonly __typename?: 'NumberConfig'
  readonly configId?: Maybe<Scalars['String']>
  readonly length: Scalars['Int']
  readonly nextSequenceNumber?: Maybe<Scalars['Long']>
  readonly prefix: Scalars['String']
  readonly scheme: NumberScheme
}

export enum NumberScheme {
  /** PSEUDO_RANDOM */
  PseudoRandom = 'PSEUDO_RANDOM',
  /** SEQUENCE */
  Sequence = 'SEQUENCE',
}

export type Opportunity = {
  readonly __typename?: 'Opportunity'
  readonly accountId?: Maybe<Scalars['String']>
  readonly createdOn?: Maybe<Scalars['String']>
  readonly crmId?: Maybe<Scalars['String']>
  readonly entityId?: Maybe<Scalars['String']>
  readonly id?: Maybe<Scalars['String']>
  readonly isClosed?: Maybe<Scalars['Boolean']>
  readonly name?: Maybe<Scalars['String']>
  readonly opportunityCrmType?: Maybe<OpportunityCrmType>
  readonly opportunityId?: Maybe<Scalars['String']>
  readonly primaryOrderId?: Maybe<Scalars['String']>
  readonly stage?: Maybe<Scalars['String']>
  readonly type?: Maybe<Scalars['String']>
  readonly updatedOn?: Maybe<Scalars['String']>
}

export enum OpportunityCrmType {
  /** HUBSPOT */
  Hubspot = 'HUBSPOT',
  /** SALESFORCE */
  Salesforce = 'SALESFORCE',
}

export type Order = {
  readonly __typename?: 'Order'
  readonly accountId?: Maybe<Scalars['String']>
  readonly approvalSegmentId?: Maybe<Scalars['String']>
  readonly attachmentId?: Maybe<Scalars['String']>
  readonly autoRenew?: Maybe<Scalars['Boolean']>
  readonly billingAnchorDate?: Maybe<Scalars['Long']>
  readonly billingContactId?: Maybe<Scalars['String']>
  readonly billingCycle?: Maybe<Recurrence>
  readonly billingTerm?: Maybe<BillingTerm>
  readonly compositeOrderId?: Maybe<Scalars['String']>
  readonly currency?: Maybe<Scalars['String']>
  readonly customFields?: Maybe<ReadonlyArray<Maybe<CustomFieldEntry>>>
  readonly documentMasterTemplateId?: Maybe<Scalars['String']>
  readonly endDate?: Maybe<Scalars['Long']>
  readonly entityId?: Maybe<Scalars['String']>
  readonly executedOn?: Maybe<Scalars['Long']>
  readonly expiresOn?: Maybe<Scalars['Long']>
  readonly externalId?: Maybe<Scalars['String']>
  readonly id?: Maybe<Scalars['String']>
  readonly isPrimaryOrderForSfdcOpportunity?: Maybe<Scalars['Boolean']>
  readonly lineItems?: Maybe<ReadonlyArray<Maybe<OrderLineItem>>>
  readonly lineItemsNetEffect?: Maybe<ReadonlyArray<Maybe<OrderLineItem>>>
  readonly name?: Maybe<Scalars['String']>
  readonly opportunityCrmType?: Maybe<OpportunityCrmType>
  readonly orderFormTemplateIds?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>
  readonly orderTerms?: Maybe<ReadonlyArray<Maybe<OrderTerms>>>
  readonly orderType: OrderType
  readonly ownerId?: Maybe<Scalars['String']>
  readonly paymentTerm?: Maybe<Scalars['String']>
  readonly predefinedDiscounts?: Maybe<ReadonlyArray<Maybe<TenantDiscountJson>>>
  readonly purchaseOrderNumber?: Maybe<Scalars['String']>
  readonly purchaseOrderRequiredForInvoicing?: Maybe<Scalars['Boolean']>
  readonly rampInterval?: Maybe<ReadonlyArray<Maybe<Scalars['Long']>>>
  readonly renewalForSubscriptionId?: Maybe<Scalars['String']>
  readonly renewalForSubscriptionVersion?: Maybe<Scalars['Int']>
  readonly sfdcOpportunityId?: Maybe<Scalars['String']>
  readonly sfdcOpportunityName?: Maybe<Scalars['String']>
  readonly sfdcOpportunityStage?: Maybe<Scalars['String']>
  readonly sfdcOpportunityType?: Maybe<Scalars['String']>
  readonly sfdcOrderCanBeExecuted?: Maybe<Scalars['Boolean']>
  readonly shippingContactId?: Maybe<Scalars['String']>
  readonly startDate: Scalars['Long']
  readonly startDateType?: Maybe<OrderStartDateType>
  readonly status: OrderStatus
  readonly subscriptionId?: Maybe<Scalars['String']>
  readonly subscriptionTargetVersion?: Maybe<Scalars['Int']>
  readonly taxEstimate?: Maybe<Scalars['BigDecimal']>
  readonly termLength?: Maybe<Recurrence>
  readonly totalAmount?: Maybe<Scalars['BigDecimal']>
  readonly updatedOn: Scalars['Long']
}

export type OrderDetail = {
  readonly __typename?: 'OrderDetail'
  /** @deprecated Deprecated */
  readonly account: Account
  /** @deprecated Deprecated */
  readonly accountDetail: AccountDetail
  readonly approvalFlows: ReadonlyArray<ApprovalFlowInstanceGroupDetail>
  readonly approvalSegment?: Maybe<ApprovalSegment>
  readonly attachmentId?: Maybe<Scalars['String']>
  readonly autoRenew?: Maybe<Scalars['Boolean']>
  readonly billingAnchorDate?: Maybe<Scalars['Long']>
  readonly billingContact?: Maybe<AccountContact>
  readonly billingCycle: Recurrence
  readonly billingTerm: BillingTerm
  readonly compositeOrderId?: Maybe<Scalars['String']>
  readonly createdBy?: Maybe<User>
  readonly currency?: Maybe<Scalars['String']>
  readonly currentSubscription?: Maybe<Subscription>
  readonly customFields?: Maybe<ReadonlyArray<Maybe<CustomFieldEntry>>>
  readonly documentCustomContent?: Maybe<DocumentCustomContent>
  readonly documentMasterTemplate?: Maybe<DocumentMasterTemplate>
  readonly endDate?: Maybe<Scalars['Long']>
  readonly entityId?: Maybe<Scalars['String']>
  readonly executedOn?: Maybe<Scalars['Long']>
  readonly expiresOn?: Maybe<Scalars['Long']>
  readonly externalId?: Maybe<Scalars['String']>
  readonly id?: Maybe<Scalars['String']>
  readonly isPrimaryOrderForSfdcOpportunity?: Maybe<Scalars['Boolean']>
  readonly lineItems: ReadonlyArray<OrderLineItemDetail>
  readonly lineItemsNetEffect?: Maybe<ReadonlyArray<OrderLineItemDetail>>
  readonly name?: Maybe<Scalars['String']>
  readonly nonRecurringDiscountPercent?: Maybe<Scalars['BigDecimal']>
  readonly opportunityCrmType?: Maybe<OpportunityCrmType>
  readonly orderDocumentTemplates?: Maybe<ReadonlyArray<OrderDocumentTemplate>>
  readonly orderFormTemplates: ReadonlyArray<DocumentTemplate>
  readonly orderMetrics?: Maybe<AccountMetrics>
  readonly orderTerms?: Maybe<ReadonlyArray<Maybe<OrderTerms>>>
  readonly orderType: OrderType
  readonly owner?: Maybe<User>
  readonly paymentTerm: Scalars['String']
  readonly predefinedDiscounts?: Maybe<ReadonlyArray<Maybe<PredefinedDiscountDetail>>>
  readonly purchaseOrderNumber?: Maybe<Scalars['String']>
  readonly purchaseOrderRequiredForInvoicing?: Maybe<Scalars['Boolean']>
  readonly rampInterval?: Maybe<ReadonlyArray<Maybe<Scalars['Long']>>>
  readonly recurringDiscountPercent?: Maybe<Scalars['BigDecimal']>
  readonly renewalForSubscription?: Maybe<Subscription>
  readonly resoldBy?: Maybe<Account>
  readonly sfdcOpportunityId?: Maybe<Scalars['String']>
  readonly sfdcOpportunityName?: Maybe<Scalars['String']>
  readonly sfdcOpportunityStage?: Maybe<Scalars['String']>
  readonly sfdcOpportunityType?: Maybe<Scalars['String']>
  readonly sfdcOrderCanBeExecuted?: Maybe<Scalars['Boolean']>
  readonly shippingContact?: Maybe<AccountContact>
  readonly startDate: Scalars['Long']
  readonly startDateType?: Maybe<OrderStartDateType>
  readonly status: OrderStatus
  readonly submittedBy?: Maybe<User>
  readonly subscriptionId?: Maybe<Scalars['String']>
  readonly subscriptionTargetVersion?: Maybe<Scalars['Int']>
  readonly taxEstimate?: Maybe<Scalars['BigDecimal']>
  readonly termLength?: Maybe<Recurrence>
  readonly totalAmount?: Maybe<Scalars['BigDecimal']>
  readonly totalListAmount?: Maybe<Scalars['BigDecimal']>
  readonly totalListAmountBeforeOverride?: Maybe<Scalars['BigDecimal']>
  readonly updatedOn: Scalars['Long']
  readonly updatedSubscription?: Maybe<Subscription>
  /** @deprecated Deprecated */
  readonly validApprovalSegments?: Maybe<ReadonlyArray<Maybe<ApprovalSegment>>>
}

export type OrderDetailMinimal = {
  readonly __typename?: 'OrderDetailMinimal'
  readonly effectiveDate?: Maybe<Scalars['Long']>
  readonly id?: Maybe<Scalars['String']>
  readonly name?: Maybe<Scalars['String']>
  readonly orderType: OrderType
  readonly ownerId?: Maybe<Scalars['String']>
  readonly status: OrderStatus
}

export type OrderDocumentTemplate = {
  readonly __typename?: 'OrderDocumentTemplate'
  readonly content: Scalars['String']
  readonly description?: Maybe<Scalars['String']>
  readonly entityIds?: Maybe<ReadonlyArray<Scalars['String']>>
  readonly id: Scalars['String']
  readonly inUse?: Maybe<Scalars['Boolean']>
  readonly isModified: Scalars['Boolean']
  readonly isUserSelectable?: Maybe<Scalars['Boolean']>
  readonly name: Scalars['String']
  readonly plans?: Maybe<ReadonlyArray<Maybe<Plan>>>
  readonly sectionUuid?: Maybe<Scalars['String']>
  readonly status: DocumentTemplateStatus
  readonly templateId?: Maybe<Scalars['String']>
  readonly type: DocumentTemplateType
  readonly updatedOn?: Maybe<Scalars['Long']>
  readonly version?: Maybe<Scalars['Int']>
}

export type OrderInvoicePreview = {
  readonly __typename?: 'OrderInvoicePreview'
  readonly invoicePreviews: ReadonlyArray<InvoicePreview>
}

export type OrderLineItem = {
  readonly __typename?: 'OrderLineItem'
  readonly action: ActionType
  readonly amount?: Maybe<Scalars['BigDecimal']>
  readonly annualizedAmount?: Maybe<Scalars['BigDecimal']>
  readonly arrOverride?: Maybe<Scalars['BigDecimal']>
  readonly attributeReferences?: Maybe<ReadonlyArray<Maybe<AttributeReference>>>
  readonly chargeId: Scalars['String']
  readonly currencyConversionRateId?: Maybe<Scalars['String']>
  readonly customFields?: Maybe<ReadonlyArray<Maybe<CustomFieldEntry>>>
  readonly discountAmount?: Maybe<Scalars['BigDecimal']>
  readonly discounts?: Maybe<ReadonlyArray<Maybe<DiscountDetailJson>>>
  readonly effectiveDate?: Maybe<Scalars['Long']>
  readonly endDate?: Maybe<Scalars['Long']>
  readonly id?: Maybe<Scalars['String']>
  readonly isDryRunItem?: Maybe<Scalars['Boolean']>
  readonly isRamp?: Maybe<Scalars['Boolean']>
  readonly itemGroupId?: Maybe<Scalars['String']>
  readonly listAmount?: Maybe<Scalars['BigDecimal']>
  readonly listAmountBeforeOverride?: Maybe<Scalars['BigDecimal']>
  readonly listPriceOverrideRatio?: Maybe<Scalars['BigDecimal']>
  readonly listUnitPrice?: Maybe<Scalars['BigDecimal']>
  readonly listUnitPriceBeforeOverride?: Maybe<Scalars['BigDecimal']>
  readonly planId?: Maybe<Scalars['String']>
  readonly predefinedDiscounts?: Maybe<ReadonlyArray<Maybe<TenantDiscountLineItemJson>>>
  readonly quantity?: Maybe<Scalars['Long']>
  readonly sellUnitPrice?: Maybe<Scalars['BigDecimal']>
  readonly subscriptionChargeGroupId?: Maybe<Scalars['String']>
  readonly subscriptionChargeId?: Maybe<Scalars['String']>
  readonly taxEstimate?: Maybe<Scalars['BigDecimal']>
}

export type OrderLineItemDetail = {
  readonly __typename?: 'OrderLineItemDetail'
  readonly action: ActionType
  readonly amount?: Maybe<Scalars['BigDecimal']>
  readonly annualizedAmount?: Maybe<Scalars['BigDecimal']>
  readonly arrOverride?: Maybe<Scalars['BigDecimal']>
  readonly attributeReferences?: Maybe<ReadonlyArray<Maybe<AttributeReference>>>
  readonly charge: Charge
  readonly chargeDetail: ChargeDetail
  readonly currencyConversionRateId?: Maybe<Scalars['String']>
  readonly customFields?: Maybe<ReadonlyArray<Maybe<CustomFieldEntry>>>
  readonly discountAmount?: Maybe<Scalars['BigDecimal']>
  readonly discounts?: Maybe<ReadonlyArray<Maybe<DiscountDetailJson>>>
  readonly effectiveDate?: Maybe<Scalars['Long']>
  readonly endDate?: Maybe<Scalars['Long']>
  readonly id?: Maybe<Scalars['String']>
  readonly isDryRunItem?: Maybe<Scalars['Boolean']>
  readonly isRamp: Scalars['Boolean']
  readonly itemGroupId?: Maybe<Scalars['String']>
  readonly listAmount?: Maybe<Scalars['BigDecimal']>
  readonly listAmountBeforeOverride?: Maybe<Scalars['BigDecimal']>
  readonly listPriceOverrideRatio?: Maybe<Scalars['BigDecimal']>
  readonly listUnitPrice?: Maybe<Scalars['BigDecimal']>
  readonly listUnitPriceBeforeOverride?: Maybe<Scalars['BigDecimal']>
  readonly metrics?: Maybe<LineItemMetrics>
  readonly plan?: Maybe<Plan>
  readonly predefinedDiscounts?: Maybe<ReadonlyArray<Maybe<PredefinedDiscountLineItemDetail>>>
  readonly quantity?: Maybe<Scalars['Long']>
  readonly rank?: Maybe<Scalars['Int']>
  readonly sellUnitPrice?: Maybe<Scalars['BigDecimal']>
  readonly subscriptionChargeGroupId?: Maybe<Scalars['String']>
  readonly subscriptionChargeId?: Maybe<Scalars['String']>
  readonly taxEstimate?: Maybe<Scalars['BigDecimal']>
}

export type OrderLineItemsConfiguration = {
  readonly __typename?: 'OrderLineItemsConfiguration'
  readonly customFields?: Maybe<ReadonlyArray<Maybe<UiElement>>>
  readonly fields?: Maybe<ReadonlyArray<Maybe<UiElement>>>
}

export type OrderPageConfiguration = {
  readonly __typename?: 'OrderPageConfiguration'
  readonly fields?: Maybe<ReadonlyArray<Maybe<UiElement>>>
  readonly orderLineItemsConfiguration?: Maybe<OrderLineItemsConfiguration>
}

export enum OrderStartDateType {
  /** EXECUTION_DATE */
  ExecutionDate = 'EXECUTION_DATE',
  /** FIXED */
  Fixed = 'FIXED',
}

export enum OrderStatus {
  /** APPROVED */
  Approved = 'APPROVED',
  /** CANCELLED */
  Cancelled = 'CANCELLED',
  /** DRAFT */
  Draft = 'DRAFT',
  /** EXECUTED */
  Executed = 'EXECUTED',
  /** EXPIRED */
  Expired = 'EXPIRED',
  /** SUBMITTED */
  Submitted = 'SUBMITTED',
}

export type OrderStatusUpdateResponse = {
  readonly __typename?: 'OrderStatusUpdateResponse'
  readonly id?: Maybe<Scalars['String']>
  readonly orderType?: Maybe<OrderType>
  readonly status?: Maybe<OrderStatus>
}

export type OrderTerms = {
  readonly __typename?: 'OrderTerms'
  readonly id?: Maybe<Scalars['String']>
  readonly levelType: OrderTermsLevelType
  readonly orderId: Scalars['String']
  readonly planIds: ReadonlyArray<Maybe<Scalars['String']>>
  readonly templateGroupId?: Maybe<Scalars['String']>
  readonly templateGroupVersion?: Maybe<Scalars['Int']>
  readonly templateId: Scalars['String']
}

export enum OrderTermsLevelType {
  /** ORDER */
  Order = 'ORDER',
  /** PLAN */
  Plan = 'PLAN',
}

export enum OrderType {
  /** AMENDMENT */
  Amendment = 'AMENDMENT',
  /** CANCEL */
  Cancel = 'CANCEL',
  /** NEW */
  New = 'NEW',
  /** RENEWAL */
  Renewal = 'RENEWAL',
  /** RESTRUCTURE */
  Restructure = 'RESTRUCTURE',
}

/** Information about pagination in a connection. */
export type PageInfo = {
  readonly __typename?: 'PageInfo'
  /** When paginating forwards, the cursor to continue. */
  readonly endCursor?: Maybe<Scalars['String']>
  /** When paginating forwards, are there more items? */
  readonly hasNextPage: Scalars['Boolean']
  /** When paginating backwards, are there more items? */
  readonly hasPreviousPage: Scalars['Boolean']
  /** When paginating backwards, the cursor to continue. */
  readonly startCursor?: Maybe<Scalars['String']>
}

export enum ParamDataType {
  /** DATE */
  Date = 'DATE',
  /** INTEGER */
  Integer = 'INTEGER',
  /** STRING */
  String = 'STRING',
}

export enum ParamType {
  /** RANGE */
  Range = 'RANGE',
  /** SELECTION */
  Selection = 'SELECTION',
  /** VALUE */
  Value = 'VALUE',
}

export type PaymentAttemptDetail = {
  readonly __typename?: 'PaymentAttemptDetail'
  readonly attemptNumber: Scalars['Int']
  readonly attemptedOn: Scalars['Long']
  readonly failureReason?: Maybe<PaymentFailureReason>
  readonly id: Scalars['String']
  /** @deprecated Deprecated */
  readonly state: PaymentAttemptState
  readonly transactionId: Scalars['String']
  readonly userFriendlyState: PaymentAttemptUserFriendlyState
}

export enum PaymentAttemptState {
  /** CONFIRMED */
  Confirmed = 'CONFIRMED',
  /** CREATED */
  Created = 'CREATED',
  /** FAILED */
  Failed = 'FAILED',
  /** PROCESSING */
  Processing = 'PROCESSING',
  /** SUCCEEDED */
  Succeeded = 'SUCCEEDED',
}

export enum PaymentAttemptUserFriendlyState {
  /** FAILED */
  Failed = 'FAILED',
  /** INITIATED */
  Initiated = 'INITIATED',
  /** SUCCESSFUL */
  Successful = 'SUCCESSFUL',
}

export type PaymentBankAccountJson = {
  readonly __typename?: 'PaymentBankAccountJson'
  readonly cashLedgerAccountId: Scalars['String']
  readonly createdOn?: Maybe<Scalars['Long']>
  readonly currencyCode: Scalars['String']
  readonly description?: Maybe<Scalars['String']>
  readonly entityIds: ReadonlyArray<Maybe<Scalars['String']>>
  readonly expenseLedgerAccountId: Scalars['String']
  readonly externalId?: Maybe<Scalars['String']>
  readonly id?: Maybe<Scalars['String']>
  readonly name: Scalars['String']
  readonly status: PaymentBankAccountStatus
  readonly updatedOn?: Maybe<Scalars['Long']>
}

export enum PaymentBankAccountStatus {
  /** ACTIVE */
  Active = 'ACTIVE',
  /** DEPRECATED */
  Deprecated = 'DEPRECATED',
  /** DRAFT */
  Draft = 'DRAFT',
}

export type PaymentConfiguration = {
  readonly __typename?: 'PaymentConfiguration'
  readonly supportedPaymentTypes: ReadonlyArray<PaymentType>
}

export type PaymentDetail = {
  readonly __typename?: 'PaymentDetail'
  readonly account: Account
  /** @deprecated Deprecated */
  readonly accountId: Scalars['String']
  readonly amount: Scalars['BigDecimal']
  readonly bankFee?: Maybe<Scalars['BigDecimal']>
  readonly canBeVoided?: Maybe<Scalars['Boolean']>
  readonly cannotBeVoidedReason?: Maybe<Scalars['String']>
  readonly currency: Scalars['String']
  readonly entityId?: Maybe<Scalars['String']>
  readonly id: Scalars['String']
  readonly invoiceBalance?: Maybe<Scalars['BigDecimal']>
  /** @deprecated Deprecated */
  readonly invoiceNumber?: Maybe<Scalars['String']>
  readonly lifecycleType?: Maybe<PaymentLifecycleType>
  readonly notes?: Maybe<Scalars['String']>
  readonly paymentAttempts: ReadonlyArray<PaymentAttemptDetail>
  readonly paymentBankAccount?: Maybe<PaymentBankAccountJson>
  readonly paymentDate: Scalars['Long']
  /** @deprecated Deprecated */
  readonly paymentId: Scalars['String']
  readonly paymentMethodDetail: PaymentMethodDetail
  /** @deprecated Deprecated */
  readonly paymentMethodId: Scalars['String']
  /** @deprecated Deprecated */
  readonly paymentStatus: PaymentStatus
  readonly refundStatus?: Maybe<PaymentRefundStatus>
  readonly refundsApplied: ReadonlyArray<Maybe<Refund>>
  readonly remainingBalance: Scalars['BigDecimal']
  readonly state: PaymentState
  readonly subscriptionId?: Maybe<Scalars['String']>
  readonly transactionId?: Maybe<Scalars['String']>
  readonly voidDate?: Maybe<Scalars['Long']>
}

export type PaymentFailureReason = {
  readonly __typename?: 'PaymentFailureReason'
  readonly declineCode?: Maybe<Scalars['String']>
  readonly errorCode?: Maybe<Scalars['String']>
  readonly isInternal: Scalars['Boolean']
  readonly paymentProvider?: Maybe<SupportedPaymentProvider>
  readonly status?: Maybe<Scalars['String']>
}

export type PaymentInAccountDetail = {
  readonly __typename?: 'PaymentInAccountDetail'
  readonly amount: Scalars['BigDecimal']
  readonly currency: Scalars['String']
  readonly id: Scalars['String']
  readonly invoiceNumber?: Maybe<Scalars['String']>
  readonly paymentDate: Scalars['Long']
  readonly paymentType: PaymentType
}

export type PaymentIntegration = {
  readonly __typename?: 'PaymentIntegration'
  readonly description?: Maybe<Scalars['String']>
  readonly paymentProvider: SupportedPaymentProvider
  readonly privateKey: Scalars['String']
  readonly publicKey: Scalars['String']
}

export enum PaymentLifecycleType {
  /** AUTOMATIC */
  Automatic = 'AUTOMATIC',
  /** OFFLINE */
  Offline = 'OFFLINE',
  /** ONE_TIME */
  OneTime = 'ONE_TIME',
}

export type PaymentLinkResponse = {
  readonly __typename?: 'PaymentLinkResponse'
  readonly accountId: Scalars['String']
  readonly connectAccountId: Scalars['String']
  readonly enrolledAccountPaymentMethod?: Maybe<PaymentMethodDetail>
  /** @deprecated Deprecated */
  readonly enrolledPaymentMethod?: Maybe<PaymentProviderPaymentMethod>
  readonly hasOngoingPayment: Scalars['Boolean']
  readonly invoiceBalance: Scalars['BigDecimal']
  readonly invoiceCurrencyCode: Scalars['String']
  readonly invoiceDueDate: Scalars['Long']
  readonly invoiceFrom: Scalars['String']
  readonly invoiceNumber: Scalars['String']
  readonly invoiceStatus: InvoiceStatus
  readonly invoiceTo: Scalars['String']
  readonly isCustomerEnrolledInAutoPay: Scalars['Boolean']
  readonly latestPaymentDate?: Maybe<Scalars['Long']>
  readonly paymentDate?: Maybe<Scalars['Long']>
  readonly subscriptionId: Scalars['String']
  readonly supportedPaymentTypes: ReadonlyArray<Maybe<PaymentType>>
}

export enum PaymentManagementLinkType {
  /** ACCOUNT */
  Account = 'ACCOUNT',
  /** INVOICE */
  Invoice = 'INVOICE',
}

export type PaymentMethodDetail = {
  readonly __typename?: 'PaymentMethodDetail'
  readonly id: Scalars['String']
  readonly isDeleted: Scalars['Boolean']
  readonly name: Scalars['String']
  readonly paymentMethod?: Maybe<PaymentProviderPaymentMethod>
  /** @deprecated Deprecated */
  readonly paymentType: PaymentType
  readonly status: AccountPaymentMethodStatus
  readonly type?: Maybe<PaymentType>
}

export enum PaymentMethodType {
  /** ACH */
  Ach = 'ACH',
  /** CARD */
  Card = 'CARD',
}

export type PaymentProviderPaymentMethod = {
  readonly __typename?: 'PaymentProviderPaymentMethod'
  readonly accountNumberLastFour?: Maybe<Scalars['String']>
  readonly accountType?: Maybe<Scalars['String']>
  readonly bankName?: Maybe<Scalars['String']>
  readonly expiryDate?: Maybe<Scalars['String']>
  readonly lastFour?: Maybe<Scalars['String']>
  readonly paymentMethodId?: Maybe<Scalars['String']>
  /** @deprecated Deprecated */
  readonly paymentMethodType?: Maybe<PaymentMethodType>
  readonly updatedOn?: Maybe<Scalars['String']>
}

export enum PaymentRefundStatus {
  /** PAID */
  Paid = 'PAID',
  /** PARTIAL */
  Partial = 'PARTIAL',
  /** REFUNDED */
  Refunded = 'REFUNDED',
}

export enum PaymentState {
  /** ACH_TRANSFER_FAILED */
  AchTransferFailed = 'ACH_TRANSFER_FAILED',
  /** CAPTURED */
  Captured = 'CAPTURED',
  /** CARD_DECLINED */
  CardDeclined = 'CARD_DECLINED',
  /** CONFIRMED */
  Confirmed = 'CONFIRMED',
  /** CREATED */
  Created = 'CREATED',
  /** FAILED */
  Failed = 'FAILED',
  /** INITIATED */
  Initiated = 'INITIATED',
  /** PROCESSING */
  Processing = 'PROCESSING',
  /** RECONCILED */
  Reconciled = 'RECONCILED',
  /** SUCCEED */
  Succeed = 'SUCCEED',
  /** VOIDED */
  Voided = 'VOIDED',
}

export enum PaymentStatus {
  /** PAID */
  Paid = 'PAID',
  /** PARTIAL */
  Partial = 'PARTIAL',
  /** REFUNDED */
  Refunded = 'REFUNDED',
  /** VOIDED */
  Voided = 'VOIDED',
}

export type PaymentStripeConnectIntegration = {
  readonly __typename?: 'PaymentStripeConnectIntegration'
  readonly connectAccountId?: Maybe<Scalars['String']>
  readonly isCompleted: Scalars['Boolean']
  readonly isDeleted: Scalars['Boolean']
}

export type PaymentStripeConnectIntegrationSetupResponse = {
  readonly __typename?: 'PaymentStripeConnectIntegrationSetupResponse'
  readonly accountLinkUrl: Scalars['String']
}

export type PaymentTermSettings = {
  readonly __typename?: 'PaymentTermSettings'
  readonly customPaymentTermsAllowed: Scalars['Boolean']
  readonly defaultPaymentTerm: Scalars['String']
  readonly defaultPaymentTerms: ReadonlyArray<Scalars['String']>
}

export enum PaymentType {
  /** ACH */
  Ach = 'ACH',
  /** CARD */
  Card = 'CARD',
  /** CHECK */
  Check = 'CHECK',
  /** EXTERNAL */
  External = 'EXTERNAL',
  /** INVOICE */
  Invoice = 'INVOICE',
  /** WIRE */
  Wire = 'WIRE',
}

export type PendingOrderApprovalFlow = {
  readonly __typename?: 'PendingOrderApprovalFlow'
  readonly accountName: Scalars['String']
  readonly approvalFlowGroupId: Scalars['String']
  readonly approvalFlowId: Scalars['String']
  readonly approvalFlowName: Scalars['String']
  readonly approvalStateId: Scalars['String']
  readonly approvalStateName: Scalars['String']
  readonly id: Scalars['String']
  readonly orderId: Scalars['String']
  readonly orderType: OrderType
  readonly tcv: Scalars['BigDecimal']
  readonly updatedOn: Scalars['Long']
}

export enum PercentDerivedFrom {
  /** LIST_AMOUNT */
  ListAmount = 'LIST_AMOUNT',
  /** SELL_AMOUNT */
  SellAmount = 'SELL_AMOUNT',
}

export type Plan = {
  readonly __typename?: 'Plan'
  readonly charges: ReadonlyArray<Charge>
  readonly currency?: Maybe<Scalars['String']>
  readonly description?: Maybe<Scalars['String']>
  readonly displayName?: Maybe<Scalars['String']>
  readonly entityIds?: Maybe<ReadonlyArray<Scalars['String']>>
  readonly externalId?: Maybe<Scalars['String']>
  readonly id?: Maybe<Scalars['String']>
  readonly name: Scalars['String']
  readonly productId: Scalars['String']
  readonly replacementPlanIds?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>
  readonly status: PlanStatus
  readonly templateIds?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>
  readonly updatedOn?: Maybe<Scalars['Long']>
}

export enum PlanCurrencySettingType {
  /** LEVERAGE_CURRENCY_CONVERSION */
  LeverageCurrencyConversion = 'LEVERAGE_CURRENCY_CONVERSION',
  /** UNIQUE_PLAN_PER_CURRENCY */
  UniquePlanPerCurrency = 'UNIQUE_PLAN_PER_CURRENCY',
}

export type PlanDetail = {
  readonly __typename?: 'PlanDetail'
  readonly charges: ReadonlyArray<ChargeDetail>
  readonly currency: Scalars['String']
  readonly description?: Maybe<Scalars['String']>
  readonly displayName?: Maybe<Scalars['String']>
  readonly documentTemplates: ReadonlyArray<Maybe<DocumentTemplate>>
  readonly entityIds?: Maybe<ReadonlyArray<Scalars['String']>>
  readonly exclusionPlans?: Maybe<ReadonlyArray<Plan>>
  readonly externalId?: Maybe<Scalars['String']>
  readonly id: Scalars['String']
  readonly inUse: Scalars['Boolean']
  readonly name: Scalars['String']
  readonly percentOfChargeRelationships?: Maybe<ReadonlyArray<CatalogRelationship>>
  readonly product: Product
  readonly productId: Scalars['String']
  readonly replacementPlans?: Maybe<ReadonlyArray<Plan>>
  readonly requireAllPlans?: Maybe<ReadonlyArray<Plan>>
  readonly requireAnyPlans?: Maybe<ReadonlyArray<Plan>>
  readonly status: PlanStatus
}

export type PlanGroupInterface = {
  readonly __typename?: 'PlanGroupInterface'
  readonly currency?: Maybe<Scalars['String']>
  readonly description?: Maybe<Scalars['String']>
  readonly id?: Maybe<Scalars['String']>
  readonly name: Scalars['String']
  readonly planGroupItems?: Maybe<ReadonlyArray<Maybe<PlanGroupItemInterface>>>
}

export type PlanGroupItemInterface = {
  readonly __typename?: 'PlanGroupItemInterface'
  readonly chargeId: Scalars['String']
  readonly customUnitPrice?: Maybe<Scalars['BigDecimal']>
  readonly defaultDiscountRatio?: Maybe<Scalars['BigDecimal']>
  readonly defaultQuantity?: Maybe<Scalars['Long']>
  readonly planId: Scalars['String']
}

export type PlanMinimal = {
  readonly __typename?: 'PlanMinimal'
  readonly charges: ReadonlyArray<ChargeDetail>
  readonly currency?: Maybe<Scalars['String']>
  readonly description?: Maybe<Scalars['String']>
  readonly entityIds?: Maybe<ReadonlyArray<Scalars['String']>>
  readonly externalId?: Maybe<Scalars['String']>
  readonly id?: Maybe<Scalars['String']>
  readonly name: Scalars['String']
  readonly product: Product
  readonly productId: Scalars['String']
  readonly status: PlanStatus
}

export enum PlanStatus {
  /** ACTIVE */
  Active = 'ACTIVE',
  /** ARCHIVED */
  Archived = 'ARCHIVED',
  /** DEPRECATED */
  Deprecated = 'DEPRECATED',
  /** DRAFT */
  Draft = 'DRAFT',
  /** GRANDFATHERED */
  Grandfathered = 'GRANDFATHERED',
}

export enum PlatformFeature {
  /** ACCOUNTING */
  Accounting = 'ACCOUNTING',
  /** BILLING */
  Billing = 'BILLING',
  /** ESIGN */
  Esign = 'ESIGN',
  /** REVENUE_RECOGNITION */
  RevenueRecognition = 'REVENUE_RECOGNITION',
}

export type PredefinedDiscountDetail = {
  readonly __typename?: 'PredefinedDiscountDetail'
  readonly description?: Maybe<Scalars['String']>
  readonly id: Scalars['String']
  readonly name?: Maybe<Scalars['String']>
  readonly percent: Scalars['BigDecimal']
  readonly status?: Maybe<DiscountStatus>
  readonly type: Scalars['String']
}

export type PredefinedDiscountLineItemDetail = {
  readonly __typename?: 'PredefinedDiscountLineItemDetail'
  readonly amount: Scalars['BigDecimal']
  readonly description?: Maybe<Scalars['String']>
  readonly id: Scalars['String']
  readonly name?: Maybe<Scalars['String']>
  readonly percent: Scalars['BigDecimal']
  readonly status?: Maybe<DiscountStatus>
  readonly type: Scalars['String']
}

export type PredefinedReportDefinition = {
  readonly __typename?: 'PredefinedReportDefinition'
  readonly chart?: Maybe<PredefinedReportDefinitionChart>
  readonly description: Scalars['String']
  readonly filters?: Maybe<ReadonlyArray<Maybe<PredefinedReportParameter>>>
  readonly name: Scalars['String']
  readonly reportId: Scalars['String']
}

export type PredefinedReportDefinitionChart = {
  readonly __typename?: 'PredefinedReportDefinitionChart'
  readonly chartType: Scalars['String']
  readonly crossAxisKey?: Maybe<Scalars['String']>
  readonly crossAxisLabel?: Maybe<Scalars['String']>
  readonly crossAxisScale?: Maybe<Scalars['String']>
  readonly mainAxisKey: Scalars['String']
  readonly mainAxisLabel?: Maybe<Scalars['String']>
  readonly mainAxisScale?: Maybe<Scalars['String']>
  readonly showLegend?: Maybe<Scalars['Boolean']>
  readonly sortBy?: Maybe<Scalars['String']>
  readonly title?: Maybe<Scalars['String']>
}

export type PredefinedReportParameter = {
  readonly __typename?: 'PredefinedReportParameter'
  readonly allowedValues?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>
  readonly datatype: ParamDataType
  readonly defaultValue?: Maybe<Scalars['String']>
  readonly description: Scalars['String']
  readonly name: Scalars['String']
  readonly optional?: Maybe<Scalars['Boolean']>
  readonly type: ParamType
}

export type PredefinedReports = {
  readonly __typename?: 'PredefinedReports'
  readonly reportDefs: ReadonlyArray<Maybe<PredefinedReportDefinition>>
}

export type PriceAttribute = {
  readonly __typename?: 'PriceAttribute'
  readonly id?: Maybe<Scalars['String']>
  readonly name: Scalars['String']
  readonly type?: Maybe<PriceAttributeType>
  readonly values: ReadonlyArray<Scalars['String']>
}

export enum PriceAttributeType {
  /** AGGREGATING */
  Aggregating = 'AGGREGATING',
  /** PRICE_TABLE_RESOLVING */
  PriceTableResolving = 'PRICE_TABLE_RESOLVING',
}

export type PriceTableElement = {
  readonly __typename?: 'PriceTableElement'
  readonly attributeReferences: ReadonlyArray<AttributeReference>
  readonly price: Scalars['BigDecimal']
}

export type Product = {
  readonly __typename?: 'Product'
  readonly description?: Maybe<Scalars['String']>
  readonly displayName?: Maybe<Scalars['String']>
  readonly entityIds?: Maybe<ReadonlyArray<Scalars['String']>>
  readonly externalId?: Maybe<Scalars['String']>
  readonly id: Scalars['String']
  readonly inUse: Scalars['Boolean']
  readonly name: Scalars['String']
  /** @deprecated Deprecated */
  readonly productCategory?: Maybe<ProductCategory>
  readonly productCategoryId?: Maybe<Scalars['String']>
  readonly sku?: Maybe<Scalars['String']>
  readonly updatedOn?: Maybe<Scalars['Long']>
}

export type ProductCategory = {
  readonly __typename?: 'ProductCategory'
  readonly description?: Maybe<Scalars['String']>
  readonly entityIds?: Maybe<ReadonlyArray<Scalars['String']>>
  readonly id: Scalars['String']
  readonly inUse?: Maybe<Scalars['Boolean']>
  readonly name?: Maybe<Scalars['String']>
  readonly updatedOn?: Maybe<Scalars['Long']>
}

export type ProductDetail = {
  readonly __typename?: 'ProductDetail'
  readonly description?: Maybe<Scalars['String']>
  readonly displayName?: Maybe<Scalars['String']>
  readonly entityIds?: Maybe<ReadonlyArray<Scalars['String']>>
  readonly externalId?: Maybe<Scalars['String']>
  readonly id: Scalars['String']
  readonly inUse?: Maybe<Scalars['Boolean']>
  readonly name: Scalars['String']
  readonly productCategory?: Maybe<ProductCategory>
  readonly sku?: Maybe<Scalars['String']>
  readonly updatedOn?: Maybe<Scalars['Long']>
}

export type Proposal = {
  readonly __typename?: 'Proposal'
  readonly currencyCode?: Maybe<Scalars['String']>
  readonly proposalFor?: Maybe<Scalars['String']>
  readonly proposalId?: Maybe<Scalars['String']>
  readonly variations: ReadonlyArray<Maybe<Variation>>
}

export type ProposalLine = {
  readonly __typename?: 'ProposalLine'
  readonly chargeId?: Maybe<Scalars['String']>
  readonly chargeName?: Maybe<Scalars['String']>
  readonly discount?: Maybe<Scalars['BigDecimal']>
  readonly id?: Maybe<Scalars['String']>
  readonly listAmount?: Maybe<Scalars['BigDecimal']>
  readonly listUnitPrice?: Maybe<Scalars['BigDecimal']>
  readonly planId?: Maybe<Scalars['String']>
  readonly planName?: Maybe<Scalars['String']>
  readonly quantity?: Maybe<Scalars['Long']>
  readonly sellAmount?: Maybe<Scalars['BigDecimal']>
  readonly sellUnitPrice?: Maybe<Scalars['BigDecimal']>
}

export enum ProrationMode {
  /** EXACT */
  Exact = 'EXACT',
  /** EXACT_DAYS */
  ExactDays = 'EXACT_DAYS',
  /** NORMALIZED */
  Normalized = 'NORMALIZED',
}

export enum ProrationScheme {
  /** CALENDAR_DAYS */
  CalendarDays = 'CALENDAR_DAYS',
  /** FIXED_DAYS */
  FixedDays = 'FIXED_DAYS',
}

export type PurchaseOrder = {
  readonly __typename?: 'PurchaseOrder'
  readonly addedOn: Scalars['Long']
  readonly originOrderId?: Maybe<Scalars['String']>
  readonly purchaseOrderNumber: Scalars['String']
}

export type RateCard = {
  readonly __typename?: 'RateCard'
  readonly currencyCode: Scalars['String']
  readonly description?: Maybe<Scalars['String']>
  readonly entityIds?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>
  readonly id?: Maybe<Scalars['String']>
  readonly name: Scalars['String']
  readonly priceTable: ReadonlyArray<PriceTableElement>
}

export enum RecognitionDateAlignment {
  /** INVOICE_END_DATE */
  InvoiceEndDate = 'INVOICE_END_DATE',
  /** INVOICE_START_DATE */
  InvoiceStartDate = 'INVOICE_START_DATE',
}

export enum RecognitionDistributionMethod {
  /** DAYS */
  Days = 'DAYS',
  /** MONTHS_EVEN */
  MonthsEven = 'MONTHS_EVEN',
  /** MONTHS_PARTIAL_PRORATED */
  MonthsPartialProrated = 'MONTHS_PARTIAL_PRORATED',
}

export enum RecognitionEventType {
  /** AMOUNT */
  Amount = 'AMOUNT',
  /** PERCENTAGE_OF_COMPLETION */
  PercentageOfCompletion = 'PERCENTAGE_OF_COMPLETION',
}

export type RecognitionRule = {
  readonly __typename?: 'RecognitionRule'
  readonly deferredRevenueAccountId?: Maybe<Scalars['String']>
  readonly distributionMethod?: Maybe<RecognitionDistributionMethod>
  readonly entityIds?: Maybe<ReadonlyArray<Scalars['String']>>
  readonly id?: Maybe<Scalars['String']>
  readonly inUse?: Maybe<Scalars['Boolean']>
  readonly isCatchupRequired?: Maybe<Scalars['Boolean']>
  readonly name: Scalars['String']
  readonly recognitionDateAlignment?: Maybe<RecognitionDateAlignment>
  readonly recognitionEventType?: Maybe<RecognitionEventType>
  readonly recognitionType: RecognitionType
  readonly recognizedRevenueAccountId?: Maybe<Scalars['String']>
  readonly source: RecognitionSource
}

export enum RecognitionSource {
  /** INVOICE */
  Invoice = 'INVOICE',
  /** ORDER */
  Order = 'ORDER',
}

export enum RecognitionType {
  /** EVENT */
  Event = 'EVENT',
  /** OVER_TIME */
  OverTime = 'OVER_TIME',
  /** POINT_IN_TIME */
  PointInTime = 'POINT_IN_TIME',
}

export type Recurrence = {
  readonly __typename?: 'Recurrence'
  readonly cycle: Cycle
  readonly step: Scalars['Int']
}

export type Refund = {
  readonly __typename?: 'Refund'
  readonly amount?: Maybe<Scalars['BigDecimal']>
  readonly createdBy?: Maybe<Scalars['String']>
  readonly createdOn?: Maybe<Scalars['Long']>
  readonly creditMemoNumber: Scalars['String']
  readonly currency: Scalars['String']
  readonly id: Scalars['String']
  readonly notes?: Maybe<Scalars['String']>
  readonly paymentId: Scalars['String']
  readonly paymentMethodType?: Maybe<Scalars['String']>
  readonly referenceId?: Maybe<Scalars['String']>
  readonly refundDate: Scalars['Long']
  readonly refundId: Scalars['String']
  readonly updatedOn?: Maybe<Scalars['Long']>
}

export type RefundPreviewDetail = {
  readonly __typename?: 'RefundPreviewDetail'
  readonly appliedDate: Scalars['Long']
  readonly creditMemoNumber: Scalars['String']
  readonly currency: Scalars['String']
  readonly paymentDetail: PaymentDetail
  readonly referenceId?: Maybe<Scalars['String']>
  readonly refundAmount: Scalars['BigDecimal']
  readonly refundAvailable: Scalars['BigDecimal']
}

export type ReportJobResponse = {
  readonly __typename?: 'ReportJobResponse'
  readonly status: ReportJobStatus
  readonly uri: Scalars['String']
}

export enum ReportJobStatus {
  /** FAILED */
  Failed = 'FAILED',
  /** OK */
  Ok = 'OK',
}

export enum RevenueStatus {
  /** OPEN */
  Open = 'OPEN',
  /** POSTED */
  Posted = 'POSTED',
  /** PROCESSING */
  Processing = 'PROCESSING',
}

export type RevenueWaterfallItem = {
  readonly __typename?: 'RevenueWaterfallItem'
  readonly accountingPeriod?: Maybe<AccountingPeriod>
  readonly recognizedEarlier?: Maybe<Scalars['BigDecimal']>
  readonly recognizedRevenue?: Maybe<Scalars['BigDecimal']>
  readonly revenueAmount?: Maybe<Scalars['BigDecimal']>
  readonly status?: Maybe<RevenueStatus>
  readonly unitOfCompletion?: Maybe<Scalars['BigDecimal']>
  readonly unrecognizedAfterwards?: Maybe<Scalars['BigDecimal']>
  readonly unrecognizedRevenue?: Maybe<Scalars['BigDecimal']>
}

export enum Role {
  /** ACCOUNTANT */
  Accountant = 'ACCOUNTANT',
  /** ADMIN */
  Admin = 'ADMIN',
  /** BILLING_CLERK */
  BillingClerk = 'BILLING_CLERK',
  /** BILLY_ADMIN */
  BillyAdmin = 'BILLY_ADMIN',
  /** BILLY_ENGINEER */
  BillyEngineer = 'BILLY_ENGINEER',
  /** BILLY_JOB */
  BillyJob = 'BILLY_JOB',
  /** CRM */
  Crm = 'CRM',
  /** EXECUTIVE */
  Executive = 'EXECUTIVE',
  /** FINANCE */
  Finance = 'FINANCE',
  /** IMPORT */
  Import = 'IMPORT',
  /** READ_ONLY */
  ReadOnly = 'READ_ONLY',
  /** REVENUE_CLERK */
  RevenueClerk = 'REVENUE_CLERK',
  /** SALES */
  Sales = 'SALES',
  /** SALES_MANAGER */
  SalesManager = 'SALES_MANAGER',
}

export enum RoundingMode {
  /** CEILING */
  Ceiling = 'CEILING',
  /** DOWN */
  Down = 'DOWN',
  /** FLOOR */
  Floor = 'FLOOR',
  /** HALF_DOWN */
  HalfDown = 'HALF_DOWN',
  /** HALF_EVEN */
  HalfEven = 'HALF_EVEN',
  /** HALF_UP */
  HalfUp = 'HALF_UP',
  /** UNNECESSARY */
  Unnecessary = 'UNNECESSARY',
  /** UP */
  Up = 'UP',
}

export type SalesRoom = {
  readonly __typename?: 'SalesRoom'
  readonly accountDetail: AccountDetail
  readonly customFields: ReadonlyArray<CustomFieldEntry>
  readonly electronicSignatureStatus?: Maybe<ElectronicSignatureStatus>
  readonly hasElectronicSignatureIntegration: Scalars['Boolean']
  readonly linkId: Scalars['String']
  readonly orderDetail: OrderDetail
  readonly tenantAddress?: Maybe<AccountAddress>
  readonly tenantName?: Maybe<Scalars['String']>
}

export type SalesRoomLink = {
  readonly __typename?: 'SalesRoomLink'
  readonly accountId: Scalars['String']
  readonly linkId: Scalars['String']
  readonly orderId: Scalars['String']
}

export type SalesforceAccount = {
  readonly __typename?: 'SalesforceAccount'
  readonly billingAddress?: Maybe<SalesforceAccountBillingAddress>
  readonly currency?: Maybe<Scalars['String']>
  readonly description?: Maybe<Scalars['String']>
  readonly id: Scalars['String']
  readonly name: Scalars['String']
  readonly phone?: Maybe<Scalars['String']>
}

export type SalesforceAccountBillingAddress = {
  readonly __typename?: 'SalesforceAccountBillingAddress'
  readonly city: Scalars['String']
  readonly country: Scalars['String']
  readonly postalCode: Scalars['String']
  readonly state: Scalars['String']
  readonly street: Scalars['String']
}

export type SalesforceContact = {
  readonly __typename?: 'SalesforceContact'
  readonly action?: Maybe<SalesforceContactAction>
  readonly email?: Maybe<Scalars['String']>
  readonly firstName?: Maybe<Scalars['String']>
  readonly id?: Maybe<Scalars['String']>
  readonly lastName?: Maybe<Scalars['String']>
  readonly mailingCity?: Maybe<Scalars['String']>
  readonly mailingCountry?: Maybe<Scalars['String']>
  readonly mailingPostalCode?: Maybe<Scalars['String']>
  readonly mailingState?: Maybe<Scalars['String']>
  readonly mailingStreet?: Maybe<Scalars['String']>
  readonly phone?: Maybe<Scalars['String']>
  readonly title?: Maybe<Scalars['String']>
}

export enum SalesforceContactAction {
  /** INSERT */
  Insert = 'INSERT',
  /** UPDATE */
  Update = 'UPDATE',
}

export type SalesforceIntegrationResponse = {
  readonly __typename?: 'SalesforceIntegrationResponse'
  readonly baseLoginUrl?: Maybe<Scalars['String']>
  readonly clientId: Scalars['String']
  readonly createdOn: Scalars['Long']
  readonly integrationId: Scalars['String']
  readonly isCompleted: Scalars['Boolean']
  readonly updatedOn: Scalars['Long']
}

export type Setting = {
  readonly __typename?: 'Setting'
  readonly defaultTimeZone?: Maybe<Scalars['String']>
  readonly globalBccEmail?: Maybe<Scalars['String']>
  readonly isUpdateOrderStartDateEnabled?: Maybe<Scalars['Boolean']>
  readonly orderExpiryDurationInDays?: Maybe<Scalars['String']>
  readonly percentDerivedFrom?: Maybe<PercentDerivedFrom>
  readonly signingOrder?: Maybe<SigningOrder>
  /** @deprecated Deprecated */
  readonly supportedCurrencies?: Maybe<ReadonlyArray<Scalars['String']>>
  readonly tenantSettingSeal?: Maybe<TenantSettingSeal>
}

export type SettlementApplication = {
  readonly __typename?: 'SettlementApplication'
  readonly amount: Scalars['BigDecimal']
  readonly applicationType: SettlementApplicationType
  readonly appliedOn: Scalars['Long']
  readonly createdOn: Scalars['Long']
  readonly creditMemoNumber?: Maybe<Scalars['String']>
  readonly id: Scalars['String']
  readonly invoiceNumber: Scalars['String']
  readonly negatedSettlement?: Maybe<SettlementApplication>
  readonly note?: Maybe<Scalars['String']>
  readonly payment?: Maybe<PaymentDetail>
  /** @deprecated Deprecated */
  readonly paymentId?: Maybe<Scalars['String']>
}

export enum SettlementApplicationType {
  /** CREDIT */
  Credit = 'CREDIT',
  /** PAYMENT */
  Payment = 'PAYMENT',
  /** UNAPPLY_CREDIT */
  UnapplyCredit = 'UNAPPLY_CREDIT',
  /** VOID_PAYMENT */
  VoidPayment = 'VOID_PAYMENT',
}

export enum SigningOrder {
  /** ACCOUNT_FIRST */
  AccountFirst = 'ACCOUNT_FIRST',
  /** ACCOUNT_ONLY */
  AccountOnly = 'ACCOUNT_ONLY',
  /** TENANT_FIRST */
  TenantFirst = 'TENANT_FIRST',
}

export enum Status {
  /** ACTIVE */
  Active = 'ACTIVE',
  /** DISABLED */
  Disabled = 'DISABLED',
  /** EXPIRED */
  Expired = 'EXPIRED',
}

export type StripeIntentResponse = {
  readonly __typename?: 'StripeIntentResponse'
  readonly clientSecret: Scalars['String']
  readonly paymentIntentId: Scalars['String']
}

export type StripeSetupIntentResponse = {
  readonly __typename?: 'StripeSetupIntentResponse'
  readonly clientSecret: Scalars['String']
  readonly setupIntentId: Scalars['String']
}

export type Subscription = {
  readonly __typename?: 'Subscription'
  readonly account: Account
  readonly activationDate?: Maybe<Scalars['Long']>
  readonly autoRenew?: Maybe<Scalars['Boolean']>
  readonly billingAnchorDate?: Maybe<Scalars['Long']>
  readonly billingContact: AccountContact
  readonly billingCycle: Recurrence
  readonly billingTerm?: Maybe<BillingTerm>
  readonly canceledDate?: Maybe<Scalars['Long']>
  readonly charges: ReadonlyArray<Maybe<SubscriptionCharge>>
  readonly creationTime: Scalars['Long']
  readonly currency?: Maybe<Scalars['String']>
  readonly emailNotifiersList: EmailNotifiersDetail
  readonly endDate: Scalars['Long']
  readonly entityId?: Maybe<Scalars['String']>
  readonly externalId?: Maybe<Scalars['String']>
  readonly id: Scalars['String']
  readonly mailingContacts?: Maybe<ReadonlyArray<AccountContact>>
  readonly orders: ReadonlyArray<Maybe<Scalars['String']>>
  readonly paymentTerm?: Maybe<Scalars['String']>
  readonly predefinedDiscounts?: Maybe<ReadonlyArray<Maybe<PredefinedDiscountDetail>>>
  readonly purchaseOrderRequiredForInvoicing?: Maybe<Scalars['Boolean']>
  readonly purchaseOrders?: Maybe<ReadonlyArray<Maybe<PurchaseOrder>>>
  readonly renewedFromDate?: Maybe<Scalars['Long']>
  readonly renewedFromSubscription?: Maybe<Subscription>
  readonly renewedToDate?: Maybe<Scalars['Long']>
  readonly renewedToSubscription?: Maybe<Subscription>
  readonly resoldBy?: Maybe<Account>
  readonly restructuredFromDate?: Maybe<Scalars['Long']>
  readonly restructuredFromSubscription?: Maybe<Subscription>
  readonly restructuredToDate?: Maybe<Scalars['Long']>
  readonly restructuredToSubscription?: Maybe<Subscription>
  readonly shippingContact: AccountContact
  readonly startDate: Scalars['Long']
  readonly state: SubscriptionState
  readonly subscriptionMetrics: AccountMetrics
  readonly termLength?: Maybe<Recurrence>
  readonly version: Scalars['Int']
}

export type SubscriptionCharge = {
  readonly __typename?: 'SubscriptionCharge'
  readonly attributeReferences?: Maybe<ReadonlyArray<Maybe<AttributeReference>>>
  readonly charge: Charge
  readonly chargeDetail: ChargeDetail
  readonly currencyConversionRateId?: Maybe<Scalars['String']>
  readonly discountAmount?: Maybe<Scalars['BigDecimal']>
  readonly discounts?: Maybe<ReadonlyArray<Maybe<Discount>>>
  readonly endDate: Scalars['Long']
  readonly groupId: Scalars['String']
  readonly id: Scalars['String']
  readonly isRamp: Scalars['Boolean']
  readonly listUnitPrice?: Maybe<Scalars['BigDecimal']>
  readonly orderLines: ReadonlyArray<Maybe<Scalars['String']>>
  readonly plan: PlanDetail
  readonly predefinedDiscounts?: Maybe<ReadonlyArray<Maybe<PredefinedDiscountLineItemDetail>>>
  readonly quantity: Scalars['Long']
  readonly sellUnitPrice?: Maybe<Scalars['BigDecimal']>
  readonly startDate: Scalars['Long']
}

export type SubscriptionChargeAlias = {
  readonly __typename?: 'SubscriptionChargeAlias'
  readonly aliasId?: Maybe<Scalars['String']>
  readonly chargeId?: Maybe<Scalars['String']>
  readonly subscriptionId?: Maybe<Scalars['String']>
}

export type SubscriptionChargeRecognition = {
  readonly __typename?: 'SubscriptionChargeRecognition'
  readonly accountingPeriod?: Maybe<AccountingPeriod>
  readonly chargeId: Scalars['String']
  readonly deferredRevenue?: Maybe<Scalars['BigDecimal']>
  /** @deprecated Deprecated */
  readonly invoicedValue?: Maybe<Scalars['BigDecimal']>
  readonly recognitionRule?: Maybe<RecognitionRule>
  readonly recognizedRevenue?: Maybe<Scalars['BigDecimal']>
  readonly revenueWaterfallItems?: Maybe<ReadonlyArray<RevenueWaterfallItem>>
  readonly subscriptionId: Scalars['String']
  readonly totalRevenue?: Maybe<Scalars['BigDecimal']>
}

export type SubscriptionModifiableResponse = {
  readonly __typename?: 'SubscriptionModifiableResponse'
  readonly message: Scalars['String']
  readonly modifiable: Scalars['Boolean']
}

export enum SubscriptionState {
  /** ACTIVE */
  Active = 'ACTIVE',
  /** CANCELLED */
  Cancelled = 'CANCELLED',
  /** EXPIRED */
  Expired = 'EXPIRED',
  /** PENDING */
  Pending = 'PENDING',
  /** PENDING_CANCELLATION */
  PendingCancellation = 'PENDING_CANCELLATION',
}

export enum SupportedPaymentProvider {
  /** STRIPE */
  Stripe = 'STRIPE',
}

export type SystemSettings = {
  readonly __typename?: 'SystemSettings'
  readonly customFieldSettings: CustomFieldSettings
}

export enum TaxExemptionUseCode {
  /** A */
  A = 'A',
  /** B */
  B = 'B',
  /** C */
  C = 'C',
  /** D */
  D = 'D',
  /** E */
  E = 'E',
  /** F */
  F = 'F',
  /** G */
  G = 'G',
  /** H */
  H = 'H',
  /** I */
  I = 'I',
  /** J */
  J = 'J',
  /** K */
  K = 'K',
  /** L */
  L = 'L',
  /** M */
  M = 'M',
  /** N */
  N = 'N',
  /** P */
  P = 'P',
  /** Q */
  Q = 'Q',
  /** R */
  R = 'R',
}

export type TaxJarIntegration = {
  readonly __typename?: 'TaxJarIntegration'
  readonly createdOn: Scalars['Long']
  readonly integrationId: Scalars['String']
  readonly isDeleted: Scalars['Boolean']
  readonly isSandbox?: Maybe<Scalars['Boolean']>
  readonly maskedApiKey: Scalars['String']
  readonly updatedOn: Scalars['Long']
}

export type TaxRate = {
  readonly __typename?: 'TaxRate'
  readonly description?: Maybe<Scalars['String']>
  readonly id?: Maybe<Scalars['String']>
  readonly inUse?: Maybe<Scalars['Boolean']>
  readonly name: Scalars['String']
  readonly status: Status
  readonly taxCode: Scalars['String']
  readonly taxInclusive: Scalars['Boolean']
  readonly taxPercentage?: Maybe<Scalars['BigDecimal']>
}

export type Tenant = {
  readonly __typename?: 'Tenant'
  readonly address?: Maybe<AccountAddress>
  readonly createdOn?: Maybe<Scalars['Long']>
  readonly email?: Maybe<Scalars['String']>
  readonly hasHubSpotIntegration: Scalars['Boolean']
  readonly hasSalesforceIntegration: Scalars['Boolean']
  readonly hasSso?: Maybe<Scalars['Boolean']>
  readonly id?: Maybe<Scalars['String']>
  readonly isDeleted?: Maybe<Scalars['Boolean']>
  readonly isTest?: Maybe<Scalars['Boolean']>
  readonly name: Scalars['String']
  readonly phoneNumber?: Maybe<Scalars['String']>
  readonly tenantSetting?: Maybe<Setting>
}

export type TenantCreationResponseJson = {
  readonly __typename?: 'TenantCreationResponseJson'
  readonly adminApiKey?: Maybe<Scalars['String']>
  readonly tenantId: Scalars['String']
}

export type TenantDetails = {
  readonly __typename?: 'TenantDetails'
  readonly address?: Maybe<AccountAddress>
  readonly email?: Maybe<Scalars['String']>
  readonly name: Scalars['String']
  readonly phoneNumber?: Maybe<Scalars['String']>
}

export type TenantDiscountJson = {
  readonly __typename?: 'TenantDiscountJson'
  readonly id?: Maybe<Scalars['String']>
  readonly percent?: Maybe<Scalars['BigDecimal']>
}

export type TenantDiscountLineItemJson = {
  readonly __typename?: 'TenantDiscountLineItemJson'
  readonly amount?: Maybe<Scalars['BigDecimal']>
  readonly id?: Maybe<Scalars['String']>
  readonly percent?: Maybe<Scalars['BigDecimal']>
}

export type TenantJob = {
  readonly __typename?: 'TenantJob'
  readonly attempts?: Maybe<Scalars['Int']>
  readonly createdOn?: Maybe<Scalars['String']>
  readonly endedOn?: Maybe<Scalars['String']>
  readonly entityId?: Maybe<Scalars['String']>
  readonly failureReason?: Maybe<Scalars['String']>
  readonly id?: Maybe<Scalars['String']>
  readonly jobType?: Maybe<TenantJobType>
  readonly module?: Maybe<TenantJobModule>
  readonly objectId?: Maybe<Scalars['String']>
  readonly objectModel?: Maybe<TenantJobObjectModel>
  readonly startedOn?: Maybe<Scalars['String']>
  readonly status?: Maybe<TenantJobStatus>
}

export enum TenantJobModule {
  /** ACCOUNTING */
  Accounting = 'ACCOUNTING',
  /** AUTOMATIC_INVOICE_GENERATION */
  AutomaticInvoiceGeneration = 'AUTOMATIC_INVOICE_GENERATION',
  /** ERP */
  Erp = 'ERP',
  /** FOREIGN_EXCHANGE */
  ForeignExchange = 'FOREIGN_EXCHANGE',
  /** HUBSPOT */
  Hubspot = 'HUBSPOT',
  /** QUICKBOOKS */
  Quickbooks = 'QUICKBOOKS',
  /** REVENUE_RECOGNITION */
  RevenueRecognition = 'REVENUE_RECOGNITION',
  /** SALESFORCE */
  Salesforce = 'SALESFORCE',
}

export enum TenantJobObjectModel {
  /** ACCOUNT */
  Account = 'ACCOUNT',
  /** ACCOUNTING_PERIOD */
  AccountingPeriod = 'ACCOUNTING_PERIOD',
  /** AUTOMATED_INVOICE_RULE */
  AutomatedInvoiceRule = 'AUTOMATED_INVOICE_RULE',
  /** COMPOSITE_ORDER */
  CompositeOrder = 'COMPOSITE_ORDER',
  /** CREDIT_MEMO */
  CreditMemo = 'CREDIT_MEMO',
  /** CRM_OPPORTUNITY */
  CrmOpportunity = 'CRM_OPPORTUNITY',
  /** EXCHANGE_RATE_REFRESH_DATE */
  ExchangeRateRefreshDate = 'EXCHANGE_RATE_REFRESH_DATE',
  /** INVOICE */
  Invoice = 'INVOICE',
  /** ORDER */
  Order = 'ORDER',
  /** SUBSCRIPTION */
  Subscription = 'SUBSCRIPTION',
}

export enum TenantJobStatus {
  /** FAILED */
  Failed = 'FAILED',
  /** PROCESSING */
  Processing = 'PROCESSING',
  /** QUEUED */
  Queued = 'QUEUED',
  /** SUCCESSFUL */
  Successful = 'SUCCESSFUL',
}

export enum TenantJobType {
  /** AUTOMATED_INVOICE_RULE_TRIGGER */
  AutomatedInvoiceRuleTrigger = 'AUTOMATED_INVOICE_RULE_TRIGGER',
  /** CREATE_JOURNAL_ENTRIES */
  CreateJournalEntries = 'CREATE_JOURNAL_ENTRIES',
  /** CRM_COMPOSITE_ORDER_DELETION_SYNC */
  CrmCompositeOrderDeletionSync = 'CRM_COMPOSITE_ORDER_DELETION_SYNC',
  /** CRM_ORDER_DELETION_SYNC */
  CrmOrderDeletionSync = 'CRM_ORDER_DELETION_SYNC',
  /** CRM_SUBSCRIPTION_DELETION_SYNC */
  CrmSubscriptionDeletionSync = 'CRM_SUBSCRIPTION_DELETION_SYNC',
  /** ERP_CREDIT_MEMO_SYNC */
  ErpCreditMemoSync = 'ERP_CREDIT_MEMO_SYNC',
  /** ERP_INVOICE_SYNC */
  ErpInvoiceSync = 'ERP_INVOICE_SYNC',
  /** ERP_JOURNAL_ENTRY_CREATION */
  ErpJournalEntryCreation = 'ERP_JOURNAL_ENTRY_CREATION',
  /** ERP_JOURNAL_ENTRY_DELETION */
  ErpJournalEntryDeletion = 'ERP_JOURNAL_ENTRY_DELETION',
  /** ERP_VOID_INVOICE */
  ErpVoidInvoice = 'ERP_VOID_INVOICE',
  /** EXCHANGE_RATE_REFRESH */
  ExchangeRateRefresh = 'EXCHANGE_RATE_REFRESH',
  /** HUBSPOT_ACCOUNT_SYNC */
  HubspotAccountSync = 'HUBSPOT_ACCOUNT_SYNC',
  /** HUBSPOT_COMPOSITE_ORDER_SYNC */
  HubspotCompositeOrderSync = 'HUBSPOT_COMPOSITE_ORDER_SYNC',
  /** HUBSPOT_ELECTRONIC_SIGNATURE_SYNC */
  HubspotElectronicSignatureSync = 'HUBSPOT_ELECTRONIC_SIGNATURE_SYNC',
  /** HUBSPOT_ORDER_ANNUAL_AMOUNT_SYNC */
  HubspotOrderAnnualAmountSync = 'HUBSPOT_ORDER_ANNUAL_AMOUNT_SYNC',
  /** HUBSPOT_ORDER_SYNC */
  HubspotOrderSync = 'HUBSPOT_ORDER_SYNC',
  /** HUBSPOT_SUBSCRIPTION_SYNC */
  HubspotSubscriptionSync = 'HUBSPOT_SUBSCRIPTION_SYNC',
  /** QBO_JOURNAL_ENTRY_CREATION */
  QboJournalEntryCreation = 'QBO_JOURNAL_ENTRY_CREATION',
  /** QBO_JOURNAL_ENTRY_DELETION */
  QboJournalEntryDeletion = 'QBO_JOURNAL_ENTRY_DELETION',
  /** RECOGNIZE_REVENUE */
  RecognizeRevenue = 'RECOGNIZE_REVENUE',
  /** REV_GENERATE_SCHEDULE */
  RevGenerateSchedule = 'REV_GENERATE_SCHEDULE',
  /** REV_GENERATE_SCHEDULES_FOR_TENANT */
  RevGenerateSchedulesForTenant = 'REV_GENERATE_SCHEDULES_FOR_TENANT',
  /** REV_REGENERATE_SCHEDULE */
  RevRegenerateSchedule = 'REV_REGENERATE_SCHEDULE',
  /** REV_REGENERATE_SCHEDULES_FOR_TENANT */
  RevRegenerateSchedulesForTenant = 'REV_REGENERATE_SCHEDULES_FOR_TENANT',
  /** SALESFORCE_ACCOUNT_ARR_SYNC */
  SalesforceAccountArrSync = 'SALESFORCE_ACCOUNT_ARR_SYNC',
  /** SALESFORCE_ACCOUNT_SYNC */
  SalesforceAccountSync = 'SALESFORCE_ACCOUNT_SYNC',
  /** SALESFORCE_COMPOSITE_ORDER_SYNC */
  SalesforceCompositeOrderSync = 'SALESFORCE_COMPOSITE_ORDER_SYNC',
  /** SALESFORCE_ORDER_SYNC */
  SalesforceOrderSync = 'SALESFORCE_ORDER_SYNC',
  /** SALESFORCE_SUBSCRIPTION_SYNC */
  SalesforceSubscriptionSync = 'SALESFORCE_SUBSCRIPTION_SYNC',
  /** SALESFORCE_TRANSACTIONAL_ARR_METRICS_SYNC */
  SalesforceTransactionalArrMetricsSync = 'SALESFORCE_TRANSACTIONAL_ARR_METRICS_SYNC',
}

export enum TenantSettingSeal {
  /** OFF */
  Off = 'OFF',
  /** ON */
  On = 'ON',
}

export type TimeSeriesAmount = {
  readonly __typename?: 'TimeSeriesAmount'
  readonly amount: Scalars['BigDecimal']
  readonly instant: Scalars['Long']
}

export type TimeZoneData = {
  readonly __typename?: 'TimeZoneData'
  readonly isDefaultTimeZone: Scalars['Boolean']
  readonly offset: Scalars['String']
  readonly zoneId: Scalars['String']
}

export type UiConfiguration = {
  readonly __typename?: 'UIConfiguration'
  readonly orderPageConfiguration?: Maybe<OrderPageConfiguration>
}

export type UiElement = {
  readonly __typename?: 'UIElement'
  readonly name?: Maybe<Scalars['String']>
}

export type UnitOfMeasure = {
  readonly __typename?: 'UnitOfMeasure'
  readonly UOMStatus: UnitOfMeasureStatus
  readonly description: Scalars['String']
  readonly id: Scalars['String']
  readonly name: Scalars['String']
}

export enum UnitOfMeasureStatus {
  /** ACTIVE */
  Active = 'ACTIVE',
  /** DEPRECATED */
  Deprecated = 'DEPRECATED',
  /** DRAFT */
  Draft = 'DRAFT',
}

export type UpsertCrmContactResponse = {
  readonly __typename?: 'UpsertCRMContactResponse'
  readonly crmContact?: Maybe<CrmContact>
  readonly error?: Maybe<Scalars['String']>
  /** @deprecated Deprecated */
  readonly salesforceContact?: Maybe<SalesforceContact>
  readonly upserted?: Maybe<Scalars['Boolean']>
  readonly upsertedContact?: Maybe<AccountContact>
}

export type UsageBatchInsertResult = {
  readonly __typename?: 'UsageBatchInsertResult'
  readonly entries?: Maybe<ReadonlyArray<UsageEntry>>
  readonly rawUsagesTotal: Scalars['Int']
  readonly totalDuplicates: Scalars['Int']
  readonly totalFailed: Scalars['Int']
}

export type UsageEntry = {
  readonly __typename?: 'UsageEntry'
  readonly failed: Scalars['Boolean']
  readonly failureReason?: Maybe<Scalars['String']>
  readonly rowNumber: Scalars['Long']
}

export type User = {
  readonly __typename?: 'User'
  readonly approvalSegments?: Maybe<ReadonlyArray<Maybe<ApprovalSegment>>>
  readonly availableEntities?: Maybe<ReadonlyArray<Entity>>
  readonly cognitoUserStatus?: Maybe<UserStatusType>
  readonly displayName: Scalars['String']
  readonly email: Scalars['String']
  readonly entityIds?: Maybe<ReadonlyArray<Scalars['String']>>
  readonly externalId?: Maybe<Scalars['String']>
  readonly hasAllEntitiesAccess?: Maybe<Scalars['Boolean']>
  readonly id: Scalars['String']
  readonly phoneNumber?: Maybe<Scalars['String']>
  readonly role: Role
  readonly ssoOnly?: Maybe<Scalars['Boolean']>
  readonly state: Status
  readonly tenantName?: Maybe<Scalars['String']>
  readonly title?: Maybe<Scalars['String']>
  readonly userGroups?: Maybe<ReadonlyArray<Maybe<UserGroupJson>>>
}

export type UserActionTimeJson = {
  readonly __typename?: 'UserActionTimeJson'
  readonly note?: Maybe<Scalars['String']>
  readonly time: Scalars['Long']
  readonly user: User
}

export type UserAuthInfo = {
  readonly __typename?: 'UserAuthInfo'
  readonly authMethod: AuthMethod
  readonly clientId: Scalars['String']
  readonly domainName: Scalars['String']
  readonly isEmailLinkLoginEnabled: Scalars['Boolean']
  readonly userPoolId: Scalars['String']
}

export type UserGroupDetail = {
  readonly __typename?: 'UserGroupDetail'
  readonly description?: Maybe<Scalars['String']>
  readonly entityIds?: Maybe<ReadonlyArray<Scalars['String']>>
  readonly externalId?: Maybe<Scalars['String']>
  readonly id: Scalars['String']
  readonly isDeleted: Scalars['Boolean']
  readonly isRole: Scalars['Boolean']
  readonly isUser: Scalars['Boolean']
  readonly name: Scalars['String']
  readonly updatedOn: Scalars['Long']
  readonly users: ReadonlyArray<Maybe<User>>
}

export type UserGroupJson = {
  readonly __typename?: 'UserGroupJson'
  readonly description?: Maybe<Scalars['String']>
  readonly entityIds?: Maybe<ReadonlyArray<Scalars['String']>>
  readonly externalId?: Maybe<Scalars['String']>
  readonly id: Scalars['String']
  readonly name: Scalars['String']
  readonly users: ReadonlyArray<Maybe<Scalars['String']>>
}

export enum UserStatusType {
  /** ARCHIVED */
  Archived = 'ARCHIVED',
  /** COMPROMISED */
  Compromised = 'COMPROMISED',
  /** CONFIRMED */
  Confirmed = 'CONFIRMED',
  /** EXTERNAL_PROVIDER */
  ExternalProvider = 'EXTERNAL_PROVIDER',
  /** FORCE_CHANGE_PASSWORD */
  ForceChangePassword = 'FORCE_CHANGE_PASSWORD',
  /** RESET_REQUIRED */
  ResetRequired = 'RESET_REQUIRED',
  /** UNCONFIRMED */
  Unconfirmed = 'UNCONFIRMED',
  /** UNKNOWN */
  Unknown = 'UNKNOWN',
  /** UNKNOWN_TO_SDK_VERSION */
  UnknownToSdkVersion = 'UNKNOWN_TO_SDK_VERSION',
}

export type Variation = {
  readonly __typename?: 'Variation'
  readonly listAmountTotal?: Maybe<Scalars['BigDecimal']>
  readonly proposalLines?: Maybe<ReadonlyArray<Maybe<ProposalLine>>>
  readonly sellAmountTotal?: Maybe<Scalars['BigDecimal']>
  readonly termInYears?: Maybe<Scalars['Int']>
}

export type WebOrderFormDetail = {
  readonly __typename?: 'WebOrderFormDetail'
  readonly html: Scalars['String']
  readonly threads: ReadonlyArray<Maybe<ClmThreadDetail>>
}

export type EmailLog = {
  readonly __typename?: 'emailLog'
  readonly createdOn: Scalars['Long']
  readonly emailContacts: EmailContacts
  readonly emailId: Scalars['String']
  readonly emailType: EmailType
  readonly entityId: Scalars['String']
  readonly parentEntityId?: Maybe<Scalars['String']>
  readonly status: Scalars['String']
  readonly updatedOn: Scalars['Long']
}

export type EmailLogsResponse = {
  readonly __typename?: 'emailLogsResponse'
  readonly logs: ReadonlyArray<EmailLog>
}

export type PriceTier = {
  readonly __typename?: 'priceTier'
  readonly amount: Scalars['BigDecimal']
  readonly overage?: Maybe<Scalars['BigDecimal']>
  readonly untilQuantity: Scalars['String']
}

export type TenantSignatory = {
  readonly __typename?: 'tenantSignatory'
  readonly user: User
}

export type AccountCoreFragment = {
  readonly __typename?: 'AccountDetail'
  readonly crmId?: string | null
  readonly currency?: string | null
  readonly description?: string | null
  readonly externalId?: string | null
  readonly erpId?: string | null
  readonly id: string
  readonly inUse: boolean
  readonly isReseller?: boolean | null
  readonly name: string
  readonly phoneNumber?: string | null
  readonly taxExemptionUseCode?: TaxExemptionUseCode | null
  readonly excludeFromBatchOperations?: boolean | null
  readonly excludeFromDunning?: boolean | null
  readonly hasAutomaticPayment?: boolean | null
  readonly supportedPaymentTypes?: ReadonlyArray<PaymentType | null> | null
  readonly entityIds?: ReadonlyArray<string> | null
  readonly address?: ({ readonly __typename?: 'AccountAddress' } & AccountAddressFragment) | null
}

export type AccountContactDetailFragment = {
  readonly __typename?: 'AccountContactDetail'
  readonly id?: string | null
  readonly externalId?: string | null
  readonly firstName: string
  readonly lastName?: string | null
  readonly phoneNumber?: string | null
  readonly email: string
  readonly title?: string | null
  readonly inUse: boolean
  readonly updatedOn?: number | null
  readonly address?: {
    readonly __typename?: 'AccountAddress'
    readonly streetAddressLine1: string
    readonly streetAddressLine2?: string | null
    readonly city: string
    readonly state?: string | null
    readonly country: string
    readonly zipcode: string
  } | null
}

export type GetAccountQueryVariables = Exact<{
  id: Scalars['String']
}>

export type GetAccountQuery = {
  readonly __typename?: 'GqlQuery'
  readonly accountDetail: {
    readonly __typename?: 'AccountDetail'
    readonly accountMetrics: { readonly __typename?: 'AccountMetrics' } & SimpleMetricsFragment
    readonly contactDetails: ReadonlyArray<
      { readonly __typename?: 'AccountContactDetail' } & AccountContactDetailFragment
    >
    readonly paymentMethodDetails?: ReadonlyArray<
      { readonly __typename?: 'PaymentMethodDetail' } & PaymentMethodDetailFragment
    > | null
    readonly enrolledPaymentMethod?:
      | ({ readonly __typename?: 'PaymentMethodDetail' } & PaymentMethodDetailFragment)
      | null
  } & AccountCoreFragment
}

export type GetCreditMemoAccountQueryVariables = Exact<{
  id: Scalars['String']
}>

export type GetCreditMemoAccountQuery = {
  readonly __typename?: 'GqlQuery'
  readonly accountDetail: {
    readonly __typename?: 'AccountDetail'
    readonly id: string
    readonly name: string
    readonly currency?: string | null
    readonly contactDetails: ReadonlyArray<{
      readonly __typename?: 'AccountContactDetail'
      readonly id?: string | null
      readonly firstName: string
      readonly lastName?: string | null
      readonly inUse: boolean
    }>
  }
}

export type SalesforceAccountBillingAddressFragment = {
  readonly __typename?: 'SalesforceAccountBillingAddress'
  readonly city: string
  readonly country: string
  readonly postalCode: string
  readonly state: string
  readonly street: string
}

export type GetSalesforceAccountByIdQueryVariables = Exact<{
  sfid: Scalars['String']
}>

export type GetSalesforceAccountByIdQuery = {
  readonly __typename?: 'GqlQuery'
  readonly getSalesforceAccountById?: {
    readonly __typename?: 'SalesforceAccount'
    readonly id: string
    readonly name: string
    readonly phone?: string | null
    readonly description?: string | null
    readonly billingAddress?:
      | ({ readonly __typename?: 'SalesforceAccountBillingAddress' } & SalesforceAccountBillingAddressFragment)
      | null
  } | null
}

export type UpsertAccountMutationVariables = Exact<{
  account: InputAccount
}>

export type UpsertAccountMutation = {
  readonly __typename?: 'GqlMutation'
  readonly upsertAccount: { readonly __typename?: 'AccountDetail' } & AccountCoreFragment
}

export type DeleteAccountMutationVariables = Exact<{
  id: Scalars['String']
}>

export type DeleteAccountMutation = {
  readonly __typename?: 'GqlMutation'
  readonly deleteAccount: { readonly __typename?: 'Account'; readonly id?: string | null }
}

export type UpsertAccountContactMutationVariables = Exact<{
  accountId: Scalars['String']
  id?: InputMaybe<Scalars['String']>
  firstName: Scalars['String']
  lastName: Scalars['String']
  email: Scalars['String']
  phoneNumber?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  externalId?: InputMaybe<Scalars['String']>
  address?: InputMaybe<InputAccountAddress>
  strict?: InputMaybe<Scalars['Boolean']>
  skipValidation?: InputMaybe<Scalars['Boolean']>
}>

export type UpsertAccountContactMutation = {
  readonly __typename?: 'GqlMutation'
  readonly upsertAccountContact: { readonly __typename?: 'AccountContact' } & AccountContactFragment
}

export type DeleteAccountContactMutationVariables = Exact<{
  id: Scalars['String']
}>

export type DeleteAccountContactMutation = {
  readonly __typename?: 'GqlMutation'
  readonly deleteAccountContact: {
    readonly __typename?: 'AccountContact'
    readonly id?: string | null
    readonly accountId?: string | null
  }
}

export type SalesforceContactFragment = {
  readonly __typename?: 'SalesforceContact'
  readonly action?: SalesforceContactAction | null
  readonly email?: string | null
  readonly firstName?: string | null
  readonly id?: string | null
  readonly lastName?: string | null
  readonly mailingCity?: string | null
  readonly mailingCountry?: string | null
  readonly mailingPostalCode?: string | null
  readonly mailingState?: string | null
  readonly mailingStreet?: string | null
  readonly phone?: string | null
}

export type SalesforceContactsForAccountQueryVariables = Exact<{
  accountId: Scalars['String']
}>

export type SalesforceContactsForAccountQuery = {
  readonly __typename?: 'GqlQuery'
  readonly salesforceContactsForAccount?: ReadonlyArray<
    { readonly __typename?: 'SalesforceContact' } & SalesforceContactFragment
  > | null
}

export type UpsertCrmContactResponseFragment = {
  readonly __typename?: 'UpsertCRMContactResponse'
  readonly error?: string | null
  readonly upserted?: boolean | null
  readonly salesforceContact?: ({ readonly __typename?: 'SalesforceContact' } & SalesforceContactFragment) | null
  readonly upsertedContact?: ({ readonly __typename?: 'AccountContact' } & AccountContactFragment) | null
  readonly crmContact?: ({ readonly __typename?: 'CrmContact' } & CrmContactFragment) | null
}

export type UpsertCrmContactMutationVariables = Exact<{
  accountId: Scalars['String']
  crmIds: ReadonlyArray<Scalars['String']> | Scalars['String']
}>

export type UpsertCrmContactMutation = {
  readonly __typename?: 'GqlMutation'
  readonly upsertCrmContacts: ReadonlyArray<
    { readonly __typename?: 'UpsertCRMContactResponse' } & UpsertCrmContactResponseFragment
  >
}

export type GetDraftInvoicesForCustomerAccountQueryVariables = Exact<{
  accountId: Scalars['String']
}>

export type GetDraftInvoicesForCustomerAccountQuery = {
  readonly __typename?: 'GqlQuery'
  readonly draftInvoicesCountForCustomerAccount: number
}

export type GetAccountByCrmIdQueryVariables = Exact<{
  accountCrmId: Scalars['String']
}>

export type GetAccountByCrmIdQuery = {
  readonly __typename?: 'GqlQuery'
  readonly accountByCrmId?: {
    readonly __typename?: 'Account'
    readonly id?: string | null
    readonly crmId?: string | null
    readonly currency?: string | null
    readonly description?: string | null
    readonly externalId?: string | null
    readonly isReseller?: boolean | null
    readonly name: string
    readonly phoneNumber?: string | null
    readonly taxExemptionUseCode?: TaxExemptionUseCode | null
    readonly excludeFromBatchOperations?: boolean | null
    readonly excludeFromDunning?: boolean | null
    readonly hasAutomaticPayment?: boolean | null
    readonly address?: ({ readonly __typename?: 'AccountAddress' } & AccountAddressFragment) | null
  } | null
}

export type CrmContactFragment = {
  readonly __typename?: 'CrmContact'
  readonly action?: CrmContactSyncAction | null
  readonly crmType?: CrmType | null
  readonly email?: string | null
  readonly firstName?: string | null
  readonly id?: string | null
  readonly lastName?: string | null
  readonly mailingCity?: string | null
  readonly mailingCountry?: string | null
  readonly mailingPostalCode?: string | null
  readonly mailingState?: string | null
  readonly mailingStreet?: string | null
  readonly phone?: string | null
}

export type GetCrmContactsForAccountQueryVariables = Exact<{
  accountId: Scalars['String']
}>

export type GetCrmContactsForAccountQuery = {
  readonly __typename?: 'GqlQuery'
  readonly crmContactsForAccount?: ReadonlyArray<{ readonly __typename?: 'CrmContact' } & CrmContactFragment> | null
}

export type GetOrCreateAccountByCrmIdMutationVariables = Exact<{
  accountCrmId: Scalars['String']
  opportunityCrmId?: InputMaybe<Scalars['String']>
}>

export type GetOrCreateAccountByCrmIdMutation = {
  readonly __typename?: 'GqlMutation'
  readonly getOrCreateAccountByCrmId?: {
    readonly __typename?: 'Account'
    readonly id?: string | null
    readonly crmId?: string | null
    readonly currency?: string | null
    readonly description?: string | null
    readonly externalId?: string | null
    readonly isReseller?: boolean | null
    readonly name: string
    readonly phoneNumber?: string | null
    readonly taxExemptionUseCode?: TaxExemptionUseCode | null
    readonly excludeFromBatchOperations?: boolean | null
    readonly excludeFromDunning?: boolean | null
    readonly hasAutomaticPayment?: boolean | null
    readonly address?: ({ readonly __typename?: 'AccountAddress' } & AccountAddressFragment) | null
  } | null
}

export type ImportAccountByCrmIdMutationVariables = Exact<{
  accountCrmId: Scalars['String']
}>

export type ImportAccountByCrmIdMutation = {
  readonly __typename?: 'GqlMutation'
  readonly importAccountByCrmId?: {
    readonly __typename?: 'Account'
    readonly id?: string | null
    readonly crmId?: string | null
    readonly currency?: string | null
    readonly description?: string | null
    readonly externalId?: string | null
    readonly isReseller?: boolean | null
    readonly name: string
    readonly phoneNumber?: string | null
    readonly taxExemptionUseCode?: TaxExemptionUseCode | null
    readonly excludeFromBatchOperations?: boolean | null
    readonly excludeFromDunning?: boolean | null
    readonly hasAutomaticPayment?: boolean | null
    readonly address?: ({ readonly __typename?: 'AccountAddress' } & AccountAddressFragment) | null
  } | null
}

export type LedgerAccountFragment = {
  readonly __typename?: 'LedgerAccount'
  readonly id?: string | null
  readonly name: string
  readonly accountCode: string
  readonly description?: string | null
  readonly accountType: LedgerAccountType
  readonly inUse?: boolean | null
  readonly isDefault?: boolean | null
}

export type GetLedgerAccountsQueryVariables = Exact<{ [key: string]: never }>

export type GetLedgerAccountsQuery = {
  readonly __typename?: 'GqlQuery'
  readonly ledgerAccounts: ReadonlyArray<{ readonly __typename?: 'LedgerAccount' } & LedgerAccountFragment>
}

export type GetAllLedgerAccountTemplatesQueryVariables = Exact<{ [key: string]: never }>

export type GetAllLedgerAccountTemplatesQuery = {
  readonly __typename?: 'GqlQuery'
  readonly allLedgerAccountTemplates: ReadonlyArray<{ readonly __typename?: 'LedgerAccount' } & LedgerAccountFragment>
}

export type UpsertLedgerAccountMutationVariables = Exact<{
  ledgerAccount: InputLedgerAccount
}>

export type UpsertLedgerAccountMutation = {
  readonly __typename?: 'GqlMutation'
  readonly upsertLedgerAccount: { readonly __typename?: 'LedgerAccount' } & LedgerAccountFragment
}

export type DeleteLedgerAccountMutationVariables = Exact<{
  id: Scalars['String']
}>

export type DeleteLedgerAccountMutation = {
  readonly __typename?: 'GqlMutation'
  readonly deleteLedgerAccount: {
    readonly __typename?: 'LedgerAccount'
    readonly id?: string | null
    readonly name: string
    readonly accountCode: string
    readonly description?: string | null
    readonly accountType: LedgerAccountType
  }
}

export type AccountingPeriodFragment = {
  readonly __typename?: 'AccountingPeriod'
  readonly id?: string | null
  readonly startDate: string
  readonly endDate: string
  readonly status: AccountingPeriodStatus
  readonly syncStatus: ExternalSyncStatus
  readonly openedBy?: string | null
  readonly openedOn?: string | null
  readonly closedBy?: string | null
  readonly closedOn?: string | null
}

export type AccountingPeriodDetailFragment = {
  readonly __typename?: 'AccountingPeriod'
  readonly calculation?: {
    readonly __typename?: 'AccountingPeriodCalculation'
    readonly deferredRevenueStartingBalance?: number | null
    readonly additionalRevenue?: number | null
    readonly deferredRevenueEndingBalance?: number | null
    readonly recognizedRevenue?: number | null
    readonly unrecognizedRevenue?: number | null
    readonly unrecognizedTransactionCount?: number | null
  } | null
  readonly openedByUser?: ({ readonly __typename?: 'User' } & UserFragment) | null
  readonly closedByUser?: ({ readonly __typename?: 'User' } & UserFragment) | null
}

export type PaymentBankAccountFragment = {
  readonly __typename?: 'PaymentBankAccountJson'
  readonly id?: string | null
  readonly cashLedgerAccountId: string
  readonly currencyCode: string
  readonly description?: string | null
  readonly entityIds: ReadonlyArray<string | null>
  readonly expenseLedgerAccountId: string
  readonly externalId?: string | null
  readonly name: string
  readonly status: PaymentBankAccountStatus
}

export type GetAccountingPeriodQueryVariables = Exact<{
  accountingPeriodId: Scalars['String']
}>

export type GetAccountingPeriodQuery = {
  readonly __typename?: 'GqlQuery'
  readonly accountingPeriod: { readonly __typename?: 'AccountingPeriod' } & AccountingPeriodFragment
}

export type GetAccountingPeriodDetailQueryVariables = Exact<{
  accountingPeriodId: Scalars['String']
}>

export type GetAccountingPeriodDetailQuery = {
  readonly __typename?: 'GqlQuery'
  readonly accountingPeriod: { readonly __typename?: 'AccountingPeriod' } & AccountingPeriodFragment &
    AccountingPeriodDetailFragment
}

export type GetCurrentAccountingPeriodQueryVariables = Exact<{ [key: string]: never }>

export type GetCurrentAccountingPeriodQuery = {
  readonly __typename?: 'GqlQuery'
  readonly currentAccountingPeriod?: ({ readonly __typename?: 'AccountingPeriod' } & AccountingPeriodFragment) | null
}

export type GetCurrentAccountingPeriodDetailQueryVariables = Exact<{ [key: string]: never }>

export type GetCurrentAccountingPeriodDetailQuery = {
  readonly __typename?: 'GqlQuery'
  readonly currentAccountingPeriod?:
    | ({ readonly __typename?: 'AccountingPeriod' } & AccountingPeriodFragment & AccountingPeriodDetailFragment)
    | null
}

export type GetCurrentAccountingPeriodByEntityIdQueryVariables = Exact<{
  entityId: Scalars['String']
}>

export type GetCurrentAccountingPeriodByEntityIdQuery = {
  readonly __typename?: 'GqlQuery'
  readonly currentAccountingPeriodByEntityId?:
    | ({ readonly __typename?: 'AccountingPeriod' } & AccountingPeriodFragment)
    | null
}

export type SpecifyCurrentAccountingPeriodMutationVariables = Exact<{
  startDate: Scalars['Long']
}>

export type SpecifyCurrentAccountingPeriodMutation = {
  readonly __typename?: 'GqlMutation'
  readonly specifyCurrentAccountingPeriod: { readonly __typename?: 'AccountingPeriod' } & AccountingPeriodFragment
}

export type GetAccountingEnabledQueryVariables = Exact<{ [key: string]: never }>

export type GetAccountingEnabledQuery = {
  readonly __typename?: 'GqlQuery'
  readonly enabledPlatformFeature: {
    readonly __typename?: 'EnabledPlatformFeature'
    readonly enabledAt?: number | null
    readonly enabledByUserId?: string | null
    readonly platformFeature?: PlatformFeature | null
  }
}

export type EnableAccountingMutationVariables = Exact<{ [key: string]: never }>

export type EnableAccountingMutation = {
  readonly __typename?: 'GqlMutation'
  readonly enableAccountingPlatformFeature: {
    readonly __typename?: 'EnabledPlatformFeature'
    readonly enabledAt?: number | null
    readonly enabledByUserId?: string | null
    readonly platformFeature?: PlatformFeature | null
  }
}

export type UpdateAccountingPeriodStatusMutationVariables = Exact<{
  id: Scalars['String']
  status: AccountingPeriodStatus
}>

export type UpdateAccountingPeriodStatusMutation = {
  readonly __typename?: 'GqlMutation'
  readonly updateAccountingPeriodStatus: { readonly __typename?: 'AccountingPeriod' } & AccountingPeriodFragment
}

export type InitiateAccountingPeriodCloseMutationVariables = Exact<{
  accountingPeriodId: Scalars['String']
}>

export type InitiateAccountingPeriodCloseMutation = {
  readonly __typename?: 'GqlMutation'
  readonly initiateAccountingPeriodClose: { readonly __typename?: 'AccountingPeriod' } & AccountingPeriodFragment &
    AccountingPeriodDetailFragment
}

export type AbandonAccountingPeriodCloseMutationVariables = Exact<{
  accountingPeriodId: Scalars['String']
}>

export type AbandonAccountingPeriodCloseMutation = {
  readonly __typename?: 'GqlMutation'
  readonly abandonAccountingPeriodClose: { readonly __typename?: 'AccountingPeriod' } & AccountingPeriodFragment &
    AccountingPeriodDetailFragment
}

export type GetRecentAccountingPeriodsQueryVariables = Exact<{
  recentNumberOfMonths: Scalars['Long']
}>

export type GetRecentAccountingPeriodsQuery = {
  readonly __typename?: 'GqlQuery'
  readonly recentAccountingPeriods: ReadonlyArray<{
    readonly __typename?: 'AccountingPeriod'
    readonly id?: string | null
    readonly startDate: string
    readonly endDate: string
    readonly status: AccountingPeriodStatus
  }>
}

export type GenerateJournalEntriesMutationVariables = Exact<{
  accountingPeriodId: Scalars['String']
}>

export type GenerateJournalEntriesMutation = {
  readonly __typename?: 'GqlMutation'
  readonly generateJournalEntries: string
}

export type SyncJournalEntriesToErpMutationVariables = Exact<{
  accountingPeriodId: Scalars['String']
}>

export type SyncJournalEntriesToErpMutation = {
  readonly __typename?: 'GqlMutation'
  readonly syncJournalEntriesInAccountingPeriodToErp: string
}

export type DeleteJournalEntriesFromErpMutationVariables = Exact<{
  accountingPeriodId: Scalars['String']
}>

export type DeleteJournalEntriesFromErpMutation = {
  readonly __typename?: 'GqlMutation'
  readonly deleteJournalEntriesInAccountingPeriodFromErp: string
}

export type GetErpIntegrationQueryVariables = Exact<{ [key: string]: never }>

export type GetErpIntegrationQuery = {
  readonly __typename?: 'GqlQuery'
  readonly erpIntegration?: ({ readonly __typename?: 'Integration' } & IntegrationFragment) | null
}

export type GetLedgerAccountsForPaymentBankAccountQueryVariables = Exact<{ [key: string]: never }>

export type GetLedgerAccountsForPaymentBankAccountQuery = {
  readonly __typename?: 'GqlQuery'
  readonly ledgerAccountsForPaymentBankAccount: ReadonlyArray<
    { readonly __typename?: 'LedgerAccount' } & LedgerAccountFragment
  >
}

export type GetPaymentBankAccountQueryVariables = Exact<{
  id: Scalars['String']
}>

export type GetPaymentBankAccountQuery = {
  readonly __typename?: 'GqlQuery'
  readonly paymentBankAccount: { readonly __typename?: 'PaymentBankAccountJson' } & PaymentBankAccountFragment
}

export type GetApplicablePaymentBankAccountsForInvoicePaymentQueryVariables = Exact<{
  invoiceNumber: Scalars['String']
}>

export type GetApplicablePaymentBankAccountsForInvoicePaymentQuery = {
  readonly __typename?: 'GqlQuery'
  readonly applicablePaymentBankAccountsForInvoicePayment: ReadonlyArray<
    { readonly __typename?: 'PaymentBankAccountJson' } & PaymentBankAccountFragment
  >
}

export type UpsertPaymentBankAccountMutationVariables = Exact<{
  paymentBankAccount: InputPaymentBankAccountJson
}>

export type UpsertPaymentBankAccountMutation = {
  readonly __typename?: 'GqlMutation'
  readonly upsertPaymentBankAccount: { readonly __typename?: 'PaymentBankAccountJson' } & PaymentBankAccountFragment
}

export type DeletePaymentBankAccountMutationVariables = Exact<{
  id: Scalars['String']
}>

export type DeletePaymentBankAccountMutation = {
  readonly __typename?: 'GqlMutation'
  readonly deletePaymentBankAccount: { readonly __typename?: 'PaymentBankAccountJson' } & PaymentBankAccountFragment
}

export type TenantFragment = {
  readonly __typename?: 'Tenant'
  readonly id?: string | null
  readonly name: string
  readonly isDeleted?: boolean | null
  readonly email?: string | null
  readonly phoneNumber?: string | null
  readonly hasSalesforceIntegration: boolean
  readonly hasHubSpotIntegration: boolean
  readonly hasSso?: boolean | null
}

export type GetCurrentTenantQueryVariables = Exact<{ [key: string]: never }>

export type GetCurrentTenantQuery = {
  readonly __typename?: 'GqlQuery'
  readonly currentTenant: { readonly __typename?: 'Tenant' } & TenantFragment
}

export type GetTenantsQueryVariables = Exact<{ [key: string]: never }>

export type GetTenantsQuery = {
  readonly __typename?: 'GqlQuery'
  readonly tenants: ReadonlyArray<{ readonly __typename?: 'Tenant' } & TenantFragment>
}

export type TenantCreationResponseJsonFragment = {
  readonly __typename?: 'TenantCreationResponseJson'
  readonly tenantId: string
}

export type AddTenantMutationVariables = Exact<{
  tenant: InputTenant
}>

export type AddTenantMutation = {
  readonly __typename?: 'GqlMutation'
  readonly addTenant: { readonly __typename?: 'TenantCreationResponseJson' } & TenantCreationResponseJsonFragment
}

export type AnrokIntegrationFragment = {
  readonly __typename?: 'Integration'
  readonly clientId: string
  readonly createdOn?: string | null
  readonly environment?: string | null
  readonly id?: string | null
  readonly maskedApiKey?: string | null
  readonly realmId?: string | null
  readonly status?: IntegrationStatus | null
  readonly targetService: IntegrationTargetService
  readonly updatedOn?: string | null
}

export type GetAnrokIntegrationQueryVariables = Exact<{ [key: string]: never }>

export type GetAnrokIntegrationQuery = {
  readonly __typename?: 'GqlQuery'
  readonly anrokIntegration: { readonly __typename?: 'Integration' } & AnrokIntegrationFragment
}

export type TestAnrokIntegrationMutationVariables = Exact<{
  apiKey: Scalars['String']
}>

export type TestAnrokIntegrationMutation = {
  readonly __typename?: 'GqlMutation'
  readonly testAnrokIntegration: boolean
}

export type UpsertAnrokIntegrationMutationVariables = Exact<{
  apiKey: Scalars['String']
}>

export type UpsertAnrokIntegrationMutation = {
  readonly __typename?: 'GqlMutation'
  readonly upsertAnrokIntegration: { readonly __typename?: 'Integration' } & AnrokIntegrationFragment
}

export type HasAnrokIntegrationQueryVariables = Exact<{ [key: string]: never }>

export type HasAnrokIntegrationQuery = { readonly __typename?: 'GqlQuery'; readonly hasAnrokIntegration: boolean }

export type ApiKeyFragment = {
  readonly __typename?: 'ApiKeyDetail'
  readonly id: string
  readonly entityId?: string | null
  readonly role?: string | null
  readonly generatedBy?: string | null
  readonly active: boolean
  readonly note?: string | null
  readonly deactivatedBy?: string | null
  readonly userId?: string | null
  readonly expiresOn?: number | null
  readonly createdOn: number
}

export type GetApiKeyQueryVariables = Exact<{
  id: Scalars['String']
}>

export type GetApiKeyQuery = {
  readonly __typename?: 'GqlQuery'
  readonly apiKey: { readonly __typename?: 'ApiKeyDetail' } & ApiKeyFragment
}

export type GetApiKeysQueryVariables = Exact<{
  activeOnly: Scalars['Boolean']
}>

export type GetApiKeysQuery = {
  readonly __typename?: 'GqlQuery'
  readonly apiKeys: ReadonlyArray<{ readonly __typename?: 'ApiKeyDetail' } & ApiKeyFragment>
}

export type GenerateApiKeyV3MutationVariables = Exact<{
  apiKey: InputApiKey
}>

export type GenerateApiKeyV3Mutation = { readonly __typename?: 'GqlMutation'; readonly generateApiKeyV3: string }

export type RevokeApiKeyV3MutationVariables = Exact<{
  id: Scalars['String']
}>

export type RevokeApiKeyV3Mutation = { readonly __typename?: 'GqlMutation'; readonly revokeApiKeyV3: boolean }

export type ApprovalStateFragment = {
  readonly __typename?: 'ApprovalStateDetail'
  readonly id: string
  readonly name: string
  readonly approvalGroup: {
    readonly __typename?: 'UserGroupDetail'
    readonly description?: string | null
    readonly id: string
    readonly isUser: boolean
    readonly isRole: boolean
    readonly name: string
    readonly users: ReadonlyArray<({ readonly __typename?: 'User' } & UserFragment) | null>
  }
}

export type ApprovalFlowFragment = {
  readonly __typename?: 'ApprovalFlowDetail'
  readonly description?: string | null
  readonly id?: string | null
  readonly name: string
  readonly entityIds?: ReadonlyArray<string> | null
  readonly status?: string | null
  readonly states: ReadonlyArray<{
    readonly __typename?: 'ApprovalStateDetail'
    readonly id: string
    readonly name: string
    readonly action: { readonly __typename?: 'ApprovalStateAction'; readonly emailGroupId: string }
    readonly approvalGroup: {
      readonly __typename?: 'UserGroupDetail'
      readonly description?: string | null
      readonly id: string
      readonly isDeleted: boolean
      readonly isUser: boolean
      readonly isRole: boolean
      readonly name: string
      readonly updatedOn: number
      readonly users: ReadonlyArray<{
        readonly __typename?: 'User'
        readonly id: string
        readonly displayName: string
        readonly title?: string | null
        readonly email: string
        readonly phoneNumber?: string | null
        readonly role: Role
        readonly state: Status
      } | null>
    }
  } | null>
  readonly transitionRules: ReadonlyArray<{
    readonly __typename?: 'ApprovalTransitionRuleDetail'
    readonly condition?: string | null
    readonly id: string
    readonly name: string
    readonly fromState?: ({ readonly __typename?: 'ApprovalStateDetail' } & ApprovalStateFragment) | null
    readonly ruleConditions: {
      readonly __typename?: 'ApprovalRuleConditions'
      readonly orderCondition?: string | null
      readonly orderLineCondition?: string | null
    }
    readonly toState: { readonly __typename?: 'ApprovalStateDetail' } & ApprovalStateFragment
  } | null>
}

export type GetApprovalFlowQueryVariables = Exact<{
  id: Scalars['String']
}>

export type GetApprovalFlowQuery = {
  readonly __typename?: 'GqlQuery'
  readonly approvalFlows: ReadonlyArray<{ readonly __typename?: 'ApprovalFlowDetail' } & ApprovalFlowFragment>
}

export type GetPendingApprovalFlowsForUserQueryVariables = Exact<{ [key: string]: never }>

export type GetPendingApprovalFlowsForUserQuery = {
  readonly __typename?: 'GqlQuery'
  readonly pendingApprovalFlowsForUser: ReadonlyArray<{
    readonly __typename?: 'PendingOrderApprovalFlow'
    readonly accountName: string
    readonly approvalFlowGroupId: string
    readonly approvalFlowId: string
    readonly approvalFlowName: string
    readonly approvalStateId: string
    readonly approvalStateName: string
    readonly id: string
    readonly orderId: string
    readonly orderType: OrderType
    readonly tcv: number
    readonly updatedOn: number
  }>
}

export type GetApprovalRolesQueryVariables = Exact<{ [key: string]: never }>

export type GetApprovalRolesQuery = {
  readonly __typename?: 'GqlQuery'
  readonly approvalRoles: ReadonlyArray<{
    readonly __typename?: 'ApprovalRole'
    readonly id?: string | null
    readonly name: string
    readonly description?: string | null
  }>
}

export type DeleteApprovalRoleMutationVariables = Exact<{
  id: Scalars['String']
}>

export type DeleteApprovalRoleMutation = {
  readonly __typename?: 'GqlMutation'
  readonly deleteApprovalRole: {
    readonly __typename?: 'ApprovalRole'
    readonly id?: string | null
    readonly name: string
    readonly description?: string | null
  }
}

export type UpsertApprovalRoleMutationVariables = Exact<{
  approvalRole: InputApprovalRole
}>

export type UpsertApprovalRoleMutation = {
  readonly __typename?: 'GqlMutation'
  readonly upsertApprovalRole: {
    readonly __typename?: 'ApprovalRole'
    readonly id?: string | null
    readonly name: string
    readonly description?: string | null
  }
}

export type UpsertApprovalFlowMutationVariables = Exact<{
  flow: InputApprovalFlow
}>

export type UpsertApprovalFlowMutation = {
  readonly __typename?: 'GqlMutation'
  readonly upsertApprovalFlow: { readonly __typename?: 'ApprovalFlowDetail' } & ApprovalFlowFragment
}

export type DeleteApprovalFlowMutationVariables = Exact<{
  id: Scalars['String']
}>

export type DeleteApprovalFlowMutation = {
  readonly __typename?: 'GqlMutation'
  readonly deleteApprovalFlow: { readonly __typename?: 'ApprovalFlowDetail'; readonly id?: string | null }
}

export type UpdateOrderApprovalStateMutationVariables = Exact<{
  orderApprovalStateUpdates: InputApprovalFlowInstanceStateUpdates
}>

export type UpdateOrderApprovalStateMutation = {
  readonly __typename?: 'GqlMutation'
  readonly updateOrderApprovalState?: {
    readonly __typename?: 'OrderDetail'
    readonly id?: string | null
    readonly approvalFlows: ReadonlyArray<
      { readonly __typename?: 'ApprovalFlowInstanceGroupDetail' } & ApprovalFlowInstanceGroupFragment
    >
  } | null
}

export type ApprovalWithCodeResponseFragment = {
  readonly __typename?: 'ApprovalWithCodeResponse'
  readonly orderId: string
  readonly status: ApprovalWithCodeStatus
}

export type UpdateApprovalFlowStatusChangeWithCodeMutationVariables = Exact<{
  code: Scalars['String']
  state: Scalars['Boolean']
}>

export type UpdateApprovalFlowStatusChangeWithCodeMutation = {
  readonly __typename?: 'GqlMutation'
  readonly approvalFlowStatusChangeWithCode: {
    readonly __typename?: 'ApprovalWithCodeResponse'
  } & ApprovalWithCodeResponseFragment
}

export type UpsertApprovalFlowSubmitterNoteMutationVariables = Exact<{
  approvalFlowSubmitterNote: InputApprovalFlowSubmitterNote
}>

export type UpsertApprovalFlowSubmitterNoteMutation = {
  readonly __typename?: 'GqlMutation'
  readonly upsertApprovalFlowSubmitterNote: {
    readonly __typename?: 'ApprovalFlowSubmitterNote'
    readonly id?: string | null
    readonly note: string
    readonly orderId: string
  }
}

export type AttachmentFragment = {
  readonly __typename?: 'Attachment'
  readonly id: string
  readonly name: string
  readonly description?: string | null
  readonly status?: AttachmentStatus | null
  readonly tag?: AttachmentTag | null
  readonly updatedOn?: string | null
  readonly createdOn: string
}

export type GetAttachmentsQueryVariables = Exact<{
  accountId: Scalars['String']
}>

export type GetAttachmentsQuery = {
  readonly __typename?: 'GqlQuery'
  readonly attachments: ReadonlyArray<{ readonly __typename?: 'Attachment' } & AttachmentFragment>
}

export type GetUserAuthInfoQueryVariables = Exact<{
  email: Scalars['String']
}>

export type GetUserAuthInfoQuery = {
  readonly __typename?: 'GqlQuery'
  readonly userAuthInfo?: {
    readonly __typename?: 'UserAuthInfo'
    readonly authMethod: AuthMethod
    readonly clientId: string
    readonly domainName: string
    readonly userPoolId: string
    readonly isEmailLinkLoginEnabled: boolean
  } | null
}

export type EmailLinkForLoginMutationVariables = Exact<{
  email: Scalars['String']
}>

export type EmailLinkForLoginMutation = { readonly __typename?: 'GqlMutation'; readonly emailLinkForLogin: boolean }

export type HasAvalaraIntegrationQueryVariables = Exact<{ [key: string]: never }>

export type HasAvalaraIntegrationQuery = { readonly __typename?: 'GqlQuery'; readonly hasAvalaraIntegration: boolean }

export type GetAvalaraIntegrationQueryVariables = Exact<{ [key: string]: never }>

export type GetAvalaraIntegrationQuery = {
  readonly __typename?: 'GqlQuery'
  readonly avalaraIntegration: {
    readonly __typename?: 'AvalaraIntegration'
    readonly accountId: string
    readonly companyCode?: string | null
    readonly createdOn: number
    readonly updatedOn: number
    readonly sandboxEnvironment: boolean
    readonly shouldCommitDocuments?: boolean | null
    readonly integrationId: string
  }
}

export type PingAvalaraMutationVariables = Exact<{
  accountId: Scalars['String']
  key: Scalars['String']
  sandbox: Scalars['Boolean']
}>

export type PingAvalaraMutation = { readonly __typename?: 'GqlMutation'; readonly pingAvalara: string }

export type UpsertAvalaraMutationVariables = Exact<{
  accountId: Scalars['String']
  companyCode: Scalars['String']
  key: Scalars['String']
  sandbox: Scalars['Boolean']
  commitDocuments: Scalars['Boolean']
}>

export type UpsertAvalaraMutation = {
  readonly __typename?: 'GqlMutation'
  readonly upsertAvalaraIntegration: {
    readonly __typename?: 'AvalaraIntegration'
    readonly integrationId: string
    readonly accountId: string
    readonly companyCode?: string | null
    readonly sandboxEnvironment: boolean
    readonly shouldCommitDocuments?: boolean | null
    readonly createdOn: number
  }
}

export type LastUsageArrivalCheckpointQueryVariables = Exact<{ [key: string]: never }>

export type LastUsageArrivalCheckpointQuery = {
  readonly __typename?: 'GqlQuery'
  readonly lastUsageArrivalCheckpoint?: number | null
}

export type BulkInvoiceRunFragment = {
  readonly __typename?: 'BulkInvoiceRun'
  readonly id: string
  readonly bulkInvoiceRunId: string
  readonly entityId?: string | null
  readonly name?: string | null
  readonly description?: string | null
  readonly targetDate?: number | null
  readonly invoiceDate?: number | null
  readonly chargeInclusionOption?: InvoiceChargeInclusionOption | null
  readonly phase?: BulkInvoiceRunPhase | null
  readonly failureReason?: string | null
  readonly status?: BulkInvoiceRunStatus | null
  readonly createdOn?: number | null
  readonly updatedOn?: number | null
}

export type GetBulkInvoiceRunQueryVariables = Exact<{
  id: Scalars['String']
}>

export type GetBulkInvoiceRunQuery = {
  readonly __typename?: 'GqlQuery'
  readonly bulkInvoiceRun: { readonly __typename?: 'BulkInvoiceRun' } & BulkInvoiceRunFragment
}

export type GetBulkInvoiceRunItemsQueryVariables = Exact<{
  id: Scalars['String']
}>

export type GetBulkInvoiceRunItemsQuery = {
  readonly __typename?: 'GqlQuery'
  readonly bulkInvoiceRunItems: ReadonlyArray<{
    readonly __typename?: 'BulkInvoiceRunItem'
    readonly runId: string
    readonly accountId: string
    readonly accountName?: string | null
    readonly subscriptionId: string
    readonly draftInvoiceNumber?: string | null
    readonly postedInvoiceNumber?: string | null
    readonly invoiceAmount?: number | null
    readonly invoiceCurrencyCode?: string | null
    readonly invoiceCreatedOn?: number | null
    readonly failureReason?: string | null
    readonly excludedForPosting?: boolean | null
    readonly createdOn?: number | null
    readonly updatedOn?: number | null
  }>
}

export type AddBulkInvoiceRunMutationVariables = Exact<{
  input: InputBulkInvoiceRunInput
}>

export type AddBulkInvoiceRunMutation = {
  readonly __typename?: 'GqlMutation'
  readonly addBulkInvoiceRun: { readonly __typename?: 'BulkInvoiceRun' } & BulkInvoiceRunFragment
}

export type PostBulkInvoiceRunMutationVariables = Exact<{
  bulkInvoiceRunId: Scalars['String']
  exclusionIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>
}>

export type PostBulkInvoiceRunMutation = {
  readonly __typename?: 'GqlMutation'
  readonly postInvoicesForBulkInvoiceRun: { readonly __typename?: 'BulkInvoiceRun' } & BulkInvoiceRunFragment
}

export type CancelAndRestructureSubscriptionFragment = {
  readonly __typename?: 'Subscription'
  readonly id: string
  readonly startDate: number
  readonly endDate: number
  readonly account: { readonly __typename?: 'Account'; readonly id?: string | null; readonly name: string }
  readonly charges: ReadonlyArray<{
    readonly __typename?: 'SubscriptionCharge'
    readonly id: string
    readonly quantity: number
  } | null>
}

export type CancelAndRestructureOrderDetailFragment = {
  readonly __typename?: 'CancelAndRestructureOrder'
  readonly id?: string | null
  readonly currency?: string | null
  readonly autoRenew?: boolean | null
  readonly billingAnchorDate?: number | null
  readonly billingTerm: BillingTerm
  readonly crmOpportunityId?: string | null
  readonly documentMasterTemplateId?: string | null
  readonly entityId?: string | null
  readonly endDate?: number | null
  readonly name?: string | null
  readonly paymentTerm: string
  readonly purchaseOrderNumber?: string | null
  readonly purchaseOrderRequiredForInvoicing?: boolean | null
  readonly rampInterval?: ReadonlyArray<number | null> | null
  readonly startDate: number
  readonly status?: OrderStatus | null
  readonly type?: CompositeOrderType | null
  readonly compositeOrderId?: string | null
  readonly arr?: number | null
  readonly deltaArr?: number | null
  readonly tcv?: number | null
  readonly nonRecurringTotal?: number | null
  readonly recurringTotal?: number | null
  readonly crmOpportunityType?: string | null
  readonly crmOpportunityName?: string | null
  readonly crmOpportunityStage?: string | null
  readonly account: { readonly __typename?: 'Account' } & AccountFragment
  readonly billingContact?: ({ readonly __typename?: 'AccountContact' } & AccountContactFragment) | null
  readonly billingCycle: { readonly __typename?: 'Recurrence' } & BillingCycleFragment
  readonly currentSubscription?:
    | ({ readonly __typename?: 'Subscription' } & CancelAndRestructureSubscriptionFragment)
    | null
  readonly orderFormTemplates?: ReadonlyArray<
    ({ readonly __typename?: 'DocumentTemplate' } & DocumentTemplateFragment) | null
  > | null
  readonly documentCustomContent?: {
    readonly __typename?: 'DocumentCustomContent'
    readonly id?: string | null
    readonly orderId: string
    readonly title?: string | null
    readonly content: string
  } | null
  readonly lineItems: ReadonlyArray<({ readonly __typename?: 'OrderLineItemDetail' } & LineItemFragment) | null>
  readonly orders?: ReadonlyArray<{
    readonly __typename?: 'OrderDetailMinimal'
    readonly effectiveDate?: number | null
    readonly id?: string | null
    readonly name?: string | null
    readonly ownerId?: string | null
    readonly status: OrderStatus
    readonly orderType: OrderType
  } | null> | null
  readonly owner?: {
    readonly __typename?: 'User'
    readonly id: string
    readonly displayName: string
    readonly state: Status
  } | null
  readonly predefinedDiscounts?: ReadonlyArray<
    ({ readonly __typename?: 'PredefinedDiscountDetail' } & DiscountFragment) | null
  > | null
  readonly resoldBy?: ({ readonly __typename?: 'Account' } & AccountFragment) | null
  readonly shippingContact?: ({ readonly __typename?: 'AccountContact' } & AccountContactFragment) | null
  readonly termLength?: { readonly __typename?: 'Recurrence'; readonly cycle: Cycle; readonly step: number } | null
  readonly customFields?: ReadonlyArray<
    { readonly __typename?: 'CustomFieldEntry' } & OrderLevelCustomFieldEntryFragment
  > | null
}

export type GenerateCancelAndRestructureMutationVariables = Exact<{
  subscriptionId: Scalars['String']
}>

export type GenerateCancelAndRestructureMutation = {
  readonly __typename?: 'GqlMutation'
  readonly generateCancelAndRestructure: {
    readonly __typename?: 'CancelAndRestructureOrder'
  } & CancelAndRestructureOrderDetailFragment
}

export type UpsertCancelAndRestructureMutationVariables = Exact<{
  order: InputCancelAndRestructureRequest
}>

export type UpsertCancelAndRestructureMutation = {
  readonly __typename?: 'GqlMutation'
  readonly upsertCancelAndRestructure: {
    readonly __typename?: 'CancelAndRestructureOrder'
  } & CancelAndRestructureOrderDetailFragment
}

export type DryRunUpsertCancelAndRestructureMutationVariables = Exact<{
  order: InputCancelAndRestructureRequest
}>

export type DryRunUpsertCancelAndRestructureMutation = {
  readonly __typename?: 'GqlMutation'
  readonly upsertCancelAndRestructure: {
    readonly __typename?: 'CancelAndRestructureOrder'
  } & CancelAndRestructureOrderDetailFragment
}

export type GetCancelAndRestructureQueryVariables = Exact<{
  id: Scalars['String']
}>

export type GetCancelAndRestructureQuery = {
  readonly __typename?: 'GqlQuery'
  readonly cancelAndRestructure: {
    readonly __typename?: 'CancelAndRestructureOrder'
  } & CancelAndRestructureOrderDetailFragment
}

export type UpdateCancelAndRestructureOrderStatusMutationVariables = Exact<{
  id: Scalars['String']
  status: OrderStatus
  statusUpdatedOn?: InputMaybe<Scalars['Long']>
}>

export type UpdateCancelAndRestructureOrderStatusMutation = {
  readonly __typename?: 'GqlMutation'
  readonly updateCancelAndRestructureOrderStatus: {
    readonly __typename?: 'CancelAndRestructureOrder'
    readonly id?: string | null
    readonly status?: OrderStatus | null
  }
}

export type DeleteCancelAndRestructureOrderMutationVariables = Exact<{
  id: Scalars['String']
}>

export type DeleteCancelAndRestructureOrderMutation = {
  readonly __typename?: 'GqlMutation'
  readonly deleteCancelAndRestructure: {
    readonly __typename?: 'CancelAndRestructureOrder'
  } & CancelAndRestructureOrderDetailFragment
}

export type AddChargeMutationVariables = Exact<{
  planId: Scalars['String']
  charge: InputCharge
}>

export type AddChargeMutation = {
  readonly __typename?: 'GqlMutation'
  readonly addCharge: { readonly __typename?: 'ChargeDetail' } & ChargeDetailFragment
}

export type UpdateChargeMutationVariables = Exact<{
  planId: Scalars['String']
  charge: InputCharge
}>

export type UpdateChargeMutation = {
  readonly __typename?: 'GqlMutation'
  readonly updateCharge: { readonly __typename?: 'ChargeDetail' } & ChargeDetailFragment
}

export type UpdateChargePartialMutationVariables = Exact<{
  planId: Scalars['String']
  chargePartial: InputChargePartial
}>

export type UpdateChargePartialMutation = {
  readonly __typename?: 'GqlMutation'
  readonly updateChargePartial: { readonly __typename?: 'ChargeDetail' } & ChargeDetailFragment
}

export type DeleteChargeMutationVariables = Exact<{
  planId: Scalars['String']
  chargeId: Scalars['String']
}>

export type DeleteChargeMutation = {
  readonly __typename?: 'GqlMutation'
  readonly deleteCharge: { readonly __typename?: 'ChargeDetail' } & ChargeDetailFragment
}

export type UpsertChargeDefaultAttributeReferencesMutationVariables = Exact<{
  attributeReferences: ReadonlyArray<InputMaybe<InputAttributeReference>> | InputMaybe<InputAttributeReference>
  chargeId: Scalars['String']
  id?: InputMaybe<Scalars['String']>
}>

export type UpsertChargeDefaultAttributeReferencesMutation = {
  readonly __typename?: 'GqlMutation'
  readonly upsertChargeDefaultAttributeReferences: {
    readonly __typename?: 'ChargeDefaultAttributeReferences'
    readonly id?: string | null
    readonly chargeId: string
  }
}

export type GetChargeQueryVariables = Exact<{
  planId: Scalars['String']
  chargeId: Scalars['String']
}>

export type GetChargeQuery = {
  readonly __typename?: 'GqlQuery'
  readonly chargeDetail: { readonly __typename?: 'ChargeDetail' } & ChargeDetailFragment
}

export type GetChargeDefaultAttributeReferencesQueryVariables = Exact<{
  chargeId?: InputMaybe<Scalars['String']>
}>

export type GetChargeDefaultAttributeReferencesQuery = {
  readonly __typename?: 'GqlQuery'
  readonly chargeDefaultAttributeReferences: {
    readonly __typename?: 'ChargeDefaultAttributeReferences'
    readonly chargeId: string
    readonly id?: string | null
    readonly attributeReferences: ReadonlyArray<{
      readonly __typename?: 'AttributeReference'
      readonly attributeDefinitionId: string
      readonly attributeValue: string
    } | null>
  }
}

export type BillyChartDataValueFragment = {
  readonly __typename?: 'BillyChartDataValue'
  readonly mainNumber?: number | null
  readonly crossNumber?: number | null
  readonly mainText?: string | null
  readonly crossText?: string | null
}

export type BillyChartDatasetFragment = {
  readonly __typename?: 'BillyChartDataset'
  readonly label: string
  readonly order: number
  readonly data: ReadonlyArray<{ readonly __typename?: 'BillyChartDataValue' } & BillyChartDataValueFragment>
}

export type CompositeOrderDetailFragment = {
  readonly __typename?: 'CompositeOrder'
  readonly id: string
  readonly type: CompositeOrderType
  readonly status: OrderStatus
  readonly orderIds: ReadonlyArray<string | null>
  readonly crmOpportunityId?: string | null
  readonly isPrimaryCompositeOrderForCrmOpportunity?: boolean | null
  readonly entityId?: string | null
  readonly account?: ({ readonly __typename?: 'Account' } & AccountFragment) | null
}

export type CreateUpsellWithEarlyRenewalMutationVariables = Exact<{
  subscriptionId: Scalars['String']
  crmOpportunityId?: InputMaybe<Scalars['String']>
  amendAndRenewRequest?: InputMaybe<InputAmendAndRenewCreateRequest>
}>

export type CreateUpsellWithEarlyRenewalMutation = {
  readonly __typename?: 'GqlMutation'
  readonly createUpsellWithEarlyRenewal: { readonly __typename?: 'CompositeOrder' } & CompositeOrderDetailFragment
}

export type GetCompositeOrderQueryVariables = Exact<{
  id: Scalars['String']
}>

export type GetCompositeOrderQuery = {
  readonly __typename?: 'GqlQuery'
  readonly compositeOrder: { readonly __typename?: 'CompositeOrder' } & CompositeOrderDetailFragment
}

export type UpdateCompositeOrderStatusMutationVariables = Exact<{
  id: Scalars['String']
  status: OrderStatus
  statusUpdatedOn?: InputMaybe<Scalars['Long']>
}>

export type UpdateCompositeOrderStatusMutation = {
  readonly __typename?: 'GqlMutation'
  readonly updateCompositeOrderStatus: {
    readonly __typename?: 'CompositeOrder'
    readonly id: string
    readonly status: OrderStatus
  }
}

export type CreateCompositeOrderDocumentMutationVariables = Exact<{
  compositeOrderId: Scalars['String']
}>

export type CreateCompositeOrderDocumentMutation = {
  readonly __typename?: 'GqlMutation'
  readonly createCompositeOrderDocument: boolean
}

export type UpdateCompositeOrderFieldsMutationVariables = Exact<{
  compositeOrderId: Scalars['String']
  compositeOrderFieldsUpdateRequest: InputCompositeOrderFieldsUpdateRequest
}>

export type UpdateCompositeOrderFieldsMutation = {
  readonly __typename?: 'GqlMutation'
  readonly updateCompositeOrderFields: { readonly __typename?: 'CompositeOrder' } & CompositeOrderDetailFragment
}

export type SettlementApplicationFragment = {
  readonly __typename?: 'SettlementApplication'
  readonly amount: number
  readonly applicationType: SettlementApplicationType
  readonly appliedOn: number
  readonly createdOn: number
  readonly creditMemoNumber?: string | null
  readonly id: string
  readonly invoiceNumber: string
  readonly note?: string | null
  readonly paymentId?: string | null
  readonly negatedSettlement?: {
    readonly __typename?: 'SettlementApplication'
    readonly amount: number
    readonly applicationType: SettlementApplicationType
    readonly appliedOn: number
    readonly createdOn: number
    readonly creditMemoNumber?: string | null
    readonly id: string
    readonly invoiceNumber: string
    readonly note?: string | null
  } | null
  readonly payment?: {
    readonly __typename?: 'PaymentDetail'
    readonly id: string
    readonly amount: number
    readonly currency: string
    readonly notes?: string | null
    readonly paymentDate: number
    readonly refundStatus?: PaymentRefundStatus | null
    readonly paymentMethodDetail: { readonly __typename?: 'PaymentMethodDetail' } & PaymentMethodDetailFragment
  } | null
}

export type CreditMemoPlanFragment = {
  readonly __typename?: 'Plan'
  readonly id?: string | null
  readonly name: string
}

export type CreditMemoFragment = {
  readonly __typename?: 'CreditMemo'
  readonly amount: number
  readonly balance?: number | null
  readonly currencyCode?: string | null
  readonly createdFrom?: string | null
  readonly createdOn: number
  readonly creditMemoNumber: string
  readonly creditMemoDate?: number | null
  readonly creditReason?: string | null
  readonly endDate?: number | null
  readonly isDeleted: boolean
  readonly notes?: string | null
  readonly postedDate?: number | null
  readonly startDate?: number | null
  readonly status: CreditMemoStatus
  readonly updatedOn: number
  readonly entityId?: string | null
  readonly erpId?: string | null
  readonly account: {
    readonly __typename?: 'Account'
    readonly id?: string | null
    readonly name: string
    readonly currency?: string | null
  }
  readonly billingContact?: {
    readonly __typename?: 'AccountContact'
    readonly id?: string | null
    readonly accountId?: string | null
  } | null
  readonly lineItems: ReadonlyArray<{
    readonly __typename?: 'CreditMemoLineItem'
    readonly amount: number
    readonly endDate: number
    readonly startDate: number
    readonly charge: { readonly __typename?: 'Charge' } & ChargeFragment
    readonly chargeDetail: { readonly __typename?: 'ChargeDetail' } & ChargeDetailFragment
    readonly plan: { readonly __typename?: 'Plan' } & PlanFragment
  } | null>
  readonly refundsApplied?: ReadonlyArray<({ readonly __typename?: 'Refund' } & RefundFragment) | null> | null
  readonly settlementApplicationDetails?: ReadonlyArray<
    ({ readonly __typename?: 'SettlementApplication' } & SettlementApplicationFragment) | null
  > | null
}

export type GetCreditMemoQueryVariables = Exact<{
  creditMemoNumber: Scalars['String']
}>

export type GetCreditMemoQuery = {
  readonly __typename?: 'GqlQuery'
  readonly creditMemo: { readonly __typename?: 'CreditMemo' } & CreditMemoFragment
}

export type PostCreditMemoMutationVariables = Exact<{
  creditMemoNumber: Scalars['String']
}>

export type PostCreditMemoMutation = {
  readonly __typename?: 'GqlMutation'
  readonly postCreditMemo: { readonly __typename?: 'CreditMemo' } & CreditMemoFragment
}

export type DeleteCreditMemoMutationVariables = Exact<{
  creditMemoNumber: Scalars['String']
}>

export type DeleteCreditMemoMutation = {
  readonly __typename?: 'GqlMutation'
  readonly deleteCreditMemo: { readonly __typename?: 'CreditMemo' } & CreditMemoFragment
}

export type ApplyCreditMemoMutationVariables = Exact<{
  ApplyCreditMemoRequest: InputApplyCreditMemoRequest
}>

export type ApplyCreditMemoMutation = {
  readonly __typename?: 'GqlMutation'
  readonly applyCreditMemo: ReadonlyArray<
    { readonly __typename?: 'SettlementApplication' } & SettlementApplicationFragment
  >
}

export type CreateStandaloneCreditMemoMutationVariables = Exact<{
  creditMemo: InputCreditMemoRequest
}>

export type CreateStandaloneCreditMemoMutation = {
  readonly __typename?: 'GqlMutation'
  readonly createStandaloneCreditMemo: { readonly __typename?: 'CreditMemo' } & CreditMemoFragment
}

export type UpdateDraftCreditMemoMutationVariables = Exact<{
  creditMemoNumber: Scalars['String']
  creditMemo: InputCreditMemoRequest
}>

export type UpdateDraftCreditMemoMutation = {
  readonly __typename?: 'GqlMutation'
  readonly updateDraftCreditMemo: { readonly __typename?: 'CreditMemo' } & CreditMemoFragment
}

export type SendCreditMemoEmailMutationVariables = Exact<{
  creditMemoNumber: Scalars['String']
}>

export type SendCreditMemoEmailMutation = {
  readonly __typename?: 'GqlMutation'
  readonly sendCreditMemoEmail: { readonly __typename?: 'CreditMemoEmailResponse'; readonly emailId: string }
}

export type RefundFragment = {
  readonly __typename?: 'Refund'
  readonly amount?: number | null
  readonly createdBy?: string | null
  readonly createdOn?: number | null
  readonly currency: string
  readonly creditMemoNumber: string
  readonly notes?: string | null
  readonly paymentId: string
  readonly paymentMethodType?: string | null
  readonly referenceId?: string | null
  readonly refundId: string
  readonly updatedOn?: number | null
}

export type GetRefundsQueryVariables = Exact<{
  accountId?: InputMaybe<Scalars['String']>
  paymentId?: InputMaybe<Scalars['String']>
}>

export type GetRefundsQuery = {
  readonly __typename?: 'GqlQuery'
  readonly refunds: ReadonlyArray<{ readonly __typename?: 'Refund' } & RefundFragment>
}

export type CreateRefundMutationVariables = Exact<{
  refundRequest: InputRefundRequest
}>

export type CreateRefundMutation = {
  readonly __typename?: 'GqlMutation'
  readonly createRefund: { readonly __typename?: 'Refund' } & RefundFragment
}

export type SyncCreditMemoToErpMutationVariables = Exact<{
  creditMemoNumber: Scalars['String']
}>

export type SyncCreditMemoToErpMutation = { readonly __typename?: 'GqlMutation'; readonly syncCreditMemoToErp: string }

export type UnapplyCreditMemoMutationVariables = Exact<{
  unapplyCreditMemoRequest: InputUnapplyCreditMemoRequest
}>

export type UnapplyCreditMemoMutation = {
  readonly __typename?: 'GqlMutation'
  readonly unapplyCreditMemo: ReadonlyArray<
    { readonly __typename?: 'SettlementApplication' } & SettlementApplicationFragment
  >
}

export type CustomFieldDefinitionFragment = {
  readonly __typename?: 'CustomFieldDefinition'
  readonly id: string
  readonly fieldName: string
  readonly fieldType: CustomFieldType
  readonly fieldLabel?: string | null
  readonly required?: boolean | null
  readonly parentObjectType: CustomFieldParentType
  readonly updatedOn: string
  readonly createdOn: string
  readonly options?: ReadonlyArray<string | null> | null
  readonly defaultValue?: {
    readonly __typename?: 'CustomFieldDefault'
    readonly value?: string | null
    readonly selections?: ReadonlyArray<string> | null
  } | null
}

export type CustomFieldEntryFragment = {
  readonly __typename?: 'CustomFieldEntry'
  readonly id: string
  readonly name: string
  readonly label?: string | null
  readonly type?: CustomFieldType | null
  readonly value?: string | null
  readonly source?: CustomFieldSource | null
  readonly options?: ReadonlyArray<string | null> | null
  readonly required?: boolean | null
  readonly selections?: ReadonlyArray<string | null> | null
  readonly defaultValue?: {
    readonly __typename?: 'CustomFieldDefault'
    readonly value?: string | null
    readonly selections?: ReadonlyArray<string> | null
  } | null
}

export type GetCustomFieldDefinitionsQueryVariables = Exact<{
  parentObjectType: CustomFieldParentType
}>

export type GetCustomFieldDefinitionsQuery = {
  readonly __typename?: 'GqlQuery'
  readonly customFieldDefinitions: ReadonlyArray<
    { readonly __typename?: 'CustomFieldDefinition' } & CustomFieldDefinitionFragment
  >
  readonly systemSettings: {
    readonly __typename?: 'SystemSettings'
    readonly customFieldSettings: {
      readonly __typename?: 'CustomFieldSettings'
      readonly definitionsPerObject?: number | null
    }
  }
}

export type CreateCustomFieldDefinitionMutationVariables = Exact<{
  customFieldDefinition: InputCustomFieldDefinitionCreate
}>

export type CreateCustomFieldDefinitionMutation = {
  readonly __typename?: 'GqlMutation'
  readonly createCustomFieldDefinition: {
    readonly __typename?: 'CustomFieldDefinition'
  } & CustomFieldDefinitionFragment
}

export type UpdateCustomFieldDefinitionMutationVariables = Exact<{
  id: Scalars['String']
  customFieldDefinition: InputCustomFieldDefinitionUpdate
}>

export type UpdateCustomFieldDefinitionMutation = {
  readonly __typename?: 'GqlMutation'
  readonly updateCustomFieldDefinition: {
    readonly __typename?: 'CustomFieldDefinition'
  } & CustomFieldDefinitionFragment
}

export type DeleteCustomFieldDefinitionMutationVariables = Exact<{
  id: Scalars['String']
}>

export type DeleteCustomFieldDefinitionMutation = {
  readonly __typename?: 'GqlMutation'
  readonly deleteCustomFieldDefinition: {
    readonly __typename?: 'CustomFieldDefinition'
  } & CustomFieldDefinitionFragment
}

export type GetCustomFieldsQueryVariables = Exact<{
  parentObjectType: CustomFieldParentType
  parentObjectId?: InputMaybe<Scalars['String']>
}>

export type GetCustomFieldsQuery = {
  readonly __typename?: 'GqlQuery'
  readonly customFields: ReadonlyArray<{ readonly __typename?: 'CustomFieldEntry' } & CustomFieldEntryFragment>
}

export type UpdateCustomFieldsMutationVariables = Exact<{
  parentObjectType: CustomFieldParentType
  parentObjectId: Scalars['String']
  customFields: ReadonlyArray<InputCustomFieldInput> | InputCustomFieldInput
}>

export type UpdateCustomFieldsMutation = {
  readonly __typename?: 'GqlMutation'
  readonly updateCustomFields: ReadonlyArray<{ readonly __typename?: 'CustomFieldEntry' } & CustomFieldEntryFragment>
}

export type GetCustomFieldsBatchedQueryVariables = Exact<{
  parentObjectType: CustomFieldParentType
  parentObjectIds: ReadonlyArray<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
}>

export type GetCustomFieldsBatchedQuery = {
  readonly __typename?: 'GqlQuery'
  readonly customFieldsBatched: ReadonlyArray<{
    readonly __typename?: 'CustomFieldWithParentReference'
    readonly parentObjectId: string
    readonly customFieldEntries: ReadonlyArray<{
      readonly __typename?: 'CustomFieldEntry'
      readonly name: string
      readonly label?: string | null
      readonly value?: string | null
    } | null>
  }>
}

export type ActionFragment = {
  readonly __typename?: 'Action'
  readonly actionMetadata: string
  readonly actionRuleName: string
  readonly customizationActionType: CustomizationActionType
}

export type PlanAdditionCustomizationQueryVariables = Exact<{
  planCustomizationInput: InputPlanAdditionCustomizationInput
}>

export type PlanAdditionCustomizationQuery = {
  readonly __typename?: 'GqlQuery'
  readonly runPlanAdditionCustomization: ReadonlyArray<{ readonly __typename?: 'Action' } & ActionFragment>
}

export type GetDefaultBulkInvoiceRunSelectorQueryVariables = Exact<{ [key: string]: never }>

export type GetDefaultBulkInvoiceRunSelectorQuery = {
  readonly __typename?: 'GqlQuery'
  readonly defaultBulkInvoiceRunSelector: {
    readonly __typename?: 'BulkInvoiceRunSelector'
    readonly exclusions: {
      readonly __typename?: 'BulkInvoiceRunExclusions'
      readonly accounts: ReadonlyArray<{
        readonly __typename?: 'Account'
        readonly id?: string | null
        readonly name: string
        readonly description?: string | null
        readonly currency?: string | null
        readonly phoneNumber?: string | null
      }>
    }
  }
}

export type GenerateDocumentLinkMutationVariables = Exact<{
  orderId: Scalars['String']
}>

export type GenerateDocumentLinkMutation = {
  readonly __typename?: 'GqlMutation'
  readonly generateDocumentLink: string
}

export type ClmChatMessageDetailFragment = {
  readonly __typename?: 'CLMChatMessageDetail'
  readonly createdOn?: number | null
  readonly message: string
  readonly threadId?: string | null
  readonly userIdentifier: string
}

export type ClmThreadDetailFragment = {
  readonly __typename?: 'CLMThreadDetail'
  readonly pinObject: string
  readonly threadId: string
  readonly messages: ReadonlyArray<
    ({ readonly __typename?: 'CLMChatMessageDetail' } & ClmChatMessageDetailFragment) | null
  >
}

export type WebOrderFormDetailQueryVariables = Exact<{
  documentId: Scalars['String']
}>

export type WebOrderFormDetailQuery = {
  readonly __typename?: 'GqlQuery'
  readonly webOrderFormDetail: {
    readonly __typename?: 'WebOrderFormDetail'
    readonly html: string
    readonly threads: ReadonlyArray<({ readonly __typename?: 'CLMThreadDetail' } & ClmThreadDetailFragment) | null>
  }
}

export type CreateClmThreadMutationVariables = Exact<{
  clmThread: InputClmCreateThreadRequest
}>

export type CreateClmThreadMutation = {
  readonly __typename?: 'GqlMutation'
  readonly createCLMThread: {
    readonly __typename?: 'CLMThreadCreationDetail'
    readonly pinObject: string
    readonly threadId: string
    readonly firstMessage: { readonly __typename?: 'CLMChatMessageDetail' } & ClmChatMessageDetailFragment
  }
}

export type AddMessageToClmThreadMutationVariables = Exact<{
  clmThreadMessageRequest: InputClmChatMessageDetail
}>

export type AddMessageToClmThreadMutation = {
  readonly __typename?: 'GqlMutation'
  readonly addMessageToCLMThread: { readonly __typename?: 'CLMChatMessageDetail' } & ClmChatMessageDetailFragment
}

export type ResolveThreadMutationVariables = Exact<{
  threadId: Scalars['String']
}>

export type ResolveThreadMutation = { readonly __typename?: 'GqlMutation'; readonly resolveThread: boolean }

export type DocuSignIntegrationResponseFragment = {
  readonly __typename?: 'DocuSignIntegrationResponse'
  readonly clientId: string
  readonly integrationId: string
  readonly environment: DocuSignEnvironmentType
  readonly completed: boolean
}

export type HasDocuSignIntegrationQueryVariables = Exact<{ [key: string]: never }>

export type HasDocuSignIntegrationQuery = { readonly __typename?: 'GqlQuery'; readonly hasDocusignIntegration: boolean }

export type GetDocuSignIntegrationQueryVariables = Exact<{ [key: string]: never }>

export type GetDocuSignIntegrationQuery = {
  readonly __typename?: 'GqlQuery'
  readonly docuSignIntegration: {
    readonly __typename?: 'DocuSignIntegrationResponse'
  } & DocuSignIntegrationResponseFragment
}

export type InitiateDocuSignIntegrationMutationVariables = Exact<{
  docuSignIntegrationRequest: InputDocuSignIntegrationRequest
}>

export type InitiateDocuSignIntegrationMutation = {
  readonly __typename?: 'GqlMutation'
  readonly initiateDocuSignIntegration: string
}

export type CompleteDocuSignIntegrationMutationVariables = Exact<{
  code: Scalars['String']
  state: Scalars['String']
}>

export type CompleteDocuSignIntegrationMutation = {
  readonly __typename?: 'GqlMutation'
  readonly completeDocuSignIntegration: boolean
}

export type DeleteDocuSignIntegrationMutationVariables = Exact<{ [key: string]: never }>

export type DeleteDocuSignIntegrationMutation = {
  readonly __typename?: 'GqlMutation'
  readonly deleteDocuSignIntegration: {
    readonly __typename?: 'DocuSignIntegrationResponse'
  } & DocuSignIntegrationResponseFragment
}

export type SendEmailForDocusignMutationVariables = Exact<{
  esignRequest: InputEsignRequest
}>

export type SendEmailForDocusignMutation = {
  readonly __typename?: 'GqlMutation'
  readonly sendEmailForDocusign: boolean
}

export type GetEmailLogsQueryVariables = Exact<{
  entityId?: InputMaybe<Scalars['String']>
  parentEntityId?: InputMaybe<Scalars['String']>
}>

export type GetEmailLogsQuery = {
  readonly __typename?: 'GqlQuery'
  readonly emailLogs: {
    readonly __typename?: 'emailLogsResponse'
    readonly logs: ReadonlyArray<{
      readonly __typename?: 'emailLog'
      readonly createdOn: number
      readonly emailId: string
      readonly emailType: EmailType
      readonly entityId: string
      readonly parentEntityId?: string | null
      readonly status: string
      readonly updatedOn: number
      readonly emailContacts: {
        readonly __typename?: 'EmailContacts'
        readonly bccContacts: ReadonlyArray<{ readonly __typename?: 'EmailContact'; readonly email: string } | null>
        readonly ccContacts: ReadonlyArray<{ readonly __typename?: 'EmailContact'; readonly email: string } | null>
        readonly toContacts: ReadonlyArray<{ readonly __typename?: 'EmailContact'; readonly email: string } | null>
      }
    }>
  }
}

export type EntityJsonFragment = {
  readonly __typename?: 'EntityJson'
  readonly id?: string | null
  readonly name: string
  readonly displayId?: string | null
  readonly prorationMode: ProrationMode
  readonly prorationScheme: ProrationScheme
  readonly functionalCurrency: string
  readonly wireInstruction?: string | null
  readonly erpId?: string | null
  readonly creditMemoConfig?: {
    readonly __typename?: 'NumberConfig'
    readonly scheme: NumberScheme
    readonly prefix: string
    readonly length: number
  } | null
  readonly invoiceConfig?: {
    readonly __typename?: 'NumberConfig'
    readonly scheme: NumberScheme
    readonly prefix: string
    readonly length: number
  } | null
  readonly companyContact?: {
    readonly __typename?: 'CompanyContactJson'
    readonly email: string
    readonly firstName: string
    readonly address?: {
      readonly __typename?: 'AccountAddress'
      readonly city: string
      readonly country: string
      readonly state?: string | null
      readonly zipcode: string
      readonly streetAddressLine1: string
      readonly streetAddressLine2?: string | null
    } | null
  } | null
}

export type EntityFragment = {
  readonly __typename?: 'Entity'
  readonly id: string
  readonly name: string
  readonly displayId?: string | null
}

export type EntityCoreFragment = {
  readonly __typename?: 'EntityResolver'
  readonly id: string
  readonly displayId?: string | null
  readonly name: string
  readonly prorationMode: ProrationMode
  readonly prorationScheme: ProrationScheme
  readonly functionalCurrency: string
  readonly wireInstruction?: string | null
  readonly erpId?: string | null
  readonly companyContact?: {
    readonly __typename?: 'CompanyContactJson'
    readonly email: string
    readonly firstName: string
    readonly address?: {
      readonly __typename?: 'AccountAddress'
      readonly city: string
      readonly country: string
      readonly state?: string | null
      readonly zipcode: string
      readonly streetAddressLine1: string
      readonly streetAddressLine2?: string | null
    } | null
  } | null
}

export type EntityConfigFragment = {
  readonly __typename?: 'EntityResolver'
  readonly invoiceConfig: {
    readonly __typename?: 'NumberConfig'
    readonly scheme: NumberScheme
    readonly prefix: string
    readonly length: number
  }
}

export type GetEntitiesQueryVariables = Exact<{ [key: string]: never }>

export type GetEntitiesQuery = {
  readonly __typename?: 'GqlQuery'
  readonly entities: ReadonlyArray<{ readonly __typename?: 'EntityJson' } & EntityJsonFragment>
}

export type GetEntityQueryVariables = Exact<{
  id: Scalars['String']
}>

export type GetEntityQuery = {
  readonly __typename?: 'GqlQuery'
  readonly entity: { readonly __typename?: 'EntityJson' } & EntityJsonFragment
}

export type GetEntityWithConfigQueryVariables = Exact<{
  id: Scalars['String']
}>

export type GetEntityWithConfigQuery = {
  readonly __typename?: 'GqlQuery'
  readonly entityResolver: { readonly __typename?: 'EntityResolver' } & EntityCoreFragment & EntityConfigFragment
}

export type AddEntityMutationVariables = Exact<{
  entity: InputEntityJson
}>

export type AddEntityMutation = {
  readonly __typename?: 'GqlMutation'
  readonly addEntity: { readonly __typename?: 'EntityJson' } & EntityJsonFragment
}

export type UpdateEntityMutationVariables = Exact<{
  entity: InputEntityPatchRequest
}>

export type UpdateEntityMutation = {
  readonly __typename?: 'GqlMutation'
  readonly updateEntity: { readonly __typename?: 'EntityJson' } & EntityJsonFragment
}

export type DeleteEntityMutationVariables = Exact<{
  entityId: Scalars['String']
}>

export type DeleteEntityMutation = { readonly __typename?: 'GqlMutation'; readonly deleteEntity: boolean }

export type ElectronicSignatureFragment = {
  readonly __typename?: 'ElectronicSignature'
  readonly id: string
  readonly externalDocumentId: string
  readonly orderId: string
  readonly documentProvider: ElectronicSignatureProvider
  readonly status: ElectronicSignatureStatus
  readonly initiatedBy: string
  readonly link?: string | null
  readonly accountSignatory?: ({ readonly __typename?: 'EmailContact' } & EmailContactFragment) | null
  readonly additionalRecipients?: ReadonlyArray<{ readonly __typename?: 'EmailContact' } & EmailContactFragment> | null
  readonly tenantSignatory?: ({ readonly __typename?: 'EmailContact' } & EmailContactFragment) | null
}

export type GetElectronicSignatureQueryVariables = Exact<{
  orderId: Scalars['String']
}>

export type GetElectronicSignatureQuery = {
  readonly __typename?: 'GqlQuery'
  readonly electronicSignature: { readonly __typename?: 'ElectronicSignature' } & ElectronicSignatureFragment
}

export type SendEmailForEsignMutationVariables = Exact<{
  esignRequest: InputEsignRequest
}>

export type SendEmailForEsignMutation = { readonly __typename?: 'GqlMutation'; readonly sendEmailForEsign: boolean }

export type VoidEsignatureDocumentMutationVariables = Exact<{
  id: Scalars['String']
}>

export type VoidEsignatureDocumentMutation = {
  readonly __typename?: 'GqlMutation'
  readonly voidEsignatureDocumentV2: string
}

export type ResendEsignatureDocumentMutationVariables = Exact<{
  id: Scalars['String']
}>

export type ResendEsignatureDocumentMutation = {
  readonly __typename?: 'GqlMutation'
  readonly resendEsignatureDocumentV2: boolean
}

export type DeleteTenantSignatoryMutationVariables = Exact<{
  userId: Scalars['String']
}>

export type DeleteTenantSignatoryMutation = {
  readonly __typename?: 'GqlMutation'
  readonly deleteTenantSignatory: boolean
}

export type EnableEsignMutationVariables = Exact<{ [key: string]: never }>

export type EnableEsignMutation = {
  readonly __typename?: 'GqlMutation'
  readonly enablePlatformFeature: {
    readonly __typename?: 'EnabledPlatformFeature'
    readonly enabledAt?: number | null
    readonly enabledByUserId?: string | null
    readonly platformFeature?: PlatformFeature | null
  }
}

export type EmailContactFragment = {
  readonly __typename?: 'EmailContact'
  readonly email: string
  readonly id: string
  readonly name: string
  readonly type: ContactType
  readonly entityIds?: ReadonlyArray<string | null> | null
}

export type EmailContactsForAccountQueryVariables = Exact<{
  accountId?: InputMaybe<Scalars['String']>
}>

export type EmailContactsForAccountQuery = {
  readonly __typename?: 'GqlQuery'
  readonly emailContactsForAccount: ReadonlyArray<{ readonly __typename?: 'EmailContact' } & EmailContactFragment>
}

export type ImmutableElectronicSignatureAuditLogFragment = {
  readonly __typename?: 'ImmutableElectronicSignatureAuditLog'
  readonly createdOn: number
  readonly electronicSignatureId: string
  readonly id: string
  readonly initiatedBy: string
  readonly status: ElectronicSignatureAuditLogStatus
}

export type GetElectronicSignatureAuditLogsQueryVariables = Exact<{
  electronicSignatureId: Scalars['String']
}>

export type GetElectronicSignatureAuditLogsQuery = {
  readonly __typename?: 'GqlQuery'
  readonly electronicSignatureAuditLogs: ReadonlyArray<
    | ({ readonly __typename?: 'ImmutableElectronicSignatureAuditLog' } & ImmutableElectronicSignatureAuditLogFragment)
    | null
  >
}

export type GetElectronicSignatureProviderQueryVariables = Exact<{ [key: string]: never }>

export type GetElectronicSignatureProviderQuery = {
  readonly __typename?: 'GqlQuery'
  readonly electronicSignatureProvider?: ElectronicSignatureProvider | null
}

export type EsignDetailsFragment = {
  readonly __typename?: 'EsignRequest'
  readonly id: string
  readonly accountSignatory?: ({ readonly __typename?: 'EmailContact' } & EmailContactFragment) | null
  readonly additionalRecipients?: ReadonlyArray<
    ({ readonly __typename?: 'EmailContact' } & EmailContactFragment) | null
  > | null
  readonly tenantSignatory?: ({ readonly __typename?: 'EmailContact' } & EmailContactFragment) | null
}

export type UpsertEsignDetailsMutationVariables = Exact<{
  esignRequest: InputEsignRequest
}>

export type UpsertEsignDetailsMutation = {
  readonly __typename?: 'GqlMutation'
  readonly upsertEsignDetails: { readonly __typename?: 'EsignRequest' } & EsignDetailsFragment
}

export type GetEsignDetailsQueryVariables = Exact<{
  orderId: Scalars['String']
}>

export type GetEsignDetailsQuery = {
  readonly __typename?: 'GqlQuery'
  readonly esignDetails?: ({ readonly __typename?: 'EsignRequest' } & EsignDetailsFragment) | null
}

export type GetDynamicFeatureEnabledQueryVariables = Exact<{
  feature: Feature
}>

export type GetDynamicFeatureEnabledQuery = {
  readonly __typename?: 'GqlQuery'
  readonly dynamicFeatureEnabled: boolean
}

export type CurrencyTypeSettingFragment = {
  readonly __typename?: 'CurrencyTypeSetting'
  readonly catalogCurrency?: string | null
  readonly currencyTypeSettingId?: string | null
  readonly id?: string | null
  readonly planCurrencySettingType: PlanCurrencySettingType
}

export type CurrencyConversionRateFragment = {
  readonly __typename?: 'CurrencyConversionRate'
  readonly conversionRate: number
  readonly effectiveDate: number
  readonly fromCurrency: string
  readonly id?: string | null
  readonly isOverridden?: boolean | null
  readonly roundingPrecision?: number | null
  readonly roundingTreatment: RoundingMode
  readonly toCurrency: string
  readonly updatedBy?: string | null
}

export type GetCurrencyTypeSettingQueryVariables = Exact<{ [key: string]: never }>

export type GetCurrencyTypeSettingQuery = {
  readonly __typename?: 'GqlQuery'
  readonly currencyTypeSetting: { readonly __typename?: 'CurrencyTypeSetting' } & CurrencyTypeSettingFragment
}

export type GetCurrencyConversionRatesQueryVariables = Exact<{ [key: string]: never }>

export type GetCurrencyConversionRatesQuery = {
  readonly __typename?: 'GqlQuery'
  readonly currencyConversionRates: ReadonlyArray<
    { readonly __typename?: 'CurrencyConversionRate' } & CurrencyConversionRateFragment
  >
}

export type GetCurrencyConversionRateByIdQueryVariables = Exact<{
  id: Scalars['String']
}>

export type GetCurrencyConversionRateByIdQuery = {
  readonly __typename?: 'GqlQuery'
  readonly currencyConversionRateById: {
    readonly __typename?: 'CurrencyConversionRate'
  } & CurrencyConversionRateFragment
}

export type GetCurrencyConversionRatesForGivenCurrencyPairQueryVariables = Exact<{
  fromCurrency: Scalars['String']
  toCurrency: Scalars['String']
}>

export type GetCurrencyConversionRatesForGivenCurrencyPairQuery = {
  readonly __typename?: 'GqlQuery'
  readonly currencyConversionRatesForGivenCurrencyPair: ReadonlyArray<
    { readonly __typename?: 'CurrencyConversionRate' } & CurrencyConversionRateFragment
  >
}

export type UpsertCurrencyTypeSettingMutationVariables = Exact<{
  currencyTypeSetting: InputCurrencyTypeSetting
}>

export type UpsertCurrencyTypeSettingMutation = {
  readonly __typename?: 'GqlMutation'
  readonly upsertCurrencyTypeSetting: { readonly __typename?: 'CurrencyTypeSetting' } & CurrencyTypeSettingFragment
}

export type UpsertCurrencyConversionRateMutationVariables = Exact<{
  currencyConversionRate: InputCurrencyConversionRate
}>

export type UpsertCurrencyConversionRateMutation = {
  readonly __typename?: 'GqlMutation'
  readonly upsertCurrencyConversionRate: {
    readonly __typename?: 'CurrencyConversionRate'
  } & CurrencyConversionRateFragment
}

export type GetHubSpotIntegrationQueryVariables = Exact<{ [key: string]: never }>

export type GetHubSpotIntegrationQuery = {
  readonly __typename?: 'GqlQuery'
  readonly hubSpotIntegration: {
    readonly __typename?: 'HubSpotIntegration'
    readonly hubspotId?: string | null
    readonly createdOn: number
    readonly integrationId: string
    readonly isCompleted: boolean
    readonly email?: string | null
  }
}

export type InitiateHubSpotIntegrationMutationVariables = Exact<{
  email: Scalars['String']
}>

export type InitiateHubSpotIntegrationMutation = {
  readonly __typename?: 'GqlMutation'
  readonly initiateHubSpotIntegration: string
}

export type CompleteHubSpotIntegrationMutationVariables = Exact<{
  code: Scalars['String']
  state: Scalars['String']
  redirect_uri: Scalars['String']
}>

export type CompleteHubSpotIntegrationMutation = {
  readonly __typename?: 'GqlMutation'
  readonly completeHubSpotIntegration?: {
    readonly __typename?: 'HubSpotIntegration'
    readonly hubspotId?: string | null
    readonly createdOn: number
    readonly integrationId: string
    readonly isCompleted: boolean
    readonly email?: string | null
  } | null
}

export type DeleteHubSpotIntegrationMutationVariables = Exact<{ [key: string]: never }>

export type DeleteHubSpotIntegrationMutation = {
  readonly __typename?: 'GqlMutation'
  readonly deleteHubSpotIntegration: boolean
}

export type GetAccountByHubSpotCompanyIdQueryVariables = Exact<{
  hsid: Scalars['String']
}>

export type GetAccountByHubSpotCompanyIdQuery = {
  readonly __typename?: 'GqlQuery'
  readonly accountByHubSpotCompanyId?: {
    readonly __typename?: 'Account'
    readonly id?: string | null
    readonly crmId?: string | null
    readonly name: string
    readonly description?: string | null
    readonly phoneNumber?: string | null
  } | null
}

export type SyncOrderToHubSpotMutationVariables = Exact<{
  orderId: Scalars['String']
}>

export type SyncOrderToHubSpotMutation = {
  readonly __typename?: 'GqlMutation'
  readonly syncDealInformationToHubSpot: boolean
}

export type UpdatePrimaryOrderIdForHubSpotOpportunityMutationVariables = Exact<{
  id: Scalars['String']
}>

export type UpdatePrimaryOrderIdForHubSpotOpportunityMutation = {
  readonly __typename?: 'GqlMutation'
  readonly updatePrimaryOrderIdForHubSpotOpportunity: {
    readonly __typename?: 'OrderDetail'
    readonly id?: string | null
  }
}

export type SetupHubSpotCustomPropertiesMutationVariables = Exact<{ [key: string]: never }>

export type SetupHubSpotCustomPropertiesMutation = {
  readonly __typename?: 'GqlMutation'
  readonly setupHubSpotCustomProperties: ReadonlyArray<{
    readonly __typename?: 'HubSpotSetupMessage'
    readonly level?: MessageLevel | null
    readonly message?: string | null
  }>
}

export type VerifyHubSpotSetupQueryVariables = Exact<{ [key: string]: never }>

export type VerifyHubSpotSetupQuery = {
  readonly __typename?: 'GqlQuery'
  readonly verifyHubSpotSetup: ReadonlyArray<{
    readonly __typename?: 'HubSpotSetupMessage'
    readonly level?: MessageLevel | null
    readonly message?: string | null
  }>
}

export type SetupHubSpotCustomObjectsMutationVariables = Exact<{
  privateKey: Scalars['String']
}>

export type SetupHubSpotCustomObjectsMutation = {
  readonly __typename?: 'GqlMutation'
  readonly setupHubSpotCustomObjects: ReadonlyArray<{
    readonly __typename?: 'HubSpotSetupMessage'
    readonly level?: MessageLevel | null
    readonly message?: string | null
  }>
}

export type DataImportFieldsFragment = {
  readonly __typename?: 'DataImport'
  readonly importId: string
  readonly fileName: string
  readonly fileType: ImportFileType
  readonly fileSizeBytes: number
  readonly rowCount: number
  readonly importedBy: string
  readonly importedOn: number
  readonly domain: ImportDomain
  readonly operation: ImportOperation
  readonly status: ImportStatus
  readonly completedOn?: number | null
}

export type FlatfileSpaceFieldsFragment = {
  readonly __typename?: 'FlatfileSpace'
  readonly spaceId: string
  readonly name: string
  readonly domain: ImportDomain
}

export type FlatfileSpaceResponseFieldsFragment = {
  readonly __typename?: 'FlatfileSpaceResponse'
  readonly data: {
    readonly __typename?: 'FlatfileSpaceResponseData'
    readonly id: string
    readonly guestLink: string
    readonly accessToken: string
    readonly environmentId: string
    readonly name: string
  }
}

export type GetDataImportsQueryVariables = Exact<{ [key: string]: never }>

export type GetDataImportsQuery = {
  readonly __typename?: 'GqlQuery'
  readonly dataImports: ReadonlyArray<{ readonly __typename?: 'DataImport' } & DataImportFieldsFragment>
}

export type GetDataImportQueryVariables = Exact<{
  dataImportId: Scalars['String']
}>

export type GetDataImportQuery = {
  readonly __typename?: 'GqlQuery'
  readonly dataImports: ReadonlyArray<{ readonly __typename?: 'DataImport' } & DataImportFieldsFragment>
}

export type GetFlatfileSpacesQueryVariables = Exact<{ [key: string]: never }>

export type GetFlatfileSpacesQuery = {
  readonly __typename?: 'GqlQuery'
  readonly flatfileSpaces: ReadonlyArray<{ readonly __typename?: 'FlatfileSpace' } & FlatfileSpaceFieldsFragment>
}

export type GetFlatfileSpaceQueryVariables = Exact<{
  spaceId: Scalars['String']
}>

export type GetFlatfileSpaceQuery = {
  readonly __typename?: 'GqlQuery'
  readonly flatfileSpace: { readonly __typename?: 'FlatfileSpaceResponse' } & FlatfileSpaceResponseFieldsFragment
}

export type ProcessImportMutationVariables = Exact<{
  dataImportId: Scalars['String']
}>

export type ProcessImportMutation = {
  readonly __typename?: 'GqlMutation'
  readonly processImport: { readonly __typename?: 'DataImport' } & DataImportFieldsFragment
}

export type CreateFlatfileWorkbookMutationVariables = Exact<{
  domain: ImportDomain
}>

export type CreateFlatfileWorkbookMutation = {
  readonly __typename?: 'GqlMutation'
  readonly createFlatfileWorkbook: {
    readonly __typename?: 'FlatfileSpaceResponse'
  } & FlatfileSpaceResponseFieldsFragment
}

export type RenewApiKeyForFlatfileSpaceMutationVariables = Exact<{
  spaceId: Scalars['String']
}>

export type RenewApiKeyForFlatfileSpaceMutation = {
  readonly __typename?: 'GqlMutation'
  readonly renewApiKeyForFlatfileSpace: boolean
}

export type DeleteFlatfileSpaceMutationVariables = Exact<{
  spaceId: Scalars['String']
}>

export type DeleteFlatfileSpaceMutation = { readonly __typename?: 'GqlMutation'; readonly deleteFlatfileSpace: boolean }

export type IntegrationFragment = {
  readonly __typename?: 'Integration'
  readonly id?: string | null
  readonly targetService: IntegrationTargetService
  readonly clientId: string
  readonly realmId?: string | null
  readonly status?: IntegrationStatus | null
  readonly createdOn?: string | null
}

export type GetIntegrationQueryVariables = Exact<{
  targetService: IntegrationTargetService
}>

export type GetIntegrationQuery = {
  readonly __typename?: 'GqlQuery'
  readonly integration?: ({ readonly __typename?: 'Integration' } & IntegrationFragment) | null
}

export type AddIntegrationMutationVariables = Exact<{
  integrationRequest: InputIntegrationRequest
}>

export type AddIntegrationMutation = {
  readonly __typename?: 'GqlMutation'
  readonly addIntegration: {
    readonly __typename?: 'IntegrationDetail'
    readonly id?: string | null
    readonly authUrl?: string | null
  }
}

export type CompleteIntegrationMutationVariables = Exact<{
  authCode: Scalars['String']
  state: Scalars['String']
  realmId?: InputMaybe<Scalars['String']>
}>

export type CompleteIntegrationMutation = {
  readonly __typename?: 'GqlMutation'
  readonly completeIntegration: {
    readonly __typename?: 'Integration'
    readonly id?: string | null
    readonly targetService: IntegrationTargetService
    readonly clientId: string
    readonly status?: IntegrationStatus | null
    readonly createdOn?: string | null
  }
}

export type DeleteIntegrationMutationVariables = Exact<{
  integrationId: Scalars['String']
}>

export type DeleteIntegrationMutation = {
  readonly __typename?: 'GqlMutation'
  readonly deleteIntegration: {
    readonly __typename?: 'Integration'
    readonly id?: string | null
    readonly targetService: IntegrationTargetService
    readonly clientId: string
    readonly status?: IntegrationStatus | null
    readonly createdOn?: string | null
  }
}

export type EmailNotifierFragment = {
  readonly __typename?: 'EmailNotifierContact'
  readonly email: string
  readonly firstName: string
  readonly id: string
  readonly isAccountContact: boolean
  readonly isUser: boolean
  readonly lastName?: string | null
}

export type InvoiceDetailFragment = {
  readonly __typename?: 'Invoice'
  readonly invoiceNumber: string
  readonly entityId?: string | null
  readonly subscriptionId: string
  readonly dueDate?: number | null
  readonly postedDate?: number | null
  readonly invoiceDate?: number | null
  readonly voidedDate?: number | null
  readonly status: InvoiceStatus
  readonly currency?: string | null
  readonly paymentTerm?: string | null
  readonly totalDiscount?: number | null
  readonly subTotal: number
  readonly taxTotal: number
  readonly total: number
  readonly balance?: number | null
  readonly convertedTo?: string | null
  readonly purchaseOrderNumber?: string | null
  readonly purchaseOrderRequired?: boolean | null
  readonly note?: string | null
  readonly erpId?: string | null
  readonly generatedBy?: string | null
  readonly generationMethod?: InvoiceGenerationMethod | null
  readonly account: { readonly __typename?: 'Account'; readonly id?: string | null; readonly name: string }
  readonly resellerAccount?: {
    readonly __typename?: 'Account'
    readonly id?: string | null
    readonly name: string
  } | null
  readonly billingContact?: ({ readonly __typename?: 'AccountContact' } & AccountContactFragment) | null
  readonly shippingContact?: ({ readonly __typename?: 'AccountContact' } & AccountContactFragment) | null
  readonly invoiceItems?: ReadonlyArray<{
    readonly __typename?: 'InvoiceItem'
    readonly invoiceLineNumber?: string | null
    readonly quantity?: number | null
    readonly drawdownQuantityUsed?: number | null
    readonly drawdownQuantityRemaining?: number | null
    readonly sellUnitPrice?: number | null
    readonly taxAmount: number
    readonly amount: number
    readonly periodStartDate: number
    readonly periodEndDate: number
    readonly charge: {
      readonly __typename?: 'ChargeDetail'
      readonly id: string
      readonly name: string
      readonly amount?: number | null
      readonly type: ChargeType
      readonly chargeModel: ChargeModel
    }
    readonly plan: {
      readonly __typename?: 'Plan'
      readonly name: string
      readonly id?: string | null
      readonly productId: string
    }
    readonly taxRate?: {
      readonly __typename?: 'TaxRate'
      readonly id?: string | null
      readonly name: string
      readonly taxCode: string
      readonly taxPercentage?: number | null
      readonly taxInclusive: boolean
    } | null
  } | null> | null
  readonly emailNotifiersList: {
    readonly __typename?: 'EmailNotifiersDetail'
    readonly toContacts: ReadonlyArray<
      ({ readonly __typename?: 'EmailNotifierContact' } & EmailNotifierFragment) | null
    >
    readonly bccContacts: ReadonlyArray<
      ({ readonly __typename?: 'EmailNotifierContact' } & EmailNotifierFragment) | null
    >
    readonly ccContacts: ReadonlyArray<
      ({ readonly __typename?: 'EmailNotifierContact' } & EmailNotifierFragment) | null
    >
  }
}

export type ErpInvoiceFragment = {
  readonly __typename?: 'ErpInvoice'
  readonly id: string
  readonly status: string
  readonly amountPaid: number
  readonly amountRemaining: number
  readonly subtotal: number
  readonly total: number
}

export type UpdateInvoiceMutationVariables = Exact<{
  invoiceNumber: Scalars['String']
  updateInvoice: InputUpdateInvoiceMutation
}>

export type UpdateInvoiceMutation = {
  readonly __typename?: 'GqlMutation'
  readonly updateInvoice: { readonly __typename?: 'Invoice' } & InvoiceDetailFragment
}

export type GetInvoiceQueryVariables = Exact<{
  invoiceNumber: Scalars['String']
}>

export type GetInvoiceQuery = {
  readonly __typename?: 'GqlQuery'
  readonly invoice: { readonly __typename?: 'Invoice' } & InvoiceDetailFragment
}

export type PreviewUnbilledUsageQueryVariables = Exact<{
  subscriptionId: Scalars['String']
}>

export type PreviewUnbilledUsageQuery = {
  readonly __typename?: 'GqlQuery'
  readonly previewUnbilledUsage: ReadonlyArray<{
    readonly __typename?: 'InvoiceItem'
    readonly periodStartDate: number
    readonly periodEndDate: number
    readonly quantity?: number | null
    readonly amount: number
    readonly plan: { readonly __typename?: 'Plan'; readonly id?: string | null; readonly name: string }
    readonly charge: { readonly __typename?: 'ChargeDetail'; readonly id: string; readonly name: string }
  }>
}

export type UsageForSubscriptionChargeQueryVariables = Exact<{
  subscriptionId: Scalars['String']
  chargeId: Scalars['String']
}>

export type UsageForSubscriptionChargeQuery = {
  readonly __typename?: 'GqlQuery'
  readonly usageForSubscriptionCharge: ReadonlyArray<{
    readonly __typename?: 'InvoiceItem'
    readonly periodStartDate: number
    readonly periodEndDate: number
    readonly quantity?: number | null
    readonly drawdownQuantityUsed?: number | null
    readonly drawdownQuantityRemaining?: number | null
    readonly amount: number
    readonly isBilled?: boolean | null
  }>
}

export type GetInvoiceSettlementApplicationsQueryVariables = Exact<{
  invoiceNumber: Scalars['String']
}>

export type GetInvoiceSettlementApplicationsQuery = {
  readonly __typename?: 'GqlQuery'
  readonly getInvoiceSettlementApplications: ReadonlyArray<
    { readonly __typename?: 'SettlementApplication' } & SettlementApplicationFragment
  >
}

export type GetOpenInvoicesReportOverviewQueryVariables = Exact<{ [key: string]: never }>

export type GetOpenInvoicesReportOverviewQuery = {
  readonly __typename?: 'GqlQuery'
  readonly openInvoicesReportOverview: ReadonlyArray<
    { readonly __typename?: 'BillyChartDataset' } & BillyChartDatasetFragment
  >
}

export type PostInvoiceMutationVariables = Exact<{
  invoiceNumber: Scalars['String']
}>

export type PostInvoiceMutation = {
  readonly __typename?: 'GqlMutation'
  readonly postInvoice: { readonly __typename?: 'Invoice' } & InvoiceDetailFragment
}

export type ConvertNegativeInvoiceMutationVariables = Exact<{
  invoiceNumber: Scalars['String']
}>

export type ConvertNegativeInvoiceMutation = {
  readonly __typename?: 'GqlMutation'
  readonly convertNegativeDraftInvoice: { readonly __typename?: 'CreditMemo'; readonly creditMemoNumber: string }
}

export type DeleteInvoiceMutationVariables = Exact<{
  invoiceNumber: Scalars['String']
}>

export type DeleteInvoiceMutation = {
  readonly __typename?: 'GqlMutation'
  readonly deleteInvoice: { readonly __typename?: 'Invoice' } & InvoiceDetailFragment
}

export type ApplyManualPaymentMutationVariables = Exact<{
  invoiceNumber: Scalars['String']
  amount: Scalars['BigDecimal']
  invoiceAmount: Scalars['String']
  paymentType?: InputMaybe<PaymentType>
  note?: InputMaybe<Scalars['String']>
  paymentDate?: InputMaybe<Scalars['Long']>
  paymentBankAccountId?: InputMaybe<Scalars['String']>
  bankFee?: InputMaybe<Scalars['BigDecimal']>
}>

export type ApplyManualPaymentMutation = {
  readonly __typename?: 'GqlMutation'
  readonly applyManualPayment: ReadonlyArray<{
    readonly __typename?: 'SettlementApplication'
    readonly id: string
    readonly invoiceNumber: string
    readonly createdOn: number
    readonly amount: number
    readonly applicationType: SettlementApplicationType
  }>
}

export type SendEmailMutationVariables = Exact<{
  invoiceNumber: Scalars['String']
}>

export type SendEmailMutation = {
  readonly __typename?: 'GqlMutation'
  readonly sendInvoiceEmail: { readonly __typename?: 'InvoiceEmailResponse'; readonly emailId: string }
}

export type InvoiceDunningDetailFragment = {
  readonly __typename?: 'InvoiceDunningDetail'
  readonly amount: number
  readonly emailId: string
  readonly emailSentOn: number
  readonly emailSentTo: string
  readonly id: string
  readonly invoiceNumber: string
  readonly reminderType: DunningReminderType
}

export type OrderInvoiceItemPreviewFragment = {
  readonly __typename?: 'InvoiceItemPreview'
  readonly orderLineItemId: string
  readonly chargeId: string
  readonly listUnitPrice?: number | null
  readonly sellUnitPrice?: number | null
  readonly amount: number
  readonly quantity: number
  readonly periodStartDate: number
  readonly periodEndDate: number
  readonly charge: {
    readonly __typename?: 'ChargeResolver'
    readonly name: string
    readonly type: ChargeType
    readonly billingTerm: BillingTerm
    readonly isEventBased?: boolean | null
  }
}

export type OrderInvoicePreviewFragment = {
  readonly __typename?: 'InvoicePreview'
  readonly total: number
  readonly totalDiscount: number
  readonly invoiceItems: ReadonlyArray<{ readonly __typename?: 'InvoiceItemPreview' } & OrderInvoiceItemPreviewFragment>
}

export type GetInvoiceDunningDetailsQueryVariables = Exact<{
  invoiceNumber: Scalars['String']
}>

export type GetInvoiceDunningDetailsQuery = {
  readonly __typename?: 'GqlQuery'
  readonly invoiceDunningDetails: ReadonlyArray<
    { readonly __typename?: 'InvoiceDunningDetail' } & InvoiceDunningDetailFragment
  >
}

export type VoidInvoiceMutationVariables = Exact<{
  voidInvoice: InputVoidInvoiceMutation
}>

export type VoidInvoiceMutation = {
  readonly __typename?: 'GqlMutation'
  readonly voidInvoice: { readonly __typename?: 'Invoice' } & InvoiceDetailFragment
}

export type SyncInvoiceToErpMutationVariables = Exact<{
  invoiceNumber: Scalars['String']
}>

export type SyncInvoiceToErpMutation = { readonly __typename?: 'GqlMutation'; readonly syncInvoiceToErp: string }

export type GetErpInvoiceQueryVariables = Exact<{
  invoiceErpId: Scalars['String']
}>

export type GetErpInvoiceQuery = {
  readonly __typename?: 'GqlQuery'
  readonly erpInvoice: { readonly __typename?: 'ErpInvoice' } & ErpInvoiceFragment
}

export type SyncVoidInvoiceToErpMutationVariables = Exact<{
  invoiceNumber: Scalars['String']
}>

export type SyncVoidInvoiceToErpMutation = { readonly __typename?: 'GqlMutation'; readonly voidInvoiceOnErp: string }

export type GetOrderInvoicePreviewQueryVariables = Exact<{
  orderId: Scalars['String']
}>

export type GetOrderInvoicePreviewQuery = {
  readonly __typename?: 'GqlQuery'
  readonly orderInvoicePreview: {
    readonly __typename?: 'OrderInvoicePreview'
    readonly invoicePreviews: ReadonlyArray<{ readonly __typename?: 'InvoicePreview' } & OrderInvoicePreviewFragment>
  }
}

export type GetEmailSentForInvoiceQueryVariables = Exact<{
  invoiceNumber: Scalars['String']
}>

export type GetEmailSentForInvoiceQuery = { readonly __typename?: 'GqlQuery'; readonly emailSentForInvoice: boolean }

export type MetricsFragment = {
  readonly __typename?: 'AccountMetrics'
  readonly tcv: number
  readonly nonRecurringTotal?: number | null
  readonly recurringTotal?: number | null
  readonly arr: number
  readonly entryArr?: number | null
  readonly exitArr?: number | null
  readonly averageArr?: number | null
  readonly deltaArr: number
  readonly deltaTcv: number
  readonly arrTrend: ReadonlyArray<{
    readonly __typename?: 'TimeSeriesAmount'
    readonly amount: number
    readonly instant: number
  } | null>
}

export type SimpleMetricsFragment = {
  readonly __typename?: 'AccountMetrics'
  readonly tcv: number
  readonly arr: number
  readonly exitArr?: number | null
}

export type NotificationInstanceWithAttemptsFragment = {
  readonly __typename?: 'NotificationInstanceWithAttempts'
  readonly entityId: string
  readonly eventType: string
  readonly id: string
  readonly lastDeliveryAttempt?: string | null
  readonly numberOfDeliveryAttempts: number
  readonly payload: string
  readonly status: NotificationInstanceStatus
  readonly deliveryAttempts?: ReadonlyArray<{
    readonly __typename?: 'DeliveryAttempt'
    readonly errorMessage?: string | null
    readonly isSuccess: boolean
    readonly statusCode?: number | null
  } | null> | null
}

export type NotificationFragment = {
  readonly __typename?: 'Notification'
  readonly name?: string | null
  readonly description?: string | null
  readonly notificationId?: string | null
  readonly notificationTarget?: string | null
  readonly notificationTargetType?: NotificationTargetType | null
  readonly subscribedEvents?: ReadonlyArray<NotificationEventType | null> | null
}

export type GetNotificationQueryVariables = Exact<{
  notificationId: Scalars['String']
}>

export type GetNotificationQuery = {
  readonly __typename?: 'GqlQuery'
  readonly notification: { readonly __typename?: 'Notification' } & NotificationFragment
}

export type GetNotificationsQueryVariables = Exact<{ [key: string]: never }>

export type GetNotificationsQuery = {
  readonly __typename?: 'GqlQuery'
  readonly notifications: ReadonlyArray<{ readonly __typename?: 'Notification' } & NotificationFragment>
}

export type GetNotificationInstanceQueryVariables = Exact<{
  notificationInstanceId: Scalars['String']
}>

export type GetNotificationInstanceQuery = {
  readonly __typename?: 'GqlQuery'
  readonly notificationInstance: {
    readonly __typename?: 'NotificationInstanceWithAttempts'
  } & NotificationInstanceWithAttemptsFragment
}

export type GetWebhookSigningKeyQueryVariables = Exact<{
  notificationId: Scalars['String']
}>

export type GetWebhookSigningKeyQuery = { readonly __typename?: 'GqlQuery'; readonly webhookSigningKey: string }

export type GetNotificationProcessorTypeSupportQueryVariables = Exact<{ [key: string]: never }>

export type GetNotificationProcessorTypeSupportQuery = {
  readonly __typename?: 'GqlQuery'
  readonly notificationProcessorTypeSupport: ReadonlyArray<{
    readonly __typename?: 'NotificationProcessorTypeSupport'
    readonly supportedTypes: ReadonlyArray<string | null>
    readonly targetType: string
  } | null>
}

export type UpsertTargetAndEventsSubscriptionsMutationVariables = Exact<{
  notification: InputNotification
}>

export type UpsertTargetAndEventsSubscriptionsMutation = {
  readonly __typename?: 'GqlMutation'
  readonly upsertNotificationTargetAndSubscriptions: { readonly __typename?: 'Notification' } & NotificationFragment
}

export type UnsubscribeNotificationTargetMutationVariables = Exact<{
  notificationId: Scalars['String']
}>

export type UnsubscribeNotificationTargetMutation = {
  readonly __typename?: 'GqlMutation'
  readonly unsubscribeNotificationTarget: { readonly __typename?: 'Notification' } & NotificationFragment
}

export type ResendNotificationMutationVariables = Exact<{
  notificationInstanceId: Scalars['String']
}>

export type ResendNotificationMutation = {
  readonly __typename?: 'GqlMutation'
  readonly resendNotification?:
    | ({ readonly __typename?: 'NotificationInstanceWithAttempts' } & NotificationInstanceWithAttemptsFragment)
    | null
}

export type OpportunityFragment = {
  readonly __typename?: 'Opportunity'
  readonly name?: string | null
  readonly id?: string | null
  readonly type?: string | null
  readonly stage?: string | null
  readonly crmId?: string | null
  readonly accountId?: string | null
  readonly createdOn?: string | null
  readonly updatedOn?: string | null
  readonly opportunityCrmType?: OpportunityCrmType | null
  readonly isClosed?: boolean | null
  readonly primaryOrderId?: string | null
  readonly opportunityId?: string | null
}

export type GetOrdersByCrmOpportunityIdQueryVariables = Exact<{
  crmOpportunityId: Scalars['String']
}>

export type GetOrdersByCrmOpportunityIdQuery = {
  readonly __typename?: 'GqlQuery'
  readonly ordersByCrmOpportunityId: ReadonlyArray<{
    readonly __typename?: 'Order'
    readonly id?: string | null
    readonly externalId?: string | null
    readonly name?: string | null
    readonly accountId?: string | null
    readonly currency?: string | null
    readonly shippingContactId?: string | null
    readonly billingContactId?: string | null
    readonly totalAmount?: number | null
    readonly status: OrderStatus
    readonly sfdcOpportunityId?: string | null
    readonly isPrimaryOrderForSfdcOpportunity?: boolean | null
    readonly updatedOn: number
  }>
}

export type GetCompositeOrdersByCrmOpportunityIdQueryVariables = Exact<{
  crmOpportunityId: Scalars['String']
}>

export type GetCompositeOrdersByCrmOpportunityIdQuery = {
  readonly __typename?: 'GqlQuery'
  readonly compositeOrdersByCrmOpportunityId: ReadonlyArray<{
    readonly __typename?: 'CompositeOrderMinimal'
    readonly compositeOrderId: string
    readonly crmOpportunityId?: string | null
    readonly crmOpportunityName?: string | null
    readonly crmOpportunityStage?: string | null
    readonly crmOpportunityType?: string | null
    readonly documentMasterTemplateId?: string | null
    readonly status: OrderStatus
    readonly type: CompositeOrderType
    readonly updatedOn?: string | null
    readonly isPrimaryCompositeOrderForCrmOpportunity?: boolean | null
  }>
}

export type GetOpportunityByOpportunityIdQueryVariables = Exact<{
  opportunityId: Scalars['String']
}>

export type GetOpportunityByOpportunityIdQuery = {
  readonly __typename?: 'GqlQuery'
  readonly opportunityByOpportunityId: { readonly __typename?: 'Opportunity' } & OpportunityFragment
}

export type GetOpportunityByCrmOpportunityIdQueryVariables = Exact<{
  crmOpportunityId: Scalars['String']
}>

export type GetOpportunityByCrmOpportunityIdQuery = {
  readonly __typename?: 'GqlQuery'
  readonly opportunityByCrmOpportunityId: { readonly __typename?: 'Opportunity' } & OpportunityFragment
}

export type GetOpportunitiesBySalesforceAccountIdQueryVariables = Exact<{
  sfdcAccountId: Scalars['String']
}>

export type GetOpportunitiesBySalesforceAccountIdQuery = {
  readonly __typename?: 'GqlQuery'
  readonly opportunitiesBySalesforceAccountId?: ReadonlyArray<
    { readonly __typename?: 'Opportunity' } & OpportunityFragment
  > | null
}

export type GetOpportunitiesByHubSpotCompanyIdQueryVariables = Exact<{
  companyId: Scalars['String']
}>

export type GetOpportunitiesByHubSpotCompanyIdQuery = {
  readonly __typename?: 'GqlQuery'
  readonly opportunitiesByHubSpotCompanyId?: ReadonlyArray<
    { readonly __typename?: 'Opportunity' } & OpportunityFragment
  > | null
}

export type GetOpportunitiesByAccountCrmIdQueryVariables = Exact<{
  accountCrmId: Scalars['String']
}>

export type GetOpportunitiesByAccountCrmIdQuery = {
  readonly __typename?: 'GqlQuery'
  readonly opportunitiesByAccountCrmId?: ReadonlyArray<
    { readonly __typename?: 'Opportunity' } & OpportunityFragment
  > | null
}

export type UpdatePrimaryCompositeOrderIdForCrmOpportunityMutationVariables = Exact<{
  compositeOrderId: Scalars['String']
}>

export type UpdatePrimaryCompositeOrderIdForCrmOpportunityMutation = {
  readonly __typename?: 'GqlMutation'
  readonly updatePrimaryCompositeOrderIdForCrmOpportunity: {
    readonly __typename?: 'CompositeOrder'
  } & CompositeOrderDetailFragment
}

export type ResetOpportunityClosedStateMutationVariables = Exact<{
  opportunityId: Scalars['String']
}>

export type ResetOpportunityClosedStateMutation = {
  readonly __typename?: 'GqlMutation'
  readonly resetOpportunityClosedState: { readonly __typename?: 'Opportunity' } & OpportunityFragment
}

export type AccountContactFragment = {
  readonly __typename?: 'AccountContact'
  readonly accountId?: string | null
  readonly email: string
  readonly externalId?: string | null
  readonly firstName: string
  readonly id?: string | null
  readonly lastName?: string | null
  readonly phoneNumber?: string | null
  readonly title?: string | null
  readonly address?: {
    readonly __typename?: 'AccountAddress'
    readonly streetAddressLine1: string
    readonly streetAddressLine2?: string | null
    readonly city: string
    readonly state?: string | null
    readonly zipcode: string
    readonly country: string
  } | null
}

export type RenewalSubscriptionFragment = { readonly __typename?: 'Subscription'; readonly id: string }

export type PlanFragment = {
  readonly __typename?: 'Plan'
  readonly id?: string | null
  readonly name: string
  readonly description?: string | null
  readonly status: PlanStatus
  readonly currency?: string | null
  readonly templateIds?: ReadonlyArray<string | null> | null
  readonly entityIds?: ReadonlyArray<string> | null
  readonly charges: ReadonlyArray<{
    readonly __typename?: 'Charge'
    readonly id?: string | null
    readonly name: string
    readonly displayName?: string | null
    readonly description?: string | null
    readonly type: ChargeType
    readonly chargeModel: ChargeModel
    readonly amount?: number | null
    readonly percent?: number | null
    readonly percentDerivedFrom?: PercentDerivedFrom | null
    readonly isDrawdown?: boolean | null
    readonly isRenewable: boolean
    readonly isCustom?: boolean | null
    readonly isListPriceEditable?: boolean | null
    readonly durationInMonths?: number | null
    readonly minQuantity?: number | null
    readonly defaultQuantity?: number | null
    readonly maxQuantity?: number | null
    readonly billingTerm?: BillingTerm | null
    readonly rateCardId?: string | null
    readonly priceTiers?: ReadonlyArray<{
      readonly __typename?: 'priceTier'
      readonly amount: number
      readonly untilQuantity: string
    }> | null
    readonly recurrence?: { readonly __typename?: 'Recurrence'; readonly cycle: Cycle; readonly step: number } | null
  }>
}

export type AccountFragment = {
  readonly __typename?: 'Account'
  readonly crmId?: string | null
  readonly id?: string | null
  readonly name: string
  readonly currency?: string | null
  readonly hasAutomaticPayment?: boolean | null
}

export type AccountDetailFragment = {
  readonly __typename?: 'AccountDetail'
  readonly crmId?: string | null
  readonly id: string
  readonly name: string
  readonly currency?: string | null
  readonly hasAutomaticPayment?: boolean | null
  readonly address?: ({ readonly __typename?: 'AccountAddress' } & AccountAddressFragment) | null
}

export type ApprovalSegmentFragment = {
  readonly __typename?: 'ApprovalSegment'
  readonly id?: string | null
  readonly description?: string | null
  readonly name: string
}

export type DocumentTemplateFragment = {
  readonly __typename?: 'DocumentTemplate'
  readonly status: DocumentTemplateStatus
  readonly content: string
  readonly description?: string | null
  readonly id: string
  readonly inUse?: boolean | null
  readonly isUserSelectable?: boolean | null
  readonly name: string
  readonly sectionUuid?: string | null
  readonly type: DocumentTemplateType
  readonly updatedOn?: number | null
  readonly entityIds?: ReadonlyArray<string> | null
  readonly plans?: ReadonlyArray<{
    readonly __typename?: 'Plan'
    readonly id?: string | null
    readonly name: string
  } | null> | null
}

export type OrderDocumentTemplateFragment = {
  readonly __typename?: 'OrderDocumentTemplate'
  readonly status: DocumentTemplateStatus
  readonly content: string
  readonly description?: string | null
  readonly id: string
  readonly inUse?: boolean | null
  readonly name: string
  readonly sectionUuid?: string | null
  readonly type: DocumentTemplateType
  readonly updatedOn?: number | null
  readonly isModified: boolean
  readonly plans?: ReadonlyArray<{
    readonly __typename?: 'Plan'
    readonly id?: string | null
    readonly name: string
  } | null> | null
}

export type OrderTermsFragment = {
  readonly __typename?: 'OrderTerms'
  readonly id?: string | null
  readonly levelType: OrderTermsLevelType
  readonly orderId: string
  readonly planIds: ReadonlyArray<string | null>
  readonly templateId: string
}

export type ChargeDetailFragment = {
  readonly __typename?: 'ChargeDetail'
  readonly id: string
  readonly name: string
  readonly displayName?: string | null
  readonly description?: string | null
  readonly type: ChargeType
  readonly chargeModel: ChargeModel
  readonly amount?: number | null
  readonly percent?: number | null
  readonly percentDerivedFrom?: PercentDerivedFrom | null
  readonly targetPlanIds?: ReadonlyArray<string> | null
  readonly isDrawdown?: boolean | null
  readonly overageBaseChargeId?: string | null
  readonly minimumCommitBaseChargeId?: string | null
  readonly isRenewable: boolean
  readonly isCustom?: boolean | null
  readonly isListPriceEditable?: boolean | null
  readonly isEventBased?: boolean | null
  readonly minQuantity?: number | null
  readonly defaultQuantity?: number | null
  readonly maxQuantity?: number | null
  readonly externalId?: string | null
  readonly erpId?: string | null
  readonly itemCode?: string | null
  readonly minAmount?: number | null
  readonly maxAmount?: number | null
  readonly rateCardId?: string | null
  readonly billingTerm: BillingTerm
  readonly billingCycle?: BillingCycle | null
  readonly shouldTrackArr?: boolean | null
  readonly durationInMonths?: number | null
  readonly taxRate?: {
    readonly __typename?: 'TaxRate'
    readonly id?: string | null
    readonly name: string
    readonly description?: string | null
  } | null
  readonly priceTiers: ReadonlyArray<{
    readonly __typename?: 'priceTier'
    readonly amount: number
    readonly untilQuantity: string
  }>
  readonly recurrence?: { readonly __typename?: 'Recurrence'; readonly cycle: Cycle; readonly step: number } | null
  readonly unitOfMeasure?: { readonly __typename?: 'UnitOfMeasure'; readonly id: string; readonly name: string } | null
  readonly ledgerAccountMapping?: {
    readonly __typename?: 'LedgerAccountMapping'
    readonly contractAssetAccountId?: string | null
    readonly deferredRevenueAccountId?: string | null
    readonly recognizedRevenueAccountId?: string | null
    readonly taxLiabilityAccountId?: string | null
  } | null
  readonly recognitionRule?: ({ readonly __typename?: 'RecognitionRule' } & RecognitionRuleFragment) | null
}

export type ChargeFragment = {
  readonly __typename?: 'Charge'
  readonly id?: string | null
  readonly name: string
  readonly displayName?: string | null
  readonly description?: string | null
  readonly type: ChargeType
  readonly chargeModel: ChargeModel
  readonly amount?: number | null
  readonly percent?: number | null
  readonly percentDerivedFrom?: PercentDerivedFrom | null
  readonly isDrawdown?: boolean | null
  readonly isRenewable: boolean
  readonly isCustom?: boolean | null
  readonly isEventBased?: boolean | null
  readonly isListPriceEditable?: boolean | null
  readonly durationInMonths?: number | null
  readonly minQuantity?: number | null
  readonly defaultQuantity?: number | null
  readonly maxQuantity?: number | null
  readonly externalId?: string | null
  readonly erpId?: string | null
  readonly itemCode?: string | null
  readonly minimumCommitBaseChargeId?: string | null
  readonly shouldTrackArr?: boolean | null
  readonly priceTiers?: ReadonlyArray<{
    readonly __typename?: 'priceTier'
    readonly amount: number
    readonly untilQuantity: string
  }> | null
  readonly recurrence?: { readonly __typename?: 'Recurrence'; readonly cycle: Cycle; readonly step: number } | null
  readonly ledgerAccountMapping?: {
    readonly __typename?: 'LedgerAccountMapping'
    readonly contractAssetAccountId?: string | null
    readonly deferredRevenueAccountId?: string | null
    readonly recognizedRevenueAccountId?: string | null
    readonly taxLiabilityAccountId?: string | null
  } | null
}

export type LineItemFragment = {
  readonly __typename?: 'OrderLineItemDetail'
  readonly action: ActionType
  readonly amount?: number | null
  readonly id?: string | null
  readonly isRamp: boolean
  readonly isDryRunItem?: boolean | null
  readonly itemGroupId?: string | null
  readonly discountAmount?: number | null
  readonly effectiveDate?: number | null
  readonly endDate?: number | null
  readonly quantity?: number | null
  readonly listUnitPrice?: number | null
  readonly listAmount?: number | null
  readonly sellUnitPrice?: number | null
  readonly annualizedAmount?: number | null
  readonly listPriceOverrideRatio?: number | null
  readonly listUnitPriceBeforeOverride?: number | null
  readonly subscriptionChargeId?: string | null
  readonly subscriptionChargeGroupId?: string | null
  readonly arrOverride?: number | null
  readonly discounts?: ReadonlyArray<{
    readonly __typename?: 'DiscountDetailJson'
    readonly discountAmount?: number | null
    readonly name?: string | null
    readonly percent?: number | null
  } | null> | null
  readonly charge: {
    readonly __typename?: 'Charge'
    readonly amount?: number | null
    readonly chargeModel: ChargeModel
    readonly defaultQuantity?: number | null
    readonly id?: string | null
    readonly isCustom?: boolean | null
    readonly isListPriceEditable?: boolean | null
    readonly durationInMonths?: number | null
    readonly maxQuantity?: number | null
    readonly minQuantity?: number | null
    readonly name: string
    readonly type: ChargeType
    readonly rateCardId?: string | null
    readonly recognitionRuleId?: string | null
    readonly unitOfMeasureId?: string | null
    readonly minimumCommitBaseChargeId?: string | null
    readonly shouldTrackArr?: boolean | null
    readonly priceTiers?: ReadonlyArray<{
      readonly __typename?: 'priceTier'
      readonly amount: number
      readonly untilQuantity: string
    }> | null
  }
  readonly chargeDetail: {
    readonly __typename?: 'ChargeDetail'
    readonly id: string
    readonly recognitionRule?: {
      readonly __typename?: 'RecognitionRule'
      readonly id?: string | null
      readonly recognitionType: RecognitionType
    } | null
  }
  readonly customFields?: ReadonlyArray<
    ({ readonly __typename?: 'CustomFieldEntry' } & CustomFieldEntryFragment) | null
  > | null
  readonly predefinedDiscounts?: ReadonlyArray<{
    readonly __typename?: 'PredefinedDiscountLineItemDetail'
    readonly description?: string | null
    readonly id: string
    readonly name?: string | null
    readonly percent: number
    readonly type: string
    readonly amount: number
    readonly status?: DiscountStatus | null
  } | null> | null
  readonly plan?: {
    readonly __typename?: 'Plan'
    readonly id?: string | null
    readonly currency?: string | null
    readonly name: string
    readonly templateIds?: ReadonlyArray<string | null> | null
    readonly status: PlanStatus
  } | null
  readonly attributeReferences?: ReadonlyArray<{
    readonly __typename?: 'AttributeReference'
    readonly attributeDefinitionId: string
    readonly attributeValue: string
  } | null> | null
  readonly metrics?: {
    readonly __typename?: 'LineItemMetrics'
    readonly arr: number
    readonly arrWithoutOverride: number
  } | null
}

export type DiscountFragment = {
  readonly __typename?: 'PredefinedDiscountDetail'
  readonly description?: string | null
  readonly id: string
  readonly name?: string | null
  readonly percent: number
  readonly type: string
  readonly status?: DiscountStatus | null
}

export type ApprovalFlowInstanceGroupFragment = {
  readonly __typename?: 'ApprovalFlowInstanceGroupDetail'
  readonly id: string
  readonly approvalStatus: ApprovalFlowInstanceStatus
  readonly workflowStatus: ApprovalFlowInstanceWorkflowStatus
  readonly version: number
  readonly createdOn: number
  readonly updatedOn: number
  readonly approvalFlowInstances: ReadonlyArray<{
    readonly __typename?: 'ApprovalFlowInstanceDetail'
    readonly id: string
    readonly activeStateId?: string | null
    readonly approvalFlowId: string
    readonly status: ApprovalFlowInstanceStatus
    readonly data: {
      readonly __typename?: 'ApprovalFlowInstanceDataDetail'
      readonly id: string
      readonly name: string
      readonly description?: string | null
      readonly status: ApprovalFlowInstanceStatus
      readonly states: ReadonlyArray<{
        readonly __typename?: 'ApprovalFlowInstanceStateDetail'
        readonly id: string
        readonly status: ApprovalFlowInstanceStatus
        readonly name: string
        readonly approvedBy: ReadonlyArray<{
          readonly __typename?: 'UserActionTimeJson'
          readonly time: number
          readonly note?: string | null
          readonly user: {
            readonly __typename?: 'User'
            readonly displayName: string
            readonly email: string
            readonly id: string
            readonly phoneNumber?: string | null
            readonly role: Role
            readonly ssoOnly?: boolean | null
            readonly state: Status
            readonly title?: string | null
          }
        } | null>
        readonly approvalGroup: {
          readonly __typename?: 'UserGroupDetail'
          readonly id: string
          readonly isUser: boolean
          readonly name: string
          readonly users: ReadonlyArray<({ readonly __typename?: 'User' } & UserFragment) | null>
        }
        readonly action: { readonly __typename?: 'ApprovalStateAction'; readonly emailGroupId: string }
        readonly rejectedBy: ReadonlyArray<{
          readonly __typename?: 'UserActionTimeJson'
          readonly time: number
          readonly note?: string | null
          readonly user: { readonly __typename?: 'User'; readonly id: string; readonly displayName: string }
        } | null>
      }>
    }
  }>
  readonly submitterNote?: {
    readonly __typename?: 'ApprovalFlowSubmitterNote'
    readonly id?: string | null
    readonly note: string
    readonly orderId: string
  } | null
}

export type BillingCycleFragment = { readonly __typename?: 'Recurrence'; readonly cycle: Cycle; readonly step: number }

export type OrderLevelCustomFieldEntryFragment = {
  readonly __typename?: 'CustomFieldEntry'
  readonly id: string
  readonly name: string
  readonly label?: string | null
  readonly type?: CustomFieldType | null
  readonly value?: string | null
  readonly source?: CustomFieldSource | null
  readonly options?: ReadonlyArray<string | null> | null
  readonly required?: boolean | null
  readonly selections?: ReadonlyArray<string | null> | null
  readonly defaultValue?: {
    readonly __typename?: 'CustomFieldDefault'
    readonly value?: string | null
    readonly selections?: ReadonlyArray<string> | null
  } | null
}

export type OrderDetailFragment = {
  readonly __typename?: 'OrderDetail'
  readonly billingAnchorDate?: number | null
  readonly currency?: string | null
  readonly endDate?: number | null
  readonly id?: string | null
  readonly entityId?: string | null
  readonly isPrimaryOrderForSfdcOpportunity?: boolean | null
  readonly name?: string | null
  readonly orderType: OrderType
  readonly paymentTerm: string
  readonly rampInterval?: ReadonlyArray<number | null> | null
  readonly sfdcOrderCanBeExecuted?: boolean | null
  readonly sfdcOpportunityId?: string | null
  readonly sfdcOpportunityName?: string | null
  readonly sfdcOpportunityStage?: string | null
  readonly sfdcOpportunityType?: string | null
  readonly startDate: number
  readonly status: OrderStatus
  readonly subscriptionId?: string | null
  readonly totalAmount?: number | null
  readonly totalListAmount?: number | null
  readonly totalListAmountBeforeOverride?: number | null
  readonly purchaseOrderNumber?: string | null
  readonly purchaseOrderRequiredForInvoicing?: boolean | null
  readonly autoRenew?: boolean | null
  readonly externalId?: string | null
  readonly attachmentId?: string | null
  readonly compositeOrderId?: string | null
  readonly expiresOn?: number | null
  readonly executedOn?: number | null
  readonly recurringDiscountPercent?: number | null
  readonly nonRecurringDiscountPercent?: number | null
  readonly startDateType?: OrderStartDateType | null
  readonly account: { readonly __typename?: 'Account' } & AccountFragment
  readonly accountDetail: { readonly __typename?: 'AccountDetail' } & AccountDetailFragment
  readonly createdBy?: { readonly __typename?: 'User'; readonly displayName: string; readonly state: Status } | null
  readonly customFields?: ReadonlyArray<
    ({ readonly __typename?: 'CustomFieldEntry' } & OrderLevelCustomFieldEntryFragment) | null
  > | null
  readonly resoldBy?: ({ readonly __typename?: 'Account' } & AccountFragment) | null
  readonly billingContact?: ({ readonly __typename?: 'AccountContact' } & AccountContactFragment) | null
  readonly billingCycle: { readonly __typename?: 'Recurrence' } & BillingCycleFragment
  readonly currentSubscription?: ({ readonly __typename?: 'Subscription' } & AmendmentSubscriptionFragment) | null
  readonly predefinedDiscounts?: ReadonlyArray<
    ({ readonly __typename?: 'PredefinedDiscountDetail' } & DiscountFragment) | null
  > | null
  readonly lineItems: ReadonlyArray<{ readonly __typename?: 'OrderLineItemDetail' } & LineItemFragment>
  readonly orderTerms?: ReadonlyArray<({ readonly __typename?: 'OrderTerms' } & OrderTermsFragment) | null> | null
  readonly orderFormTemplates: ReadonlyArray<{ readonly __typename?: 'DocumentTemplate' } & DocumentTemplateFragment>
  readonly orderDocumentTemplates?: ReadonlyArray<
    { readonly __typename?: 'OrderDocumentTemplate' } & OrderDocumentTemplateFragment
  > | null
  readonly owner?: {
    readonly __typename?: 'User'
    readonly id: string
    readonly displayName: string
    readonly state: Status
  } | null
  readonly shippingContact?: ({ readonly __typename?: 'AccountContact' } & AccountContactFragment) | null
  readonly termLength?: { readonly __typename?: 'Recurrence'; readonly cycle: Cycle; readonly step: number } | null
  readonly renewalForSubscription?: ({ readonly __typename?: 'Subscription' } & RenewalSubscriptionFragment) | null
  readonly approvalFlows: ReadonlyArray<
    { readonly __typename?: 'ApprovalFlowInstanceGroupDetail' } & ApprovalFlowInstanceGroupFragment
  >
  readonly approvalSegment?: ({ readonly __typename?: 'ApprovalSegment' } & ApprovalSegmentFragment) | null
  readonly documentMasterTemplate?: {
    readonly __typename?: 'DocumentMasterTemplate'
    readonly id?: string | null
    readonly name: string
    readonly isDefault?: boolean | null
  } | null
  readonly orderMetrics?: ({ readonly __typename?: 'AccountMetrics' } & MetricsFragment) | null
  readonly documentCustomContent?: {
    readonly __typename?: 'DocumentCustomContent'
    readonly id?: string | null
    readonly orderId: string
    readonly title?: string | null
    readonly content: string
  } | null
  readonly submittedBy?: { readonly __typename?: 'User'; readonly id: string; readonly displayName: string } | null
}

export type ListPlansQueryVariables = Exact<{ [key: string]: never }>

export type ListPlansQuery = {
  readonly __typename?: 'GqlQuery'
  readonly plans: ReadonlyArray<{ readonly __typename?: 'Plan' } & PlanFragment>
}

export type ListAccountsQueryVariables = Exact<{ [key: string]: never }>

export type ListAccountsQuery = {
  readonly __typename?: 'GqlQuery'
  readonly accounts: ReadonlyArray<{ readonly __typename?: 'Account' } & AccountFragment>
}

export type ApprovalSegmentsQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']>
}>

export type ApprovalSegmentsQuery = {
  readonly __typename?: 'GqlQuery'
  readonly approvalSegments: ReadonlyArray<{ readonly __typename?: 'ApprovalSegment' } & ApprovalSegmentFragment>
}

export type ListResellerAccountsQueryVariables = Exact<{ [key: string]: never }>

export type ListResellerAccountsQuery = {
  readonly __typename?: 'GqlQuery'
  readonly accounts: ReadonlyArray<{ readonly __typename?: 'Account' } & AccountFragment>
}

export type ListAccountContactsQueryVariables = Exact<{
  accountId: Scalars['String']
}>

export type ListAccountContactsQuery = {
  readonly __typename?: 'GqlQuery'
  readonly accountContacts: ReadonlyArray<{ readonly __typename?: 'AccountContact' } & AccountContactFragment>
}

export type DiscountJsonFragment = {
  readonly __typename?: 'DiscountJson'
  readonly description?: string | null
  readonly id?: string | null
  readonly name: string
  readonly percent: number
  readonly type: string
  readonly status?: DiscountStatus | null
  readonly inUse?: boolean | null
  readonly entityIds?: ReadonlyArray<string> | null
}

export type ListDiscountsQueryVariables = Exact<{ [key: string]: never }>

export type ListDiscountsQuery = {
  readonly __typename?: 'GqlQuery'
  readonly discounts: ReadonlyArray<{ readonly __typename?: 'DiscountJson' } & DiscountJsonFragment>
}

export type GetOrdersOverviewByDateQueryVariables = Exact<{
  from: Scalars['Long']
  to: Scalars['Long']
}>

export type GetOrdersOverviewByDateQuery = {
  readonly __typename?: 'GqlQuery'
  readonly ordersOverviewByDate: ReadonlyArray<
    { readonly __typename?: 'BillyChartDataset' } & BillyChartDatasetFragment
  >
}

export type GetOrderDetailQueryVariables = Exact<{
  id: Scalars['String']
}>

export type GetOrderDetailQuery = {
  readonly __typename?: 'GqlQuery'
  readonly orderDetail: { readonly __typename?: 'OrderDetail' } & OrderDetailFragment
}

export type UpdateOrderStatusMutationVariables = Exact<{
  id: Scalars['String']
  status: Scalars['String']
  statusUpdatedOn?: InputMaybe<Scalars['Long']>
}>

export type UpdateOrderStatusMutation = {
  readonly __typename?: 'GqlMutation'
  readonly updateOrderStatus: {
    readonly __typename?: 'OrderStatusUpdateResponse'
    readonly id?: string | null
    readonly status?: OrderStatus | null
    readonly orderType?: OrderType | null
  }
}

export type DeleteOrderMutationVariables = Exact<{
  id: Scalars['String']
}>

export type DeleteOrderMutation = {
  readonly __typename?: 'GqlMutation'
  readonly deleteOrder: { readonly __typename?: 'OrderDetail' } & OrderDetailFragment
}

export type DeleteUpsellWithEarlyRenewalMutationVariables = Exact<{
  id: Scalars['String']
}>

export type DeleteUpsellWithEarlyRenewalMutation = {
  readonly __typename?: 'GqlMutation'
  readonly deleteUpsellWithEarlyRenewal: { readonly __typename?: 'CompositeOrder' } & CompositeOrderDetailFragment
}

export type UpsertOrderMutationVariables = Exact<{
  order: InputOrderRequest
}>

export type UpsertOrderMutation = {
  readonly __typename?: 'GqlMutation'
  readonly upsertOrder: { readonly __typename?: 'OrderDetail' } & OrderDetailFragment
}

export type AmendmentSubscriptionFragment = {
  readonly __typename?: 'Subscription'
  readonly id: string
  readonly startDate: number
  readonly endDate: number
  readonly account: { readonly __typename?: 'Account'; readonly id?: string | null; readonly name: string }
  readonly charges: ReadonlyArray<{
    readonly __typename?: 'SubscriptionCharge'
    readonly id: string
    readonly quantity: number
  } | null>
}

export type GenerateAmendmentQueryVariables = Exact<{
  subscriptionId: Scalars['String']
}>

export type GenerateAmendmentQuery = {
  readonly __typename?: 'GqlQuery'
  readonly generateAmendment: { readonly __typename?: 'OrderDetail' } & OrderDetailFragment
}

export type GenerateRenewalOrderQueryVariables = Exact<{
  subscriptionId: Scalars['String']
}>

export type GenerateRenewalOrderQuery = {
  readonly __typename?: 'GqlQuery'
  readonly generateRenewalOrder: { readonly __typename?: 'OrderDetail' } & OrderDetailFragment
}

export type DuplicateOrderQueryVariables = Exact<{
  orderId: Scalars['String']
}>

export type DuplicateOrderQuery = {
  readonly __typename?: 'GqlQuery'
  readonly duplicateOrder: { readonly __typename?: 'OrderDetail' } & OrderDetailFragment
}

export type GetPaymentTermSettingsQueryVariables = Exact<{ [key: string]: never }>

export type GetPaymentTermSettingsQuery = {
  readonly __typename?: 'GqlQuery'
  readonly paymentTermSettings: {
    readonly __typename?: 'PaymentTermSettings'
    readonly customPaymentTermsAllowed: boolean
    readonly defaultPaymentTerms: ReadonlyArray<string>
    readonly defaultPaymentTerm: string
  }
}

export type UpsertAmendmentMutationVariables = Exact<{
  order: InputAmendmentOrderRequest
}>

export type UpsertAmendmentMutation = {
  readonly __typename?: 'GqlMutation'
  readonly upsertAmendment: { readonly __typename?: 'OrderDetail' } & OrderDetailFragment
}

export type DryRunAmendmentMutationVariables = Exact<{
  order: InputAmendmentOrderRequest
}>

export type DryRunAmendmentMutation = {
  readonly __typename?: 'GqlMutation'
  readonly upsertAmendment: { readonly __typename?: 'OrderDetail' } & OrderDetailFragment
}

export type GenerateCancelOrderQueryVariables = Exact<{
  subscriptionId: Scalars['String']
}>

export type GenerateCancelOrderQuery = {
  readonly __typename?: 'GqlQuery'
  readonly generateCancelOrder: { readonly __typename?: 'OrderDetail' } & OrderDetailFragment
}

export type IsAmendmentCurrentQueryVariables = Exact<{
  id: Scalars['String']
}>

export type IsAmendmentCurrentQuery = { readonly __typename?: 'GqlQuery'; readonly isAmendmentCurrent: boolean }

export type DryRunCancellationMutationVariables = Exact<{
  order: InputAmendmentOrderRequest
}>

export type DryRunCancellationMutation = {
  readonly __typename?: 'GqlMutation'
  readonly upsertAmendment: { readonly __typename?: 'OrderDetail' } & OrderDetailFragment
}

export type UpsertCancellationMutationVariables = Exact<{
  order: InputAmendmentOrderRequest
}>

export type UpsertCancellationMutation = {
  readonly __typename?: 'GqlMutation'
  readonly upsertAmendment: { readonly __typename?: 'OrderDetail' } & OrderDetailFragment
}

export type DryRunOrderMutationVariables = Exact<{
  order: InputOrderRequest
}>

export type DryRunOrderMutation = {
  readonly __typename?: 'GqlMutation'
  readonly upsertOrder: { readonly __typename?: 'OrderDetail' } & OrderDetailFragment
}

export type UpsertRenewalOrderMutationVariables = Exact<{
  order: InputOrderRequest
}>

export type UpsertRenewalOrderMutation = {
  readonly __typename?: 'GqlMutation'
  readonly upsertRenewalOrder: { readonly __typename?: 'OrderDetail' } & OrderDetailFragment
}

export type DryRunRenewalOrderMutationVariables = Exact<{
  order: InputOrderRequest
}>

export type DryRunRenewalOrderMutation = {
  readonly __typename?: 'GqlMutation'
  readonly upsertRenewalOrder: { readonly __typename?: 'OrderDetail' } & OrderDetailFragment
}

export type SyncOrderToSalesforceMutationVariables = Exact<{
  orderId: Scalars['String']
}>

export type SyncOrderToSalesforceMutation = {
  readonly __typename?: 'GqlMutation'
  readonly syncOrderToSalesforce: { readonly __typename?: 'OrderDetail' } & OrderDetailFragment
}

export type CreateOrderDocumentMutationVariables = Exact<{
  orderId: Scalars['String']
}>

export type CreateOrderDocumentMutation = { readonly __typename?: 'GqlMutation'; readonly createOrderDocument: boolean }

export type UpsertCustomPredefinedTemplateOnOrderMutationVariables = Exact<{
  documentTemplate: InputCustomPredefinedTemplateOnOrder
}>

export type UpsertCustomPredefinedTemplateOnOrderMutation = {
  readonly __typename?: 'GqlMutation'
  readonly upsertCustomPredefinedTemplateOnOrder: {
    readonly __typename?: 'CustomPredefinedTemplateOnOrder'
    readonly content: string
    readonly description?: string | null
    readonly id?: string | null
    readonly name: string
    readonly orderId: string
  }
}

export type DeleteCustomPredefinedTemplateOnOrderMutationVariables = Exact<{
  orderId: Scalars['String']
  templateId: Scalars['String']
}>

export type DeleteCustomPredefinedTemplateOnOrderMutation = {
  readonly __typename?: 'GqlMutation'
  readonly deleteCustomPredefinedTemplateOnOrder: {
    readonly __typename?: 'CustomPredefinedTemplateOnOrder'
    readonly content: string
    readonly description?: string | null
    readonly id?: string | null
    readonly name: string
    readonly orderId: string
  }
}

export type RebaseAmendmentMutationVariables = Exact<{
  id: Scalars['String']
}>

export type RebaseAmendmentMutation = {
  readonly __typename?: 'GqlMutation'
  readonly rebaseAmendment: { readonly __typename?: 'OrderDetail'; readonly id?: string | null }
}

export type StripeConnectIntegrationFragment = {
  readonly __typename?: 'PaymentStripeConnectIntegration'
  readonly connectAccountId?: string | null
  readonly isCompleted: boolean
}

export type GetStripeConnectIntegrationQueryVariables = Exact<{ [key: string]: never }>

export type GetStripeConnectIntegrationQuery = {
  readonly __typename?: 'GqlQuery'
  readonly stripeConnectIntegration?:
    | ({ readonly __typename?: 'PaymentStripeConnectIntegration' } & StripeConnectIntegrationFragment)
    | null
}

export type InitiateStripeConnectIntegrationMutationVariables = Exact<{ [key: string]: never }>

export type InitiateStripeConnectIntegrationMutation = {
  readonly __typename?: 'GqlMutation'
  readonly initiateStripeConnectIntegration: {
    readonly __typename?: 'PaymentStripeConnectIntegrationSetupResponse'
    readonly accountLinkUrl: string
  }
}

export type CompleteStripeConnectIntegrationMutationVariables = Exact<{
  code: Scalars['String']
  scope: Scalars['String']
  state: Scalars['String']
}>

export type CompleteStripeConnectIntegrationMutation = {
  readonly __typename?: 'GqlMutation'
  readonly completeStripeConnectIntegration: boolean
}

export type GeneratePaymentLinkMutationVariables = Exact<{
  accountId: Scalars['String']
  actionType: AddPaymentMethodActionType
}>

export type GeneratePaymentLinkMutation = { readonly __typename?: 'GqlMutation'; readonly generatePaymentLink: string }

export type GetFailedPaymentsForInvoiceQueryVariables = Exact<{
  invoiceNumber: Scalars['String']
}>

export type GetFailedPaymentsForInvoiceQuery = {
  readonly __typename?: 'GqlQuery'
  readonly failedPaymentsForInvoice: ReadonlyArray<{
    readonly __typename?: 'FailedPayment'
    readonly amount: number
    readonly createdOn: number
    readonly note: string
    readonly transactionId?: string | null
    readonly paymentState: string
    readonly invoiceNumber: string
  }>
}

export type DeleteStripeConnectIntegrationMutationVariables = Exact<{ [key: string]: never }>

export type DeleteStripeConnectIntegrationMutation = {
  readonly __typename?: 'GqlMutation'
  readonly deleteStripeConnectIntegration: {
    readonly __typename?: 'PaymentStripeConnectIntegration'
    readonly isDeleted: boolean
  }
}

export type GetPaymentIntegrationEnabledQueryVariables = Exact<{ [key: string]: never }>

export type GetPaymentIntegrationEnabledQuery = {
  readonly __typename?: 'GqlQuery'
  readonly paymentIntegrationEnabled: boolean
}

export type GetPaymentMethodsQueryVariables = Exact<{
  accountId: Scalars['String']
}>

export type GetPaymentMethodsQuery = {
  readonly __typename?: 'GqlQuery'
  readonly paymentMethods: ReadonlyArray<{ readonly __typename?: 'PaymentMethodDetail' } & PaymentMethodDetailFragment>
}

export type GetPaymentConfigurationQueryVariables = Exact<{ [key: string]: never }>

export type GetPaymentConfigurationQuery = {
  readonly __typename?: 'GqlQuery'
  readonly paymentConfiguration: {
    readonly __typename?: 'PaymentConfiguration'
    readonly supportedPaymentTypes: ReadonlyArray<PaymentType>
  }
}

export type GetSupportedPaymentTypesQueryVariables = Exact<{ [key: string]: never }>

export type GetSupportedPaymentTypesQuery = {
  readonly __typename?: 'GqlQuery'
  readonly supportedPaymentTypes: ReadonlyArray<PaymentType | null>
}

export type UpdatePaymentConfigurationMutationVariables = Exact<{
  paymentTypes: ReadonlyArray<PaymentType> | PaymentType
}>

export type UpdatePaymentConfigurationMutation = {
  readonly __typename?: 'GqlMutation'
  readonly updatePaymentConfiguration: {
    readonly __typename?: 'PaymentConfiguration'
    readonly supportedPaymentTypes: ReadonlyArray<PaymentType>
  }
}

export type UpdateAutomaticPaymentConfigurationMutationVariables = Exact<{
  paymentTypes: ReadonlyArray<PaymentType> | PaymentType
}>

export type UpdateAutomaticPaymentConfigurationMutation = {
  readonly __typename?: 'GqlMutation'
  readonly updateAutomaticPaymentConfiguration: {
    readonly __typename?: 'PaymentConfiguration'
    readonly supportedPaymentTypes: ReadonlyArray<PaymentType>
  }
}

export type PaymentFailureReasonFragment = {
  readonly __typename?: 'PaymentFailureReason'
  readonly declineCode?: string | null
  readonly errorCode?: string | null
  readonly isInternal: boolean
  readonly paymentProvider?: SupportedPaymentProvider | null
}

export type PaymentAttemptDetailFragment = {
  readonly __typename?: 'PaymentAttemptDetail'
  readonly attemptedOn: number
  readonly attemptNumber: number
  readonly id: string
  readonly state: PaymentAttemptState
  readonly userFriendlyState: PaymentAttemptUserFriendlyState
  readonly transactionId: string
  readonly failureReason?: ({ readonly __typename?: 'PaymentFailureReason' } & PaymentFailureReasonFragment) | null
}

export type PaymentDetailFragment = {
  readonly __typename?: 'PaymentDetail'
  readonly id: string
  readonly entityId?: string | null
  readonly amount: number
  readonly currency: string
  readonly canBeVoided?: boolean | null
  readonly cannotBeVoidedReason?: string | null
  readonly invoiceNumber?: string | null
  readonly lifecycleType?: PaymentLifecycleType | null
  readonly invoiceBalance?: number | null
  readonly notes?: string | null
  readonly paymentDate: number
  readonly bankFee?: number | null
  readonly refundStatus?: PaymentRefundStatus | null
  readonly remainingBalance: number
  readonly state: PaymentState
  readonly subscriptionId?: string | null
  readonly transactionId?: string | null
  readonly voidDate?: number | null
  readonly account: { readonly __typename?: 'Account'; readonly id?: string | null; readonly name: string }
  readonly paymentAttempts: ReadonlyArray<
    { readonly __typename?: 'PaymentAttemptDetail' } & PaymentAttemptDetailFragment
  >
  readonly paymentMethodDetail: { readonly __typename?: 'PaymentMethodDetail' } & PaymentMethodDetailFragment
  readonly refundsApplied: ReadonlyArray<({ readonly __typename?: 'Refund' } & RefundFragment) | null>
  readonly paymentBankAccount?: {
    readonly __typename?: 'PaymentBankAccountJson'
    readonly id?: string | null
    readonly entityIds: ReadonlyArray<string | null>
    readonly expenseLedgerAccountId: string
    readonly cashLedgerAccountId: string
    readonly currencyCode: string
    readonly description?: string | null
    readonly name: string
    readonly status: PaymentBankAccountStatus
  } | null
}

export type GetPaymentDetailQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>
}>

export type GetPaymentDetailQuery = {
  readonly __typename?: 'GqlQuery'
  readonly getPayment: ReadonlyArray<{ readonly __typename?: 'PaymentDetail' } & PaymentDetailFragment>
}

export type GetPaymentsInAccountQueryVariables = Exact<{
  accountId?: InputMaybe<Scalars['String']>
}>

export type GetPaymentsInAccountQuery = {
  readonly __typename?: 'GqlQuery'
  readonly getPayment: ReadonlyArray<{ readonly __typename?: 'PaymentDetail' } & PaymentDetailFragment>
}

export type GetPaymentsForInvoiceQueryVariables = Exact<{
  invoiceNumber?: InputMaybe<Scalars['String']>
}>

export type GetPaymentsForInvoiceQuery = {
  readonly __typename?: 'GqlQuery'
  readonly paymentsForInvoice: ReadonlyArray<{ readonly __typename?: 'PaymentDetail' } & PaymentDetailFragment>
}

export type RefundPreviewDetailFragment = {
  readonly __typename?: 'RefundPreviewDetail'
  readonly appliedDate: number
  readonly creditMemoNumber: string
  readonly referenceId?: string | null
  readonly refundAmount: number
  readonly refundAvailable: number
  readonly paymentDetail: { readonly __typename?: 'PaymentDetail' } & PaymentDetailFragment
}

export type GenerateRefundQueryVariables = Exact<{
  creditMemoNumber: Scalars['String']
  paymentId: Scalars['String']
}>

export type GenerateRefundQuery = {
  readonly __typename?: 'GqlQuery'
  readonly generateRefund: { readonly __typename?: 'RefundPreviewDetail' } & RefundPreviewDetailFragment
}

export type VoidPaymentMutationVariables = Exact<{
  voidPaymentRequest: InputVoidPaymentRequest
}>

export type VoidPaymentMutation = {
  readonly __typename?: 'GqlMutation'
  readonly voidPayment: ReadonlyArray<{ readonly __typename?: 'SettlementApplication' } & SettlementApplicationFragment>
}

export type PaymentProviderPaymentMethodFragment = {
  readonly __typename?: 'PaymentProviderPaymentMethod'
  readonly accountNumberLastFour?: string | null
  readonly accountType?: string | null
  readonly bankName?: string | null
  readonly expiryDate?: string | null
  readonly lastFour?: string | null
  readonly updatedOn?: string | null
  readonly paymentMethodId?: string | null
}

export type PaymentMethodDetailFragment = {
  readonly __typename?: 'PaymentMethodDetail'
  readonly id: string
  readonly isDeleted: boolean
  readonly name: string
  readonly status: AccountPaymentMethodStatus
  readonly type?: PaymentType | null
  readonly paymentMethod?:
    | ({ readonly __typename?: 'PaymentProviderPaymentMethod' } & PaymentProviderPaymentMethodFragment)
    | null
}

export type PaymentLinkResponseFragment = {
  readonly __typename?: 'PaymentLinkResponse'
  readonly accountId: string
  readonly connectAccountId: string
  readonly hasOngoingPayment: boolean
  readonly invoiceBalance: number
  readonly invoiceCurrencyCode: string
  readonly invoiceDueDate: number
  readonly invoiceFrom: string
  readonly invoiceNumber: string
  readonly invoiceStatus: InvoiceStatus
  readonly invoiceTo: string
  readonly isCustomerEnrolledInAutoPay: boolean
  readonly paymentDate?: number | null
  readonly subscriptionId: string
  readonly supportedPaymentTypes: ReadonlyArray<PaymentType | null>
  readonly latestPaymentDate?: number | null
  readonly enrolledAccountPaymentMethod?:
    | ({ readonly __typename?: 'PaymentMethodDetail' } & PaymentMethodDetailFragment)
    | null
}

export type AccountPaymentLinkResponseFragment = {
  readonly __typename?: 'AccountPaymentLinkResponse'
  readonly accountId: string
  readonly connectAccountId: string
  readonly isCustomerEnrolledInAutoPay: boolean
  readonly supportedPaymentTypes: ReadonlyArray<PaymentType | null>
  readonly enrolledPaymentMethodDetail?:
    | ({ readonly __typename?: 'PaymentMethodDetail' } & PaymentMethodDetailFragment)
    | null
}

export type InvoicePaymentLinkDetailsQueryVariables = Exact<{
  linkId: Scalars['String']
}>

export type InvoicePaymentLinkDetailsQuery = {
  readonly __typename?: 'GqlQuery'
  readonly invoicePaymentLinkDetails: { readonly __typename?: 'PaymentLinkResponse' } & PaymentLinkResponseFragment
}

export type CreatePaymentIntentMutationVariables = Exact<{
  paymentLinkId: Scalars['String']
}>

export type CreatePaymentIntentMutation = {
  readonly __typename?: 'GqlMutation'
  readonly createPaymentIntent: {
    readonly __typename?: 'StripeIntentResponse'
    readonly clientSecret: string
    readonly paymentIntentId: string
  }
}

export type UpdatePaymentIntentMutationVariables = Exact<{
  paymentLinkId: Scalars['String']
  paymentIntentId: Scalars['String']
  optInForAutomaticPayments: Scalars['Boolean']
}>

export type UpdatePaymentIntentMutation = { readonly __typename?: 'GqlMutation'; readonly updatePaymentIntent: boolean }

export type UnEnrollFromAutomaticPaymentsMutationVariables = Exact<{
  paymentLinkId: Scalars['String']
  paymentLinkType: PaymentManagementLinkType
}>

export type UnEnrollFromAutomaticPaymentsMutation = {
  readonly __typename?: 'GqlMutation'
  readonly unEnrollFromAutomaticPayments: boolean
}

export type UpdateAccountSupportedPaymentTypesMutationVariables = Exact<{
  accountId: Scalars['String']
  supportedPaymentTypes: ReadonlyArray<InputMaybe<PaymentType>> | InputMaybe<PaymentType>
}>

export type UpdateAccountSupportedPaymentTypesMutation = {
  readonly __typename?: 'GqlMutation'
  readonly updateAccountSupportedPaymentTypes: boolean
}

export type GenerateAccountPaymentLinkMutationVariables = Exact<{
  accountId: Scalars['String']
}>

export type GenerateAccountPaymentLinkMutation = {
  readonly __typename?: 'GqlMutation'
  readonly generateAccountPaymentLink?: {
    readonly __typename?: 'AccountPaymentManagementLinkResponse'
    readonly accountId: string
    readonly linkId: string
  } | null
}

export type AccountPaymentLinkDetailsQueryVariables = Exact<{
  linkId: Scalars['String']
}>

export type AccountPaymentLinkDetailsQuery = {
  readonly __typename?: 'GqlQuery'
  readonly accountPaymentLinkDetails: {
    readonly __typename?: 'AccountPaymentLinkResponse'
  } & AccountPaymentLinkResponseFragment
}

export type CreateSetupIntentMutationVariables = Exact<{
  paymentLinkId: Scalars['String']
}>

export type CreateSetupIntentMutation = {
  readonly __typename?: 'GqlMutation'
  readonly createSetupIntent: {
    readonly __typename?: 'StripeSetupIntentResponse'
    readonly clientSecret: string
    readonly setupIntentId: string
  }
}

export type ReEnableAutomaticPaymentsMutationVariables = Exact<{
  paymentLinkId: Scalars['String']
  paymentLinkType: PaymentManagementLinkType
}>

export type ReEnableAutomaticPaymentsMutation = {
  readonly __typename?: 'GqlMutation'
  readonly reEnableAutomaticPayments: boolean
}

export type GetHasPaymentIntegrationQueryVariables = Exact<{ [key: string]: never }>

export type GetHasPaymentIntegrationQuery = {
  readonly __typename?: 'GqlQuery'
  readonly hasPaymentIntegration: boolean
}

export type PlanCoreFragment = {
  readonly __typename?: 'PlanDetail'
  readonly id: string
  readonly name: string
  readonly displayName?: string | null
  readonly inUse: boolean
  readonly description?: string | null
  readonly currency: string
  readonly status: PlanStatus
  readonly externalId?: string | null
}

export type LedgerAccountMappingFragment = {
  readonly __typename?: 'LedgerAccountMapping'
  readonly contractAssetAccountId?: string | null
  readonly deferredRevenueAccountId?: string | null
  readonly recognizedRevenueAccountId?: string | null
  readonly taxLiabilityAccountId?: string | null
}

export type PlanDetailFragment = {
  readonly __typename?: 'PlanDetail'
  readonly entityIds?: ReadonlyArray<string> | null
  readonly product: {
    readonly __typename?: 'Product'
    readonly id: string
    readonly name: string
    readonly description?: string | null
    readonly inUse: boolean
    readonly sku?: string | null
    readonly updatedOn?: number | null
  }
  readonly charges: ReadonlyArray<{
    readonly __typename?: 'ChargeDetail'
    readonly id: string
    readonly name: string
    readonly displayName?: string | null
    readonly description?: string | null
    readonly amount?: number | null
    readonly percent?: number | null
    readonly percentDerivedFrom?: PercentDerivedFrom | null
    readonly type: ChargeType
    readonly chargeModel: ChargeModel
    readonly isDrawdown?: boolean | null
    readonly isRenewable: boolean
    readonly isCustom?: boolean | null
    readonly isListPriceEditable?: boolean | null
    readonly minQuantity?: number | null
    readonly defaultQuantity?: number | null
    readonly maxQuantity?: number | null
    readonly billingTerm: BillingTerm
    readonly durationInMonths?: number | null
    readonly recurrence?: { readonly __typename?: 'Recurrence'; readonly cycle: Cycle; readonly step: number } | null
    readonly priceTiers: ReadonlyArray<{
      readonly __typename?: 'priceTier'
      readonly untilQuantity: string
      readonly amount: number
    }>
    readonly taxRate?: {
      readonly __typename?: 'TaxRate'
      readonly id?: string | null
      readonly name: string
      readonly description?: string | null
      readonly taxCode: string
      readonly taxInclusive: boolean
      readonly status: Status
    } | null
    readonly unitOfMeasure?: {
      readonly __typename?: 'UnitOfMeasure'
      readonly id: string
      readonly name: string
      readonly description: string
      readonly UOMStatus: UnitOfMeasureStatus
    } | null
    readonly recognitionRule?: ({ readonly __typename?: 'RecognitionRule' } & RecognitionRuleFragment) | null
    readonly ledgerAccountMapping?:
      | ({ readonly __typename?: 'LedgerAccountMapping' } & LedgerAccountMappingFragment)
      | null
  }>
  readonly percentOfChargeRelationships?: ReadonlyArray<{
    readonly __typename?: 'CatalogRelationship'
    readonly catalogRelationshipType?: CatalogRelationshipType | null
    readonly fromPlanId?: string | null
    readonly fromProductId?: string | null
    readonly toPlanId?: string | null
    readonly toProductId?: string | null
    readonly relationshipId?: string | null
  }> | null
  readonly requireAllPlans?: ReadonlyArray<{
    readonly __typename?: 'Plan'
    readonly id?: string | null
    readonly name: string
    readonly productId: string
  }> | null
  readonly requireAnyPlans?: ReadonlyArray<{
    readonly __typename?: 'Plan'
    readonly id?: string | null
    readonly name: string
    readonly productId: string
  }> | null
  readonly exclusionPlans?: ReadonlyArray<{
    readonly __typename?: 'Plan'
    readonly id?: string | null
    readonly name: string
    readonly productId: string
  }> | null
  readonly documentTemplates: ReadonlyArray<{
    readonly __typename?: 'DocumentTemplate'
    readonly id: string
    readonly name: string
  } | null>
} & PlanCoreFragment

export type GetPlanQueryVariables = Exact<{
  id: Scalars['String']
}>

export type GetPlanQuery = {
  readonly __typename?: 'GqlQuery'
  readonly planDetail: { readonly __typename?: 'PlanDetail' } & PlanDetailFragment
}

export type DeletePlanMutationVariables = Exact<{
  id: Scalars['String']
}>

export type DeletePlanMutation = {
  readonly __typename?: 'GqlMutation'
  readonly deletePlan: { readonly __typename?: 'PlanDetail'; readonly id: string }
}

export type DeprecatePlanMutationVariables = Exact<{
  id: Scalars['String']
}>

export type DeprecatePlanMutation = {
  readonly __typename?: 'GqlMutation'
  readonly deprecatePlan: { readonly __typename?: 'PlanDetail'; readonly id: string }
}

export type ActivatePlanMutationVariables = Exact<{
  id: Scalars['String']
}>

export type ActivatePlanMutation = {
  readonly __typename?: 'GqlMutation'
  readonly activatePlan: { readonly __typename?: 'PlanDetail'; readonly id: string }
}

export type AddPlanMutationVariables = Exact<{
  addPlanRequest: InputAddPlanRequest
}>

export type AddPlanMutation = {
  readonly __typename?: 'GqlMutation'
  readonly addPlan: { readonly __typename?: 'PlanDetail'; readonly id: string }
}

export type UpdatePlanMutationVariables = Exact<{
  updatePlanRequest: InputUpdatePlanRequest
}>

export type UpdatePlanMutation = {
  readonly __typename?: 'GqlMutation'
  readonly updatePlan: { readonly __typename?: 'PlanDetail'; readonly id: string }
}

export type DuplicatePlanMutationVariables = Exact<{
  id: Scalars['String']
}>

export type DuplicatePlanMutation = {
  readonly __typename?: 'GqlMutation'
  readonly duplicatePlan: { readonly __typename?: 'PlanDetail'; readonly id: string }
}

export type RevertToDraftMutationVariables = Exact<{
  id: Scalars['String']
}>

export type RevertToDraftMutation = {
  readonly __typename?: 'GqlMutation'
  readonly revertToDraft: { readonly __typename?: 'PlanDetail'; readonly id: string; readonly status: PlanStatus }
}

export type ReactivatePlanMutationVariables = Exact<{
  id: Scalars['String']
}>

export type ReactivatePlanMutation = {
  readonly __typename?: 'GqlMutation'
  readonly reactivatePlan: { readonly __typename?: 'PlanDetail'; readonly id: string }
}

export type ListUnitsOfMeasureQueryVariables = Exact<{ [key: string]: never }>

export type ListUnitsOfMeasureQuery = {
  readonly __typename?: 'GqlQuery'
  readonly unitsOfMeasure: ReadonlyArray<{
    readonly __typename?: 'UnitOfMeasure'
    readonly id: string
    readonly name: string
    readonly description: string
    readonly UOMStatus: UnitOfMeasureStatus
  }>
}

export type ListTaxRatesQueryVariables = Exact<{ [key: string]: never }>

export type ListTaxRatesQuery = {
  readonly __typename?: 'GqlQuery'
  readonly taxRates: ReadonlyArray<{
    readonly __typename?: 'TaxRate'
    readonly id?: string | null
    readonly name: string
    readonly description?: string | null
    readonly taxCode: string
    readonly taxInclusive: boolean
    readonly status: Status
  }>
}

export type ListRecognitionRulesQueryVariables = Exact<{ [key: string]: never }>

export type ListRecognitionRulesQuery = {
  readonly __typename?: 'GqlQuery'
  readonly recognitionRules: ReadonlyArray<{ readonly __typename?: 'RecognitionRule' } & RecognitionRuleFragment>
}

export type PlanGroupItemFragment = {
  readonly __typename?: 'PlanGroupItemInterface'
  readonly planId: string
  readonly chargeId: string
  readonly defaultQuantity?: number | null
}

export type PlanGroupFragment = {
  readonly __typename?: 'PlanGroupInterface'
  readonly id?: string | null
  readonly name: string
  readonly description?: string | null
  readonly currency?: string | null
  readonly planGroupItems?: ReadonlyArray<
    ({ readonly __typename?: 'PlanGroupItemInterface' } & PlanGroupItemFragment) | null
  > | null
}

export type GetPlanGroupQueryVariables = Exact<{
  planGroupId: Scalars['String']
}>

export type GetPlanGroupQuery = {
  readonly __typename?: 'GqlQuery'
  readonly planGroup: { readonly __typename?: 'PlanGroupInterface' } & PlanGroupFragment
}

export type UpsertPlanGroupMutationVariables = Exact<{
  planGroup: InputPlanGroupInterface
}>

export type UpsertPlanGroupMutation = {
  readonly __typename?: 'GqlMutation'
  readonly upsertPlanGroup: { readonly __typename?: 'PlanGroupInterface' } & PlanGroupFragment
}

export type ProposalLineFragment = {
  readonly __typename?: 'ProposalLine'
  readonly chargeId?: string | null
  readonly chargeName?: string | null
  readonly discount?: number | null
  readonly id?: string | null
  readonly listAmount?: number | null
  readonly listUnitPrice?: number | null
  readonly planId?: string | null
  readonly planName?: string | null
  readonly quantity?: number | null
  readonly sellAmount?: number | null
  readonly sellUnitPrice?: number | null
}

export type CreateProposalMutationVariables = Exact<{
  createProposalRequest: InputCreateProposalRequest
}>

export type CreateProposalMutation = {
  readonly __typename?: 'GqlMutation'
  readonly createProposal: {
    readonly __typename?: 'Proposal'
    readonly proposalId?: string | null
    readonly proposalFor?: string | null
    readonly variations: ReadonlyArray<{
      readonly __typename?: 'Variation'
      readonly listAmountTotal?: number | null
      readonly sellAmountTotal?: number | null
      readonly termInYears?: number | null
      readonly proposalLines?: ReadonlyArray<
        ({ readonly __typename?: 'ProposalLine' } & ProposalLineFragment) | null
      > | null
    } | null>
  }
}

export type ProductCategoryFragment = {
  readonly __typename?: 'ProductCategory'
  readonly id: string
  readonly description?: string | null
  readonly name?: string | null
  readonly inUse?: boolean | null
  readonly updatedOn?: number | null
  readonly entityIds?: ReadonlyArray<string> | null
}

export type GetProductCategoriesQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>
}>

export type GetProductCategoriesQuery = {
  readonly __typename?: 'GqlQuery'
  readonly productCategories: ReadonlyArray<{ readonly __typename?: 'ProductCategory' } & ProductCategoryFragment>
}

export type UpdateProductCategoryMutationVariables = Exact<{
  id: Scalars['String']
  description: Scalars['String']
  name: Scalars['String']
  entityIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>
}>

export type UpdateProductCategoryMutation = {
  readonly __typename?: 'GqlMutation'
  readonly updateProductCategory: { readonly __typename?: 'ProductCategory' } & ProductCategoryFragment
}

export type UpsertProductCategoryMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']>
  description: Scalars['String']
  name: Scalars['String']
  entityIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>
}>

export type UpsertProductCategoryMutation = {
  readonly __typename?: 'GqlMutation'
  readonly upsertProductCategory: { readonly __typename?: 'ProductCategory' } & ProductCategoryFragment
}

export type DeleteProductCategoryMutationVariables = Exact<{
  id: Scalars['String']
}>

export type DeleteProductCategoryMutation = {
  readonly __typename?: 'GqlMutation'
  readonly deleteProductCategory: { readonly __typename?: 'ProductCategory'; readonly id: string }
}

export type AddProductCategoryMutationVariables = Exact<{
  description: Scalars['String']
  name: Scalars['String']
}>

export type AddProductCategoryMutation = {
  readonly __typename?: 'GqlMutation'
  readonly addProductCategory: { readonly __typename?: 'ProductCategory' } & ProductCategoryFragment
}

export type ProductFragment = {
  readonly __typename?: 'Product'
  readonly id: string
  readonly entityIds?: ReadonlyArray<string> | null
  readonly description?: string | null
  readonly name: string
  readonly displayName?: string | null
  readonly inUse: boolean
  readonly sku?: string | null
  readonly updatedOn?: number | null
  readonly externalId?: string | null
  readonly productCategory?: ({ readonly __typename?: 'ProductCategory' } & ProductCategoryFragment) | null
}

export type ProductDetailFragment = {
  readonly __typename?: 'ProductDetail'
  readonly id: string
  readonly entityIds?: ReadonlyArray<string> | null
  readonly description?: string | null
  readonly name: string
  readonly displayName?: string | null
  readonly inUse?: boolean | null
  readonly sku?: string | null
  readonly updatedOn?: number | null
  readonly externalId?: string | null
  readonly productCategory?: ({ readonly __typename?: 'ProductCategory' } & ProductCategoryFragment) | null
}

export type GetProductQueryVariables = Exact<{
  id: Scalars['String']
}>

export type GetProductQuery = {
  readonly __typename?: 'GqlQuery'
  readonly product: { readonly __typename?: 'ProductDetail' } & ProductDetailFragment
}

export type UpdateProductMutationVariables = Exact<{
  id: Scalars['String']
  description?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  displayName?: InputMaybe<Scalars['String']>
  sku?: InputMaybe<Scalars['String']>
  productCategoryId?: InputMaybe<Scalars['String']>
  externalId?: InputMaybe<Scalars['String']>
  entityIds?: InputMaybe<ReadonlyArray<Scalars['String']> | Scalars['String']>
}>

export type UpdateProductMutation = {
  readonly __typename?: 'GqlMutation'
  readonly updateProduct: { readonly __typename?: 'ProductDetail' } & ProductDetailFragment
}

export type DeleteProductMutationVariables = Exact<{
  id: Scalars['String']
}>

export type DeleteProductMutation = {
  readonly __typename?: 'GqlMutation'
  readonly deleteProduct: { readonly __typename?: 'ProductDetail'; readonly id: string }
}

export type GetPlansQueryVariables = Exact<{
  productId: Scalars['String']
}>

export type GetPlansQuery = {
  readonly __typename?: 'GqlQuery'
  readonly plans: ReadonlyArray<{
    readonly __typename?: 'Plan'
    readonly id?: string | null
    readonly entityIds?: ReadonlyArray<string> | null
    readonly name: string
    readonly currency?: string | null
    readonly description?: string | null
    readonly status: PlanStatus
    readonly productId: string
    readonly charges: ReadonlyArray<{ readonly __typename?: 'Charge' } & ChargeFragment>
  }>
}

export type GetAllPlansQueryVariables = Exact<{ [key: string]: never }>

export type GetAllPlansQuery = {
  readonly __typename?: 'GqlQuery'
  readonly plans: ReadonlyArray<{
    readonly __typename?: 'Plan'
    readonly id?: string | null
    readonly name: string
    readonly currency?: string | null
    readonly description?: string | null
    readonly status: PlanStatus
    readonly productId: string
    readonly charges: ReadonlyArray<{ readonly __typename?: 'Charge' } & ChargeFragment>
  }>
}

export type PlanMinimalFragment = {
  readonly __typename?: 'PlanMinimal'
  readonly id?: string | null
  readonly name: string
  readonly currency?: string | null
  readonly description?: string | null
  readonly status: PlanStatus
  readonly productId: string
  readonly entityIds?: ReadonlyArray<string> | null
  readonly product: { readonly __typename?: 'Product' } & ProductFragment
}

export type GetPlanMinimalsQueryVariables = Exact<{
  status?: InputMaybe<PlanStatus>
  accountId?: InputMaybe<Scalars['String']>
  orderCurrencyCode?: InputMaybe<Scalars['String']>
}>

export type GetPlanMinimalsQuery = {
  readonly __typename?: 'GqlQuery'
  readonly planMinimals: ReadonlyArray<{ readonly __typename?: 'PlanMinimal' } & PlanMinimalFragment>
}

export type AddProductMutationVariables = Exact<{
  description?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  displayName?: InputMaybe<Scalars['String']>
  sku?: InputMaybe<Scalars['String']>
  productCategoryId?: InputMaybe<Scalars['String']>
  externalId?: InputMaybe<Scalars['String']>
  entityIds?: InputMaybe<ReadonlyArray<Scalars['String']> | Scalars['String']>
}>

export type AddProductMutation = {
  readonly __typename?: 'GqlMutation'
  readonly addProduct: { readonly __typename?: 'ProductDetail' } & ProductDetailFragment
}

export type AttributeReferenceFragment = {
  readonly __typename?: 'AttributeReference'
  readonly attributeDefinitionId: string
  readonly attributeValue: string
}

export type PriceTableFragment = {
  readonly __typename?: 'PriceTableElement'
  readonly price: number
  readonly attributeReferences: ReadonlyArray<
    { readonly __typename?: 'AttributeReference' } & AttributeReferenceFragment
  >
}

export type RateCardFragment = {
  readonly __typename?: 'RateCard'
  readonly id?: string | null
  readonly name: string
  readonly description?: string | null
  readonly currencyCode: string
  readonly priceTable: ReadonlyArray<{ readonly __typename?: 'PriceTableElement' } & PriceTableFragment>
}

export type GetRateCardsQueryVariables = Exact<{ [key: string]: never }>

export type GetRateCardsQuery = {
  readonly __typename?: 'GqlQuery'
  readonly rateCards: ReadonlyArray<{ readonly __typename?: 'RateCard' } & RateCardFragment>
}

export type GetRateCardQueryVariables = Exact<{
  id: Scalars['String']
  targetCurrencyCode?: InputMaybe<Scalars['String']>
  effectiveDate?: InputMaybe<Scalars['Long']>
}>

export type GetRateCardQuery = {
  readonly __typename?: 'GqlQuery'
  readonly rateCard: { readonly __typename?: 'RateCard' } & RateCardFragment
}

export type GetPriceAttributesQueryVariables = Exact<{ [key: string]: never }>

export type GetPriceAttributesQuery = {
  readonly __typename?: 'GqlQuery'
  readonly priceAttributes: ReadonlyArray<{ readonly __typename?: 'PriceAttribute' } & PriceAttributeFragment>
}

export type UpsertPriceAttributeMutationVariables = Exact<{
  priceAttribute: InputPriceAttribute
}>

export type UpsertPriceAttributeMutation = {
  readonly __typename?: 'GqlMutation'
  readonly upsertPriceAttribute: { readonly __typename?: 'PriceAttribute' } & PriceAttributeFragment
}

export type UpsertRateCardMutationVariables = Exact<{
  rateCard: InputRateCard
}>

export type UpsertRateCardMutation = {
  readonly __typename?: 'GqlMutation'
  readonly upsertRateCard: { readonly __typename?: 'RateCard' } & RateCardFragment
}

export type PriceAttributeFragment = {
  readonly __typename?: 'PriceAttribute'
  readonly id?: string | null
  readonly name: string
  readonly type?: PriceAttributeType | null
  readonly values: ReadonlyArray<string>
}

export type ReportFilterFragment = {
  readonly __typename?: 'PredefinedReportParameter'
  readonly datatype: ParamDataType
  readonly type: ParamType
  readonly optional?: boolean | null
  readonly name: string
  readonly defaultValue?: string | null
}

export type ReportChartFragment = {
  readonly __typename?: 'PredefinedReportDefinitionChart'
  readonly chartType: string
  readonly mainAxisKey: string
  readonly crossAxisKey?: string | null
  readonly mainAxisLabel?: string | null
  readonly crossAxisLabel?: string | null
  readonly mainAxisScale?: string | null
  readonly crossAxisScale?: string | null
  readonly title?: string | null
  readonly sortBy?: string | null
  readonly showLegend?: boolean | null
}

export type ReportDefinitionFragment = {
  readonly __typename?: 'PredefinedReportDefinition'
  readonly reportId: string
  readonly name: string
  readonly description: string
  readonly filters?: ReadonlyArray<
    ({ readonly __typename?: 'PredefinedReportParameter' } & ReportFilterFragment) | null
  > | null
  readonly chart?: ({ readonly __typename?: 'PredefinedReportDefinitionChart' } & ReportChartFragment) | null
}

export type GetPredefinedReportsQueryVariables = Exact<{ [key: string]: never }>

export type GetPredefinedReportsQuery = {
  readonly __typename?: 'GqlQuery'
  readonly predefinedReports: {
    readonly __typename?: 'PredefinedReports'
    readonly reportDefs: ReadonlyArray<
      ({ readonly __typename?: 'PredefinedReportDefinition' } & ReportDefinitionFragment) | null
    >
  }
}

export type GenerateReportMutationVariables = Exact<{
  req: InputPredefinedReportRequest
}>

export type GenerateReportMutation = {
  readonly __typename?: 'GqlMutation'
  readonly generateReport: {
    readonly __typename?: 'ReportJobResponse'
    readonly status: ReportJobStatus
    readonly uri: string
  }
}

export type GetCurrentLookerUserQueryVariables = Exact<{ [key: string]: never }>

export type GetCurrentLookerUserQuery = {
  readonly __typename?: 'GqlQuery'
  readonly currentLookerUser?: {
    readonly __typename?: 'LookerUserMapping'
    readonly isDisabled: boolean
    readonly userId: string
    readonly lookerUserId: string
  } | null
}

export type RecognitionRuleFragment = {
  readonly __typename?: 'RecognitionRule'
  readonly id?: string | null
  readonly name: string
  readonly source: RecognitionSource
  readonly recognitionType: RecognitionType
  readonly recognitionEventType?: RecognitionEventType | null
  readonly distributionMethod?: RecognitionDistributionMethod | null
  readonly recognitionDateAlignment?: RecognitionDateAlignment | null
  readonly deferredRevenueAccountId?: string | null
  readonly recognizedRevenueAccountId?: string | null
  readonly inUse?: boolean | null
  readonly entityIds?: ReadonlyArray<string> | null
}

export type GetRecognitionRulesQueryVariables = Exact<{ [key: string]: never }>

export type GetRecognitionRulesQuery = {
  readonly __typename?: 'GqlQuery'
  readonly recognitionRules: ReadonlyArray<{ readonly __typename?: 'RecognitionRule' } & RecognitionRuleFragment>
}

export type DeleteRecognitionRuleMutationVariables = Exact<{
  id: Scalars['String']
}>

export type DeleteRecognitionRuleMutation = {
  readonly __typename?: 'GqlMutation'
  readonly deleteRecognitionRule: { readonly __typename?: 'RecognitionRule' } & RecognitionRuleFragment
}

export type RecognizeRevenueMutationVariables = Exact<{ [key: string]: never }>

export type RecognizeRevenueMutation = { readonly __typename?: 'GqlMutation'; readonly recognizeRevenue: boolean }

export type UpsertRecognitionRuleMutationVariables = Exact<{
  recognitionRule: InputRecognitionRule
}>

export type UpsertRecognitionRuleMutation = {
  readonly __typename?: 'GqlMutation'
  readonly upsertRecognitionRule: { readonly __typename?: 'RecognitionRule' } & RecognitionRuleFragment
}

export type RevenueWaterfallItemFragment = {
  readonly __typename?: 'RevenueWaterfallItem'
  readonly revenueAmount?: number | null
  readonly status?: RevenueStatus | null
  readonly unitOfCompletion?: number | null
  readonly accountingPeriod?: { readonly __typename?: 'AccountingPeriod'; readonly startDate: string } | null
}

export type SubscriptionChargeRecognitionFragment = {
  readonly __typename?: 'SubscriptionChargeRecognition'
  readonly chargeId: string
  readonly deferredRevenue?: number | null
  readonly totalRevenue?: number | null
  readonly recognizedRevenue?: number | null
  readonly subscriptionId: string
  readonly accountingPeriod?: ({ readonly __typename?: 'AccountingPeriod' } & AccountingPeriodFragment) | null
  readonly revenueWaterfallItems?: ReadonlyArray<
    { readonly __typename?: 'RevenueWaterfallItem' } & RevenueWaterfallItemFragment
  > | null
  readonly recognitionRule?: ({ readonly __typename?: 'RecognitionRule' } & RecognitionRuleFragment) | null
}

export type GetSubscriptionChargeRecognitionQueryVariables = Exact<{
  subscriptionId: Scalars['String']
  chargeId: Scalars['String']
}>

export type GetSubscriptionChargeRecognitionQuery = {
  readonly __typename?: 'GqlQuery'
  readonly subscriptionChargeRecognition: {
    readonly __typename?: 'SubscriptionChargeRecognition'
  } & SubscriptionChargeRecognitionFragment
}

export type CreateSalesRoomLinkMutationVariables = Exact<{
  orderId: Scalars['String']
}>

export type CreateSalesRoomLinkMutation = {
  readonly __typename?: 'GqlMutation'
  readonly createSalesRoomLink: {
    readonly __typename?: 'SalesRoomLink'
    readonly linkId: string
    readonly accountId: string
    readonly orderId: string
    readonly id: string
  }
}

export type GetSalesRoomLinkQueryVariables = Exact<{
  orderId: Scalars['String']
}>

export type GetSalesRoomLinkQuery = {
  readonly __typename?: 'GqlQuery'
  readonly salesRoomLink?: {
    readonly __typename?: 'SalesRoomLink'
    readonly linkId: string
    readonly accountId: string
    readonly orderId: string
    readonly id: string
  } | null
}

export type SalesRoomAccountAddressFragment = {
  readonly __typename?: 'AccountAddress'
  readonly city: string
  readonly country: string
  readonly state?: string | null
  readonly streetAddressLine1: string
  readonly streetAddressLine2?: string | null
  readonly zipcode: string
}

export type SalesRoomAccountFragment = {
  readonly __typename?: 'AccountDetail'
  readonly id: string
  readonly name: string
  readonly contactDetails: ReadonlyArray<
    { readonly __typename?: 'AccountContactDetail' } & AccountContactDetailFragment
  >
  readonly address?: ({ readonly __typename?: 'AccountAddress' } & SalesRoomAccountAddressFragment) | null
}

export type SalesRoomCustomFieldEntryFragment = {
  readonly __typename?: 'CustomFieldEntry'
  readonly id: string
  readonly name: string
  readonly label?: string | null
  readonly type?: CustomFieldType | null
  readonly value?: string | null
  readonly source?: CustomFieldSource | null
  readonly options?: ReadonlyArray<string | null> | null
  readonly required?: boolean | null
  readonly selections?: ReadonlyArray<string | null> | null
}

export type GetSalesRoomQueryVariables = Exact<{
  linkId: Scalars['String']
}>

export type GetSalesRoomQuery = {
  readonly __typename?: 'GqlQuery'
  readonly salesRoom?: {
    readonly __typename?: 'SalesRoom'
    readonly linkId: string
    readonly electronicSignatureStatus?: ElectronicSignatureStatus | null
    readonly hasElectronicSignatureIntegration: boolean
    readonly tenantName?: string | null
    readonly id: string
    readonly accountDetail: { readonly __typename?: 'AccountDetail' } & SalesRoomAccountFragment
    readonly orderDetail: { readonly __typename?: 'OrderDetail' } & OrderDetailFragment
    readonly customFields: ReadonlyArray<
      { readonly __typename?: 'CustomFieldEntry' } & SalesRoomCustomFieldEntryFragment
    >
    readonly tenantAddress?: {
      readonly __typename?: 'AccountAddress'
      readonly city: string
      readonly country: string
      readonly state?: string | null
      readonly streetAddressLine1: string
      readonly streetAddressLine2?: string | null
      readonly zipcode: string
    } | null
  } | null
}

export type GetSalesRoomResellerQueryVariables = Exact<{
  linkId: Scalars['String']
}>

export type GetSalesRoomResellerQuery = {
  readonly __typename?: 'GqlQuery'
  readonly resellerForSalesRoom?: ({ readonly __typename?: 'AccountDetail' } & SalesRoomAccountFragment) | null
}

export type UpsertAccountContactForSalesRoomMutationVariables = Exact<{
  accountContact: InputAccountContact
  linkId: Scalars['String']
  updateReseller: Scalars['Boolean']
}>

export type UpsertAccountContactForSalesRoomMutation = {
  readonly __typename?: 'GqlMutation'
  readonly upsertAccountContactForSalesRoom: { readonly __typename?: 'AccountContact' } & AccountContactFragment
}

export type UpdateOrderContactsMutationVariables = Exact<{
  linkId: Scalars['String']
  billingContactId?: InputMaybe<Scalars['String']>
  shippingContactId?: InputMaybe<Scalars['String']>
}>

export type UpdateOrderContactsMutation = { readonly __typename?: 'GqlMutation'; readonly updateOrderContacts: boolean }

export type SendEmailForEsignSalesRoomMutationVariables = Exact<{
  linkId: Scalars['String']
  accountSignatory: InputEmailContact
}>

export type SendEmailForEsignSalesRoomMutation = {
  readonly __typename?: 'GqlMutation'
  readonly sendEmailForEsignSalesRoom: boolean
}

export type UpdateCustomFieldsForSalesRoomMutationVariables = Exact<{
  linkId: Scalars['String']
  customFields: ReadonlyArray<InputCustomFieldInput> | InputCustomFieldInput
}>

export type UpdateCustomFieldsForSalesRoomMutation = {
  readonly __typename?: 'GqlMutation'
  readonly updateCustomFieldsForSalesRoom: ReadonlyArray<
    { readonly __typename?: 'CustomFieldEntry' } & SalesRoomCustomFieldEntryFragment
  >
}

export type CreateOrderDocumentForSalesRoomMutationVariables = Exact<{
  linkId: Scalars['String']
}>

export type CreateOrderDocumentForSalesRoomMutation = {
  readonly __typename?: 'GqlMutation'
  readonly createOrderDocumentForSalesRoom: boolean
}

export type SyncCompositeOrderToCrmMutationVariables = Exact<{
  compositeOrderId: Scalars['String']
}>

export type SyncCompositeOrderToCrmMutation = {
  readonly __typename?: 'GqlMutation'
  readonly syncCompositeOrderToCrm: { readonly __typename?: 'CompositeOrder' } & CompositeOrderDetailFragment
}

export type GetSalesforceIntegrationQueryVariables = Exact<{ [key: string]: never }>

export type GetSalesforceIntegrationQuery = {
  readonly __typename?: 'GqlQuery'
  readonly salesforceIntegration: {
    readonly __typename?: 'SalesforceIntegrationResponse'
    readonly clientId: string
    readonly createdOn: number
    readonly updatedOn: number
    readonly integrationId: string
    readonly baseLoginUrl?: string | null
    readonly isCompleted: boolean
  }
}

export type GetSalesforceIntegrationDetailsQueryVariables = Exact<{ [key: string]: never }>

export type GetSalesforceIntegrationDetailsQuery = {
  readonly __typename?: 'GqlQuery'
  readonly salesforceIntegrationDetails: {
    readonly __typename?: 'SalesforceIntegrationResponse'
    readonly clientId: string
    readonly createdOn: number
    readonly updatedOn: number
    readonly integrationId: string
    readonly baseLoginUrl?: string | null
    readonly isCompleted: boolean
  }
}

export type InitiateSalesforceIntegrationMutationVariables = Exact<{
  clientId: Scalars['String']
  clientSecret: Scalars['String']
  redirectUri?: InputMaybe<Scalars['String']>
  baseLoginUrl?: InputMaybe<Scalars['String']>
}>

export type InitiateSalesforceIntegrationMutation = {
  readonly __typename?: 'GqlMutation'
  readonly initiateSalesforceIntegration: string
}

export type CompleteSalesforceIntegrationMutationVariables = Exact<{
  code: Scalars['String']
  state: Scalars['String']
  redirect_uri: Scalars['String']
}>

export type CompleteSalesforceIntegrationMutation = {
  readonly __typename?: 'GqlMutation'
  readonly completeSalesforceIntegration: boolean
}

export type DeleteSalesforceIntegrationMutationVariables = Exact<{ [key: string]: never }>

export type DeleteSalesforceIntegrationMutation = {
  readonly __typename?: 'GqlMutation'
  readonly deleteSalesforceIntegration: boolean
}

export type UpdatePrimaryOrderIdForSalesforceOpportunityMutationVariables = Exact<{
  id: Scalars['String']
}>

export type UpdatePrimaryOrderIdForSalesforceOpportunityMutation = {
  readonly __typename?: 'GqlMutation'
  readonly updatePrimaryOrderIdForSalesforceOpportunity: {
    readonly __typename?: 'OrderDetail'
    readonly id?: string | null
  }
}

export type SearchQueryVariables = Exact<{
  jsonInBase64: Scalars['String']
}>

export type SearchQuery = { readonly __typename?: 'GqlQuery'; readonly search: string }

export type SearchInTenantQueryVariables = Exact<{
  jsonInBase64: Scalars['String']
  tenantId: Scalars['String']
}>

export type SearchInTenantQuery = { readonly __typename?: 'GqlQuery'; readonly searchInTenant: string }

export type AccountAddressFragment = {
  readonly __typename?: 'AccountAddress'
  readonly city: string
  readonly country: string
  readonly state?: string | null
  readonly streetAddressLine1: string
  readonly streetAddressLine2?: string | null
  readonly zipcode: string
}

export type TenantDetailsFragment = {
  readonly __typename?: 'TenantDetails'
  readonly name: string
  readonly email?: string | null
  readonly phoneNumber?: string | null
  readonly address?: ({ readonly __typename?: 'AccountAddress' } & AccountAddressFragment) | null
}

export type GetSettingQueryVariables = Exact<{ [key: string]: never }>

export type GetSettingQuery = {
  readonly __typename?: 'GqlQuery'
  readonly setting: {
    readonly __typename?: 'Setting'
    readonly percentDerivedFrom?: PercentDerivedFrom | null
    readonly defaultTimeZone?: string | null
    readonly supportedCurrencies?: ReadonlyArray<string> | null
    readonly orderExpiryDurationInDays?: string | null
    readonly isUpdateOrderStartDateEnabled?: boolean | null
  }
}

export type GetSupportedCurrenciesQueryVariables = Exact<{ [key: string]: never }>

export type GetSupportedCurrenciesQuery = {
  readonly __typename?: 'GqlQuery'
  readonly supportedCurrencies: ReadonlyArray<{
    readonly __typename?: 'Currency'
    readonly currencyCode: string
    readonly inUse: boolean
    readonly supportedByTenant: boolean
  }>
}

export type SetSupportedCurrenciesMutationVariables = Exact<{
  currencies: ReadonlyArray<Scalars['String']> | Scalars['String']
}>

export type SetSupportedCurrenciesMutation = {
  readonly __typename?: 'GqlMutation'
  readonly updateSupportedCurrencies: ReadonlyArray<{
    readonly __typename?: 'Currency'
    readonly currencyCode: string
    readonly supportedByTenant: boolean
    readonly inUse: boolean
  }>
}

export type GetTenantDetailsQueryVariables = Exact<{ [key: string]: never }>

export type GetTenantDetailsQuery = {
  readonly __typename?: 'GqlQuery'
  readonly tenantDetails: { readonly __typename?: 'TenantDetails' } & TenantDetailsFragment
}

export type GetUnitsOfMeasureQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>
}>

export type GetUnitsOfMeasureQuery = {
  readonly __typename?: 'GqlQuery'
  readonly unitsOfMeasure: ReadonlyArray<{
    readonly __typename?: 'UnitOfMeasure'
    readonly id: string
    readonly name: string
    readonly description: string
    readonly UOMStatus: UnitOfMeasureStatus
  }>
}

export type UpdateTenantDetailsMutationVariables = Exact<{
  name: Scalars['String']
  email?: InputMaybe<Scalars['String']>
  phoneNumber?: InputMaybe<Scalars['String']>
  address?: InputMaybe<InputAccountAddress>
}>

export type UpdateTenantDetailsMutation = {
  readonly __typename?: 'GqlMutation'
  readonly updateTenantDetails: { readonly __typename?: 'TenantDetails' } & TenantDetailsFragment
}

export type UpsertUnitOfMeasureMutationVariables = Exact<{
  name: Scalars['String']
  description?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['String']>
}>

export type UpsertUnitOfMeasureMutation = {
  readonly __typename?: 'GqlMutation'
  readonly upsertUnitOfMeasure: {
    readonly __typename?: 'UnitOfMeasure'
    readonly id: string
    readonly name: string
    readonly description: string
    readonly UOMStatus: UnitOfMeasureStatus
  }
}

export type DeleteUnitOfMeasureMutationVariables = Exact<{
  id: Scalars['String']
}>

export type DeleteUnitOfMeasureMutation = {
  readonly __typename?: 'GqlMutation'
  readonly deleteUnitOfMeasure: {
    readonly __typename?: 'UnitOfMeasure'
    readonly id: string
    readonly name: string
    readonly description: string
    readonly UOMStatus: UnitOfMeasureStatus
  }
}

export type GetTaxRatesQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>
}>

export type GetTaxRatesQuery = {
  readonly __typename?: 'GqlQuery'
  readonly taxRates: ReadonlyArray<{
    readonly __typename?: 'TaxRate'
    readonly id?: string | null
    readonly name: string
    readonly description?: string | null
    readonly taxCode: string
    readonly status: Status
    readonly taxInclusive: boolean
    readonly taxPercentage?: number | null
    readonly inUse?: boolean | null
  }>
}

export type UpsertTaxRatesMutationVariables = Exact<{
  name: Scalars['String']
  taxCode: Scalars['String']
  taxInclusive: Scalars['Boolean']
  taxPercentage?: InputMaybe<Scalars['BigDecimal']>
  description?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['String']>
}>

export type UpsertTaxRatesMutation = {
  readonly __typename?: 'GqlMutation'
  readonly upsertTaxRate: {
    readonly __typename?: 'TaxRate'
    readonly id?: string | null
    readonly name: string
    readonly description?: string | null
    readonly taxCode: string
    readonly taxPercentage?: number | null
    readonly status: Status
    readonly taxInclusive: boolean
    readonly inUse?: boolean | null
  }
}

export type DeleteTaxRateMutationVariables = Exact<{
  id: Scalars['String']
}>

export type DeleteTaxRateMutation = {
  readonly __typename?: 'GqlMutation'
  readonly deleteTaxRate: {
    readonly __typename?: 'TaxRate'
    readonly id?: string | null
    readonly name: string
    readonly description?: string | null
    readonly taxCode: string
    readonly status: Status
    readonly taxInclusive: boolean
    readonly inUse?: boolean | null
  }
}

export type UpdateDiscountStatusMutationVariables = Exact<{
  id: Scalars['String']
  status: DiscountStatus
}>

export type UpdateDiscountStatusMutation = {
  readonly __typename?: 'GqlMutation'
  readonly updateDiscountStatus: { readonly __typename?: 'DiscountJson' } & DiscountJsonFragment
}

export type UpsertDiscountMutationVariables = Exact<{
  discount: InputDiscountJson
}>

export type UpsertDiscountMutation = {
  readonly __typename?: 'GqlMutation'
  readonly upsertDiscount: { readonly __typename?: 'DiscountJson' } & DiscountJsonFragment
}

export type DeleteDiscountMutationVariables = Exact<{
  id: Scalars['String']
}>

export type DeleteDiscountMutation = {
  readonly __typename?: 'GqlMutation'
  readonly deleteDiscount: { readonly __typename?: 'DiscountJson' } & DiscountJsonFragment
}

export type UserGroupFragment = {
  readonly __typename?: 'UserGroupDetail'
  readonly description?: string | null
  readonly id: string
  readonly entityIds?: ReadonlyArray<string> | null
  readonly isDeleted: boolean
  readonly isUser: boolean
  readonly isRole: boolean
  readonly name: string
  readonly updatedOn: number
  readonly users: ReadonlyArray<({ readonly __typename?: 'User' } & UserFragment) | null>
}

export type AccountReceivableContactFragment = {
  readonly __typename?: 'AccountReceivableContactJson'
  readonly email: string
  readonly firstName: string
  readonly lastName?: string | null
  readonly phoneNumber?: string | null
  readonly address?: ({ readonly __typename?: 'AccountAddress' } & AccountAddressFragment) | null
}

export type ListUserGroupsQueryVariables = Exact<{ [key: string]: never }>

export type ListUserGroupsQuery = {
  readonly __typename?: 'GqlQuery'
  readonly userGroups: ReadonlyArray<{ readonly __typename?: 'UserGroupDetail' } & UserGroupFragment>
}

export type GetAccountReceivableContactQueryVariables = Exact<{ [key: string]: never }>

export type GetAccountReceivableContactQuery = {
  readonly __typename?: 'GqlQuery'
  readonly accountReceivableContactSetting?:
    | ({ readonly __typename?: 'AccountReceivableContactJson' } & AccountReceivableContactFragment)
    | null
}

export type UpsertUserGroupMutationVariables = Exact<{
  userGroup: InputUserGroupRequest
}>

export type UpsertUserGroupMutation = {
  readonly __typename?: 'GqlMutation'
  readonly upsertUserGroup: { readonly __typename?: 'UserGroupDetail' } & UserGroupFragment
}

export type DeleteUserGroupMutationVariables = Exact<{
  id: Scalars['String']
}>

export type DeleteUserGroupMutation = {
  readonly __typename?: 'GqlMutation'
  readonly deleteUserGroup: { readonly __typename?: 'UserGroupDetail' } & UserGroupFragment
}

export type UpdateAccountReceivableContactMutationVariables = Exact<{
  accountReceivableContact: InputAccountReceivableContactJson
}>

export type UpdateAccountReceivableContactMutation = {
  readonly __typename?: 'GqlMutation'
  readonly updateAccountReceivableContact: {
    readonly __typename?: 'AccountReceivableContactJson'
  } & AccountReceivableContactFragment
}

export type DunningSettingDetailsFragment = {
  readonly __typename?: 'DunningSettingDetails'
  readonly isEnabled?: boolean | null
  readonly dunningTypeStatus?: ReadonlyArray<{
    readonly __typename?: 'DunningTypeStatus'
    readonly dunningType: DunningReminderType
    readonly isEnabled: boolean
  } | null> | null
}

export type GetDunningSettingQueryVariables = Exact<{ [key: string]: never }>

export type GetDunningSettingQuery = {
  readonly __typename?: 'GqlQuery'
  readonly dunningSetting: { readonly __typename?: 'DunningSettingDetails' } & DunningSettingDetailsFragment
}

export type SendSampleDunningEmailQueryVariables = Exact<{
  dunningReminderType: DunningReminderType
}>

export type SendSampleDunningEmailQuery = { readonly __typename?: 'GqlQuery'; readonly sendSampleDunningEmail: boolean }

export type UpdateDunningSettingMutationVariables = Exact<{
  dunningSetting: InputDunningSettingDetails
}>

export type UpdateDunningSettingMutation = {
  readonly __typename?: 'GqlMutation'
  readonly updateDunningSetting: { readonly __typename?: 'DunningSettingDetails' } & DunningSettingDetailsFragment
}

export type GetTenantSignatoriesQueryVariables = Exact<{ [key: string]: never }>

export type GetTenantSignatoriesQuery = {
  readonly __typename?: 'GqlQuery'
  readonly tenantSignatories: ReadonlyArray<{
    readonly __typename?: 'tenantSignatory'
    readonly user: { readonly __typename?: 'User'; readonly entityIds?: ReadonlyArray<string> | null } & UserFragment
  } | null>
}

export type AddTenantSignatoryMutationVariables = Exact<{
  tenantSignatory: InputEsignTenantSignatory
}>

export type AddTenantSignatoryMutation = { readonly __typename?: 'GqlMutation'; readonly addTenantSignatory: boolean }

export type UpdatePaymentTermSettingsMutationVariables = Exact<{
  paymentTermSettings: InputPaymentTermSettings
}>

export type UpdatePaymentTermSettingsMutation = {
  readonly __typename?: 'GqlMutation'
  readonly updatePaymentTermSettings: {
    readonly __typename?: 'PaymentTermSettings'
    readonly defaultPaymentTerm: string
    readonly defaultPaymentTerms: ReadonlyArray<string>
    readonly customPaymentTermsAllowed: boolean
  }
}

export type GetOrderExpiryDurationQueryVariables = Exact<{ [key: string]: never }>

export type GetOrderExpiryDurationQuery = {
  readonly __typename?: 'GqlQuery'
  readonly orderExpiryDurationInDays?: number | null
}

export type UpdateOrderExpiryDurationMutationVariables = Exact<{
  orderExpiryDurationInDays?: InputMaybe<Scalars['String']>
}>

export type UpdateOrderExpiryDurationMutation = {
  readonly __typename?: 'GqlMutation'
  readonly updateOrderExpiryDurationInDays?: number | null
}

export type GetSigningOrderQueryVariables = Exact<{ [key: string]: never }>

export type GetSigningOrderQuery = { readonly __typename?: 'GqlQuery'; readonly signingOrder?: SigningOrder | null }

export type UpdateSigningOrderMutationVariables = Exact<{
  signingOrder: SigningOrder
}>

export type UpdateSigningOrderMutation = {
  readonly __typename?: 'GqlMutation'
  readonly updateSigningOrder?: SigningOrder | null
}

export type GetTimeZonesQueryVariables = Exact<{ [key: string]: never }>

export type GetTimeZonesQuery = {
  readonly __typename?: 'GqlQuery'
  readonly timezones: ReadonlyArray<{
    readonly __typename?: 'TimeZoneData'
    readonly zoneId: string
    readonly offset: string
    readonly isDefaultTimeZone: boolean
  }>
}

export type CreateMergeLinkTokenMutationVariables = Exact<{
  integrationType: IntegrationTargetService
}>

export type CreateMergeLinkTokenMutation = {
  readonly __typename?: 'GqlMutation'
  readonly createMergeLinkToken?: string | null
}

export type ProcessMergePublicTokenMutationVariables = Exact<{
  publicToken: Scalars['String']
  integrationType: IntegrationTargetService
}>

export type ProcessMergePublicTokenMutation = {
  readonly __typename?: 'GqlMutation'
  readonly processMergePublicToken?: boolean | null
}

export type SubscriptionChargeFragment = {
  readonly __typename?: 'SubscriptionCharge'
  readonly id: string
  readonly quantity: number
  readonly orderLines: ReadonlyArray<string | null>
  readonly startDate: number
  readonly endDate: number
  readonly listUnitPrice?: number | null
  readonly sellUnitPrice?: number | null
  readonly plan: {
    readonly __typename?: 'PlanDetail'
    readonly name: string
    readonly id: string
    readonly product: { readonly __typename?: 'Product'; readonly id: string; readonly name: string }
  }
  readonly charge: { readonly __typename?: 'Charge' } & ChargeFragment
  readonly chargeDetail: { readonly __typename?: 'ChargeDetail' } & ChargeDetailFragment
  readonly discounts?: ReadonlyArray<{
    readonly __typename?: 'Discount'
    readonly discountAmount?: number | null
    readonly name?: string | null
    readonly percent?: number | null
  } | null> | null
}

export type SubscriptionContactFragment = {
  readonly __typename?: 'AccountContact'
  readonly accountId?: string | null
  readonly email: string
  readonly externalId?: string | null
  readonly firstName: string
  readonly id?: string | null
  readonly lastName?: string | null
  readonly phoneNumber?: string | null
  readonly address?: {
    readonly __typename?: 'AccountAddress'
    readonly city: string
    readonly country: string
    readonly state?: string | null
    readonly streetAddressLine1: string
    readonly streetAddressLine2?: string | null
    readonly zipcode: string
  } | null
}

export type SubscriptionRenewDatesFragment = {
  readonly __typename?: 'Subscription'
  readonly renewedFromDate?: number | null
  readonly renewedToDate?: number | null
  readonly renewedFromSubscription?: { readonly __typename?: 'Subscription'; readonly id: string } | null
  readonly renewedToSubscription?: { readonly __typename?: 'Subscription'; readonly id: string } | null
}

export type SubscriptionRestructureDatesFragment = {
  readonly __typename?: 'Subscription'
  readonly restructuredFromDate?: number | null
  readonly restructuredToDate?: number | null
  readonly restructuredFromSubscription?: { readonly __typename?: 'Subscription'; readonly id: string } | null
  readonly restructuredToSubscription?: { readonly __typename?: 'Subscription'; readonly id: string } | null
}

export type SubscriptionFragment = {
  readonly __typename?: 'Subscription'
  readonly id: string
  readonly version: number
  readonly entityId?: string | null
  readonly billingAnchorDate?: number | null
  readonly creationTime: number
  readonly currency?: string | null
  readonly startDate: number
  readonly endDate: number
  readonly orders: ReadonlyArray<string | null>
  readonly paymentTerm?: string | null
  readonly state: SubscriptionState
  readonly canceledDate?: number | null
  readonly purchaseOrderRequiredForInvoicing?: boolean | null
  readonly autoRenew?: boolean | null
  readonly activationDate?: number | null
  readonly account: {
    readonly __typename?: 'Account'
    readonly id?: string | null
    readonly name: string
    readonly currency?: string | null
  }
  readonly resoldBy?: { readonly __typename?: 'Account'; readonly id?: string | null; readonly name: string } | null
  readonly billingContact: { readonly __typename?: 'AccountContact' } & AccountContactFragment
  readonly billingCycle: { readonly __typename?: 'Recurrence'; readonly cycle: Cycle; readonly step: number }
  readonly termLength?: { readonly __typename?: 'Recurrence'; readonly cycle: Cycle; readonly step: number } | null
  readonly charges: ReadonlyArray<({ readonly __typename?: 'SubscriptionCharge' } & SubscriptionChargeFragment) | null>
  readonly shippingContact: { readonly __typename?: 'AccountContact' } & AccountContactFragment
  readonly subscriptionMetrics: { readonly __typename?: 'AccountMetrics' } & MetricsFragment
  readonly mailingContacts?: ReadonlyArray<{ readonly __typename?: 'AccountContact' } & AccountContactFragment> | null
  readonly purchaseOrders?: ReadonlyArray<{
    readonly __typename?: 'PurchaseOrder'
    readonly purchaseOrderNumber: string
    readonly originOrderId?: string | null
    readonly addedOn: number
  } | null> | null
  readonly emailNotifiersList: {
    readonly __typename?: 'EmailNotifiersDetail'
    readonly toContacts: ReadonlyArray<
      ({ readonly __typename?: 'EmailNotifierContact' } & EmailNotifierFragment) | null
    >
    readonly bccContacts: ReadonlyArray<
      ({ readonly __typename?: 'EmailNotifierContact' } & EmailNotifierFragment) | null
    >
    readonly ccContacts: ReadonlyArray<
      ({ readonly __typename?: 'EmailNotifierContact' } & EmailNotifierFragment) | null
    >
  }
} & SubscriptionRenewDatesFragment &
  SubscriptionRestructureDatesFragment

export type GetSubscriptionQueryVariables = Exact<{
  id: Scalars['String']
}>

export type GetSubscriptionQuery = {
  readonly __typename?: 'GqlQuery'
  readonly subscriptions: ReadonlyArray<{ readonly __typename?: 'Subscription' } & SubscriptionFragment>
}

export type BillingEventEntryFragment = {
  readonly __typename?: 'BillingEventEntry'
  readonly amount: number
  readonly createdOn: number
  readonly id: string
  readonly triggerOn: number
}

export type GetBillingEventsQueryVariables = Exact<{
  subscriptionId: Scalars['String']
  subscriptionChargeId: Scalars['String']
}>

export type GetBillingEventsQuery = {
  readonly __typename?: 'GqlQuery'
  readonly billingEvents: {
    readonly __typename?: 'BillingEvents'
    readonly remainingAmount: number
    readonly entries: ReadonlyArray<{ readonly __typename?: 'BillingEventEntry' } & BillingEventEntryFragment>
  }
}

export type GenerateInvoiceMutationVariables = Exact<{
  id: Scalars['String']
  targetDate: Scalars['Long']
  invoiceDate?: InputMaybe<Scalars['Long']>
  includeUsage: InvoiceChargeInclusionOption
}>

export type GenerateInvoiceMutation = {
  readonly __typename?: 'GqlMutation'
  readonly generateInvoice?: { readonly __typename?: 'Invoice'; readonly invoiceNumber: string } | null
}

export type SubscriptionChargeAliasFragment = {
  readonly __typename?: 'SubscriptionChargeAlias'
  readonly aliasId?: string | null
  readonly chargeId?: string | null
  readonly subscriptionId?: string | null
}

export type GetSubscriptionChargeAliasQueryVariables = Exact<{
  subscriptionId: Scalars['String']
  chargeId: Scalars['String']
}>

export type GetSubscriptionChargeAliasQuery = {
  readonly __typename?: 'GqlQuery'
  readonly aliasesBySubscriptionIdAndChargeId?: ReadonlyArray<
    { readonly __typename?: 'SubscriptionChargeAlias' } & SubscriptionChargeAliasFragment
  > | null
}

export type DeleteSubscriptionChargeAliasMutationVariables = Exact<{
  aliasId: Scalars['String']
}>

export type DeleteSubscriptionChargeAliasMutation = {
  readonly __typename?: 'GqlMutation'
  readonly deleteSubscriptionChargeAlias: {
    readonly __typename?: 'SubscriptionChargeAlias'
  } & SubscriptionChargeAliasFragment
}

export type AddSubscriptionChargeAliasMutationVariables = Exact<{
  aliasId: Scalars['String']
  subscriptionId: Scalars['String']
  chargeId: Scalars['String']
}>

export type AddSubscriptionChargeAliasMutation = {
  readonly __typename?: 'GqlMutation'
  readonly addSubscriptionChargeAlias: {
    readonly __typename?: 'SubscriptionChargeAlias'
  } & SubscriptionChargeAliasFragment
}

export type UpdateSubscriptionAttributesMutationVariables = Exact<{
  subscriptionId: Scalars['String']
  subscriptionUpdate: InputSubscriptionUpdate
}>

export type UpdateSubscriptionAttributesMutation = {
  readonly __typename?: 'GqlMutation'
  readonly updateSubscriptionAttributes: { readonly __typename?: 'Subscription' } & SubscriptionFragment
}

export type AddBillingEventMutationVariables = Exact<{
  billingEventInput: InputBillingEventInput
}>

export type AddBillingEventMutation = {
  readonly __typename?: 'GqlMutation'
  readonly addBillingEvent: {
    readonly __typename?: 'BillingEvents'
    readonly remainingAmount: number
    readonly entries: ReadonlyArray<{ readonly __typename?: 'BillingEventEntry' } & BillingEventEntryFragment>
  }
}

export type DeletBillingEventMutationVariables = Exact<{
  id: Scalars['String']
}>

export type DeletBillingEventMutation = {
  readonly __typename?: 'GqlMutation'
  readonly deleteBillingEvent: {
    readonly __typename?: 'BillingEvents'
    readonly remainingAmount: number
    readonly entries: ReadonlyArray<{ readonly __typename?: 'BillingEventEntry' } & BillingEventEntryFragment>
  }
}

export type DeleteSubscriptionMutationVariables = Exact<{
  id: Scalars['String']
  version: Scalars['Int']
}>

export type DeleteSubscriptionMutation = { readonly __typename?: 'GqlMutation'; readonly deleteSubscription: boolean }

export type RevertSubscriptionMutationVariables = Exact<{
  id: Scalars['String']
  version: Scalars['Int']
}>

export type RevertSubscriptionMutation = { readonly __typename?: 'GqlMutation'; readonly revertSubscription: boolean }

export type AddRawUsageMutationVariables = Exact<{
  rawUsagesData: InputRawUsagesData
}>

export type AddRawUsageMutation = {
  readonly __typename?: 'GqlMutation'
  readonly addRawUsages: {
    readonly __typename?: 'UsageBatchInsertResult'
    readonly rawUsagesTotal: number
    readonly totalFailed: number
    readonly totalDuplicates: number
    readonly entries?: ReadonlyArray<{
      readonly __typename?: 'UsageEntry'
      readonly rowNumber: number
      readonly failed: boolean
      readonly failureReason?: string | null
    }> | null
  }
}

export type GetActiveSubscriptionsByAccountCrmIdQueryVariables = Exact<{
  accountCrmId: Scalars['String']
}>

export type GetActiveSubscriptionsByAccountCrmIdQuery = {
  readonly __typename?: 'GqlQuery'
  readonly activeSubscriptionsByAccountCrmId: ReadonlyArray<string | null>
}

export type SubscriptionModifiableQueryVariables = Exact<{
  id: Scalars['String']
}>

export type SubscriptionModifiableQuery = {
  readonly __typename?: 'GqlQuery'
  readonly subscriptionModifiable: {
    readonly __typename?: 'SubscriptionModifiableResponse'
    readonly modifiable: boolean
    readonly message: string
  }
}

export type SubscriptionReversibleQueryVariables = Exact<{
  id: Scalars['String']
}>

export type SubscriptionReversibleQuery = {
  readonly __typename?: 'GqlQuery'
  readonly subscriptionReversible: {
    readonly __typename?: 'SubscriptionModifiableResponse'
    readonly modifiable: boolean
    readonly message: string
  }
}

export type GetErpInvoiceBySubscriptionIdQueryVariables = Exact<{
  subscriptionId: Scalars['String']
}>

export type GetErpInvoiceBySubscriptionIdQuery = {
  readonly __typename?: 'GqlQuery'
  readonly erpInvoicesBySubscriptionId: ReadonlyArray<{ readonly __typename?: 'ErpInvoice' } & ErpInvoiceFragment>
}

export type TaxJarIntegrationFragment = {
  readonly __typename?: 'TaxJarIntegration'
  readonly maskedApiKey: string
  readonly integrationId: string
  readonly isSandbox?: boolean | null
  readonly isDeleted: boolean
  readonly createdOn: number
  readonly updatedOn: number
}

export type GetTaxJarIntegrationQueryVariables = Exact<{ [key: string]: never }>

export type GetTaxJarIntegrationQuery = {
  readonly __typename?: 'GqlQuery'
  readonly taxJarIntegration: { readonly __typename?: 'TaxJarIntegration' } & TaxJarIntegrationFragment
}

export type TestTaxJarIntegrationMutationVariables = Exact<{
  apiKey: Scalars['String']
  isSandbox: Scalars['Boolean']
}>

export type TestTaxJarIntegrationMutation = {
  readonly __typename?: 'GqlMutation'
  readonly testTaxJarIntegration: boolean
}

export type UpsertTaxJarIntegrationMutationVariables = Exact<{
  apiKey: Scalars['String']
  isSandbox: Scalars['Boolean']
}>

export type UpsertTaxJarIntegrationMutation = {
  readonly __typename?: 'GqlMutation'
  readonly upsertTaxJarIntegration: { readonly __typename?: 'TaxJarIntegration' } & TaxJarIntegrationFragment
}

export type HasTaxJarIntegrationQueryVariables = Exact<{ [key: string]: never }>

export type HasTaxJarIntegrationQuery = { readonly __typename?: 'GqlQuery'; readonly hasTaxJarIntegration: boolean }

export type UpsertTemplateMutationVariables = Exact<{
  type: DocumentTemplateType
  name: Scalars['String']
  content: Scalars['String']
  description?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['String']>
  sectionUuid?: InputMaybe<Scalars['String']>
  entityIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>
  isUserSelectable?: InputMaybe<Scalars['Boolean']>
}>

export type UpsertTemplateMutation = {
  readonly __typename?: 'GqlMutation'
  readonly upsertDocumentTemplate: {
    readonly __typename?: 'DocumentTemplate'
    readonly id: string
    readonly type: DocumentTemplateType
    readonly name: string
    readonly description?: string | null
    readonly content: string
    readonly sectionUuid?: string | null
    readonly entityIds?: ReadonlyArray<string> | null
  }
}

export type DeleteTemplateMutationVariables = Exact<{
  id: Scalars['String']
}>

export type DeleteTemplateMutation = {
  readonly __typename?: 'GqlMutation'
  readonly deleteDocumentTemplate: {
    readonly __typename?: 'DocumentTemplate'
    readonly id: string
    readonly name: string
    readonly type: DocumentTemplateType
    readonly description?: string | null
    readonly content: string
    readonly sectionUuid?: string | null
  }
}

export type ListTemplatesByTypeQueryVariables = Exact<{
  type?: InputMaybe<Scalars['String']>
}>

export type ListTemplatesByTypeQuery = {
  readonly __typename?: 'GqlQuery'
  readonly documentTemplates: ReadonlyArray<{ readonly __typename?: 'DocumentTemplate' } & DocumentTemplateFragment>
}

export type GetTemplateWithContentQueryVariables = Exact<{
  id: Scalars['String']
}>

export type GetTemplateWithContentQuery = {
  readonly __typename?: 'GqlQuery'
  readonly documentTemplate: { readonly __typename?: 'DocumentTemplate' } & DocumentTemplateFragment
}

export type DocumentMasterTemplateFragment = {
  readonly __typename?: 'DocumentMasterTemplate'
  readonly content: string
  readonly isFullHtml?: boolean | null
  readonly id?: string | null
  readonly inUse?: boolean | null
  readonly isDefault?: boolean | null
  readonly name: string
  readonly status?: DocumentMasterTemplateStatus | null
  readonly type: DocumentTemplateType
  readonly entityIds?: ReadonlyArray<string> | null
  readonly configuration?: {
    readonly __typename?: 'DocumentMasterTemplateConfiguration'
    readonly contractTermsColumns: ReadonlyArray<ContractTermsColumn | null>
    readonly contractTermsTableStyle: ContractTermsTableStyle
    readonly fontFamily: string
    readonly fontSize: string
    readonly headerStyle: HeaderStyle
    readonly lineItemColumns: ReadonlyArray<LineItemColumn | null>
    readonly lineItemTableStyle: LineItemTableStyle
    readonly primaryColor: string
    readonly secondaryColor: string
    readonly showLineBreakupByPredefinedDiscounts?: boolean | null
    readonly showArrByYears: boolean
    readonly showBreakupByBillingCycle: boolean
    readonly showNextInvoiceAmount: boolean
    readonly version: number
  } | null
}

export type GetDocumentMasterTemplatesQueryVariables = Exact<{ [key: string]: never }>

export type GetDocumentMasterTemplatesQuery = {
  readonly __typename?: 'GqlQuery'
  readonly documentMasterTemplates: ReadonlyArray<{
    readonly __typename?: 'DocumentMasterTemplate'
    readonly id?: string | null
    readonly type: DocumentTemplateType
    readonly name: string
    readonly isDefault?: boolean | null
    readonly status?: DocumentMasterTemplateStatus | null
    readonly entityIds?: ReadonlyArray<string> | null
  }>
}

export type GetDocumentMasterTemplateQueryVariables = Exact<{
  id: Scalars['String']
}>

export type GetDocumentMasterTemplateQuery = {
  readonly __typename?: 'GqlQuery'
  readonly documentMasterTemplate: { readonly __typename?: 'DocumentMasterTemplate' } & DocumentMasterTemplateFragment
}

export type UpsertDocumentMasterTemplateMutationVariables = Exact<{
  documentMasterTemplate: InputDocumentMasterTemplate
}>

export type UpsertDocumentMasterTemplateMutation = {
  readonly __typename?: 'GqlMutation'
  readonly upsertDocumentMasterTemplate: {
    readonly __typename?: 'DocumentMasterTemplate'
  } & DocumentMasterTemplateFragment
}

export type UpsertDocumentMasterTemplateV2MutationVariables = Exact<{
  documentMasterTemplate: InputDocumentMasterTemplate
}>

export type UpsertDocumentMasterTemplateV2Mutation = {
  readonly __typename?: 'GqlMutation'
  readonly upsertDocumentMasterTemplateV2: {
    readonly __typename?: 'DocumentMasterTemplate'
    readonly isFullHtml?: boolean | null
  } & DocumentMasterTemplateFragment
}

export type UpdateDocumentMasterTemplateStatusMutationVariables = Exact<{
  id: Scalars['String']
  status: DocumentMasterTemplateStatus
}>

export type UpdateDocumentMasterTemplateStatusMutation = {
  readonly __typename?: 'GqlMutation'
  readonly updateDocumentMasterTemplateStatus: {
    readonly __typename?: 'DocumentMasterTemplate'
  } & DocumentMasterTemplateFragment
}

export type DeleteDocumentMasterTemplateMutationVariables = Exact<{
  id: Scalars['String']
}>

export type DeleteDocumentMasterTemplateMutation = {
  readonly __typename?: 'GqlMutation'
  readonly deleteDocumentMasterTemplate: {
    readonly __typename?: 'DocumentMasterTemplate'
  } & DocumentMasterTemplateFragment
}

export type DocumentSectionFragment = {
  readonly __typename?: 'DocumentSection'
  readonly id?: string | null
  readonly name: string
  readonly title: string
  readonly location: DocumentSectionLocation
  readonly entityIds?: ReadonlyArray<string> | null
}

export type GetDocumentSectionsQueryVariables = Exact<{ [key: string]: never }>

export type GetDocumentSectionsQuery = {
  readonly __typename?: 'GqlQuery'
  readonly documentSections: ReadonlyArray<{ readonly __typename?: 'DocumentSection' } & DocumentSectionFragment>
}

export type UpsertDocumentSectionMutationVariables = Exact<{
  documentSection: InputDocumentSection
}>

export type UpsertDocumentSectionMutation = {
  readonly __typename?: 'GqlMutation'
  readonly upsertDocumentSection: { readonly __typename?: 'DocumentSection' } & DocumentSectionFragment
}

export type UpdateDocumentTemplateStatusMutationVariables = Exact<{
  templateId: Scalars['String']
  status: DocumentTemplateStatus
}>

export type UpdateDocumentTemplateStatusMutation = {
  readonly __typename?: 'GqlMutation'
  readonly updateDocumentTemplateStatus: { readonly __typename?: 'DocumentTemplate' } & DocumentTemplateFragment
}

export type GetPreviewMasterTemplateQueryVariables = Exact<{
  orderId: Scalars['String']
  masterTemplateId: Scalars['String']
}>

export type GetPreviewMasterTemplateQuery = { readonly __typename?: 'GqlQuery'; readonly previewMasterTemplate: string }

export type GetPreviewDocumentTemplateQueryVariables = Exact<{
  orderId: Scalars['String']
  templateId: Scalars['String']
}>

export type GetPreviewDocumentTemplateQuery = {
  readonly __typename?: 'GqlQuery'
  readonly previewDocumentTemplate: string
}

export type TenantJobFieldsFragment = {
  readonly __typename?: 'TenantJob'
  readonly id?: string | null
  readonly jobType?: TenantJobType | null
  readonly module?: TenantJobModule | null
  readonly objectModel?: TenantJobObjectModel | null
  readonly objectId?: string | null
  readonly attempts?: number | null
  readonly status?: TenantJobStatus | null
  readonly failureReason?: string | null
  readonly startedOn?: string | null
  readonly endedOn?: string | null
  readonly createdOn?: string | null
}

export type GetTenantJobQueryVariables = Exact<{
  id: Scalars['String']
}>

export type GetTenantJobQuery = {
  readonly __typename?: 'GqlQuery'
  readonly tenantJob: { readonly __typename?: 'TenantJob' } & TenantJobFieldsFragment
}

export type GetTenantJobAsAdminQueryVariables = Exact<{
  id: Scalars['String']
  tenantId: Scalars['String']
}>

export type GetTenantJobAsAdminQuery = {
  readonly __typename?: 'GqlQuery'
  readonly tenantJobAsAdmin: { readonly __typename?: 'TenantJob' } & TenantJobFieldsFragment
}

export type RetryTenantJobAsAdminMutationVariables = Exact<{
  id: Scalars['String']
  tenantId: Scalars['String']
}>

export type RetryTenantJobAsAdminMutation = {
  readonly __typename?: 'GqlMutation'
  readonly retryTenantJobAsAdmin: { readonly __typename?: 'TenantJob' } & TenantJobFieldsFragment
}

export type GetActiveErpSyncJobsQueryVariables = Exact<{ [key: string]: never }>

export type GetActiveErpSyncJobsQuery = {
  readonly __typename?: 'GqlQuery'
  readonly activeErpSyncTasks: ReadonlyArray<{ readonly __typename?: 'TenantJob' } & TenantJobFieldsFragment>
}

export type UserFragment = {
  readonly __typename?: 'User'
  readonly id: string
  readonly displayName: string
  readonly title?: string | null
  readonly email: string
  readonly phoneNumber?: string | null
  readonly role: Role
  readonly state: Status
  readonly ssoOnly?: boolean | null
  readonly cognitoUserStatus?: UserStatusType | null
}

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never }>

export type GetCurrentUserQuery = {
  readonly __typename?: 'GqlQuery'
  readonly currentUser: {
    readonly __typename?: 'User'
    readonly id: string
    readonly displayName: string
    readonly email: string
    readonly role: Role
    readonly state: Status
    readonly ssoOnly?: boolean | null
  }
}

export type GetUserQueryVariables = Exact<{
  id: Scalars['String']
}>

export type GetUserQuery = {
  readonly __typename?: 'GqlQuery'
  readonly user: {
    readonly __typename?: 'User'
    readonly hasAllEntitiesAccess?: boolean | null
    readonly availableEntities?: ReadonlyArray<{ readonly __typename?: 'Entity' } & EntityFragment> | null
  } & UserFragment
}

export type GetIsUserLoggedInQueryVariables = Exact<{ [key: string]: never }>

export type GetIsUserLoggedInQuery = { readonly __typename?: 'GqlQuery'; readonly userLoggedIn: boolean }

export type GetAssociatedUsersQueryVariables = Exact<{ [key: string]: never }>

export type GetAssociatedUsersQuery = {
  readonly __typename?: 'GqlQuery'
  readonly associatedUsers: ReadonlyArray<{
    readonly __typename?: 'User'
    readonly id: string
    readonly displayName: string
    readonly title?: string | null
    readonly email: string
    readonly tenantName?: string | null
  }>
}

export type UpsertUserMutationVariables = Exact<{
  user: InputUser
}>

export type UpsertUserMutation = {
  readonly __typename?: 'GqlMutation'
  readonly upsertUser: { readonly __typename?: 'User' } & UserFragment
}

export type UpsertUserV2MutationVariables = Exact<{
  user: InputUserInput
}>

export type UpsertUserV2Mutation = {
  readonly __typename?: 'GqlMutation'
  readonly upsertUserV2: {
    readonly __typename?: 'User'
    readonly hasAllEntitiesAccess?: boolean | null
    readonly availableEntities?: ReadonlyArray<{ readonly __typename?: 'Entity' } & EntityFragment> | null
  } & UserFragment
}

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['String']
}>

export type DeleteUserMutation = {
  readonly __typename?: 'GqlMutation'
  readonly deleteUser: { readonly __typename?: 'User' } & UserFragment
}

export type EnableUserMutationVariables = Exact<{
  id: Scalars['String']
}>

export type EnableUserMutation = {
  readonly __typename?: 'GqlMutation'
  readonly enableUser: { readonly __typename?: 'User' } & UserFragment
}

export type DisableUserMutationVariables = Exact<{
  id: Scalars['String']
}>

export type DisableUserMutation = {
  readonly __typename?: 'GqlMutation'
  readonly disableUser: { readonly __typename?: 'User' } & UserFragment
}

export type ResendCognitoInvitationEmailForExistingUserMutationVariables = Exact<{
  email: Scalars['String']
}>

export type ResendCognitoInvitationEmailForExistingUserMutation = {
  readonly __typename?: 'GqlMutation'
  readonly resendCognitoInvitationEmailForExistingUser: boolean
}

export type UiElementConfigFragment = { readonly __typename?: 'UIElement'; readonly name?: string | null }

export type UiConfigurationFragment = {
  readonly __typename?: 'UIConfiguration'
  readonly orderPageConfiguration?: {
    readonly __typename?: 'OrderPageConfiguration'
    readonly fields?: ReadonlyArray<({ readonly __typename?: 'UIElement' } & UiElementConfigFragment) | null> | null
    readonly orderLineItemsConfiguration?: {
      readonly __typename?: 'OrderLineItemsConfiguration'
      readonly fields?: ReadonlyArray<({ readonly __typename?: 'UIElement' } & UiElementConfigFragment) | null> | null
      readonly customFields?: ReadonlyArray<
        ({ readonly __typename?: 'UIElement' } & UiElementConfigFragment) | null
      > | null
    } | null
  } | null
}

export type GetUserTenantSessionQueryVariables = Exact<{ [key: string]: never }>

export type GetUserTenantSessionQuery = {
  readonly __typename?: 'GqlQuery'
  readonly setting: { readonly __typename?: 'Setting'; readonly defaultTimeZone?: string | null }
  readonly entities: ReadonlyArray<{
    readonly __typename?: 'EntityJson'
    readonly id?: string | null
    readonly name: string
    readonly displayId?: string | null
  }>
  readonly currentUser: {
    readonly __typename?: 'User'
    readonly id: string
    readonly displayName: string
    readonly email: string
    readonly role: Role
    readonly state: Status
    readonly hasAllEntitiesAccess?: boolean | null
    readonly availableEntities?: ReadonlyArray<{ readonly __typename?: 'Entity' } & EntityFragment> | null
  }
  readonly currentTenant: { readonly __typename?: 'Tenant' } & TenantFragment
  readonly dynamicFeatureListV2: ReadonlyArray<{
    readonly __typename?: 'FeatureSetting'
    readonly enabled: boolean
    readonly name: string
  }>
  readonly uiConfiguration?: ({ readonly __typename?: 'UIConfiguration' } & UiConfigurationFragment) | null
  readonly enabledPlatformFeatures: ReadonlyArray<{
    readonly __typename?: 'EnabledPlatformFeature'
    readonly enabledAt?: number | null
    readonly enabledByUserId?: string | null
    readonly platformFeature?: PlatformFeature | null
  }>
}

export const AccountAddressFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountAddressFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetAddressLine1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetAddressLine2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zipcode' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountAddressFragment, unknown>
export const AccountCoreFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountCore' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountAddressFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'crmId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'erpId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inUse' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isReseller' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taxExemptionUseCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'excludeFromBatchOperations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'excludeFromDunning' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasAutomaticPayment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'supportedPaymentTypes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entityIds' } },
        ],
      },
    },
    ...AccountAddressFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<AccountCoreFragment, unknown>
export const SalesforceAccountBillingAddressFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesforceAccountBillingAddressFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SalesforceAccountBillingAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'street' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SalesforceAccountBillingAddressFragment, unknown>
export const SalesforceContactFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesforceContactFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SalesforceContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'action' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mailingCity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mailingCountry' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mailingPostalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mailingState' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mailingStreet' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SalesforceContactFragment, unknown>
export const AccountContactFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountContactFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'streetAddressLine1' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetAddressLine2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zipcode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountContactFragment, unknown>
export const CrmContactFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CrmContactFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CrmContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'action' } },
          { kind: 'Field', name: { kind: 'Name', value: 'crmType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mailingCity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mailingCountry' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mailingPostalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mailingState' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mailingStreet' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CrmContactFragment, unknown>
export const UpsertCrmContactResponseFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UpsertCRMContactResponseFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UpsertCRMContactResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'error' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesforceContact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SalesforceContactFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'upserted' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertedContact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountContactFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crmContact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CrmContactFragment' } }],
            },
          },
        ],
      },
    },
    ...SalesforceContactFragmentDoc.definitions,
    ...AccountContactFragmentDoc.definitions,
    ...CrmContactFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpsertCrmContactResponseFragment, unknown>
export const LedgerAccountFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LedgerAccountFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'LedgerAccount' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inUse' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDefault' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LedgerAccountFragment, unknown>
export const UserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ssoOnly' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cognitoUserStatus' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserFragment, unknown>
export const AccountingPeriodDetailFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountingPeriodDetailFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountingPeriod' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calculation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'deferredRevenueStartingBalance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalRevenue' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deferredRevenueEndingBalance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recognizedRevenue' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unrecognizedRevenue' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deferredRevenueEndingBalance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unrecognizedTransactionCount' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'openedByUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'closedByUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserFragment' } }],
            },
          },
        ],
      },
    },
    ...UserFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<AccountingPeriodDetailFragment, unknown>
export const PaymentBankAccountFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaymentBankAccountFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentBankAccountJson' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cashLedgerAccountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entityIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expenseLedgerAccountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PaymentBankAccountFragment, unknown>
export const TenantFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TenantFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Tenant' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDeleted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasSalesforceIntegration' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasHubSpotIntegration' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasSso' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TenantFragment, unknown>
export const TenantCreationResponseJsonFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TenantCreationResponseJsonFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TenantCreationResponseJson' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'tenantId' } }],
      },
    },
  ],
} as unknown as DocumentNode<TenantCreationResponseJsonFragment, unknown>
export const AnrokIntegrationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AnrokIntegrationFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Integration' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'environment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maskedApiKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'realmId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetService' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedOn' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AnrokIntegrationFragment, unknown>
export const ApiKeyFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ApiKeyFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ApiKeyDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'generatedBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deactivatedBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiresOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdOn' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApiKeyFragment, unknown>
export const ApprovalStateFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ApprovalStateFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ApprovalStateDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approvalGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isUser' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isRole' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserFragment' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...UserFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ApprovalStateFragment, unknown>
export const ApprovalFlowFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ApprovalFlowFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ApprovalFlowDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entityIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'states' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'action' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'emailGroupId' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'approvalGroup' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isDeleted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isUser' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRole' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedOn' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'users' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transitionRules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'condition' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fromState' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ApprovalStateFragment' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ruleConditions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'orderCondition' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderLineCondition' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'toState' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ApprovalStateFragment' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ApprovalStateFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ApprovalFlowFragment, unknown>
export const ApprovalWithCodeResponseFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ApprovalWithCodeResponseFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ApprovalWithCodeResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApprovalWithCodeResponseFragment, unknown>
export const AttachmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AttachmentFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Attachment' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdOn' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AttachmentFragment, unknown>
export const BulkInvoiceRunFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BulkInvoiceRunFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BulkInvoiceRun' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bulkInvoiceRunId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invoiceDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'chargeInclusionOption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phase' } },
          { kind: 'Field', name: { kind: 'Name', value: 'failureReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedOn' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BulkInvoiceRunFragment, unknown>
export const AccountFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Account' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'crmId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasAutomaticPayment' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountFragment, unknown>
export const BillingCycleFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BillingCycleFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Recurrence' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cycle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'step' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BillingCycleFragment, unknown>
export const CancelAndRestructureSubscriptionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CancelAndRestructureSubscriptionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subscription' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'charges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CancelAndRestructureSubscriptionFragment, unknown>
export const DocumentTemplateFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DocumentTemplateFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DocumentTemplate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inUse' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isUserSelectable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plans' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sectionUuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entityIds' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DocumentTemplateFragment, unknown>
export const CustomFieldEntryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomFieldEntryFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomFieldEntry' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'options' } },
          { kind: 'Field', name: { kind: 'Name', value: 'required' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selections' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultValue' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'selections' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CustomFieldEntryFragment, unknown>
export const LineItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LineItemFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OrderLineItemDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'action' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDryRunItem' } },
          { kind: 'Field', name: { kind: 'Name', value: 'itemGroupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'discountAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'percent' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'discountAmount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'charge' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'chargeModel' } },
                { kind: 'Field', name: { kind: 'Name', value: 'defaultQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCustom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isListPriceEditable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'durationInMonths' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'priceTiers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'untilQuantity' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'rateCardId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recognitionRuleId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unitOfMeasureId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minimumCommitBaseChargeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shouldTrackArr' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chargeDetail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recognitionRule' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'recognitionType' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CustomFieldEntryFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'effectiveDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'predefinedDiscounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'percent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'templateIds' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'listUnitPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'listAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellUnitPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'annualizedAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'listPriceOverrideRatio' } },
          { kind: 'Field', name: { kind: 'Name', value: 'listUnitPriceBeforeOverride' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subscriptionChargeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subscriptionChargeGroupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attributeReferences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'attributeDefinitionId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'attributeValue' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'metrics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'arr' } },
                { kind: 'Field', name: { kind: 'Name', value: 'arrWithoutOverride' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'arrOverride' } },
        ],
      },
    },
    ...CustomFieldEntryFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<LineItemFragment, unknown>
export const DiscountFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DiscountFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PredefinedDiscountDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DiscountFragment, unknown>
export const OrderLevelCustomFieldEntryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderLevelCustomFieldEntryFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomFieldEntry' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'options' } },
          { kind: 'Field', name: { kind: 'Name', value: 'required' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selections' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultValue' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'selections' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OrderLevelCustomFieldEntryFragment, unknown>
export const CancelAndRestructureOrderDetailFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CancelAndRestructureOrderDetailFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CancelAndRestructureOrder' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'autoRenew' } },
          { kind: 'Field', name: { kind: 'Name', value: 'billingAnchorDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingContact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountContactFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingCycle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BillingCycleFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'billingTerm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'crmOpportunityId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentSubscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CancelAndRestructureSubscriptionFragment' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'documentMasterTemplateId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderFormTemplates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DocumentTemplateFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'documentCustomContent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lineItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LineItemFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'effectiveDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentTerm' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'predefinedDiscounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DiscountFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'purchaseOrderNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'purchaseOrderRequiredForInvoicing' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rampInterval' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resoldBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shippingContact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountContactFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'termLength' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cycle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'step' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compositeOrderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'arr' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deltaArr' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tcv' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nonRecurringTotal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recurringTotal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'crmOpportunityId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'crmOpportunityType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'crmOpportunityName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'crmOpportunityStage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrderLevelCustomFieldEntryFragment' } },
              ],
            },
          },
        ],
      },
    },
    ...AccountFragmentDoc.definitions,
    ...AccountContactFragmentDoc.definitions,
    ...BillingCycleFragmentDoc.definitions,
    ...CancelAndRestructureSubscriptionFragmentDoc.definitions,
    ...DocumentTemplateFragmentDoc.definitions,
    ...LineItemFragmentDoc.definitions,
    ...DiscountFragmentDoc.definitions,
    ...OrderLevelCustomFieldEntryFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<CancelAndRestructureOrderDetailFragment, unknown>
export const BillyChartDataValueFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BillyChartDataValueFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BillyChartDataValue' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'mainNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'crossNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'crossText' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BillyChartDataValueFragment, unknown>
export const BillyChartDatasetFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BillyChartDatasetFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BillyChartDataset' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BillyChartDataValueFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
        ],
      },
    },
    ...BillyChartDataValueFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<BillyChartDatasetFragment, unknown>
export const CompositeOrderDetailFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompositeOrderDetailFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CompositeOrder' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'crmOpportunityId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrimaryCompositeOrderForCrmOpportunity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
        ],
      },
    },
    ...AccountFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<CompositeOrderDetailFragment, unknown>
export const CreditMemoPlanFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditMemoPlanFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Plan' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreditMemoPlanFragment, unknown>
export const ChargeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChargeFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Charge' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'chargeModel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentDerivedFrom' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDrawdown' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRenewable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isCustom' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEventBased' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isListPriceEditable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'durationInMonths' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minQuantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultQuantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxQuantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'erpId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'itemCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minimumCommitBaseChargeId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'priceTiers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'untilQuantity' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recurrence' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cycle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'step' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ledgerAccountMapping' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'contractAssetAccountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deferredRevenueAccountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recognizedRevenueAccountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxLiabilityAccountId' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'shouldTrackArr' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChargeFragment, unknown>
export const RecognitionRuleFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecognitionRuleFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RecognitionRule' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recognitionType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recognitionEventType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distributionMethod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recognitionDateAlignment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deferredRevenueAccountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recognizedRevenueAccountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inUse' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entityIds' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RecognitionRuleFragment, unknown>
export const ChargeDetailFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChargeDetailFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChargeDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'chargeModel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentDerivedFrom' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetPlanIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDrawdown' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overageBaseChargeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minimumCommitBaseChargeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRenewable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isCustom' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isListPriceEditable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEventBased' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minQuantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultQuantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxQuantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'erpId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'itemCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rateCardId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'billingTerm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'billingCycle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shouldTrackArr' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taxRate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'priceTiers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'untilQuantity' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recurrence' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cycle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'step' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'durationInMonths' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unitOfMeasure' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ledgerAccountMapping' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'contractAssetAccountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deferredRevenueAccountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recognizedRevenueAccountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxLiabilityAccountId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recognitionRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RecognitionRuleFragment' } }],
            },
          },
        ],
      },
    },
    ...RecognitionRuleFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ChargeDetailFragment, unknown>
export const PlanFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PlanFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Plan' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'charges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'chargeModel' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'percent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'percentDerivedFrom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDrawdown' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isRenewable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCustom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isListPriceEditable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'durationInMonths' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'defaultQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'billingTerm' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rateCardId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'priceTiers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'untilQuantity' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurrence' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cycle' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'step' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'templateIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entityIds' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PlanFragment, unknown>
export const RefundFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RefundFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Refund' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'creditMemoNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentMethodType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referenceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refundId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedOn' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RefundFragment, unknown>
export const PaymentProviderPaymentMethodFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaymentProviderPaymentMethodFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentProviderPaymentMethod' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'accountNumberLastFour' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiryDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastFour' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentMethodId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PaymentProviderPaymentMethodFragment, unknown>
export const PaymentMethodDetailFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaymentMethodDetailFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentMethodDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDeleted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paymentMethod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PaymentProviderPaymentMethodFragment' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    ...PaymentProviderPaymentMethodFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<PaymentMethodDetailFragment, unknown>
export const SettlementApplicationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SettlementApplicationFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SettlementApplication' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'applicationType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'appliedOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'creditMemoNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invoiceNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'negatedSettlement' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'applicationType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'appliedOn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdOn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'creditMemoNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'invoiceNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'note' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'payment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paymentDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentMethodDetail' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PaymentMethodDetailFragment' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'refundStatus' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentId' } },
        ],
      },
    },
    ...PaymentMethodDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<SettlementApplicationFragment, unknown>
export const CreditMemoFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditMemoFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CreditMemo' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingContact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lineItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'charge' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChargeFragment' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chargeDetail' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChargeDetailFragment' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'plan' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PlanFragment' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'balance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdFrom' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'creditMemoNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'creditMemoDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'creditReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDeleted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postedDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'refundsApplied' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RefundFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'settlementApplicationDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SettlementApplicationFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'erpId' } },
        ],
      },
    },
    ...ChargeFragmentDoc.definitions,
    ...ChargeDetailFragmentDoc.definitions,
    ...PlanFragmentDoc.definitions,
    ...RefundFragmentDoc.definitions,
    ...SettlementApplicationFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<CreditMemoFragment, unknown>
export const CustomFieldDefinitionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'customFieldDefinition' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomFieldDefinition' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fieldName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fieldType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fieldLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'required' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentObjectType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultValue' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'selections' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'options' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CustomFieldDefinitionFragment, unknown>
export const ActionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Action' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'actionMetadata' } },
          { kind: 'Field', name: { kind: 'Name', value: 'actionRuleName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customizationActionType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ActionFragment, unknown>
export const ClmChatMessageDetailFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CLMChatMessageDetailFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CLMChatMessageDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userIdentifier' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClmChatMessageDetailFragment, unknown>
export const ClmThreadDetailFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CLMThreadDetailFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CLMThreadDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CLMChatMessageDetailFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'pinObject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
        ],
      },
    },
    ...ClmChatMessageDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ClmThreadDetailFragment, unknown>
export const DocuSignIntegrationResponseFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DocuSignIntegrationResponseFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DocuSignIntegrationResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'integrationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'environment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DocuSignIntegrationResponseFragment, unknown>
export const EntityJsonFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EntityJsonFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EntityJson' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'prorationMode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'prorationScheme' } },
          { kind: 'Field', name: { kind: 'Name', value: 'functionalCurrency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireInstruction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'erpId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creditMemoConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'scheme' } },
                { kind: 'Field', name: { kind: 'Name', value: 'prefix' } },
                { kind: 'Field', name: { kind: 'Name', value: 'length' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invoiceConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'scheme' } },
                { kind: 'Field', name: { kind: 'Name', value: 'prefix' } },
                { kind: 'Field', name: { kind: 'Name', value: 'length' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companyContact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zipcode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetAddressLine1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetAddressLine2' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EntityJsonFragment, unknown>
export const EntityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EntityFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Entity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EntityFragment, unknown>
export const EntityCoreFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EntityCoreFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EntityResolver' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'prorationMode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'prorationScheme' } },
          { kind: 'Field', name: { kind: 'Name', value: 'functionalCurrency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wireInstruction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'erpId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companyContact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zipcode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetAddressLine1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetAddressLine2' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EntityCoreFragment, unknown>
export const EntityConfigFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EntityConfigFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EntityResolver' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invoiceConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'scheme' } },
                { kind: 'Field', name: { kind: 'Name', value: 'prefix' } },
                { kind: 'Field', name: { kind: 'Name', value: 'length' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EntityConfigFragment, unknown>
export const EmailContactFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmailContactFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmailContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entityIds' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmailContactFragment, unknown>
export const ElectronicSignatureFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElectronicSignatureFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ElectronicSignature' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalDocumentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentProvider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initiatedBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'link' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountSignatory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmailContactFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'additionalRecipients' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmailContactFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenantSignatory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmailContactFragment' } }],
            },
          },
        ],
      },
    },
    ...EmailContactFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ElectronicSignatureFragment, unknown>
export const ImmutableElectronicSignatureAuditLogFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ImmutableElectronicSignatureAuditLogFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ImmutableElectronicSignatureAuditLog' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'electronicSignatureId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initiatedBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ImmutableElectronicSignatureAuditLogFragment, unknown>
export const EsignDetailsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EsignDetailsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EsignRequest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', alias: { kind: 'Name', value: 'id' }, name: { kind: 'Name', value: 'orderId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountSignatory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmailContactFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'additionalRecipients' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmailContactFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenantSignatory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmailContactFragment' } }],
            },
          },
        ],
      },
    },
    ...EmailContactFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<EsignDetailsFragment, unknown>
export const CurrencyTypeSettingFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CurrencyTypeSettingFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CurrencyTypeSetting' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'catalogCurrency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyTypeSettingId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planCurrencySettingType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CurrencyTypeSettingFragment, unknown>
export const CurrencyConversionRateFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CurrencyConversionRateFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CurrencyConversionRate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'conversionRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'effectiveDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fromCurrency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isOverridden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roundingPrecision' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roundingTreatment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'toCurrency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedBy' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CurrencyConversionRateFragment, unknown>
export const DataImportFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataImportFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataImport' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'importId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileSizeBytes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rowCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'importedBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'importedOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
          { kind: 'Field', name: { kind: 'Name', value: 'operation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedOn' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataImportFieldsFragment, unknown>
export const FlatfileSpaceFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FlatfileSpaceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FlatfileSpace' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'spaceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FlatfileSpaceFieldsFragment, unknown>
export const FlatfileSpaceResponseFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FlatfileSpaceResponseFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FlatfileSpaceResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guestLink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accessToken' } },
                { kind: 'Field', name: { kind: 'Name', value: 'environmentId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FlatfileSpaceResponseFieldsFragment, unknown>
export const IntegrationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IntegrationFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Integration' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetService' } },
          { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'realmId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdOn' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<IntegrationFragment, unknown>
export const EmailNotifierFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmailNotifierFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmailNotifierContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAccountContact' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmailNotifierFragment, unknown>
export const InvoiceDetailFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InvoiceDetailFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Invoice' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'invoiceNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subscriptionId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resellerAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postedDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invoiceDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'voidedDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentTerm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalDiscount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subTotal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taxTotal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total' } },
          { kind: 'Field', name: { kind: 'Name', value: 'balance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'convertedTo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'purchaseOrderNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'purchaseOrderRequired' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'erpId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'generatedBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'generationMethod' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingContact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountContactFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shippingContact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountContactFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invoiceItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'invoiceLineNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'charge' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'chargeModel' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'plan' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'productId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'taxRate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'taxCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'taxPercentage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'taxInclusive' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'drawdownQuantityUsed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'drawdownQuantityRemaining' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sellUnitPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'periodStartDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'periodEndDate' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailNotifiersList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'toContacts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmailNotifierFragment' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bccContacts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmailNotifierFragment' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ccContacts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmailNotifierFragment' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...AccountContactFragmentDoc.definitions,
    ...EmailNotifierFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<InvoiceDetailFragment, unknown>
export const ErpInvoiceFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ErpInvoiceFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ErpInvoice' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amountPaid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amountRemaining' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtotal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ErpInvoiceFragment, unknown>
export const InvoiceDunningDetailFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InvoiceDunningDetailFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InvoiceDunningDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailSentOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailSentTo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invoiceNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reminderType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InvoiceDunningDetailFragment, unknown>
export const OrderInvoiceItemPreviewFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderInvoiceItemPreview' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InvoiceItemPreview' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'orderLineItemId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'chargeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'listUnitPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellUnitPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'periodStartDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'periodEndDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'charge' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'billingTerm' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEventBased' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OrderInvoiceItemPreviewFragment, unknown>
export const OrderInvoicePreviewFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderInvoicePreview' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InvoicePreview' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'total' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalDiscount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invoiceItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrderInvoiceItemPreview' } }],
            },
          },
        ],
      },
    },
    ...OrderInvoiceItemPreviewFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<OrderInvoicePreviewFragment, unknown>
export const SimpleMetricsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SimpleMetricsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountMetrics' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'tcv' } },
          { kind: 'Field', name: { kind: 'Name', value: 'arr' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exitArr' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SimpleMetricsFragment, unknown>
export const NotificationInstanceWithAttemptsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NotificationInstanceWithAttemptsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NotificationInstanceWithAttempts' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryAttempts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isSuccess' } },
                { kind: 'Field', name: { kind: 'Name', value: 'statusCode' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eventType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastDeliveryAttempt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfDeliveryAttempts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'payload' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NotificationInstanceWithAttemptsFragment, unknown>
export const NotificationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NotificationFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Notification' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notificationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notificationTarget' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notificationTargetType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subscribedEvents' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NotificationFragment, unknown>
export const OpportunityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OpportunityFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Opportunity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'crmId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'opportunityCrmType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isClosed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryOrderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'opportunityId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OpportunityFragment, unknown>
export const AccountDetailFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountDetailFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountAddressFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'crmId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasAutomaticPayment' } },
        ],
      },
    },
    ...AccountAddressFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<AccountDetailFragment, unknown>
export const AmendmentSubscriptionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AmendmentSubscriptionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subscription' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'charges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AmendmentSubscriptionFragment, unknown>
export const OrderTermsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderTermsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OrderTerms' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'levelType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'templateId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OrderTermsFragment, unknown>
export const OrderDocumentTemplateFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderDocumentTemplateFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OrderDocumentTemplate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inUse' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plans' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sectionUuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isModified' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OrderDocumentTemplateFragment, unknown>
export const RenewalSubscriptionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RenewalSubscriptionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subscription' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }] },
    },
  ],
} as unknown as DocumentNode<RenewalSubscriptionFragment, unknown>
export const ApprovalFlowInstanceGroupFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ApprovalFlowInstanceGroupFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ApprovalFlowInstanceGroupDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approvalFlowInstances' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activeStateId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approvalFlowId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'states' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'approvedBy' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'time' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'ssoOnly' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'approvalGroup' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isUser' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'users' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserFragment' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'action' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'emailGroupId' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'rejectedBy' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'time' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitterNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'approvalStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'workflowStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedOn' } },
        ],
      },
    },
    ...UserFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ApprovalFlowInstanceGroupFragment, unknown>
export const ApprovalSegmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ApprovalSegmentFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ApprovalSegment' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApprovalSegmentFragment, unknown>
export const MetricsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MetricsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountMetrics' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'tcv' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nonRecurringTotal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recurringTotal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'arr' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entryArr' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exitArr' } },
          { kind: 'Field', name: { kind: 'Name', value: 'averageArr' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deltaArr' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deltaTcv' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'arrTrend' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'instant' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MetricsFragment, unknown>
export const OrderDetailFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderDetailFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OrderDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountDetail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountDetailFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrderLevelCustomFieldEntryFragment' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resoldBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'billingAnchorDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingContact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountContactFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingCycle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BillingCycleFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentSubscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AmendmentSubscriptionFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'predefinedDiscounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DiscountFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lineItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LineItemFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderTerms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrderTermsFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderFormTemplates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DocumentTemplateFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderDocumentTemplates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrderDocumentTemplateFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shippingContact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountContactFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'termLength' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cycle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'step' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'renewalForSubscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RenewalSubscriptionFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPrimaryOrderForSfdcOpportunity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentTerm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rampInterval' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sfdcOrderCanBeExecuted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sfdcOpportunityId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sfdcOpportunityName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sfdcOpportunityStage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sfdcOpportunityType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subscriptionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalListAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalListAmountBeforeOverride' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approvalFlows' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ApprovalFlowInstanceGroupFragment' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approvalSegment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ApprovalSegmentFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'documentMasterTemplate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDefault' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderMetrics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'MetricsFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'documentCustomContent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'purchaseOrderNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'purchaseOrderRequiredForInvoicing' } },
          { kind: 'Field', name: { kind: 'Name', value: 'autoRenew' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'billingAnchorDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'attachmentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compositeOrderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiresOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'executedOn' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submittedBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'recurringDiscountPercent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nonRecurringDiscountPercent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDateType' } },
        ],
      },
    },
    ...AccountFragmentDoc.definitions,
    ...AccountDetailFragmentDoc.definitions,
    ...OrderLevelCustomFieldEntryFragmentDoc.definitions,
    ...AccountContactFragmentDoc.definitions,
    ...BillingCycleFragmentDoc.definitions,
    ...AmendmentSubscriptionFragmentDoc.definitions,
    ...DiscountFragmentDoc.definitions,
    ...LineItemFragmentDoc.definitions,
    ...OrderTermsFragmentDoc.definitions,
    ...DocumentTemplateFragmentDoc.definitions,
    ...OrderDocumentTemplateFragmentDoc.definitions,
    ...RenewalSubscriptionFragmentDoc.definitions,
    ...ApprovalFlowInstanceGroupFragmentDoc.definitions,
    ...ApprovalSegmentFragmentDoc.definitions,
    ...MetricsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<OrderDetailFragment, unknown>
export const DiscountJsonFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DiscountJsonFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DiscountJson' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inUse' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entityIds' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DiscountJsonFragment, unknown>
export const StripeConnectIntegrationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StripeConnectIntegrationFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentStripeConnectIntegration' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'connectAccountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isCompleted' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StripeConnectIntegrationFragment, unknown>
export const PaymentFailureReasonFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaymentFailureReasonFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentFailureReason' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'declineCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'errorCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isInternal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentProvider' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PaymentFailureReasonFragment, unknown>
export const PaymentAttemptDetailFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaymentAttemptDetailFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentAttemptDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'attemptedOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'attemptNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userFriendlyState' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'failureReason' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PaymentFailureReasonFragment' } }],
            },
          },
        ],
      },
    },
    ...PaymentFailureReasonFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<PaymentAttemptDetailFragment, unknown>
export const PaymentDetailFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaymentDetailFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canBeVoided' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cannotBeVoidedReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invoiceNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lifecycleType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invoiceBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paymentAttempts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PaymentAttemptDetailFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paymentMethodDetail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PaymentMethodDetailFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'refundsApplied' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RefundFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'bankFee' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paymentBankAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'entityIds' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expenseLedgerAccountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cashLedgerAccountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'refundStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remainingBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subscriptionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'voidDate' } },
        ],
      },
    },
    ...PaymentAttemptDetailFragmentDoc.definitions,
    ...PaymentMethodDetailFragmentDoc.definitions,
    ...RefundFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<PaymentDetailFragment, unknown>
export const RefundPreviewDetailFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RefundPreviewDetailFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RefundPreviewDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'appliedDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'creditMemoNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paymentDetail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PaymentDetailFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'referenceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refundAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refundAvailable' } },
        ],
      },
    },
    ...PaymentDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<RefundPreviewDetailFragment, unknown>
export const PaymentLinkResponseFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaymentLinkResponseFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentLinkResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'connectAccountId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enrolledAccountPaymentMethod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PaymentMethodDetailFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'hasOngoingPayment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invoiceBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invoiceCurrencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invoiceDueDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invoiceFrom' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invoiceNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invoiceStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invoiceTo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isCustomerEnrolledInAutoPay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subscriptionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'supportedPaymentTypes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'latestPaymentDate' } },
        ],
      },
    },
    ...PaymentMethodDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<PaymentLinkResponseFragment, unknown>
export const AccountPaymentLinkResponseFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountPaymentLinkResponseFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountPaymentLinkResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'connectAccountId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enrolledPaymentMethodDetail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PaymentMethodDetailFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isCustomerEnrolledInAutoPay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'supportedPaymentTypes' } },
        ],
      },
    },
    ...PaymentMethodDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<AccountPaymentLinkResponseFragment, unknown>
export const PlanCoreFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PlanCore' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PlanDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inUse' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PlanCoreFragment, unknown>
export const LedgerAccountMappingFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LedgerAccountMappingFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'LedgerAccountMapping' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'contractAssetAccountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deferredRevenueAccountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recognizedRevenueAccountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taxLiabilityAccountId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LedgerAccountMappingFragment, unknown>
export const PlanDetailFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PlanDetailFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PlanDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PlanCore' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'product' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inUse' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedOn' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'charges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'percent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'percentDerivedFrom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'chargeModel' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDrawdown' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isRenewable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCustom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isListPriceEditable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'defaultQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxQuantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'billingTerm' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurrence' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cycle' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'step' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'durationInMonths' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'priceTiers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'untilQuantity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'taxRate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'taxCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'taxInclusive' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'unitOfMeasure' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'UOMStatus' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recognitionRule' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RecognitionRuleFragment' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ledgerAccountMapping' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LedgerAccountMappingFragment' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'percentOfChargeRelationships' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'catalogRelationshipType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromPlanId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromProductId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'toPlanId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'toProductId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'relationshipId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requireAllPlans' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'productId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requireAnyPlans' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'productId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exclusionPlans' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'productId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'documentTemplates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'entityIds' } },
        ],
      },
    },
    ...PlanCoreFragmentDoc.definitions,
    ...RecognitionRuleFragmentDoc.definitions,
    ...LedgerAccountMappingFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<PlanDetailFragment, unknown>
export const PlanGroupItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PlanGroupItemFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PlanGroupItemInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'planId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'chargeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultQuantity' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PlanGroupItemFragment, unknown>
export const PlanGroupFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PlanGroupFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PlanGroupInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'planGroupItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PlanGroupItemFragment' } }],
            },
          },
        ],
      },
    },
    ...PlanGroupItemFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<PlanGroupFragment, unknown>
export const ProposalLineFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProposalLineFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProposalLine' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'chargeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'chargeName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'listAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'listUnitPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellUnitPrice' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProposalLineFragment, unknown>
export const ProductCategoryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductCategoryFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductCategory' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inUse' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entityIds' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProductCategoryFragment, unknown>
export const ProductDetailFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductDetailFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entityIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inUse' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedOn' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productCategory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductCategoryFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
        ],
      },
    },
    ...ProductCategoryFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ProductDetailFragment, unknown>
export const ProductFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entityIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inUse' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedOn' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productCategory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductCategoryFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
        ],
      },
    },
    ...ProductCategoryFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ProductFragment, unknown>
export const PlanMinimalFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PlanMinimalFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PlanMinimal' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'product' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'entityIds' } },
        ],
      },
    },
    ...ProductFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<PlanMinimalFragment, unknown>
export const AttributeReferenceFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AttributeReferenceFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AttributeReference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'attributeDefinitionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'attributeValue' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AttributeReferenceFragment, unknown>
export const PriceTableFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PriceTableFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PriceTableElement' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attributeReferences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AttributeReferenceFragment' } }],
            },
          },
        ],
      },
    },
    ...AttributeReferenceFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<PriceTableFragment, unknown>
export const RateCardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RateCardFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RateCard' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'priceTable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PriceTableFragment' } }],
            },
          },
        ],
      },
    },
    ...PriceTableFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<RateCardFragment, unknown>
export const PriceAttributeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PriceAttributeFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PriceAttribute' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'values' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PriceAttributeFragment, unknown>
export const ReportFilterFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportFilterFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PredefinedReportParameter' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'datatype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'optional' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultValue' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportFilterFragment, unknown>
export const ReportChartFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportChartFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PredefinedReportDefinitionChart' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'chartType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainAxisKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'crossAxisKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainAxisLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'crossAxisLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainAxisScale' } },
          { kind: 'Field', name: { kind: 'Name', value: 'crossAxisScale' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sortBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showLegend' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportChartFragment, unknown>
export const ReportDefinitionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportDefinitionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PredefinedReportDefinition' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'reportId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ReportFilterFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chart' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ReportChartFragment' } }],
            },
          },
        ],
      },
    },
    ...ReportFilterFragmentDoc.definitions,
    ...ReportChartFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ReportDefinitionFragment, unknown>
export const AccountingPeriodFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountingPeriodFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountingPeriod' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'syncStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'openedBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'openedOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'closedBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'closedOn' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountingPeriodFragment, unknown>
export const RevenueWaterfallItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RevenueWaterfallItemFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RevenueWaterfallItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountingPeriod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'startDate' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'revenueAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unitOfCompletion' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RevenueWaterfallItemFragment, unknown>
export const SubscriptionChargeRecognitionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionChargeRecognitionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SubscriptionChargeRecognition' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountingPeriod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountingPeriodFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'chargeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deferredRevenue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalRevenue' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'revenueWaterfallItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RevenueWaterfallItemFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recognitionRule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RecognitionRuleFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'recognizedRevenue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subscriptionId' } },
        ],
      },
    },
    ...AccountingPeriodFragmentDoc.definitions,
    ...RevenueWaterfallItemFragmentDoc.definitions,
    ...RecognitionRuleFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<SubscriptionChargeRecognitionFragment, unknown>
export const AccountContactDetailFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountContactDetailFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountContactDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inUse' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedOn' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'streetAddressLine1' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetAddressLine2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zipcode' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountContactDetailFragment, unknown>
export const SalesRoomAccountAddressFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesRoomAccountAddressFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetAddressLine1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetAddressLine2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zipcode' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SalesRoomAccountAddressFragment, unknown>
export const SalesRoomAccountFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesRoomAccountFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountContactDetailFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SalesRoomAccountAddressFragment' } },
              ],
            },
          },
        ],
      },
    },
    ...AccountContactDetailFragmentDoc.definitions,
    ...SalesRoomAccountAddressFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<SalesRoomAccountFragment, unknown>
export const SalesRoomCustomFieldEntryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesRoomCustomFieldEntryFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomFieldEntry' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'options' } },
          { kind: 'Field', name: { kind: 'Name', value: 'required' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selections' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SalesRoomCustomFieldEntryFragment, unknown>
export const TenantDetailsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TenantDetailsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TenantDetails' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountAddressFragment' } }],
            },
          },
        ],
      },
    },
    ...AccountAddressFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<TenantDetailsFragment, unknown>
export const UserGroupFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroupFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserGroupDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entityIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDeleted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRole' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedOn' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserFragment' } }],
            },
          },
        ],
      },
    },
    ...UserFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UserGroupFragment, unknown>
export const AccountReceivableContactFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountReceivableContactFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountReceivableContactJson' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountAddressFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
        ],
      },
    },
    ...AccountAddressFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<AccountReceivableContactFragment, unknown>
export const DunningSettingDetailsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DunningSettingDetailsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DunningSettingDetails' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dunningTypeStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'dunningType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DunningSettingDetailsFragment, unknown>
export const SubscriptionContactFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionContactFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetAddressLine1' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetAddressLine2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zipcode' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubscriptionContactFragment, unknown>
export const SubscriptionRenewDatesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionRenewDatesFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subscription' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'renewedFromDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'renewedFromSubscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'renewedToDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'renewedToSubscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubscriptionRenewDatesFragment, unknown>
export const SubscriptionRestructureDatesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionRestructureDatesFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subscription' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'restructuredFromDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'restructuredFromSubscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'restructuredToDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'restructuredToSubscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubscriptionRestructureDatesFragment, unknown>
export const SubscriptionChargeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionChargeFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SubscriptionCharge' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'product' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'charge' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChargeFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chargeDetail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChargeDetailFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderLines' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'discountAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'percent' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'listUnitPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellUnitPrice' } },
        ],
      },
    },
    ...ChargeFragmentDoc.definitions,
    ...ChargeDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<SubscriptionChargeFragment, unknown>
export const SubscriptionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Subscription' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SubscriptionRenewDatesFragment' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SubscriptionRestructureDatesFragment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resoldBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingContact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountContactFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingCycle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cycle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'step' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'billingAnchorDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'termLength' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cycle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'step' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'creationTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orders' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentTerm' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'charges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SubscriptionChargeFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shippingContact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountContactFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptionMetrics' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'MetricsFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'canceledDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mailingContacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountContactFragment' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'purchaseOrderRequiredForInvoicing' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purchaseOrders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'purchaseOrderNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'originOrderId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addedOn' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'autoRenew' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activationDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailNotifiersList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'toContacts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmailNotifierFragment' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bccContacts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmailNotifierFragment' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ccContacts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmailNotifierFragment' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...SubscriptionRenewDatesFragmentDoc.definitions,
    ...SubscriptionRestructureDatesFragmentDoc.definitions,
    ...AccountContactFragmentDoc.definitions,
    ...SubscriptionChargeFragmentDoc.definitions,
    ...MetricsFragmentDoc.definitions,
    ...EmailNotifierFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<SubscriptionFragment, unknown>
export const BillingEventEntryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BillingEventEntryFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BillingEventEntry' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'triggerOn' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BillingEventEntryFragment, unknown>
export const SubscriptionChargeAliasFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionChargeAliasFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SubscriptionChargeAlias' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'aliasId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'chargeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subscriptionId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubscriptionChargeAliasFragment, unknown>
export const TaxJarIntegrationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TaxJarIntegrationFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TaxJarIntegration' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'maskedApiKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'integrationId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSandbox' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDeleted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedOn' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TaxJarIntegrationFragment, unknown>
export const DocumentMasterTemplateFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DocumentMasterTemplateFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DocumentMasterTemplate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'configuration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'contractTermsColumns' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contractTermsTableStyle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fontFamily' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fontSize' } },
                { kind: 'Field', name: { kind: 'Name', value: 'headerStyle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lineItemColumns' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lineItemTableStyle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'secondaryColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'showLineBreakupByPredefinedDiscounts' } },
                { kind: 'Field', name: { kind: 'Name', value: 'showArrByYears' } },
                { kind: 'Field', name: { kind: 'Name', value: 'showBreakupByBillingCycle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'showNextInvoiceAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFullHtml' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inUse' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDefault' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entityIds' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DocumentMasterTemplateFragment, unknown>
export const DocumentSectionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DocumentSectionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DocumentSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entityIds' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DocumentSectionFragment, unknown>
export const TenantJobFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TenantJobFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TenantJob' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'module' } },
          { kind: 'Field', name: { kind: 'Name', value: 'objectModel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'objectId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'attempts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'failureReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endedOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdOn' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TenantJobFieldsFragment, unknown>
export const UiElementConfigFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UIElementConfigFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UIElement' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }] },
    },
  ],
} as unknown as DocumentNode<UiElementConfigFragment, unknown>
export const UiConfigurationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UIConfigurationFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UIConfiguration' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderPageConfiguration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UIElementConfigFragment' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderLineItemsConfiguration' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fields' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UIElementConfigFragment' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customFields' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UIElementConfigFragment' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...UiElementConfigFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UiConfigurationFragment, unknown>
export const GetAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountDetail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountCore' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accountMetrics' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SimpleMetricsFragment' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountContactDetailFragment' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentMethodDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PaymentMethodDetailFragment' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'enrolledPaymentMethod' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PaymentMethodDetailFragment' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...AccountCoreFragmentDoc.definitions,
    ...SimpleMetricsFragmentDoc.definitions,
    ...AccountContactDetailFragmentDoc.definitions,
    ...PaymentMethodDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetAccountQuery, GetAccountQueryVariables>
export const GetCreditMemoAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCreditMemoAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountDetail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inUse' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCreditMemoAccountQuery, GetCreditMemoAccountQueryVariables>
export const GetSalesforceAccountByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSalesforceAccountById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sfid' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getSalesforceAccountById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sfid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billingAddress' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SalesforceAccountBillingAddressFragment' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
        ],
      },
    },
    ...SalesforceAccountBillingAddressFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetSalesforceAccountByIdQuery, GetSalesforceAccountByIdQueryVariables>
export const UpsertAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'account' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputAccount' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'account' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'account' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountCore' } }],
            },
          },
        ],
      },
    },
    ...AccountCoreFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpsertAccountMutation, UpsertAccountMutationVariables>
export const DeleteAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteAccountMutation, DeleteAccountMutationVariables>
export const UpsertAccountContactDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertAccountContact' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'firstName' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lastName' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'phoneNumber' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'externalId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'address' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputAccountAddress' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'strict' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skipValidation' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertAccountContact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skipAddressValidation' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skipValidation' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'strictValidation' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'strict' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountContact' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'email' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'firstName' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'firstName' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'lastName' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'lastName' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'phoneNumber' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'phoneNumber' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'externalId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'externalId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'address' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'address' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountContactFragment' } }],
            },
          },
        ],
      },
    },
    ...AccountContactFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpsertAccountContactMutation, UpsertAccountContactMutationVariables>
export const DeleteAccountContactDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteAccountContact' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteAccountContact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteAccountContactMutation, DeleteAccountContactMutationVariables>
export const SalesforceContactsForAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'salesforceContactsForAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesforceContactsForAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SalesforceContactFragment' } }],
            },
          },
        ],
      },
    },
    ...SalesforceContactFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<SalesforceContactsForAccountQuery, SalesforceContactsForAccountQueryVariables>
export const UpsertCrmContactDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertCRMContact' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'crmIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertCrmContacts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'crmIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'crmIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UpsertCRMContactResponseFragment' } },
              ],
            },
          },
        ],
      },
    },
    ...UpsertCrmContactResponseFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpsertCrmContactMutation, UpsertCrmContactMutationVariables>
export const GetDraftInvoicesForCustomerAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDraftInvoicesForCustomerAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'draftInvoicesCountForCustomerAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDraftInvoicesForCustomerAccountQuery, GetDraftInvoicesForCustomerAccountQueryVariables>
export const GetAccountByCrmIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAccountByCrmId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountCrmId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountByCrmId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountCrmId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountCrmId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'crmId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isReseller' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxExemptionUseCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'excludeFromBatchOperations' } },
                { kind: 'Field', name: { kind: 'Name', value: 'excludeFromDunning' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasAutomaticPayment' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountAddressFragment' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...AccountAddressFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetAccountByCrmIdQuery, GetAccountByCrmIdQueryVariables>
export const GetCrmContactsForAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCrmContactsForAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crmContactsForAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CrmContactFragment' } }],
            },
          },
        ],
      },
    },
    ...CrmContactFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetCrmContactsForAccountQuery, GetCrmContactsForAccountQueryVariables>
export const GetOrCreateAccountByCrmIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'getOrCreateAccountByCrmId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountCrmId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'opportunityCrmId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getOrCreateAccountByCrmId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountCrmId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountCrmId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'opportunityCrmId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'opportunityCrmId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'crmId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isReseller' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxExemptionUseCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'excludeFromBatchOperations' } },
                { kind: 'Field', name: { kind: 'Name', value: 'excludeFromDunning' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasAutomaticPayment' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountAddressFragment' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...AccountAddressFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetOrCreateAccountByCrmIdMutation, GetOrCreateAccountByCrmIdMutationVariables>
export const ImportAccountByCrmIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ImportAccountByCrmId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountCrmId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'importAccountByCrmId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountCrmId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountCrmId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'crmId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isReseller' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxExemptionUseCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'excludeFromBatchOperations' } },
                { kind: 'Field', name: { kind: 'Name', value: 'excludeFromDunning' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasAutomaticPayment' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountAddressFragment' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...AccountAddressFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ImportAccountByCrmIdMutation, ImportAccountByCrmIdMutationVariables>
export const GetLedgerAccountsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetLedgerAccounts' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ledgerAccounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LedgerAccountFragment' } }],
            },
          },
        ],
      },
    },
    ...LedgerAccountFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetLedgerAccountsQuery, GetLedgerAccountsQueryVariables>
export const GetAllLedgerAccountTemplatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllLedgerAccountTemplates' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allLedgerAccountTemplates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LedgerAccountFragment' } }],
            },
          },
        ],
      },
    },
    ...LedgerAccountFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetAllLedgerAccountTemplatesQuery, GetAllLedgerAccountTemplatesQueryVariables>
export const UpsertLedgerAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertLedgerAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ledgerAccount' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputLedgerAccount' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertLedgerAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ledgerAccount' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ledgerAccount' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LedgerAccountFragment' } }],
            },
          },
        ],
      },
    },
    ...LedgerAccountFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpsertLedgerAccountMutation, UpsertLedgerAccountMutationVariables>
export const DeleteLedgerAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteLedgerAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteLedgerAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteLedgerAccountMutation, DeleteLedgerAccountMutationVariables>
export const GetAccountingPeriodDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAccountingPeriod' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountingPeriodId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountingPeriod' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountingPeriodId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountingPeriodId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountingPeriodFragment' } }],
            },
          },
        ],
      },
    },
    ...AccountingPeriodFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetAccountingPeriodQuery, GetAccountingPeriodQueryVariables>
export const GetAccountingPeriodDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAccountingPeriodDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountingPeriodId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountingPeriod' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountingPeriodId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountingPeriodId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountingPeriodFragment' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountingPeriodDetailFragment' } },
              ],
            },
          },
        ],
      },
    },
    ...AccountingPeriodFragmentDoc.definitions,
    ...AccountingPeriodDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetAccountingPeriodDetailQuery, GetAccountingPeriodDetailQueryVariables>
export const GetCurrentAccountingPeriodDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCurrentAccountingPeriod' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentAccountingPeriod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountingPeriodFragment' } }],
            },
          },
        ],
      },
    },
    ...AccountingPeriodFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetCurrentAccountingPeriodQuery, GetCurrentAccountingPeriodQueryVariables>
export const GetCurrentAccountingPeriodDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCurrentAccountingPeriodDetail' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentAccountingPeriod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountingPeriodFragment' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountingPeriodDetailFragment' } },
              ],
            },
          },
        ],
      },
    },
    ...AccountingPeriodFragmentDoc.definitions,
    ...AccountingPeriodDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetCurrentAccountingPeriodDetailQuery, GetCurrentAccountingPeriodDetailQueryVariables>
export const GetCurrentAccountingPeriodByEntityIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCurrentAccountingPeriodByEntityId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'entityId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentAccountingPeriodByEntityId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'entityId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'entityId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountingPeriodFragment' } }],
            },
          },
        ],
      },
    },
    ...AccountingPeriodFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetCurrentAccountingPeriodByEntityIdQuery,
  GetCurrentAccountingPeriodByEntityIdQueryVariables
>
export const SpecifyCurrentAccountingPeriodDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SpecifyCurrentAccountingPeriod' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Long' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'specifyCurrentAccountingPeriod' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountingPeriodFragment' } }],
            },
          },
        ],
      },
    },
    ...AccountingPeriodFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<SpecifyCurrentAccountingPeriodMutation, SpecifyCurrentAccountingPeriodMutationVariables>
export const GetAccountingEnabledDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAccountingEnabled' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enabledPlatformFeature' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'platformFeature' },
                value: { kind: 'EnumValue', value: 'ACCOUNTING' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'enabledAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabledByUserId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'platformFeature' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAccountingEnabledQuery, GetAccountingEnabledQueryVariables>
export const EnableAccountingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EnableAccounting' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enableAccountingPlatformFeature' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'enabledAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabledByUserId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'platformFeature' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EnableAccountingMutation, EnableAccountingMutationVariables>
export const UpdateAccountingPeriodStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateAccountingPeriodStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountingPeriodStatus' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAccountingPeriodStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountingPeriodFragment' } }],
            },
          },
        ],
      },
    },
    ...AccountingPeriodFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpdateAccountingPeriodStatusMutation, UpdateAccountingPeriodStatusMutationVariables>
export const InitiateAccountingPeriodCloseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InitiateAccountingPeriodClose' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountingPeriodId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'initiateAccountingPeriodClose' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountingPeriodId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountingPeriodId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountingPeriodFragment' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountingPeriodDetailFragment' } },
              ],
            },
          },
        ],
      },
    },
    ...AccountingPeriodFragmentDoc.definitions,
    ...AccountingPeriodDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<InitiateAccountingPeriodCloseMutation, InitiateAccountingPeriodCloseMutationVariables>
export const AbandonAccountingPeriodCloseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AbandonAccountingPeriodClose' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountingPeriodId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'abandonAccountingPeriodClose' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountingPeriodId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountingPeriodId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountingPeriodFragment' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountingPeriodDetailFragment' } },
              ],
            },
          },
        ],
      },
    },
    ...AccountingPeriodFragmentDoc.definitions,
    ...AccountingPeriodDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<AbandonAccountingPeriodCloseMutation, AbandonAccountingPeriodCloseMutationVariables>
export const GetRecentAccountingPeriodsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetRecentAccountingPeriods' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'recentNumberOfMonths' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Long' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recentAccountingPeriods' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'recentNumberOfMonths' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'recentNumberOfMonths' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRecentAccountingPeriodsQuery, GetRecentAccountingPeriodsQueryVariables>
export const GenerateJournalEntriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'GenerateJournalEntries' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountingPeriodId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generateJournalEntries' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountingPeriodId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountingPeriodId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GenerateJournalEntriesMutation, GenerateJournalEntriesMutationVariables>
export const SyncJournalEntriesToErpDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SyncJournalEntriesToErp' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountingPeriodId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syncJournalEntriesInAccountingPeriodToErp' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountingPeriodId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountingPeriodId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SyncJournalEntriesToErpMutation, SyncJournalEntriesToErpMutationVariables>
export const DeleteJournalEntriesFromErpDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteJournalEntriesFromErp' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountingPeriodId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteJournalEntriesInAccountingPeriodFromErp' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountingPeriodId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountingPeriodId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteJournalEntriesFromErpMutation, DeleteJournalEntriesFromErpMutationVariables>
export const GetErpIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetErpIntegration' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'erpIntegration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'IntegrationFragment' } }],
            },
          },
        ],
      },
    },
    ...IntegrationFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetErpIntegrationQuery, GetErpIntegrationQueryVariables>
export const GetLedgerAccountsForPaymentBankAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetLedgerAccountsForPaymentBankAccount' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ledgerAccountsForPaymentBankAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LedgerAccountFragment' } }],
            },
          },
        ],
      },
    },
    ...LedgerAccountFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetLedgerAccountsForPaymentBankAccountQuery,
  GetLedgerAccountsForPaymentBankAccountQueryVariables
>
export const GetPaymentBankAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPaymentBankAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paymentBankAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PaymentBankAccountFragment' } }],
            },
          },
        ],
      },
    },
    ...PaymentBankAccountFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetPaymentBankAccountQuery, GetPaymentBankAccountQueryVariables>
export const GetApplicablePaymentBankAccountsForInvoicePaymentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetApplicablePaymentBankAccountsForInvoicePayment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceNumber' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicablePaymentBankAccountsForInvoicePayment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoiceNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceNumber' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PaymentBankAccountFragment' } }],
            },
          },
        ],
      },
    },
    ...PaymentBankAccountFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetApplicablePaymentBankAccountsForInvoicePaymentQuery,
  GetApplicablePaymentBankAccountsForInvoicePaymentQueryVariables
>
export const UpsertPaymentBankAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertPaymentBankAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'paymentBankAccount' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputPaymentBankAccountJson' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertPaymentBankAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentBankAccount' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'paymentBankAccount' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PaymentBankAccountFragment' } }],
            },
          },
        ],
      },
    },
    ...PaymentBankAccountFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpsertPaymentBankAccountMutation, UpsertPaymentBankAccountMutationVariables>
export const DeletePaymentBankAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeletePaymentBankAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePaymentBankAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PaymentBankAccountFragment' } }],
            },
          },
        ],
      },
    },
    ...PaymentBankAccountFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<DeletePaymentBankAccountMutation, DeletePaymentBankAccountMutationVariables>
export const GetCurrentTenantDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCurrentTenant' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentTenant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'TenantFragment' } }],
            },
          },
        ],
      },
    },
    ...TenantFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetCurrentTenantQuery, GetCurrentTenantQueryVariables>
export const GetTenantsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTenants' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'TenantFragment' } }],
            },
          },
        ],
      },
    },
    ...TenantFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetTenantsQuery, GetTenantsQueryVariables>
export const AddTenantDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddTenant' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tenant' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputTenant' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addTenant' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tenant' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'tenant' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TenantCreationResponseJsonFragment' } },
              ],
            },
          },
        ],
      },
    },
    ...TenantCreationResponseJsonFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<AddTenantMutation, AddTenantMutationVariables>
export const GetAnrokIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAnrokIntegration' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'anrokIntegration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AnrokIntegrationFragment' } }],
            },
          },
        ],
      },
    },
    ...AnrokIntegrationFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetAnrokIntegrationQuery, GetAnrokIntegrationQueryVariables>
export const TestAnrokIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TestAnrokIntegration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'apiKey' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'testAnrokIntegration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'apiKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'apiKey' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TestAnrokIntegrationMutation, TestAnrokIntegrationMutationVariables>
export const UpsertAnrokIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertAnrokIntegration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'apiKey' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertAnrokIntegration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'apiKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'apiKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AnrokIntegrationFragment' } }],
            },
          },
        ],
      },
    },
    ...AnrokIntegrationFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpsertAnrokIntegrationMutation, UpsertAnrokIntegrationMutationVariables>
export const HasAnrokIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'HasAnrokIntegration' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'hasAnrokIntegration' } }],
      },
    },
  ],
} as unknown as DocumentNode<HasAnrokIntegrationQuery, HasAnrokIntegrationQueryVariables>
export const GetApiKeyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetApiKey' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'apiKey' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ApiKeyFragment' } }],
            },
          },
        ],
      },
    },
    ...ApiKeyFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetApiKeyQuery, GetApiKeyQueryVariables>
export const GetApiKeysDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetApiKeys' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'activeOnly' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'apiKeys' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'activeOnly' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'activeOnly' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ApiKeyFragment' } }],
            },
          },
        ],
      },
    },
    ...ApiKeyFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetApiKeysQuery, GetApiKeysQueryVariables>
export const GenerateApiKeyV3Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'GenerateApiKeyV3' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'apiKey' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputApiKey' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generateApiKeyV3' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'apiKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'apiKey' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GenerateApiKeyV3Mutation, GenerateApiKeyV3MutationVariables>
export const RevokeApiKeyV3Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RevokeApiKeyV3' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'revokeApiKeyV3' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RevokeApiKeyV3Mutation, RevokeApiKeyV3MutationVariables>
export const GetApprovalFlowDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetApprovalFlow' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approvalFlows' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ApprovalFlowFragment' } }],
            },
          },
        ],
      },
    },
    ...ApprovalFlowFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetApprovalFlowQuery, GetApprovalFlowQueryVariables>
export const GetPendingApprovalFlowsForUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPendingApprovalFlowsForUser' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pendingApprovalFlowsForUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accountName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approvalFlowGroupId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approvalFlowId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approvalFlowName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approvalStateId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approvalStateName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tcv' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedOn' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPendingApprovalFlowsForUserQuery, GetPendingApprovalFlowsForUserQueryVariables>
export const GetApprovalRolesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetApprovalRoles' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approvalRoles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetApprovalRolesQuery, GetApprovalRolesQueryVariables>
export const DeleteApprovalRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteApprovalRole' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteApprovalRole' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteApprovalRoleMutation, DeleteApprovalRoleMutationVariables>
export const UpsertApprovalRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertApprovalRole' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'approvalRole' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputApprovalRole' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertApprovalRole' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'approvalRole' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'approvalRole' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertApprovalRoleMutation, UpsertApprovalRoleMutationVariables>
export const UpsertApprovalFlowDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertApprovalFlow' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'flow' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputApprovalFlow' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertApprovalFlow' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'approvalFlow' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'flow' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ApprovalFlowFragment' } }],
            },
          },
        ],
      },
    },
    ...ApprovalFlowFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpsertApprovalFlowMutation, UpsertApprovalFlowMutationVariables>
export const DeleteApprovalFlowDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteApprovalFlow' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteApprovalFlow' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteApprovalFlowMutation, DeleteApprovalFlowMutationVariables>
export const UpdateOrderApprovalStateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateOrderApprovalState' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderApprovalStateUpdates' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputApprovalFlowInstanceStateUpdates' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateOrderApprovalState' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderApprovalStateUpdates' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderApprovalStateUpdates' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'approvalFlows' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ApprovalFlowInstanceGroupFragment' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ApprovalFlowInstanceGroupFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpdateOrderApprovalStateMutation, UpdateOrderApprovalStateMutationVariables>
export const UpdateApprovalFlowStatusChangeWithCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateApprovalFlowStatusChangeWithCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'state' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approvalFlowStatusChangeWithCode' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'code' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'state' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'state' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ApprovalWithCodeResponseFragment' } },
              ],
            },
          },
        ],
      },
    },
    ...ApprovalWithCodeResponseFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  UpdateApprovalFlowStatusChangeWithCodeMutation,
  UpdateApprovalFlowStatusChangeWithCodeMutationVariables
>
export const UpsertApprovalFlowSubmitterNoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertApprovalFlowSubmitterNote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'approvalFlowSubmitterNote' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputApprovalFlowSubmitterNote' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertApprovalFlowSubmitterNote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'approvalFlowSubmitterNote' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'approvalFlowSubmitterNote' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertApprovalFlowSubmitterNoteMutation, UpsertApprovalFlowSubmitterNoteMutationVariables>
export const GetAttachmentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAttachments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attachments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AttachmentFragment' } }],
            },
          },
        ],
      },
    },
    ...AttachmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetAttachmentsQuery, GetAttachmentsQueryVariables>
export const GetUserAuthInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUserAuthInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAuthInfo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'authMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'domainName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userPoolId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEmailLinkLoginEnabled' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserAuthInfoQuery, GetUserAuthInfoQueryVariables>
export const EmailLinkForLoginDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EmailLinkForLogin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailLinkForLogin' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmailLinkForLoginMutation, EmailLinkForLoginMutationVariables>
export const HasAvalaraIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'HasAvalaraIntegration' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'hasAvalaraIntegration' } }],
      },
    },
  ],
} as unknown as DocumentNode<HasAvalaraIntegrationQuery, HasAvalaraIntegrationQueryVariables>
export const GetAvalaraIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAvalaraIntegration' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avalaraIntegration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdOn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedOn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sandboxEnvironment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shouldCommitDocuments' } },
                { kind: 'Field', name: { kind: 'Name', value: 'integrationId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAvalaraIntegrationQuery, GetAvalaraIntegrationQueryVariables>
export const PingAvalaraDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PingAvalara' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sandbox' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pingAvalara' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountLicenseKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sandboxEnvironment' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sandbox' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PingAvalaraMutation, PingAvalaraMutationVariables>
export const UpsertAvalaraDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertAvalara' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companyCode' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sandbox' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'commitDocuments' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertAvalaraIntegration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companyCode' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountLicenseKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sandboxEnvironment' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sandbox' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shouldCommitDocuments' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'commitDocuments' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'integrationId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sandboxEnvironment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shouldCommitDocuments' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdOn' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertAvalaraMutation, UpsertAvalaraMutationVariables>
export const LastUsageArrivalCheckpointDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LastUsageArrivalCheckpoint' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'lastUsageArrivalCheckpoint' } }],
      },
    },
  ],
} as unknown as DocumentNode<LastUsageArrivalCheckpointQuery, LastUsageArrivalCheckpointQueryVariables>
export const GetBulkInvoiceRunDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetBulkInvoiceRun' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkInvoiceRun' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bulkInvoiceRunId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BulkInvoiceRunFragment' } }],
            },
          },
        ],
      },
    },
    ...BulkInvoiceRunFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetBulkInvoiceRunQuery, GetBulkInvoiceRunQueryVariables>
export const GetBulkInvoiceRunItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetBulkInvoiceRunItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkInvoiceRunItems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bulkInvoiceRunId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'runId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subscriptionId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'draftInvoiceNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postedInvoiceNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'invoiceAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'invoiceCurrencyCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'invoiceCreatedOn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'failureReason' } },
                { kind: 'Field', name: { kind: 'Name', value: 'excludedForPosting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdOn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedOn' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetBulkInvoiceRunItemsQuery, GetBulkInvoiceRunItemsQueryVariables>
export const AddBulkInvoiceRunDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddBulkInvoiceRun' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputBulkInvoiceRunInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addBulkInvoiceRun' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bulkInvoiceRunInput' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BulkInvoiceRunFragment' } }],
            },
          },
        ],
      },
    },
    ...BulkInvoiceRunFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<AddBulkInvoiceRunMutation, AddBulkInvoiceRunMutationVariables>
export const PostBulkInvoiceRunDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PostBulkInvoiceRun' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bulkInvoiceRunId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'exclusionIds' } },
          type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postInvoicesForBulkInvoiceRun' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bulkInvoiceRunId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'bulkInvoiceRunId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subscriptionIdExclusionList' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'exclusionIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BulkInvoiceRunFragment' } }],
            },
          },
        ],
      },
    },
    ...BulkInvoiceRunFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<PostBulkInvoiceRunMutation, PostBulkInvoiceRunMutationVariables>
export const GenerateCancelAndRestructureDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'GenerateCancelAndRestructure' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subscriptionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generateCancelAndRestructure' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subscriptionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subscriptionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CancelAndRestructureOrderDetailFragment' } },
              ],
            },
          },
        ],
      },
    },
    ...CancelAndRestructureOrderDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GenerateCancelAndRestructureMutation, GenerateCancelAndRestructureMutationVariables>
export const UpsertCancelAndRestructureDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertCancelAndRestructure' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputCancelAndRestructureRequest' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertCancelAndRestructure' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CancelAndRestructureOrderDetailFragment' } },
              ],
            },
          },
        ],
      },
    },
    ...CancelAndRestructureOrderDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpsertCancelAndRestructureMutation, UpsertCancelAndRestructureMutationVariables>
export const DryRunUpsertCancelAndRestructureDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DryRunUpsertCancelAndRestructure' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputCancelAndRestructureRequest' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertCancelAndRestructure' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isDryRun' },
                value: { kind: 'BooleanValue', value: true },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CancelAndRestructureOrderDetailFragment' } },
              ],
            },
          },
        ],
      },
    },
    ...CancelAndRestructureOrderDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  DryRunUpsertCancelAndRestructureMutation,
  DryRunUpsertCancelAndRestructureMutationVariables
>
export const GetCancelAndRestructureDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCancelAndRestructure' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelAndRestructure' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CancelAndRestructureOrderDetailFragment' } },
              ],
            },
          },
        ],
      },
    },
    ...CancelAndRestructureOrderDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetCancelAndRestructureQuery, GetCancelAndRestructureQueryVariables>
export const UpdateCancelAndRestructureOrderStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCancelAndRestructureOrderStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'OrderStatus' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'statusUpdatedOn' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Long' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCancelAndRestructureOrderStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'statusUpdatedOn' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'statusUpdatedOn' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCancelAndRestructureOrderStatusMutation,
  UpdateCancelAndRestructureOrderStatusMutationVariables
>
export const DeleteCancelAndRestructureOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteCancelAndRestructureOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCancelAndRestructure' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CancelAndRestructureOrderDetailFragment' } },
              ],
            },
          },
        ],
      },
    },
    ...CancelAndRestructureOrderDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<DeleteCancelAndRestructureOrderMutation, DeleteCancelAndRestructureOrderMutationVariables>
export const AddChargeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddCharge' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'planId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'charge' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputCharge' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addCharge' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'planId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'planId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'charge' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'charge' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChargeDetailFragment' } }],
            },
          },
        ],
      },
    },
    ...ChargeDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<AddChargeMutation, AddChargeMutationVariables>
export const UpdateChargeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCharge' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'planId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'charge' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputCharge' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCharge' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'planId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'planId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'charge' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'charge' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChargeDetailFragment' } }],
            },
          },
        ],
      },
    },
    ...ChargeDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpdateChargeMutation, UpdateChargeMutationVariables>
export const UpdateChargePartialDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateChargePartial' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'planId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'chargePartial' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputChargePartial' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateChargePartial' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'planId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'planId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'chargePartial' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'chargePartial' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChargeDetailFragment' } }],
            },
          },
        ],
      },
    },
    ...ChargeDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpdateChargePartialMutation, UpdateChargePartialMutationVariables>
export const DeleteChargeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteCharge' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'planId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'chargeId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCharge' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'planId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'planId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'chargeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'chargeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChargeDetailFragment' } }],
            },
          },
        ],
      },
    },
    ...ChargeDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<DeleteChargeMutation, DeleteChargeMutationVariables>
export const UpsertChargeDefaultAttributeReferencesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertChargeDefaultAttributeReferences' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'attributeReferences' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputAttributeReference' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'chargeId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertChargeDefaultAttributeReferences' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'chargeDefaultAttributeReferences' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'attributeReferences' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'attributeReferences' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'chargeId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'chargeId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'chargeId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertChargeDefaultAttributeReferencesMutation,
  UpsertChargeDefaultAttributeReferencesMutationVariables
>
export const GetChargeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCharge' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'planId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'chargeId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chargeDetail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'planId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'planId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'chargeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'chargeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChargeDetailFragment' } }],
            },
          },
        ],
      },
    },
    ...ChargeDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetChargeQuery, GetChargeQueryVariables>
export const GetChargeDefaultAttributeReferencesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetChargeDefaultAttributeReferences' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'chargeId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chargeDefaultAttributeReferences' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'chargeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'chargeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributeReferences' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'attributeDefinitionId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'attributeValue' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'chargeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetChargeDefaultAttributeReferencesQuery,
  GetChargeDefaultAttributeReferencesQueryVariables
>
export const CreateUpsellWithEarlyRenewalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateUpsellWithEarlyRenewal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subscriptionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'crmOpportunityId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'amendAndRenewRequest' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputAmendAndRenewCreateRequest' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUpsellWithEarlyRenewal' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subscriptionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subscriptionId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'crmOpportunityId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'crmOpportunityId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'amendAndRenewRequest' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'amendAndRenewRequest' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CompositeOrderDetailFragment' } }],
            },
          },
        ],
      },
    },
    ...CompositeOrderDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<CreateUpsellWithEarlyRenewalMutation, CreateUpsellWithEarlyRenewalMutationVariables>
export const GetCompositeOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCompositeOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'compositeOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CompositeOrderDetailFragment' } }],
            },
          },
        ],
      },
    },
    ...CompositeOrderDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetCompositeOrderQuery, GetCompositeOrderQueryVariables>
export const UpdateCompositeOrderStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCompositeOrderStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'OrderStatus' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'statusUpdatedOn' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Long' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCompositeOrderStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'statusUpdatedOn' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'statusUpdatedOn' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateCompositeOrderStatusMutation, UpdateCompositeOrderStatusMutationVariables>
export const CreateCompositeOrderDocumentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCompositeOrderDocument' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'compositeOrderId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCompositeOrderDocument' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'compositeOrderId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateCompositeOrderDocumentMutation, CreateCompositeOrderDocumentMutationVariables>
export const UpdateCompositeOrderFieldsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCompositeOrderFields' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'compositeOrderId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'compositeOrderFieldsUpdateRequest' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputCompositeOrderFieldsUpdateRequest' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCompositeOrderFields' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'compositeOrderId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'compositeOrderId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'compositeOrderFieldsUpdateRequest' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'compositeOrderFieldsUpdateRequest' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CompositeOrderDetailFragment' } }],
            },
          },
        ],
      },
    },
    ...CompositeOrderDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpdateCompositeOrderFieldsMutation, UpdateCompositeOrderFieldsMutationVariables>
export const GetCreditMemoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCreditMemo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'creditMemoNumber' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creditMemo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'creditMemoNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'creditMemoNumber' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CreditMemoFragment' } }],
            },
          },
        ],
      },
    },
    ...CreditMemoFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetCreditMemoQuery, GetCreditMemoQueryVariables>
export const PostCreditMemoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PostCreditMemo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'creditMemoNumber' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postCreditMemo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'creditMemoNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'creditMemoNumber' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CreditMemoFragment' } }],
            },
          },
        ],
      },
    },
    ...CreditMemoFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<PostCreditMemoMutation, PostCreditMemoMutationVariables>
export const DeleteCreditMemoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteCreditMemo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'creditMemoNumber' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCreditMemo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'creditMemoNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'creditMemoNumber' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CreditMemoFragment' } }],
            },
          },
        ],
      },
    },
    ...CreditMemoFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<DeleteCreditMemoMutation, DeleteCreditMemoMutationVariables>
export const ApplyCreditMemoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ApplyCreditMemo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ApplyCreditMemoRequest' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputApplyCreditMemoRequest' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applyCreditMemo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ApplyCreditMemoRequest' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ApplyCreditMemoRequest' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SettlementApplicationFragment' } }],
            },
          },
        ],
      },
    },
    ...SettlementApplicationFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ApplyCreditMemoMutation, ApplyCreditMemoMutationVariables>
export const CreateStandaloneCreditMemoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateStandaloneCreditMemo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'creditMemo' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputCreditMemoRequest' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createStandaloneCreditMemo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'creditMemo' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'creditMemo' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CreditMemoFragment' } }],
            },
          },
        ],
      },
    },
    ...CreditMemoFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<CreateStandaloneCreditMemoMutation, CreateStandaloneCreditMemoMutationVariables>
export const UpdateDraftCreditMemoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateDraftCreditMemo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'creditMemoNumber' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'creditMemo' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputCreditMemoRequest' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDraftCreditMemo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'creditMemoNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'creditMemoNumber' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'creditMemo' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'creditMemo' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CreditMemoFragment' } }],
            },
          },
        ],
      },
    },
    ...CreditMemoFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpdateDraftCreditMemoMutation, UpdateDraftCreditMemoMutationVariables>
export const SendCreditMemoEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendCreditMemoEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'creditMemoNumber' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendCreditMemoEmail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'creditMemoNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'creditMemoNumber' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'emailId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SendCreditMemoEmailMutation, SendCreditMemoEmailMutationVariables>
export const GetRefundsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetRefunds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'paymentId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'refunds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'paymentId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RefundFragment' } }],
            },
          },
        ],
      },
    },
    ...RefundFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetRefundsQuery, GetRefundsQueryVariables>
export const CreateRefundDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateRefund' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'refundRequest' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputRefundRequest' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createRefund' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'refundRequest' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'refundRequest' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RefundFragment' } }],
            },
          },
        ],
      },
    },
    ...RefundFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<CreateRefundMutation, CreateRefundMutationVariables>
export const SyncCreditMemoToErpDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SyncCreditMemoToErp' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'creditMemoNumber' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syncCreditMemoToErp' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'creditMemoNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'creditMemoNumber' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SyncCreditMemoToErpMutation, SyncCreditMemoToErpMutationVariables>
export const UnapplyCreditMemoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UnapplyCreditMemo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'unapplyCreditMemoRequest' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputUnapplyCreditMemoRequest' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unapplyCreditMemo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UnapplyCreditMemoRequest' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'unapplyCreditMemoRequest' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SettlementApplicationFragment' } }],
            },
          },
        ],
      },
    },
    ...SettlementApplicationFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UnapplyCreditMemoMutation, UnapplyCreditMemoMutationVariables>
export const GetCustomFieldDefinitionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCustomFieldDefinitions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'parentObjectType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomFieldParentType' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customFieldDefinitions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'parentObjectType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'parentObjectType' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'customFieldDefinition' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'systemSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customFieldSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'definitionsPerObject' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CustomFieldDefinitionFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetCustomFieldDefinitionsQuery, GetCustomFieldDefinitionsQueryVariables>
export const CreateCustomFieldDefinitionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCustomFieldDefinition' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customFieldDefinition' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputCustomFieldDefinitionCreate' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCustomFieldDefinition' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customFieldDefinition' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customFieldDefinition' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'customFieldDefinition' } }],
            },
          },
        ],
      },
    },
    ...CustomFieldDefinitionFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<CreateCustomFieldDefinitionMutation, CreateCustomFieldDefinitionMutationVariables>
export const UpdateCustomFieldDefinitionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCustomFieldDefinition' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customFieldDefinition' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputCustomFieldDefinitionUpdate' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCustomFieldDefinition' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customFieldDefinitionUpdate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customFieldDefinition' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'customFieldDefinition' } }],
            },
          },
        ],
      },
    },
    ...CustomFieldDefinitionFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpdateCustomFieldDefinitionMutation, UpdateCustomFieldDefinitionMutationVariables>
export const DeleteCustomFieldDefinitionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteCustomFieldDefinition' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCustomFieldDefinition' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'customFieldDefinition' } }],
            },
          },
        ],
      },
    },
    ...CustomFieldDefinitionFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<DeleteCustomFieldDefinitionMutation, DeleteCustomFieldDefinitionMutationVariables>
export const GetCustomFieldsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCustomFields' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'parentObjectType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomFieldParentType' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'parentObjectId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customFields' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'parentObjectType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'parentObjectType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'parentObjectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'parentObjectId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CustomFieldEntryFragment' } }],
            },
          },
        ],
      },
    },
    ...CustomFieldEntryFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetCustomFieldsQuery, GetCustomFieldsQueryVariables>
export const UpdateCustomFieldsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCustomFields' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'parentObjectType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomFieldParentType' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'parentObjectId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customFields' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputCustomFieldInput' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCustomFields' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'parentObjectType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'parentObjectType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'parentObjectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'parentObjectId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customFields' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customFields' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CustomFieldEntryFragment' } }],
            },
          },
        ],
      },
    },
    ...CustomFieldEntryFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpdateCustomFieldsMutation, UpdateCustomFieldsMutationVariables>
export const GetCustomFieldsBatchedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCustomFieldsBatched' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'parentObjectType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomFieldParentType' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'parentObjectIds' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customFieldsBatched' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'parentObjectType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'parentObjectType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'parentObjectIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'parentObjectIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'parentObjectId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customFieldEntries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCustomFieldsBatchedQuery, GetCustomFieldsBatchedQueryVariables>
export const PlanAdditionCustomizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PlanAdditionCustomization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'planCustomizationInput' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputPlanAdditionCustomizationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'runPlanAdditionCustomization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'planCustomizationInput' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'planCustomizationInput' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ActionFragment' } }],
            },
          },
        ],
      },
    },
    ...ActionFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<PlanAdditionCustomizationQuery, PlanAdditionCustomizationQueryVariables>
export const GetDefaultBulkInvoiceRunSelectorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDefaultBulkInvoiceRunSelector' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultBulkInvoiceRunSelector' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'exclusions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accounts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDefaultBulkInvoiceRunSelectorQuery, GetDefaultBulkInvoiceRunSelectorQueryVariables>
export const GenerateDocumentLinkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'GenerateDocumentLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generateDocumentLink' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GenerateDocumentLinkMutation, GenerateDocumentLinkMutationVariables>
export const WebOrderFormDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'WebOrderFormDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'documentId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'webOrderFormDetail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'documentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'documentId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'threads' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CLMThreadDetailFragment' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ClmThreadDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<WebOrderFormDetailQuery, WebOrderFormDetailQueryVariables>
export const CreateClmThreadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCLMThread' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clmThread' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputCLMCreateThreadRequest' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCLMThread' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clmThread' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'clmThread' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'firstMessage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CLMChatMessageDetailFragment' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'pinObject' } },
                { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
              ],
            },
          },
        ],
      },
    },
    ...ClmChatMessageDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<CreateClmThreadMutation, CreateClmThreadMutationVariables>
export const AddMessageToClmThreadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddMessageToCLMThread' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clmThreadMessageRequest' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputCLMChatMessageDetail' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addMessageToCLMThread' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clmThreadMessageRequest' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'clmThreadMessageRequest' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CLMChatMessageDetailFragment' } }],
            },
          },
        ],
      },
    },
    ...ClmChatMessageDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<AddMessageToClmThreadMutation, AddMessageToClmThreadMutationVariables>
export const ResolveThreadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResolveThread' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'threadId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resolveThread' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'threadId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'threadId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResolveThreadMutation, ResolveThreadMutationVariables>
export const HasDocuSignIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'HasDocuSignIntegration' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'hasDocusignIntegration' } }],
      },
    },
  ],
} as unknown as DocumentNode<HasDocuSignIntegrationQuery, HasDocuSignIntegrationQueryVariables>
export const GetDocuSignIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDocuSignIntegration' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'docuSignIntegration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DocuSignIntegrationResponseFragment' } },
              ],
            },
          },
        ],
      },
    },
    ...DocuSignIntegrationResponseFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetDocuSignIntegrationQuery, GetDocuSignIntegrationQueryVariables>
export const InitiateDocuSignIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InitiateDocuSignIntegration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'docuSignIntegrationRequest' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputDocuSignIntegrationRequest' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'initiateDocuSignIntegration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'docuSignIntegrationRequest' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'docuSignIntegrationRequest' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InitiateDocuSignIntegrationMutation, InitiateDocuSignIntegrationMutationVariables>
export const CompleteDocuSignIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CompleteDocuSignIntegration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'state' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'completeDocuSignIntegration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'code' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'state' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'state' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompleteDocuSignIntegrationMutation, CompleteDocuSignIntegrationMutationVariables>
export const DeleteDocuSignIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteDocuSignIntegration' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteDocuSignIntegration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DocuSignIntegrationResponseFragment' } },
              ],
            },
          },
        ],
      },
    },
    ...DocuSignIntegrationResponseFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<DeleteDocuSignIntegrationMutation, DeleteDocuSignIntegrationMutationVariables>
export const SendEmailForDocusignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendEmailForDocusign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'esignRequest' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputEsignRequest' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendEmailForDocusign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'esignRequest' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'esignRequest' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SendEmailForDocusignMutation, SendEmailForDocusignMutationVariables>
export const GetEmailLogsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetEmailLogs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'entityId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'parentEntityId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailLogs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'entityId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'entityId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'parentEntityId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'parentEntityId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'logs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'createdOn' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailContacts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'bccContacts' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'email' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ccContacts' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'email' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'toContacts' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'email' } }],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'emailId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'emailType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'parentEntityId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedOn' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetEmailLogsQuery, GetEmailLogsQueryVariables>
export const GetEntitiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetEntities' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EntityJsonFragment' } }],
            },
          },
        ],
      },
    },
    ...EntityJsonFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetEntitiesQuery, GetEntitiesQueryVariables>
export const GetEntityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetEntity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EntityJsonFragment' } }],
            },
          },
        ],
      },
    },
    ...EntityJsonFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetEntityQuery, GetEntityQueryVariables>
export const GetEntityWithConfigDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetEntityWithConfig' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entityResolver' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EntityCoreFragment' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EntityConfigFragment' } },
              ],
            },
          },
        ],
      },
    },
    ...EntityCoreFragmentDoc.definitions,
    ...EntityConfigFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetEntityWithConfigQuery, GetEntityWithConfigQueryVariables>
export const AddEntityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddEntity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'entity' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputEntityJson' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addEntity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'entity' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'entity' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EntityJsonFragment' } }],
            },
          },
        ],
      },
    },
    ...EntityJsonFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<AddEntityMutation, AddEntityMutationVariables>
export const UpdateEntityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateEntity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'entity' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputEntityPatchRequest' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateEntity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'entity' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'entity' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EntityJsonFragment' } }],
            },
          },
        ],
      },
    },
    ...EntityJsonFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpdateEntityMutation, UpdateEntityMutationVariables>
export const DeleteEntityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteEntity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'entityId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteEntity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'entityId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'entityId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteEntityMutation, DeleteEntityMutationVariables>
export const GetElectronicSignatureDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetElectronicSignature' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'electronicSignature' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ElectronicSignatureFragment' } }],
            },
          },
        ],
      },
    },
    ...ElectronicSignatureFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetElectronicSignatureQuery, GetElectronicSignatureQueryVariables>
export const SendEmailForEsignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendEmailForEsign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'esignRequest' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputEsignRequest' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendEmailForEsign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'esignRequest' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'esignRequest' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SendEmailForEsignMutation, SendEmailForEsignMutationVariables>
export const VoidEsignatureDocumentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'VoidEsignatureDocument' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'voidEsignatureDocumentV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VoidEsignatureDocumentMutation, VoidEsignatureDocumentMutationVariables>
export const ResendEsignatureDocumentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResendEsignatureDocument' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resendEsignatureDocumentV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResendEsignatureDocumentMutation, ResendEsignatureDocumentMutationVariables>
export const DeleteTenantSignatoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteTenantSignatory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteTenantSignatory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteTenantSignatoryMutation, DeleteTenantSignatoryMutationVariables>
export const EnableEsignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EnableEsign' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enablePlatformFeature' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'platformFeature' },
                value: { kind: 'EnumValue', value: 'ESIGN' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'enabledAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabledByUserId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'platformFeature' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EnableEsignMutation, EnableEsignMutationVariables>
export const EmailContactsForAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EmailContactsForAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailContactsForAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmailContactFragment' } }],
            },
          },
        ],
      },
    },
    ...EmailContactFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<EmailContactsForAccountQuery, EmailContactsForAccountQueryVariables>
export const GetElectronicSignatureAuditLogsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetElectronicSignatureAuditLogs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'electronicSignatureId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'electronicSignatureAuditLogs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'electronicSignatureId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'electronicSignatureId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ImmutableElectronicSignatureAuditLogFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    ...ImmutableElectronicSignatureAuditLogFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetElectronicSignatureAuditLogsQuery, GetElectronicSignatureAuditLogsQueryVariables>
export const GetElectronicSignatureProviderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetElectronicSignatureProvider' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'electronicSignatureProvider' } }],
      },
    },
  ],
} as unknown as DocumentNode<GetElectronicSignatureProviderQuery, GetElectronicSignatureProviderQueryVariables>
export const UpsertEsignDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertEsignDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'esignRequest' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputEsignRequest' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertEsignDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'esignRequest' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'esignRequest' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EsignDetailsFragment' } }],
            },
          },
        ],
      },
    },
    ...EsignDetailsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpsertEsignDetailsMutation, UpsertEsignDetailsMutationVariables>
export const GetEsignDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetEsignDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'esignDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EsignDetailsFragment' } }],
            },
          },
        ],
      },
    },
    ...EsignDetailsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetEsignDetailsQuery, GetEsignDetailsQueryVariables>
export const GetDynamicFeatureEnabledDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDynamicFeatureEnabled' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'feature' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Feature' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dynamicFeatureEnabled' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'feature' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'feature' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDynamicFeatureEnabledQuery, GetDynamicFeatureEnabledQueryVariables>
export const GetCurrencyTypeSettingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCurrencyTypeSetting' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currencyTypeSetting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CurrencyTypeSettingFragment' } }],
            },
          },
        ],
      },
    },
    ...CurrencyTypeSettingFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetCurrencyTypeSettingQuery, GetCurrencyTypeSettingQueryVariables>
export const GetCurrencyConversionRatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCurrencyConversionRates' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currencyConversionRates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CurrencyConversionRateFragment' } }],
            },
          },
        ],
      },
    },
    ...CurrencyConversionRateFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetCurrencyConversionRatesQuery, GetCurrencyConversionRatesQueryVariables>
export const GetCurrencyConversionRateByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCurrencyConversionRateById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currencyConversionRateById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CurrencyConversionRateFragment' } }],
            },
          },
        ],
      },
    },
    ...CurrencyConversionRateFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetCurrencyConversionRateByIdQuery, GetCurrencyConversionRateByIdQueryVariables>
export const GetCurrencyConversionRatesForGivenCurrencyPairDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCurrencyConversionRatesForGivenCurrencyPair' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fromCurrency' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'toCurrency' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currencyConversionRatesForGivenCurrencyPair' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fromCurrency' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fromCurrency' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'toCurrency' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'toCurrency' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CurrencyConversionRateFragment' } }],
            },
          },
        ],
      },
    },
    ...CurrencyConversionRateFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetCurrencyConversionRatesForGivenCurrencyPairQuery,
  GetCurrencyConversionRatesForGivenCurrencyPairQueryVariables
>
export const UpsertCurrencyTypeSettingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertCurrencyTypeSetting' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'currencyTypeSetting' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputCurrencyTypeSetting' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertCurrencyTypeSetting' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'currencyTypeSetting' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'currencyTypeSetting' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CurrencyTypeSettingFragment' } }],
            },
          },
        ],
      },
    },
    ...CurrencyTypeSettingFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpsertCurrencyTypeSettingMutation, UpsertCurrencyTypeSettingMutationVariables>
export const UpsertCurrencyConversionRateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertCurrencyConversionRate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'currencyConversionRate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputCurrencyConversionRate' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertCurrencyConversionRate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'currencyConversionRate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'currencyConversionRate' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CurrencyConversionRateFragment' } }],
            },
          },
        ],
      },
    },
    ...CurrencyConversionRateFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpsertCurrencyConversionRateMutation, UpsertCurrencyConversionRateMutationVariables>
export const GetHubSpotIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetHubSpotIntegration' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hubSpotIntegration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'hubspotId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdOn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'integrationId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCompleted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetHubSpotIntegrationQuery, GetHubSpotIntegrationQueryVariables>
export const InitiateHubSpotIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InitiateHubSpotIntegration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'initiateHubSpotIntegration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InitiateHubSpotIntegrationMutation, InitiateHubSpotIntegrationMutationVariables>
export const CompleteHubSpotIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CompleteHubSpotIntegration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'state' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'redirect_uri' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'completeHubSpotIntegration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'code' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'state' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'state' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'redirect_uri' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'redirect_uri' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'hubspotId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdOn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'integrationId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCompleted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompleteHubSpotIntegrationMutation, CompleteHubSpotIntegrationMutationVariables>
export const DeleteHubSpotIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteHubSpotIntegration' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'deleteHubSpotIntegration' } }],
      },
    },
  ],
} as unknown as DocumentNode<DeleteHubSpotIntegrationMutation, DeleteHubSpotIntegrationMutationVariables>
export const GetAccountByHubSpotCompanyIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAccountByHubSpotCompanyId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'hsid' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountByHubSpotCompanyId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'hsid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'crmId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAccountByHubSpotCompanyIdQuery, GetAccountByHubSpotCompanyIdQueryVariables>
export const SyncOrderToHubSpotDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SyncOrderToHubSpot' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syncDealInformationToHubSpot' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SyncOrderToHubSpotMutation, SyncOrderToHubSpotMutationVariables>
export const UpdatePrimaryOrderIdForHubSpotOpportunityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePrimaryOrderIdForHubSpotOpportunity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePrimaryOrderIdForHubSpotOpportunity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePrimaryOrderIdForHubSpotOpportunityMutation,
  UpdatePrimaryOrderIdForHubSpotOpportunityMutationVariables
>
export const SetupHubSpotCustomPropertiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetupHubSpotCustomProperties' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setupHubSpotCustomProperties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'level' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetupHubSpotCustomPropertiesMutation, SetupHubSpotCustomPropertiesMutationVariables>
export const VerifyHubSpotSetupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'VerifyHubSpotSetup' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verifyHubSpotSetup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'level' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VerifyHubSpotSetupQuery, VerifyHubSpotSetupQueryVariables>
export const SetupHubSpotCustomObjectsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetupHubSpotCustomObjects' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'privateKey' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setupHubSpotCustomObjects' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'privateKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'privateKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'level' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetupHubSpotCustomObjectsMutation, SetupHubSpotCustomObjectsMutationVariables>
export const GetDataImportsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDataImports' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dataImports' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataImportFields' } }],
            },
          },
        ],
      },
    },
    ...DataImportFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetDataImportsQuery, GetDataImportsQueryVariables>
export const GetDataImportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDataImport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataImportId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dataImports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataImportId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataImportId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataImportFields' } }],
            },
          },
        ],
      },
    },
    ...DataImportFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetDataImportQuery, GetDataImportQueryVariables>
export const GetFlatfileSpacesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFlatfileSpaces' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flatfileSpaces' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FlatfileSpaceFields' } }],
            },
          },
        ],
      },
    },
    ...FlatfileSpaceFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetFlatfileSpacesQuery, GetFlatfileSpacesQueryVariables>
export const GetFlatfileSpaceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFlatfileSpace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'spaceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flatfileSpace' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'spaceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'spaceId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FlatfileSpaceResponseFields' } }],
            },
          },
        ],
      },
    },
    ...FlatfileSpaceResponseFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetFlatfileSpaceQuery, GetFlatfileSpaceQueryVariables>
export const ProcessImportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ProcessImport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataImportId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'processImport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataImportId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataImportId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataImportFields' } }],
            },
          },
        ],
      },
    },
    ...DataImportFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ProcessImportMutation, ProcessImportMutationVariables>
export const CreateFlatfileWorkbookDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateFlatfileWorkbook' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'domain' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ImportDomain' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFlatfileWorkbook' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'domain' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'domain' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FlatfileSpaceResponseFields' } }],
            },
          },
        ],
      },
    },
    ...FlatfileSpaceResponseFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<CreateFlatfileWorkbookMutation, CreateFlatfileWorkbookMutationVariables>
export const RenewApiKeyForFlatfileSpaceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RenewApiKeyForFlatfileSpace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'spaceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'renewApiKeyForFlatfileSpace' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'spaceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'spaceId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RenewApiKeyForFlatfileSpaceMutation, RenewApiKeyForFlatfileSpaceMutationVariables>
export const DeleteFlatfileSpaceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteFlatfileSpace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'spaceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteFlatfileSpace' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'spaceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'spaceId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteFlatfileSpaceMutation, DeleteFlatfileSpaceMutationVariables>
export const GetIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetIntegration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'targetService' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'IntegrationTargetService' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'integration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'targetService' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'targetService' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'IntegrationFragment' } }],
            },
          },
        ],
      },
    },
    ...IntegrationFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetIntegrationQuery, GetIntegrationQueryVariables>
export const AddIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddIntegration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'integrationRequest' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputIntegrationRequest' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addIntegration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'integrationRequest' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'integrationRequest' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'authUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddIntegrationMutation, AddIntegrationMutationVariables>
export const CompleteIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CompleteIntegration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'authCode' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'state' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'realmId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'completeIntegration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'authCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'authCode' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'state' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'state' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'realmId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'realmId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetService' } },
                { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdOn' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompleteIntegrationMutation, CompleteIntegrationMutationVariables>
export const DeleteIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteIntegration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'integrationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteIntegration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'integrationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'integrationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetService' } },
                { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdOn' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteIntegrationMutation, DeleteIntegrationMutationVariables>
export const UpdateInvoiceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateInvoice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceNumber' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'updateInvoice' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputUpdateInvoiceMutation' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateInvoice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoiceNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceNumber' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updateInvoice' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'updateInvoice' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'InvoiceDetailFragment' } }],
            },
          },
        ],
      },
    },
    ...InvoiceDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>
export const GetInvoiceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetInvoice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceNumber' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invoice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoiceNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceNumber' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'InvoiceDetailFragment' } }],
            },
          },
        ],
      },
    },
    ...InvoiceDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetInvoiceQuery, GetInvoiceQueryVariables>
export const PreviewUnbilledUsageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PreviewUnbilledUsage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subscriptionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewUnbilledUsage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subscriptionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subscriptionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'periodStartDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'periodEndDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'plan' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'charge' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PreviewUnbilledUsageQuery, PreviewUnbilledUsageQueryVariables>
export const UsageForSubscriptionChargeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UsageForSubscriptionCharge' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subscriptionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'chargeId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'usageForSubscriptionCharge' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subscriptionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subscriptionId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'chargeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'chargeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'periodStartDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'periodEndDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'drawdownQuantityUsed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'drawdownQuantityRemaining' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isBilled' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UsageForSubscriptionChargeQuery, UsageForSubscriptionChargeQueryVariables>
export const GetInvoiceSettlementApplicationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetInvoiceSettlementApplications' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceNumber' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getInvoiceSettlementApplications' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoiceNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceNumber' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SettlementApplicationFragment' } }],
            },
          },
        ],
      },
    },
    ...SettlementApplicationFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetInvoiceSettlementApplicationsQuery, GetInvoiceSettlementApplicationsQueryVariables>
export const GetOpenInvoicesReportOverviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetOpenInvoicesReportOverview' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'openInvoicesReportOverview' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BillyChartDatasetFragment' } }],
            },
          },
        ],
      },
    },
    ...BillyChartDatasetFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetOpenInvoicesReportOverviewQuery, GetOpenInvoicesReportOverviewQueryVariables>
export const PostInvoiceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PostInvoice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceNumber' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postInvoice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoiceNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceNumber' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'InvoiceDetailFragment' } }],
            },
          },
        ],
      },
    },
    ...InvoiceDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<PostInvoiceMutation, PostInvoiceMutationVariables>
export const ConvertNegativeInvoiceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ConvertNegativeInvoice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceNumber' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'convertNegativeDraftInvoice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoiceNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceNumber' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'creditMemoNumber' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ConvertNegativeInvoiceMutation, ConvertNegativeInvoiceMutationVariables>
export const DeleteInvoiceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteInvoice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceNumber' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteInvoice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoiceNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceNumber' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'InvoiceDetailFragment' } }],
            },
          },
        ],
      },
    },
    ...InvoiceDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<DeleteInvoiceMutation, DeleteInvoiceMutationVariables>
export const ApplyManualPaymentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ApplyManualPayment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceNumber' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'amount' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'BigDecimal' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceAmount' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'paymentType' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentType' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'note' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'paymentDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Long' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'paymentBankAccountId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bankFee' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BigDecimal' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applyManualPayment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ApplyPaymentRequest' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'amount' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'amount' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'invoiceAmount' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceAmount' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'invoiceNumber' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceNumber' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'paymentType' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'paymentType' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'note' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'note' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'paymentDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'paymentDate' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'bankFee' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'bankFee' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'paymentBankAccountId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'paymentBankAccountId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'invoiceNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdOn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'applicationType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApplyManualPaymentMutation, ApplyManualPaymentMutationVariables>
export const SendEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceNumber' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendInvoiceEmail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoiceNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceNumber' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'emailId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SendEmailMutation, SendEmailMutationVariables>
export const GetInvoiceDunningDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetInvoiceDunningDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceNumber' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invoiceDunningDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoiceNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceNumber' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'InvoiceDunningDetailFragment' } }],
            },
          },
        ],
      },
    },
    ...InvoiceDunningDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetInvoiceDunningDetailsQuery, GetInvoiceDunningDetailsQueryVariables>
export const VoidInvoiceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'VoidInvoice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'voidInvoice' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputVoidInvoiceMutation' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'voidInvoice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'voidInvoice' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'voidInvoice' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'InvoiceDetailFragment' } }],
            },
          },
        ],
      },
    },
    ...InvoiceDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<VoidInvoiceMutation, VoidInvoiceMutationVariables>
export const SyncInvoiceToErpDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SyncInvoiceToErp' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceNumber' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syncInvoiceToErp' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoiceNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceNumber' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SyncInvoiceToErpMutation, SyncInvoiceToErpMutationVariables>
export const GetErpInvoiceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetErpInvoice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceErpId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'erpInvoice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoiceErpId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceErpId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ErpInvoiceFragment' } }],
            },
          },
        ],
      },
    },
    ...ErpInvoiceFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetErpInvoiceQuery, GetErpInvoiceQueryVariables>
export const SyncVoidInvoiceToErpDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SyncVoidInvoiceToErp' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceNumber' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'voidInvoiceOnErp' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoiceNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceNumber' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SyncVoidInvoiceToErpMutation, SyncVoidInvoiceToErpMutationVariables>
export const GetOrderInvoicePreviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetOrderInvoicePreview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderInvoicePreview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invoicePreviews' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrderInvoicePreview' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...OrderInvoicePreviewFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetOrderInvoicePreviewQuery, GetOrderInvoicePreviewQueryVariables>
export const GetEmailSentForInvoiceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetEmailSentForInvoice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceNumber' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailSentForInvoice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoiceNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceNumber' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetEmailSentForInvoiceQuery, GetEmailSentForInvoiceQueryVariables>
export const GetNotificationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetNotification' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'notificationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notification' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'notificationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'notificationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NotificationFragment' } }],
            },
          },
        ],
      },
    },
    ...NotificationFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetNotificationQuery, GetNotificationQueryVariables>
export const GetNotificationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetNotifications' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notifications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NotificationFragment' } }],
            },
          },
        ],
      },
    },
    ...NotificationFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetNotificationsQuery, GetNotificationsQueryVariables>
export const GetNotificationInstanceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetNotificationInstance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'notificationInstanceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notificationInstance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'notificationInstanceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'notificationInstanceId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'NotificationInstanceWithAttemptsFragment' } },
              ],
            },
          },
        ],
      },
    },
    ...NotificationInstanceWithAttemptsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetNotificationInstanceQuery, GetNotificationInstanceQueryVariables>
export const GetWebhookSigningKeyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetWebhookSigningKey' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'notificationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'webhookSigningKey' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'notificationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'notificationId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetWebhookSigningKeyQuery, GetWebhookSigningKeyQueryVariables>
export const GetNotificationProcessorTypeSupportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetNotificationProcessorTypeSupport' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notificationProcessorTypeSupport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'supportedTypes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetNotificationProcessorTypeSupportQuery,
  GetNotificationProcessorTypeSupportQueryVariables
>
export const UpsertTargetAndEventsSubscriptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertTargetAndEventsSubscriptions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'notification' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputNotification' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertNotificationTargetAndSubscriptions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'notification' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'notification' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NotificationFragment' } }],
            },
          },
        ],
      },
    },
    ...NotificationFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  UpsertTargetAndEventsSubscriptionsMutation,
  UpsertTargetAndEventsSubscriptionsMutationVariables
>
export const UnsubscribeNotificationTargetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UnsubscribeNotificationTarget' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'notificationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unsubscribeNotificationTarget' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'notificationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'notificationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NotificationFragment' } }],
            },
          },
        ],
      },
    },
    ...NotificationFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UnsubscribeNotificationTargetMutation, UnsubscribeNotificationTargetMutationVariables>
export const ResendNotificationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResendNotification' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'notificationInstanceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resendNotification' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'notificationInstanceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'notificationInstanceId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'NotificationInstanceWithAttemptsFragment' } },
              ],
            },
          },
        ],
      },
    },
    ...NotificationInstanceWithAttemptsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ResendNotificationMutation, ResendNotificationMutationVariables>
export const GetOrdersByCrmOpportunityIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetOrdersByCrmOpportunityId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'crmOpportunityId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ordersByCrmOpportunityId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'crmOpportunityId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'crmOpportunityId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shippingContactId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'billingContactId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sfdcOpportunityId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrimaryOrderForSfdcOpportunity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedOn' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetOrdersByCrmOpportunityIdQuery, GetOrdersByCrmOpportunityIdQueryVariables>
export const GetCompositeOrdersByCrmOpportunityIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCompositeOrdersByCrmOpportunityId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'crmOpportunityId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'compositeOrdersByCrmOpportunityId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'crmOpportunityId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'crmOpportunityId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'compositeOrderId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'crmOpportunityId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'crmOpportunityName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'crmOpportunityStage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'crmOpportunityType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'documentMasterTemplateId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedOn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isPrimaryCompositeOrderForCrmOpportunity' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCompositeOrdersByCrmOpportunityIdQuery,
  GetCompositeOrdersByCrmOpportunityIdQueryVariables
>
export const GetOpportunityByOpportunityIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetOpportunityByOpportunityId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'opportunityId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'opportunityByOpportunityId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'opportunityId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'opportunityId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'OpportunityFragment' } }],
            },
          },
        ],
      },
    },
    ...OpportunityFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetOpportunityByOpportunityIdQuery, GetOpportunityByOpportunityIdQueryVariables>
export const GetOpportunityByCrmOpportunityIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetOpportunityByCrmOpportunityId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'crmOpportunityId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'opportunityByCrmOpportunityId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'crmOpportunityId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'crmOpportunityId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'OpportunityFragment' } }],
            },
          },
        ],
      },
    },
    ...OpportunityFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetOpportunityByCrmOpportunityIdQuery, GetOpportunityByCrmOpportunityIdQueryVariables>
export const GetOpportunitiesBySalesforceAccountIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetOpportunitiesBySalesforceAccountId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sfdcAccountId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'opportunitiesBySalesforceAccountId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sfdcAccountId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sfdcAccountId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'OpportunityFragment' } }],
            },
          },
        ],
      },
    },
    ...OpportunityFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetOpportunitiesBySalesforceAccountIdQuery,
  GetOpportunitiesBySalesforceAccountIdQueryVariables
>
export const GetOpportunitiesByHubSpotCompanyIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetOpportunitiesByHubSpotCompanyId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companyId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'opportunitiesByHubSpotCompanyId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companyId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'OpportunityFragment' } }],
            },
          },
        ],
      },
    },
    ...OpportunityFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetOpportunitiesByHubSpotCompanyIdQuery, GetOpportunitiesByHubSpotCompanyIdQueryVariables>
export const GetOpportunitiesByAccountCrmIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetOpportunitiesByAccountCrmId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountCrmId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'opportunitiesByAccountCrmId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountCrmId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountCrmId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'OpportunityFragment' } }],
            },
          },
        ],
      },
    },
    ...OpportunityFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetOpportunitiesByAccountCrmIdQuery, GetOpportunitiesByAccountCrmIdQueryVariables>
export const UpdatePrimaryCompositeOrderIdForCrmOpportunityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePrimaryCompositeOrderIdForCrmOpportunity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'compositeOrderId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePrimaryCompositeOrderIdForCrmOpportunity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'compositeOrderId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'compositeOrderId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CompositeOrderDetailFragment' } }],
            },
          },
        ],
      },
    },
    ...CompositeOrderDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  UpdatePrimaryCompositeOrderIdForCrmOpportunityMutation,
  UpdatePrimaryCompositeOrderIdForCrmOpportunityMutationVariables
>
export const ResetOpportunityClosedStateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResetOpportunityClosedState' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'opportunityId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resetOpportunityClosedState' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'opportunityId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'opportunityId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'OpportunityFragment' } }],
            },
          },
        ],
      },
    },
    ...OpportunityFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ResetOpportunityClosedStateMutation, ResetOpportunityClosedStateMutationVariables>
export const ListPlansDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListPlans' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plans' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PlanFragment' } }],
            },
          },
        ],
      },
    },
    ...PlanFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ListPlansQuery, ListPlansQueryVariables>
export const ListAccountsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListAccounts' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountFragment' } }],
            },
          },
        ],
      },
    },
    ...AccountFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ListAccountsQuery, ListAccountsQueryVariables>
export const ApprovalSegmentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ApprovalSegments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approvalSegments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ApprovalSegmentFragment' } }],
            },
          },
        ],
      },
    },
    ...ApprovalSegmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ApprovalSegmentsQuery, ApprovalSegmentsQueryVariables>
export const ListResellerAccountsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListResellerAccounts' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accounts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'StringValue', value: 'RESELLER', block: false },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountFragment' } }],
            },
          },
        ],
      },
    },
    ...AccountFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ListResellerAccountsQuery, ListResellerAccountsQueryVariables>
export const ListAccountContactsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListAccountContacts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountContacts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountContactFragment' } }],
            },
          },
        ],
      },
    },
    ...AccountContactFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ListAccountContactsQuery, ListAccountContactsQueryVariables>
export const ListDiscountsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListDiscounts' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DiscountJsonFragment' } }],
            },
          },
        ],
      },
    },
    ...DiscountJsonFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ListDiscountsQuery, ListDiscountsQueryVariables>
export const GetOrdersOverviewByDateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetOrdersOverviewByDate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'from' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Long' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'to' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Long' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ordersOverviewByDate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'from' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'from' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'to' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'to' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BillyChartDatasetFragment' } }],
            },
          },
        ],
      },
    },
    ...BillyChartDatasetFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetOrdersOverviewByDateQuery, GetOrdersOverviewByDateQueryVariables>
export const GetOrderDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetOrderDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderDetail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrderDetailFragment' } }],
            },
          },
        ],
      },
    },
    ...OrderDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetOrderDetailQuery, GetOrderDetailQueryVariables>
export const UpdateOrderStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateOrderStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'statusUpdatedOn' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Long' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateOrderStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'statusUpdatedOn' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'statusUpdatedOn' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateOrderStatusMutation, UpdateOrderStatusMutationVariables>
export const DeleteOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrderDetailFragment' } }],
            },
          },
        ],
      },
    },
    ...OrderDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<DeleteOrderMutation, DeleteOrderMutationVariables>
export const DeleteUpsellWithEarlyRenewalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteUpsellWithEarlyRenewal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteUpsellWithEarlyRenewal' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CompositeOrderDetailFragment' } }],
            },
          },
        ],
      },
    },
    ...CompositeOrderDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<DeleteUpsellWithEarlyRenewalMutation, DeleteUpsellWithEarlyRenewalMutationVariables>
export const UpsertOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputOrderRequest' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrderDetailFragment' } }],
            },
          },
        ],
      },
    },
    ...OrderDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpsertOrderMutation, UpsertOrderMutationVariables>
export const GenerateAmendmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GenerateAmendment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subscriptionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generateAmendment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subscriptionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subscriptionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrderDetailFragment' } }],
            },
          },
        ],
      },
    },
    ...OrderDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GenerateAmendmentQuery, GenerateAmendmentQueryVariables>
export const GenerateRenewalOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GenerateRenewalOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subscriptionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generateRenewalOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subscriptionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subscriptionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrderDetailFragment' } }],
            },
          },
        ],
      },
    },
    ...OrderDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GenerateRenewalOrderQuery, GenerateRenewalOrderQueryVariables>
export const DuplicateOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DuplicateOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duplicateOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrderDetailFragment' } }],
            },
          },
        ],
      },
    },
    ...OrderDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<DuplicateOrderQuery, DuplicateOrderQueryVariables>
export const GetPaymentTermSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPaymentTermSettings' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paymentTermSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'customPaymentTermsAllowed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'defaultPaymentTerms' } },
                { kind: 'Field', name: { kind: 'Name', value: 'defaultPaymentTerm' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPaymentTermSettingsQuery, GetPaymentTermSettingsQueryVariables>
export const UpsertAmendmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertAmendment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputAmendmentOrderRequest' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertAmendment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrderDetailFragment' } }],
            },
          },
        ],
      },
    },
    ...OrderDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpsertAmendmentMutation, UpsertAmendmentMutationVariables>
export const DryRunAmendmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DryRunAmendment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputAmendmentOrderRequest' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertAmendment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isDryRun' },
                value: { kind: 'BooleanValue', value: true },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'populateMissingLineItems' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrderDetailFragment' } }],
            },
          },
        ],
      },
    },
    ...OrderDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<DryRunAmendmentMutation, DryRunAmendmentMutationVariables>
export const GenerateCancelOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GenerateCancelOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subscriptionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generateCancelOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subscriptionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subscriptionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrderDetailFragment' } }],
            },
          },
        ],
      },
    },
    ...OrderDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GenerateCancelOrderQuery, GenerateCancelOrderQueryVariables>
export const IsAmendmentCurrentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'IsAmendmentCurrent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isAmendmentCurrent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<IsAmendmentCurrentQuery, IsAmendmentCurrentQueryVariables>
export const DryRunCancellationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DryRunCancellation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputAmendmentOrderRequest' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertAmendment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isDryRun' },
                value: { kind: 'BooleanValue', value: true },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'populateMissingLineItems' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrderDetailFragment' } }],
            },
          },
        ],
      },
    },
    ...OrderDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<DryRunCancellationMutation, DryRunCancellationMutationVariables>
export const UpsertCancellationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertCancellation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputAmendmentOrderRequest' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertAmendment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrderDetailFragment' } }],
            },
          },
        ],
      },
    },
    ...OrderDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpsertCancellationMutation, UpsertCancellationMutationVariables>
export const DryRunOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DryRunOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputOrderRequest' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isDryRun' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrderDetailFragment' } }],
            },
          },
        ],
      },
    },
    ...OrderDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<DryRunOrderMutation, DryRunOrderMutationVariables>
export const UpsertRenewalOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertRenewalOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputOrderRequest' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertRenewalOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrderDetailFragment' } }],
            },
          },
        ],
      },
    },
    ...OrderDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpsertRenewalOrderMutation, UpsertRenewalOrderMutationVariables>
export const DryRunRenewalOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DryRunRenewalOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputOrderRequest' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertRenewalOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isDryRun' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrderDetailFragment' } }],
            },
          },
        ],
      },
    },
    ...OrderDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<DryRunRenewalOrderMutation, DryRunRenewalOrderMutationVariables>
export const SyncOrderToSalesforceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SyncOrderToSalesforce' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syncOrderToSalesforce' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrderDetailFragment' } }],
            },
          },
        ],
      },
    },
    ...OrderDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<SyncOrderToSalesforceMutation, SyncOrderToSalesforceMutationVariables>
export const CreateOrderDocumentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateOrderDocument' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createOrderDocument' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateOrderDocumentMutation, CreateOrderDocumentMutationVariables>
export const UpsertCustomPredefinedTemplateOnOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertCustomPredefinedTemplateOnOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'documentTemplate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputCustomPredefinedTemplateOnOrder' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertCustomPredefinedTemplateOnOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'documentTemplate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'documentTemplate' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertCustomPredefinedTemplateOnOrderMutation,
  UpsertCustomPredefinedTemplateOnOrderMutationVariables
>
export const DeleteCustomPredefinedTemplateOnOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteCustomPredefinedTemplateOnOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'templateId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCustomPredefinedTemplateOnOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'templateId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'templateId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteCustomPredefinedTemplateOnOrderMutation,
  DeleteCustomPredefinedTemplateOnOrderMutationVariables
>
export const RebaseAmendmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RebaseAmendment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rebaseAmendment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RebaseAmendmentMutation, RebaseAmendmentMutationVariables>
export const GetStripeConnectIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetStripeConnectIntegration' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeConnectIntegration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StripeConnectIntegrationFragment' } },
              ],
            },
          },
        ],
      },
    },
    ...StripeConnectIntegrationFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetStripeConnectIntegrationQuery, GetStripeConnectIntegrationQueryVariables>
export const InitiateStripeConnectIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InitiateStripeConnectIntegration' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'initiateStripeConnectIntegration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'accountLinkUrl' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InitiateStripeConnectIntegrationMutation,
  InitiateStripeConnectIntegrationMutationVariables
>
export const CompleteStripeConnectIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CompleteStripeConnectIntegration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'scope' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'state' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'completeStripeConnectIntegration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'code' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'scope' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'scope' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'state' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'state' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CompleteStripeConnectIntegrationMutation,
  CompleteStripeConnectIntegrationMutationVariables
>
export const GeneratePaymentLinkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'GeneratePaymentLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'actionType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AddPaymentMethodActionType' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generatePaymentLink' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'actionType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'actionType' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GeneratePaymentLinkMutation, GeneratePaymentLinkMutationVariables>
export const GetFailedPaymentsForInvoiceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFailedPaymentsForInvoice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceNumber' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'failedPaymentsForInvoice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoiceNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceNumber' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdOn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transactionId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paymentState' } },
                { kind: 'Field', name: { kind: 'Name', value: 'invoiceNumber' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetFailedPaymentsForInvoiceQuery, GetFailedPaymentsForInvoiceQueryVariables>
export const DeleteStripeConnectIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteStripeConnectIntegration' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteStripeConnectIntegration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'isDeleted' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteStripeConnectIntegrationMutation, DeleteStripeConnectIntegrationMutationVariables>
export const GetPaymentIntegrationEnabledDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPaymentIntegrationEnabled' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'paymentIntegrationEnabled' } }],
      },
    },
  ],
} as unknown as DocumentNode<GetPaymentIntegrationEnabledQuery, GetPaymentIntegrationEnabledQueryVariables>
export const GetPaymentMethodsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPaymentMethods' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paymentMethods' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PaymentMethodDetailFragment' } }],
            },
          },
        ],
      },
    },
    ...PaymentMethodDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>
export const GetPaymentConfigurationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPaymentConfiguration' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paymentConfiguration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'supportedPaymentTypes' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPaymentConfigurationQuery, GetPaymentConfigurationQueryVariables>
export const GetSupportedPaymentTypesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSupportedPaymentTypes' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'supportedPaymentTypes' } }],
      },
    },
  ],
} as unknown as DocumentNode<GetSupportedPaymentTypesQuery, GetSupportedPaymentTypesQueryVariables>
export const UpdatePaymentConfigurationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePaymentConfiguration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'paymentTypes' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentType' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePaymentConfiguration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentTypes' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'paymentTypes' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'supportedPaymentTypes' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePaymentConfigurationMutation, UpdatePaymentConfigurationMutationVariables>
export const UpdateAutomaticPaymentConfigurationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateAutomaticPaymentConfiguration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'paymentTypes' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentType' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAutomaticPaymentConfiguration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentTypes' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'paymentTypes' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'supportedPaymentTypes' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateAutomaticPaymentConfigurationMutation,
  UpdateAutomaticPaymentConfigurationMutationVariables
>
export const GetPaymentDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPaymentDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPayment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PaymentDetailFragment' } }],
            },
          },
        ],
      },
    },
    ...PaymentDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetPaymentDetailQuery, GetPaymentDetailQueryVariables>
export const GetPaymentsInAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPaymentsInAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPayment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PaymentDetailFragment' } }],
            },
          },
        ],
      },
    },
    ...PaymentDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetPaymentsInAccountQuery, GetPaymentsInAccountQueryVariables>
export const GetPaymentsForInvoiceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPaymentsForInvoice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceNumber' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paymentsForInvoice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoiceNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceNumber' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PaymentDetailFragment' } }],
            },
          },
        ],
      },
    },
    ...PaymentDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetPaymentsForInvoiceQuery, GetPaymentsForInvoiceQueryVariables>
export const GenerateRefundDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GenerateRefund' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'creditMemoNumber' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'paymentId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generateRefund' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'creditMemoNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'creditMemoNumber' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'paymentId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RefundPreviewDetailFragment' } }],
            },
          },
        ],
      },
    },
    ...RefundPreviewDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GenerateRefundQuery, GenerateRefundQueryVariables>
export const VoidPaymentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'VoidPayment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'voidPaymentRequest' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputVoidPaymentRequest' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'voidPayment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'VoidPaymentRequest' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'voidPaymentRequest' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SettlementApplicationFragment' } }],
            },
          },
        ],
      },
    },
    ...SettlementApplicationFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<VoidPaymentMutation, VoidPaymentMutationVariables>
export const InvoicePaymentLinkDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InvoicePaymentLinkDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'linkId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invoicePaymentLinkDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'linkId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'linkId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PaymentLinkResponseFragment' } }],
            },
          },
        ],
      },
    },
    ...PaymentLinkResponseFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<InvoicePaymentLinkDetailsQuery, InvoicePaymentLinkDetailsQueryVariables>
export const CreatePaymentIntentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreatePaymentIntent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'paymentLinkId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPaymentIntent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentLinkId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'paymentLinkId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'clientSecret' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paymentIntentId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatePaymentIntentMutation, CreatePaymentIntentMutationVariables>
export const UpdatePaymentIntentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePaymentIntent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'paymentLinkId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'paymentIntentId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'optInForAutomaticPayments' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePaymentIntent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentLinkId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'paymentLinkId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentIntentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'paymentIntentId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'optInForAutomaticPayments' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'optInForAutomaticPayments' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePaymentIntentMutation, UpdatePaymentIntentMutationVariables>
export const UnEnrollFromAutomaticPaymentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UnEnrollFromAutomaticPayments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'paymentLinkId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'paymentLinkType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentManagementLinkType' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unEnrollFromAutomaticPayments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentLinkId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'paymentLinkId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentLinkType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'paymentLinkType' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UnEnrollFromAutomaticPaymentsMutation, UnEnrollFromAutomaticPaymentsMutationVariables>
export const UpdateAccountSupportedPaymentTypesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateAccountSupportedPaymentTypes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'supportedPaymentTypes' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentType' } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAccountSupportedPaymentTypes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'supportedPaymentTypes' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'supportedPaymentTypes' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateAccountSupportedPaymentTypesMutation,
  UpdateAccountSupportedPaymentTypesMutationVariables
>
export const GenerateAccountPaymentLinkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'GenerateAccountPaymentLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generateAccountPaymentLink' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GenerateAccountPaymentLinkMutation, GenerateAccountPaymentLinkMutationVariables>
export const AccountPaymentLinkDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AccountPaymentLinkDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'linkId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountPaymentLinkDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'linkId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'linkId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountPaymentLinkResponseFragment' } },
              ],
            },
          },
        ],
      },
    },
    ...AccountPaymentLinkResponseFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<AccountPaymentLinkDetailsQuery, AccountPaymentLinkDetailsQueryVariables>
export const CreateSetupIntentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateSetupIntent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'paymentLinkId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSetupIntent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentLinkId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'paymentLinkId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'clientSecret' } },
                { kind: 'Field', name: { kind: 'Name', value: 'setupIntentId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateSetupIntentMutation, CreateSetupIntentMutationVariables>
export const ReEnableAutomaticPaymentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ReEnableAutomaticPayments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'paymentLinkId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'paymentLinkType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentManagementLinkType' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reEnableAutomaticPayments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentLinkId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'paymentLinkId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentLinkType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'paymentLinkType' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReEnableAutomaticPaymentsMutation, ReEnableAutomaticPaymentsMutationVariables>
export const GetHasPaymentIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetHasPaymentIntegration' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'hasPaymentIntegration' } }],
      },
    },
  ],
} as unknown as DocumentNode<GetHasPaymentIntegrationQuery, GetHasPaymentIntegrationQueryVariables>
export const GetPlanDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPlan' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'planDetail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PlanDetailFragment' } }],
            },
          },
        ],
      },
    },
    ...PlanDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetPlanQuery, GetPlanQueryVariables>
export const DeletePlanDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeletePlan' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePlan' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeletePlanMutation, DeletePlanMutationVariables>
export const DeprecatePlanDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeprecatePlan' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deprecatePlan' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeprecatePlanMutation, DeprecatePlanMutationVariables>
export const ActivatePlanDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ActivatePlan' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activatePlan' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ActivatePlanMutation, ActivatePlanMutationVariables>
export const AddPlanDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddPlan' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'addPlanRequest' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputAddPlanRequest' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addPlan' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addPlanRequest' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'addPlanRequest' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddPlanMutation, AddPlanMutationVariables>
export const UpdatePlanDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePlan' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'updatePlanRequest' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputUpdatePlanRequest' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePlan' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updatePlanRequest' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'updatePlanRequest' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePlanMutation, UpdatePlanMutationVariables>
export const DuplicatePlanDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DuplicatePlan' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duplicatePlan' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DuplicatePlanMutation, DuplicatePlanMutationVariables>
export const RevertToDraftDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RevertToDraft' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'revertToDraft' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RevertToDraftMutation, RevertToDraftMutationVariables>
export const ReactivatePlanDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ReactivatePlan' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reactivatePlan' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReactivatePlanMutation, ReactivatePlanMutationVariables>
export const ListUnitsOfMeasureDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListUnitsOfMeasure' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unitsOfMeasure' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'UOMStatus' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListUnitsOfMeasureQuery, ListUnitsOfMeasureQueryVariables>
export const ListTaxRatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListTaxRates' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taxRates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxInclusive' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListTaxRatesQuery, ListTaxRatesQueryVariables>
export const ListRecognitionRulesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListRecognitionRules' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recognitionRules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RecognitionRuleFragment' } }],
            },
          },
        ],
      },
    },
    ...RecognitionRuleFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ListRecognitionRulesQuery, ListRecognitionRulesQueryVariables>
export const GetPlanGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPlanGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'planGroupId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'planGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'planGroupId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PlanGroupFragment' } }],
            },
          },
        ],
      },
    },
    ...PlanGroupFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetPlanGroupQuery, GetPlanGroupQueryVariables>
export const UpsertPlanGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertPlanGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'planGroup' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputPlanGroupInterface' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertPlanGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'planGroup' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'planGroup' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PlanGroupFragment' } }],
            },
          },
        ],
      },
    },
    ...PlanGroupFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpsertPlanGroupMutation, UpsertPlanGroupMutationVariables>
export const CreateProposalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateProposal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'createProposalRequest' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputCreateProposalRequest' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createProposal' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'createProposalRequest' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'createProposalRequest' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'proposalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'proposalFor' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'listAmountTotal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sellAmountTotal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'termInYears' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'proposalLines' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProposalLineFragment' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ProposalLineFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<CreateProposalMutation, CreateProposalMutationVariables>
export const GetProductCategoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProductCategories' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productCategories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductCategoryFragment' } }],
            },
          },
        ],
      },
    },
    ...ProductCategoryFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetProductCategoriesQuery, GetProductCategoriesQueryVariables>
export const UpdateProductCategoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateProductCategory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'entityIds' } },
          type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProductCategory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'description' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'entityIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'entityIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductCategoryFragment' } }],
            },
          },
        ],
      },
    },
    ...ProductCategoryFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpdateProductCategoryMutation, UpdateProductCategoryMutationVariables>
export const UpsertProductCategoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertProductCategory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'entityIds' } },
          type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertProductCategory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'description' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'entityIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'entityIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductCategoryFragment' } }],
            },
          },
        ],
      },
    },
    ...ProductCategoryFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpsertProductCategoryMutation, UpsertProductCategoryMutationVariables>
export const DeleteProductCategoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteProductCategory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteProductCategory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteProductCategoryMutation, DeleteProductCategoryMutationVariables>
export const AddProductCategoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddProductCategory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addProductCategory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'description' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductCategoryFragment' } }],
            },
          },
        ],
      },
    },
    ...ProductCategoryFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<AddProductCategoryMutation, AddProductCategoryMutationVariables>
export const GetProductDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProduct' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'product' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductDetailFragment' } }],
            },
          },
        ],
      },
    },
    ...ProductDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetProductQuery, GetProductQueryVariables>
export const UpdateProductDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateProduct' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'displayName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sku' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'productCategoryId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'externalId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'entityIds' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProduct' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'displayName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'displayName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'description' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'productCategoryId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'productCategoryId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sku' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sku' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'externalId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'externalId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'entityIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'entityIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductDetailFragment' } }],
            },
          },
        ],
      },
    },
    ...ProductDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpdateProductMutation, UpdateProductMutationVariables>
export const DeleteProductDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteProduct' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteProduct' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteProductMutation, DeleteProductMutationVariables>
export const GetPlansDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPlans' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'productId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plans' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'productId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'productId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'entityIds' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'productId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'charges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChargeFragment' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ChargeFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetPlansQuery, GetPlansQueryVariables>
export const GetAllPlansDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllPlans' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plans' },
            arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'productId' }, value: { kind: 'NullValue' } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'productId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'charges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChargeFragment' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ChargeFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetAllPlansQuery, GetAllPlansQueryVariables>
export const GetPlanMinimalsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPlanMinimals' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PlanStatus' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderCurrencyCode' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'planMinimals' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderCurrencyCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderCurrencyCode' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PlanMinimalFragment' } }],
            },
          },
        ],
      },
    },
    ...PlanMinimalFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetPlanMinimalsQuery, GetPlanMinimalsQueryVariables>
export const AddProductDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddProduct' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'displayName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sku' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'productCategoryId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'externalId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'entityIds' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addProduct' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'description' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'displayName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'displayName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'productCategoryId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'productCategoryId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sku' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sku' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'externalId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'externalId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'entityIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'entityIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductDetailFragment' } }],
            },
          },
        ],
      },
    },
    ...ProductDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<AddProductMutation, AddProductMutationVariables>
export const GetRateCardsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetRateCards' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rateCards' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RateCardFragment' } }],
            },
          },
        ],
      },
    },
    ...RateCardFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetRateCardsQuery, GetRateCardsQueryVariables>
export const GetRateCardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetRateCard' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'targetCurrencyCode' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'effectiveDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Long' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rateCard' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'targetCurrencyCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'targetCurrencyCode' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'effectiveDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'effectiveDate' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RateCardFragment' } }],
            },
          },
        ],
      },
    },
    ...RateCardFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetRateCardQuery, GetRateCardQueryVariables>
export const GetPriceAttributesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPriceAttributes' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'priceAttributes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PriceAttributeFragment' } }],
            },
          },
        ],
      },
    },
    ...PriceAttributeFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetPriceAttributesQuery, GetPriceAttributesQueryVariables>
export const UpsertPriceAttributeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertPriceAttribute' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'priceAttribute' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputPriceAttribute' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertPriceAttribute' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'priceAttribute' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'priceAttribute' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PriceAttributeFragment' } }],
            },
          },
        ],
      },
    },
    ...PriceAttributeFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpsertPriceAttributeMutation, UpsertPriceAttributeMutationVariables>
export const UpsertRateCardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertRateCard' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'rateCard' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputRateCard' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertRateCard' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'rateCard' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'rateCard' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RateCardFragment' } }],
            },
          },
        ],
      },
    },
    ...RateCardFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpsertRateCardMutation, UpsertRateCardMutationVariables>
export const GetPredefinedReportsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPredefinedReports' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'predefinedReports' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reportDefs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ReportDefinitionFragment' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ReportDefinitionFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetPredefinedReportsQuery, GetPredefinedReportsQueryVariables>
export const GenerateReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'GenerateReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'req' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputPredefinedReportRequest' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generateReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reportRequest' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'req' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GenerateReportMutation, GenerateReportMutationVariables>
export const GetCurrentLookerUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCurrentLookerUser' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentLookerUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isDisabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lookerUserId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCurrentLookerUserQuery, GetCurrentLookerUserQueryVariables>
export const GetRecognitionRulesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetRecognitionRules' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recognitionRules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RecognitionRuleFragment' } }],
            },
          },
        ],
      },
    },
    ...RecognitionRuleFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetRecognitionRulesQuery, GetRecognitionRulesQueryVariables>
export const DeleteRecognitionRuleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteRecognitionRule' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteRecognitionRule' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RecognitionRuleFragment' } }],
            },
          },
        ],
      },
    },
    ...RecognitionRuleFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<DeleteRecognitionRuleMutation, DeleteRecognitionRuleMutationVariables>
export const RecognizeRevenueDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RecognizeRevenue' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'recognizeRevenue' } }],
      },
    },
  ],
} as unknown as DocumentNode<RecognizeRevenueMutation, RecognizeRevenueMutationVariables>
export const UpsertRecognitionRuleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertRecognitionRule' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'recognitionRule' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputRecognitionRule' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertRecognitionRule' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'recognitionRule' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'recognitionRule' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RecognitionRuleFragment' } }],
            },
          },
        ],
      },
    },
    ...RecognitionRuleFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpsertRecognitionRuleMutation, UpsertRecognitionRuleMutationVariables>
export const GetSubscriptionChargeRecognitionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSubscriptionChargeRecognition' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subscriptionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'chargeId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptionChargeRecognition' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subscriptionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subscriptionId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'chargeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'chargeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SubscriptionChargeRecognitionFragment' } },
              ],
            },
          },
        ],
      },
    },
    ...SubscriptionChargeRecognitionFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetSubscriptionChargeRecognitionQuery, GetSubscriptionChargeRecognitionQueryVariables>
export const CreateSalesRoomLinkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateSalesRoomLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSalesRoomLink' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', alias: { kind: 'Name', value: 'id' }, name: { kind: 'Name', value: 'linkId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateSalesRoomLinkMutation, CreateSalesRoomLinkMutationVariables>
export const GetSalesRoomLinkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSalesRoomLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesRoomLink' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', alias: { kind: 'Name', value: 'id' }, name: { kind: 'Name', value: 'linkId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSalesRoomLinkQuery, GetSalesRoomLinkQueryVariables>
export const GetSalesRoomDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSalesRoom' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'linkId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesRoom' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'linkId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'linkId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'linkId' } },
                { kind: 'Field', alias: { kind: 'Name', value: 'id' }, name: { kind: 'Name', value: 'linkId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accountDetail' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SalesRoomAccountFragment' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderDetail' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrderDetailFragment' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customFields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SalesRoomCustomFieldEntryFragment' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'electronicSignatureStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasElectronicSignatureIntegration' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenantName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tenantAddress' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetAddressLine1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetAddressLine2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zipcode' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...SalesRoomAccountFragmentDoc.definitions,
    ...OrderDetailFragmentDoc.definitions,
    ...SalesRoomCustomFieldEntryFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetSalesRoomQuery, GetSalesRoomQueryVariables>
export const GetSalesRoomResellerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSalesRoomReseller' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'linkId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resellerForSalesRoom' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'linkId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'linkId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SalesRoomAccountFragment' } }],
            },
          },
        ],
      },
    },
    ...SalesRoomAccountFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetSalesRoomResellerQuery, GetSalesRoomResellerQueryVariables>
export const UpsertAccountContactForSalesRoomDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertAccountContactForSalesRoom' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountContact' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputAccountContact' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'linkId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'updateReseller' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertAccountContactForSalesRoom' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountContact' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountContact' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'linkId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'linkId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skipAddressValidation' },
                value: { kind: 'BooleanValue', value: true },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'strictValidation' },
                value: { kind: 'BooleanValue', value: false },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updateReseller' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'updateReseller' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountContactFragment' } }],
            },
          },
        ],
      },
    },
    ...AccountContactFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  UpsertAccountContactForSalesRoomMutation,
  UpsertAccountContactForSalesRoomMutationVariables
>
export const UpdateOrderContactsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateOrderContacts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'linkId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'billingContactId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shippingContactId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateOrderContacts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'linkId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'linkId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'billingContactId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'billingContactId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shippingContactId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'shippingContactId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateOrderContactsMutation, UpdateOrderContactsMutationVariables>
export const SendEmailForEsignSalesRoomDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendEmailForEsignSalesRoom' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'linkId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountSignatory' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputEmailContact' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendEmailForEsignSalesRoom' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'linkId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'linkId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountSignatory' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountSignatory' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SendEmailForEsignSalesRoomMutation, SendEmailForEsignSalesRoomMutationVariables>
export const UpdateCustomFieldsForSalesRoomDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCustomFieldsForSalesRoom' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'linkId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customFields' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputCustomFieldInput' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCustomFieldsForSalesRoom' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'linkId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'linkId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customFields' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customFields' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SalesRoomCustomFieldEntryFragment' } },
              ],
            },
          },
        ],
      },
    },
    ...SalesRoomCustomFieldEntryFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpdateCustomFieldsForSalesRoomMutation, UpdateCustomFieldsForSalesRoomMutationVariables>
export const CreateOrderDocumentForSalesRoomDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createOrderDocumentForSalesRoom' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'linkId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createOrderDocumentForSalesRoom' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'linkId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'linkId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateOrderDocumentForSalesRoomMutation, CreateOrderDocumentForSalesRoomMutationVariables>
export const SyncCompositeOrderToCrmDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SyncCompositeOrderToCrm' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'compositeOrderId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syncCompositeOrderToCrm' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'compositeOrderId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'compositeOrderId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CompositeOrderDetailFragment' } }],
            },
          },
        ],
      },
    },
    ...CompositeOrderDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<SyncCompositeOrderToCrmMutation, SyncCompositeOrderToCrmMutationVariables>
export const GetSalesforceIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSalesforceIntegration' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesforceIntegration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdOn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedOn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'integrationId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'baseLoginUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCompleted' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSalesforceIntegrationQuery, GetSalesforceIntegrationQueryVariables>
export const GetSalesforceIntegrationDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSalesforceIntegrationDetails' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesforceIntegrationDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdOn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedOn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'integrationId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'baseLoginUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCompleted' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSalesforceIntegrationDetailsQuery, GetSalesforceIntegrationDetailsQueryVariables>
export const InitiateSalesforceIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InitiateSalesforceIntegration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clientId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clientSecret' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'redirectUri' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'baseLoginUrl' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'initiateSalesforceIntegration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'salesforceIntegration' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'clientId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'clientId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'clientSecret' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'clientSecret' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'redirectUri' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'redirectUri' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'baseLoginUrl' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'baseLoginUrl' } },
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InitiateSalesforceIntegrationMutation, InitiateSalesforceIntegrationMutationVariables>
export const CompleteSalesforceIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CompleteSalesforceIntegration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'state' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'redirect_uri' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'completeSalesforceIntegration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'code' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'state' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'state' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'redirect_uri' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'redirect_uri' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompleteSalesforceIntegrationMutation, CompleteSalesforceIntegrationMutationVariables>
export const DeleteSalesforceIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteSalesforceIntegration' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'deleteSalesforceIntegration' } }],
      },
    },
  ],
} as unknown as DocumentNode<DeleteSalesforceIntegrationMutation, DeleteSalesforceIntegrationMutationVariables>
export const UpdatePrimaryOrderIdForSalesforceOpportunityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePrimaryOrderIdForSalesforceOpportunity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePrimaryOrderIdForSalesforceOpportunity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePrimaryOrderIdForSalesforceOpportunityMutation,
  UpdatePrimaryOrderIdForSalesforceOpportunityMutationVariables
>
export const SearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Search' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'jsonInBase64' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'search' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jsonInBase64' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'jsonInBase64' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchQuery, SearchQueryVariables>
export const SearchInTenantDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SearchInTenant' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'jsonInBase64' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchInTenant' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jsonInBase64' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'jsonInBase64' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tenantId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchInTenantQuery, SearchInTenantQueryVariables>
export const GetSettingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSetting' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'percentDerivedFrom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'defaultTimeZone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'supportedCurrencies' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderExpiryDurationInDays' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isUpdateOrderStartDateEnabled' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSettingQuery, GetSettingQueryVariables>
export const GetSupportedCurrenciesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSupportedCurrencies' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'supportedCurrencies' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inUse' } },
                { kind: 'Field', name: { kind: 'Name', value: 'supportedByTenant' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSupportedCurrenciesQuery, GetSupportedCurrenciesQueryVariables>
export const SetSupportedCurrenciesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetSupportedCurrencies' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'currencies' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSupportedCurrencies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'currencies' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'currencies' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'supportedByTenant' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inUse' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetSupportedCurrenciesMutation, SetSupportedCurrenciesMutationVariables>
export const GetTenantDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTenantDetails' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenantDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'TenantDetailsFragment' } }],
            },
          },
        ],
      },
    },
    ...TenantDetailsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetTenantDetailsQuery, GetTenantDetailsQueryVariables>
export const GetUnitsOfMeasureDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUnitsOfMeasure' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unitsOfMeasure' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'UOMStatus' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUnitsOfMeasureQuery, GetUnitsOfMeasureQueryVariables>
export const UpdateTenantDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateTenantDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'phoneNumber' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'address' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputAccountAddress' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateTenantDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tenant' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'email' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'phoneNumber' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'phoneNumber' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'address' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'address' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'TenantDetailsFragment' } }],
            },
          },
        ],
      },
    },
    ...TenantDetailsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpdateTenantDetailsMutation, UpdateTenantDetailsMutationVariables>
export const UpsertUnitOfMeasureDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertUnitOfMeasure' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertUnitOfMeasure' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'unitOfMeasure' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'description' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'UOMStatus' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertUnitOfMeasureMutation, UpsertUnitOfMeasureMutationVariables>
export const DeleteUnitOfMeasureDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteUnitOfMeasure' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteUnitOfMeasure' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'UOMStatus' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteUnitOfMeasureMutation, DeleteUnitOfMeasureMutationVariables>
export const GetTaxRatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTaxRates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taxRates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxInclusive' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxPercentage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inUse' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTaxRatesQuery, GetTaxRatesQueryVariables>
export const UpsertTaxRatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertTaxRates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'taxCode' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'taxInclusive' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'taxPercentage' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BigDecimal' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertTaxRate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'taxRate' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'taxCode' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'taxCode' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'taxInclusive' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'taxInclusive' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'taxPercentage' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'taxPercentage' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'description' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxPercentage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxInclusive' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inUse' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertTaxRatesMutation, UpsertTaxRatesMutationVariables>
export const DeleteTaxRateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteTaxRate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteTaxRate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxInclusive' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inUse' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteTaxRateMutation, DeleteTaxRateMutationVariables>
export const UpdateDiscountStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateDiscountStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DiscountStatus' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDiscountStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'discountId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DiscountJsonFragment' } }],
            },
          },
        ],
      },
    },
    ...DiscountJsonFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpdateDiscountStatusMutation, UpdateDiscountStatusMutationVariables>
export const UpsertDiscountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertDiscount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'discount' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputDiscountJson' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertDiscount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'discount' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'discount' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DiscountJsonFragment' } }],
            },
          },
        ],
      },
    },
    ...DiscountJsonFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpsertDiscountMutation, UpsertDiscountMutationVariables>
export const DeleteDiscountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteDiscount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteDiscount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DiscountJsonFragment' } }],
            },
          },
        ],
      },
    },
    ...DiscountJsonFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<DeleteDiscountMutation, DeleteDiscountMutationVariables>
export const ListUserGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListUserGroups' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserGroupFragment' } }],
            },
          },
        ],
      },
    },
    ...UserGroupFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ListUserGroupsQuery, ListUserGroupsQueryVariables>
export const GetAccountReceivableContactDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAccountReceivableContact' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountReceivableContactSetting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountReceivableContactFragment' } },
              ],
            },
          },
        ],
      },
    },
    ...AccountReceivableContactFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetAccountReceivableContactQuery, GetAccountReceivableContactQueryVariables>
export const UpsertUserGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertUserGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userGroup' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputUserGroupRequest' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertUserGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userGroup' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userGroup' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserGroupFragment' } }],
            },
          },
        ],
      },
    },
    ...UserGroupFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpsertUserGroupMutation, UpsertUserGroupMutationVariables>
export const DeleteUserGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteUserGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteUserGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserGroupFragment' } }],
            },
          },
        ],
      },
    },
    ...UserGroupFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<DeleteUserGroupMutation, DeleteUserGroupMutationVariables>
export const UpdateAccountReceivableContactDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateAccountReceivableContact' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountReceivableContact' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputAccountReceivableContactJson' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAccountReceivableContact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountReceivableContact' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountReceivableContact' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AccountReceivableContactFragment' } },
              ],
            },
          },
        ],
      },
    },
    ...AccountReceivableContactFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpdateAccountReceivableContactMutation, UpdateAccountReceivableContactMutationVariables>
export const GetDunningSettingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDunningSetting' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dunningSetting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DunningSettingDetailsFragment' } }],
            },
          },
        ],
      },
    },
    ...DunningSettingDetailsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetDunningSettingQuery, GetDunningSettingQueryVariables>
export const SendSampleDunningEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SendSampleDunningEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dunningReminderType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DunningReminderType' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendSampleDunningEmail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dunningReminderType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dunningReminderType' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SendSampleDunningEmailQuery, SendSampleDunningEmailQueryVariables>
export const UpdateDunningSettingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateDunningSetting' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dunningSetting' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputDunningSettingDetails' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDunningSetting' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dunningSetting' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dunningSetting' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DunningSettingDetailsFragment' } }],
            },
          },
        ],
      },
    },
    ...DunningSettingDetailsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpdateDunningSettingMutation, UpdateDunningSettingMutationVariables>
export const GetTenantSignatoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTenantSignatories' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenantSignatories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserFragment' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'entityIds' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...UserFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetTenantSignatoriesQuery, GetTenantSignatoriesQueryVariables>
export const AddTenantSignatoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddTenantSignatory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tenantSignatory' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputEsignTenantSignatory' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addTenantSignatory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tenantSignatory' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'tenantSignatory' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddTenantSignatoryMutation, AddTenantSignatoryMutationVariables>
export const UpdatePaymentTermSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePaymentTermSettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'paymentTermSettings' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputPaymentTermSettings' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePaymentTermSettings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentTermSettings' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'paymentTermSettings' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'defaultPaymentTerm' } },
                { kind: 'Field', name: { kind: 'Name', value: 'defaultPaymentTerms' } },
                { kind: 'Field', name: { kind: 'Name', value: 'customPaymentTermsAllowed' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePaymentTermSettingsMutation, UpdatePaymentTermSettingsMutationVariables>
export const GetOrderExpiryDurationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetOrderExpiryDuration' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'orderExpiryDurationInDays' } }],
      },
    },
  ],
} as unknown as DocumentNode<GetOrderExpiryDurationQuery, GetOrderExpiryDurationQueryVariables>
export const UpdateOrderExpiryDurationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateOrderExpiryDuration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderExpiryDurationInDays' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateOrderExpiryDurationInDays' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderExpiryDurationInDays' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderExpiryDurationInDays' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateOrderExpiryDurationMutation, UpdateOrderExpiryDurationMutationVariables>
export const GetSigningOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSigningOrder' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'signingOrder' } }],
      },
    },
  ],
} as unknown as DocumentNode<GetSigningOrderQuery, GetSigningOrderQueryVariables>
export const UpdateSigningOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSigningOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'signingOrder' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'SigningOrder' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSigningOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'signingOrder' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'signingOrder' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateSigningOrderMutation, UpdateSigningOrderMutationVariables>
export const GetTimeZonesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTimeZones' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'timezones' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'zoneId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'offset' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDefaultTimeZone' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTimeZonesQuery, GetTimeZonesQueryVariables>
export const CreateMergeLinkTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateMergeLinkToken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'integrationType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'IntegrationTargetService' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createMergeLinkToken' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'integrationType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'integrationType' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateMergeLinkTokenMutation, CreateMergeLinkTokenMutationVariables>
export const ProcessMergePublicTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ProcessMergePublicToken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'publicToken' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'integrationType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'IntegrationTargetService' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'processMergePublicToken' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'publicToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'publicToken' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'integrationType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'integrationType' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProcessMergePublicTokenMutation, ProcessMergePublicTokenMutationVariables>
export const GetSubscriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSubscription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SubscriptionFragment' } }],
            },
          },
        ],
      },
    },
    ...SubscriptionFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetSubscriptionQuery, GetSubscriptionQueryVariables>
export const GetBillingEventsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetBillingEvents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subscriptionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subscriptionChargeId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingEvents' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subscriptionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subscriptionId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subscriptionChargeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subscriptionChargeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'remainingAmount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'entries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BillingEventEntryFragment' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...BillingEventEntryFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetBillingEventsQuery, GetBillingEventsQueryVariables>
export const GenerateInvoiceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'GenerateInvoice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'targetDate' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Long' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Long' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeUsage' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InvoiceChargeInclusionOption' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generateInvoice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subscriptionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'targetDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'targetDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoiceDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoiceChargeInclusionOption' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'includeUsage' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'invoiceNumber' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GenerateInvoiceMutation, GenerateInvoiceMutationVariables>
export const GetSubscriptionChargeAliasDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSubscriptionChargeAlias' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subscriptionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'chargeId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'aliasesBySubscriptionIdAndChargeId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subscriptionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subscriptionId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'chargeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'chargeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SubscriptionChargeAliasFragment' } },
              ],
            },
          },
        ],
      },
    },
    ...SubscriptionChargeAliasFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetSubscriptionChargeAliasQuery, GetSubscriptionChargeAliasQueryVariables>
export const DeleteSubscriptionChargeAliasDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteSubscriptionChargeAlias' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'aliasId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteSubscriptionChargeAlias' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'aliasId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'aliasId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SubscriptionChargeAliasFragment' } },
              ],
            },
          },
        ],
      },
    },
    ...SubscriptionChargeAliasFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<DeleteSubscriptionChargeAliasMutation, DeleteSubscriptionChargeAliasMutationVariables>
export const AddSubscriptionChargeAliasDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddSubscriptionChargeAlias' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'aliasId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subscriptionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'chargeId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addSubscriptionChargeAlias' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'aliasId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'aliasId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subscriptionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subscriptionId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'chargeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'chargeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SubscriptionChargeAliasFragment' } },
              ],
            },
          },
        ],
      },
    },
    ...SubscriptionChargeAliasFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<AddSubscriptionChargeAliasMutation, AddSubscriptionChargeAliasMutationVariables>
export const UpdateSubscriptionAttributesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSubscriptionAttributes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subscriptionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subscriptionUpdate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputSubscriptionUpdate' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSubscriptionAttributes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subscriptionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subscriptionId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subscriptionUpdate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subscriptionUpdate' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SubscriptionFragment' } }],
            },
          },
        ],
      },
    },
    ...SubscriptionFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpdateSubscriptionAttributesMutation, UpdateSubscriptionAttributesMutationVariables>
export const AddBillingEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddBillingEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'billingEventInput' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputBillingEventInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addBillingEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'billingEventInput' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'billingEventInput' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'remainingAmount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'entries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BillingEventEntryFragment' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...BillingEventEntryFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<AddBillingEventMutation, AddBillingEventMutationVariables>
export const DeletBillingEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeletBillingEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteBillingEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'remainingAmount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'entries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BillingEventEntryFragment' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...BillingEventEntryFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<DeletBillingEventMutation, DeletBillingEventMutationVariables>
export const DeleteSubscriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteSubscription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'version' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteSubscription' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subscriptionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'version' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'version' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteSubscriptionMutation, DeleteSubscriptionMutationVariables>
export const RevertSubscriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RevertSubscription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'version' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'revertSubscription' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subscriptionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'version' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'version' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RevertSubscriptionMutation, RevertSubscriptionMutationVariables>
export const AddRawUsageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddRawUsage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'rawUsagesData' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputRawUsagesData' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addRawUsages' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'rawUsagesData' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'rawUsagesData' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rawUsagesTotal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalFailed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalDuplicates' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'entries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'rowNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'failed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'failureReason' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddRawUsageMutation, AddRawUsageMutationVariables>
export const GetActiveSubscriptionsByAccountCrmIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetActiveSubscriptionsByAccountCrmId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountCrmId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeSubscriptionsByAccountCrmId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountCrmId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountCrmId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetActiveSubscriptionsByAccountCrmIdQuery,
  GetActiveSubscriptionsByAccountCrmIdQueryVariables
>
export const SubscriptionModifiableDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SubscriptionModifiable' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptionModifiable' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subscriptionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'modifiable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubscriptionModifiableQuery, SubscriptionModifiableQueryVariables>
export const SubscriptionReversibleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SubscriptionReversible' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptionReversible' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subscriptionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'modifiable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubscriptionReversibleQuery, SubscriptionReversibleQueryVariables>
export const GetErpInvoiceBySubscriptionIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetErpInvoiceBySubscriptionId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'subscriptionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'erpInvoicesBySubscriptionId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subscriptionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'subscriptionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ErpInvoiceFragment' } }],
            },
          },
        ],
      },
    },
    ...ErpInvoiceFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetErpInvoiceBySubscriptionIdQuery, GetErpInvoiceBySubscriptionIdQueryVariables>
export const GetTaxJarIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTaxJarIntegration' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taxJarIntegration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'TaxJarIntegrationFragment' } }],
            },
          },
        ],
      },
    },
    ...TaxJarIntegrationFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetTaxJarIntegrationQuery, GetTaxJarIntegrationQueryVariables>
export const TestTaxJarIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TestTaxJarIntegration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'apiKey' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isSandbox' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'testTaxJarIntegration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'apiKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'apiKey' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isSandbox' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isSandbox' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TestTaxJarIntegrationMutation, TestTaxJarIntegrationMutationVariables>
export const UpsertTaxJarIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertTaxJarIntegration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'apiKey' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isSandbox' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertTaxJarIntegration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'apiKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'apiKey' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isSandbox' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isSandbox' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'TaxJarIntegrationFragment' } }],
            },
          },
        ],
      },
    },
    ...TaxJarIntegrationFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpsertTaxJarIntegrationMutation, UpsertTaxJarIntegrationMutationVariables>
export const HasTaxJarIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'HasTaxJarIntegration' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'hasTaxJarIntegration' } }],
      },
    },
  ],
} as unknown as DocumentNode<HasTaxJarIntegrationQuery, HasTaxJarIntegrationQueryVariables>
export const UpsertTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DocumentTemplateType' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'content' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sectionUuid' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'entityIds' } },
          type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isUserSelectable' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertDocumentTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'documentTemplate' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'content' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'content' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'description' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sectionUuid' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'sectionUuid' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'entityIds' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'entityIds' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'isUserSelectable' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'isUserSelectable' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sectionUuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'entityIds' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertTemplateMutation, UpsertTemplateMutationVariables>
export const DeleteTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteDocumentTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sectionUuid' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteTemplateMutation, DeleteTemplateMutationVariables>
export const ListTemplatesByTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListTemplatesByType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'documentTemplates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DocumentTemplateFragment' } }],
            },
          },
        ],
      },
    },
    ...DocumentTemplateFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ListTemplatesByTypeQuery, ListTemplatesByTypeQueryVariables>
export const GetTemplateWithContentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTemplateWithContent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'documentTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DocumentTemplateFragment' } }],
            },
          },
        ],
      },
    },
    ...DocumentTemplateFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetTemplateWithContentQuery, GetTemplateWithContentQueryVariables>
export const GetDocumentMasterTemplatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDocumentMasterTemplates' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'documentMasterTemplates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDefault' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'entityIds' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDocumentMasterTemplatesQuery, GetDocumentMasterTemplatesQueryVariables>
export const GetDocumentMasterTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDocumentMasterTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'documentMasterTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DocumentMasterTemplateFragment' } }],
            },
          },
        ],
      },
    },
    ...DocumentMasterTemplateFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetDocumentMasterTemplateQuery, GetDocumentMasterTemplateQueryVariables>
export const UpsertDocumentMasterTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertDocumentMasterTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'documentMasterTemplate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputDocumentMasterTemplate' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertDocumentMasterTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'documentMasterTemplate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'documentMasterTemplate' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DocumentMasterTemplateFragment' } }],
            },
          },
        ],
      },
    },
    ...DocumentMasterTemplateFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpsertDocumentMasterTemplateMutation, UpsertDocumentMasterTemplateMutationVariables>
export const UpsertDocumentMasterTemplateV2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertDocumentMasterTemplateV2' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'documentMasterTemplate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputDocumentMasterTemplate' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertDocumentMasterTemplateV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'documentMasterTemplate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'documentMasterTemplate' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DocumentMasterTemplateFragment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isFullHtml' } },
              ],
            },
          },
        ],
      },
    },
    ...DocumentMasterTemplateFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpsertDocumentMasterTemplateV2Mutation, UpsertDocumentMasterTemplateV2MutationVariables>
export const UpdateDocumentMasterTemplateStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateDocumentMasterTemplateStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DocumentMasterTemplateStatus' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDocumentMasterTemplateStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DocumentMasterTemplateFragment' } }],
            },
          },
        ],
      },
    },
    ...DocumentMasterTemplateFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  UpdateDocumentMasterTemplateStatusMutation,
  UpdateDocumentMasterTemplateStatusMutationVariables
>
export const DeleteDocumentMasterTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteDocumentMasterTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteDocumentMasterTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DocumentMasterTemplateFragment' } }],
            },
          },
        ],
      },
    },
    ...DocumentMasterTemplateFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<DeleteDocumentMasterTemplateMutation, DeleteDocumentMasterTemplateMutationVariables>
export const GetDocumentSectionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDocumentSections' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'documentSections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DocumentSectionFragment' } }],
            },
          },
        ],
      },
    },
    ...DocumentSectionFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetDocumentSectionsQuery, GetDocumentSectionsQueryVariables>
export const UpsertDocumentSectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertDocumentSection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'documentSection' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputDocumentSection' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertDocumentSection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'documentSection' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'documentSection' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DocumentSectionFragment' } }],
            },
          },
        ],
      },
    },
    ...DocumentSectionFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpsertDocumentSectionMutation, UpsertDocumentSectionMutationVariables>
export const UpdateDocumentTemplateStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateDocumentTemplateStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'templateId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DocumentTemplateStatus' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDocumentTemplateStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'templateId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'templateId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DocumentTemplateFragment' } }],
            },
          },
        ],
      },
    },
    ...DocumentTemplateFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpdateDocumentTemplateStatusMutation, UpdateDocumentTemplateStatusMutationVariables>
export const GetPreviewMasterTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPreviewMasterTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'masterTemplateId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewMasterTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'masterTemplateId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'masterTemplateId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPreviewMasterTemplateQuery, GetPreviewMasterTemplateQueryVariables>
export const GetPreviewDocumentTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPreviewDocumentTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'templateId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewDocumentTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'templateId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'templateId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPreviewDocumentTemplateQuery, GetPreviewDocumentTemplateQueryVariables>
export const GetTenantJobDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTenantJob' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenantJob' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'TenantJobFields' } }],
            },
          },
        ],
      },
    },
    ...TenantJobFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetTenantJobQuery, GetTenantJobQueryVariables>
export const GetTenantJobAsAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTenantJobAsAdmin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tenantJobAsAdmin' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tenantId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'TenantJobFields' } }],
            },
          },
        ],
      },
    },
    ...TenantJobFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetTenantJobAsAdminQuery, GetTenantJobAsAdminQueryVariables>
export const RetryTenantJobAsAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RetryTenantJobAsAdmin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'retryTenantJobAsAdmin' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tenantId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'tenantId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'TenantJobFields' } }],
            },
          },
        ],
      },
    },
    ...TenantJobFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<RetryTenantJobAsAdminMutation, RetryTenantJobAsAdminMutationVariables>
export const GetActiveErpSyncJobsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetActiveErpSyncJobs' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeErpSyncTasks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'TenantJobFields' } }],
            },
          },
        ],
      },
    },
    ...TenantJobFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetActiveErpSyncJobsQuery, GetActiveErpSyncJobsQueryVariables>
export const GetCurrentUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCurrentUser' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ssoOnly' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCurrentUserQuery, GetCurrentUserQueryVariables>
export const GetUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserFragment' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'availableEntities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EntityFragment' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'hasAllEntitiesAccess' } },
              ],
            },
          },
        ],
      },
    },
    ...UserFragmentDoc.definitions,
    ...EntityFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetUserQuery, GetUserQueryVariables>
export const GetIsUserLoggedInDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetIsUserLoggedIn' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'userLoggedIn' } }],
      },
    },
  ],
} as unknown as DocumentNode<GetIsUserLoggedInQuery, GetIsUserLoggedInQueryVariables>
export const GetAssociatedUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAssociatedUsers' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'associatedUsers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenantName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAssociatedUsersQuery, GetAssociatedUsersQueryVariables>
export const UpsertUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'user' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputUser' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'user' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'user' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserFragment' } }],
            },
          },
        ],
      },
    },
    ...UserFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpsertUserMutation, UpsertUserMutationVariables>
export const UpsertUserV2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertUserV2' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'user' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'InputUserInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertUserV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'user' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'user' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserFragment' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'availableEntities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EntityFragment' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'hasAllEntitiesAccess' } },
              ],
            },
          },
        ],
      },
    },
    ...UserFragmentDoc.definitions,
    ...EntityFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpsertUserV2Mutation, UpsertUserV2MutationVariables>
export const DeleteUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserFragment' } }],
            },
          },
        ],
      },
    },
    ...UserFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<DeleteUserMutation, DeleteUserMutationVariables>
export const EnableUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EnableUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enableUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserFragment' } }],
            },
          },
        ],
      },
    },
    ...UserFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<EnableUserMutation, EnableUserMutationVariables>
export const DisableUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DisableUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'disableUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserFragment' } }],
            },
          },
        ],
      },
    },
    ...UserFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<DisableUserMutation, DisableUserMutationVariables>
export const ResendCognitoInvitationEmailForExistingUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResendCognitoInvitationEmailForExistingUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resendCognitoInvitationEmailForExistingUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResendCognitoInvitationEmailForExistingUserMutation,
  ResendCognitoInvitationEmailForExistingUserMutationVariables
>
export const GetUserTenantSessionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUserTenantSession' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'defaultTimeZone' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'availableEntities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EntityFragment' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'hasAllEntitiesAccess' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentTenant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'TenantFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dynamicFeatureListV2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uiConfiguration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UIConfigurationFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enabledPlatformFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'enabledAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabledByUserId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'platformFeature' } },
              ],
            },
          },
        ],
      },
    },
    ...EntityFragmentDoc.definitions,
    ...TenantFragmentDoc.definitions,
    ...UiConfigurationFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetUserTenantSessionQuery, GetUserTenantSessionQueryVariables>

export function useGetAccountQuery(options: Omit<Urql.UseQueryArgs<GetAccountQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAccountQuery, GetAccountQueryVariables>({ query: GetAccountDocument, ...options })
}

export function useGetCreditMemoAccountQuery(
  options: Omit<Urql.UseQueryArgs<GetCreditMemoAccountQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetCreditMemoAccountQuery, GetCreditMemoAccountQueryVariables>({
    query: GetCreditMemoAccountDocument,
    ...options,
  })
}

export function useGetSalesforceAccountByIdQuery(
  options: Omit<Urql.UseQueryArgs<GetSalesforceAccountByIdQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetSalesforceAccountByIdQuery, GetSalesforceAccountByIdQueryVariables>({
    query: GetSalesforceAccountByIdDocument,
    ...options,
  })
}

export function useUpsertAccountMutation() {
  return Urql.useMutation<UpsertAccountMutation, UpsertAccountMutationVariables>(UpsertAccountDocument)
}

export function useDeleteAccountMutation() {
  return Urql.useMutation<DeleteAccountMutation, DeleteAccountMutationVariables>(DeleteAccountDocument)
}

export function useUpsertAccountContactMutation() {
  return Urql.useMutation<UpsertAccountContactMutation, UpsertAccountContactMutationVariables>(
    UpsertAccountContactDocument
  )
}

export function useDeleteAccountContactMutation() {
  return Urql.useMutation<DeleteAccountContactMutation, DeleteAccountContactMutationVariables>(
    DeleteAccountContactDocument
  )
}

export function useSalesforceContactsForAccountQuery(
  options: Omit<Urql.UseQueryArgs<SalesforceContactsForAccountQueryVariables>, 'query'>
) {
  return Urql.useQuery<SalesforceContactsForAccountQuery, SalesforceContactsForAccountQueryVariables>({
    query: SalesforceContactsForAccountDocument,
    ...options,
  })
}

export function useUpsertCrmContactMutation() {
  return Urql.useMutation<UpsertCrmContactMutation, UpsertCrmContactMutationVariables>(UpsertCrmContactDocument)
}

export function useGetDraftInvoicesForCustomerAccountQuery(
  options: Omit<Urql.UseQueryArgs<GetDraftInvoicesForCustomerAccountQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetDraftInvoicesForCustomerAccountQuery, GetDraftInvoicesForCustomerAccountQueryVariables>({
    query: GetDraftInvoicesForCustomerAccountDocument,
    ...options,
  })
}

export function useGetAccountByCrmIdQuery(options: Omit<Urql.UseQueryArgs<GetAccountByCrmIdQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAccountByCrmIdQuery, GetAccountByCrmIdQueryVariables>({
    query: GetAccountByCrmIdDocument,
    ...options,
  })
}

export function useGetCrmContactsForAccountQuery(
  options: Omit<Urql.UseQueryArgs<GetCrmContactsForAccountQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetCrmContactsForAccountQuery, GetCrmContactsForAccountQueryVariables>({
    query: GetCrmContactsForAccountDocument,
    ...options,
  })
}

export function useGetOrCreateAccountByCrmIdMutation() {
  return Urql.useMutation<GetOrCreateAccountByCrmIdMutation, GetOrCreateAccountByCrmIdMutationVariables>(
    GetOrCreateAccountByCrmIdDocument
  )
}

export function useImportAccountByCrmIdMutation() {
  return Urql.useMutation<ImportAccountByCrmIdMutation, ImportAccountByCrmIdMutationVariables>(
    ImportAccountByCrmIdDocument
  )
}

export function useGetLedgerAccountsQuery(options?: Omit<Urql.UseQueryArgs<GetLedgerAccountsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetLedgerAccountsQuery, GetLedgerAccountsQueryVariables>({
    query: GetLedgerAccountsDocument,
    ...options,
  })
}

export function useGetAllLedgerAccountTemplatesQuery(
  options?: Omit<Urql.UseQueryArgs<GetAllLedgerAccountTemplatesQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetAllLedgerAccountTemplatesQuery, GetAllLedgerAccountTemplatesQueryVariables>({
    query: GetAllLedgerAccountTemplatesDocument,
    ...options,
  })
}

export function useUpsertLedgerAccountMutation() {
  return Urql.useMutation<UpsertLedgerAccountMutation, UpsertLedgerAccountMutationVariables>(
    UpsertLedgerAccountDocument
  )
}

export function useDeleteLedgerAccountMutation() {
  return Urql.useMutation<DeleteLedgerAccountMutation, DeleteLedgerAccountMutationVariables>(
    DeleteLedgerAccountDocument
  )
}

export function useGetAccountingPeriodQuery(
  options: Omit<Urql.UseQueryArgs<GetAccountingPeriodQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetAccountingPeriodQuery, GetAccountingPeriodQueryVariables>({
    query: GetAccountingPeriodDocument,
    ...options,
  })
}

export function useGetAccountingPeriodDetailQuery(
  options: Omit<Urql.UseQueryArgs<GetAccountingPeriodDetailQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetAccountingPeriodDetailQuery, GetAccountingPeriodDetailQueryVariables>({
    query: GetAccountingPeriodDetailDocument,
    ...options,
  })
}

export function useGetCurrentAccountingPeriodQuery(
  options?: Omit<Urql.UseQueryArgs<GetCurrentAccountingPeriodQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetCurrentAccountingPeriodQuery, GetCurrentAccountingPeriodQueryVariables>({
    query: GetCurrentAccountingPeriodDocument,
    ...options,
  })
}

export function useGetCurrentAccountingPeriodDetailQuery(
  options?: Omit<Urql.UseQueryArgs<GetCurrentAccountingPeriodDetailQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetCurrentAccountingPeriodDetailQuery, GetCurrentAccountingPeriodDetailQueryVariables>({
    query: GetCurrentAccountingPeriodDetailDocument,
    ...options,
  })
}

export function useGetCurrentAccountingPeriodByEntityIdQuery(
  options: Omit<Urql.UseQueryArgs<GetCurrentAccountingPeriodByEntityIdQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetCurrentAccountingPeriodByEntityIdQuery, GetCurrentAccountingPeriodByEntityIdQueryVariables>({
    query: GetCurrentAccountingPeriodByEntityIdDocument,
    ...options,
  })
}

export function useSpecifyCurrentAccountingPeriodMutation() {
  return Urql.useMutation<SpecifyCurrentAccountingPeriodMutation, SpecifyCurrentAccountingPeriodMutationVariables>(
    SpecifyCurrentAccountingPeriodDocument
  )
}

export function useGetAccountingEnabledQuery(
  options?: Omit<Urql.UseQueryArgs<GetAccountingEnabledQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetAccountingEnabledQuery, GetAccountingEnabledQueryVariables>({
    query: GetAccountingEnabledDocument,
    ...options,
  })
}

export function useEnableAccountingMutation() {
  return Urql.useMutation<EnableAccountingMutation, EnableAccountingMutationVariables>(EnableAccountingDocument)
}

export function useUpdateAccountingPeriodStatusMutation() {
  return Urql.useMutation<UpdateAccountingPeriodStatusMutation, UpdateAccountingPeriodStatusMutationVariables>(
    UpdateAccountingPeriodStatusDocument
  )
}

export function useInitiateAccountingPeriodCloseMutation() {
  return Urql.useMutation<InitiateAccountingPeriodCloseMutation, InitiateAccountingPeriodCloseMutationVariables>(
    InitiateAccountingPeriodCloseDocument
  )
}

export function useAbandonAccountingPeriodCloseMutation() {
  return Urql.useMutation<AbandonAccountingPeriodCloseMutation, AbandonAccountingPeriodCloseMutationVariables>(
    AbandonAccountingPeriodCloseDocument
  )
}

export function useGetRecentAccountingPeriodsQuery(
  options: Omit<Urql.UseQueryArgs<GetRecentAccountingPeriodsQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetRecentAccountingPeriodsQuery, GetRecentAccountingPeriodsQueryVariables>({
    query: GetRecentAccountingPeriodsDocument,
    ...options,
  })
}

export function useGenerateJournalEntriesMutation() {
  return Urql.useMutation<GenerateJournalEntriesMutation, GenerateJournalEntriesMutationVariables>(
    GenerateJournalEntriesDocument
  )
}

export function useSyncJournalEntriesToErpMutation() {
  return Urql.useMutation<SyncJournalEntriesToErpMutation, SyncJournalEntriesToErpMutationVariables>(
    SyncJournalEntriesToErpDocument
  )
}

export function useDeleteJournalEntriesFromErpMutation() {
  return Urql.useMutation<DeleteJournalEntriesFromErpMutation, DeleteJournalEntriesFromErpMutationVariables>(
    DeleteJournalEntriesFromErpDocument
  )
}

export function useGetErpIntegrationQuery(options?: Omit<Urql.UseQueryArgs<GetErpIntegrationQueryVariables>, 'query'>) {
  return Urql.useQuery<GetErpIntegrationQuery, GetErpIntegrationQueryVariables>({
    query: GetErpIntegrationDocument,
    ...options,
  })
}

export function useGetLedgerAccountsForPaymentBankAccountQuery(
  options?: Omit<Urql.UseQueryArgs<GetLedgerAccountsForPaymentBankAccountQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GetLedgerAccountsForPaymentBankAccountQuery,
    GetLedgerAccountsForPaymentBankAccountQueryVariables
  >({ query: GetLedgerAccountsForPaymentBankAccountDocument, ...options })
}

export function useGetPaymentBankAccountQuery(
  options: Omit<Urql.UseQueryArgs<GetPaymentBankAccountQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetPaymentBankAccountQuery, GetPaymentBankAccountQueryVariables>({
    query: GetPaymentBankAccountDocument,
    ...options,
  })
}

export function useGetApplicablePaymentBankAccountsForInvoicePaymentQuery(
  options: Omit<Urql.UseQueryArgs<GetApplicablePaymentBankAccountsForInvoicePaymentQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GetApplicablePaymentBankAccountsForInvoicePaymentQuery,
    GetApplicablePaymentBankAccountsForInvoicePaymentQueryVariables
  >({ query: GetApplicablePaymentBankAccountsForInvoicePaymentDocument, ...options })
}

export function useUpsertPaymentBankAccountMutation() {
  return Urql.useMutation<UpsertPaymentBankAccountMutation, UpsertPaymentBankAccountMutationVariables>(
    UpsertPaymentBankAccountDocument
  )
}

export function useDeletePaymentBankAccountMutation() {
  return Urql.useMutation<DeletePaymentBankAccountMutation, DeletePaymentBankAccountMutationVariables>(
    DeletePaymentBankAccountDocument
  )
}

export function useGetCurrentTenantQuery(options?: Omit<Urql.UseQueryArgs<GetCurrentTenantQueryVariables>, 'query'>) {
  return Urql.useQuery<GetCurrentTenantQuery, GetCurrentTenantQueryVariables>({
    query: GetCurrentTenantDocument,
    ...options,
  })
}

export function useGetTenantsQuery(options?: Omit<Urql.UseQueryArgs<GetTenantsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetTenantsQuery, GetTenantsQueryVariables>({ query: GetTenantsDocument, ...options })
}

export function useAddTenantMutation() {
  return Urql.useMutation<AddTenantMutation, AddTenantMutationVariables>(AddTenantDocument)
}

export function useGetAnrokIntegrationQuery(
  options?: Omit<Urql.UseQueryArgs<GetAnrokIntegrationQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetAnrokIntegrationQuery, GetAnrokIntegrationQueryVariables>({
    query: GetAnrokIntegrationDocument,
    ...options,
  })
}

export function useTestAnrokIntegrationMutation() {
  return Urql.useMutation<TestAnrokIntegrationMutation, TestAnrokIntegrationMutationVariables>(
    TestAnrokIntegrationDocument
  )
}

export function useUpsertAnrokIntegrationMutation() {
  return Urql.useMutation<UpsertAnrokIntegrationMutation, UpsertAnrokIntegrationMutationVariables>(
    UpsertAnrokIntegrationDocument
  )
}

export function useHasAnrokIntegrationQuery(
  options?: Omit<Urql.UseQueryArgs<HasAnrokIntegrationQueryVariables>, 'query'>
) {
  return Urql.useQuery<HasAnrokIntegrationQuery, HasAnrokIntegrationQueryVariables>({
    query: HasAnrokIntegrationDocument,
    ...options,
  })
}

export function useGetApiKeyQuery(options: Omit<Urql.UseQueryArgs<GetApiKeyQueryVariables>, 'query'>) {
  return Urql.useQuery<GetApiKeyQuery, GetApiKeyQueryVariables>({ query: GetApiKeyDocument, ...options })
}

export function useGetApiKeysQuery(options: Omit<Urql.UseQueryArgs<GetApiKeysQueryVariables>, 'query'>) {
  return Urql.useQuery<GetApiKeysQuery, GetApiKeysQueryVariables>({ query: GetApiKeysDocument, ...options })
}

export function useGenerateApiKeyV3Mutation() {
  return Urql.useMutation<GenerateApiKeyV3Mutation, GenerateApiKeyV3MutationVariables>(GenerateApiKeyV3Document)
}

export function useRevokeApiKeyV3Mutation() {
  return Urql.useMutation<RevokeApiKeyV3Mutation, RevokeApiKeyV3MutationVariables>(RevokeApiKeyV3Document)
}

export function useGetApprovalFlowQuery(options: Omit<Urql.UseQueryArgs<GetApprovalFlowQueryVariables>, 'query'>) {
  return Urql.useQuery<GetApprovalFlowQuery, GetApprovalFlowQueryVariables>({
    query: GetApprovalFlowDocument,
    ...options,
  })
}

export function useGetPendingApprovalFlowsForUserQuery(
  options?: Omit<Urql.UseQueryArgs<GetPendingApprovalFlowsForUserQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetPendingApprovalFlowsForUserQuery, GetPendingApprovalFlowsForUserQueryVariables>({
    query: GetPendingApprovalFlowsForUserDocument,
    ...options,
  })
}

export function useGetApprovalRolesQuery(options?: Omit<Urql.UseQueryArgs<GetApprovalRolesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetApprovalRolesQuery, GetApprovalRolesQueryVariables>({
    query: GetApprovalRolesDocument,
    ...options,
  })
}

export function useDeleteApprovalRoleMutation() {
  return Urql.useMutation<DeleteApprovalRoleMutation, DeleteApprovalRoleMutationVariables>(DeleteApprovalRoleDocument)
}

export function useUpsertApprovalRoleMutation() {
  return Urql.useMutation<UpsertApprovalRoleMutation, UpsertApprovalRoleMutationVariables>(UpsertApprovalRoleDocument)
}

export function useUpsertApprovalFlowMutation() {
  return Urql.useMutation<UpsertApprovalFlowMutation, UpsertApprovalFlowMutationVariables>(UpsertApprovalFlowDocument)
}

export function useDeleteApprovalFlowMutation() {
  return Urql.useMutation<DeleteApprovalFlowMutation, DeleteApprovalFlowMutationVariables>(DeleteApprovalFlowDocument)
}

export function useUpdateOrderApprovalStateMutation() {
  return Urql.useMutation<UpdateOrderApprovalStateMutation, UpdateOrderApprovalStateMutationVariables>(
    UpdateOrderApprovalStateDocument
  )
}

export function useUpdateApprovalFlowStatusChangeWithCodeMutation() {
  return Urql.useMutation<
    UpdateApprovalFlowStatusChangeWithCodeMutation,
    UpdateApprovalFlowStatusChangeWithCodeMutationVariables
  >(UpdateApprovalFlowStatusChangeWithCodeDocument)
}

export function useUpsertApprovalFlowSubmitterNoteMutation() {
  return Urql.useMutation<UpsertApprovalFlowSubmitterNoteMutation, UpsertApprovalFlowSubmitterNoteMutationVariables>(
    UpsertApprovalFlowSubmitterNoteDocument
  )
}

export function useGetAttachmentsQuery(options: Omit<Urql.UseQueryArgs<GetAttachmentsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAttachmentsQuery, GetAttachmentsQueryVariables>({ query: GetAttachmentsDocument, ...options })
}

export function useGetUserAuthInfoQuery(options: Omit<Urql.UseQueryArgs<GetUserAuthInfoQueryVariables>, 'query'>) {
  return Urql.useQuery<GetUserAuthInfoQuery, GetUserAuthInfoQueryVariables>({
    query: GetUserAuthInfoDocument,
    ...options,
  })
}

export function useEmailLinkForLoginMutation() {
  return Urql.useMutation<EmailLinkForLoginMutation, EmailLinkForLoginMutationVariables>(EmailLinkForLoginDocument)
}

export function useHasAvalaraIntegrationQuery(
  options?: Omit<Urql.UseQueryArgs<HasAvalaraIntegrationQueryVariables>, 'query'>
) {
  return Urql.useQuery<HasAvalaraIntegrationQuery, HasAvalaraIntegrationQueryVariables>({
    query: HasAvalaraIntegrationDocument,
    ...options,
  })
}

export function useGetAvalaraIntegrationQuery(
  options?: Omit<Urql.UseQueryArgs<GetAvalaraIntegrationQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetAvalaraIntegrationQuery, GetAvalaraIntegrationQueryVariables>({
    query: GetAvalaraIntegrationDocument,
    ...options,
  })
}

export function usePingAvalaraMutation() {
  return Urql.useMutation<PingAvalaraMutation, PingAvalaraMutationVariables>(PingAvalaraDocument)
}

export function useUpsertAvalaraMutation() {
  return Urql.useMutation<UpsertAvalaraMutation, UpsertAvalaraMutationVariables>(UpsertAvalaraDocument)
}

export function useLastUsageArrivalCheckpointQuery(
  options?: Omit<Urql.UseQueryArgs<LastUsageArrivalCheckpointQueryVariables>, 'query'>
) {
  return Urql.useQuery<LastUsageArrivalCheckpointQuery, LastUsageArrivalCheckpointQueryVariables>({
    query: LastUsageArrivalCheckpointDocument,
    ...options,
  })
}

export function useGetBulkInvoiceRunQuery(options: Omit<Urql.UseQueryArgs<GetBulkInvoiceRunQueryVariables>, 'query'>) {
  return Urql.useQuery<GetBulkInvoiceRunQuery, GetBulkInvoiceRunQueryVariables>({
    query: GetBulkInvoiceRunDocument,
    ...options,
  })
}

export function useGetBulkInvoiceRunItemsQuery(
  options: Omit<Urql.UseQueryArgs<GetBulkInvoiceRunItemsQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetBulkInvoiceRunItemsQuery, GetBulkInvoiceRunItemsQueryVariables>({
    query: GetBulkInvoiceRunItemsDocument,
    ...options,
  })
}

export function useAddBulkInvoiceRunMutation() {
  return Urql.useMutation<AddBulkInvoiceRunMutation, AddBulkInvoiceRunMutationVariables>(AddBulkInvoiceRunDocument)
}

export function usePostBulkInvoiceRunMutation() {
  return Urql.useMutation<PostBulkInvoiceRunMutation, PostBulkInvoiceRunMutationVariables>(PostBulkInvoiceRunDocument)
}

export function useGenerateCancelAndRestructureMutation() {
  return Urql.useMutation<GenerateCancelAndRestructureMutation, GenerateCancelAndRestructureMutationVariables>(
    GenerateCancelAndRestructureDocument
  )
}

export function useUpsertCancelAndRestructureMutation() {
  return Urql.useMutation<UpsertCancelAndRestructureMutation, UpsertCancelAndRestructureMutationVariables>(
    UpsertCancelAndRestructureDocument
  )
}

export function useDryRunUpsertCancelAndRestructureMutation() {
  return Urql.useMutation<DryRunUpsertCancelAndRestructureMutation, DryRunUpsertCancelAndRestructureMutationVariables>(
    DryRunUpsertCancelAndRestructureDocument
  )
}

export function useGetCancelAndRestructureQuery(
  options: Omit<Urql.UseQueryArgs<GetCancelAndRestructureQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetCancelAndRestructureQuery, GetCancelAndRestructureQueryVariables>({
    query: GetCancelAndRestructureDocument,
    ...options,
  })
}

export function useUpdateCancelAndRestructureOrderStatusMutation() {
  return Urql.useMutation<
    UpdateCancelAndRestructureOrderStatusMutation,
    UpdateCancelAndRestructureOrderStatusMutationVariables
  >(UpdateCancelAndRestructureOrderStatusDocument)
}

export function useDeleteCancelAndRestructureOrderMutation() {
  return Urql.useMutation<DeleteCancelAndRestructureOrderMutation, DeleteCancelAndRestructureOrderMutationVariables>(
    DeleteCancelAndRestructureOrderDocument
  )
}

export function useAddChargeMutation() {
  return Urql.useMutation<AddChargeMutation, AddChargeMutationVariables>(AddChargeDocument)
}

export function useUpdateChargeMutation() {
  return Urql.useMutation<UpdateChargeMutation, UpdateChargeMutationVariables>(UpdateChargeDocument)
}

export function useUpdateChargePartialMutation() {
  return Urql.useMutation<UpdateChargePartialMutation, UpdateChargePartialMutationVariables>(
    UpdateChargePartialDocument
  )
}

export function useDeleteChargeMutation() {
  return Urql.useMutation<DeleteChargeMutation, DeleteChargeMutationVariables>(DeleteChargeDocument)
}

export function useUpsertChargeDefaultAttributeReferencesMutation() {
  return Urql.useMutation<
    UpsertChargeDefaultAttributeReferencesMutation,
    UpsertChargeDefaultAttributeReferencesMutationVariables
  >(UpsertChargeDefaultAttributeReferencesDocument)
}

export function useGetChargeQuery(options: Omit<Urql.UseQueryArgs<GetChargeQueryVariables>, 'query'>) {
  return Urql.useQuery<GetChargeQuery, GetChargeQueryVariables>({ query: GetChargeDocument, ...options })
}

export function useGetChargeDefaultAttributeReferencesQuery(
  options?: Omit<Urql.UseQueryArgs<GetChargeDefaultAttributeReferencesQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetChargeDefaultAttributeReferencesQuery, GetChargeDefaultAttributeReferencesQueryVariables>({
    query: GetChargeDefaultAttributeReferencesDocument,
    ...options,
  })
}

export function useCreateUpsellWithEarlyRenewalMutation() {
  return Urql.useMutation<CreateUpsellWithEarlyRenewalMutation, CreateUpsellWithEarlyRenewalMutationVariables>(
    CreateUpsellWithEarlyRenewalDocument
  )
}

export function useGetCompositeOrderQuery(options: Omit<Urql.UseQueryArgs<GetCompositeOrderQueryVariables>, 'query'>) {
  return Urql.useQuery<GetCompositeOrderQuery, GetCompositeOrderQueryVariables>({
    query: GetCompositeOrderDocument,
    ...options,
  })
}

export function useUpdateCompositeOrderStatusMutation() {
  return Urql.useMutation<UpdateCompositeOrderStatusMutation, UpdateCompositeOrderStatusMutationVariables>(
    UpdateCompositeOrderStatusDocument
  )
}

export function useCreateCompositeOrderDocumentMutation() {
  return Urql.useMutation<CreateCompositeOrderDocumentMutation, CreateCompositeOrderDocumentMutationVariables>(
    CreateCompositeOrderDocumentDocument
  )
}

export function useUpdateCompositeOrderFieldsMutation() {
  return Urql.useMutation<UpdateCompositeOrderFieldsMutation, UpdateCompositeOrderFieldsMutationVariables>(
    UpdateCompositeOrderFieldsDocument
  )
}

export function useGetCreditMemoQuery(options: Omit<Urql.UseQueryArgs<GetCreditMemoQueryVariables>, 'query'>) {
  return Urql.useQuery<GetCreditMemoQuery, GetCreditMemoQueryVariables>({ query: GetCreditMemoDocument, ...options })
}

export function usePostCreditMemoMutation() {
  return Urql.useMutation<PostCreditMemoMutation, PostCreditMemoMutationVariables>(PostCreditMemoDocument)
}

export function useDeleteCreditMemoMutation() {
  return Urql.useMutation<DeleteCreditMemoMutation, DeleteCreditMemoMutationVariables>(DeleteCreditMemoDocument)
}

export function useApplyCreditMemoMutation() {
  return Urql.useMutation<ApplyCreditMemoMutation, ApplyCreditMemoMutationVariables>(ApplyCreditMemoDocument)
}

export function useCreateStandaloneCreditMemoMutation() {
  return Urql.useMutation<CreateStandaloneCreditMemoMutation, CreateStandaloneCreditMemoMutationVariables>(
    CreateStandaloneCreditMemoDocument
  )
}

export function useUpdateDraftCreditMemoMutation() {
  return Urql.useMutation<UpdateDraftCreditMemoMutation, UpdateDraftCreditMemoMutationVariables>(
    UpdateDraftCreditMemoDocument
  )
}

export function useSendCreditMemoEmailMutation() {
  return Urql.useMutation<SendCreditMemoEmailMutation, SendCreditMemoEmailMutationVariables>(
    SendCreditMemoEmailDocument
  )
}

export function useGetRefundsQuery(options?: Omit<Urql.UseQueryArgs<GetRefundsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetRefundsQuery, GetRefundsQueryVariables>({ query: GetRefundsDocument, ...options })
}

export function useCreateRefundMutation() {
  return Urql.useMutation<CreateRefundMutation, CreateRefundMutationVariables>(CreateRefundDocument)
}

export function useSyncCreditMemoToErpMutation() {
  return Urql.useMutation<SyncCreditMemoToErpMutation, SyncCreditMemoToErpMutationVariables>(
    SyncCreditMemoToErpDocument
  )
}

export function useUnapplyCreditMemoMutation() {
  return Urql.useMutation<UnapplyCreditMemoMutation, UnapplyCreditMemoMutationVariables>(UnapplyCreditMemoDocument)
}

export function useGetCustomFieldDefinitionsQuery(
  options: Omit<Urql.UseQueryArgs<GetCustomFieldDefinitionsQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetCustomFieldDefinitionsQuery, GetCustomFieldDefinitionsQueryVariables>({
    query: GetCustomFieldDefinitionsDocument,
    ...options,
  })
}

export function useCreateCustomFieldDefinitionMutation() {
  return Urql.useMutation<CreateCustomFieldDefinitionMutation, CreateCustomFieldDefinitionMutationVariables>(
    CreateCustomFieldDefinitionDocument
  )
}

export function useUpdateCustomFieldDefinitionMutation() {
  return Urql.useMutation<UpdateCustomFieldDefinitionMutation, UpdateCustomFieldDefinitionMutationVariables>(
    UpdateCustomFieldDefinitionDocument
  )
}

export function useDeleteCustomFieldDefinitionMutation() {
  return Urql.useMutation<DeleteCustomFieldDefinitionMutation, DeleteCustomFieldDefinitionMutationVariables>(
    DeleteCustomFieldDefinitionDocument
  )
}

export function useGetCustomFieldsQuery(options: Omit<Urql.UseQueryArgs<GetCustomFieldsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetCustomFieldsQuery, GetCustomFieldsQueryVariables>({
    query: GetCustomFieldsDocument,
    ...options,
  })
}

export function useUpdateCustomFieldsMutation() {
  return Urql.useMutation<UpdateCustomFieldsMutation, UpdateCustomFieldsMutationVariables>(UpdateCustomFieldsDocument)
}

export function useGetCustomFieldsBatchedQuery(
  options: Omit<Urql.UseQueryArgs<GetCustomFieldsBatchedQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetCustomFieldsBatchedQuery, GetCustomFieldsBatchedQueryVariables>({
    query: GetCustomFieldsBatchedDocument,
    ...options,
  })
}

export function usePlanAdditionCustomizationQuery(
  options: Omit<Urql.UseQueryArgs<PlanAdditionCustomizationQueryVariables>, 'query'>
) {
  return Urql.useQuery<PlanAdditionCustomizationQuery, PlanAdditionCustomizationQueryVariables>({
    query: PlanAdditionCustomizationDocument,
    ...options,
  })
}

export function useGetDefaultBulkInvoiceRunSelectorQuery(
  options?: Omit<Urql.UseQueryArgs<GetDefaultBulkInvoiceRunSelectorQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetDefaultBulkInvoiceRunSelectorQuery, GetDefaultBulkInvoiceRunSelectorQueryVariables>({
    query: GetDefaultBulkInvoiceRunSelectorDocument,
    ...options,
  })
}

export function useGenerateDocumentLinkMutation() {
  return Urql.useMutation<GenerateDocumentLinkMutation, GenerateDocumentLinkMutationVariables>(
    GenerateDocumentLinkDocument
  )
}

export function useWebOrderFormDetailQuery(
  options: Omit<Urql.UseQueryArgs<WebOrderFormDetailQueryVariables>, 'query'>
) {
  return Urql.useQuery<WebOrderFormDetailQuery, WebOrderFormDetailQueryVariables>({
    query: WebOrderFormDetailDocument,
    ...options,
  })
}

export function useCreateClmThreadMutation() {
  return Urql.useMutation<CreateClmThreadMutation, CreateClmThreadMutationVariables>(CreateClmThreadDocument)
}

export function useAddMessageToClmThreadMutation() {
  return Urql.useMutation<AddMessageToClmThreadMutation, AddMessageToClmThreadMutationVariables>(
    AddMessageToClmThreadDocument
  )
}

export function useResolveThreadMutation() {
  return Urql.useMutation<ResolveThreadMutation, ResolveThreadMutationVariables>(ResolveThreadDocument)
}

export function useHasDocuSignIntegrationQuery(
  options?: Omit<Urql.UseQueryArgs<HasDocuSignIntegrationQueryVariables>, 'query'>
) {
  return Urql.useQuery<HasDocuSignIntegrationQuery, HasDocuSignIntegrationQueryVariables>({
    query: HasDocuSignIntegrationDocument,
    ...options,
  })
}

export function useGetDocuSignIntegrationQuery(
  options?: Omit<Urql.UseQueryArgs<GetDocuSignIntegrationQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetDocuSignIntegrationQuery, GetDocuSignIntegrationQueryVariables>({
    query: GetDocuSignIntegrationDocument,
    ...options,
  })
}

export function useInitiateDocuSignIntegrationMutation() {
  return Urql.useMutation<InitiateDocuSignIntegrationMutation, InitiateDocuSignIntegrationMutationVariables>(
    InitiateDocuSignIntegrationDocument
  )
}

export function useCompleteDocuSignIntegrationMutation() {
  return Urql.useMutation<CompleteDocuSignIntegrationMutation, CompleteDocuSignIntegrationMutationVariables>(
    CompleteDocuSignIntegrationDocument
  )
}

export function useDeleteDocuSignIntegrationMutation() {
  return Urql.useMutation<DeleteDocuSignIntegrationMutation, DeleteDocuSignIntegrationMutationVariables>(
    DeleteDocuSignIntegrationDocument
  )
}

export function useSendEmailForDocusignMutation() {
  return Urql.useMutation<SendEmailForDocusignMutation, SendEmailForDocusignMutationVariables>(
    SendEmailForDocusignDocument
  )
}

export function useGetEmailLogsQuery(options?: Omit<Urql.UseQueryArgs<GetEmailLogsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetEmailLogsQuery, GetEmailLogsQueryVariables>({ query: GetEmailLogsDocument, ...options })
}

export function useGetEntitiesQuery(options?: Omit<Urql.UseQueryArgs<GetEntitiesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetEntitiesQuery, GetEntitiesQueryVariables>({ query: GetEntitiesDocument, ...options })
}

export function useGetEntityQuery(options: Omit<Urql.UseQueryArgs<GetEntityQueryVariables>, 'query'>) {
  return Urql.useQuery<GetEntityQuery, GetEntityQueryVariables>({ query: GetEntityDocument, ...options })
}

export function useGetEntityWithConfigQuery(
  options: Omit<Urql.UseQueryArgs<GetEntityWithConfigQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetEntityWithConfigQuery, GetEntityWithConfigQueryVariables>({
    query: GetEntityWithConfigDocument,
    ...options,
  })
}

export function useAddEntityMutation() {
  return Urql.useMutation<AddEntityMutation, AddEntityMutationVariables>(AddEntityDocument)
}

export function useUpdateEntityMutation() {
  return Urql.useMutation<UpdateEntityMutation, UpdateEntityMutationVariables>(UpdateEntityDocument)
}

export function useDeleteEntityMutation() {
  return Urql.useMutation<DeleteEntityMutation, DeleteEntityMutationVariables>(DeleteEntityDocument)
}

export function useGetElectronicSignatureQuery(
  options: Omit<Urql.UseQueryArgs<GetElectronicSignatureQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetElectronicSignatureQuery, GetElectronicSignatureQueryVariables>({
    query: GetElectronicSignatureDocument,
    ...options,
  })
}

export function useSendEmailForEsignMutation() {
  return Urql.useMutation<SendEmailForEsignMutation, SendEmailForEsignMutationVariables>(SendEmailForEsignDocument)
}

export function useVoidEsignatureDocumentMutation() {
  return Urql.useMutation<VoidEsignatureDocumentMutation, VoidEsignatureDocumentMutationVariables>(
    VoidEsignatureDocumentDocument
  )
}

export function useResendEsignatureDocumentMutation() {
  return Urql.useMutation<ResendEsignatureDocumentMutation, ResendEsignatureDocumentMutationVariables>(
    ResendEsignatureDocumentDocument
  )
}

export function useDeleteTenantSignatoryMutation() {
  return Urql.useMutation<DeleteTenantSignatoryMutation, DeleteTenantSignatoryMutationVariables>(
    DeleteTenantSignatoryDocument
  )
}

export function useEnableEsignMutation() {
  return Urql.useMutation<EnableEsignMutation, EnableEsignMutationVariables>(EnableEsignDocument)
}

export function useEmailContactsForAccountQuery(
  options?: Omit<Urql.UseQueryArgs<EmailContactsForAccountQueryVariables>, 'query'>
) {
  return Urql.useQuery<EmailContactsForAccountQuery, EmailContactsForAccountQueryVariables>({
    query: EmailContactsForAccountDocument,
    ...options,
  })
}

export function useGetElectronicSignatureAuditLogsQuery(
  options: Omit<Urql.UseQueryArgs<GetElectronicSignatureAuditLogsQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetElectronicSignatureAuditLogsQuery, GetElectronicSignatureAuditLogsQueryVariables>({
    query: GetElectronicSignatureAuditLogsDocument,
    ...options,
  })
}

export function useGetElectronicSignatureProviderQuery(
  options?: Omit<Urql.UseQueryArgs<GetElectronicSignatureProviderQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetElectronicSignatureProviderQuery, GetElectronicSignatureProviderQueryVariables>({
    query: GetElectronicSignatureProviderDocument,
    ...options,
  })
}

export function useUpsertEsignDetailsMutation() {
  return Urql.useMutation<UpsertEsignDetailsMutation, UpsertEsignDetailsMutationVariables>(UpsertEsignDetailsDocument)
}

export function useGetEsignDetailsQuery(options: Omit<Urql.UseQueryArgs<GetEsignDetailsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetEsignDetailsQuery, GetEsignDetailsQueryVariables>({
    query: GetEsignDetailsDocument,
    ...options,
  })
}

export function useGetDynamicFeatureEnabledQuery(
  options: Omit<Urql.UseQueryArgs<GetDynamicFeatureEnabledQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetDynamicFeatureEnabledQuery, GetDynamicFeatureEnabledQueryVariables>({
    query: GetDynamicFeatureEnabledDocument,
    ...options,
  })
}

export function useGetCurrencyTypeSettingQuery(
  options?: Omit<Urql.UseQueryArgs<GetCurrencyTypeSettingQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetCurrencyTypeSettingQuery, GetCurrencyTypeSettingQueryVariables>({
    query: GetCurrencyTypeSettingDocument,
    ...options,
  })
}

export function useGetCurrencyConversionRatesQuery(
  options?: Omit<Urql.UseQueryArgs<GetCurrencyConversionRatesQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetCurrencyConversionRatesQuery, GetCurrencyConversionRatesQueryVariables>({
    query: GetCurrencyConversionRatesDocument,
    ...options,
  })
}

export function useGetCurrencyConversionRateByIdQuery(
  options: Omit<Urql.UseQueryArgs<GetCurrencyConversionRateByIdQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetCurrencyConversionRateByIdQuery, GetCurrencyConversionRateByIdQueryVariables>({
    query: GetCurrencyConversionRateByIdDocument,
    ...options,
  })
}

export function useGetCurrencyConversionRatesForGivenCurrencyPairQuery(
  options: Omit<Urql.UseQueryArgs<GetCurrencyConversionRatesForGivenCurrencyPairQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GetCurrencyConversionRatesForGivenCurrencyPairQuery,
    GetCurrencyConversionRatesForGivenCurrencyPairQueryVariables
  >({ query: GetCurrencyConversionRatesForGivenCurrencyPairDocument, ...options })
}

export function useUpsertCurrencyTypeSettingMutation() {
  return Urql.useMutation<UpsertCurrencyTypeSettingMutation, UpsertCurrencyTypeSettingMutationVariables>(
    UpsertCurrencyTypeSettingDocument
  )
}

export function useUpsertCurrencyConversionRateMutation() {
  return Urql.useMutation<UpsertCurrencyConversionRateMutation, UpsertCurrencyConversionRateMutationVariables>(
    UpsertCurrencyConversionRateDocument
  )
}

export function useGetHubSpotIntegrationQuery(
  options?: Omit<Urql.UseQueryArgs<GetHubSpotIntegrationQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetHubSpotIntegrationQuery, GetHubSpotIntegrationQueryVariables>({
    query: GetHubSpotIntegrationDocument,
    ...options,
  })
}

export function useInitiateHubSpotIntegrationMutation() {
  return Urql.useMutation<InitiateHubSpotIntegrationMutation, InitiateHubSpotIntegrationMutationVariables>(
    InitiateHubSpotIntegrationDocument
  )
}

export function useCompleteHubSpotIntegrationMutation() {
  return Urql.useMutation<CompleteHubSpotIntegrationMutation, CompleteHubSpotIntegrationMutationVariables>(
    CompleteHubSpotIntegrationDocument
  )
}

export function useDeleteHubSpotIntegrationMutation() {
  return Urql.useMutation<DeleteHubSpotIntegrationMutation, DeleteHubSpotIntegrationMutationVariables>(
    DeleteHubSpotIntegrationDocument
  )
}

export function useGetAccountByHubSpotCompanyIdQuery(
  options: Omit<Urql.UseQueryArgs<GetAccountByHubSpotCompanyIdQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetAccountByHubSpotCompanyIdQuery, GetAccountByHubSpotCompanyIdQueryVariables>({
    query: GetAccountByHubSpotCompanyIdDocument,
    ...options,
  })
}

export function useSyncOrderToHubSpotMutation() {
  return Urql.useMutation<SyncOrderToHubSpotMutation, SyncOrderToHubSpotMutationVariables>(SyncOrderToHubSpotDocument)
}

export function useUpdatePrimaryOrderIdForHubSpotOpportunityMutation() {
  return Urql.useMutation<
    UpdatePrimaryOrderIdForHubSpotOpportunityMutation,
    UpdatePrimaryOrderIdForHubSpotOpportunityMutationVariables
  >(UpdatePrimaryOrderIdForHubSpotOpportunityDocument)
}

export function useSetupHubSpotCustomPropertiesMutation() {
  return Urql.useMutation<SetupHubSpotCustomPropertiesMutation, SetupHubSpotCustomPropertiesMutationVariables>(
    SetupHubSpotCustomPropertiesDocument
  )
}

export function useVerifyHubSpotSetupQuery(
  options?: Omit<Urql.UseQueryArgs<VerifyHubSpotSetupQueryVariables>, 'query'>
) {
  return Urql.useQuery<VerifyHubSpotSetupQuery, VerifyHubSpotSetupQueryVariables>({
    query: VerifyHubSpotSetupDocument,
    ...options,
  })
}

export function useSetupHubSpotCustomObjectsMutation() {
  return Urql.useMutation<SetupHubSpotCustomObjectsMutation, SetupHubSpotCustomObjectsMutationVariables>(
    SetupHubSpotCustomObjectsDocument
  )
}

export function useGetDataImportsQuery(options?: Omit<Urql.UseQueryArgs<GetDataImportsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetDataImportsQuery, GetDataImportsQueryVariables>({ query: GetDataImportsDocument, ...options })
}

export function useGetDataImportQuery(options: Omit<Urql.UseQueryArgs<GetDataImportQueryVariables>, 'query'>) {
  return Urql.useQuery<GetDataImportQuery, GetDataImportQueryVariables>({ query: GetDataImportDocument, ...options })
}

export function useGetFlatfileSpacesQuery(options?: Omit<Urql.UseQueryArgs<GetFlatfileSpacesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetFlatfileSpacesQuery, GetFlatfileSpacesQueryVariables>({
    query: GetFlatfileSpacesDocument,
    ...options,
  })
}

export function useGetFlatfileSpaceQuery(options: Omit<Urql.UseQueryArgs<GetFlatfileSpaceQueryVariables>, 'query'>) {
  return Urql.useQuery<GetFlatfileSpaceQuery, GetFlatfileSpaceQueryVariables>({
    query: GetFlatfileSpaceDocument,
    ...options,
  })
}

export function useProcessImportMutation() {
  return Urql.useMutation<ProcessImportMutation, ProcessImportMutationVariables>(ProcessImportDocument)
}

export function useCreateFlatfileWorkbookMutation() {
  return Urql.useMutation<CreateFlatfileWorkbookMutation, CreateFlatfileWorkbookMutationVariables>(
    CreateFlatfileWorkbookDocument
  )
}

export function useRenewApiKeyForFlatfileSpaceMutation() {
  return Urql.useMutation<RenewApiKeyForFlatfileSpaceMutation, RenewApiKeyForFlatfileSpaceMutationVariables>(
    RenewApiKeyForFlatfileSpaceDocument
  )
}

export function useDeleteFlatfileSpaceMutation() {
  return Urql.useMutation<DeleteFlatfileSpaceMutation, DeleteFlatfileSpaceMutationVariables>(
    DeleteFlatfileSpaceDocument
  )
}

export function useGetIntegrationQuery(options: Omit<Urql.UseQueryArgs<GetIntegrationQueryVariables>, 'query'>) {
  return Urql.useQuery<GetIntegrationQuery, GetIntegrationQueryVariables>({ query: GetIntegrationDocument, ...options })
}

export function useAddIntegrationMutation() {
  return Urql.useMutation<AddIntegrationMutation, AddIntegrationMutationVariables>(AddIntegrationDocument)
}

export function useCompleteIntegrationMutation() {
  return Urql.useMutation<CompleteIntegrationMutation, CompleteIntegrationMutationVariables>(
    CompleteIntegrationDocument
  )
}

export function useDeleteIntegrationMutation() {
  return Urql.useMutation<DeleteIntegrationMutation, DeleteIntegrationMutationVariables>(DeleteIntegrationDocument)
}

export function useUpdateInvoiceMutation() {
  return Urql.useMutation<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>(UpdateInvoiceDocument)
}

export function useGetInvoiceQuery(options: Omit<Urql.UseQueryArgs<GetInvoiceQueryVariables>, 'query'>) {
  return Urql.useQuery<GetInvoiceQuery, GetInvoiceQueryVariables>({ query: GetInvoiceDocument, ...options })
}

export function usePreviewUnbilledUsageQuery(
  options: Omit<Urql.UseQueryArgs<PreviewUnbilledUsageQueryVariables>, 'query'>
) {
  return Urql.useQuery<PreviewUnbilledUsageQuery, PreviewUnbilledUsageQueryVariables>({
    query: PreviewUnbilledUsageDocument,
    ...options,
  })
}

export function useUsageForSubscriptionChargeQuery(
  options: Omit<Urql.UseQueryArgs<UsageForSubscriptionChargeQueryVariables>, 'query'>
) {
  return Urql.useQuery<UsageForSubscriptionChargeQuery, UsageForSubscriptionChargeQueryVariables>({
    query: UsageForSubscriptionChargeDocument,
    ...options,
  })
}

export function useGetInvoiceSettlementApplicationsQuery(
  options: Omit<Urql.UseQueryArgs<GetInvoiceSettlementApplicationsQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetInvoiceSettlementApplicationsQuery, GetInvoiceSettlementApplicationsQueryVariables>({
    query: GetInvoiceSettlementApplicationsDocument,
    ...options,
  })
}

export function useGetOpenInvoicesReportOverviewQuery(
  options?: Omit<Urql.UseQueryArgs<GetOpenInvoicesReportOverviewQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetOpenInvoicesReportOverviewQuery, GetOpenInvoicesReportOverviewQueryVariables>({
    query: GetOpenInvoicesReportOverviewDocument,
    ...options,
  })
}

export function usePostInvoiceMutation() {
  return Urql.useMutation<PostInvoiceMutation, PostInvoiceMutationVariables>(PostInvoiceDocument)
}

export function useConvertNegativeInvoiceMutation() {
  return Urql.useMutation<ConvertNegativeInvoiceMutation, ConvertNegativeInvoiceMutationVariables>(
    ConvertNegativeInvoiceDocument
  )
}

export function useDeleteInvoiceMutation() {
  return Urql.useMutation<DeleteInvoiceMutation, DeleteInvoiceMutationVariables>(DeleteInvoiceDocument)
}

export function useApplyManualPaymentMutation() {
  return Urql.useMutation<ApplyManualPaymentMutation, ApplyManualPaymentMutationVariables>(ApplyManualPaymentDocument)
}

export function useSendEmailMutation() {
  return Urql.useMutation<SendEmailMutation, SendEmailMutationVariables>(SendEmailDocument)
}

export function useGetInvoiceDunningDetailsQuery(
  options: Omit<Urql.UseQueryArgs<GetInvoiceDunningDetailsQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetInvoiceDunningDetailsQuery, GetInvoiceDunningDetailsQueryVariables>({
    query: GetInvoiceDunningDetailsDocument,
    ...options,
  })
}

export function useVoidInvoiceMutation() {
  return Urql.useMutation<VoidInvoiceMutation, VoidInvoiceMutationVariables>(VoidInvoiceDocument)
}

export function useSyncInvoiceToErpMutation() {
  return Urql.useMutation<SyncInvoiceToErpMutation, SyncInvoiceToErpMutationVariables>(SyncInvoiceToErpDocument)
}

export function useGetErpInvoiceQuery(options: Omit<Urql.UseQueryArgs<GetErpInvoiceQueryVariables>, 'query'>) {
  return Urql.useQuery<GetErpInvoiceQuery, GetErpInvoiceQueryVariables>({ query: GetErpInvoiceDocument, ...options })
}

export function useSyncVoidInvoiceToErpMutation() {
  return Urql.useMutation<SyncVoidInvoiceToErpMutation, SyncVoidInvoiceToErpMutationVariables>(
    SyncVoidInvoiceToErpDocument
  )
}

export function useGetOrderInvoicePreviewQuery(
  options: Omit<Urql.UseQueryArgs<GetOrderInvoicePreviewQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetOrderInvoicePreviewQuery, GetOrderInvoicePreviewQueryVariables>({
    query: GetOrderInvoicePreviewDocument,
    ...options,
  })
}

export function useGetEmailSentForInvoiceQuery(
  options: Omit<Urql.UseQueryArgs<GetEmailSentForInvoiceQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetEmailSentForInvoiceQuery, GetEmailSentForInvoiceQueryVariables>({
    query: GetEmailSentForInvoiceDocument,
    ...options,
  })
}

export function useGetNotificationQuery(options: Omit<Urql.UseQueryArgs<GetNotificationQueryVariables>, 'query'>) {
  return Urql.useQuery<GetNotificationQuery, GetNotificationQueryVariables>({
    query: GetNotificationDocument,
    ...options,
  })
}

export function useGetNotificationsQuery(options?: Omit<Urql.UseQueryArgs<GetNotificationsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>({
    query: GetNotificationsDocument,
    ...options,
  })
}

export function useGetNotificationInstanceQuery(
  options: Omit<Urql.UseQueryArgs<GetNotificationInstanceQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetNotificationInstanceQuery, GetNotificationInstanceQueryVariables>({
    query: GetNotificationInstanceDocument,
    ...options,
  })
}

export function useGetWebhookSigningKeyQuery(
  options: Omit<Urql.UseQueryArgs<GetWebhookSigningKeyQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetWebhookSigningKeyQuery, GetWebhookSigningKeyQueryVariables>({
    query: GetWebhookSigningKeyDocument,
    ...options,
  })
}

export function useGetNotificationProcessorTypeSupportQuery(
  options?: Omit<Urql.UseQueryArgs<GetNotificationProcessorTypeSupportQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetNotificationProcessorTypeSupportQuery, GetNotificationProcessorTypeSupportQueryVariables>({
    query: GetNotificationProcessorTypeSupportDocument,
    ...options,
  })
}

export function useUpsertTargetAndEventsSubscriptionsMutation() {
  return Urql.useMutation<
    UpsertTargetAndEventsSubscriptionsMutation,
    UpsertTargetAndEventsSubscriptionsMutationVariables
  >(UpsertTargetAndEventsSubscriptionsDocument)
}

export function useUnsubscribeNotificationTargetMutation() {
  return Urql.useMutation<UnsubscribeNotificationTargetMutation, UnsubscribeNotificationTargetMutationVariables>(
    UnsubscribeNotificationTargetDocument
  )
}

export function useResendNotificationMutation() {
  return Urql.useMutation<ResendNotificationMutation, ResendNotificationMutationVariables>(ResendNotificationDocument)
}

export function useGetOrdersByCrmOpportunityIdQuery(
  options: Omit<Urql.UseQueryArgs<GetOrdersByCrmOpportunityIdQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetOrdersByCrmOpportunityIdQuery, GetOrdersByCrmOpportunityIdQueryVariables>({
    query: GetOrdersByCrmOpportunityIdDocument,
    ...options,
  })
}

export function useGetCompositeOrdersByCrmOpportunityIdQuery(
  options: Omit<Urql.UseQueryArgs<GetCompositeOrdersByCrmOpportunityIdQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetCompositeOrdersByCrmOpportunityIdQuery, GetCompositeOrdersByCrmOpportunityIdQueryVariables>({
    query: GetCompositeOrdersByCrmOpportunityIdDocument,
    ...options,
  })
}

export function useGetOpportunityByOpportunityIdQuery(
  options: Omit<Urql.UseQueryArgs<GetOpportunityByOpportunityIdQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetOpportunityByOpportunityIdQuery, GetOpportunityByOpportunityIdQueryVariables>({
    query: GetOpportunityByOpportunityIdDocument,
    ...options,
  })
}

export function useGetOpportunityByCrmOpportunityIdQuery(
  options: Omit<Urql.UseQueryArgs<GetOpportunityByCrmOpportunityIdQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetOpportunityByCrmOpportunityIdQuery, GetOpportunityByCrmOpportunityIdQueryVariables>({
    query: GetOpportunityByCrmOpportunityIdDocument,
    ...options,
  })
}

export function useGetOpportunitiesBySalesforceAccountIdQuery(
  options: Omit<Urql.UseQueryArgs<GetOpportunitiesBySalesforceAccountIdQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetOpportunitiesBySalesforceAccountIdQuery, GetOpportunitiesBySalesforceAccountIdQueryVariables>(
    { query: GetOpportunitiesBySalesforceAccountIdDocument, ...options }
  )
}

export function useGetOpportunitiesByHubSpotCompanyIdQuery(
  options: Omit<Urql.UseQueryArgs<GetOpportunitiesByHubSpotCompanyIdQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetOpportunitiesByHubSpotCompanyIdQuery, GetOpportunitiesByHubSpotCompanyIdQueryVariables>({
    query: GetOpportunitiesByHubSpotCompanyIdDocument,
    ...options,
  })
}

export function useGetOpportunitiesByAccountCrmIdQuery(
  options: Omit<Urql.UseQueryArgs<GetOpportunitiesByAccountCrmIdQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetOpportunitiesByAccountCrmIdQuery, GetOpportunitiesByAccountCrmIdQueryVariables>({
    query: GetOpportunitiesByAccountCrmIdDocument,
    ...options,
  })
}

export function useUpdatePrimaryCompositeOrderIdForCrmOpportunityMutation() {
  return Urql.useMutation<
    UpdatePrimaryCompositeOrderIdForCrmOpportunityMutation,
    UpdatePrimaryCompositeOrderIdForCrmOpportunityMutationVariables
  >(UpdatePrimaryCompositeOrderIdForCrmOpportunityDocument)
}

export function useResetOpportunityClosedStateMutation() {
  return Urql.useMutation<ResetOpportunityClosedStateMutation, ResetOpportunityClosedStateMutationVariables>(
    ResetOpportunityClosedStateDocument
  )
}

export function useListPlansQuery(options?: Omit<Urql.UseQueryArgs<ListPlansQueryVariables>, 'query'>) {
  return Urql.useQuery<ListPlansQuery, ListPlansQueryVariables>({ query: ListPlansDocument, ...options })
}

export function useListAccountsQuery(options?: Omit<Urql.UseQueryArgs<ListAccountsQueryVariables>, 'query'>) {
  return Urql.useQuery<ListAccountsQuery, ListAccountsQueryVariables>({ query: ListAccountsDocument, ...options })
}

export function useApprovalSegmentsQuery(options?: Omit<Urql.UseQueryArgs<ApprovalSegmentsQueryVariables>, 'query'>) {
  return Urql.useQuery<ApprovalSegmentsQuery, ApprovalSegmentsQueryVariables>({
    query: ApprovalSegmentsDocument,
    ...options,
  })
}

export function useListResellerAccountsQuery(
  options?: Omit<Urql.UseQueryArgs<ListResellerAccountsQueryVariables>, 'query'>
) {
  return Urql.useQuery<ListResellerAccountsQuery, ListResellerAccountsQueryVariables>({
    query: ListResellerAccountsDocument,
    ...options,
  })
}

export function useListAccountContactsQuery(
  options: Omit<Urql.UseQueryArgs<ListAccountContactsQueryVariables>, 'query'>
) {
  return Urql.useQuery<ListAccountContactsQuery, ListAccountContactsQueryVariables>({
    query: ListAccountContactsDocument,
    ...options,
  })
}

export function useListDiscountsQuery(options?: Omit<Urql.UseQueryArgs<ListDiscountsQueryVariables>, 'query'>) {
  return Urql.useQuery<ListDiscountsQuery, ListDiscountsQueryVariables>({ query: ListDiscountsDocument, ...options })
}

export function useGetOrdersOverviewByDateQuery(
  options: Omit<Urql.UseQueryArgs<GetOrdersOverviewByDateQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetOrdersOverviewByDateQuery, GetOrdersOverviewByDateQueryVariables>({
    query: GetOrdersOverviewByDateDocument,
    ...options,
  })
}

export function useGetOrderDetailQuery(options: Omit<Urql.UseQueryArgs<GetOrderDetailQueryVariables>, 'query'>) {
  return Urql.useQuery<GetOrderDetailQuery, GetOrderDetailQueryVariables>({ query: GetOrderDetailDocument, ...options })
}

export function useUpdateOrderStatusMutation() {
  return Urql.useMutation<UpdateOrderStatusMutation, UpdateOrderStatusMutationVariables>(UpdateOrderStatusDocument)
}

export function useDeleteOrderMutation() {
  return Urql.useMutation<DeleteOrderMutation, DeleteOrderMutationVariables>(DeleteOrderDocument)
}

export function useDeleteUpsellWithEarlyRenewalMutation() {
  return Urql.useMutation<DeleteUpsellWithEarlyRenewalMutation, DeleteUpsellWithEarlyRenewalMutationVariables>(
    DeleteUpsellWithEarlyRenewalDocument
  )
}

export function useUpsertOrderMutation() {
  return Urql.useMutation<UpsertOrderMutation, UpsertOrderMutationVariables>(UpsertOrderDocument)
}

export function useGenerateAmendmentQuery(options: Omit<Urql.UseQueryArgs<GenerateAmendmentQueryVariables>, 'query'>) {
  return Urql.useQuery<GenerateAmendmentQuery, GenerateAmendmentQueryVariables>({
    query: GenerateAmendmentDocument,
    ...options,
  })
}

export function useGenerateRenewalOrderQuery(
  options: Omit<Urql.UseQueryArgs<GenerateRenewalOrderQueryVariables>, 'query'>
) {
  return Urql.useQuery<GenerateRenewalOrderQuery, GenerateRenewalOrderQueryVariables>({
    query: GenerateRenewalOrderDocument,
    ...options,
  })
}

export function useDuplicateOrderQuery(options: Omit<Urql.UseQueryArgs<DuplicateOrderQueryVariables>, 'query'>) {
  return Urql.useQuery<DuplicateOrderQuery, DuplicateOrderQueryVariables>({ query: DuplicateOrderDocument, ...options })
}

export function useGetPaymentTermSettingsQuery(
  options?: Omit<Urql.UseQueryArgs<GetPaymentTermSettingsQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetPaymentTermSettingsQuery, GetPaymentTermSettingsQueryVariables>({
    query: GetPaymentTermSettingsDocument,
    ...options,
  })
}

export function useUpsertAmendmentMutation() {
  return Urql.useMutation<UpsertAmendmentMutation, UpsertAmendmentMutationVariables>(UpsertAmendmentDocument)
}

export function useDryRunAmendmentMutation() {
  return Urql.useMutation<DryRunAmendmentMutation, DryRunAmendmentMutationVariables>(DryRunAmendmentDocument)
}

export function useGenerateCancelOrderQuery(
  options: Omit<Urql.UseQueryArgs<GenerateCancelOrderQueryVariables>, 'query'>
) {
  return Urql.useQuery<GenerateCancelOrderQuery, GenerateCancelOrderQueryVariables>({
    query: GenerateCancelOrderDocument,
    ...options,
  })
}

export function useIsAmendmentCurrentQuery(
  options: Omit<Urql.UseQueryArgs<IsAmendmentCurrentQueryVariables>, 'query'>
) {
  return Urql.useQuery<IsAmendmentCurrentQuery, IsAmendmentCurrentQueryVariables>({
    query: IsAmendmentCurrentDocument,
    ...options,
  })
}

export function useDryRunCancellationMutation() {
  return Urql.useMutation<DryRunCancellationMutation, DryRunCancellationMutationVariables>(DryRunCancellationDocument)
}

export function useUpsertCancellationMutation() {
  return Urql.useMutation<UpsertCancellationMutation, UpsertCancellationMutationVariables>(UpsertCancellationDocument)
}

export function useDryRunOrderMutation() {
  return Urql.useMutation<DryRunOrderMutation, DryRunOrderMutationVariables>(DryRunOrderDocument)
}

export function useUpsertRenewalOrderMutation() {
  return Urql.useMutation<UpsertRenewalOrderMutation, UpsertRenewalOrderMutationVariables>(UpsertRenewalOrderDocument)
}

export function useDryRunRenewalOrderMutation() {
  return Urql.useMutation<DryRunRenewalOrderMutation, DryRunRenewalOrderMutationVariables>(DryRunRenewalOrderDocument)
}

export function useSyncOrderToSalesforceMutation() {
  return Urql.useMutation<SyncOrderToSalesforceMutation, SyncOrderToSalesforceMutationVariables>(
    SyncOrderToSalesforceDocument
  )
}

export function useCreateOrderDocumentMutation() {
  return Urql.useMutation<CreateOrderDocumentMutation, CreateOrderDocumentMutationVariables>(
    CreateOrderDocumentDocument
  )
}

export function useUpsertCustomPredefinedTemplateOnOrderMutation() {
  return Urql.useMutation<
    UpsertCustomPredefinedTemplateOnOrderMutation,
    UpsertCustomPredefinedTemplateOnOrderMutationVariables
  >(UpsertCustomPredefinedTemplateOnOrderDocument)
}

export function useDeleteCustomPredefinedTemplateOnOrderMutation() {
  return Urql.useMutation<
    DeleteCustomPredefinedTemplateOnOrderMutation,
    DeleteCustomPredefinedTemplateOnOrderMutationVariables
  >(DeleteCustomPredefinedTemplateOnOrderDocument)
}

export function useRebaseAmendmentMutation() {
  return Urql.useMutation<RebaseAmendmentMutation, RebaseAmendmentMutationVariables>(RebaseAmendmentDocument)
}

export function useGetStripeConnectIntegrationQuery(
  options?: Omit<Urql.UseQueryArgs<GetStripeConnectIntegrationQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetStripeConnectIntegrationQuery, GetStripeConnectIntegrationQueryVariables>({
    query: GetStripeConnectIntegrationDocument,
    ...options,
  })
}

export function useInitiateStripeConnectIntegrationMutation() {
  return Urql.useMutation<InitiateStripeConnectIntegrationMutation, InitiateStripeConnectIntegrationMutationVariables>(
    InitiateStripeConnectIntegrationDocument
  )
}

export function useCompleteStripeConnectIntegrationMutation() {
  return Urql.useMutation<CompleteStripeConnectIntegrationMutation, CompleteStripeConnectIntegrationMutationVariables>(
    CompleteStripeConnectIntegrationDocument
  )
}

export function useGeneratePaymentLinkMutation() {
  return Urql.useMutation<GeneratePaymentLinkMutation, GeneratePaymentLinkMutationVariables>(
    GeneratePaymentLinkDocument
  )
}

export function useGetFailedPaymentsForInvoiceQuery(
  options: Omit<Urql.UseQueryArgs<GetFailedPaymentsForInvoiceQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetFailedPaymentsForInvoiceQuery, GetFailedPaymentsForInvoiceQueryVariables>({
    query: GetFailedPaymentsForInvoiceDocument,
    ...options,
  })
}

export function useDeleteStripeConnectIntegrationMutation() {
  return Urql.useMutation<DeleteStripeConnectIntegrationMutation, DeleteStripeConnectIntegrationMutationVariables>(
    DeleteStripeConnectIntegrationDocument
  )
}

export function useGetPaymentIntegrationEnabledQuery(
  options?: Omit<Urql.UseQueryArgs<GetPaymentIntegrationEnabledQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetPaymentIntegrationEnabledQuery, GetPaymentIntegrationEnabledQueryVariables>({
    query: GetPaymentIntegrationEnabledDocument,
    ...options,
  })
}

export function useGetPaymentMethodsQuery(options: Omit<Urql.UseQueryArgs<GetPaymentMethodsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>({
    query: GetPaymentMethodsDocument,
    ...options,
  })
}

export function useGetPaymentConfigurationQuery(
  options?: Omit<Urql.UseQueryArgs<GetPaymentConfigurationQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetPaymentConfigurationQuery, GetPaymentConfigurationQueryVariables>({
    query: GetPaymentConfigurationDocument,
    ...options,
  })
}

export function useGetSupportedPaymentTypesQuery(
  options?: Omit<Urql.UseQueryArgs<GetSupportedPaymentTypesQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetSupportedPaymentTypesQuery, GetSupportedPaymentTypesQueryVariables>({
    query: GetSupportedPaymentTypesDocument,
    ...options,
  })
}

export function useUpdatePaymentConfigurationMutation() {
  return Urql.useMutation<UpdatePaymentConfigurationMutation, UpdatePaymentConfigurationMutationVariables>(
    UpdatePaymentConfigurationDocument
  )
}

export function useUpdateAutomaticPaymentConfigurationMutation() {
  return Urql.useMutation<
    UpdateAutomaticPaymentConfigurationMutation,
    UpdateAutomaticPaymentConfigurationMutationVariables
  >(UpdateAutomaticPaymentConfigurationDocument)
}

export function useGetPaymentDetailQuery(options?: Omit<Urql.UseQueryArgs<GetPaymentDetailQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPaymentDetailQuery, GetPaymentDetailQueryVariables>({
    query: GetPaymentDetailDocument,
    ...options,
  })
}

export function useGetPaymentsInAccountQuery(
  options?: Omit<Urql.UseQueryArgs<GetPaymentsInAccountQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetPaymentsInAccountQuery, GetPaymentsInAccountQueryVariables>({
    query: GetPaymentsInAccountDocument,
    ...options,
  })
}

export function useGetPaymentsForInvoiceQuery(
  options?: Omit<Urql.UseQueryArgs<GetPaymentsForInvoiceQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetPaymentsForInvoiceQuery, GetPaymentsForInvoiceQueryVariables>({
    query: GetPaymentsForInvoiceDocument,
    ...options,
  })
}

export function useGenerateRefundQuery(options: Omit<Urql.UseQueryArgs<GenerateRefundQueryVariables>, 'query'>) {
  return Urql.useQuery<GenerateRefundQuery, GenerateRefundQueryVariables>({ query: GenerateRefundDocument, ...options })
}

export function useVoidPaymentMutation() {
  return Urql.useMutation<VoidPaymentMutation, VoidPaymentMutationVariables>(VoidPaymentDocument)
}

export function useInvoicePaymentLinkDetailsQuery(
  options: Omit<Urql.UseQueryArgs<InvoicePaymentLinkDetailsQueryVariables>, 'query'>
) {
  return Urql.useQuery<InvoicePaymentLinkDetailsQuery, InvoicePaymentLinkDetailsQueryVariables>({
    query: InvoicePaymentLinkDetailsDocument,
    ...options,
  })
}

export function useCreatePaymentIntentMutation() {
  return Urql.useMutation<CreatePaymentIntentMutation, CreatePaymentIntentMutationVariables>(
    CreatePaymentIntentDocument
  )
}

export function useUpdatePaymentIntentMutation() {
  return Urql.useMutation<UpdatePaymentIntentMutation, UpdatePaymentIntentMutationVariables>(
    UpdatePaymentIntentDocument
  )
}

export function useUnEnrollFromAutomaticPaymentsMutation() {
  return Urql.useMutation<UnEnrollFromAutomaticPaymentsMutation, UnEnrollFromAutomaticPaymentsMutationVariables>(
    UnEnrollFromAutomaticPaymentsDocument
  )
}

export function useUpdateAccountSupportedPaymentTypesMutation() {
  return Urql.useMutation<
    UpdateAccountSupportedPaymentTypesMutation,
    UpdateAccountSupportedPaymentTypesMutationVariables
  >(UpdateAccountSupportedPaymentTypesDocument)
}

export function useGenerateAccountPaymentLinkMutation() {
  return Urql.useMutation<GenerateAccountPaymentLinkMutation, GenerateAccountPaymentLinkMutationVariables>(
    GenerateAccountPaymentLinkDocument
  )
}

export function useAccountPaymentLinkDetailsQuery(
  options: Omit<Urql.UseQueryArgs<AccountPaymentLinkDetailsQueryVariables>, 'query'>
) {
  return Urql.useQuery<AccountPaymentLinkDetailsQuery, AccountPaymentLinkDetailsQueryVariables>({
    query: AccountPaymentLinkDetailsDocument,
    ...options,
  })
}

export function useCreateSetupIntentMutation() {
  return Urql.useMutation<CreateSetupIntentMutation, CreateSetupIntentMutationVariables>(CreateSetupIntentDocument)
}

export function useReEnableAutomaticPaymentsMutation() {
  return Urql.useMutation<ReEnableAutomaticPaymentsMutation, ReEnableAutomaticPaymentsMutationVariables>(
    ReEnableAutomaticPaymentsDocument
  )
}

export function useGetHasPaymentIntegrationQuery(
  options?: Omit<Urql.UseQueryArgs<GetHasPaymentIntegrationQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetHasPaymentIntegrationQuery, GetHasPaymentIntegrationQueryVariables>({
    query: GetHasPaymentIntegrationDocument,
    ...options,
  })
}

export function useGetPlanQuery(options: Omit<Urql.UseQueryArgs<GetPlanQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPlanQuery, GetPlanQueryVariables>({ query: GetPlanDocument, ...options })
}

export function useDeletePlanMutation() {
  return Urql.useMutation<DeletePlanMutation, DeletePlanMutationVariables>(DeletePlanDocument)
}

export function useDeprecatePlanMutation() {
  return Urql.useMutation<DeprecatePlanMutation, DeprecatePlanMutationVariables>(DeprecatePlanDocument)
}

export function useActivatePlanMutation() {
  return Urql.useMutation<ActivatePlanMutation, ActivatePlanMutationVariables>(ActivatePlanDocument)
}

export function useAddPlanMutation() {
  return Urql.useMutation<AddPlanMutation, AddPlanMutationVariables>(AddPlanDocument)
}

export function useUpdatePlanMutation() {
  return Urql.useMutation<UpdatePlanMutation, UpdatePlanMutationVariables>(UpdatePlanDocument)
}

export function useDuplicatePlanMutation() {
  return Urql.useMutation<DuplicatePlanMutation, DuplicatePlanMutationVariables>(DuplicatePlanDocument)
}

export function useRevertToDraftMutation() {
  return Urql.useMutation<RevertToDraftMutation, RevertToDraftMutationVariables>(RevertToDraftDocument)
}

export function useReactivatePlanMutation() {
  return Urql.useMutation<ReactivatePlanMutation, ReactivatePlanMutationVariables>(ReactivatePlanDocument)
}

export function useListUnitsOfMeasureQuery(
  options?: Omit<Urql.UseQueryArgs<ListUnitsOfMeasureQueryVariables>, 'query'>
) {
  return Urql.useQuery<ListUnitsOfMeasureQuery, ListUnitsOfMeasureQueryVariables>({
    query: ListUnitsOfMeasureDocument,
    ...options,
  })
}

export function useListTaxRatesQuery(options?: Omit<Urql.UseQueryArgs<ListTaxRatesQueryVariables>, 'query'>) {
  return Urql.useQuery<ListTaxRatesQuery, ListTaxRatesQueryVariables>({ query: ListTaxRatesDocument, ...options })
}

export function useListRecognitionRulesQuery(
  options?: Omit<Urql.UseQueryArgs<ListRecognitionRulesQueryVariables>, 'query'>
) {
  return Urql.useQuery<ListRecognitionRulesQuery, ListRecognitionRulesQueryVariables>({
    query: ListRecognitionRulesDocument,
    ...options,
  })
}

export function useGetPlanGroupQuery(options: Omit<Urql.UseQueryArgs<GetPlanGroupQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPlanGroupQuery, GetPlanGroupQueryVariables>({ query: GetPlanGroupDocument, ...options })
}

export function useUpsertPlanGroupMutation() {
  return Urql.useMutation<UpsertPlanGroupMutation, UpsertPlanGroupMutationVariables>(UpsertPlanGroupDocument)
}

export function useCreateProposalMutation() {
  return Urql.useMutation<CreateProposalMutation, CreateProposalMutationVariables>(CreateProposalDocument)
}

export function useGetProductCategoriesQuery(
  options?: Omit<Urql.UseQueryArgs<GetProductCategoriesQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetProductCategoriesQuery, GetProductCategoriesQueryVariables>({
    query: GetProductCategoriesDocument,
    ...options,
  })
}

export function useUpdateProductCategoryMutation() {
  return Urql.useMutation<UpdateProductCategoryMutation, UpdateProductCategoryMutationVariables>(
    UpdateProductCategoryDocument
  )
}

export function useUpsertProductCategoryMutation() {
  return Urql.useMutation<UpsertProductCategoryMutation, UpsertProductCategoryMutationVariables>(
    UpsertProductCategoryDocument
  )
}

export function useDeleteProductCategoryMutation() {
  return Urql.useMutation<DeleteProductCategoryMutation, DeleteProductCategoryMutationVariables>(
    DeleteProductCategoryDocument
  )
}

export function useAddProductCategoryMutation() {
  return Urql.useMutation<AddProductCategoryMutation, AddProductCategoryMutationVariables>(AddProductCategoryDocument)
}

export function useGetProductQuery(options: Omit<Urql.UseQueryArgs<GetProductQueryVariables>, 'query'>) {
  return Urql.useQuery<GetProductQuery, GetProductQueryVariables>({ query: GetProductDocument, ...options })
}

export function useUpdateProductMutation() {
  return Urql.useMutation<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument)
}

export function useDeleteProductMutation() {
  return Urql.useMutation<DeleteProductMutation, DeleteProductMutationVariables>(DeleteProductDocument)
}

export function useGetPlansQuery(options: Omit<Urql.UseQueryArgs<GetPlansQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPlansQuery, GetPlansQueryVariables>({ query: GetPlansDocument, ...options })
}

export function useGetAllPlansQuery(options?: Omit<Urql.UseQueryArgs<GetAllPlansQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAllPlansQuery, GetAllPlansQueryVariables>({ query: GetAllPlansDocument, ...options })
}

export function useGetPlanMinimalsQuery(options?: Omit<Urql.UseQueryArgs<GetPlanMinimalsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPlanMinimalsQuery, GetPlanMinimalsQueryVariables>({
    query: GetPlanMinimalsDocument,
    ...options,
  })
}

export function useAddProductMutation() {
  return Urql.useMutation<AddProductMutation, AddProductMutationVariables>(AddProductDocument)
}

export function useGetRateCardsQuery(options?: Omit<Urql.UseQueryArgs<GetRateCardsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetRateCardsQuery, GetRateCardsQueryVariables>({ query: GetRateCardsDocument, ...options })
}

export function useGetRateCardQuery(options: Omit<Urql.UseQueryArgs<GetRateCardQueryVariables>, 'query'>) {
  return Urql.useQuery<GetRateCardQuery, GetRateCardQueryVariables>({ query: GetRateCardDocument, ...options })
}

export function useGetPriceAttributesQuery(
  options?: Omit<Urql.UseQueryArgs<GetPriceAttributesQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetPriceAttributesQuery, GetPriceAttributesQueryVariables>({
    query: GetPriceAttributesDocument,
    ...options,
  })
}

export function useUpsertPriceAttributeMutation() {
  return Urql.useMutation<UpsertPriceAttributeMutation, UpsertPriceAttributeMutationVariables>(
    UpsertPriceAttributeDocument
  )
}

export function useUpsertRateCardMutation() {
  return Urql.useMutation<UpsertRateCardMutation, UpsertRateCardMutationVariables>(UpsertRateCardDocument)
}

export function useGetPredefinedReportsQuery(
  options?: Omit<Urql.UseQueryArgs<GetPredefinedReportsQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetPredefinedReportsQuery, GetPredefinedReportsQueryVariables>({
    query: GetPredefinedReportsDocument,
    ...options,
  })
}

export function useGenerateReportMutation() {
  return Urql.useMutation<GenerateReportMutation, GenerateReportMutationVariables>(GenerateReportDocument)
}

export function useGetCurrentLookerUserQuery(
  options?: Omit<Urql.UseQueryArgs<GetCurrentLookerUserQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetCurrentLookerUserQuery, GetCurrentLookerUserQueryVariables>({
    query: GetCurrentLookerUserDocument,
    ...options,
  })
}

export function useGetRecognitionRulesQuery(
  options?: Omit<Urql.UseQueryArgs<GetRecognitionRulesQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetRecognitionRulesQuery, GetRecognitionRulesQueryVariables>({
    query: GetRecognitionRulesDocument,
    ...options,
  })
}

export function useDeleteRecognitionRuleMutation() {
  return Urql.useMutation<DeleteRecognitionRuleMutation, DeleteRecognitionRuleMutationVariables>(
    DeleteRecognitionRuleDocument
  )
}

export function useRecognizeRevenueMutation() {
  return Urql.useMutation<RecognizeRevenueMutation, RecognizeRevenueMutationVariables>(RecognizeRevenueDocument)
}

export function useUpsertRecognitionRuleMutation() {
  return Urql.useMutation<UpsertRecognitionRuleMutation, UpsertRecognitionRuleMutationVariables>(
    UpsertRecognitionRuleDocument
  )
}

export function useGetSubscriptionChargeRecognitionQuery(
  options: Omit<Urql.UseQueryArgs<GetSubscriptionChargeRecognitionQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetSubscriptionChargeRecognitionQuery, GetSubscriptionChargeRecognitionQueryVariables>({
    query: GetSubscriptionChargeRecognitionDocument,
    ...options,
  })
}

export function useCreateSalesRoomLinkMutation() {
  return Urql.useMutation<CreateSalesRoomLinkMutation, CreateSalesRoomLinkMutationVariables>(
    CreateSalesRoomLinkDocument
  )
}

export function useGetSalesRoomLinkQuery(options: Omit<Urql.UseQueryArgs<GetSalesRoomLinkQueryVariables>, 'query'>) {
  return Urql.useQuery<GetSalesRoomLinkQuery, GetSalesRoomLinkQueryVariables>({
    query: GetSalesRoomLinkDocument,
    ...options,
  })
}

export function useGetSalesRoomQuery(options: Omit<Urql.UseQueryArgs<GetSalesRoomQueryVariables>, 'query'>) {
  return Urql.useQuery<GetSalesRoomQuery, GetSalesRoomQueryVariables>({ query: GetSalesRoomDocument, ...options })
}

export function useGetSalesRoomResellerQuery(
  options: Omit<Urql.UseQueryArgs<GetSalesRoomResellerQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetSalesRoomResellerQuery, GetSalesRoomResellerQueryVariables>({
    query: GetSalesRoomResellerDocument,
    ...options,
  })
}

export function useUpsertAccountContactForSalesRoomMutation() {
  return Urql.useMutation<UpsertAccountContactForSalesRoomMutation, UpsertAccountContactForSalesRoomMutationVariables>(
    UpsertAccountContactForSalesRoomDocument
  )
}

export function useUpdateOrderContactsMutation() {
  return Urql.useMutation<UpdateOrderContactsMutation, UpdateOrderContactsMutationVariables>(
    UpdateOrderContactsDocument
  )
}

export function useSendEmailForEsignSalesRoomMutation() {
  return Urql.useMutation<SendEmailForEsignSalesRoomMutation, SendEmailForEsignSalesRoomMutationVariables>(
    SendEmailForEsignSalesRoomDocument
  )
}

export function useUpdateCustomFieldsForSalesRoomMutation() {
  return Urql.useMutation<UpdateCustomFieldsForSalesRoomMutation, UpdateCustomFieldsForSalesRoomMutationVariables>(
    UpdateCustomFieldsForSalesRoomDocument
  )
}

export function useCreateOrderDocumentForSalesRoomMutation() {
  return Urql.useMutation<CreateOrderDocumentForSalesRoomMutation, CreateOrderDocumentForSalesRoomMutationVariables>(
    CreateOrderDocumentForSalesRoomDocument
  )
}

export function useSyncCompositeOrderToCrmMutation() {
  return Urql.useMutation<SyncCompositeOrderToCrmMutation, SyncCompositeOrderToCrmMutationVariables>(
    SyncCompositeOrderToCrmDocument
  )
}

export function useGetSalesforceIntegrationQuery(
  options?: Omit<Urql.UseQueryArgs<GetSalesforceIntegrationQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetSalesforceIntegrationQuery, GetSalesforceIntegrationQueryVariables>({
    query: GetSalesforceIntegrationDocument,
    ...options,
  })
}

export function useGetSalesforceIntegrationDetailsQuery(
  options?: Omit<Urql.UseQueryArgs<GetSalesforceIntegrationDetailsQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetSalesforceIntegrationDetailsQuery, GetSalesforceIntegrationDetailsQueryVariables>({
    query: GetSalesforceIntegrationDetailsDocument,
    ...options,
  })
}

export function useInitiateSalesforceIntegrationMutation() {
  return Urql.useMutation<InitiateSalesforceIntegrationMutation, InitiateSalesforceIntegrationMutationVariables>(
    InitiateSalesforceIntegrationDocument
  )
}

export function useCompleteSalesforceIntegrationMutation() {
  return Urql.useMutation<CompleteSalesforceIntegrationMutation, CompleteSalesforceIntegrationMutationVariables>(
    CompleteSalesforceIntegrationDocument
  )
}

export function useDeleteSalesforceIntegrationMutation() {
  return Urql.useMutation<DeleteSalesforceIntegrationMutation, DeleteSalesforceIntegrationMutationVariables>(
    DeleteSalesforceIntegrationDocument
  )
}

export function useUpdatePrimaryOrderIdForSalesforceOpportunityMutation() {
  return Urql.useMutation<
    UpdatePrimaryOrderIdForSalesforceOpportunityMutation,
    UpdatePrimaryOrderIdForSalesforceOpportunityMutationVariables
  >(UpdatePrimaryOrderIdForSalesforceOpportunityDocument)
}

export function useSearchQuery(options: Omit<Urql.UseQueryArgs<SearchQueryVariables>, 'query'>) {
  return Urql.useQuery<SearchQuery, SearchQueryVariables>({ query: SearchDocument, ...options })
}

export function useSearchInTenantQuery(options: Omit<Urql.UseQueryArgs<SearchInTenantQueryVariables>, 'query'>) {
  return Urql.useQuery<SearchInTenantQuery, SearchInTenantQueryVariables>({ query: SearchInTenantDocument, ...options })
}

export function useGetSettingQuery(options?: Omit<Urql.UseQueryArgs<GetSettingQueryVariables>, 'query'>) {
  return Urql.useQuery<GetSettingQuery, GetSettingQueryVariables>({ query: GetSettingDocument, ...options })
}

export function useGetSupportedCurrenciesQuery(
  options?: Omit<Urql.UseQueryArgs<GetSupportedCurrenciesQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetSupportedCurrenciesQuery, GetSupportedCurrenciesQueryVariables>({
    query: GetSupportedCurrenciesDocument,
    ...options,
  })
}

export function useSetSupportedCurrenciesMutation() {
  return Urql.useMutation<SetSupportedCurrenciesMutation, SetSupportedCurrenciesMutationVariables>(
    SetSupportedCurrenciesDocument
  )
}

export function useGetTenantDetailsQuery(options?: Omit<Urql.UseQueryArgs<GetTenantDetailsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetTenantDetailsQuery, GetTenantDetailsQueryVariables>({
    query: GetTenantDetailsDocument,
    ...options,
  })
}

export function useGetUnitsOfMeasureQuery(options?: Omit<Urql.UseQueryArgs<GetUnitsOfMeasureQueryVariables>, 'query'>) {
  return Urql.useQuery<GetUnitsOfMeasureQuery, GetUnitsOfMeasureQueryVariables>({
    query: GetUnitsOfMeasureDocument,
    ...options,
  })
}

export function useUpdateTenantDetailsMutation() {
  return Urql.useMutation<UpdateTenantDetailsMutation, UpdateTenantDetailsMutationVariables>(
    UpdateTenantDetailsDocument
  )
}

export function useUpsertUnitOfMeasureMutation() {
  return Urql.useMutation<UpsertUnitOfMeasureMutation, UpsertUnitOfMeasureMutationVariables>(
    UpsertUnitOfMeasureDocument
  )
}

export function useDeleteUnitOfMeasureMutation() {
  return Urql.useMutation<DeleteUnitOfMeasureMutation, DeleteUnitOfMeasureMutationVariables>(
    DeleteUnitOfMeasureDocument
  )
}

export function useGetTaxRatesQuery(options?: Omit<Urql.UseQueryArgs<GetTaxRatesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetTaxRatesQuery, GetTaxRatesQueryVariables>({ query: GetTaxRatesDocument, ...options })
}

export function useUpsertTaxRatesMutation() {
  return Urql.useMutation<UpsertTaxRatesMutation, UpsertTaxRatesMutationVariables>(UpsertTaxRatesDocument)
}

export function useDeleteTaxRateMutation() {
  return Urql.useMutation<DeleteTaxRateMutation, DeleteTaxRateMutationVariables>(DeleteTaxRateDocument)
}

export function useUpdateDiscountStatusMutation() {
  return Urql.useMutation<UpdateDiscountStatusMutation, UpdateDiscountStatusMutationVariables>(
    UpdateDiscountStatusDocument
  )
}

export function useUpsertDiscountMutation() {
  return Urql.useMutation<UpsertDiscountMutation, UpsertDiscountMutationVariables>(UpsertDiscountDocument)
}

export function useDeleteDiscountMutation() {
  return Urql.useMutation<DeleteDiscountMutation, DeleteDiscountMutationVariables>(DeleteDiscountDocument)
}

export function useListUserGroupsQuery(options?: Omit<Urql.UseQueryArgs<ListUserGroupsQueryVariables>, 'query'>) {
  return Urql.useQuery<ListUserGroupsQuery, ListUserGroupsQueryVariables>({ query: ListUserGroupsDocument, ...options })
}

export function useGetAccountReceivableContactQuery(
  options?: Omit<Urql.UseQueryArgs<GetAccountReceivableContactQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetAccountReceivableContactQuery, GetAccountReceivableContactQueryVariables>({
    query: GetAccountReceivableContactDocument,
    ...options,
  })
}

export function useUpsertUserGroupMutation() {
  return Urql.useMutation<UpsertUserGroupMutation, UpsertUserGroupMutationVariables>(UpsertUserGroupDocument)
}

export function useDeleteUserGroupMutation() {
  return Urql.useMutation<DeleteUserGroupMutation, DeleteUserGroupMutationVariables>(DeleteUserGroupDocument)
}

export function useUpdateAccountReceivableContactMutation() {
  return Urql.useMutation<UpdateAccountReceivableContactMutation, UpdateAccountReceivableContactMutationVariables>(
    UpdateAccountReceivableContactDocument
  )
}

export function useGetDunningSettingQuery(options?: Omit<Urql.UseQueryArgs<GetDunningSettingQueryVariables>, 'query'>) {
  return Urql.useQuery<GetDunningSettingQuery, GetDunningSettingQueryVariables>({
    query: GetDunningSettingDocument,
    ...options,
  })
}

export function useSendSampleDunningEmailQuery(
  options: Omit<Urql.UseQueryArgs<SendSampleDunningEmailQueryVariables>, 'query'>
) {
  return Urql.useQuery<SendSampleDunningEmailQuery, SendSampleDunningEmailQueryVariables>({
    query: SendSampleDunningEmailDocument,
    ...options,
  })
}

export function useUpdateDunningSettingMutation() {
  return Urql.useMutation<UpdateDunningSettingMutation, UpdateDunningSettingMutationVariables>(
    UpdateDunningSettingDocument
  )
}

export function useGetTenantSignatoriesQuery(
  options?: Omit<Urql.UseQueryArgs<GetTenantSignatoriesQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetTenantSignatoriesQuery, GetTenantSignatoriesQueryVariables>({
    query: GetTenantSignatoriesDocument,
    ...options,
  })
}

export function useAddTenantSignatoryMutation() {
  return Urql.useMutation<AddTenantSignatoryMutation, AddTenantSignatoryMutationVariables>(AddTenantSignatoryDocument)
}

export function useUpdatePaymentTermSettingsMutation() {
  return Urql.useMutation<UpdatePaymentTermSettingsMutation, UpdatePaymentTermSettingsMutationVariables>(
    UpdatePaymentTermSettingsDocument
  )
}

export function useGetOrderExpiryDurationQuery(
  options?: Omit<Urql.UseQueryArgs<GetOrderExpiryDurationQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetOrderExpiryDurationQuery, GetOrderExpiryDurationQueryVariables>({
    query: GetOrderExpiryDurationDocument,
    ...options,
  })
}

export function useUpdateOrderExpiryDurationMutation() {
  return Urql.useMutation<UpdateOrderExpiryDurationMutation, UpdateOrderExpiryDurationMutationVariables>(
    UpdateOrderExpiryDurationDocument
  )
}

export function useGetSigningOrderQuery(options?: Omit<Urql.UseQueryArgs<GetSigningOrderQueryVariables>, 'query'>) {
  return Urql.useQuery<GetSigningOrderQuery, GetSigningOrderQueryVariables>({
    query: GetSigningOrderDocument,
    ...options,
  })
}

export function useUpdateSigningOrderMutation() {
  return Urql.useMutation<UpdateSigningOrderMutation, UpdateSigningOrderMutationVariables>(UpdateSigningOrderDocument)
}

export function useGetTimeZonesQuery(options?: Omit<Urql.UseQueryArgs<GetTimeZonesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetTimeZonesQuery, GetTimeZonesQueryVariables>({ query: GetTimeZonesDocument, ...options })
}

export function useCreateMergeLinkTokenMutation() {
  return Urql.useMutation<CreateMergeLinkTokenMutation, CreateMergeLinkTokenMutationVariables>(
    CreateMergeLinkTokenDocument
  )
}

export function useProcessMergePublicTokenMutation() {
  return Urql.useMutation<ProcessMergePublicTokenMutation, ProcessMergePublicTokenMutationVariables>(
    ProcessMergePublicTokenDocument
  )
}

export function useGetSubscriptionQuery(options: Omit<Urql.UseQueryArgs<GetSubscriptionQueryVariables>, 'query'>) {
  return Urql.useQuery<GetSubscriptionQuery, GetSubscriptionQueryVariables>({
    query: GetSubscriptionDocument,
    ...options,
  })
}

export function useGetBillingEventsQuery(options: Omit<Urql.UseQueryArgs<GetBillingEventsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetBillingEventsQuery, GetBillingEventsQueryVariables>({
    query: GetBillingEventsDocument,
    ...options,
  })
}

export function useGenerateInvoiceMutation() {
  return Urql.useMutation<GenerateInvoiceMutation, GenerateInvoiceMutationVariables>(GenerateInvoiceDocument)
}

export function useGetSubscriptionChargeAliasQuery(
  options: Omit<Urql.UseQueryArgs<GetSubscriptionChargeAliasQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetSubscriptionChargeAliasQuery, GetSubscriptionChargeAliasQueryVariables>({
    query: GetSubscriptionChargeAliasDocument,
    ...options,
  })
}

export function useDeleteSubscriptionChargeAliasMutation() {
  return Urql.useMutation<DeleteSubscriptionChargeAliasMutation, DeleteSubscriptionChargeAliasMutationVariables>(
    DeleteSubscriptionChargeAliasDocument
  )
}

export function useAddSubscriptionChargeAliasMutation() {
  return Urql.useMutation<AddSubscriptionChargeAliasMutation, AddSubscriptionChargeAliasMutationVariables>(
    AddSubscriptionChargeAliasDocument
  )
}

export function useUpdateSubscriptionAttributesMutation() {
  return Urql.useMutation<UpdateSubscriptionAttributesMutation, UpdateSubscriptionAttributesMutationVariables>(
    UpdateSubscriptionAttributesDocument
  )
}

export function useAddBillingEventMutation() {
  return Urql.useMutation<AddBillingEventMutation, AddBillingEventMutationVariables>(AddBillingEventDocument)
}

export function useDeletBillingEventMutation() {
  return Urql.useMutation<DeletBillingEventMutation, DeletBillingEventMutationVariables>(DeletBillingEventDocument)
}

export function useDeleteSubscriptionMutation() {
  return Urql.useMutation<DeleteSubscriptionMutation, DeleteSubscriptionMutationVariables>(DeleteSubscriptionDocument)
}

export function useRevertSubscriptionMutation() {
  return Urql.useMutation<RevertSubscriptionMutation, RevertSubscriptionMutationVariables>(RevertSubscriptionDocument)
}

export function useAddRawUsageMutation() {
  return Urql.useMutation<AddRawUsageMutation, AddRawUsageMutationVariables>(AddRawUsageDocument)
}

export function useGetActiveSubscriptionsByAccountCrmIdQuery(
  options: Omit<Urql.UseQueryArgs<GetActiveSubscriptionsByAccountCrmIdQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetActiveSubscriptionsByAccountCrmIdQuery, GetActiveSubscriptionsByAccountCrmIdQueryVariables>({
    query: GetActiveSubscriptionsByAccountCrmIdDocument,
    ...options,
  })
}

export function useSubscriptionModifiableQuery(
  options: Omit<Urql.UseQueryArgs<SubscriptionModifiableQueryVariables>, 'query'>
) {
  return Urql.useQuery<SubscriptionModifiableQuery, SubscriptionModifiableQueryVariables>({
    query: SubscriptionModifiableDocument,
    ...options,
  })
}

export function useSubscriptionReversibleQuery(
  options: Omit<Urql.UseQueryArgs<SubscriptionReversibleQueryVariables>, 'query'>
) {
  return Urql.useQuery<SubscriptionReversibleQuery, SubscriptionReversibleQueryVariables>({
    query: SubscriptionReversibleDocument,
    ...options,
  })
}

export function useGetErpInvoiceBySubscriptionIdQuery(
  options: Omit<Urql.UseQueryArgs<GetErpInvoiceBySubscriptionIdQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetErpInvoiceBySubscriptionIdQuery, GetErpInvoiceBySubscriptionIdQueryVariables>({
    query: GetErpInvoiceBySubscriptionIdDocument,
    ...options,
  })
}

export function useGetTaxJarIntegrationQuery(
  options?: Omit<Urql.UseQueryArgs<GetTaxJarIntegrationQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetTaxJarIntegrationQuery, GetTaxJarIntegrationQueryVariables>({
    query: GetTaxJarIntegrationDocument,
    ...options,
  })
}

export function useTestTaxJarIntegrationMutation() {
  return Urql.useMutation<TestTaxJarIntegrationMutation, TestTaxJarIntegrationMutationVariables>(
    TestTaxJarIntegrationDocument
  )
}

export function useUpsertTaxJarIntegrationMutation() {
  return Urql.useMutation<UpsertTaxJarIntegrationMutation, UpsertTaxJarIntegrationMutationVariables>(
    UpsertTaxJarIntegrationDocument
  )
}

export function useHasTaxJarIntegrationQuery(
  options?: Omit<Urql.UseQueryArgs<HasTaxJarIntegrationQueryVariables>, 'query'>
) {
  return Urql.useQuery<HasTaxJarIntegrationQuery, HasTaxJarIntegrationQueryVariables>({
    query: HasTaxJarIntegrationDocument,
    ...options,
  })
}

export function useUpsertTemplateMutation() {
  return Urql.useMutation<UpsertTemplateMutation, UpsertTemplateMutationVariables>(UpsertTemplateDocument)
}

export function useDeleteTemplateMutation() {
  return Urql.useMutation<DeleteTemplateMutation, DeleteTemplateMutationVariables>(DeleteTemplateDocument)
}

export function useListTemplatesByTypeQuery(
  options?: Omit<Urql.UseQueryArgs<ListTemplatesByTypeQueryVariables>, 'query'>
) {
  return Urql.useQuery<ListTemplatesByTypeQuery, ListTemplatesByTypeQueryVariables>({
    query: ListTemplatesByTypeDocument,
    ...options,
  })
}

export function useGetTemplateWithContentQuery(
  options: Omit<Urql.UseQueryArgs<GetTemplateWithContentQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetTemplateWithContentQuery, GetTemplateWithContentQueryVariables>({
    query: GetTemplateWithContentDocument,
    ...options,
  })
}

export function useGetDocumentMasterTemplatesQuery(
  options?: Omit<Urql.UseQueryArgs<GetDocumentMasterTemplatesQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetDocumentMasterTemplatesQuery, GetDocumentMasterTemplatesQueryVariables>({
    query: GetDocumentMasterTemplatesDocument,
    ...options,
  })
}

export function useGetDocumentMasterTemplateQuery(
  options: Omit<Urql.UseQueryArgs<GetDocumentMasterTemplateQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetDocumentMasterTemplateQuery, GetDocumentMasterTemplateQueryVariables>({
    query: GetDocumentMasterTemplateDocument,
    ...options,
  })
}

export function useUpsertDocumentMasterTemplateMutation() {
  return Urql.useMutation<UpsertDocumentMasterTemplateMutation, UpsertDocumentMasterTemplateMutationVariables>(
    UpsertDocumentMasterTemplateDocument
  )
}

export function useUpsertDocumentMasterTemplateV2Mutation() {
  return Urql.useMutation<UpsertDocumentMasterTemplateV2Mutation, UpsertDocumentMasterTemplateV2MutationVariables>(
    UpsertDocumentMasterTemplateV2Document
  )
}

export function useUpdateDocumentMasterTemplateStatusMutation() {
  return Urql.useMutation<
    UpdateDocumentMasterTemplateStatusMutation,
    UpdateDocumentMasterTemplateStatusMutationVariables
  >(UpdateDocumentMasterTemplateStatusDocument)
}

export function useDeleteDocumentMasterTemplateMutation() {
  return Urql.useMutation<DeleteDocumentMasterTemplateMutation, DeleteDocumentMasterTemplateMutationVariables>(
    DeleteDocumentMasterTemplateDocument
  )
}

export function useGetDocumentSectionsQuery(
  options?: Omit<Urql.UseQueryArgs<GetDocumentSectionsQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetDocumentSectionsQuery, GetDocumentSectionsQueryVariables>({
    query: GetDocumentSectionsDocument,
    ...options,
  })
}

export function useUpsertDocumentSectionMutation() {
  return Urql.useMutation<UpsertDocumentSectionMutation, UpsertDocumentSectionMutationVariables>(
    UpsertDocumentSectionDocument
  )
}

export function useUpdateDocumentTemplateStatusMutation() {
  return Urql.useMutation<UpdateDocumentTemplateStatusMutation, UpdateDocumentTemplateStatusMutationVariables>(
    UpdateDocumentTemplateStatusDocument
  )
}

export function useGetPreviewMasterTemplateQuery(
  options: Omit<Urql.UseQueryArgs<GetPreviewMasterTemplateQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetPreviewMasterTemplateQuery, GetPreviewMasterTemplateQueryVariables>({
    query: GetPreviewMasterTemplateDocument,
    ...options,
  })
}

export function useGetPreviewDocumentTemplateQuery(
  options: Omit<Urql.UseQueryArgs<GetPreviewDocumentTemplateQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetPreviewDocumentTemplateQuery, GetPreviewDocumentTemplateQueryVariables>({
    query: GetPreviewDocumentTemplateDocument,
    ...options,
  })
}

export function useGetTenantJobQuery(options: Omit<Urql.UseQueryArgs<GetTenantJobQueryVariables>, 'query'>) {
  return Urql.useQuery<GetTenantJobQuery, GetTenantJobQueryVariables>({ query: GetTenantJobDocument, ...options })
}

export function useGetTenantJobAsAdminQuery(
  options: Omit<Urql.UseQueryArgs<GetTenantJobAsAdminQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetTenantJobAsAdminQuery, GetTenantJobAsAdminQueryVariables>({
    query: GetTenantJobAsAdminDocument,
    ...options,
  })
}

export function useRetryTenantJobAsAdminMutation() {
  return Urql.useMutation<RetryTenantJobAsAdminMutation, RetryTenantJobAsAdminMutationVariables>(
    RetryTenantJobAsAdminDocument
  )
}

export function useGetActiveErpSyncJobsQuery(
  options?: Omit<Urql.UseQueryArgs<GetActiveErpSyncJobsQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetActiveErpSyncJobsQuery, GetActiveErpSyncJobsQueryVariables>({
    query: GetActiveErpSyncJobsDocument,
    ...options,
  })
}

export function useGetCurrentUserQuery(options?: Omit<Urql.UseQueryArgs<GetCurrentUserQueryVariables>, 'query'>) {
  return Urql.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>({ query: GetCurrentUserDocument, ...options })
}

export function useGetUserQuery(options: Omit<Urql.UseQueryArgs<GetUserQueryVariables>, 'query'>) {
  return Urql.useQuery<GetUserQuery, GetUserQueryVariables>({ query: GetUserDocument, ...options })
}

export function useGetIsUserLoggedInQuery(options?: Omit<Urql.UseQueryArgs<GetIsUserLoggedInQueryVariables>, 'query'>) {
  return Urql.useQuery<GetIsUserLoggedInQuery, GetIsUserLoggedInQueryVariables>({
    query: GetIsUserLoggedInDocument,
    ...options,
  })
}

export function useGetAssociatedUsersQuery(
  options?: Omit<Urql.UseQueryArgs<GetAssociatedUsersQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetAssociatedUsersQuery, GetAssociatedUsersQueryVariables>({
    query: GetAssociatedUsersDocument,
    ...options,
  })
}

export function useUpsertUserMutation() {
  return Urql.useMutation<UpsertUserMutation, UpsertUserMutationVariables>(UpsertUserDocument)
}

export function useUpsertUserV2Mutation() {
  return Urql.useMutation<UpsertUserV2Mutation, UpsertUserV2MutationVariables>(UpsertUserV2Document)
}

export function useDeleteUserMutation() {
  return Urql.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument)
}

export function useEnableUserMutation() {
  return Urql.useMutation<EnableUserMutation, EnableUserMutationVariables>(EnableUserDocument)
}

export function useDisableUserMutation() {
  return Urql.useMutation<DisableUserMutation, DisableUserMutationVariables>(DisableUserDocument)
}

export function useResendCognitoInvitationEmailForExistingUserMutation() {
  return Urql.useMutation<
    ResendCognitoInvitationEmailForExistingUserMutation,
    ResendCognitoInvitationEmailForExistingUserMutationVariables
  >(ResendCognitoInvitationEmailForExistingUserDocument)
}

export function useGetUserTenantSessionQuery(
  options?: Omit<Urql.UseQueryArgs<GetUserTenantSessionQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetUserTenantSessionQuery, GetUserTenantSessionQueryVariables>({
    query: GetUserTenantSessionDocument,
    ...options,
  })
}
