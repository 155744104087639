import { useAsyncLoadingNotifier } from '@/components/AsyncLoadingIndicator/AsyncLoadingIndicatorProvider'
import { OrderPageSalesRoomCustomFieldsCard } from '@/components/SalesRoom/OrderPageSalesRoomCustomFieldsCard'
import { useSalesRoomLinkRevertToDraftWarningDialog } from '@/components/SalesRoom/SalesRoomLinkRevertToDraftWarning'
import { useSendEmailOrderView } from '@/pageComponents/esign/SendEmailOrderView'
import { OrderDetailsViewCard } from '@/pageComponents/orders/ViewOrderPage/OrderDetailsViewCard'
import useRenewalOrderPageRedirectModalEffect from '@/pageComponents/orders/useRenewalOrderPageRedirectModalEffect'
import { canMutateOrder, isAdminRole } from '@/util/roleUtils'
import { Box, CardContent, CardHeader, Grid, Step, StepLabel, Stepper, Typography } from '@mui/material'
import Head from 'next/head'
import Image from 'next/image'
import NextLink from 'next/link'
import React, { useCallback, useMemo, useReducer } from 'react'
import { CustomFieldsCard } from '../../../components/CustomFields/CustomFieldsCard'
import { useErrorHandler } from '../../../components/ErrorHandler/ErrorHandler'
import { useSnackbarHandler } from '../../../components/SnackbarHandler/SnackbarHandler'
import { useUserTenantSession } from '../../../components/UserTenantSessionProvider/UserTenantSessionContext'
import { PurchaseOrderWarning } from '../../../components/alerts/PurchaseOrderWarning'
import BillyAlert from '../../../components/alerts/alert'
import InProgressAlert from '../../../components/alerts/inProgressAlert'
import SuccessAlert from '../../../components/alerts/successAlert'
import { getPageButtons } from '../../../components/button/actionButton'
import BillyCard from '../../../components/card/billyCard'
import ActionDialog from '../../../components/dialog/actionDialog'
import GqlErrorDisplay from '../../../components/error/gqlErrorDisplay'
import InnerPageContainer from '../../../components/layout/innerPageContainer'
import { NavLayout } from '../../../components/layout/navLayout'
import BillyLink from '../../../components/link/billyLink'
import { useBillyRouter } from '../../../components/route/useBillyRouter'
import useJotaiRestBillySnackBar from '../../../components/snackBar/useJotaiRestBillySnackBar'
import useDynamicFeatureFlag from '../../../components/state/useDynamicFeatureFlag'
import useJotaiForm from '../../../components/state/useJotaiForm'
import { initJotaiRestState, WithRestApi } from '../../../components/state/useJotaiRest'
import {
  Attachment,
  CancelAndRestructureOrderDetailFragment,
  CustomFieldParentType,
  Feature,
  OrderDetailFragment,
  OrderStartDateType,
  OrderStatus,
  useCreateOrderDocumentMutation,
  useDeleteOrderMutation,
  useGetAttachmentsQuery,
  useGetHubSpotIntegrationQuery,
  useGetOpportunityByCrmOpportunityIdQuery,
  useGetOrderDetailQuery,
  useGetSalesforceIntegrationQuery,
  useGetSalesRoomLinkQuery,
  useIsAmendmentCurrentQuery,
  useRebaseAmendmentMutation,
  useSyncOrderToHubSpotMutation,
  useSyncOrderToSalesforceMutation,
  useUpdateOrderStatusMutation,
  useUpdatePrimaryOrderIdForHubSpotOpportunityMutation,
  useUpdatePrimaryOrderIdForSalesforceOpportunityMutation,
} from '../../../generated/graphql'
import { useExecuteOrderDialogWithAddressValidation } from '../../../pageComponents/orders/ExecuteOrderDialog'
import { IncludedTermsViewJotai } from '../../../pageComponents/orders/IncludedTerms/IncludedTermsView'
import { useOrderConfirmDeleteDialog } from '../../../pageComponents/orders/OrderConfirmDeleteDialog'
import { OrderApprovalFlowsView } from '../../../pageComponents/orders/orderApprovalFlowsView'
import OrderTotalListPriceCardContent from '../../../pageComponents/orders/orderTotalListPriceCardContent'
import PredefinedDiscountsTable from '../../../pageComponents/orders/predefinedDiscountsTable'
import { billableCurrencyFormat, discountFormat } from '../../../util/currencyUtil'
import { download } from '../../../util/file'
import buildLogger from '../../../util/logger'
import { getOrderViewActionButtons } from '../../../util/order'
import { ES_WAIT_TIME_IN_MS } from '../../../util/searchUtil'
import { CurrencyProvider } from '@/components/state/context/CurrencyContext'
import { OrderItemPreview } from '@/pageComponents/orders/OrderItemPreview'

export const logger = buildLogger('ViewOrderPage')

export const NOT_APPLICABLE = 'N/A'

type DisplayPredefinedDiscount = {
  id: string
  name: string
  percent: string
  amount: number
}

export const isOrderExecutedOrCancelled = (order: OrderDetailFragment | CancelAndRestructureOrderDetailFragment) => {
  if (order && order.status && order.status !== OrderStatus.Cancelled)
    return order.status === OrderStatus.Approved || order.status === OrderStatus.Executed
  return true
}

export function StatusStepper({
  order,
  className,
}: {
  order: Pick<OrderDetailFragment, 'status' | 'id'>
  className?: string
}): JSX.Element {
  if (order && order.status && order.status !== OrderStatus.Cancelled) {
    return (
      <BillyCard>
        <Stepper className={className}>
          <Step completed>
            <StepLabel>Draft</StepLabel>
          </Step>
          <Step completed={order.status !== OrderStatus.Draft && order.status !== OrderStatus.Expired}>
            <StepLabel>Submitted for Approval</StepLabel>
          </Step>
          <Step completed={order.status === OrderStatus.Approved || order.status === OrderStatus.Executed}>
            <StepLabel>Approved</StepLabel>
          </Step>
          <Step completed={order.status === OrderStatus.Executed}>
            <StepLabel>Executed</StepLabel>
          </Step>
        </Stepper>
        {<OrderApprovalFlowsView orderId={order.id || undefined} />}
      </BillyCard>
    )
  } else {
    return <div />
  }
}

export type OrderViewPageForm = WithRestApi & {
  accountDocument?: Attachment
  orderDetail?: OrderDetailFragment
  isCustomContentDialogOpen: boolean
}

function OrderViewPage(): JSX.Element {
  const router = useBillyRouter()
  const errorHandler = useErrorHandler()
  const orderId = router.query.orderId as string
  const steppingFormat = router.query.steppingFormat as string

  const DOWNLOAD_ORDER_PDF_PATH = `/orders/${orderId}/pdf`
  const DOWNLOAD_WORD_DOC_PATH = `/orders/${orderId}/doc`

  const [forcedUpdateValue, forceUpdate] = useReducer((x) => x + 1, 0)

  const [confirmPrimaryOpportunityDialog, setConfirmPrimaryOpportunityDialog] = React.useState(false)
  const [updatePrimaryOpportunityDialog, setUpdatePrimaryOpportunityDialog] = React.useState(false)

  const [openSubscriptionRedirectAlert, setOpenSubscriptionRedirectAlert] = React.useState(false)

  const [orderResponse, updateOrder] = useGetOrderDetailQuery({
    variables: { id: orderId },
  })
  const orderData = orderResponse.data?.orderDetail

  useRenewalOrderPageRedirectModalEffect({ orderData })

  const [attachmentsResponse] = useGetAttachmentsQuery({
    variables: { accountId: orderData?.account.id ?? '' },
    pause: !orderData?.account.id,
    handleError: true,
  })

  const accountDocument = attachmentsResponse.data?.attachments.find(
    (attachment) => attachment.id === orderData?.attachmentId
  )

  const [syncOrderToSalesforceResponse, syncOrderToSalesforce] = useSyncOrderToSalesforceMutation()
  const [syncOrderToHubSpotResponse, syncOrderToHubSpot] = useSyncOrderToHubSpotMutation()

  const [isAmendmentCurrentResponse, isAmendmentCurrent] = useIsAmendmentCurrentQuery({ variables: { id: orderId } })
  const amendmentIsCurrent = isAmendmentCurrentResponse.data ? isAmendmentCurrentResponse.data.isAmendmentCurrent : true

  const breadcrumbs = [
    {
      label: '',
      link: '/',
      icon: <Image src="/icons/home-sm.svg" width={20} height={20} />,
    },
    { label: 'Orders', link: '/orders' },
    { label: orderId },
  ]

  const [updateOrderStatusResult, updateOrderStatus] = useUpdateOrderStatusMutation()
  const [deleteOrderResult, deleteOrder] = useDeleteOrderMutation()
  const [updatePrimaryOrderIdForSalesforceOpportunityResult, updatePrimaryOrderIdForSalesforceOpportunity] =
    useUpdatePrimaryOrderIdForSalesforceOpportunityMutation()
  const [updatePrimaryOrderIdForHubSpotOpportunityResult, updatePrimaryOrderIdForHubSpotOpportunity] =
    useUpdatePrimaryOrderIdForHubSpotOpportunityMutation()

  const [rebaseAmendmentResult, rebaseAmendment] = useRebaseAmendmentMutation()

  const handleRebaseAmendment = useCallback((): void => {
    rebaseAmendment({ id: orderId })
      .then(() => {
        updateOrder({
          id: orderId,
        })
        isAmendmentCurrent({
          id: orderId,
        })
      })
      .catch((err) => errorHandler(err))
  }, [errorHandler, isAmendmentCurrent, orderId, rebaseAmendment, updateOrder])

  const toggleOrderConfirmDeleteDialog = useOrderConfirmDeleteDialog({
    onSubmit: useCallback(async () => {
      await deleteOrder({ id: orderId })
    }, [orderId, deleteOrder]),
  })

  const deleteDraftOrder = useCallback(() => {
    toggleOrderConfirmDeleteDialog()
  }, [toggleOrderConfirmDeleteDialog])

  if (deleteOrderResult.data?.deleteOrder) {
    setTimeout(() => {
      router.push('/orders')
    }, ES_WAIT_TIME_IN_MS)
  }

  const snackbarHandler = useSnackbarHandler()

  const changeOrderStatus = useCallback(
    (status: OrderStatus, statusUpdatedOn?: number): void => {
      async function doAsync() {
        const key = snackbarHandler.inProgressAlert('Updating order status...')

        try {
          const response = await updateOrderStatus({
            id: orderId,
            status: status.toString(),
            statusUpdatedOn,
          })
          if (response.data) {
            forceUpdate()
            if (status === OrderStatus.Executed) {
              setOpenSubscriptionRedirectAlert(true)
            }
            snackbarHandler.successAlert('Order status updated')
          }
        } finally {
          snackbarHandler.dismissAlert(key)
        }
      }
      doAsync().catch((err) => {
        errorHandler(err)
      })
    },
    [orderId, updateOrderStatus, forceUpdate, setOpenSubscriptionRedirectAlert, snackbarHandler, errorHandler]
  )

  const toggleExecuteOrderDialog = useExecuteOrderDialogWithAddressValidation({
    accountId: orderData?.account?.id ?? '',
    orderId: orderData?.id ?? '',
    orderType: orderData?.orderType,
    shippingContact: orderData?.shippingContact ?? undefined,
    billingContact: orderData?.billingContact ?? undefined,
    setOrderExecutedState: setOpenSubscriptionRedirectAlert,
    onExecute: () => changeOrderStatus(OrderStatus.Executed),
    refresh: forceUpdate,
    startDateType: orderData?.startDateType ?? OrderStartDateType.Fixed,
    reload: router.reload,
  })

  const setPrimaryOrderIdForOpportunity = (): void => {
    ;(salesforceIntegration
      ? updatePrimaryOrderIdForSalesforceOpportunity({
          id: orderId,
        })
      : updatePrimaryOrderIdForHubSpotOpportunity({
          id: orderId,
        })
    )
      .then(() => {
        updateOrder({
          id: orderId,
        })
      })
      .catch(errorHandler)
  }

  const setPrimaryOrderIdForOpportunityThenExecute = (): void => {
    ;(salesforceIntegration
      ? updatePrimaryOrderIdForSalesforceOpportunity({
          id: orderId,
        })
      : updatePrimaryOrderIdForHubSpotOpportunity({
          id: orderId,
        })
    )
      .then(() => {
        toggleExecuteOrderDialog()
      })
      .catch((error) => {
        logger.debug({ msg: 'error=', error })
      })
  }

  const userTenantSession = useUserTenantSession()
  const role = userTenantSession.currentUser.role

  const [getOpportunityByCrmOpportunityIdQueryResponse] = useGetOpportunityByCrmOpportunityIdQuery({
    variables: { crmOpportunityId: orderData?.sfdcOpportunityId ?? '' },
    pause: !orderData?.sfdcOpportunityId,
  })
  const opportunityData = getOpportunityByCrmOpportunityIdQueryResponse.data?.opportunityByCrmOpportunityId
  const [salesforceIntegrationResult] = useGetSalesforceIntegrationQuery()
  const [hubSpotIntegrationResult] = useGetHubSpotIntegrationQuery()
  const salesforceIntegration = salesforceIntegrationResult.data?.salesforceIntegration
  const hubSpotIntegration = hubSpotIntegrationResult.data?.hubSpotIntegration

  const handleSyncOrderToSalesforce = useCallback((): void => {
    syncOrderToSalesforce({ orderId: orderId }).catch((err) => logger.warn(err))
  }, [orderId, syncOrderToSalesforce])

  const handleSyncOrderToHubSpot = useCallback((): void => {
    syncOrderToHubSpot({ orderId: orderId }).catch((err) => logger.warn(err))
  }, [orderId, syncOrderToHubSpot])

  const handleSyncToCRM = hubSpotIntegration ? handleSyncOrderToHubSpot : handleSyncOrderToSalesforce

  const crmName = hubSpotIntegration ? 'HubSpot' : 'Salesforce'

  const hasCrmIntegration = salesforceIntegration || hubSpotIntegration

  const orderCanBeMarkedPrimary =
    !!orderData?.sfdcOpportunityId &&
    !orderData?.isPrimaryOrderForSfdcOpportunity &&
    !!orderData?.sfdcOrderCanBeExecuted &&
    !!hasCrmIntegration

  const orderCanBeSynced =
    !!hasCrmIntegration && orderData?.isPrimaryOrderForSfdcOpportunity && !!orderData?.sfdcOpportunityId

  const jotaiForm = useJotaiForm<OrderViewPageForm>(
    React.useMemo(
      () => ({
        defaultValue: {
          accountDocument,
          orderDetail: orderData,
          isCustomContentDialogOpen: false,
          ...initJotaiRestState([DOWNLOAD_ORDER_PDF_PATH, DOWNLOAD_WORD_DOC_PATH]),
        },
      }),
      [orderData, accountDocument, DOWNLOAD_ORDER_PDF_PATH, DOWNLOAD_WORD_DOC_PATH]
    )
  )

  const [, createOrderDocument] = useCreateOrderDocumentMutation()

  const downloadOrderDocumentAsync = useCallback(
    async (docType: 'DOC' | 'PDF') => {
      const progressKey = snackbarHandler.inProgressAlert(`Generating ${docType}...`, { persisting: true })
      try {
        const response = await createOrderDocument({ orderId })
        if (response.data?.createOrderDocument) {
          if (docType === 'DOC') {
            download(DOWNLOAD_WORD_DOC_PATH)
          }
          if (docType === 'PDF') {
            download(DOWNLOAD_ORDER_PDF_PATH)
          }
          snackbarHandler.successAlert(`${docType} Downloaded`)
        } else {
          throw new Error(`${docType} generation failed`)
        }
      } finally {
        snackbarHandler.dismissAlert(progressKey)
      }
    },
    [snackbarHandler, createOrderDocument, orderId, DOWNLOAD_ORDER_PDF_PATH, DOWNLOAD_WORD_DOC_PATH]
  )

  const handlePdfDownload = useCallback((): void => {
    downloadOrderDocumentAsync('PDF').catch(errorHandler)
  }, [errorHandler, downloadOrderDocumentAsync])

  const [DownloadPDFError, DownloadPDFSnackbar] = useJotaiRestBillySnackBar(jotaiForm, DOWNLOAD_ORDER_PDF_PATH)

  const handleWordDocDownload = useCallback((): void => {
    downloadOrderDocumentAsync('DOC').catch(errorHandler)
  }, [errorHandler, downloadOrderDocumentAsync])

  const [DownloadReportError, DownloadReportSnackbar] = useJotaiRestBillySnackBar(jotaiForm, DOWNLOAD_WORD_DOC_PATH)

  const predefinedDiscountAmounts = useMemo(() => {
    const amounts = new Map()
    orderData?.lineItems.forEach((lineItem) => {
      lineItem.predefinedDiscounts?.forEach((predefinedDiscount) => {
        if (predefinedDiscount) {
          const amount = amounts.get(predefinedDiscount.id) || 0
          amounts.set(predefinedDiscount.id, predefinedDiscount.amount + amount)
        }
      })
    })
    return amounts
  }, [orderData?.lineItems])

  const predefinedDiscounts: DisplayPredefinedDiscount[] = jotaiForm.useSelect(
    useCallback(
      (form) =>
        form.orderDetail?.predefinedDiscounts?.map((predefinedDiscount) => ({
          id: predefinedDiscount?.id || '',
          name: predefinedDiscount?.name || '',
          percent: discountFormat({ value: predefinedDiscount?.percent }),
          amount: predefinedDiscountAmounts.get(predefinedDiscount?.id || '') || 0,
        })) || [],
      [predefinedDiscountAmounts]
    )
  )
  const isPredefinedDiscountPresent = jotaiForm.useSelect(
    useCallback((form) => form.orderDetail?.predefinedDiscounts && form.orderDetail.predefinedDiscounts.length > 0, [])
  )

  const onSubmitForApproval = useCallback(() => changeOrderStatus(OrderStatus.Submitted), [changeOrderStatus])

  const onMarkAsExecuted = useCallback(() => {
    {
      if (orderData?.sfdcOpportunityId && !orderData?.isPrimaryOrderForSfdcOpportunity) {
        setConfirmPrimaryOpportunityDialog(true)
      } else {
        toggleExecuteOrderDialog()
      }
    }
  }, [orderData, toggleExecuteOrderDialog])

  const onMarkOrderAsPrimary = useCallback(() => {
    setUpdatePrimaryOpportunityDialog(true)
  }, [setUpdatePrimaryOpportunityDialog])

  const { toggleSendEsignEmailDialog, toggleEsignInProgressDialog, esignStatus, isEsignInProgress, esignProvider } =
    useSendEmailOrderView(
      useMemo(
        () => ({
          orderData,
          orderId,
          errorHandler,
          snackbarHandler,
          router,
        }),
        [orderData, orderId, errorHandler, snackbarHandler, router]
      )
    )

  const { loading } = useAsyncLoadingNotifier()

  const [salesRoomLinkResponse] = useGetSalesRoomLinkQuery({ variables: { orderId } })

  const isSalesRoomLinkGenerated = !!salesRoomLinkResponse.data?.salesRoomLink?.linkId
  const toggleSalesRoomLinkVoidedDialog = useSalesRoomLinkRevertToDraftWarningDialog({
    revertToDraft: () => {
      changeOrderStatus(OrderStatus.Draft)
    },
  })

  const buttonDataList = jotaiForm.useSelect(
    useCallback(
      (form) => {
        const { actionsMenu } = getOrderViewActionButtons({
          variant: 'single-menu',
          steppingFormat,
          form,
          role,
          orderCanBeMarkedPrimary,
          orderCanBeSynced,
          isOpportunityClosed: opportunityData?.isClosed,
          handlePdfDownload,
          handleWordDocDownload,
          onSubmitForApproval,
          handleSyncToCRM,
          deleteDraftOrder,
          changeOrderStatus,
          onMarkAsExecuted,
          onRequestEsignature: toggleSendEsignEmailDialog,
          onMarkOrderAsPrimary,
          esignStatus,
          isEsignInProgress,
          toggleEsignInProgressDialog,
          esignProvider,
          isSalesRoomLinkGenerated,
          toggleSalesRoomLinkVoidedDialog,
        })

        return getPageButtons({ actions: actionsMenu, loading })
      },
      [
        steppingFormat,
        onMarkAsExecuted,
        onMarkOrderAsPrimary,
        handlePdfDownload,
        handleWordDocDownload,
        onSubmitForApproval,
        role,
        orderCanBeSynced,
        orderCanBeMarkedPrimary,
        opportunityData?.isClosed,
        changeOrderStatus,
        handleSyncToCRM,
        deleteDraftOrder,
        isEsignInProgress,
        toggleEsignInProgressDialog,
        loading,
        esignStatus,
        toggleSendEsignEmailDialog,
        esignProvider,
        isSalesRoomLinkGenerated,
        toggleSalesRoomLinkVoidedDialog,
      ]
    )
  )

  const isRebaseAmendmentFeatureEnabled = useDynamicFeatureFlag(Feature.RebaseAmendment)
  function canRebaseAmendment() {
    return (
      isRebaseAmendmentFeatureEnabled &&
      !amendmentIsCurrent &&
      orderData?.status !== OrderStatus.Executed &&
      isAdminRole(role)
    )
  }
  const currency = orderData?.currency ?? undefined

  return (
    <CurrencyProvider currency={currency}>
      <Head>
        <title>Order Detail | Subskribe</title>
      </Head>
      <NavLayout breadcrumbs={breadcrumbs} actionButtons={orderData ? buttonDataList : []}>
        <InnerPageContainer>
          {syncOrderToSalesforceResponse.fetching && <InProgressAlert message="Syncing..." />}
          {syncOrderToSalesforceResponse.data?.syncOrderToSalesforce && <SuccessAlert message="Sync successful" />}
          {syncOrderToHubSpotResponse.fetching && <InProgressAlert message="Syncing..." />}
          {syncOrderToHubSpotResponse.data?.syncDealInformationToHubSpot && <SuccessAlert message="Sync successful" />}
          {orderResponse.fetching && <InProgressAlert message="Loading..." />}
          {orderResponse.error && <GqlErrorDisplay error={orderResponse.error} />}
          {deleteOrderResult.fetching && <InProgressAlert message="Deleting order..." />}
          {deleteOrderResult.data?.deleteOrder && <SuccessAlert message="Deleted successfully" />}
          {updateOrderStatusResult.fetching && <InProgressAlert message="Updating order status..." />}
          {updatePrimaryOrderIdForSalesforceOpportunityResult.fetching && (
            <InProgressAlert message="Updating primary order ID..." />
          )}
          {updatePrimaryOrderIdForHubSpotOpportunityResult.fetching && (
            <InProgressAlert message="Updating primary order ID..." />
          )}

          {rebaseAmendmentResult.fetching && <InProgressAlert message="Refreshing order..." />}
          {rebaseAmendmentResult.data?.rebaseAmendment && <SuccessAlert message="Order refreshed successfully" />}

          {canRebaseAmendment() && (
            <BillyAlert
              showIcon
              alertType="warning"
              alertProps={{ style: { marginTop: 0 } }}
              message={'The subscription was modified after this order was created.'}
            >
              <BillyLink
                linkProps={{
                  role: 'button',
                  onClick: handleRebaseAmendment,
                }}
                style={{ display: 'inline' }}
              >
                {'Update Order Target'}
              </BillyLink>
            </BillyAlert>
          )}

          <PurchaseOrderWarning
            type="order"
            purchaseOrderRequiredForInvoicing={orderData?.purchaseOrderRequiredForInvoicing}
            hasPurchaseOrderNumber={!!orderData?.purchaseOrderNumber}
          />
          <DownloadPDFSnackbar jotaiForm={jotaiForm} workingMessage="Downloading PDF..." successMessage="Downloaded!" />
          <DownloadPDFError jotaiForm={jotaiForm} />
          {orderData?.orderType && orderData?.subscriptionId && openSubscriptionRedirectAlert && (
            <BillyAlert
              alertType="root"
              title={`Your ${orderData.orderType} order has been executed. Go to subscription `}
              alertProps={{ style: { backgroundColor: '#ffffff', borderColor: 'rgba(0, 0, 0, 0.12)' } }}
            >
              <NextLink href={`/subscriptions/${orderData.subscriptionId}`}>{orderData.subscriptionId}</NextLink>
            </BillyAlert>
          )}

          <DownloadReportError jotaiForm={jotaiForm} />
          <DownloadReportSnackbar
            jotaiForm={jotaiForm}
            workingMessage="Downloading..."
            successMessage="Downloaded Successfully"
          />

          <Grid container direction="column" wrap="nowrap" spacing={3}>
            {!!orderData && (
              <Grid item>
                <StatusStepper order={orderData} key={forcedUpdateValue} />
              </Grid>
            )}

            {orderData && (
              <OrderDetailsViewCard
                orderData={orderData}
                hasHubSpotIntegration={!!salesforceIntegration}
                hasSalesforceIntegration={!!hubSpotIntegration}
                opportunityData={opportunityData}
                orderId={orderId}
              />
            )}
            {orderData && (
              <CustomFieldsCard
                customFieldType={CustomFieldParentType.Order}
                parentObjectId={orderId}
                canEditCustomFields={!isOrderExecutedOrCancelled(orderData) && canMutateOrder(role)}
              />
            )}
            <OrderPageSalesRoomCustomFieldsCard orderId={orderId} />
            {!!orderData?.lineItems && (
              <Grid item>
                <OrderItemPreview orderData={orderData} orderId={orderId} updateOrder={updateOrder} role={role} />
              </Grid>
            )}
            {isPredefinedDiscountPresent && (
              <Grid item>
                <BillyCard hidden={!orderResponse.data}>
                  <CardHeader title="Predefined Discounts Applied" />
                  <PredefinedDiscountsTable currency={currency} predefinedDiscounts={predefinedDiscounts} />
                  <CardContent>
                    <Grid container alignItems={'flex-end'} direction={'column'}>
                      <Grid item display="flex">
                        <Box sx={{ fontWeight: 'bold' }}>Total Predefined Discount:&nbsp;</Box>
                        <Box>
                          {billableCurrencyFormat({
                            currency,
                            value: predefinedDiscounts.reduce((prev, next) => prev + next.amount, 0),
                          })}
                        </Box>
                      </Grid>
                    </Grid>
                  </CardContent>
                </BillyCard>
              </Grid>
            )}
            {orderData?.documentMasterTemplate?.name && (
              <Grid item>
                <BillyCard>
                  <CardHeader title="Document Template" />
                  <CardContent>
                    <Typography>{orderData?.documentMasterTemplate?.name}</Typography>
                  </CardContent>
                </BillyCard>
              </Grid>
            )}
            <IncludedTermsViewJotai jotaiForm={jotaiForm} refresh={updateOrder} />
          </Grid>

          <ActionDialog
            open={confirmPrimaryOpportunityDialog}
            title={'Set order as primary?'}
            content={`Executing this order will set it as the primary order for the associated ${crmName} opportunity.`}
            actionButtonData={{
              label: 'Set Primary',
              onClick: () => {
                setPrimaryOrderIdForOpportunityThenExecute()
                setConfirmPrimaryOpportunityDialog(false)
              },
              color: 'danger',
              buttonProps: { variant: 'outlined' },
            }}
            cancelButtonData={{
              label: 'Cancel',
              onClick: () => setConfirmPrimaryOpportunityDialog(false),
              buttonProps: { variant: 'outlined' },
            }}
          />
          <ActionDialog
            open={updatePrimaryOpportunityDialog}
            title={'Set order as primary?'}
            content={`Updating this order will set it as the primary order for the associated ${crmName} opportunity.`}
            actionButtonData={{
              label: 'Set Primary',
              onClick: () => {
                setPrimaryOrderIdForOpportunity()
                setUpdatePrimaryOpportunityDialog(false)
              },
              color: 'danger',
              buttonProps: { variant: 'outlined' },
            }}
            cancelButtonData={{
              label: 'Cancel',
              onClick: () => setUpdatePrimaryOpportunityDialog(false),
              buttonProps: { variant: 'outlined' },
            }}
          />
        </InnerPageContainer>
      </NavLayout>
    </CurrencyProvider>
  )
}

export default OrderViewPage
